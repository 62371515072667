import React from "react";
import 'font-awesome/css/font-awesome.min.css';

import "./reset-filters.css";
import Tooltip from '@material-ui/core/Tooltip';
import { theme } from 'get-theme';
import { withStyles } from '@material-ui/core';
import { ResetIcon } from 'pdc-svg-icons'

const styles = theme => ({
	tooltip: {
		backgroundColor: '#48B9D5',
    fontSize:	11,
    fontWeight: 500,
    padding: '8px 12px !important',
    fontFamily: 'Montserrat',
  },
  tooltipDisabled: {
    display: 'none'
	}
})

class ResetFilters extends React.Component {
  resetTooltipText = 'Start new search';

  render() {
    let { classes } = this.props;
    return (
      <Tooltip title={this.resetTooltipText} classes={{ tooltip: this.props.disabled ? classes.tooltipDisabled : classes.tooltip }} placement='top'>
        <div data-test-id='reset-filters' className={`${this.props.disabled ? 'reset-filters-disabled': 'reset-filters'}`} onClick={this.props.handleReset}>
          <ResetIcon/>
        </div>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(ResetFilters);
