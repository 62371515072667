<template>
	<div class="credit-card-wrapper mx-auto" :class="{'primary-cc': card.status === 'primary'}" v-bind="$attrs" v-on="$listeners">
		<!-- <div v-if="card.status === 'primary'" class="w-label primary-credit-card-label">
			{{l.t('app.primary', 'Primary')}}
		</div> -->
		<div class="w-body-1">
			{{ card_name }}
		</div>
		<div class="credit-cards-img">
			<v-img
				v-if="card.details && card.details.brand && images[card.details.brand]"
				:src="images[card.details.brand]"
				:alt="card.details.brand"
				width="40"
				:height="card.details && card.details.brand === 'MasterCard' ? '25' : '27'"
			/>
			<span v-else-if="card.details && card.details.brand">
				{{ card.details.brand }}
			</span>
		</div>
		<div class="w-body-2 mt-2">
			<template v-if="card.cc_number">
				<span v-html="dots(card.cc_number.substring(0,4))" class="dots" />
				<span v-html="dots(card.cc_number.substring(4, card.cc_number.length - 4))" class="dots" />
				<span v-html="card.cc_number.substring(card.cc_number.length - 4, card.cc_number.length)"/>
			</template>
			<span v-else>&mdash;</span>
		</div>
		<div class="w-body-2 mt-2">
			{{ l.t('app.expires', 'Expires') }}&nbsp;
			<span v-if="card.cc_exp">{{ card.cc_exp.substring(0, 2) }}/{{ card.cc_exp.substring(2) }}</span>
			<span v-else>&mdash;</span>
		</div>
	</div>
</template>

<script>
import l from '../../libs/lang';
import amex from '../../../../vue-apps-assets/images/credit-cards/amex.png';
import diners from '../../../../vue-apps-assets/images/credit-cards/diners.png';
import discover from '../../../../vue-apps-assets/images/credit-cards/discover.png';
import jcb from '../../../../vue-apps-assets/images/credit-cards/jcb.png';
import mastercard from '../../../../vue-apps-assets/images/credit-cards/mastercard.png';
import unionpay from '../../../../vue-apps-assets/images/credit-cards/unionpay.png';
import visa from '../../../../vue-apps-assets/images/credit-cards/visa.png';

export default {
	props: {
		card: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			l,
			images: {
				'American Express': amex,
				'MasterCard': mastercard,
				'Visa': visa,
				'Diners Club': diners,
				'Discover': discover,
				'JCB': jcb,
				'UnionPay': unionpay,
			},

		};
	},
	methods: {
		dots(str) {
			let type = '';
			for (let i = 0; i < str.length; i++) {
				type += '&bull;';
			}
			return type;
		},
	},
	computed: {
		card_name() {
			if (this.$props.card.nickname) {
				return this.$props.card.nickname;
			}
			if (this.$props.card.details && this.$props.card.details.customer_name) {
				return this.$props.card.details.customer_name;
			}
			return '—';
		},
	},
};
</script>
