import React, { Component } from 'react'
import StepWizard from 'step-wizard'
import GeneralSettingsStep, { GeneralSettingsData } from './GeneralSettingsStep'
import BusinessHoursStep, { BusinessHoursData } from './BusinessHoursStep'
import UserSetupStep, { UserSetupData } from './UserSetupStep'
import CallHandlingStep, { CallHandlingData } from './CallHandlingStep'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import { StepsSaveData } from 'step-wizard/src/StepWizard'
import Api from 'api'
import { CircleLogo/*, LogoIcon */ } from 'svg-icons'
import Typography from 'typography'
import GeneralSettingsIcon from './icons/GeneralSettingsIcon'
import BusinessHoursIcon from './icons/BusinessHoursIcon'
import UserSetupIcon from './icons/UserSetupIcon'
import CallHandlingIcon from './icons/CallHandlingIcon'
import RemoteConfigValue from 'remote-config-value'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import SplashScreen from './SplashScreen'
import TeammateProvider, { TeammateConsumer } from 'providers/src/MyPhoneContact/TeammateProvider'
import PhoneNumberProvider, { PhoneNumberConsumer } from 'providers/src/PhoneNumbers/PhoneNumberProvider'
import { Teammate } from 'models/src'
import { PhoneNumber } from 'models/src/phone-numbers/PhoneNumber'

/***/
export enum StepName { GeneralSettings, BusinessHours, UserSetup, CallHandling }

/***/
export interface PassData {
    [StepName.GeneralSettings]: GeneralSettingsData
    [StepName.BusinessHours]: BusinessHoursData
    [StepName.UserSetup]: UserSetupData
    [StepName.CallHandling]: CallHandlingData
    extraData: { companyExtension, companyName: string, users: Teammate[], phoneNumbers: PhoneNumber[] }
}

interface Props {
    extension: any
    screenViewType: { isMobileView: boolean, isTabletView: boolean, isTabletPortraitView: boolean }
    companyName: string
    setBusy: (isBusy: boolean) => void
    updateCompanyName: (name: string) => void
    classes
}

interface State {
    saved: boolean,
    companyExtension: number
    loading: boolean
    splash: boolean
    loadingUsers: boolean
    loadingPhoneNumbers: boolean
}

class CompanySetup extends Component<Props, State> {
    steps: any[]
    constructor (props: Props) {
        super(props)
        this.steps = [
            {
                id: StepName.GeneralSettings,
                title: <div data-test-id='csw-company-setup-title' className='title-wrapper'><GeneralSettingsIcon/> <RemoteConfigValue valueId='company_setup_general_settings_title'/></div>,
                secondaryTitle: <RemoteConfigValue valueId='company_setup_general_settings_subtitle'/>,
                label: <RemoteConfigValue valueId='company_setup_general_settings_title'/>,
                secondaryLabel: '',
                component: GeneralSettingsStep,
                bottomNavigationText: <RemoteConfigValue valueId='company_setup_general_settings_bottom_navigation_text'/>,
                'data-test-id': 'step-1-company-setup'
            },
            {
                id: StepName.BusinessHours,
                title: <div data-test-id='csw-business-hours-title' className='title-wrapper'><BusinessHoursIcon/> <RemoteConfigValue valueId='company_setup_business_hours_title'/></div>,
                secondaryTitle: <RemoteConfigValue valueId='company_setup_business_hours_subtitle'/>,
                label: <RemoteConfigValue valueId='company_setup_business_hours_circle_label'/>,
                secondaryLabel: '',
                component: BusinessHoursStep,
                bottomNavigationText: <RemoteConfigValue valueId='company_setup_business_hours_bottom_navigation_text'/>,
                'data-test-id': 'step-2-business-hours'
            },
            {
                id: StepName.UserSetup,
                title: <div data-test-id='csw-user-setup-title' className='title-wrapper'><UserSetupIcon/> <RemoteConfigValue valueId='company_setup_user_setup_title'/></div>,
                secondaryTitle: <RemoteConfigValue valueId='company_setup_user_setup_subtitle'/>,
                label: <RemoteConfigValue valueId='company_setup_user_setup_circle_label'/>,
                secondaryLabel: '',
                component: UserSetupStep,
                bottomNavigationText: <RemoteConfigValue valueId='company_setup_user_setup_bottom_navigation_text'/>,
                'data-test-id': 'step-3-user-setup'
            },
            {
                id: StepName.CallHandling,
                title: <div data-test-id='csw-call-handling-title' className='title-wrapper'><CallHandlingIcon/> <RemoteConfigValue valueId='company_setup_call_handling_title'/></div>,
                secondaryTitle: <RemoteConfigValue valueId='company_setup_call_handling_subtitle'/>,
                label: <RemoteConfigValue valueId='company_setup_call_handling_circle_label'/>,
                secondaryLabel: '',
                component: CallHandlingStep,
                bottomNavigationText: <RemoteConfigValue valueId='company_setup_call_handling_bottom_navigation_text'/>,
                'data-test-id': 'step-4-call-handling'
            }
        ]
        this.state = {
            saved: false,
            companyExtension: null,
            loading: true,
            splash: true,
            loadingUsers: true,
            loadingPhoneNumbers: true
        }
    }

    componentDidMount = async () => {
        await Promise.all([this.getCompanyExtension()])
        this.setState({ loading: false })
    }

    getCompanyExtension = async () => {
        const companyExtension = await Api.getCompanyExtension()
        this.setState({ companyExtension })
    }

    onSubmit = async (stepsSaveData: StepsSaveData) => {
        console.log('#### onSubmit called', stepsSaveData)
        this.setState({ saved: true })
    }

    renderDoneStep = () => {
        const { classes } = this.props
        Api.markWizardComplete().then(() => {
            setTimeout(() => {
                window.location.href = process?.env?.REACT_APP_SETTINGS_BASE_URL
            }, 5000)
        })

        return (
            <div key='done' className={classes.infoWrapper}>
                <CircleLogo style={{ fontSize: 50 }}/>
                <Typography data-test-id='csw-account-setup-successful' variant='h6' classes={{ root: 'main-message second' }} remoteConfigID='company_setup_saved_main_message'/>
                <Typography data-test-id='redirect-to-my-pdc-text' variant='body1' remoteConfigID='company_setup_saved_secondary_message'/>
            </div>
        )
    }

    renderSplashScreen = () => {
        const { screenViewType } = this.props
        const name = window.V5PHONECOM ? `${window.V5PHONECOM.first_name} ${window.V5PHONECOM.last_name}`.trim() : null
        return (
            <SplashScreen
                screenViewType = {screenViewType}
                name = {name}
                onNext = {() => this.setState({ splash: false })}
            />
        )
    }

    renderStepWizard = (users, phoneNumbers): JSX.Element => {
        const { classes, screenViewType, companyName } = this.props
        const { isMobileView } = screenViewType
        const { companyExtension } = this.state
        return (
            <>
                <StepWizard
                    className = {`${classes.stepWizard} ${isMobileView ? 'mobile' : ''}`}
                    steps = {this.steps}
                    smallView = {isMobileView}
                    onSubmit = {this.onSubmit}
                    extraData = {{ users, phoneNumbers, companyExtension, companyName }}
                />
                {/* <LogoIcon className={classes.wizardLogo}/> */}
            </>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary.main} size='bigger'/>
            </div>
        )
    }

    render = (): JSX.Element => {
        const { loading, loadingUsers, saved, splash } = this.state
        return (
            <PhoneNumberProvider>
                <TeammateProvider>
                    <TeammateConsumer>
                        {(users: any) => {
                            return (
                                <PhoneNumberConsumer>
                                    {(phoneNumbers: any) => {
                                        if (users.loaded && this.state.loadingUsers) this.setState({ loadingUsers: false })
                                        if (phoneNumbers.loaded && this.state.loadingPhoneNumbers) this.setState({ loadingPhoneNumbers: false })
                                        if (splash) return this.renderSplashScreen()
                                        if (loading || loadingUsers) return this.renderLoader()
                                        const usersArray = Array.from(users.teammates.items.values())
                                        const phoneNumbersArray = Array.from(phoneNumbers.phoneNumbers.items.values())
                                        if (saved) return this.renderDoneStep()
                                        return this.renderStepWizard(usersArray, phoneNumbersArray)
                                    }}
                                </PhoneNumberConsumer>
                            )
                        }}
                    </TeammateConsumer>
                </TeammateProvider>
            </PhoneNumberProvider>
        )
    }
}

export default withStyles(styles)(CompanySetup)
