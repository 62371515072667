import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8550
 *
 * @param {SvgIconProps} props - svg props
 */
export const MicIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M15.2592 11.733C15.2592 13.4268 13.8096 14.7941 12.0003 14.7941C10.191 14.7941 8.73053 13.4268 8.73053 11.733V5.56109C8.73053 3.86729 10.191 2.5 12.0003 2.5C13.8096 2.5 15.2701 3.86729 15.2701 5.56109L15.2592 11.733ZM16.8979 12.233C16.9577 11.7985 17.3071 11.4409 17.7456 11.4409C18.1842 11.4409 18.5443 11.7979 18.4963 12.2339C18.149 15.3849 15.7649 17.8931 12.9344 18.3489V20.5656C12.9344 21.0815 12.5161 21.4998 12.0002 21.4998C11.4842 21.4998 11.066 21.0815 11.066 20.5656V18.3489C8.2361 17.8838 5.85243 15.3758 5.50435 12.2338C5.45606 11.7979 5.81618 11.4409 6.25475 11.4409C6.69331 11.4409 7.04268 11.7985 7.10251 12.233C7.46818 14.8882 9.64871 16.6836 12.0002 16.6836C14.3517 16.6836 16.5322 14.8882 16.8979 12.233Z' fill={color}/>
        </SvgIcon>
    )
}

export default MicIcon
