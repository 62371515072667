/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { Extension } from '../extensions/Extension'

import { formatPhoneNumber } from 'phone-numbers'
import { getPhoneNumberType } from './util'

/***/
export class PhoneNumber {
    public type: string = null
    /***/
    constructor (
        public id = 0,
        public voip_did_id = 0,
        public cursor = '',
        public name = '',
        public phone_number = '',
        public block_incoming = false,
        public block_anonymous = false,
        public record_calls = false,
        public sms_forwarding: SmsForwarding = null,
        public call_rules: any, // CallHandlingRules = null,
        public caller_name = ''
    ) {
        if (!name && this.phone_number) this.name = formatPhoneNumber(this.phone_number)
        this.type = getPhoneNumberType(this)
    }

    isMainCompanyNumber = (): boolean => this.type === 'Main company number'

    /**
     * @param json
     */
    public static fromJson (json: any): PhoneNumber {
        const phoneNumber = new PhoneNumber()
        phoneNumber.id = json.id
        phoneNumber.voip_did_id = json.voip_did_id
        phoneNumber.cursor = json.cursor
        phoneNumber.name = json.name
        phoneNumber.phone_number = json.phone_number
        phoneNumber.block_incoming = json.block_incoming
        phoneNumber.block_anonymous = json.block_anonymous
        phoneNumber.record_calls = json.record_calls
        phoneNumber.sms_forwarding = json.sms_forwarding ? SmsForwarding.fromJson(json.sms_forwarding) : null
        phoneNumber.call_rules = json.call_rules // CallHandlingRules.fromJson(json.call_rules)
        phoneNumber.caller_name = json.caller_name
        phoneNumber.type = getPhoneNumberType(phoneNumber)
        return phoneNumber
    }

    /***/
    public toJson (): any {
        return {
            id: this.id,
            voip_did_id: this.voip_did_id,
            cursor: this.cursor,
            name: this.name,
            phone_number: this.phone_number,
            block_incoming: this.block_incoming,
            block_anonymous: this.block_anonymous,
            record_calls: this.record_calls,
            sms_forwarding: this.sms_forwarding.toJson(),
            call_rules: this.call_rules.toJson(),
            caller_name: this.caller_name
        }
    }
}

/**
 *
 */
class SmsForwarding {
    constructor (
        public type = 'extension',
        public extension: Extension
    ) { this.type = type }

    /**
     * @param json
     */
    public static fromJson (json: any): SmsForwarding {
        const extensionJson = { extension_id: json.extension.id, extension: json.extension.extension, extension_name: json.extension.name }
        return new SmsForwarding(json.type, Extension.fromJson(extensionJson))
    }

    /***/
    public toJson (): any {
        const extensionJson = this.extension?.toJson()
        if (extensionJson) {
            extensionJson.id = extensionJson.extension_id
            extensionJson.name = extensionJson.extension_name
            delete extensionJson.extension_id
            delete extensionJson.extension_name
        }
        return {
            type: this.type,
            extension: extensionJson
        }
    }
}
// class CallHandlingRules {}
