<template>
    <DefaultModal v-model="show_modal" max-width="550" persistent :with_x="false">
        <div class="address-update-alert">
            <v-icon size="24" color="warning darken-1">$vuetify.icons.infotip_stylized</v-icon>
            <div class="w-body-1 warning--text text--darken-1">
                {{l.t('app.we-have-updated-address-entered', 'We have updated the address entered. If correct, please use the suggested address to ensure accuracy.')}}
            </div>
        </div>
        <w-radio-group
            class="address-radio-selector"
            hide-details="auto"
            data-test-id="address-radio-selector"
            v-model="address_select"
        >
            <v-radio
                v-for="type of ['user_input', 'validated_address']"
                color="secondary"
                type="radio"
                :value="type"
                :data-test-id="`user-${type}-address`"
                >
                <template v-slot:label>
                    <div class="w-body-2">
                        <span class="font-weight-bold">
                            {{type === 'user_input' ? l.t('app.entered-address', 'Entered address') : l.t('app.suggested-address', 'Suggested address')}}
                        </span>
                        <div v-if="$props[type].line_1">{{format_address($props[type])}}</div>
                    </div>
                </template>
            </v-radio>
        </w-radio-group>
        <template v-slot:buttons>
            <w-btn @click="on_address_select" color="primary" text>{{l.t('app.use-selected-address', 'Use selected address')}} <v-icon size="18">$vuetify.iocons.chevron_forward</v-icon></w-btn>
        </template>
    </DefaultModal>
</template>
<script>
import l from '../../../libs/lang';
import {stringifyAddress} from 'formatters';
import DefaultModal from './DefaultModal.vue';

export default {
    props: {
        user_input: {
            type: Object,
            required: true,
        },
        validated_address: {
            type: Object,
            required: true,
        },
    },
    components: { DefaultModal, },
    data() {
        return {
            l,
            address_select: 'validated_address',
            show_modal: false,

        };
    },
    created() {
        this.$data.show_modal = JSON.stringify(this.$props.validated_address).toLowerCase() !== JSON.stringify(this.$props.user_input).toLowerCase()
    },
    methods: {
        on_address_select() {
			const input = JSON.parse(JSON.stringify(this.$props[this.$data.address_select]));
			this.$emit('address_input', input);
            this.$data.show_modal = false;
		},
        format_address(address) {
			return stringifyAddress(address);
		},
    }
};
</script>
