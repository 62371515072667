import React from 'react'
import { Button } from 'button-mui'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import GuideIcon from './guide-icon.png'
import { ArrowBackIosIcon, ArrowForwardIosIcon } from 'svg-icons'
import Typography from 'typography'

interface Props {
    // Material ui classes
    classes
    /**
     * Is mobile view
     */
    smallView: boolean
    /**
     * The left part of the bottom navigation
     */
    description: string | JSX.Element
    /**
     * Should the back button be hidden
     */
    hideBackButton: boolean
    /**
     * Should the back button be disabled
     */
    backDisabled: boolean
    /**
     * Should the next button be disabled
     */
    nextDisabled: boolean
    /**
     * Custom text for the 'back' button
     */
    backButtonText: string
    /**
     * Custom text for the 'next' button
     */
    nextButtonText: string
    /**
     * Called on back click
     */
    onBackClick: React.MouseEventHandler<HTMLButtonElement>
    /**
     * Called on next click
     */
    onNextClick: React.MouseEventHandler<HTMLButtonElement>
    className: string | null
    nextButtonDataTestId?: string
    noIconInNextButton?: boolean,
    noIconInBackButton?: boolean
}

const BottomNavigation = (props: Props) => {
    const {
        classes,
        smallView,
        description,
        hideBackButton,
        backDisabled,
        nextDisabled,
        onBackClick,
        onNextClick,
        className,
        backButtonText,
        nextButtonText,
        noIconInNextButton,
        noIconInBackButton
    } = props
    let classNames = classes.wrapper
    if (className) classNames += ` ${className}`
    if (smallView) classNames += ' mobile'
    const backText = backButtonText || 'Back'
    const nextText = nextButtonText || 'Next'
    const startIcon = noIconInBackButton ? null : <ArrowBackIosIcon/>
    const endIcon = noIconInNextButton ? null : <ArrowForwardIosIcon/>
    const nextButtonDataTestId = props.nextButtonDataTestId || 'bottom-navigation-next-button'
    return (
        <div className={classNames}>
            {!smallView
                ? <div className='info-wrapper'>
                    <img src={GuideIcon} alt='guide'/>
                    <Typography variant='body2'>{description || ''}</Typography>
                </div>
                : null
            }
            <div className='buttons-wrapper'>
                {hideBackButton ? null : <Button size='large' startIcon={startIcon} color='secondary' disabled={backDisabled} onClick={onBackClick} data-test-id='bottom-navigation-prev-button'>{backText}</Button>}
                <Button size='large' endIcon={endIcon} color='primary' disabled={nextDisabled} onClick={onNextClick} data-test-id={nextButtonDataTestId}>{nextText}</Button>
            </div>
        </div>
    )
}

BottomNavigation.defaultProps = {
    smallView: false,
    description: '',
    hideBackButton: false,
    backDisabled: false,
    nextDisabled: false,
    onBackClick: null,
    onNextClick: null,
    backButtonText: 'Back',
    nextButtonText: 'Next'
}

export default withStyles(styles)(BottomNavigation)
