import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'pdc-checkbox'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core'

import cancelButton from '../images/btn-cancel-enabled.png'
import cancelButtonHover from '../images/btn-cancel-hover.png'
import deleteButton from '../images/btn-delete-enabled.png'
import deleteButtonHover from '../images/btn-delete-hover.png'
import deleteButtonDisabled from '../images/btn-delete-disabled.png'
import noButton from '../images/btn-no.png'
import noButtonHover from '../images/btn-no-hover.png'
import yesButton from '../images/btn-yes-enabled.png'
import yesButtonDisabled from '../images/btn-yes-disabled.png'
import yesButtonHover from '../images/btn-yes-hover.png'
import PDCButton from 'pdc-button'

const styles = theme => ({
    dialog: theme.confirmDialog.paper,
    dialogTitle: theme.confirmDialog.dialogTitle,
    dialogContent: theme.confirmDialog.dialogContent,
    dialogFooter: theme.confirmDialog.dialogFooter,
    acknowledgeDelete: {
        marginTop: 25,
        '& label': {
            color: theme.confirmDialog.acknowledgeDeleteColor,
            fontSize: 16,
            fontWeight: 500,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            margin: 0
        }
    },
    rejectButton: {
        cursor: 'pointer',
        width: 'fit-content',
        '&:not(:hover)': {
            '& .hover': {
                display: 'none'
            }
        },
        '&:hover': {
            '& .enabled': {
                display: 'none'
            }
        }
    },
    confirmButton: {
        cursor: 'pointer',
        marginLeft: '15px',
        width: 'fit-content',
        '&.disabled': {
            cursor: 'not-allowed'
        },
        '&:not(:hover), &.disabled': {
            '& .hover': {
                display: 'none'
            }
        },
        '&:not(.disabled):hover': {
            '& .enabled': {
                display: 'none'
            }
        }
    }
})

class ConfirmDeleteModal extends Component {
    constructor (props) {
        super(props)
        const ackType = ['conversation', 'message'].includes(props.itemType) ? 'conversation' : props.itemType
        let deleteAcknowledged
        try { deleteAcknowledged = localStorage[`${ackType}DeleteAcknowledged`] } catch (e) { deleteAcknowledged = true }
        this.state = {
            checkedAck: false,
            deleteAcknowledged
        }

        this.rejectButton = props.itemType === 'message'
            ? {
                enabled: noButton,
                hover: noButtonHover
            }
            : {
                enabled: cancelButton,
                hover: cancelButtonHover
            }

        this.confirmButton = props.itemType === 'message'
            ? {
                enabled: yesButton,
                disabled: yesButtonDisabled,
                hover: yesButtonHover
            }
            : {
                enabled: deleteButton,
                disabled: deleteButtonDisabled,
                hover: deleteButtonHover
            }
    }

    ackChanged = e => {
        e.persist()
        this.setState({ checkedAck: e.target.checked })
    }

    onDelete = () => {
        if (!this.state.deleteAcknowledged && !this.state.checkedAck) return
        this.props.onClose()
        if (!this.state.deleteAcknowledged) {
            try { localStorage[`${this.props.itemType}DeleteAcknowledged`] = true } catch (e) {}
            this.setState({ deleteAcknowledged: true })
        }
        this.props.onDelete(true)
    }

    render () {
        const { classes, isShown, newButtonDesigns } = this.props
        const cancelButtonProps = {
            color: newButtonDesigns ? 'tertiary' : 'tertiary',
            variant: newButtonDesigns ? 'new-primary' : 'raised'
        }
        const deleteButtonProps = {
            color: newButtonDesigns ? 'danger' : 'primary',
            variant: newButtonDesigns ? 'new-primary' : 'raised'
        }
        return (
            <Dialog
                open = {isShown}
                classes = {{ paper: `${classes.dialog} ${this.props.size || ''}` }}
            >
                <DialogTitle classes={{ root: classes.dialogTitle }} data-test-id={this.props.titleTestId}>
                    {this.state.deleteAcknowledged ? this.props.acknowledgedTitle : this.props.notAcknowledgedTitle}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <span data-test-id="confirm_delete_modal_content">
                        {this.state.deleteAcknowledged ? this.props.acknowledgedMainContent : this.props.notAcknowledgedMainContent}
                    </span>
                    {!this.state.deleteAcknowledged
                        ? <div className={classes.acknowledgeDelete}>
                            <input
                                type = 'checkbox'
                                id = 'understand-delete'
                                name = 'understand-delete'
                                className = 'hidden'
                                onChange = {this.ackChanged}
                            />
                            <label htmlFor='understand-delete'>
                                <Checkbox checked={this.state.checkedAck}/> I understand
                            </label>
                        </div>
                        : null}
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogFooter }}>
                    <PDCButton
                        className = {classes.rejectButton}
                        onClick = {this.props.onClose}
                        data-test-id= 'cancel_button'
                        {...cancelButtonProps}
                    >
                        Cancel
                    </PDCButton>
                    <PDCButton
                        className = {`${classes.confirmButton}`}
                        onClick = {this.onDelete}
                        disabled = {!this.state.deleteAcknowledged && !this.state.checkedAck}
                        data-test-id= 'confirm_button'
                        {...deleteButtonProps}
                    >
                        Delete
                    </PDCButton>
                </DialogActions>
            </Dialog>
        )
    }
}

ConfirmDeleteModal.propTypes = {
    acknowledgedMainContent: PropTypes.string,
    acknowledgedTitle: PropTypes.string,
    classes: PropTypes.object,
    isShown: PropTypes.bool,
    itemType: PropTypes.string,
    newButtonDesigns: PropTypes.bool,
    notAcknowledgedMainContent: PropTypes.string,
    notAcknowledgedTitle: PropTypes.string,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    size: PropTypes.string,
    titleTestId: PropTypes.string
}

export default withStyles(styles)(ConfirmDeleteModal)
