import React from 'react'
import { Step as MuiStep, StepLabel, StepProps as MuiStepProps, StepContent } from '@material-ui/core'
import { CheckIcon, SoftAlertIcon } from 'svg-icons'
import Typography from 'typography'
import { theme } from 'get-theme'
import { ThemeProvider } from 'theme-provider'

interface BigStepButtonProps {
    completed: boolean;
    error: boolean;
    active: boolean;
    icon: React.ReactNode;
}
const BigStepButton = (props: BigStepButtonProps): React.ReactNode => {
    if (props.error) return <SoftAlertIcon style={{ color: theme.palette.error.main, fontSize: 30 }}/>
    if (props.completed && !props.active) return <div><CheckIcon style={{ fontSize: 13 }}/></div>
    const typographyVariant = props.active ? 'avatarLetter' : 'inputText'
    return <div><Typography variant={typographyVariant}>{props.icon}</Typography></div>
}

const SmallStepButton = (): React.ReactNode => <div/>

/***/
export enum Variant {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DONE = 'done',
    ERROR = 'error'
}

interface StepProps extends MuiStepProps {
    /**
     * Main text below the step icon
     */
    title?: string;
    /**
     * Secondary / alternative text below the main text
     */
    secondaryTitle?: string;
    /**
     * The variant of the step item
     */
    variant?: Variant.INACTIVE | Variant.ACTIVE | Variant.DONE | Variant.ERROR;
    /**
     * Is it small / mobile view
     */
    smallView?: boolean;
    /**
     * Is the step clickable
     */
    clickable?: boolean;
    /**
     * Called on click on a step if the step is clickable
     */
    onClick?: () => void;
    content: React.ReactNode;
}

/**
 * Step component
 *
 * @param {StepProps} props - props
 */
export const Step = (props: StepProps): JSX.Element => {
    const { title, secondaryTitle, variant, clickable, smallView, onClick, content } = props
    const isActive = variant === Variant.ACTIVE
    const hasError = variant === Variant.ERROR
    const isDone = variant === Variant.DONE
    const clickableClass = clickable ? 'clickable' : ''
    const smallClass = smallView ? 'mobile' : ''
    const stepLabelClassNames = `${variant} ${clickableClass} ${smallClass}`
    return (
        <ThemeProvider>
            <MuiStep
                {...props}
                onClick = {clickable ? onClick : null}
                classes = {{ root: smallView ? 'mobile' : '' }}
            >
                <StepLabel
                    classes = {{ root: stepLabelClassNames, vertical: 'vertical', iconContainer: `icon-container ${smallView ? 'mobile' : ''}` }}
                    StepIconComponent = {smallView ? SmallStepButton : BigStepButton}
                    error = {hasError}
                    completed = {isDone}
                    active = {isActive}
                >
                    {!smallView
                        ? <>
                            <div className='primary-title'><Typography variant='subtitle3'>{title}</Typography></div>
                            {secondaryTitle ? <div className='secondary-title'><Typography variant='helperText'>{secondaryTitle}</Typography></div> : null}
                        </>
                        : <Typography variant='subtitle1'>{title}</Typography>
                    }
                </StepLabel>
                {content ? <StepContent expanded>{content}</StepContent> : null}
            </MuiStep>
        </ThemeProvider>
    )
}

const defaultStepProps = {
    title: null,
    secondaryTitle: null,
    variant: Variant.INACTIVE,
    smallView: false,
    clickable: false,
    onClick: null,
    content: null
}

Step.defaultProps = defaultStepProps

export default Step
