import l from '../lang';

export default {
	'routes': {
		'create': 'live-answer.create',
		'show': 'live-answer.show',
		'index': 'live-answer.index',
	},
	'delete-single': l.t('live-answer.delete-single', 'Are you sure you want to delete this script?'),
	'delete-filtered': l.t('live-answer.delete-filtered', 'Are you sure you want to delete all filtered scripts?'),
	'delete-all': l.t('live-answer.delete-all', 'Are you sure you want to delete all scripts?'),
	'successfull-creation': l.t('live-answer.successfully-created', 'Your script has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('live-answer.failed-creation', 'There was a problem creating your script'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('live-answer.this-script-is-currently-in-use', 'This script is currently in use'),
	'to-delete-used': l.t('live-answer.to-delete-this-script-remove-it', 'To delete this script, remove it from your call handling rules first, then try again.'),
};
