export default class FileHandler {
    constructor(url, session, options = {}) {
        this.url = url;
        this.session = session;
        this.busy = false;
        this.audio = null;
        this.downloading = false;
        this.saved_url = this.url.slice(0, 4) === 'http' ? this.url : null;
        this._playing = false;
        this.stop_right_after_it_start = false;
        this.stopped_callback = options.stopped_callback;
        this.started_callback = options.started_callback;
    }

    set playing(val) {
      this._playing = val;
      if (!val && typeof this.stopped_callback === 'function') this.stopped_callback();
      if (val && typeof this.started_callback === 'function') this.started_callback();
    }

    get playing() {
      return this._playing;
    }

    async play() {
        try {
             if (this.busy) {
               return;
             }
             if (!this.saved_url) {
                 const blob = await this.get_blob();
                 this.saved_url = URL.createObjectURL(blob);
             }
             if (!this.audio) {
               this.audio = document.createElement('audio');
               this.audio.onpause = () => {
                 this.playing = false;
                 this.stop_right_after_it_start = false;
               };
               this.audio.onplay = () => {
                this.playing = true;
                if (this.stop_right_after_it_start) {
                  setTimeout(() => {
                    this.stop();
                  }, 10);
                }
              };
             }
             this.audio.src = this.saved_url;
             const playPromise = this.audio.play();
             if (playPromise !== undefined) {
               await playPromise;
             }
             this.audio.addEventListener('ended', () => {
                 this.playing = false;
            });
        } catch (err) {
            this.playing = false;
            throw err;
        }
      }

    async download() {
      if (this.busy) {
        return;
      }
      const blob = await this.get_blob();
      const download_name = `download.${blob.type.split('/')[1]}`;
      this.saved_url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', this.saved_url);
      a.setAttribute('download', download_name);
      a.click();
    }

    async get_blob() {
      this.downloading = true;
      let blob;
      if (!this.saved_url) {
        blob = await this.session.download_item(this.url);
      } else {
        blob = await fetch(this.saved_url, {method: 'GET'});
        blob = await blob.blob();
      }
      this.downloading = false;

      return blob;
    }

      stop() {
        if (this.audio && this.playing) {
          this.audio.pause();
        } else {
          this.stop_right_after_it_start = true;
        }
        this.playing = false;
      }

      toggle_play() {
        if (!this.playing) return this.play();
        return this.stop();
      }

      revoke_urls() {
        if (this.audio) {
          this.audio.pause();
        }
        if (this.saved_url && this.saved_url.slice(0, 4) === 'blob') {
          URL.revokeObjectURL(this.saved_url);
          this.saved_url = null;
        }
      }
}
