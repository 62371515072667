import { theme } from 'get-theme'
const mainTheme = theme

/**
 * These are the styles for the Menu component
 *
 * @param {object} contextTheme - passed by the theme provider
 */
const styles = contextTheme => {
    const theme = (contextTheme.palette.secondary['-300']) ? contextTheme : mainTheme
    return {
        errorChip: {
            background: `${theme.palette.error.background} !important`,
            '& svg': {
                color: `${theme.palette.error.main} !important`
            }
        }
    }
}

export default styles
