/**
 * @param {object} theme
 */
const styles = theme => ({
    loadingDiv: theme.loadingDiv,
    businessHours: {
        position: 'relative',
        overflowY: 'auto',
        height: 'calc(100% - 60px)',
        display: 'flex',
        flexDirection: 'column',
        '&.small': {
            height: 'calc(100% - 50px)'
        }
    },
    settingWrapper: {
        ...theme.personalSettingsApp.settingWrapper,
        padding: '48px 50px 100px',
        flexDirection: 'column',
        height: '100%',
        '&.hidden': {
            display: 'none'
        },
        '& .section': {
            '& > .title': {
                display: 'flex',
                slignItems: 'center',
                marginBottom: 6,
                '& > span': {
                    fontFamily: 'Montserrat-semi-bold',
                    color: 'black',
                    fontSize: 18,
                    lineHeight: '26px',
                    marginRight: 8
                },
                '& > svg': {
                    color: theme.palette.tertiary[-100],
                    marginLeft: 9
                }
            },
            '&.business-hours-section': {
                '& .office-hours-options-wrapper': {
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 16,
                    marginBottom: 42,
                    '& .office-hours-option': {
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& .office-hours-option-text': {
                            '& .main-text': {
                                // display: 'flex',
                                // alignItems: 'center',
                                fontFamily: 'Montserrat-semi-bold',
                                fontSize: 14,
                                fontWeight: 600,
                                lineHeight: '21px',
                                marginTop: 3,
                                marginBottom: 5
                            },
                            '& .description': {
                                fontFamily: 'Montserrat-medium',
                                fontSize: 12,
                                fontWeight: 500,
                                lineHeight: '19px',
                                letterSpacing: 0.05,
                                color: theme.palette.secondary['+200']
                            }
                        }
                    }
                },
                '& .custom-office-hours-subsection': {
                    marginBottom: 50,
                    '& .title': {
                        fontSize: 16,
                        paddingLeft: 18
                    }
                }
            },
            '&.holidays-section, &.custom-holidays-section': {
                '& .title': {
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Montserrat-bold !important',
                    fontSize: 16,
                    fontWeight: 'bold',
                    lineHeight: '24px',
                    marginTop: 20,
                    paddingLeft: 25,
                    '& .holidays-switch': {
                        marginLeft: 28
                    }
                },
                '& .add-custom-holiday-wrapper > div': {
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    color: theme.palette.primary[0],
                    marginTop: 34,
                    width: 'fit-content',
                    '&:hover': {
                        '& svg.basic-icon': {
                            display: 'none'
                        }
                    },
                    '&:not(:hover)': {
                        '& svg.hover-icon': {
                            display: 'none'
                        }
                    },
                    '& .add-custom-holiday-button': {
                        marginRight: 9
                    },
                    '& svg': {
                        color: theme.palette.primary[0]
                    },
                    '& > span': {
                        fontFamily: 'Montserrat-semi-bold',
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: '21px'
                    }
                }
            },
            '& .section-heading': {
                background: theme.palette.secondary[-100],
                color: 'white',
                padding: '16px 25px',
                fontFamily: 'Montserrat-medium',
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: '18px',
                textTransform: 'uppercase',
                borderRadius: 2
            }
        },
        '&.mobile': {
            paddingLeft: 25,
            paddingRight: 25
        },
        '&.company-setup': {
            padding: 0,
            '& .section': {
                '&.business-hours-section': {
                    '& .office-hours-options-wrapper': {
                        marginBottom: 30,
                        '& .office-hours-option': {
                            '& .office-hours-option-text': {
                                '& .main-text': {
                                    marginTop: 0,
                                    '& p': {
                                        color: theme.palette.info.dark
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    checkbox: {
        marginRight: 6
    },
    radio: {
        marginRight: 8
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 25px',
        marginBottom: 30,
        borderRadius: 10,
        minWidth: 'fit-content',
        '& .table-row': {
            height: 80,
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.secondary[-300]}`,
            minWidth: 'fit-content',
            width: '100%',
            '&.lg': {
                height: 90
            },
            '&:first-child': {
                borderTop: `1px solid ${theme.palette.secondary[-300]}`
            },
            '& .column-name': {
                display: 'flex',
                alignItems: 'center',
                minWidth: 194,
                maxWidth: 215,
                fontSize: 14,
                fontWeight: 600,
                marginRight: 70,
                letterSpacing: 0.1,
                '&.editable': {
                    minWidth: 225,
                    maxWidth: 225,
                    marginRight: 15,
                    '& .custom-holiday-text-field': {
                        width: '100%'
                    }
                },
                '& > svg': {
                    color: theme.palette.tertiary[-100]
                },
                '&.lunch span': {
                    marginRight: 8
                }
            },
            '& .new-line': {
                width: '100%'
            },
            '& .date-column': {
                display: 'flex',
                alignItems: 'center',
                marginRight: 25,
                height: 51,
                '& .picker-wrapper': {
                    width: 150,
                    height: '100%',
                    '& .MuiTextField-root': {
                        height: '100%',
                        '& .MuiInputBase-root': {
                            width: '100%',
                            height: '100%',
                            '& input': {
                                height: '100%'
                            }
                        }
                    }
                },
                '& .date-picker': {
                    padding: 5,
                    borderRadius: '50%',
                    cursor: 'pointer',
                    '&:hover': {
                        background: theme.palette.primary[-400]
                    }
                },
                '& .date-string': {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '21px',
                    fontFamily: 'Montserrat-medium',
                    marginLeft: 10
                }
            },
            '& .column-content': {
                display: 'flex',
                alignItems: 'center'
            },
            '& .select-wrapper': {
                '& [class*="MuiInputBase-root"]': {
                    width: 125
                },
                '&.md [class*="MuiInputBase-root"]': {
                    width: 145
                },
                '&.lg [class*="MuiInputBase-root"]': {
                    width: 160
                },
                '&.xlg [class*="MuiInputBase-root"]': {
                    width: 200
                },
                '&.single-menu [class*="MuiInputBase-root"]': {
                    marginRight: 25
                },
                '& > div:first-child [class*="MuiInputBase-root"]': {
                    background: theme.palette.secondary[-500]
                }
            },
            '& .to-span': {
                margin: '0 10px'
            },
            '& .remove-custom-holiday-button': {
                padding: 12,
                borderRadius: '50%',
                marginLeft: 'auto',
                cursor: 'pointer',
                '&:hover': {
                    background: theme.palette.secondary[-400]
                },
                '& svg': {
                    color: theme.palette.secondary[0]
                }
            }
        },
        '&.mobile': {
            padding: 0,
            '& .table-row': {
                flexWrap: 'wrap',
                height: 'fit-content !important',
                rowGap: 5,
                padding: '15px 0',
                '& .select-wrapper': {
                    '& [class*="MuiInputBase-root"]': {
                        width: 112
                    }
                }
            }
        },
        '&.company-setup': {
            rowGap: 20,
            '& .table-row': {
                height: 48,
                borderBottom: 'none',
                '&:first-child': {
                    borderTop: 'none'
                },
                '& .column-name': {
                    minWidth: 84,
                    marginRight: 40
                }
            },
            '&.mobile': {
                rowGap: 30,
                '& .table-row': {
                    padding: 0
                }
            }
        }
    },
    footer: {
        height: 83,
        background: theme.palette.secondary[-500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: `2px solid ${theme.palette.secondary[-300]}`,
        '& button': {
            width: 150
        }
    },
    selectItemsWrapper: {
        maxHeight: 220,
        overflowY: 'auto'
    }
})

export default styles
