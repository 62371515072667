import l from '../lang';
import csv_helpers from './csv-helpers';
import CSVTransformer from './CSVTransformer';
import CONTACT_HEADERS from './contact-headers.json';
import LaProCSVTransformer from './LaProCSVTransformer';
import ValidationException from '../validation/exception';

export default class ContactsCSVTransformer extends CSVTransformer {
	constructor(session, file, extension) {
		const headers = csv_helpers.take_clean_headers(file);
		let valid_headers = false;
		let branding = null;
		for (const brand of Object.keys(CONTACT_HEADERS)) {
			const conf = CONTACT_HEADERS[brand];
			const keys = Object.keys(conf);
			if (keys.length === headers.length) {
				let is_header_valid = true;
				for (let i = 0; i < headers.length; i++) {
					if (headers[i] !== keys[i]) {
						is_header_valid = false;
						break;
					}
				}
				if (is_header_valid) {
					valid_headers = conf;
					branding = brand;
					break;
				}
			}
		}
		if (!valid_headers) {
	        throw new ValidationException(l.t('contacts.your-csv-unsupported-format', 'Your .csv file has unsupported format.'));
		}

		super(session, file, valid_headers);
		this.extension = extension;
		if (branding === 'lapro') {
			this.setup = new LaProCSVTransformer(extension);
		}
	}

	async create_contacts_objects(contacts) {
		let new_contacts = [];
		const groups = {};
		for (const contact of contacts) {
			const new_contact = {
				emails: [],
				addresses: [],
				phone_numbers: [],
			};
			const phonedotcom_headers = Object.values(CONTACT_HEADERS.default);
			for (const key of phonedotcom_headers) {
				if (key.startsWith('email_') && contact[key]) {
					const type = key.split('_')[1];
					new_contact.emails.push({
						type,
						email: contact[key],
					});
				} else if (
					key.startsWith('phone_number_')
					&& contact[key]
				) {
					const type = key.split('_')[2];
					new_contact.phone_numbers.push({
						type,
						number: contact[key],
					});
				} else if (key.startsWith('address_') && contact[key]) {
					const type = key.split('_')[1];
					// contact.functions => street, street2, city, state, zip, country
					const addressParts = contact[key].split('/-/');
					new_contact.addresses.push({
						type,
						street: addressParts[0],
						street2: addressParts[1],
						city: addressParts[2],
						state: addressParts[3],
						zip: addressParts[4],
						country: addressParts[5],
					});
				} else if (key === 'group' && contact[key]) {
					if (groups[contact[key]]) {
						new_contact.group = groups[contact[key]];
					} else {
						let group = await this.session.get_list(
							`/extensions/${this.extension}/contact-groups?filters[name]=${contact[key]}`,
							1,
						);
						if (group.items.length) {
							group = group.items[0];
							groups[group.name] = group;
						} else {
							group = { 'name': contact[key] };
						}
						new_contact.group = group;
					}
				} else {
					new_contact[key] = contact[key];
				}
			}
			new_contacts.push(new_contact);
		}
		new_contacts = new_contacts.map((x) => {
			if (x.group === '') x.group = null;
			return x;
		});
		if (this.setup && this.setup.contacts_callback) {
			new_contacts = this.setup.contacts_callback(new_contacts);
		}
		return new_contacts;
	}
}
