<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M17.6498 6.35C16.1998 4.9 14.2098 4 11.9998 4C7.57977 4 4.00977 7.58 4.00977 12C4.00977 16.42 7.57977 20 11.9998 20C15.6169 20 18.6509 17.602 19.6429 14.3108C19.69 14.1543 19.5705 14 19.4071 14H17.8268C17.7207 14 17.6267 14.0672 17.5885 14.1662C16.7232 16.409 14.5473 18 11.9998 18C8.68977 18 5.99977 15.31 5.99977 12C5.99977 8.69 8.68977 6 11.9998 6C13.6598 6 15.1398 6.69 16.2198 7.78L13.4265 10.5732C13.2691 10.7307 13.3806 11 13.6033 11H19.7498C19.8878 11 19.9998 10.8881 19.9998 10.75V4.60355C19.9998 4.38083 19.7305 4.26929 19.573 4.42678L17.6498 6.35Z" fill="currentColor"/>
	</svg>

</template>

<script>
  export default {};
</script>
