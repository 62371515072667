import React from 'react'
import App from './src/components/App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'
import PropTypes from 'prop-types'
import withTheme from '@material-ui/core/styles/withTheme'

const store = createStore(rootReducer)

class Users extends React.Component {
    render = () => {
        return (
            <Provider store={store}>
                <App
                    isOffline={this.props.isOffline}
                    extension={this.props.extension}
                    contactsUtil={this.props.contactsUtil}
                    screenViewType={this.props.screenViewType}
                    standalone={this.props.standalone}
                    onLoaded={this.props.onLoaded}
                    subscribeForNotifications={this.props.subscribeForNotifications}
                    resetSubscription={this.props.resetSubscription}
                    updateUnreadCounts={this.props.updateUnreadCounts}
                    changeVoicemailReadStatus={this.props.changeVoicemailReadStatus}
                    redirect={this.props.redirect}
                    setIsDialerOpen={this.props.setIsDialerOpen}
                    appData={this.props.appData}
                    setHasChange={this.props.setHasChange}
                    routeProps={this.props.routeProps}
                    companyName={this.props.companyName}
                    isCSR={this.props.isCSR}
                />
            </Provider>
        )
    }
}

Users.propTypes = {
    isOffline: PropTypes.bool,
    contactsUtil: PropTypes.func,
    screenViewType: PropTypes.string,
    standalone: PropTypes.bool,
    onLoaded: PropTypes.func,
    subscribeForNotifications: PropTypes.func,
    resetSubscription: PropTypes.func,
    updateUnreadCounts: PropTypes.func,
    changeVoicemailReadStatus: PropTypes.func,
    redirect: PropTypes.func,
    setIsDialerOpen: PropTypes.func,
    appData: PropTypes.any,
    companyName: PropTypes.string,
    setHasChange: PropTypes.func,
    users: PropTypes.array,
    extension: PropTypes.object,
    routeProps: PropTypes.object,
    isCSR: PropTypes.bool
}

export default withTheme(Users)
