/**
 * @param {object} theme
 */
const styles = theme => ({
    audioUploadedWrapper: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        width: 300,
        '& .audio-uploader': {
            border: '1px solid lightgray',
            borderRadius: 6,
            width: 550,
            '&.error': {
                border: `2px solid ${theme.palette.error.main}`
            }
        }
    },
    uploadSection: {
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            marginTop: -10
        }
    },
    uploadWrapper: {
        display: 'flex',
        columnGap: 15,
        '&.dragging': {
            outline: `5px dashed ${theme.palette.primary.light}bd`
        },
        cursor: 'pointer',
        maxWidth: 350,
        width: 'fit-content',
        '& > svg': {
            fontSize: 86,
            color: theme.palette.primary.main
        },
        '& .info-texts': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 5
        }
    },
    audioPlayerContainer: {
        width: '100%'
    },
    fileSection: {},
    selectButtonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    uploadFileButton: {
        padding: 13,
        border: `1px solid ${theme.palette.primary.main}`,
        margin: 0,
        color: theme.palette.primary.main,
        fontSize: 16,
        cursor: 'pointer',
        borderTopLeftRadius: 6,
        marginLeft: -1,
        marginTop: -1,
        marginBottom: -1,
        borderColor: 'lightgray',
        '&:hover': {
            color: 'white',
            background: theme.palette.primary.main
        },
        '&.error': {
            borderTopColor: '#EA0303',
            borderLeftColor: '#EA0303'
        }
    },
    uploadedFileInfo: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        '& .filename': {
            fontWeight: 'bold'
        }
    },
    infoSection: {
        padding: '8px 0',
        color: theme.palette.text.primary,
        display: 'flex'
    },
    infoIcon: {
        marginRight: 5
    },
    removeButton: {
        cursor: 'pointer',
        color: '#ff000077',
        '&:hover': {
            color: '#ff0000'
        }
    },
    promptErrorMessage: {
        display: 'flex',
        flexDirection: 'column',
        '& .gap': {
            marginBottom: 20
        }
    }
})

export default styles
