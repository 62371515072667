import React, { Component } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import { Stepper, StepInfo, MobileVariant as MobileStepperVariant } from 'stepper'
import Typography from 'typography'
import BottomNavigation from 'bottom-navigation'

interface StepData {[key: string]: any}

/***/
export interface UpdateData {
    completed: boolean
    gotoNext?: boolean
    saveData?: StepData
    disableNextButton?: () => boolean
}

/***/
export interface StepComponentProps {
    currentData: StepData,
    update: (updateData: UpdateData) => void
    saveId: number
    stepIndex: number
}

interface RequestStep {
    id: string | number
    label: string
    secondaryLabel: string | Element
    title: string | Element
    secondaryTitle: string | Element
    /*
     * `component` properties: StepComponentProps
     * The `component` may then show validation errors, or may call the `update` prop function in order to tell to the wizard to go forward.
     *
     * Note that you should keep the steps / components' states somewhere (like redux). The wizard doesn't do that.
    */
    component: any, // TODO: It shouldn't be any
    bottomNavigationText: string
    /**
     * Data which gets passed in each step component
     */
    // eslint-disable-next-line
    passData: any
    additionalProps: Record<string, unknown>
    disableNextButton: () => boolean
    customBackButtonActionOnFirstStep?: () => void
}

/***/
export interface StepsSaveData {[stepId: string]: StepData}

interface Props {
    // Material ui classes
    classes
    /**
     * Array with objects: { id, label, secondaryLabel, title, secondaryTitle, component, bottomNavigationText, passData }
     */
    steps: RequestStep[]
    /**
     * Is it mobile view
     */
    smallView: boolean
    /**
     * Class name to be added to the base element
     */
    className: string
    /**
     * Called when the user clicks on submit on the last step if the last step is completed
     */
    onSubmit: (stepsSaveData: StepsSaveData) => void
    /**
     * Any extra data which will be included in the passData
     */
    // eslint-disable-next-line
    extraData: any
    /**
     *  onBackClick should go directly without updating saveId (e.g. to bypass validation)
     */
    noSaveIdForBackClick: boolean
    /**
     *  If update is called with completed:false and this flag is true then it will mark all next steps as uncompleted too
     */
    disallowCompleteGaps?: boolean
    enableBackButtonOnFirstStep?: boolean
}

interface State {
    activeStepId: string
    nextDisabled: boolean,
    completedIds: (string | number)[]
    saveId: string
    stepsSaveData: StepsSaveData
    gotoStepIndex: string | number
}

class StepWizard extends Component<Props, State> {
    noSaveIdForBackClick: boolean
    constructor (props) {
        super(props)
        const stepsSaveData: StepsSaveData = props.steps.reduce((data, step) => {
            data[step.id] = null
            return data
        }, {})
        this.state = {
            activeStepId: props.steps[0].id,
            nextDisabled: false,
            completedIds: [],
            saveId: null,
            stepsSaveData,
            gotoStepIndex: null
        }
    }

    // Called on step click
    // onStepClick = stepId => (this.state.completedIds.includes(stepId)) ? this.gotoStep(stepId) : null
    onStepClick = stepId => {
        if (!this.state.completedIds.includes(stepId)) return
        const { steps } = this.props
        const gotoStepIndex = steps.findIndex(s => s.id === stepId)
        this.setState({ saveId: `${Math.random()}#${gotoStepIndex}`, gotoStepIndex })
    }

    // Go to the step with id `stepId` if it isn't the current one
    gotoStep = stepId => {
        const { activeStepId } = this.state
        if (activeStepId === stepId) return
        this.setState({ activeStepId: stepId, saveId: null })
    }

    renderStepper = () => {
        const { smallView } = this.props
        const { completedIds, activeStepId } = this.state
        const steps = [...this.props.steps].map((s, i) => {
            const step: StepInfo = {
                ...s,
                done: completedIds.includes(s.id),
                active: s.id === activeStepId,
                error: false,
                title: s.label,
                secondaryTitle: s.secondaryLabel
            }
            return step
        })
        return (
            <div className='stepper-wrapper'>
                <Stepper
                    steps = {steps}
                    mobileVariant = {MobileStepperVariant.DOTS}
                    onStepClick = {this.onStepClick}
                    onBackClick = {this.onBackClick}
                    onNextClick = {this.onNextClick}
                    smallView = {smallView}
                />
            </div>
        )
    }

    updateStepState = (info: UpdateData) => {
        const { activeStepId, stepsSaveData } = this.state
        const completedIds = [...this.state.completedIds]
        const activeCompletedStepIndex = completedIds.findIndex(i => i === activeStepId)
        if (info.completed) {
            if (!completedIds.includes(activeStepId)) completedIds.push(activeStepId)
        } else if (activeCompletedStepIndex !== -1) {
            completedIds.splice(activeCompletedStepIndex, this.props.disallowCompleteGaps ? completedIds.length : 1)
        }
        stepsSaveData[activeStepId] = info.saveData
        if ('disableNextButton' in info) this.setState({ nextDisabled: info.disableNextButton() })
        this.setState({ completedIds, stepsSaveData }, () => {
            if (!('gotoNext' in info)) return
            if (info.gotoNext) this.switchStep()
            else this.setState({ gotoStepIndex: null })
        })
    }

    switchStep = (): void => {
        const { steps } = this.props
        const { activeStepId } = this.state
        const currentStepIndex = steps.findIndex(s => s.id === activeStepId)
        let { gotoStepIndex } = this.state
        gotoStepIndex = (gotoStepIndex || gotoStepIndex === 0) ? gotoStepIndex : (currentStepIndex === steps.length - 1 ? 'submit' : currentStepIndex + 1)
        if (gotoStepIndex === 'submit') return this.props.onSubmit(this.state.stepsSaveData)
        const nextStep = steps[gotoStepIndex]
        this.gotoStep(nextStep.id)
    }

    renderContent = () => {
        const { steps, smallView, extraData } = this.props
        const { activeStepId, saveId, stepsSaveData, gotoStepIndex } = this.state
        const currentStep = steps.find(s => s.id === activeStepId)
        let content = null
        let title = ''
        let subtitle = ''
        if (currentStep) {
            title = currentStep.title
            subtitle = currentStep.secondaryTitle
            const StepComponent = currentStep.component
            content = (
                <StepComponent
                    passData = {{ ...stepsSaveData, extraData }}
                    update = {this.updateStepState}
                    saveId = {saveId}
                    smallView = {smallView}
                    stepIndex = {steps.findIndex(s => s.id === activeStepId)}
                    gotoStepIndex = {gotoStepIndex}
                    {...currentStep.additionalProps}
                />
            )
        }
        const titleTypography = smallView ? 'h5' : 'h4'
        // const subtitleTypography = smallView ? 'body1' : 'body2'
        return (
            <div id='step-wizard-content-root' className='content'>
                <div className='titles'>
                    <div className='title'><Typography variant={titleTypography}>{title}</Typography></div>
                    <div className='subtitle'><Typography variant='body2'>{subtitle}</Typography></div>
                </div>
                {content}
            </div>
        )
    }

    onBackClick = () => {
        const { steps } = this.props
        const { activeStepId } = this.state
        const currentStepIndex = steps.findIndex(s => s.id === activeStepId)
        if (currentStepIndex === 0) return
        const gotoStepIndex = currentStepIndex - 1
        if (this.props.noSaveIdForBackClick) {
            this.setState({ gotoStepIndex }, this.switchStep)
        } else {
            this.setState({ saveId: `${Math.random()}#${gotoStepIndex}`, gotoStepIndex })
        }
    }

    onNextClick = () => {
        const { steps } = this.props
        const { activeStepId } = this.state
        const currentStepIndex = steps.findIndex(s => s.id === activeStepId)
        const gotoStepIndex = currentStepIndex + 1 === steps.length ? 'submit' : currentStepIndex + 1
        this.setState({ saveId: `${Math.random()}#${gotoStepIndex}`, gotoStepIndex })
    }

    renderFooter = () => {
        const { steps, smallView } = this.props
        const { activeStepId, nextDisabled } = this.state
        const currentStepIndex = steps.findIndex(s => s.id === activeStepId)
        const currentStep = steps[currentStepIndex]
        const bottomNavigationText = currentStep.bottomNavigationText
        const customBackButtonActionOnFirstStep = currentStep.customBackButtonActionOnFirstStep
        const nextButtonText = currentStepIndex === (steps.length - 1) ? 'Submit' : 'Next'
        return (
            <BottomNavigation
                backDisabled = {currentStepIndex === 0 && !this.props.enableBackButtonOnFirstStep}
                hideBackButton = {currentStepIndex === 0 && !this.props.enableBackButtonOnFirstStep}
                nextDisabled = {nextDisabled}
                nextButtonText = {nextButtonText}
                nextButtonDataTestId = {nextButtonText === 'Submit' ? 'bottom-navigation-submit-button' : null}
                noIconInNextButton = {currentStepIndex === (steps.length - 1)}
                description = {bottomNavigationText || ''}
                onBackClick = {customBackButtonActionOnFirstStep || this.onBackClick}
                onNextClick = {this.onNextClick}
                smallView = {smallView}
                className = 'bottom-navigation'
            />
        )
    }

    render = () => {
        const { classes, smallView, className } = this.props
        let classNames = `${classes.wrapper} ${smallView ? 'mobile' : ''}`
        if (className) classNames += ` ${className}`
        return (
            <div className={classNames}>
                {this.renderStepper()}
                {this.renderContent()}
                {this.renderFooter()}
            </div>
        )
    }
}

StepWizard.defaultProps = {
    smallView: false
}

export default withStyles(styles)(StepWizard)
