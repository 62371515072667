<template>
  <v-select
    v-bind="$attrs"
    color="primary"
    v-on="$listeners"
    :append-icon="$attrs.disabled ? '' : '$vuetify.icons.dropdown_arrow_black'"
    @input="input"
    class="custom-select"
    hide-details="auto"
    >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
  </v-select>
</template>
<script>
import EventBus from 'event-bus/EventBus';

export default {
  methods: {
    input() {
      EventBus.$emit('form_input_changed');
    }
  },
};
</script>
<style lang="scss">
  .custom-select {
    padding: 0;
    border: 0;
    .v-input__icon--append {
      margin-right: 9px;
    }
  }
</style>
