/**
 * @param {object} theme
 */
const styles = theme => ({
    recordInfo: {
        marginLeft: 10
    },
    removeButton: {
        cursor: 'pointer',
        marginLeft: 10,
        color: '#ff000077',
        '&:hover': {
            color: '#ff0000'
        }
    },
    audioPlayerWrapper: {
        width: 300
    },
    recordSection: {
        display: 'flex',
        alignItems: 'center'
    },
    audioSection: {
        display: 'flex',
        alignItems: 'center'
    },
    loadingBar: {
        width: 300
    },
    actionButton: {
        padding: 8,
        backgroundColor: 'white',
        borderRadius: '50%',
        minWidth: 62,
        minHeight: 62,
        width: 62,
        height: 62,
        cursor: 'pointer',
        transition: 'background .5s',
        '&:hover': {
            backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.background}, ${theme.palette.paper.green})`,
            '& > div': {
                backgroundColor: `${theme.palette.primary.main}61`,
                '& > svg': {
                    color: theme.palette.info.textDark
                }
            },
            '&.recording': {
                backgroundImage: `linear-gradient(180deg, ${theme.palette.error.background}, ${theme.palette.action.errorFocus})`,
                '& > div': {
                    backgroundColor: theme.palette.error.border,
                    '& > svg': {
                        color: theme.palette.error.dark
                    }
                }
            }
        },
        '&:active': {
            backgroundImage: `linear-gradient(180deg, #B1D7A9, ${theme.palette.primary.background})`,
            '&.recording': {
                backgroundImage: `linear-gradient(180deg, ${theme.palette.error.border}, ${theme.palette.error.background}) !important`
            }
        },
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.background,
            transition: 'background .5s',
            '& > svg': {
                color: theme.palette.info.dark,
                fontSize: 26,
                transition: 'color .5s'
            }
        },
        '&.recording': {
            '& > div': {
                backgroundColor: theme.palette.error.background,
                '& > svg': {
                    color: theme.palette.error.main
                }
            }
        }
    }
})

export default styles
