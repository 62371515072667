const mappedUSATimezones = {
    'Eastern-New_York': [
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay'
    ],
    'Central-Chicago': [
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah'
    ],
    'Mountain-Phoenix': [
        'America/Denver',
        'America/Boise',
        'America/Phoenix'
    ],
    'Pacific-Los_Angeles': [
        'America/Los_Angeles'
    ],
    'Alaska-Anchorage': [
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome'
    ],
    'Hawaii-Honolulu': [
        'America/Adak',
        'Pacific/Honolulu'
    ]
}

const timezoneMenuItems = [
    {
        value: 'Eastern-New_York',
        content: 'Eastern - New York'
    },
    {
        value: 'Central-Chicago',
        content: 'Central - Chicago'
    },
    {
        value: 'Mountain-Phoenix',
        content: 'Mountain - Phoenix'
    },
    {
        value: 'Pacific-Los_Angeles',
        content: 'Pacific - Los Angeles'
    },
    {
        value: 'Alaska-Anchorage',
        content: 'Alaska - Anchorage'
    },
    {
        value: 'Hawaii-Honolulu',
        content: 'Hawaii - Honolulu'
    },
    { value: 'a', content: 'a', divider: true },
    {
        value: 'America/New_York',
        content: 'America/New_York'
    },
    {
        value: 'America/Detroit',
        content: 'America/Detroit'
    },
    {
        value: 'America/Kentucky/Louisville',
        content: 'America/Kentucky/Louisville'
    },
    {
        value: 'America/Kentucky/Monticello',
        content: 'America/Kentucky/Monticello'
    },
    {
        value: 'America/Indiana/Indianapolis',
        content: 'America/Indiana/Indianapolis'
    },
    {
        value: 'America/Indiana/Vincennes',
        content: 'America/Indiana/Vincennes'
    },
    {
        value: 'America/Indiana/Winamac',
        content: 'America/Indiana/Winamac'
    },
    {
        value: 'America/Indiana/Marengo',
        content: 'America/Indiana/Marengo'
    },
    {
        value: 'America/Indiana/Petersburg',
        content: 'America/Indiana/Petersburg'
    },
    {
        value: 'America/Indiana/Vevay',
        content: 'America/Indiana/Vevay'
    },
    {
        value: 'America/Chicago',
        content: 'America/Chicago'
    },
    {
        value: 'America/Indiana/Tell_City',
        content: 'America/Indiana/Tell_City'
    },
    {
        value: 'America/Indiana/Knox',
        content: 'America/Indiana/Knox'
    },
    {
        value: 'America/Menominee',
        content: 'America/Menominee'
    },
    {
        value: 'America/North_Dakota/Center',
        content: 'America/North_Dakota/Center'
    },
    {
        value: 'America/North_Dakota/New_Salem',
        content: 'America/North_Dakota/New_Salem'
    },
    {
        value: 'America/North_Dakota/Beulah',
        content: 'America/North_Dakota/Beulah'
    },
    {
        value: 'America/Denver',
        content: 'America/Denver'
    },
    {
        value: 'America/Boise',
        content: 'America/Boise'
    },
    {
        value: 'America/Phoenix',
        content: 'America/Phoenix'
    },
    {
        value: 'America/Los_Angeles',
        content: 'America/Los_Angeles'
    },
    {
        value: 'America/Anchorage',
        content: 'America/Anchorage'
    },
    {
        value: 'America/Juneau',
        content: 'America/Juneau'
    },
    {
        value: 'America/Sitka',
        content: 'America/Sitka'
    },
    {
        value: 'America/Metlakatla',
        content: 'America/Metlakatla'
    },
    {
        value: 'America/Yakutat',
        content: 'America/Yakutat'
    },
    {
        value: 'America/Nome',
        content: 'America/Nome'
    },
    {
        value: 'America/Adak',
        content: 'America/Adak'
    },
    {
        value: 'Pacific/Honolulu',
        content: 'Pacific/Honolulu'
    },
    {
        value: 'America/Montevideo',
        content: 'America/Montevideo'
    },
    {
        value: 'Europe/Andorra',
        content: 'Europe/Andorra'
    },
    {
        value: 'Asia/Dubai',
        content: 'Asia/Dubai'
    },
    {
        value: 'Asia/Kabul',
        content: 'Asia/Kabul'
    },
    {
        value: 'Europe/Tirane',
        content: 'Europe/Tirane'
    },
    {
        value: 'Asia/Yerevan',
        content: 'Asia/Yerevan'
    },
    {
        value: 'Antarctica/Casey',
        content: 'Antarctica/Casey'
    },
    {
        value: 'Antarctica/Davis',
        content: 'Antarctica/Davis'
    },
    {
        value: 'Antarctica/DumontDUrville',
        content: 'Antarctica/DumontDUrville'
    },
    {
        value: 'Antarctica/Mawson',
        content: 'Antarctica/Mawson'
    },
    {
        value: 'Antarctica/Palmer',
        content: 'Antarctica/Palmer'
    },
    {
        value: 'Antarctica/Rothera',
        content: 'Antarctica/Rothera'
    },
    {
        value: 'Antarctica/Syowa',
        content: 'Antarctica/Syowa'
    },
    {
        value: 'Antarctica/Troll',
        content: 'Antarctica/Troll'
    },
    {
        value: 'Antarctica/Vostok',
        content: 'Antarctica/Vostok'
    },
    {
        value: 'America/Argentina/Buenos_Aires',
        content: 'America/Argentina/Buenos_Aires'
    },
    {
        value: 'America/Argentina/Cordoba',
        content: 'America/Argentina/Cordoba'
    },
    {
        value: 'America/Argentina/Salta',
        content: 'America/Argentina/Salta'
    },
    {
        value: 'America/Argentina/Jujuy',
        content: 'America/Argentina/Jujuy'
    },
    {
        value: 'America/Argentina/Tucuman',
        content: 'America/Argentina/Tucuman'
    },
    {
        value: 'America/Argentina/Catamarca',
        content: 'America/Argentina/Catamarca'
    },
    {
        value: 'America/Argentina/La_Rioja',
        content: 'America/Argentina/La_Rioja'
    },
    {
        value: 'America/Argentina/San_Juan',
        content: 'America/Argentina/San_Juan'
    },
    {
        value: 'America/Argentina/Mendoza',
        content: 'America/Argentina/Mendoza'
    },
    {
        value: 'America/Argentina/San_Luis',
        content: 'America/Argentina/San_Luis'
    },
    {
        value: 'America/Argentina/Rio_Gallegos',
        content: 'America/Argentina/Rio_Gallegos'
    },
    {
        value: 'America/Argentina/Ushuaia',
        content: 'America/Argentina/Ushuaia'
    },
    {
        value: 'Pacific/Pago_Pago',
        content: 'Pacific/Pago_Pago'
    },
    {
        value: 'Europe/Vienna',
        content: 'Europe/Vienna'
    },
    {
        value: 'Australia/Lord_Howe',
        content: 'Australia/Lord_Howe'
    },
    {
        value: 'Antarctica/Macquarie',
        content: 'Antarctica/Macquarie'
    },
    {
        value: 'Australia/Hobart',
        content: 'Australia/Hobart'
    },
    {
        value: 'Australia/Currie',
        content: 'Australia/Currie'
    },
    {
        value: 'Australia/Melbourne',
        content: 'Australia/Melbourne'
    },
    {
        value: 'Australia/Sydney',
        content: 'Australia/Sydney'
    },
    {
        value: 'Australia/Broken_Hill',
        content: 'Australia/Broken_Hill'
    },
    {
        value: 'Australia/Brisbane',
        content: 'Australia/Brisbane'
    },
    {
        value: 'Australia/Lindeman',
        content: 'Australia/Lindeman'
    },
    {
        value: 'Australia/Adelaide',
        content: 'Australia/Adelaide'
    },
    {
        value: 'Australia/Darwin',
        content: 'Australia/Darwin'
    },
    {
        value: 'Australia/Perth',
        content: 'Australia/Perth'
    },
    {
        value: 'Australia/Eucla',
        content: 'Australia/Eucla'
    },
    {
        value: 'Asia/Baku',
        content: 'Asia/Baku'
    },
    {
        value: 'America/Barbados',
        content: 'America/Barbados'
    },
    {
        value: 'Asia/Dhaka',
        content: 'Asia/Dhaka'
    },
    {
        value: 'Europe/Brussels',
        content: 'Europe/Brussels'
    },
    {
        value: 'Europe/Sofia',
        content: 'Europe/Sofia'
    },
    {
        value: 'Europe/Skopje',
        content: 'Europe/Skopje'
    },
    {
        value: 'Atlantic/Bermuda',
        content: 'Atlantic/Bermuda'
    },
    {
        value: 'Asia/Brunei',
        content: 'Asia/Brunei'
    },
    {
        value: 'America/La_Paz',
        content: 'America/La_Paz'
    },
    {
        value: 'America/Noronha',
        content: 'America/Noronha'
    },
    {
        value: 'America/Belem',
        content: 'America/Belem'
    },
    {
        value: 'America/Fortaleza',
        content: 'America/Fortaleza'
    },
    {
        value: 'America/Recife',
        content: 'America/Recife'
    },
    {
        value: 'America/Araguaina',
        content: 'America/Araguaina'
    },
    {
        value: 'America/Maceio',
        content: 'America/Maceio'
    },
    {
        value: 'America/Bahia',
        content: 'America/Bahia'
    },
    {
        value: 'America/Sao_Paulo',
        content: 'America/Sao_Paulo'
    },
    {
        value: 'America/Campo_Grande',
        content: 'America/Campo_Grande'
    },
    {
        value: 'America/Cuiaba',
        content: 'America/Cuiaba'
    },
    {
        value: 'America/Santarem',
        content: 'America/Santarem'
    },
    {
        value: 'America/Porto_Velho',
        content: 'America/Porto_Velho'
    },
    {
        value: 'America/Boa_Vista',
        content: 'America/Boa_Vista'
    },
    {
        value: 'America/Manaus',
        content: 'America/Manaus'
    },
    {
        value: 'America/Eirunepe',
        content: 'America/Eirunepe'
    },
    {
        value: 'America/Rio_Branco',
        content: 'America/Rio_Branco'
    },
    {
        value: 'America/Nassau',
        content: 'America/Nassau'
    },
    {
        value: 'Asia/Thimphu',
        content: 'Asia/Thimphu'
    },
    {
        value: 'Europe/Minsk',
        content: 'Europe/Minsk'
    },
    {
        value: 'America/Belize',
        content: 'America/Belize'
    },
    {
        value: 'America/St_Johns',
        content: 'America/St_Johns'
    },
    {
        value: 'America/Halifax',
        content: 'America/Halifax'
    },
    {
        value: 'America/Glace_Bay',
        content: 'America/Glace_Bay'
    },
    {
        value: 'America/Moncton',
        content: 'America/Moncton'
    },
    {
        value: 'America/Goose_Bay',
        content: 'America/Goose_Bay'
    },
    {
        value: 'America/Blanc-Sablon',
        content: 'America/Blanc-Sablon'
    },
    {
        value: 'America/Toronto',
        content: 'America/Toronto'
    },
    {
        value: 'America/Nipigon',
        content: 'America/Nipigon'
    },
    {
        value: 'America/Thunder_Bay',
        content: 'America/Thunder_Bay'
    },
    {
        value: 'America/Iqaluit',
        content: 'America/Iqaluit'
    },
    {
        value: 'America/Pangnirtung',
        content: 'America/Pangnirtung'
    },
    {
        value: 'America/Atikokan',
        content: 'America/Atikokan'
    },
    {
        value: 'America/Winnipeg',
        content: 'America/Winnipeg'
    },
    {
        value: 'America/Rainy_River',
        content: 'America/Rainy_River'
    },
    {
        value: 'America/Resolute',
        content: 'America/Resolute'
    },
    {
        value: 'America/Rankin_Inlet',
        content: 'America/Rankin_Inlet'
    },
    {
        value: 'America/Regina',
        content: 'America/Regina'
    },
    {
        value: 'America/Swift_Current',
        content: 'America/Swift_Current'
    },
    {
        value: 'America/Edmonton',
        content: 'America/Edmonton'
    },
    {
        value: 'America/Cambridge_Bay',
        content: 'America/Cambridge_Bay'
    },
    {
        value: 'America/Yellowknife',
        content: 'America/Yellowknife'
    },
    {
        value: 'America/Inuvik',
        content: 'America/Inuvik'
    },
    {
        value: 'America/Creston',
        content: 'America/Creston'
    },
    {
        value: 'America/Dawson_Creek',
        content: 'America/Dawson_Creek'
    },
    {
        value: 'America/Fort_Nelson',
        content: 'America/Fort_Nelson'
    },
    {
        value: 'America/Vancouver',
        content: 'America/Vancouver'
    },
    {
        value: 'America/Whitehorse',
        content: 'America/Whitehorse'
    },
    {
        value: 'America/Dawson',
        content: 'America/Dawson'
    },
    {
        value: 'Indian/Cocos',
        content: 'Indian/Cocos'
    },
    {
        value: 'Europe/Zurich',
        content: 'Europe/Zurich'
    },
    {
        value: 'Africa/Abidjan',
        content: 'Africa/Abidjan'
    },
    {
        value: 'Pacific/Rarotonga',
        content: 'Pacific/Rarotonga'
    },
    {
        value: 'America/Santiago',
        content: 'America/Santiago'
    },
    {
        value: 'America/Punta_Arenas',
        content: 'America/Punta_Arenas'
    },
    {
        value: 'Pacific/Easter',
        content: 'Pacific/Easter'
    },
    {
        value: 'Asia/Shanghai',
        content: 'Asia/Shanghai'
    },
    {
        value: 'Asia/Urumqi',
        content: 'Asia/Urumqi'
    },
    {
        value: 'America/Bogota',
        content: 'America/Bogota'
    },
    {
        value: 'America/Costa_Rica',
        content: 'America/Costa_Rica'
    },
    {
        value: 'America/Havana',
        content: 'America/Havana'
    },
    {
        value: 'Atlantic/Cape_Verde',
        content: 'Atlantic/Cape_Verde'
    },
    {
        value: 'America/Curacao',
        content: 'America/Curacao'
    },
    {
        value: 'Indian/Christmas',
        content: 'Indian/Christmas'
    },
    {
        value: 'Asia/Nicosia',
        content: 'Asia/Nicosia'
    },
    {
        value: 'Asia/Famagusta',
        content: 'Asia/Famagusta'
    },
    {
        value: 'Europe/Prague',
        content: 'Europe/Prague'
    },
    {
        value: 'Europe/Berlin',
        content: 'Europe/Berlin'
    },
    {
        value: 'Europe/Copenhagen',
        content: 'Europe/Copenhagen'
    },
    {
        value: 'America/Santo_Domingo',
        content: 'America/Santo_Domingo'
    },
    {
        value: 'Africa/Algiers',
        content: 'Africa/Algiers'
    },
    {
        value: 'America/Guayaquil',
        content: 'America/Guayaquil'
    },
    {
        value: 'Pacific/Galapagos',
        content: 'Pacific/Galapagos'
    },
    {
        value: 'Europe/Tallinn',
        content: 'Europe/Tallinn'
    },
    {
        value: 'Africa/Cairo',
        content: 'Africa/Cairo'
    },
    {
        value: 'Africa/El_Aaiun',
        content: 'Africa/El_Aaiun'
    },
    {
        value: 'Europe/Madrid',
        content: 'Europe/Madrid'
    },
    {
        value: 'Africa/Ceuta',
        content: 'Africa/Ceuta'
    },
    {
        value: 'Atlantic/Canary',
        content: 'Atlantic/Canary'
    },
    {
        value: 'Europe/Helsinki',
        content: 'Europe/Helsinki'
    },
    {
        value: 'Pacific/Fiji',
        content: 'Pacific/Fiji'
    },
    {
        value: 'Atlantic/Stanley',
        content: 'Atlantic/Stanley'
    },
    {
        value: 'Pacific/Chuuk',
        content: 'Pacific/Chuuk'
    },
    {
        value: 'Pacific/Pohnpei',
        content: 'Pacific/Pohnpei'
    },
    {
        value: 'Pacific/Kosrae',
        content: 'Pacific/Kosrae'
    },
    {
        value: 'Atlantic/Faroe',
        content: 'Atlantic/Faroe'
    },
    {
        value: 'Europe/Paris',
        content: 'Europe/Paris'
    },
    {
        value: 'Europe/London',
        content: 'Europe/London'
    },
    {
        value: 'Asia/Tbilisi',
        content: 'Asia/Tbilisi'
    },
    {
        value: 'America/Cayenne',
        content: 'America/Cayenne'
    },
    {
        value: 'Africa/Accra',
        content: 'Africa/Accra'
    },
    {
        value: 'Europe/Gibraltar',
        content: 'Europe/Gibraltar'
    },
    {
        value: 'America/Godthab',
        content: 'America/Godthab'
    },
    {
        value: 'America/Danmarkshavn',
        content: 'America/Danmarkshavn'
    },
    {
        value: 'America/Scoresbysund',
        content: 'America/Scoresbysund'
    },
    {
        value: 'America/Thule',
        content: 'America/Thule'
    },
    {
        value: 'Europe/Athens',
        content: 'Europe/Athens'
    },
    {
        value: 'Atlantic/South_Georgia',
        content: 'Atlantic/South_Georgia'
    },
    {
        value: 'America/Guatemala',
        content: 'America/Guatemala'
    },
    {
        value: 'Pacific/Guam',
        content: 'Pacific/Guam'
    },
    {
        value: 'Africa/Bissau',
        content: 'Africa/Bissau'
    },
    {
        value: 'America/Guyana',
        content: 'America/Guyana'
    },
    {
        value: 'Asia/Hong_Kong',
        content: 'Asia/Hong_Kong'
    },
    {
        value: 'America/Tegucigalpa',
        content: 'America/Tegucigalpa'
    },
    {
        value: 'America/Port-au-Prince',
        content: 'America/Port-au-Prince'
    },
    {
        value: 'Europe/Budapest',
        content: 'Europe/Budapest'
    },
    {
        value: 'Asia/Jakarta',
        content: 'Asia/Jakarta'
    },
    {
        value: 'Asia/Pontianak',
        content: 'Asia/Pontianak'
    },
    {
        value: 'Asia/Makassar',
        content: 'Asia/Makassar'
    },
    {
        value: 'Asia/Jayapura',
        content: 'Asia/Jayapura'
    },
    {
        value: 'Europe/Dublin',
        content: 'Europe/Dublin'
    },
    {
        value: 'Asia/Jerusalem',
        content: 'Asia/Jerusalem'
    },
    {
        value: 'Asia/Kolkata',
        content: 'Asia/Kolkata'
    },
    {
        value: 'Indian/Chagos',
        content: 'Indian/Chagos'
    },
    {
        value: 'Asia/Baghdad',
        content: 'Asia/Baghdad'
    },
    {
        value: 'Asia/Tehran',
        content: 'Asia/Tehran'
    },
    {
        value: 'Atlantic/Reykjavik',
        content: 'Atlantic/Reykjavik'
    },
    {
        value: 'Europe/Rome',
        content: 'Europe/Rome'
    },
    {
        value: 'America/Jamaica',
        content: 'America/Jamaica'
    },
    {
        value: 'Asia/Amman',
        content: 'Asia/Amman'
    },
    {
        value: 'Asia/Tokyo',
        content: 'Asia/Tokyo'
    },
    {
        value: 'Africa/Nairobi',
        content: 'Africa/Nairobi'
    },
    {
        value: 'Asia/Bishkek',
        content: 'Asia/Bishkek'
    },
    {
        value: 'Pacific/Tarawa',
        content: 'Pacific/Tarawa'
    },
    {
        value: 'Pacific/Enderbury',
        content: 'Pacific/Enderbury'
    },
    {
        value: 'Pacific/Kiritimati',
        content: 'Pacific/Kiritimati'
    },
    {
        value: 'Asia/Pyongyang',
        content: 'Asia/Pyongyang'
    },
    {
        value: 'Asia/Seoul',
        content: 'Asia/Seoul'
    },
    {
        value: 'Asia/Almaty',
        content: 'Asia/Almaty'
    },
    {
        value: 'Asia/Qyzylorda',
        content: 'Asia/Qyzylorda'
    },
    {
        value: 'Asia/Qostanay',
        content: 'Asia/Qostanay'
    },
    {
        value: 'Asia/Aqtobe',
        content: 'Asia/Aqtobe'
    },
    {
        value: 'Asia/Aqtau',
        content: 'Asia/Aqtau'
    },
    {
        value: 'Asia/Atyrau',
        content: 'Asia/Atyrau'
    },
    {
        value: 'Asia/Oral',
        content: 'Asia/Oral'
    },
    {
        value: 'Asia/Beirut',
        content: 'Asia/Beirut'
    },
    {
        value: 'Asia/Colombo',
        content: 'Asia/Colombo'
    },
    {
        value: 'Africa/Monrovia',
        content: 'Africa/Monrovia'
    },
    {
        value: 'Europe/Vilnius',
        content: 'Europe/Vilnius'
    },
    {
        value: 'Europe/Luxembourg',
        content: 'Europe/Luxembourg'
    },
    {
        value: 'Europe/Riga',
        content: 'Europe/Riga'
    },
    {
        value: 'Africa/Tripoli',
        content: 'Africa/Tripoli'
    },
    {
        value: 'Africa/Casablanca',
        content: 'Africa/Casablanca'
    },
    {
        value: 'Europe/Monaco',
        content: 'Europe/Monaco'
    },
    {
        value: 'Europe/Chisinau',
        content: 'Europe/Chisinau'
    },
    {
        value: 'Pacific/Majuro',
        content: 'Pacific/Majuro'
    },
    {
        value: 'Pacific/Kwajalein',
        content: 'Pacific/Kwajalein'
    },
    {
        value: 'Asia/Yangon',
        content: 'Asia/Yangon'
    },
    {
        value: 'Asia/Ulaanbaatar',
        content: 'Asia/Ulaanbaatar'
    },
    {
        value: 'Asia/Hovd',
        content: 'Asia/Hovd'
    },
    {
        value: 'Asia/Choibalsan',
        content: 'Asia/Choibalsan'
    },
    {
        value: 'Asia/Macau',
        content: 'Asia/Macau'
    },
    {
        value: 'America/Martinique',
        content: 'America/Martinique'
    },
    {
        value: 'Europe/Malta',
        content: 'Europe/Malta'
    },
    {
        value: 'Indian/Mauritius',
        content: 'Indian/Mauritius'
    },
    {
        value: 'Indian/Maldives',
        content: 'Indian/Maldives'
    },
    {
        value: 'America/Mexico_City',
        content: 'America/Mexico_City'
    },
    {
        value: 'America/Cancun',
        content: 'America/Cancun'
    },
    {
        value: 'America/Merida',
        content: 'America/Merida'
    },
    {
        value: 'America/Monterrey',
        content: 'America/Monterrey'
    },
    {
        value: 'America/Matamoros',
        content: 'America/Matamoros'
    },
    {
        value: 'America/Mazatlan',
        content: 'America/Mazatlan'
    },
    {
        value: 'America/Chihuahua',
        content: 'America/Chihuahua'
    },
    {
        value: 'America/Ojinaga',
        content: 'America/Ojinaga'
    },
    {
        value: 'America/Hermosillo',
        content: 'America/Hermosillo'
    },
    {
        value: 'America/Tijuana',
        content: 'America/Tijuana'
    },
    {
        value: 'America/Bahia_Banderas',
        content: 'America/Bahia_Banderas'
    },
    {
        value: 'Asia/Kuala_Lumpur',
        content: 'Asia/Kuala_Lumpur'
    },
    {
        value: 'Asia/Kuching',
        content: 'Asia/Kuching'
    },
    {
        value: 'Africa/Maputo',
        content: 'Africa/Maputo'
    },
    {
        value: 'Africa/Windhoek',
        content: 'Africa/Windhoek'
    },
    {
        value: 'Pacific/Noumea',
        content: 'Pacific/Noumea'
    },
    {
        value: 'Pacific/Norfolk',
        content: 'Pacific/Norfolk'
    },
    {
        value: 'Africa/Lagos',
        content: 'Africa/Lagos'
    },
    {
        value: 'America/Managua',
        content: 'America/Managua'
    },
    {
        value: 'Europe/Amsterdam',
        content: 'Europe/Amsterdam'
    },
    {
        value: 'Europe/Oslo',
        content: 'Europe/Oslo'
    },
    {
        value: 'Asia/Kathmandu',
        content: 'Asia/Kathmandu'
    },
    {
        value: 'Pacific/Nauru',
        content: 'Pacific/Nauru'
    },
    {
        value: 'Pacific/Niue',
        content: 'Pacific/Niue'
    },
    {
        value: 'Pacific/Auckland',
        content: 'Pacific/Auckland'
    },
    {
        value: 'Pacific/Chatham',
        content: 'Pacific/Chatham'
    },
    {
        value: 'America/Panama',
        content: 'America/Panama'
    },
    {
        value: 'America/Lima',
        content: 'America/Lima'
    },
    {
        value: 'Pacific/Tahiti',
        content: 'Pacific/Tahiti'
    },
    {
        value: 'Pacific/Marquesas',
        content: 'Pacific/Marquesas'
    },
    {
        value: 'Pacific/Gambier',
        content: 'Pacific/Gambier'
    },
    {
        value: 'Pacific/Port_Moresby',
        content: 'Pacific/Port_Moresby'
    },
    {
        value: 'Pacific/Bougainville',
        content: 'Pacific/Bougainville'
    },
    {
        value: 'Asia/Manila',
        content: 'Asia/Manila'
    },
    {
        value: 'Asia/Karachi',
        content: 'Asia/Karachi'
    },
    {
        value: 'Europe/Warsaw',
        content: 'Europe/Warsaw'
    },
    {
        value: 'America/Miquelon',
        content: 'America/Miquelon'
    },
    {
        value: 'Pacific/Pitcairn',
        content: 'Pacific/Pitcairn'
    },
    {
        value: 'America/Puerto_Rico',
        content: 'America/Puerto_Rico'
    },
    {
        value: 'Asia/Gaza',
        content: 'Asia/Gaza'
    },
    {
        value: 'Asia/Hebron',
        content: 'Asia/Hebron'
    },
    {
        value: 'Europe/Lisbon',
        content: 'Europe/Lisbon'
    },
    {
        value: 'Atlantic/Madeira',
        content: 'Atlantic/Madeira'
    },
    {
        value: 'Atlantic/Azores',
        content: 'Atlantic/Azores'
    },
    {
        value: 'Pacific/Palau',
        content: 'Pacific/Palau'
    },
    {
        value: 'America/Asuncion',
        content: 'America/Asuncion'
    },
    {
        value: 'Asia/Qatar',
        content: 'Asia/Qatar'
    },
    {
        value: 'Indian/Reunion',
        content: 'Indian/Reunion'
    },
    {
        value: 'Europe/Bucharest',
        content: 'Europe/Bucharest'
    },
    {
        value: 'Europe/Belgrade',
        content: 'Europe/Belgrade'
    },
    {
        value: 'Europe/Kaliningrad',
        content: 'Europe/Kaliningrad'
    },
    {
        value: 'Europe/Moscow',
        content: 'Europe/Moscow'
    },
    {
        value: 'Europe/Simferopol',
        content: 'Europe/Simferopol'
    },
    {
        value: 'Europe/Kirov',
        content: 'Europe/Kirov'
    },
    {
        value: 'Europe/Astrakhan',
        content: 'Europe/Astrakhan'
    },
    {
        value: 'Europe/Volgograd',
        content: 'Europe/Volgograd'
    },
    {
        value: 'Europe/Saratov',
        content: 'Europe/Saratov'
    },
    {
        value: 'Europe/Ulyanovsk',
        content: 'Europe/Ulyanovsk'
    },
    {
        value: 'Europe/Samara',
        content: 'Europe/Samara'
    },
    {
        value: 'Asia/Yekaterinburg',
        content: 'Asia/Yekaterinburg'
    },
    {
        value: 'Asia/Omsk',
        content: 'Asia/Omsk'
    },
    {
        value: 'Asia/Novosibirsk',
        content: 'Asia/Novosibirsk'
    },
    {
        value: 'Asia/Barnaul',
        content: 'Asia/Barnaul'
    },
    {
        value: 'Asia/Tomsk',
        content: 'Asia/Tomsk'
    },
    {
        value: 'Asia/Novokuznetsk',
        content: 'Asia/Novokuznetsk'
    },
    {
        value: 'Asia/Krasnoyarsk',
        content: 'Asia/Krasnoyarsk'
    },
    {
        value: 'Asia/Irkutsk',
        content: 'Asia/Irkutsk'
    },
    {
        value: 'Asia/Chita',
        content: 'Asia/Chita'
    },
    {
        value: 'Asia/Yakutsk',
        content: 'Asia/Yakutsk'
    },
    {
        value: 'Asia/Khandyga',
        content: 'Asia/Khandyga'
    },
    {
        value: 'Asia/Vladivostok',
        content: 'Asia/Vladivostok'
    },
    {
        value: 'Asia/Ust-Nera',
        content: 'Asia/Ust-Nera'
    },
    {
        value: 'Asia/Magadan',
        content: 'Asia/Magadan'
    },
    {
        value: 'Asia/Sakhalin',
        content: 'Asia/Sakhalin'
    },
    {
        value: 'Asia/Srednekolymsk',
        content: 'Asia/Srednekolymsk'
    },
    {
        value: 'Asia/Kamchatka',
        content: 'Asia/Kamchatka'
    },
    {
        value: 'Asia/Anadyr',
        content: 'Asia/Anadyr'
    },
    {
        value: 'Asia/Riyadh',
        content: 'Asia/Riyadh'
    },
    {
        value: 'Pacific/Guadalcanal',
        content: 'Pacific/Guadalcanal'
    },
    {
        value: 'Indian/Mahe',
        content: 'Indian/Mahe'
    },
    {
        value: 'Africa/Khartoum',
        content: 'Africa/Khartoum'
    },
    {
        value: 'Europe/Stockholm',
        content: 'Europe/Stockholm'
    },
    {
        value: 'Asia/Singapore',
        content: 'Asia/Singapore'
    },
    {
        value: 'America/Paramaribo',
        content: 'America/Paramaribo'
    },
    {
        value: 'Africa/Juba',
        content: 'Africa/Juba'
    },
    {
        value: 'Africa/Sao_Tome',
        content: 'Africa/Sao_Tome'
    },
    {
        value: 'America/El_Salvador',
        content: 'America/El_Salvador'
    },
    {
        value: 'Asia/Damascus',
        content: 'Asia/Damascus'
    },
    {
        value: 'America/Grand_Turk',
        content: 'America/Grand_Turk'
    },
    {
        value: 'Africa/Ndjamena',
        content: 'Africa/Ndjamena'
    },
    {
        value: 'Indian/Kerguelen',
        content: 'Indian/Kerguelen'
    },
    {
        value: 'Asia/Bangkok',
        content: 'Asia/Bangkok'
    },
    {
        value: 'Asia/Dushanbe',
        content: 'Asia/Dushanbe'
    },
    {
        value: 'Pacific/Fakaofo',
        content: 'Pacific/Fakaofo'
    },
    {
        value: 'Asia/Dili',
        content: 'Asia/Dili'
    },
    {
        value: 'Asia/Ashgabat',
        content: 'Asia/Ashgabat'
    },
    {
        value: 'Africa/Tunis',
        content: 'Africa/Tunis'
    },
    {
        value: 'Pacific/Tongatapu',
        content: 'Pacific/Tongatapu'
    },
    {
        value: 'Europe/Istanbul',
        content: 'Europe/Istanbul'
    },
    {
        value: 'America/Port_of_Spain',
        content: 'America/Port_of_Spain'
    },
    {
        value: 'Pacific/Funafuti',
        content: 'Pacific/Funafuti'
    },
    {
        value: 'Asia/Taipei',
        content: 'Asia/Taipei'
    },
    {
        value: 'Europe/Kiev',
        content: 'Europe/Kiev'
    },
    {
        value: 'Europe/Uzhgorod',
        content: 'Europe/Uzhgorod'
    },
    {
        value: 'Europe/Zaporozhye',
        content: 'Europe/Zaporozhye'
    },
    {
        value: 'Pacific/Wake',
        content: 'Pacific/Wake'
    },
    {
        value: 'Asia/Samarkand',
        content: 'Asia/Samarkand'
    },
    {
        value: 'Asia/Tashkent',
        content: 'Asia/Tashkent'
    },
    {
        value: 'America/Caracas',
        content: 'America/Caracas'
    },
    {
        value: 'Asia/Ho_Chi_Minh',
        content: 'Asia/Ho_Chi_Minh'
    },
    {
        value: 'Pacific/Efate',
        content: 'Pacific/Efate'
    },
    {
        value: 'Pacific/Wallis',
        content: 'Pacific/Wallis'
    },
    {
        value: 'Pacific/Apia',
        content: 'Pacific/Apia'
    },
    {
        value: 'Africa/Johannesburg',
        content: 'Africa/Johannesburg'
    }
]

/***/
export { mappedUSATimezones, timezoneMenuItems }
