import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5628%3A753
 *
 * @param {SvgIconProps} props - svg props
 */
export const PauseIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M7 18.25C7 18.6642 7.33579 19 7.75 19H10C10.4142 19 10.75 18.6642 10.75 18.25V5.75C10.75 5.33579 10.4142 5 10 5H7.75C7.33579 5 7 5.33579 7 5.75V18.25ZM13.25 18.25C13.25 18.6642 13.5858 19 14 19H16.25C16.6642 19 17 18.6642 17 18.25V5.75C17 5.33579 16.6642 5 16.25 5H14C13.5858 5 13.25 5.33579 13.25 5.75V18.25Z' fill={color}/>
        </SvgIcon>
    )
}

export default PauseIcon
