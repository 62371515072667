var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('w-select',_vm._g(_vm._b({staticClass:"descriptive-selector",attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('ShippingSelectorOption',{attrs:{"text":item.text,"description":item.description,"label":item.label}})]}},{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('ShippingSelectorOption',{attrs:{"text":item.text,"description":item.description,"label":item.label}})]}}])},'w-select',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }