import React from 'react'
import { Radio as MuiRadio, FormControlLabel, CheckboxProps as MuiCheckboxProps } from '@material-ui/core'
import { RadioButtonUncheckedIcon, RadioButtonCheckedIcon } from 'svg-icons'
import Typography from 'typography'
import { ThemeProvider } from 'theme-provider'

/***/
export enum Variant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

interface CheckboxProps extends MuiCheckboxProps {
    /**
     * Variant of the checkbox
     */
    variant: Variant.PRIMARY | Variant.SECONDARY;
    /**
     * A label which will be placed next to the checkbox
     */
    label?: string;
}

/**
 * Radio component
 *
 * @param {CheckboxProps} props - props
 */
export const Radio = (props: CheckboxProps): JSX.Element => {
    const { variant, label } = props
    const checkboxComponent = (
        <MuiRadio
            disableRipple
            icon = {<RadioButtonUncheckedIcon/>}
            checkedIcon = {<RadioButtonCheckedIcon/>}
            color = {variant}
            {...props}
        />
    )
    return (
        <ThemeProvider>
            {label
                ? <FormControlLabel
                    control = {checkboxComponent}
                    label = {<Typography variant='body1'>{label}</Typography>}
                />
                : checkboxComponent
            }
        </ThemeProvider>
    )
}

const defaultMenuItemProps = {
    label: null
}

Radio.defaultProps = defaultMenuItemProps

export default Radio
