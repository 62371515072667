import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

class API {
    // BlockList
    static getBlocklist = async cursor => {
        const phonecom = await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/get-blocklist`
        const response = await ajax.post(requestUrl, { account_id: phonecom.voip_id, cursor: cursor, limit: 5 })
        return response.data
    }

    static removeFromBlocklist = async item_id => {
        const phonecom = await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/remove-from-blocklist`
        const response = await ajax.post(requestUrl, { account_id: phonecom.voip_id, blocklist_id: item_id })
        return response.data
    }

    static addToBlocklist = async pattern => {
        const phonecom = await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/add-to-blocklist`
        const response = await ajax.post(requestUrl, {
            account_id: phonecom.voip_id,
            pattern: pattern,
            block_type: 'incoming',
            note: ''
        })
        if (response.errors) {
            return {
                status: 'fail',
                message: response.errors[Object.keys(response.errors)[0]]
            }
        }
        return response.data
    }

    // INVOICES
    static getInvoices = async () => {
        const phonecom = await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/get-invoice-list`
        const response = await ajax.post(requestUrl, { account_id: phonecom.voip_id })
        return response.data
    }

    static getInvoicePdf = async invoice_id => {
        const phonecom = await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/get-invoice`
        const response = await ajax.post(requestUrl, { account_id: phonecom.voip_id, invoice_id })
        return response.data
    }

    // Notifications
    static getAccountInfo = async () => {
        const voipId = PhoneComUser.getAPIAccountId()
        const baseUrl = 'https://api.phone.com/v4'
        const requestUrl = `${baseUrl}/accounts/${voipId}`
        const response = await ajax.get(requestUrl, 'Bearer')
        return response.data
    }

    static updateAccountInfo = async data => {
        const voipId = PhoneComUser.getAPIAccountId()
        const baseUrl = 'https://api.phone.com/v4'
        const requestUrl = `${baseUrl}/accounts/${voipId}`
        const response = await ajax.patchClean(requestUrl, data)
        if (response.toString().substr(0, 5) === 'Error') return { error: true }
        return response.data
    }
}

export default API
