import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: No link at the moment
 *
 * @param {SvgIconProps} props - svg props
 */
export const NoOptionsDialedIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#2A9BB7'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M19.6875 33.7443C18.7973 33.7159 17.9904 33.5446 17.3125 32.5103L10.6364 23.78C10.2898 23.3268 10.392 22.6757 10.8618 22.3518C11.123 22.1717 11.3464 22.0187 11.3628 22.0103C11.5878 21.8124 11.8639 21.7082 12.1708 21.7082C12.3958 21.7082 12.6003 21.7707 12.7844 21.8749C12.818 21.8835 15.7569 23.3653 16.7983 23.8907C16.9646 23.9746 17.1591 23.8528 17.1591 23.6666V12.9166C17.1591 12.052 17.8443 11.3541 18.6932 11.3541C19.5421 11.3541 20.2273 12.052 20.2273 12.9166V20.061C20.5826 20.0864 20.9673 20.0916 21.359 20.0969C22.4832 20.112 23.665 20.128 24.375 20.625C25.5095 21.4191 26.4462 21.9067 27.19 22.2938C28.6589 23.0582 29.375 23.4309 29.375 25C29.375 26.8916 28.6137 31.1599 28.2794 32.9432C28.1914 33.4126 27.7808 33.75 27.3033 33.75H19.6875V33.7443Z' fill='white'/>
            <path d='M15.2778 12.4962V17.6875C13.5972 16.5632 12.5 14.6616 12.5 12.4962C12.5 9.03997 15.2917 6.25 18.75 6.25C22.2083 6.25 25 9.03997 25 12.4962C25 14.6616 23.9028 16.5632 22.2222 17.6875V12.4962C22.2222 10.5807 20.6667 9.02609 18.75 9.02609C16.8333 9.02609 15.2778 10.5807 15.2778 12.4962Z' fill='white'/>
        </SvgIcon>
    )
}

export default NoOptionsDialedIcon
