import { Workbox } from 'workbox-window'

// import PDCCall from 'pdc-call'

// https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
/**
 * @param {Function} callback - callback func
 * @param {string} swFileName - file name
 */
const isUpdateAvailable = (callback, swFileName = `${process.env.PUBLIC_URL}/service-worker.js`) => {
    const wb = new Workbox(swFileName)
    let registration
    const callbackWrapper = () => {
        console.log('registration')
        console.log(registration)
        if (!registration) {
            wb.register().then((r) => {
                registration = r
                console.log('registered')
                console.log(registration)
                if (r?.waiting) { callback(r) }
            })
        } else {
            callback(registration)
        }
    }
    wb.addEventListener('waiting', callbackWrapper)
    wb.addEventListener('externalwaiting', callbackWrapper)

    try {
        wb.register().then((r) => {
            registration = r
            console.log('registered 1')
            console.log(registration)
        })
    } catch (e) {
        console.error(e)
    }
}

const isServiceWorkerSupported = navigator && navigator.serviceWorker

/**
 * @param {string} type
 * @param {Function} callback
 */
const addSWEventListener = (type, callback) => {
    if (isServiceWorkerSupported) {
        return navigator.serviceWorker.addEventListener(type, callback)
    }
    console.warn('Service worker not supported on browser')
}

/**
 * @param {string} message
 */
const postMessageToSW = (message) => {
    if (isServiceWorkerSupported && navigator.serviceWorker.ready) {
        try {
            navigator.serviceWorker.ready.then(() => {
                if (navigator.serviceWorker && navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage) { navigator.serviceWorker.controller.postMessage(message) }
            })
        } catch (e) {
            console.error(e)
        }
    }
}

/**
 * @description
 * This function can be used to listen to cache update events from staleWhileRevalidate serviceworker caching method.
 * This will make sure if there is account if that it only shares info for this account. We plan on adding an extension option as well
 * WORK IN PROGRESS - only used in communicator.js for get-user-info request right now.
 * @example
 *    cacheUpdateListener('/voicemails/list-voicemail', data => {
 *        this.props.updateVoicemails(data)
 *    })
 * @param {string} cachedUrlPath - the url path to listen for cache updates for
 * @param {Function} callback - function that will be executed when cache update event is triggered
 * @public
 */
const cacheUpdateListener = (cachedUrlPath, callback) => {
    addSWEventListener('message', event => {
        console.log('sw_receive_cache_update', event)
        const eventData = event?.data
        const extraData = eventData?.extraData
        const eventUrl = extraData?.url
        const eventType = eventData?.type
        const accountId = window?.V5PHONECOM?.voip_id
        console.log({ eventData, extraData, eventUrl, eventType })
        if (eventType === 'cacheUpdateEvent' && eventUrl?.includes(cachedUrlPath)) {
            console.log('sw_receive_cache_update matched url', cachedUrlPath)

            if (eventUrl?.includes('account_id') || extraData?.account_id) {
                console.log('sw_receive_cache_update account id')
                if (eventUrl?.includes(accountId) || extraData?.account_id === accountId) {
                    console.log('sw_receive_cache_update account id match')

                    callback(extraData)
                }
            } else {
                console.log('no account info in cache update, pushing to all clients listening for url')
                callback(extraData)
            }
        }
    })
}

/**
 *
 */
export { isUpdateAvailable, isServiceWorkerSupported, addSWEventListener, postMessageToSW, cacheUpdateListener }
