/* eslint-disable quote-props, quotes */
import l from 'lang'
import { getFeatureEnabled } from 'feature-flag'

/**
 *
 */
export default class LiveAnswerVendor {
    /**
     *
     * @param {number} initial_code
     */
    constructor (initial_code) {
        this.initial_code = initial_code
        this._eligible_vendors = null
        this._vendors = null
    }

    static CODES_DETAILS = {
        // AVO
        19074: {
            short: l.t('lr.plan-text-short', '{} mins', [50]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [50, 90]),
            price: 90
        },
        19075: {
            short: l.t('lr.plan-text-short', '{} mins', [100]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [100, 140]),
            price: 140
        },
        19076: {
            short: l.t('lr.plan-text-short', '{} mins', [200]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [200, 220]),
            price: 220
        },
        19077: {
            short: l.t('lr.plan-text-short', '{} mins', [350]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [350, 400]),
            price: 400
        },
        19078: {
            short: l.t('lr.plan-text-short', '{} mins', [500]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [500, 550]),
            price: 550
        },
        19079: {
            short: l.t('lr.plan-text-short', '{} mins', [750]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [750, 800]),
            price: 800
        },
        19080: {
            short: l.t('lr.plan-text-short', '{} mins', [1000]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [1000, 1000]),
            price: 1000
        },
        19086: {
            short: 'Alliance Virtual Offices - English',
            long: 'Alliance Virtual Offices - English'
        },
        // Onvego
        19087: {
            short: l.t('lr.standard-script', 'Standard script (${} per month)', [4.99]),
            long: l.t('lr.standard-script', 'Standard script (${} per month)', [4.99]),
            price: 4.99
        },
        19088: {
            short: l.t('lr.enhanced-script ', 'Enhanced script  (${} per month)', [19.99]),
            long: l.t('lr.enhanced-script ', 'Enhanced script  (${} per month)', [19.99]),
            price: 19.99
        },
        // Answernet
        19110: {
            short: l.t('lr.plan-text-short', '{} mins', [50]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [50, 125]),
            price: 125
        },
        19111: {
            short: l.t('lr.plan-text-short', '{} mins', [100]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [100, 199]),
            price: 199
        },
        19112: {
            short: l.t('lr.plan-text-short', '{} mins', [200]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [200, 375]),
            price: 375
        },
        19113: {
            short: l.t('lr.plan-text-short', '{} mins', [250]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [250, 449]),
            price: 449
        },
        19114: {
            short: l.t('lr.plan-text-short', '{} mins', [350]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [350, 599]),
            price: 599
        },
        19115: {
            short: l.t('lr.plan-text-short', '{} mins', [500]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [500, 799]),
            price: 799
        },
        19116: {
            short: l.t('lr.plan-text-short', '{} mins', [750]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [750, 1099]),
            price: 1099
        },
        19117: {
            short: l.t('lr.plan-text-short', '{} mins', [1000]),
            long: l.t('lr.plan-text', '{} minutes for ${} per month', [1000, 1299]),
            price: 1299
        }
    }

    /**
     *
     */
    async init () {
        this._eligible_vendors = await this.eligibleVendors()
        return this.eligible_vendors
    }

    /**
     *
     */
    get eligible_vendors () {
        if (!this._eligible_vendors) {
            throw new Error('You should use init method to load eligible_vendors for LiveAnswerVendor class.')
        }

        return this._eligible_vendors
    }

    /**
     *
     */
    get vendors () {
        if (!this._vendors) {
            this._vendors = this.loadVendors()
        }

        return this._vendors
    }

    /**
     * returns vender object of given id
     *
     * @param {int} id - vendor id
     */
    getVendor (id) {
        return this.vendors.find((vendor) => vendor.id === id)
    }

    /**
     *
     * @param {object, number} vendor
     * @returns
     */
    static vendor_group (vendor) {
        let id
        if (vendor && typeof vendor === 'object') {
            id = Number.parseInt(vendor.id)
        } else if (!Number.isNaN(vendor)) {
            id = Number.parseInt(vendor)
        }
        if (!id) {
            return null
        }

        if (id === 1) return 'AVO'
        if ([4, 6, 9].includes(id)) return 'ONVEGO'
        if (id === 8) return 'ANSWERNET'
        return null
    }

    /**
     *
     */
    get vendorsObj () {
        return this.vendors.reduce((a, c) => {
            a[c.id] = c
            return a
        }, {})
    }

    /**
     * @param {int} id
     */
    vendorName (id) {
        const v = this.vendors.find((x) => x.id === id)
        if (!v) return `#${id}`
        return v.name
    }

    /**
     *
     */
    async eligibleVendors () {
        const vendors_ids = [4]
        const answernet_is_live = await getFeatureEnabled('answernet_answer_is_live')
        const avo_is_live = await getFeatureEnabled('avo_answer_is_live')
        const has_standalone_feature = await getFeatureEnabled('console.Onvego_stand_alone_plan') // id 9
        const show_onvego_stage = process?.env?.REACT_APP_STAGE_ONVEGO_VENDOR_ENABLED

        if (avo_is_live) vendors_ids.push(1)
        if (show_onvego_stage) vendors_ids.push(6)
        if (answernet_is_live) vendors_ids.push(8)
        if (has_standalone_feature) vendors_ids.push(9)

        const vendors = this.vendors.filter((x) => vendors_ids.includes(x.id))

        for (const vendor of vendors) {
            if (vendor.codes) {
                let i = 0
                for (const code of vendor.codes) {
                    const trans = this.constructor.CODES_DETAILS[code.code]
                    if (code.code === 19086) {
                        vendor.codes.splice(i, 1)
                    }
                    if (!trans) {
                        throw new Error(`${code.code} is not translated`)
                    }
                    code.details = {
                        name: {
                            short: trans.short,
                            long: trans.long
                        },
                        price: trans.price
                    }
                    i++
                }
            }
        }
        vendors.sort((a, b) => a.name.localeCompare(b.name))
        return vendors
    }

    /**
     *
     */
    loadVendors () {
        const onvego = {
            'id': 4,
            'name': l.t('app.ai-connect', 'AI-Connect'),
            '_name': 'Onvego',
            'description': l.t('lr.while our-LR-service-is-perfect-for-some-businesses,-others-prefer-the-affordability-and-simplicity-of-voice-enabled-call-routing.', 'While our Live Receptionist service is perfect for some businesses, others prefer the affordability and simplicity of voice-enabled call routing.'),
            'img': 'onvego.svg',
            'number': '+883510008279653',
            'screening': false,
            'codes': []
        }
        const standard_onvego_plan = {
            'id': 552,
            'code': 19087,
            'type': 'periodic',
            'retail': 0
        }
        if (this.initial_code === standard_onvego_plan.code) {
            onvego.codes.push(standard_onvego_plan)
        } else {
            onvego.codes.push({
                'id': 558,
                'code': 19088,
                'type': 'periodic',
                'retail': 0
            })
        }
        const vendors = [
            {
                'id': 1,
                'name': l.t('app.live-receptionist-service', 'Live Receptionist service'),
                '_name': 'AVO - English',
                'description': l.t('lr.we-ll-take-your-calls-when-you-cant.', 'We\'ll take your calls when you can\'t.'),
                'img': 'live-receptionist.svg',
                'number': '+883510080970',
                'screening': true,
                'codes': [
                    {
                        'id': 474,
                        'code': 19074,
                        'type': 'periodic',
                        'retail': 0
                    },
                    {
                        'id': 475,
                        'code': 19075,
                        'type': 'periodic',
                        'retail': 0
                    },
                    {
                        'id': 476,
                        'code': 19076,
                        'type': 'periodic',
                        'retail': 0
                    },
                    {
                        'id': 547,
                        'code': 19077,
                        'type': 'periodic',
                        'retail': 0
                    },
                    {
                        'id': 548,
                        'code': 19078,
                        'type': 'periodic',
                        'retail': 0
                    },
                    {
                        'id': 549,
                        'code': 19079,
                        'type': 'periodic',
                        'retail': 0
                    },
                    {
                        'id': 550,
                        'code': 19080,
                        'type': 'periodic',
                        'retail': 0
                    }
                ]
            },
            onvego,
            {
                ...onvego,
                'id': 6,
                'name': `${l.t('app.stage', 'Stage')} ${onvego.name}`
            },
            {
                "id": 8,
                "name": l.t('vedors.live-receptionist-plus', 'Live Answer-Connect'),
                "_name": "Answernet",
                "description": l.t('lr.we-ll-take-your-calls-when-you-cant.', 'We\'ll take your calls when you can\'t.'),
                "img": 'live-receptionist.svg',
                "number": "+883510008273130",
                "screening": true,
                "codes": [
                    {
                        "id": 560,
                        "code": 19110,
                        "type": "periodic",
                        "retail": 0
                    },
                    {
                        "id": 561,
                        "code": 19111,
                        "type": "periodic",
                        "retail": 0
                    },
                    {
                        "id": 562,
                        "code": 19112,
                        "type": "periodic",
                        "retail": 0
                    },
                    {
                        "id": 563,
                        "code": 19113,
                        "type": "periodic",
                        "retail": 0
                    },
                    {
                        "id": 564,
                        "code": 19114,
                        "type": "periodic",
                        "retail": 0
                    },
                    {
                        "id": 565,
                        "code": 19115,
                        "type": "periodic",
                        "retail": 0
                    },
                    {
                        "id": 566,
                        "code": 19116,
                        "type": "periodic",
                        "retail": 0
                    },
                    {
                        "id": 567,
                        "code": 19117,
                        "type": "periodic",
                        "retail": 0
                    }
                ]
            },
            {
                "id": 9,
                "name": "Onvego Sandbox",
                "number": "+883510008272062",
                "screening": false,
                'codes': [
                    {
                        'id': 552,
                        'code': 19087,
                        'type': 'periodic',
                        'retail': 0
                    },
                    {
                        'id': 558,
                        'code': 19088,
                        'type': 'periodic',
                        'retail': 0
                    }
                ]
            }
        ]
        for (const vendor of vendors) {
            if (vendor.codes) {
                let i = 0
                for (const code of vendor.codes) {
                    const trans = this.constructor.CODES_DETAILS[code.code]
                    if (code.code === 19086) {
                        vendor.codes.splice(i, 1)
                    }
                    if (!trans) {
                        throw new Error(`${code.code} is not translated`)
                    }
                    code.details = {
                        name: {
                            short: trans.short,
                            long: trans.long
                        },
                        price: trans.price
                    }
                    i++
                }
            }
        }
        vendors.sort((a, b) => a.name.localeCompare(b.name))

        return vendors
    }
}
