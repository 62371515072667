import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { setTempUsers, updateTempUser, addTempUser, addUser } from '../../actions/users'
import { getFeatureEnabled } from 'feature-flag'
import AddUsersLarge from './AddUsersLarge'
import AddUsersSmall from './AddUsersSmall'
import api from '../../util/api_v5'
import Prompt from 'pdc-prompt'
import PropTypes from 'prop-types'

const styles = theme => ({
    addUsersMain: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    successMessageOne: {
        fontWeight: 'bold',
        fontSize: 13
    },
    successMessageTwo: {
        fontWeight: 'normal',
        display: 'block'
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView,
    tempUsers: state.tempUsers

})

const mapDispatchToProps = dispatch => ({
    setTempUsers: tempUsers => dispatch(setTempUsers(tempUsers)),
    updateTempUser: tempUser => dispatch(updateTempUser(tempUser)),
    addTempUser: () => dispatch(addTempUser()),
    addUser: user => dispatch(addUser(user))
})

class AddUsers extends Component {
    state = {
        pdcPromptOpen: false,
        pdcPromptMessage: null,
        pendingAddUsers: false,
        forceProPlan: false
    }

    componentDidMount = async () => {
        const forceProPlan = await getFeatureEnabled('configure.force_pro_plan')

        if (forceProPlan) {
            this.setState({
                forceProPlan: true
            })
        }
    }

    changeValueInUser = (user, key, value) => {
        this.props.tempUsers.filter((u) => {
            return u.id === user.id
        })
            .map((u) => {
                if (['first_name', 'last_name', 'email'].includes(key)) {
                    value = value.trimStart()
                    delete user[key + '_error'] // reset validation
                }
                u[key] = value
                this.props.updateTempUser(u)
                return null
            })
    }

    addUserRow = () => {
        if (this.props.tempUsers.length === 5 || !(this.validateUsers())) {
            return
        }
        this.props.addTempUser()
    }

    validateUsers = () => {
        let isValid = true
        this.props.tempUsers.forEach((user) => {
            isValid = !this.validateFirstName(user) ? false : isValid
            isValid = !this.validateLastName(user) ? false : isValid
            isValid = !this.validateEmailForUser(user) ? false : isValid
        })
        return isValid
    }

    validateFirstName = user => {
        if (user.first_name.length === 0) {
            const error = 'Please enter a valid First Name.'
            this.changeValueInUser(user, 'first_name_error', error)
            return false
        }
        return true
    }

    validateLastName = user => {
        if (user.last_name.length === 0) {
            const error = 'Please enter a valid Last Name.'
            this.changeValueInUser(user, 'last_name_error', error)
            return false
        }
        return true
    }

    validateEmailForUser = user => {
        if (user.email === '') {
            return true
        }
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(String(user.email).toLowerCase())) {
            const error = 'NOT A VALID EMAIL'
            this.changeValueInUser(user, 'email_error', error)
            return false
        }
        const tempEmails = this.props.tempUsers.map((u) => { return u.id !== user.id ? u.email : null })
        if (tempEmails.includes(user.email)) {
            this.changeValueInUser(user, 'email_error', 'DUPLICATE EMAIL')
            return false
        }
        return true
    }

    onAddUsersClick = () => {
        if (!(this.validateUsers())) {
            return
        }

        this.addUsers()
    }

    prepareUsers = () => {
        const users = JSON.parse(JSON.stringify(this.props.tempUsers))
        //  clean user objects
        users.forEach((u) => {
            delete u.id
            delete u.firstNameError
            delete u.lastNameError
            delete u.emailError
            u.first_name = u.first_name.trim()
            u.last_name = u.last_name.trim()
            u.email = u.email.trim()
            u.status = u.email === '' ? 'new' : 'invite_pending'
            if (u.email === '') delete u.email
        })
        return users
    }

    addUsers = async () => {
        const { classes } = this.props

        this.setState({ pendingAddUsers: true })
        // if this.state.forceProPlan is true, we need to set each user_plan_id to 3
        if (this.state.forceProPlan) {
            this.props.tempUsers.forEach((u) => {
                this.changeValueInUser(u, 'user_plan_id', 3)
            })
        }
        // The createUser endpoint can handle multiple users at once
        const response = await api.inviteUsers(this.prepareUsers())
        if (
            response.error &&
            response.error.message &&
            response.error.message.toLowerCase().includes('unique')
        ) {
            if (response.error.message) {
                this.props.tempUsers.forEach((u) => {
                    if (u.email !== '' && response.error.message.includes(u.email)) {
                        this.changeValueInUser(u, 'email_error', 'EMAIL IS ALREADY IN USE')
                    }
                })
            }
            this.setState({ pendingAddUsers: false })
            return
        } else if (response.error) {
            this.setState({ pendingAddUsers: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while adding user.' })
            return
        }
        this.props.setTempUsers([{
            id: 1,
            user_plan_id: 1,
            first_name: '',
            last_name: '',
            email: '',
            is_admin: false,
            relationships: {},
            status: 'new'
        }])
        const successMessage = <div><span className={classes.successMessageOne}>User(s) created successfully.</span><span className={classes.successMessageTwo}>Users created with an email address will receive a welcome email.</span></div>
        setTimeout(() => { this.setState({ pendingAddUsers: false, pdcPromptOpen: true, pdcPromptMessage: successMessage }) }, 100)
        // newUsers.forEach((user) => {this.props.addUser(user)})
        // this.props.swtichTab('manage-users')
    }

    handlepdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null })

    render () {
        const { classes } = this.props
        let mainView = ''
        if (this.props.smallView) {
            mainView = <AddUsersSmall
                onAddUsersClick={this.onAddUsersClick}
                changeValueInUser={this.changeValueInUser}
                pendingAddUsers={this.state.pendingAddUsers}
                forceProPlan={this.state.forceProPlan}
            />
        } else {
            mainView = <AddUsersLarge
                addUserRow={this.addUserRow}
                onAddUsersClick={this.onAddUsersClick}
                changeValueInUser={this.changeValueInUser}
                pendingAddUsers={this.state.pendingAddUsers}
                forceProPlan={this.state.forceProPlan}
            />
        }
        return (
            <div className={classes.addUsersMain}>
                {mainView}
                <Prompt promptProps={{ 'data-prompt': 'add-users' }} isOpen={this.state.pdcPromptOpen} color='tertiary' position={'bottom'} onClose={this.handlepdcPromptClose} content={this.state.pdcPromptMessage}/>
            </div>
        )
    }
}

AddUsers.propTypes = {
    classes: PropTypes.object,
    changeValueInUser: PropTypes.fucn,
    smallView: PropTypes.bool,
    users: PropTypes.array,
    tempUsers: PropTypes.array,
    pendingAddUsers: PropTypes.bool,
    onAddUsersClick: PropTypes.func,
    addUserRow: PropTypes.func,
    handlepdcPromptClose: PropTypes.func,
    setTempUsers: PropTypes.func,
    inviteUsers: PropTypes.func,
    prepareUsers: PropTypes.func,
    validateEmailForUser: PropTypes.func,
    validateUsers: PropTypes.func,
    validateLastName: PropTypes.func,
    validateFirstName: PropTypes.func,
    updateTempUser: PropTypes.func,
    addTempUser: PropTypes.func
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddUsers))
