import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.1.0?node-id=5537%3A4481
 *
 * @param {SvgIconProps} props - svg props
 */
export const ArrowForwardIosIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.41119 3.58967C7.1183 3.88256 7.1183 4.35744 7.41119 4.65033L14.7609 12L7.41119 19.3497C7.1183 19.6426 7.1183 20.1174 7.41119 20.4103L8.47053 21.4697C8.76342 21.7626 9.2383 21.7626 9.53119 21.4697L18.4705 12.5303C18.7634 12.2374 18.7634 11.7626 18.4705 11.4697L9.53119 2.53033C9.2383 2.23744 8.76342 2.23744 8.47053 2.53033L7.41119 3.58967Z' fill={color}/>
        </SvgIcon>
    )
}

export default ArrowForwardIosIcon
