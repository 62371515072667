import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5588%3A3815
 *
 * @param {SvgIconProps} props - svg props
 */
export const CheckIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M5.73623 10.5557C5.6382 10.4605 5.4819 10.4617 5.38528 10.5583L3.42724 12.5164C3.32943 12.6142 3.32963 12.7728 3.4277 12.8704L9.40848 18.82C9.50779 18.9188 9.66889 18.9167 9.76562 18.8154L20.5813 7.48621C20.6751 7.38798 20.6733 7.23284 20.5773 7.13681L18.6204 5.17989C18.5216 5.08105 18.3609 5.08245 18.2638 5.18299L9.52341 14.2335L5.73623 10.5557Z' fill={color}/>
        </SvgIcon>
    )
}

export default CheckIcon
