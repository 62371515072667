import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"loader",staticClass:"loader-container",class:{
        small: _vm.size === 'small',
        medium: _vm.size === 'medium',
        large: _vm.size === 'large' || !_vm.size,
        no_margins: _vm.no_margins,
        'overlay-loader': !!_vm.overlay
    },attrs:{"data-test-id":"loader"}},[_c('div',{staticClass:"loader-inner d-flex justify-center"},[_c(VIcon,{staticClass:"loader-spinner"},[_vm._v("$vuetify.icons.spinning_loader")])],1),_vm._v(" "),(_vm.message !== false)?_c('div',{staticClass:"d-flex justify-center"},[_vm._v("\n        "+_vm._s(_vm.message || (_vm.size === "small" ? "" : ((_vm.l.t("app.loading", "Loading")) + "...")))+"\n    ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }