<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="12" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 7.5C13.5 8.32843 12.8284 9 12 9C11.1716 9 10.5 8.32843 10.5 7.5C10.5 6.67157 11.1716 6 12 6C12.8284 6 13.5 6.67157 13.5 7.5ZM13.5 15.9783C13.5 16.8187 12.8284 17.5 12 17.5C11.1716 17.5 10.5 16.8187 10.5 15.9783V12.0217C10.5 11.1813 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1813 13.5 12.0217V15.9783Z" fill="white"/>
</svg>
</template>

<script>
  export default {};
</script>
