import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setSmallView } from '../actions/view'

import ResizeAware from 'react-resize-aware'
import HeaderTabs from 'header-tabs'
import ConfirmModal from 'confirm-modal'
import PhoneComUser from 'phone-com-user'
import GeneralSettings from './GeneralSettings/GeneralSettings'
import LiveReceptionist from './LiveReceptionist/LiveReceptionist'
import BusinessHours from 'business-hours'
import { GeneralSettingsIcon/* , LiveReceptionistIcon */, BusinessHoursIcon } from 'pdc-svg-icons'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentTabFromRoute } from 'app-tabs'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .resize-aware': {
            height: '100%'
        }
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView
})

const mapDispatchToProps = dispatch => ({
    setSmallView: boolVal => dispatch(setSmallView(boolVal))
})

const tabs = [
    { id: 'general-settings', icon: GeneralSettingsIcon, default: true },
    { id: 'business-hours', icon: BusinessHoursIcon }
    // { id: 'live-receptionist', icon: LiveReceptionistIcon }
]

class App extends Component {
    constructor (props) {
        super(props)
        const currentTab = getCurrentTabFromRoute('company', this.props.routeProps, tabs)
        if (!currentTab) {
            const currentTab = tabs.find(t => t.default).id
            this.updateRouterHistory(currentTab)
        }
        this.state = { isBusy: false }
        this.companyExtension = props.extensions.find(e => e.company_name) || props.extensions[0]
        if (this.companyExtension && this.companyExtension.extension_id !== PhoneComUser.getExtensionId()) {
            PhoneComUser.changeExtension(this.companyExtension.extension_id)
        }
    }

    handleResize = size => {
        const needToExpand = this.props.smallView && !this.props.screenViewType.isMobileView
        const needToShrink = !this.props.smallView && this.props.screenViewType.isMobileView
        if (needToShrink) this.props.setSmallView(true)
        else if (needToExpand) this.props.setSmallView(false)
    }

    onTabSwitch = newTab => {
        const currentTab = getCurrentTabFromRoute('company', this.props.routeProps, tabs)
        if (currentTab === newTab) return
        if (this.state.isBusy) {
            this.setState({ exitAttempt: newTab })
            return false
        }
        this.updateRouterHistory(newTab)
    }

    getBasePath = () => this.props.routeProps.match.path.replace(/\/+$/g, '')

    updateRouterHistory = tabName => {
        const basePath = this.getBasePath()
        this.props.routeProps.history.push(`${basePath}/${tabName}`)
    }

    setBusy = isBusy => {
        this.setState({ isBusy })
        this.props.setHasChange(isBusy)
    }

    renderApp = () => {
        const basePath = this.getBasePath()
        const defaultTabId = tabs.find(t => t.default).id
        return (
            <Switch>
                <Route path={`${basePath}/general-settings`} render={props => <GeneralSettings extension={this.companyExtension} setBusy={this.setBusy} updateCompanyName={this.props.updateCompanyName}/>}/>
                <Route path={`${basePath}/live-receptionist`} render={props => <LiveReceptionist setBusy={this.setBusy}/>}/>
                <Route path={`${basePath}/business-hours`} render={props => <BusinessHours smallView={this.props.smallView} setBusy={this.setBusy} origin='business-hours-tab'/>}/>
                <Route path='/' render={() => <Redirect to={`${basePath}/${defaultTabId}`}/>}/>
            </Switch>
        )
    }

    discardChanges = () => {
        const newTab = this.state.exitAttempt
        this.setState({ isBusy: false, exitAttempt: null }, () => this.onTabSwitch(newTab))
    }

    renderDiscardChangesModal = () => {
        return (
            <ConfirmModal
                isShown = {Boolean(this.state.exitAttempt)}
                title = 'Discard changes?'
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {() => this.setState({ exitAttempt: null })}
                onConfirm = {this.discardChanges}
                size = 'size2' // size440
            />
        )
    }

    render = () => {
        const { classes } = this.props
        const currentTab = getCurrentTabFromRoute('company', this.props.routeProps, tabs)
        return (
            <div className={classes.wrapper}>
                <ResizeAware
                    className='resize-aware'
                    onResize={this.handleResize}
                >
                    <HeaderTabs smallView={this.props.smallView} selectedTabId={currentTab} tabs={tabs} onTabSwitch={this.onTabSwitch}/>
                    {this.renderApp()}
                    {this.renderDiscardChangesModal()}
                </ResizeAware>
            </div>
        )
    }
}

App.propTypes = {
    classes: PropTypes.object,
    smallView: PropTypes.bool,
    screenViewType: PropTypes.object,
    routeProps: PropTypes.object,
    setSmallView: PropTypes.func,
    extension: PropTypes.object,
    setHasChange: PropTypes.func,
    extensions: PropTypes.array,
    updateCompanyName: PropTypes.func
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
