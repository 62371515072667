<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5661_7163)">
        <rect width="24" height="24" rx="12" fill="currentColor" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.3332 6.66675C11.1491 6.66675 10.9998 6.81599 10.9998 7.00008V11.0001H6.99984C6.81574 11.0001 6.6665 11.1493 6.6665 11.3334V12.6667C6.6665 12.8508 6.81574 13.0001 6.99984 13.0001H10.9998V17.0001C10.9998 17.1842 11.1491 17.3334 11.3332 17.3334H12.6665C12.8506 17.3334 12.9998 17.1842 12.9998 17.0001V13.0001H16.9998C17.1839 13.0001 17.3332 12.8508 17.3332 12.6667V11.3334C17.3332 11.1493 17.1839 11.0001 16.9998 11.0001H12.9998V7.00008C12.9998 6.81599 12.8506 6.66675 12.6665 6.66675H11.3332Z"
            fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_5661_7163">
            <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
    </defs>
</svg>
</template>

<script>
export default {};
</script>
