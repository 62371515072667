import React from 'react'
import MuiTab, { TabProps as MuiTabProps } from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core'
import { ThemeProvider } from 'theme-provider'
import { AlertIcon } from 'svg-icons'
import { theme } from 'get-theme'
import Typography from 'typography'

/***/
const useStyles = makeStyles({
    root: theme.secondaryOverrides.MuiTab.root,
    textColorInherit: theme.secondaryOverrides.MuiTab.textColorInherit
})

/**
 * MuiTabProps extension for additional pdc functionality.
 */
export interface TabProps extends MuiTabProps {
    /**
     * Label position relative to icon.
     */
    labelPosition?: 'centered' | 'stacked'
    /**
     * badge for unread/new data if it exists.
     */
    hasNotification?: boolean
    /**
     * Theme selection. Should be passed in by wrapping <Tabs> component automatically.
     */
    theme: 'primary' | 'secondary'
    isMobileView?: boolean
}

const renderIcon = (icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined) => {
    console.log(icon)
    // <Icon>icon_name</Icon> not working. forced to pass in Icon instead of just string.
    // if (typeof icon === 'string') {
    //     icon[0].toUpperCase()
    //     const Icon = import (`@material-ui/icons/${icon}`)
    //     return <Icon />
    // }
    return icon
}

const renderLabel = (
    label: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null,
    labelPosition: 'centered' | 'stacked',
    hasNotification: boolean,
    icon: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined,
    props: TabProps
) => {
    icon = labelPosition === 'centered' ? renderIcon(icon) : undefined
    const indicator = hasNotification ? <AlertIcon className='alert-icon' color={theme.palette.error.light}/> : null
    const labelElement = <Typography variant={props.isMobileView ? 'subtitle3' : 'body3'}>{label}</Typography>
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>{icon} {labelElement} {indicator}</div>
    )
}

const getClasses = (propsClasses, props: TabProps) => {
    const { theme, isMobileView } = props
    if (theme === 'primary') return propsClasses
    const mobileClass = isMobileView ? 'mobile' : ''
    const formattedClasses = { ...useStyles() }
    formattedClasses.root += ` ${mobileClass}`
    if (propsClasses) {
        if (propsClasses.root) formattedClasses.root += ` ${propsClasses.root}`
        if (propsClasses.textColorInherit) formattedClasses.textColorInherit += ` ${propsClasses.textColorInherit}`
        const otherClasses = { ...propsClasses }
        delete otherClasses.root
        delete otherClasses.textColorInherit
        Object.assign(formattedClasses, otherClasses)
    }
    return formattedClasses
}

/***/
export const Tab = ({
    label = '',
    labelPosition = 'centered',
    icon,
    disabled = false,
    value,
    hasNotification = false,
    onChange,
    classes,
    ...props // rest props/ rest params
}: TabProps): React.ReactElement => {
    const renderedLabel = renderLabel(label, labelPosition, hasNotification, icon, props)
    icon = labelPosition === 'centered' ? undefined : renderIcon(icon)
    classes = getClasses(classes, props)
    return (
        <ThemeProvider>
            <MuiTab
                {...props}
                disableTouchRipple
                disableFocusRipple
                classes={classes}
                label={renderedLabel}
                icon={icon}
                disabled={disabled}
                value={value}
                onChange={(event, value) => {
                    event.preventDefault()
                    if (onChange) onChange(event, value)
                }}
            />
        </ThemeProvider>
    )
}

export default Tab
