import { create_default_contact_groups } from 'phoenix-session-helpers'
import Cachier from 'cachier'
import l from '../libs/lang'
import EventBus from '../libs/EventBus'
import Autocomplete from './Autocomplete'
import gtmDataPush from 'gtm-events'

class Resource {
    /**
     * @param {object} session - PhoenixAPIJSClient Object
     * @param {object} component - helpers vueCompoenent
     * @param {string} baseUri - endpoint url for the resource
     */
    constructor (session, component, baseUri) {
        this.session = session
        this.cachier = new Cachier(this.session.user.id)
        this.component = component
        this.baseUri = baseUri
        this.uri = this.baseUri
        this._loading = false
        this._alert = null
        this.limit = 25
        this.page = 1
        this.items = []
        this.filters_applied = false
        this._filters = {}
        this.hide_filters = false
        this.disable_delete_button = true
        this.successful_deletions = null
        this.failed_deletions = null
        this.deleting = null
        this.deleting_item = null
        this.deleting_finished = false
        this._stopDeletion = false
        this.everythingDeleted = false
        this._check_all = false
        this.timeUntilRedirects = 2500
        this.empty_filters = null
        this.functions = {}
        this.headers = {}
        this.csv_incorrect_lines = []
        this.possibleCsvMemeTypes = [
            'text/plain',
            'text/x-csv',
            'application/vnd.ms-excel',
            'application/csv',
            'application/x-csv',
            'text/csv',
            'text/comma-separated-values',
            'text/x-comma-separated-values',
            'text/tab-separated-values'
        ]
        this.alert_timer = null
        this.csv_downloader = null
        this.keep_filters_open = false
        this.dynamic_filters_delay = 500
        this.dynamic_filters_timer = null
        this.progress = null
        this.route_using_resource = null
        this._setup = null
    }

    /**
     *
     */
    get setup () {
        if (this._setup) return this._setup
        console.error('No setup file provided for the resource')
        return {}
    }

    /**
     *
     */
    set setup (val) {
        this._setup = val
    }

    /**
     *
     */
    get enable_close_alert () {
        return this.alert && this.alert.status_code && this.alert.status_code <= 500 && ![404, 201, 204].includes(this.alert.status_code)
    }

    /**
     *
     */
    get filters () {
        return this._filters
    }

    /**
     * @param {object} val
     */
    set filters (val) {
        if (!this.empty_filters) {
            this.empty_filters = { ...val }
        }
        this._filters = val
    }

    /**
     *
     */
    get check_all () {
        return this._check_all
    }

    /**
     *
     */
    set check_all (val) {
        this._check_all = val
        if (this._check_all) {
            return this.checkAll()
        }

        return this.uncheckAll()
    }

    /**
     * @param {object} val
     */
    set alert (val) {
        if (val) {
            window.scrollTo({ top: 0 })
        }
        if (val === null) clearTimeout(this.alert_timer)
        this._alert = val
    }

    /**
     *
     */
    get alert () {
        return this._alert
    }

    /**
     * @param {boolean} val
     */
    set loading (val) {
        this._loading = val
        window.scrollTo({ top: 0 })
        if (val) {
            if (typeof this.component.emit === 'function') this.component.emit('hidePagination')
            this.resetDeleting()
        } else if (typeof this.component.emit === 'function') {
            this.component.emit('showPagination')
        }
    }

    /**
     *
     */
    get loading () {
        return this._loading
    }

    /**
     * @param {number} val - integer
     */
    set extension (val) {
        if (val === this._extension) return false
        this._extension = val
        this.resetDeleting()
        let uri = this.baseUri.split('/')
        uri = uri.filter((x) => x)
        if (uri[0] === 'extensions') {
            if (val) {
                uri[0] = '/extensions'
                uri[1] = val
            } else {
                uri[0] = null
                uri[1] = null
                uri = uri.filter((x) => x)
                uri[0] = `/${uri[0]}`
            }
            this.baseUri = uri.join('/')
        } else {
            let new_uri = `/${uri.join('/')}`
            if (val) {
                new_uri = `/extensions/${val}${new_uri}`
            }
            this.baseUri = new_uri
        }
        this.keep_filters_open = false
        if (typeof this.apply_filters === 'function') this.apply_filters() // this will change the URI based on baseUri

        return this._extension
    }

    /**
     *
     */
    get extension () {
        return this._extension
    }

    /**
     * @param {boolean} val
     */
    set stopDeletion (val) {
        this._stopDeletion = val
        if (val) window.deleting_in_progress = false
    }

    /**
     *
     */
    get stopDeletion () {
        return this._stopDeletion
    }

    /**
     * @param {number} id - integer
     */
    async getItem (id) {
        this.loading = true
        try {
            this.item = await this.session.get_item(`${this.baseUri}/${id}`)
            this.loading = false
            return this.item
        } catch (err) {
            this.loading = false
            return this.validation_error(err, true)
        }
    }

    /**
     * @param {object} item
     */
    async update (item) {
        this.loading = true
        try {
            this.item = item
            await this.session.replace_item(
                `${this.baseUri}/${this.item.id}`, this.item
            )
            this.successfulUpdate()
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     * @param {number} off - offset
     * @param {number} page - page number
     */
    checkIfUserDeletedItems (off, page) {
        let offset = off
        if (
            page === this.page + 1 &&
            this.items &&
            this.items.length < this.limit
        ) {
            offset -= (this.limit - this.items.length)
            if (offset < 0) offset = 0
        }

        return offset
    }

    /**
     *
     * @param {any} data
     * @param {string} event
     * @param {number} price
     * @returns
     */
    async gtm_data_push (event, data, price) {
        if (price) {
            const isArray = Array.isArray(data)
            gtmDataPush({
                event: event,
                ecommerce: {
                    currency: 'USD',
                    value: (price / 100),
                    tax: isArray ? (this.getTotalTax(data) / 100) : (data.price.taxes / 100),
                    items: isArray ? data : [data]
                }
            })
        } else {
            gtmDataPush({
                event: event,
                items: [data]
            })
        }
    }

    /**
     * @param {Array} data - cart items
     */
    getTotalTax (data) {
        return data.reduce((a, c) => {
            a += c.price.taxes
            return a
        }, 0)
    }

    /**
     * @param {object} items - api response object
     */
    finalizeLoadingItems (items) {
        this.check_all = false
        this.hideFiltersIfNeeded(items.items)
        this.checkSubmission()
        if (!this.are_filters_applied()) {
            this.keep_filters_open = false
            this.filters_applied = false
        }
    }

    /**
     * @param {object} e - click event
     * @param {object} rss - api response items[x]
     */
    checked (e, rss) {
        let item = rss
        if (e) e.preventDefault()
        item = this.items.find((x) => x.id === item.id)
        setTimeout(() => {
            e.target.checked = item.selected
        }, 1)
        this.disable_delete_button = !this.items.find((x) => x.selected)
    }

    /**
     *
     */
    hideFiltersIfNeeded () {
        for (const key of Object.keys(this.filters)) {
            if (this.filters[key] || this.filters[key] === 0) return (this.hide_filters = false)
        }

        this.hide_filters = !this.items.length

        return this.hide_filters
    }

    /**
     * @param {boolean} close_filters
     */
    clear_filters (close_filters = false) {
        this.keep_filters_open = !close_filters
        this.filters = { ...this.empty_filters }
        this.filters_applied = false
        if (this.saved_filter_name) this.saved_filter_name = null
        this.uri = this.baseUri
    }

    /**
     *
     */
    checkAll () {
        if (!this.items.length) return null

        for (const item of this.items) {
            item.selected = true
        }
        this.disable_delete_button = false

        return true
    }

    /**
     *
     */
    uncheckAll () {
        if (!this.items.length) return null

        for (const item of this.items) {
            item.selected = false
        }
        this.disable_delete_button = true

        return true
    }

    /**
     *
     */
    checkSubmission () {
        this.disable_delete_button = !this.items.filter(
            (x) => x.selected
        ).length
        this.component.forceUpdate()
    }

    /**
     *
     */
    async bulkDelete () {
        this.deleting_finished = false
        this.loading = true
        window.deleting_in_progress = true
        this.deleting = {
            total: 0,
            status: []
        }
        if (typeof this.component.emit === 'function') this.component.emit('hidePagination')
        let deleteItems = this.items.filter((x) => x.selected && x.id)
        if (typeof this.pre_delete_selected_filter === 'function') deleteItems = await this.pre_delete_selected_filter(deleteItems)
        await this.delete_items(deleteItems)
        if (!this.items.length) {
            this.everythingDeleted = true
        } else {
            this.uncheckAll()
        }
        // this.loading = false;
    }

    /**
     * @param {string} uri - uri to pull resource to be deleted - with filters or without
     */
    async deleteAll (uri) {
        let searchUri = uri
        this.deleting_finished = false
        this.loading = true
        window.deleting_in_progress = true
        searchUri = searchUri || this.uri
        this.deleting = {
            total: 0,
            status: []
        }
        if (typeof this.component.emit === 'function') this.component.emit('hidePagination')
        let deleteItems = await this.session.get_list_all(searchUri)
        deleteItems = deleteItems.items
        if (typeof this.pre_delete_all_filter === 'function') deleteItems = await this.pre_delete_all_filter(deleteItems)

        const deleted_items = await this.delete_items(deleteItems)
        if (!this.items.length) {
            this.everythingDeleted = true
        }

        if (this.everythingDeleted && !deleted_items.failed) {
            if (typeof this.component.emit === 'function') this.component.emit('hidePagination')
            if (!this.filters_applied) {
                this.hide_filters = true
            }
        }
    }

    /**
     * @param {Array} deleteItems - array of items to be deleted
     */
    async delete_items (deleteItems) {
        this.deleting = {
            total: deleteItems.length,
            status: []
        }

        for (const item of deleteItems) {
            if (this.stopDeletion) {
                break
            }
            try {
                if (typeof this.pre_delete === 'function') await this.pre_delete(item)
                await this.session.delete_item(`${this.baseUri}/${item.id}`)
                this.deleting.status.push({
                    status: 'success',
                    message: l.t('app.successfully-deleted', 'Successfully deleted'),
                    name: this.composeName(item)
                })
                if (typeof this.post_delete === 'function') await this.post_delete(item)
                this.removeFromCache(item)
                const index = this.items.findIndex((x) => x.id === item.id)
                if (index > -1) {
                    this.items.splice(index, 1)
                }
            } catch (err) {
                this.deleting.status.push({
                    status: 'failed',
                    message: Resource.findError(err, item),
                    name: this.composeName(item)
                })
            }
            this.check_all = false
        }
        this.stopDeletion = false
        window.scroll(0, 0)

        window.deleting_in_progress = false
        setTimeout(() => {
            this.generateDeletionsAlerts()
            if (typeof this.component.emit === 'function') this.component.emit('showPagination')
            this.deleting_finished = true
            this.loading = false
        }, 1000)

        return {
            success: this.deleting.status.filter((x) => x.status === 'success').length,
            failed: this.deleting.status.filter((x) => x.status === 'failed').length
        }
    }

    /**
     * @param {object} item - api response items[x]
     */
    composeName (item) {
        if (item.name) return item.name
        if (this.baseUri.includes('/contacts')) {
            return [item.first_name, item.middle_name, item.last_name]
                .filter((x) => x).join(' ')
        }
        if (item.id) return `#${item.id}`

        return ''
    }

    /**
     * @param {object} to - route to object
     * @param {object} from - router from object
     * @param {Function} next - router next() method
     */
    handleUserRedirect (to, from, next) {
        if (window.deleting_in_progress) {
            const answer = window.confirm(
                l.t('app.stop-deletion', 'Do you want to leave the page and stop deletion?')
            )
            if (answer) {
                this.stopDeletion = true
                setTimeout(() => {
                    next()
                }, 3000)
            } else {
                next(false)
            }
        } else {
            next()
        }
    }

    /**
     * @param {string} k - base for cache key generator
     */
    cache_key (k) {
        let key = k
        if (this.extension) key += `-${this.extension}`
        return `${key}-autocomplete`
    }

    /**
     * @param {object} item - api response object
     * @param {string} mode - cache key for apiautocomplete
     */
    addToCache (item, mode) {
        if (!mode) {
            if (this.selector_mode && this.selector_mode.length) {
                for (const s_mode of this.selector_mode) {
                    this.cachier.addToCache(this.cache_key(s_mode), item, item.id)
                    const ac = new Autocomplete(s_mode, this.session, this.extension)
                    ac.add_to_cache(item)
                }
            }
        } else {
            this.cachier.addToCache(this.cache_key(mode), item, item.id)
            const ac = new Autocomplete(mode, this.session, this.extension)
            ac.add_to_cache(item)
        }
    }

    /**
     * @param {object} item - api response object
     * @param {string} mode - cache key for apiautocomplete
     */
    updateCache (item, mode) {
        if (!mode) {
            if (this.selector_mode && this.selector_mode.length) {
                for (const s_mode of this.selector_mode) {
                    this.cachier.updateCache(this.cache_key(s_mode), item, item.id)
                    const ac = new Autocomplete(s_mode, this.session, this.extension)
                    ac.update_cache(item)
                }
            }
        } else {
            this.cachier.updateCache(this.cache_key(mode), item, item.id)
            const ac = new Autocomplete(mode, this.session, this.extension)
            ac.update_cache(item)
        }
    }

    /**
     * @param {object} item - api response object
     */
    removeFromCache (item) {
        if (this.selector_mode && this.selector_mode.length) {
            for (const mode of this.selector_mode) {
                this.cachier.removeFromCache(this.cache_key(mode), item, item.id)
                const ac = new Autocomplete(mode, this.session, this.extension)
                ac.remove_from_cache(item)
            }
        }
    }

    /**
     * @param {string} name - route name where user will be redirected
     * @param {object} params - route params
     * @param {number} redirect_countdown - ms till the redirection
     * @param {object} item - api response object
     */
    successfulCreation (name, params, redirect_countdown, item) {
        this.alert = {
            level: 'success',
            message: l.t('app.successfully-created', 'Successfully created.'),
            status_code: 201
        }
        const modals = document.querySelectorAll('.v-dialog--active')
        if (!modals.length) {
            window.scrollTo(0, 0)
            window.successfulAlertTimeout = setTimeout(() => {
                let parameters = { redirection_after_creation: true }
                if (params) {
                    parameters = { ...parameters, ...params }
                }
                if (this.component.routerPush && name) this.component.routerPush(name, parameters)
            }, redirect_countdown || this.timeUntilRedirects)
        }
        if (typeof this.component.emit === 'function') this.component.emit('created', item)
        this.loading = false
    }

    /**
     * @param {string} message - message that will be displayed
     */
    successfulAlert (message) {
        this.alert = {
            level: 'success',
            message: message || l.t('app.success', 'Success')
        }

        this.hide_alert(3)
    }

    /**
     * @param {string} route_name - route name where user will be redirected
     * @param {object} params - route params
     * @param {number} redirect_countdown - ms till the redirection
     */
    successfulUpdate (route_name, params, redirect_countdown) {
        this.alert = {
            level: 'success',
            message: `${l.t('app.changes-saved', 'Changes saved')}.`,
            status_code: route_name ? 204 : 200
        }
        this.hide_alert(2.5)
        const modals = document.querySelectorAll('.v-dialog--active')
        if (!modals.length) {
            window.scrollTo(0, 0)
            if (route_name) {
                window.successfulAlertTimeout = setTimeout(() => {
                    let parameters = { redirection_after_update: true }
                    if (params) {
                        parameters = { ...parameters, ...params }
                    }
                    this.component.routerPush(route_name, parameters)
                }, redirect_countdown || this.timeUntilRedirects)
            }
        }
        if (typeof this.component.emit === 'function') this.component.emit('updated', this.item)
        EventBus.$emit('item_updated')
        this.loading = false
    }

    /**
     * @param {number} ms - time in ms until filters are applied, typing debounce
     */
    apply_dynamic_filters (ms) {
        if (this.dynamic_filters_timer) clearTimeout(this.dynamic_filters_timer)
        const delay = ms || this.dynamic_filters_delay
        if (typeof this.do_apply_dynamic_filters !== 'function') {
            throw new Error('You need to define do_apply_dynamic_filters method')
        }
        this.dynamic_filters_timer = setTimeout(() => {
            this.clearMessages()
            this.filters_applied = true
            this.do_apply_dynamic_filters()
        }, delay)
    }

    /**
     * @param {number} s - time in s until alert is hidden
     */
    csv_downloaded_successfully (s = 5) {
        this.alert = {
            message: l.t('app.csv-downloaded', 'Csv downloaded'),
            level: 'success'
        }
        this.hide_alert(s)
    }

    /**
     * @param {number} seconds - time in s when alert will be hidden
     * @param {string} type - default alert different alert can be shown - most used is default one
     */
    hide_alert (seconds, type) {
        clearTimeout(this.alert_timer)
        const alertType = type || 'alert'
        this.alert_timer = setTimeout(() => {
            this[alertType] = null
        }, seconds * 1000)
    }

    /**
     *
     */
    show_generic_error () {
        this.alert = {
            message: l.t('app.generic-error', 'Something went wrong'),
            level: 'error',
            status_code: 500
        }
        this.hide_alert(10)
        window.scrollTo(0, 0)
        this.loading = false
    }

    /**
     * @param {object} err - error object
     */
    static find_validation_message (err) {
        console.log(err)
        const controled_exceptions = ['ValidationException']
        let message = null
        if (err && err['@error'] && err['@error'].fields && Object.keys(err['@error'].fields).length) {
            const { fields } = err['@error']
            message = Resource.stringifyErrorFields(fields)
        } else if (
            err &&
            err['@error'] &&
            err['@error']['@message']
        ) {
            message = err['@error']['@message']
        } else if (controled_exceptions.includes(err.constructor.name) && err.message) {
            // eslint-disable-next-line prefer-destructuring
            message = err.message
        }
        if (message) message = message.split('.').join(' ')
        return message
    }

    /**
     * @param {object} fields - api error err['@error']['fields']
     */
    static stringifyErrorFields (fields) {
        const message = []
        for (const field of Object.keys(fields)) {
            let key = field
                .replace(/:/g, '')
                .replace(/@(\w+)/g, '')
                .replace('_', ' ').split('.')
            key = key.map((x) => x.charAt(0).toUpperCase() + x.slice(1)).filter((x) => x)
            key = key.join(' / ')
            if (fields[field] && typeof fields[field] === 'object') {
                message.push(`${key}: ${Resource.stringifyErrorFields(fields[field])}`)
                continue
            }
            const keyMessage = Array.isArray(fields[field]) ? fields[field].join('. ') : fields[field]
            message.push(`${key}: ${keyMessage}`)
        }

        return message.join(' ')
    }

    /**
     *
     */
    generateDeletionsAlerts () {
        this.clearMessages()
        if (!this.deleting.status.find((x) => x.status === 'failed')) {
            if (!this.everythingDeleted) {
                if (this.deleting.total === 0) {
                    this.alert = {
                        message: l.t(
                            'app.no-items-are-deleted',
                            'No items are deleted'
                        ),
                        level: 'success'
                    }
                } else if (this.deleting.total === 1) {
                    this.alert = {
                        message: l.t(
                            'app.item-successfully-deleted',
                            '{} item successfully deleted', [this.deleting.status.length]
                        ),
                        level: 'success'
                    }
                } else {
                    this.alert = {
                        message: l.t(
                            'app.multiple-successfully-deleted',
                            '{} items successfully deleted', [this.deleting.status.length]
                        ),
                        level: 'success'
                    }
                }
                this.hide_alert(20)
            }
        } else if (!this.deleting.status.find((x) => x.status === 'success')) {
            this.alert = {
                message: l.t(
                    'app.failed-deletions',
                    '{} items not deleted. Errors:', [this.deleting.status.length]
                ),
                level: 'error'
            }
            this.alert.message += this.constructor.group_messages_by_name(this.deleting.status.filter((x) => x.status === 'failed'))
            this.hide_alert(20)
        } else {
            this.successful_deletions = {
                message: l.t(
                    'app.multiple-successfully-deleted',
                    '{} items successfully deleted', [this.deleting.status.filter((x) => x.status === 'success').length]
                ),
                level: 'success'
            }
            this.failed_deletions = {
                message: l.t(
                    'app.failed-deletions',
                    '{} items not deleted. Errors:', [this.deleting.status.filter((x) => x.status === 'failed').length]
                ),
                level: 'error'
            }
            this.failed_deletions.message += this.constructor.group_messages_by_name(this.deleting.status.filter((x) => x.status === 'failed'))
            this.hide_alert(20, 'successful_deletions')
            this.hide_alert(20, 'failed_deletions')
        }
    }

    /**
     * @param {number} id
     */
    async delete_item (id) {
        this.deleting_item = id
        try {
            await this.session.delete_item(`${this.baseUri}/${id}`)
            this.removeFromCache(this.items.find((x) => x.id === id))
            this.items = this.items.filter((x) => x.id !== id)
            this.successfulAlert(l.t('app.successfully-deleted', 'Successfully deleted.'))
        } catch (err) {
            console.log(err)
            if (
                err['@error'] &&
                err['@error']['@httpStatusCode'] === 409 &&
                err['@error'].usage &&
                err['@error'].usage.length
            ) {
                this.route_using_resource = err['@error'].usage[0].id
            } else {
                this.validation_error(err)
            }
        }
        this.deleting_item = null
    }

    /**
     * @param {Array} messages
     */
    static group_messages_by_name (messages) {
        const mess_object = {}
        for (const m of messages) mess_object[m.name] = m.message
        const helper = Object.entries(mess_object).reduce((helperObj, item) => {
            const key = item[0]
            const val = item[1]
            if (Object.prototype.hasOwnProperty.call(helperObj, val)) {
                helperObj[val].push(key)
            } else {
                helperObj[val] = [key]
            }
            return helperObj
        }, {})

        const res = Object.entries(helper).reduce((theRes, item) => {
            const name = item[0]
            const message = item[1]
            const resKey = message.join(', ')
            theRes[resKey] = name
            return theRes
        }, {})
        let messages_string = Object.entries(res).map((m) => `${m[1]}`)
        messages_string = `\n${messages_string.join('\n')}`
        return messages_string
    }

    /**
     * @param {object} err - api error object
     * @param {object} item - api response item
     */
    static findError (err, item) {
        let message = Resource.find_validation_message(err)
        if (message) {
            message = `${item.name ? `${item.name}:` : ''} ${message}`
        } else {
            message = l.t('app.generic-error', 'Something went wrong')
        }

        return message
    }

    /**
     *
     */
    clearMessages () {
        this.alert = null
        this.successful_deletions = null
        this.failed_deletions = null
    }

    /**
     * @param {object} err
     * @param {boolean} redirect_404 - if we want to redirect to 404 page if rss not found
     */
    validation_error (err, redirect_404 = false) {
        window.scrollTo(0, 0)
        if (
            err.status === 404 &&
            redirect_404 &&
            !document.querySelector('.default-modal-content') &&
            typeof this.component.routerPush === 'function'
        ) {
            return this.component.routerPush('not-found')
        }
        try {
            const message = Resource.find_validation_message(err)
            if (message) {
                this.alert = {
                    message,
                    level: 'error',
                    status_code: err.status
                }
                if (err.status <= 500 && err.status !== 404) {
                    this.hide_alert(10)
                }
                this.loading = false
                this.csv_downloader = null
            } else {
                this.show_generic_error()
            }
        } catch (e) {
            this.show_generic_error()
        }

        return true
    }

    /**
     * @param {Array} data - array of api response items
     * @param {object} f - custom functions to build csv
     * @param {object} h - custom headers to build csv
     */
    build_csv (data, f, h) {
        const functions = f || this.functions
        const headers = h || this.headers
        let lines = [`${Object.values(headers).map((v) => this.constructor.csv_format_value(v)).join(',')}`]
        lines = lines.concat(
            data.map((item) => {
                let v
                const line = []
                for (const key of Object.keys(headers)) {
                    if (headers[key]) {
                        v = Object.prototype.hasOwnProperty.call(functions, key)
                            ? functions[key](item)
                            : item[key]
                        line.push(this.constructor.csv_format_value(v))
                    }
                }
                return line.join(',')
            })
        )
        return lines.join('\n')
    }

    /**
     * @param {string} v - dirty value of string to be put in a csv
     */
    static csv_format_value (v) {
        // eslint-disable-next-line newline-per-chained-call
        return typeof v === 'string' ? `"${v.split('\n').join('\\n').split(',').join(' ').split('"').join('\\"')}"` : v
    }

    /**
     * @param {string} csv - string with proper csv values
     * @param {string} name - file name
     */
    static download_csv (csv, name) {
        const file = new Blob([csv], {
            type: 'text/csv'
        })
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = name
        document.body.appendChild(a)
        a.click()
        setTimeout(() => {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
        }, 0)
    }

    /**
     *
     */
    async loadGroups () {
        const cache_key = this.cache_key('groups')
        const cachedGroups = this.cachier.getItem(cache_key)
        let groups
        if (cachedGroups) {
            groups = cachedGroups
        } else {
            const url = `/extensions/${this.extension}/contact-groups`
            const items = await this.session.get_list_all(url)
            if (!items.items.length) {
                items.items = await create_default_contact_groups(this.session, this.extension)
                items.total = items.items.length
                this.cachier.setItem(cache_key, items)
                groups = items
            }
        }
        return groups
    }

    /**
     * @param {object} event - js event
     * @param {string} property - this[property] where results will be stored
     */
    csvFileUploaded (event, property) {
        this.alert = null
        if (event.target.files.length) {
            if (!event.target.files[0].name.endsWith('.csv') && !this.possibleCsvMemeTypes.includes(event.target.files[0].type)) {
                return (this.alert = {
                    message: l.t('validation.csv-file', 'Uploaded file must be a CSV file'),
                    level: 'error'
                })
            }
            const file = event.target.files[0]
            const reader = new FileReader()
            reader.onload = (e) => {
                this[property] = e.target.result
            }
            reader.onerror = () => {
                this.alert = {
                    message: l.t('validation.error-reading-file', 'Error reading file.'),
                    level: 'error'
                }
            }
            reader.readAsText(file)

            return true
        }

        return false
    }

    /**
     *
     */
    resetDeleting () {
        this.successful_deletions = null
        this.failed_deletions = null
        this.stopDeletion = false
        this.everythingDeleted = false
        this.deleting = null
    }

    /**
     *
     */
    are_filters_applied () {
        const filters = Object.keys(this.filters).filter((x) => this.filters[x])
        if (!filters.length) return false
        if (filters.length === 1 && filters[0] === 'type' && this.filters.type === 'forever') return false
        return true
    }
}

export default Resource
