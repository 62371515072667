/* eslint-disable no-redeclare */
// import 'babel-polyfill';

/*
    This class wraps the global PHONECOM variable that is injected by the
    unified code base

    should wrap it with an auth object in the future?
*/
function getPhoneComObj () {
    /* global PHONECOM */
    if (typeof (PHONECOM) !== 'undefined') {
        return PHONECOM
        /* global V5PHONECOM */
    } else if (typeof (V5PHONECOM) !== 'undefined') {
        return V5PHONECOM
    }
    return null
}

// Wipe the current extension if the new voip_id different than the last login
const voip_id = getPhoneComObj() ? getPhoneComObj().voip_id : process.env.REACT_APP_VOIP_ID
let localStorageExtensionId = null
let localStoragePhoneNumber = null
try {
    if (window.localStorage.getItem('current_voip_id') !== voip_id) {
        window.localStorage.setItem('current_voip_id', voip_id)
        window.localStorage.removeItem('current_extension_id')
        window.localStorage.removeItem('current_phone_number')
    }

    if (window?.performance?.navigation?.type === 0 && (document.referrer.includes('phone.com/voip') || document.referrer.includes('phone.com/login'))) {
        window.localStorage.removeItem('current_extension_id')
        window.localStorage.removeItem('current_phone_number')
    }

    localStorageExtensionId = window.localStorage.getItem('current_extension_id')
    localStoragePhoneNumber = window.localStorage.getItem('current_phone_number')
} catch (e) {
    console.warn(e)
    console.warn('local storage disabled')
    window?.alert('Browser security settings set to block localstorage and cookies. This may cause the site to not operate in an expected way.')
}

let extension_id = localStorageExtensionId || process.env.REACT_APP_EXTENSION_ID
let phone_number = localStoragePhoneNumber || process.env.REACT_APP_PHONE_NUMBER
if (phone_number) { phone_number = phone_number.split(',') }

class PhoneComUser {
    static changeExtension = (ext) => {
        if (typeof (extension_id) !== 'undefined') {
            extension_id = ext
        } else if (typeof (PHONECOM) !== 'undefined') {
            PHONECOM.voip_phone_id = ext
        } else if (typeof (V5PHONECOM) !== 'undefined') {
            V5PHONECOM.voip_phone_id = ext
        }
        return PhoneComUser.getExtensionId()
    }

    static changePhoneNumber = (num) => {
        if (typeof (phone_number) !== 'undefined') {
            phone_number = num
        } else if (typeof (PHONECOM) !== 'undefined') {
            PHONECOM.phone_number = num
        } else if (typeof (V5PHONECOM) !== 'undefined') {
            V5PHONECOM.phone_number = num
        }
        return PhoneComUser.getPhoneNumber()
    }

    static getPhoneNumber = () => {
        let ret = phone_number
        if (!ret) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                ret = pdcObj.phone_number || ''
            }
            if (!ret && typeof (V5PHONECOM) !== 'undefined') {
                ret = V5PHONECOM.phone_number
            }
        }
        return ret
    }

    static getFeatures = () => {
        let ret = null
        if (!ret) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                ret = pdcObj.features || ''
            }
        }
        return ret
    }

    static getRedirectUrl = () => {
        let ret = null
        if (!ret) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                ret = pdcObj.redirect_url || ''
            }
        }
        return ret
    }

    static getLogoutUrl = () => {
        const redirect = PhoneComUser.getRedirectUrl()
        const base = redirect.split('?')[0]
        const token = PhoneComUser.getToken()
        const logout = `${base}?action=logout;_sid=${token}`
        return logout
    }

    static getCPUrl = () => {
        const redirect = PhoneComUser.getRedirectUrl()
        const base = redirect.split('/login')[0]
        const token = PhoneComUser.getToken()
        const cpUrl = `${base}/voip/?_sid=${token}`
        return cpUrl
    }

    static getControlPanelConferenceUrl = () => {
        const redirect = PhoneComUser.getRedirectUrl()
        const base = redirect.split('/login')[0]
        const token = PhoneComUser.getToken()
        const cpUrl = `${base}/voip/conference?_sid=${token}`
        return cpUrl
    }

    static getToken = () => {
        let ret = null
        if (!ret) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                // console.log('Value of pdcObj', pdcObj)
                ret = pdcObj.ac_token || pdcObj.cp_token || ''
            }
        }
        // console.log('Value of returned token', ret)
        return ret
    }

    static getScheme = () => {
        let ret = null
        if (!ret) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                ret = pdcObj.ac_token ? 'Bearer' : 'CP'
            }
        }
        return ret
    }

    // apiAccountId == voipId
    static getAPIAccountId = () => {
        let ret = null
        if (!ret) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                ret = pdcObj.voip_id || ''
            }
        }
        return ret
    }

    static getExtensionId = () => {
        let ret = null
        if (!ret) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                ret = pdcObj.voip_phone_id || ''
            }
        }
        return ret
    }

    // this should be pushed into its own library like phonecomuser
    static getv5ApiRoot = () => {
        /* global V5PHONECOM */
        let apiv5Root = null
        if (!apiv5Root) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                apiv5Root = pdcObj.v5ApiRoot
            }
        }
        return apiv5Root
    }

    static getStage = () => {
        /* global V5PHONECOM */
        let stage = null
        if (!stage) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                stage = pdcObj.stage
            }
        }
        return stage
    }

    static getv5ToolsRoot = () => {
        /* global V5PHONECOM */
        let toolsv5Root = null
        if (!toolsv5Root) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                toolsv5Root = pdcObj.v5ToolsRoot
            }
        }
        return toolsv5Root
    }

    static getv4ApiRoot = () => {
        /* global V5PHONECOM */
        let apiv4Root = null
        if (!apiv4Root) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) { /**/ }
            if (typeof (V5PHONECOM) !== 'undefined') {
                apiv4Root = V5PHONECOM.v4ApiRoot
            }
        }
        return apiv4Root
    }

    static getRole = () => {
        /* global V5PHONECOM */
        let role = null
        if (!role) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                role = pdcObj.role
            }
        }
        return role
    }

    static getHasActivePorts = () => {
        let hasActivePorts = null
        if (!hasActivePorts) {
            const pdcObj = getPhoneComObj()
            if (pdcObj) {
                hasActivePorts = pdcObj.has_active_ports
            }
        }
        return hasActivePorts
    }
}

export default PhoneComUser
