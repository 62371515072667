import React, { Component } from 'react'
import { Stepper, Variant as StepperVariant, StepInfo } from 'stepper'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import AvatarIcon from 'avatar-icon'
import { CloseIcon } from 'svg-icons/src/navigation'
import RingUsersIcon from './icons/RingUsersIcon'
import Section from './Section'
import Typography from 'typography'
import gtmDataPush from 'gtm-events'
import UsersDropdown from './UsersDropdown'
// import NoAnswerSection from './NoAnswerSection'

const GtmAppName = 'configure-number'

/***/
export enum CallUsersType {
    PARALLEL = 'parallel',
    SEQUENTIAL = 'sequential'
}

interface Props {
    classes: any
    selectedScheduleOption: any
    onCallOptionGroups: any
    users: Array<any>
    showValidationErrors: boolean
    formatUserString: (u: any) => string
    updateOnCallOptionGroups: (a: any) => void
    screenViewType: any
    companyExtension: any
}

interface State {
    draggingUserId: number
    dragDownOnIndex: number
    dragUpOnIndex: number
}

class RingUsersSection extends Component<Props, State> {
    /**
     *
     */
    constructor (props: Props) {
        super(props)
        this.state = { draggingUserId: null, dragDownOnIndex: -1, dragUpOnIndex: -1 }
    }

    /**
     * NOTE: Remove this once we start supporting sequential ringing and use the `renderUsersStepContentWAIT` function then.
     */
    renderUsersStepContent = (): JSX.Element => {
        const { users, selectedScheduleOption, showValidationErrors } = this.props
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        return (
            <UsersDropdown
                users = {users}
                selectedValue = {onCallOptionGroup.forward[0].users}
                isMultiple
                onChange = {event => {
                    gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'forward;user-click' }) // eslint-disable-line
                    onCallOptionGroup.forward[0].users = event.target.value
                    onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                    this.props.updateOnCallOptionGroups(onCallOptionGroups)
                }}
                showValidationErrors = {showValidationErrors}
            />
        )
    }

    /**
     * NOTE: This will be needed once we support parallel and sequential ringing.\
     * Until then let it stay as unused function.
     */
    renderUsersStepContentWAIT = (): JSX.Element => {
        const { classes } = this.props
        const selectedScheduleOption = this.props.selectedScheduleOption
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        const selectedUsers = onCallOptionGroup.forward[0].users.map((userString: string) => {
            const extensionId = parseInt(userString.split('-')[0])
            return this.props.users.find(u => u.extension.id === extensionId)
        })
        const remainingUsers = this.props.users.filter(u => !selectedUsers.includes(u))

        const onDragStart = dragInfo => this.setState({ draggingUserId: dragInfo.draggableId })

        const onDragUpdate = dragInfo => {
            if (!dragInfo.destination || !dragInfo.source) return
            const sourceIndex = dragInfo.source.index
            const destinationIndex = dragInfo.destination.index
            if (sourceIndex === destinationIndex) return this.setState({ dragDownOnIndex: -1, dragUpOnIndex: -1 })
            const draggingDown = sourceIndex < destinationIndex
            if (draggingDown && destinationIndex === this.state.dragDownOnIndex) return
            if (!draggingDown && destinationIndex === this.state.dragUpOnIndex) return
            if (draggingDown) this.setState({ dragDownOnIndex: destinationIndex, dragUpOnIndex: -1 })
            else this.setState({ dragDownOnIndex: -1, dragUpOnIndex: destinationIndex })
        }

        const onDragEnd = result => {
            const { destination, source } = result
            this.setState({ draggingUserId: null, dragDownOnIndex: -1, dragUpOnIndex: -1 })
            if (!destination) return
            if (destination.index === source.index) return

            const forwardUsers = [...onCallOptionGroup.forward[0].users]
            const draggedItem = forwardUsers.splice(source.index, 1)[0]
            forwardUsers.splice(destination.index, 0, draggedItem)

            onCallOptionGroup.forward[0].users = forwardUsers
            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
            this.props.updateOnCallOptionGroups(onCallOptionGroups)
        }

        const renderSelectUserField = (): JSX.Element => (
            <Select
                label = 'Add another user'
                value = {null}
                onChange = {e => {
                    const forwardUsers = [...onCallOptionGroup.forward[0].users]
                    forwardUsers.push(e.target.value)
                    onCallOptionGroup.forward[0].users = forwardUsers
                    onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                    this.props.updateOnCallOptionGroups(onCallOptionGroups)
                }}
                error = {this.props.showValidationErrors && !selectedUsers.length}
                formControlClasses = {{ root: 'select-form-control-root' }}
            >
                {remainingUsers.map((u, i) => {
                    const value = this.props.formatUserString(u)
                    const text = value.split('-').slice(1).join(' ')
                    return (
                        <MenuItem
                            key = {i}
                            value = {value}
                            checked = {value === onCallOptionGroup.onCallOption}
                        >{`${text[0].toUpperCase()}${text.substring(1)}`}</MenuItem>
                    )
                })}
            </Select>
        )

        const renderSelectedUser = (u, i): JSX.Element => (
            <Draggable key={u.id} draggableId={u.id} index={i}>
                {provided => {
                    const draggingClass = u.id === this.state.draggingUserId ? 'dragging' : ''
                    const underlineClass = i === this.state.dragDownOnIndex ? 'drop-underline' : ''
                    const overlineClass = i === this.state.dragUpOnIndex ? 'drop-overline' : ''
                    return (
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref = {provided.innerRef}
                            className = {`selected-user-item ${draggingClass} ${underlineClass} ${overlineClass}`}
                        >
                            <AvatarIcon
                                imageUrl = {u.avatar_url}
                                noBorder = {true}
                                opacity = {u.status === 'invite_pending' ? 0.7 : 1}
                                style = {{ marginRight: 15 }}
                                height = {40}
                                width = {40}
                            />
                            <span>{u.first_name} {u.last_name}</span>
                            <CloseIcon onClick={() => {
                                const forwardUsers = [...onCallOptionGroup.forward[0].users]
                                const thisUserString = this.props.formatUserString(u)
                                onCallOptionGroup.forward[0].users = forwardUsers.filter(c => c !== thisUserString)
                                onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                                this.props.updateOnCallOptionGroups(onCallOptionGroups)
                            }}/>
                        </div>
                    )
                }}
            </Draggable>
        )

        const renderSelectedUsers = (): JSX.Element => (
            <DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
                <Droppable droppableId='items-wrapper'>
                    {provided => (
                        <div
                            className={classes.selectedUsersWrapper}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {selectedUsers.map(renderSelectedUser)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )

        return (
            <div className={classes.selectUsersContent}>
                {renderSelectUserField()}
                {renderSelectedUsers()}
            </div>
        )
    }

    renderRingTypeContent = (): JSX.Element => {
        const selectedScheduleOption = this.props.selectedScheduleOption
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        return (
            <Select
                value = {onCallOptionGroup.forward[0].type}
                onChange = {e => {
                    onCallOptionGroup.forward[0].type = e.target.value
                    onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                    this.props.updateOnCallOptionGroups(onCallOptionGroups)
                }}
                error = {this.props.showValidationErrors && !onCallOptionGroup.forward[0].type}
                formControlClasses = {{ root: 'select-form-control-root' }}
            >
                <MenuItem
                    value = {CallUsersType.PARALLEL}
                    checked = {onCallOptionGroup.forward[0].type === CallUsersType.PARALLEL}
                >At the same time</MenuItem>
                <MenuItem
                    value = {CallUsersType.SEQUENTIAL}
                    checked = {onCallOptionGroup.forward[0].type === CallUsersType.SEQUENTIAL}
                >One after other</MenuItem>
            </Select>
        )
    }

    renderWhileRingingContent = (): JSX.Element => {
        const { classes } = this.props
        const selectedScheduleOption = this.props.selectedScheduleOption
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        const durations = [...Array(19).keys()].slice(1).map(e => e * 5)
        return (
            <div className={classes.whileRinging}>
                <Select
                    value = {onCallOptionGroup.forward[0].duration}
                    onChange = {e => {
                        onCallOptionGroup.forward[0].duration = e.target.value
                        onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                        this.props.updateOnCallOptionGroups(onCallOptionGroups)
                    }}
                    error = {this.props.showValidationErrors && !onCallOptionGroup.forward[0].duration}
                    formControlClasses = {{ root: 'select-form-control-root' }}
                >
                    {durations.map((seconds: number): JSX.Element => (
                        <MenuItem
                            key = {seconds}
                            value = {seconds}
                            checked = {onCallOptionGroup.forward[0].duration === seconds}
                        >{seconds / 5} rings ({seconds} seconds)</MenuItem>
                    ))}
                </Select>
                <Typography classes={{ root: 'while-playing' }} variant='body2'>while playing</Typography>
                <Select
                    label = 'Music on hold'
                    value = {onCallOptionGroup.forward[0].holdMusic}
                    onChange = {e => {
                        onCallOptionGroup.forward[0].holdMusic = e.target.value
                        onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                        this.props.updateOnCallOptionGroups(onCallOptionGroups)
                    }}
                    error = {this.props.showValidationErrors && !onCallOptionGroup.forward[0].holdMusic}
                    formControlClasses = {{ root: 'select-form-control-root' }}
                ></Select>
            </div>
        )
    }

    renderMainSection = (): JSX.Element => {
        const { classes, screenViewType } = this.props
        const { isMobileView } = screenViewType
        const steps: StepInfo[] = [
            {
                id: 'select-users',
                title: 'Select users to ring',
                active: true,
                content: this.renderUsersStepContent()
            }
            // {
            //     id: 'ring-type',
            //     title: 'Ring users',
            //     active: true,
            //     content: this.renderRingTypeContent()
            // },
            // {
            //     id: 'while-ringing',
            //     title: 'Ring each user for',
            //     active: true,
            //     content: this.renderWhileRingingContent()
            // }
        ]
        return (
            <Section screenViewType={screenViewType} classes={classes} icon={<RingUsersIcon/>} remoteConfigID='configure_number_ring_users_header_text'>
                <Stepper variant={StepperVariant.VERTICAL} steps={steps} classes={isMobileView ? { root: classes.mobileViewStepper } : {}}/>
            </Section>
        )
    }

    render = (): JSX.Element => {
        return (
            <>
                {this.renderMainSection()}
                {/* {NoAnswerSection(this.props)} */}
            </>
        )
    }
}

export default RingUsersSection
