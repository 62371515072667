import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=5973%3A4518
 *
 * @param {SvgIconProps} props - svg props
 */
export const UsersIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.8845 10.8744C13.7677 10.7827 15.1312 8.46299 15.0132 6.06187C14.8953 3.66075 13.2951 1.88198 11.2149 1.98328C9.13467 2.08458 7.76176 3.80598 7.87969 6.2071C7.99763 8.60822 10.0012 10.9661 11.8845 10.8744ZM8.83084 10.9207C8.93614 10.8503 9.02404 10.7915 9.09558 10.7585C9.48803 12.2748 10.9829 14.0229 12.5146 13.9249C13.6457 13.8525 13.9933 12.9772 13.8851 11.1019C14.0095 11.1632 14.0348 11.2266 14.0621 11.2946C14.0888 11.3615 14.1174 11.433 14.2436 11.5116C14.6836 11.7424 15.0674 11.8853 15.4057 12.0113C16.7012 12.4937 17.3315 12.7284 17.9116 16.7043C18.0372 17.5655 17.7775 18.4738 17.0692 18.9796C13.6882 21.3942 8.77495 19.7741 5.51501 18.6991C5.26091 18.6153 5.01685 18.5349 4.78434 18.4599C3.87117 18.1653 3.17697 17.3216 3.2375 16.3641C3.36678 14.3191 3.78977 13.2968 4.55309 12.4093C5.28211 11.5617 6.19842 11.5317 6.99249 11.5057C7.13053 11.5012 7.26488 11.4968 7.3939 11.4882C8.04662 11.4449 8.51634 11.1309 8.83084 10.9207Z" fill={color}/>
        </SvgIcon>
    )
}

export default UsersIcon
