import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
    options: {
        display: 'flex',
        padding: 15,
        borderRadius: 18,
        alignItems: 'center',
        width: 30,
        '&:not(.disabled)': {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.secondary['-300']
            }
        },
        '&.disabled': {
            cursor: 'not-allowed',
            '& .dot': {
                backgroundColor: theme.palette.secondary['-300']
            }
        },
        '&.horizontal': {
            flexDirection: 'row',
            '& .dot:not(:last-child)': {
                marginRight: 4
            }
        },
        '&.vertical': {
            flexDirection: 'column',
            '& .dot:not(:last-child)': {
                marginBottom: 4
            }
        },
        '& .dot': {
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: theme.palette.secondary['+100']
        }
    }
})

class Options extends Component {
    render () {
        const { classes } = this.props
        let variant = this.props.variant
        if (!['horizontal', 'vertical'].includes(variant)) variant = 'vertical'
        const disabled = this.props.disabled ? 'disabled' : ''
        return (
            <div onClick={this.props.onClick} className={`${classes.options} ${variant} ${disabled}`}>
                <div className='dot'></div>
                <div className='dot'></div>
                <div className='dot'></div>
            </div>
        )
    }
}

Options.propTypes = {
    /**
     * The variant of the Options
     * 'vertical' or 'horizontal'
     */
    variant: PropTypes.string,
    /**
     * Is it disabled
     * true or false
     */
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    onClick: PropTypes.func
}

Options.defaultProps = {
    variant: 'vertical',
    disabled: false
}

export default withStyles(styles)(Options)
