<template>
    <div v-bind="$attrs" v-on="$listeners" class="dynamic-filters">
        <slot></slot>
    </div>
</template>
<script>
  export default {
    props: [],
    data() {
      return {
      };
    },
  };
</script>
