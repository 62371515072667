import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=6024%3A8443
 *
 * @param {object} props - svg props
 */
export const LunchAltIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.38656 13.501L10.4947 10.3121L3.69632 3.34841C3.16914 2.80754 2.25756 2.95402 1.92808 3.65265C1.14829 5.3316 1.43385 7.39367 2.78474 8.79092L7.38656 13.501ZM14.8329 11.4615C16.5133 12.2615 18.8746 11.6981 20.6209 9.90647C22.7186 7.75425 23.125 4.66678 21.5105 3.01037C19.907 1.36522 16.8977 1.77087 14.789 3.92308C13.0427 5.71472 12.4936 8.13737 13.2734 9.86139L3.3229 20.0703C2.89457 20.5098 2.89457 21.2197 3.3229 21.6591C3.75123 22.0986 4.44315 22.0986 4.87148 21.6591L11.6699 14.7067L18.4573 21.6704C18.8856 22.1099 19.5775 22.1099 20.0059 21.6704C20.4342 21.231 20.4342 20.5211 20.0059 20.0816L13.2185 13.1179L14.8329 11.4615Z' fill={color}/>
        </SvgIcon>
    )
}

export default LunchAltIcon
