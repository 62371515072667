const defaultSettings = {
    companyName: '',
    meetingsDomain: '',
    address: { street: '', city: '', state: '', zip: '', country: '' },
    suiteApt: '',
    // voiceLanguage: '',
    // voiceName: '',
    holdMusic: '',
    holdMusicOptions: [{
        value: 'ringing-tone',
        content: '(Ringing Tone)'
    }]
}

/**
 * @param {object} state
 * @param {object} action
 */
export function settings (state = null, action) {
    let hasSelected
    switch (action.type) {
            case 'UPDATE_SETTINGS':
                hasSelected = false
                action.settings.holdMusicOptions = action.settings.holdMusicOptions.map(wm => {
                    if (wm.selected) hasSelected = true
                    return {
                        value: wm.id || wm.value,
                        content: wm.name || wm.content,
                        selected: wm.selected
                    }
                })
                action.settings.holdMusicOptions = defaultSettings.holdMusicOptions.concat(action.settings.holdMusicOptions)
                if (!hasSelected) action.settings.holdMusicOptions[0].selected = true
                return { ...defaultSettings, ...action.settings }
            default:
                return state
    }
}

/**
 * @param {object} state
 * @param {object} action
 */
export function voicemail (state = null, action) {
    switch (action.type) {
            case 'UPDATE_VOICEMAIL':
                return action.voicemail
            default:
                return state
    }
}

/**
 * @param {object} state
 * @param {object} action
 */
export function holdMusic (state = null, action) {
    switch (action.type) {
            case 'UPDATE_HOLD_MUSIC':
                return action.holdMusic
            default:
                return state
    }
}
