import React from 'react'
import { ChangeInfo, DEFAULT_CANCEL_TEXT, DEFAULT_CONFIRM_TEXT, DEFAULT_DETAILS, DEFAULT_QUESTION, LeaveLevel } from './LeaveProvider'
import ConfirmModal from 'confirm-modal'

interface Props {
    show: boolean
    data: Map<LeaveLevel, ChangeInfo>
    onReject: () => void
    onConfirm: () => void
    mobile: boolean
}

/***/
const Modal = ({ show, data, onReject, onConfirm, mobile }: Props): JSX.Element => {
    const isOneItem = data.size === 1
    const title = isOneItem ? [...data][0][1].question : DEFAULT_QUESTION
    const noButtonText = isOneItem ? [...data][0][1].cancelText : DEFAULT_CANCEL_TEXT
    const yesButtonText = isOneItem ? [...data][0][1].confirmText : DEFAULT_CONFIRM_TEXT
    const content = data.size > 0
        ? [...data].map(item => (
            <React.Fragment key={item[0]}>
                {item[1].details}<br/><br/>
            </React.Fragment>
        ))
        : DEFAULT_DETAILS
    return (
        <ConfirmModal
            isShown={show}
            title={title}
            content={content}
            noButtonText={noButtonText}
            yesButtonText={yesButtonText}
            yesButtonColor='attention'
            onReject={onReject}
            onConfirm={onConfirm}
            size={!mobile ? 'size550' : 'sizeMobile'}
        />
    )
}

export default Modal
