import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5666%3A129
 *
 * @param {SvgIconProps} props - svg props
 */
export const SupportIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.0834 11.0003C21.0834 16.5692 16.5689 21.0837 11 21.0837C5.43115 21.0837 0.916687 16.5692 0.916687 11.0003C0.916687 5.43145 5.43115 0.916992 11 0.916992C16.5689 0.916992 21.0834 5.43145 21.0834 11.0003ZM11.5619 12.4901C11.5535 12.4742 11.5433 12.4572 11.5323 12.4387C11.4735 12.3399 11.3895 12.1986 11.3949 11.9669C11.3949 11.4413 11.9317 11.1428 12.5362 10.8064C13.3159 10.3727 14.2084 9.87625 14.2084 8.74933C14.2084 7.53262 13.0152 6.41677 11.0732 6.41677C9.75139 6.41677 8.61735 7.04951 7.83335 7.8461C7.79465 7.88543 7.7662 7.98957 7.82659 8.06853C7.9103 8.14952 8.00624 8.24502 8.11817 8.35644L8.11826 8.35653C8.27506 8.51261 8.46324 8.69992 8.69315 8.92236C8.75201 8.97776 8.85172 8.89094 8.86864 8.87203C9.37108 8.31063 9.97928 7.82274 10.9037 7.82274C11.8901 7.82274 12.4194 8.47895 12.4194 9.00619C12.4194 9.52898 11.9227 9.84751 11.3813 10.1947C10.7327 10.6107 10.0198 11.0679 10.0198 11.9669L10.0198 11.993V11.993C10.0197 12.2467 10.0194 12.6343 10.1453 12.9077C10.1565 12.9321 10.1852 12.9181 10.2485 12.8871C10.3893 12.8184 10.7012 12.666 11.3731 12.666C11.4034 12.6691 11.62 12.6141 11.5619 12.4901ZM10.8944 15.3726C11.3347 15.3726 11.7055 14.9902 11.7055 14.5498C11.7055 14.121 11.3347 13.7501 10.8944 13.7501C10.4541 13.7501 10.0834 14.121 10.0834 14.5498C10.0834 14.9902 10.4541 15.3726 10.8944 15.3726Z" fill={color}/>
        </SvgIcon>
    )
}

export default SupportIcon
