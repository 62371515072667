import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styles from './styles'
import Section from './Section.tsx'
import { TextField } from 'text-field-mui'
import { Switch } from 'switch'
import { Checkbox, Variant as CheckboxVariant } from 'checkbox-mui'
import { stateOptions, provinceOptions, countryOptions } from '../../util/util'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import { FSPrivate } from 'privacy'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import LoaderFull from 'loader-full'
import Api from 'api'
import { theme } from 'get-theme'

class AccountInformationStep extends Component {
    constructor (props) {
        super(props)
        const passData = this.props.passData.accountInfo
        const companyName = passData?.companyName || ''
        const showCompanyNameSection = passData?.showCompanyNameSection || false
        const firstName = passData?.firstName || ''
        const lastName = passData?.lastName || ''
        const address = passData?.address || ''
        const address2 = passData?.address2 || ''
        const city = passData?.city || ''
        const state = passData?.state || 'State'
        const zip = passData?.zip || ''
        const country = passData?.country || 'Country'
        const accountNumber = passData?.accountNumber || ''
        const accountPin = passData?.accountPin || ''
        const wirelessNumber = passData?.wirelessNumber || false
        const addressOnFile = passData?.addressOnFile || {}
        const serviceSameAsBilling = passData?.serviceSameAsBilling || false
        this.state = {
            companyName,
            firstName,
            lastName,
            address,
            address2,
            city,
            state,
            zip,
            country,
            accountNumber,
            accountPin,
            wirelessNumber,
            isLoading: true,
            showCompanyNameSection,
            hasChange: false,
            addressOnFile,
            serviceSameAsBilling
        }
    }

    componentDidMount = () => {
        // scroll to top of page on mount
        const componentTitle = document.getElementsByClassName('titles')[0]
        componentTitle.scrollIntoView()
        Api.getCompanySettings().then((response) => {
            const address = response?.address
            this.setState({
                addressOnFile: address,
                isLoading: false
            })
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        const hasSaveRequest = this.props.saveId && (prevProps.saveId !== this.props.saveId)
        this.markThisAndNextStepsAsNotComplete(prevState)
        if (hasSaveRequest) this.save()
    }

    save = () => {
        const movingForward = (parseInt(this.props.saveId.split('#')[1]) > this.props.stepIndex)
        if (movingForward) {
            if (!this.validate()) return
        }
        const completed = movingForward ? true : this.props.passData?.accountInfo?.completed
        this.props.update({
            completed: completed,
            gotoNext: true,
            saveData: {
                completed: completed,
                companyName: this.state.companyName,
                showCompanyNameSection: this.state.showCompanyNameSection,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                address: this.state.address,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state !== 'State' ? this.state.state : '',
                zip: this.state.zip,
                country: this.state.country !== 'Country' ? this.state.country : '',
                accountNumber: this.state.accountNumber,
                accountPin: this.state.accountPin,
                isBusinessNumber: this.state.showCompanyNameSection,
                wirelessNumber: this.state.wirelessNumber,
                addressOnFile: this.state.addressOnFile,
                serviceSameAsBilling: this.state.serviceSameAsBilling
            }
        })
    }

    markThisAndNextStepsAsNotComplete = (prevState) => {
        if (this.state.hasChange) return
        if (
            prevState.companyName !== this.state.companyName ||
            prevState.firstName !== this.state.firstName ||
            prevState.lastName !== this.state.lastName ||
            prevState.address !== this.state.address ||
            prevState.address2 !== this.state.address2 ||
            prevState.city !== this.state.city ||
            prevState.state !== this.state.state ||
            prevState.zip !== this.state.zip ||
            prevState.country !== this.state.country ||
            prevState.accountNumber !== this.state.accountNumber ||
            prevState.accountPin !== this.state.accountPin ||
            prevState.showCompanyNameSection !== this.state.showCompanyNameSection ||
            prevState.wirelessNumber !== this.state.wirelessNumber
        ) {
            const saveData = { ...this.props.passData.accountInfo }
            saveData.completed = false
            this.props.update({
                completed: false,
                saveData: saveData
            })
            this.setState({ hasChange: true })
        }
    }

    validate = () => {
        let isValid = true
        isValid = !this.validateCompanyName() ? false : isValid
        isValid = !this.validateFirstName() ? false : isValid
        isValid = !this.validateLastName() ? false : isValid
        isValid = !this.validateAddress() ? false : isValid
        isValid = !this.validateCity() ? false : isValid
        isValid = !this.validateState() ? false : isValid
        isValid = !this.validateZip() ? false : isValid
        isValid = !this.validateCountry() ? false : isValid
        isValid = !this.validateAccountNumber() ? false : isValid
        isValid = !this.validateAccountPint() ? false : isValid
        if (!isValid) setTimeout(this.scrollToErrorElement, 10)
        return isValid
    }

    scrollToErrorElement = () => {
        const errorElement = document.getElementsByClassName('error')[0]
        const muiErrorElement = document.getElementsByClassName('Mui-error')[0]
        if (muiErrorElement) muiErrorElement.scrollIntoView(false)
        else if (errorElement) errorElement?.scrollIntoView(false)
    }

    scrollToBottom = () => {
        const bottom = document.getElementsByClassName('wireless-check')[0]
        bottom.scrollIntoView(true)
    }

    validateCompanyName = () => {
        if (!this.state.showCompanyNameSection) return true
        if (this.state.companyName.length === 0) {
            const error = 'Please enter a company name.'
            this.updateFieldValue('companyNameError', error)
            return false
        }
        return true
    }

    validateFirstName = () => {
        if (this.state.firstName.length === 0) {
            const error = 'Please enter a First Name.'
            this.updateFieldValue('firstNameError', error)
            return false
        }
        return true
    }

    validateLastName = () => {
        if (this.state.lastName.length === 0) {
            const error = 'Please enter a Last Name.'
            this.updateFieldValue('lastNameError', error)
            return false
        }
        return true
    }

    validateAddress = () => {
        if (this.state.address.length === 0) {
            const error = 'Please enter a valid address.'
            this.updateFieldValue('addressError', error)
            return false
        }
        return true
    }

    validateCity = () => {
        if (this.state.city.length === 0) {
            const error = 'Please enter a city.'
            this.updateFieldValue('cityError', error)
            return false
        }
        return true
    }

    validateState = () => {
        if (this.state.state === 'State' || this.state.state === '') {
            const error = 'Please select a state.'
            this.updateFieldValue('stateError', error)
            return false
        }
        return true
    }

    validateZip = () => {
        if (this.state.zip.length !== 5) {
            const error = 'Please enter a valid zip code.'
            this.updateFieldValue('zipError', error)
            return false
        }
        return true
    }

    validateCountry = () => {
        if (this.state.country === 'Country' || this.state.country === '') {
            const error = 'Please select a country.'
            this.updateFieldValue('countryError', error)
            return false
        }
        return true
    }

    validateAccountNumber = () => {
        if (!this.state.wirelessNumber) return true
        if (this.state.accountNumber.length === 0) {
            const error = 'Please enter a valid account number.'
            this.updateFieldValue('accountNumberError', error)
            // Longer timeout than scrollToErrorElement, i.e. this wins
            setTimeout(this.scrollToBottom, 50)
            return false
        }
        return true
    }

    validateAccountPint = () => {
        if (!this.state.wirelessNumber) return true
        if (this.state.accountPin.length === 0) {
            const error = 'Please enter a valid pin.'
            this.updateFieldValue('accountPinError', error)
            // Longer timeout than scrollToErrorElement, i.e. this wins
            setTimeout(this.scrollToBottom, 50)
            return false
        }
        return true
    }

    updateFieldValue = (field, value) => {
        value = field === 'zip' ? this.liveFormatZip(value) : value
        value = value.trimStart()
        field.includes('Error') ? this.setState({ [field]: value }) : this.setState({ [field]: value, [field + 'Error']: false })
    }

    updateCountry = (country) => {
        this.updateFieldValue('country', country)
        this.setState({
            state: 'State',
            zip: '',
            address: '',
            address2: '',
            city: ''
        })
    }

    liveFormatZip = (zip) => {
        let formatedZip = ''
        formatedZip = zip.replace(/[^\d]/g, '')
        if (formatedZip.length === 6) return formatedZip.slice(0, -1)
        if (formatedZip.length > 6) return ''
        return formatedZip
    }

    toggleCompanyNameSection = () => {
        this.setState({
            showCompanyNameSection: !this.state.showCompanyNameSection,
            companyNameError: false
        })
        if (!this.state.showCompanyNameSection) { this.setState({ companyName: '' }) }
    }

    toggleWireless = () => {
        this.setState({
            wirelessNumber: !this.state.wirelessNumber,
            accountNumberError: false,
            accountPinError: false

        })
    }

    toggleServiceSameAsBilling = () => {
        if (this.state.serviceSameAsBilling) {
            this.setState({
                serviceSameAsBilling: !this.state.serviceSameAsBilling,
                address: '',
                city: '',
                state: 'State',
                zip: '',
                country: 'Country'
            })
        } else {
            this.setState({
                serviceSameAsBilling: !this.state.serviceSameAsBilling,
                address: this.state.addressOnFile?.street,
                city: this.state.addressOnFile?.city,
                state: this.state.addressOnFile?.state,
                zip: this.state.addressOnFile?.zip,
                country: this.state.addressOnFile?.country
            })
        }
    }

    renderTextField = (testId, field, label, name, fullWidth, inactiveLabelOnly = false) => {
        return (
            <TextField
                label = {label}
                name = {name}
                value = {this.state[field]}
                onChange = {(e) => this.updateFieldValue(field, e.target.value)}
                onXClick = {() => this.updateFieldValue(field, '')}
                disabled = {this.state.isLoading}
                error={this.state[field + 'Error']}
                helperText={this.state[field + 'Error']}
                fullWidth = {fullWidth}
                noLabelOnActiveOrResolved = {inactiveLabelOnly}
                data-test-id = {testId}
            />
        )
    }

    renderCompanyNameContent = () => {
        return this.renderTextField('tw-company-name', 'companyName', 'e.g. Acme', 'company', true, true)
    }

    renderAutorizedPersonContent = () => {
        return (
            <form>
                <div className='section-row'>
                    {this.renderTextField('tw-account-info-first-name', 'firstName', '* First name:', 'first-name', true)}
                    {this.renderTextField('tw-account-info-last-name', 'lastName', '* Last name:', 'last-name', true)}
                </div>
            </form>
        )
    }

    renderAddressContent = () => {
        const stateItems = (this.state.country === 'US' || this.state.country === 'Country') ? stateOptions : provinceOptions
        const postalCodeText = (this.state.country === 'US' || this.state.country === 'Country') ? '* Zip code:' : '* Postal code:'
        const postalCodeType = (this.state.country === 'US' || this.state.country === 'Country') ? 'zip' : 'postalCode'
        return (
            <>
                <div className={'billing-check'}>
                    <Checkbox
                        variant={CheckboxVariant.PRIMARY}
                        label='Is the phone service address the same as your phone.com account billing address?'
                        checked={this.state.serviceSameAsBilling}
                        onChange={() => this.toggleServiceSameAsBilling()}
                        name='billing-checkbox'
                        value={this.state.serviceSameAsBilling}
                        data-test-id='tw-billing-check'
                        labelDataTestId='tw-billing-check-label'
                    />
                </div>
                <form>
                    <div className='section-row'>
                        <Select
                            id="country"
                            value={this.state.country}
                            onChange={e => this.updateCountry(e.target.value)}
                            error = {this.state.countryError}
                            helperText = {this.state.countryError}
                            fullWidth
                            label = 'Country'
                            name = 'country'
                            data-test-id = 'tw-country-select'
                        >
                            {countryOptions.map((country, i) => (
                                <MenuItem data-test-id='tw-country-list' key={i} value={country.value}>{country.content}</MenuItem>
                            ))}

                        </Select>
                    </div>
                    <div className='section-row'>
                        {this.renderTextField('tw-street-address', 'address', '* Street address:', 'address', true)}
                    </div>
                    <div className='section-row'>
                        {this.renderTextField('tw-street-additional-address', 'address2', 'Additional address:', 'address2', true)}
                    </div>
                    <div className='section-row'>
                        {this.renderTextField('tw-city-name', 'city', '* City:', 'city', true)}
                        <Select
                            id="state"
                            value={this.state.state}
                            onChange={e => this.updateFieldValue('state', e.target.value)}
                            error = {this.state.stateError}
                            helperText = {this.state.stateError}
                            fullWidth
                            label = {(this.state.country === 'US' || this.state.country === 'Country') ? 'State' : 'Province'}
                            name = 'state'
                            data-test-id = 'tw-state-select'
                        >
                            {stateItems.map((state, i) => (
                                <MenuItem data-test-id='tw-state-list' key={i} value={state.value}>{state.content}</MenuItem>
                            ))}

                        </Select>
                        {this.renderTextField('tw-zip-code', postalCodeType, postalCodeText, 'zip', true)}
                    </div>
                </form>
            </>
        )
    }

    renderAccountNumberContent = () => {
        return this.renderTextField('tw-account-number', 'accountNumber', 'Enter account number', 'account-number', true, true)
    }

    renderAccountPinContent = () => {
        return this.renderTextField('tw-account-pin', 'accountPin', 'Enter your pin', 'account-pin', true, true)
    }

    SectionWrapper = (props) => {
        if (props.hidden) return null
        return (
            <Section
                smallView={this.props.smallView}
                classes={this.props.classes}
                titleRemoteConfigID={props.titleRemoteConfigID}
                subtitleRemoteConfigID={props.subtitleRemoteConfigID}
            >
                {props.content}
            </Section>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary.main} size='bigger'/>
            </div>
        )
    }

    render () {
        const { classes } = this.props
        const SectionWrapper = this.SectionWrapper
        return (
            <div className={classes.accountInfoStep}>
                {this.state.isLoading ? this.renderLoader() : null}
                <Switch
                    checked={this.state.showCompanyNameSection}
                    label='This is a business number'
                    onChange={() => this.toggleCompanyNameSection()}
                    name='company-name-section-switch'
                    value={this.state.showCompanyNameSection}
                />
                <SectionWrapper
                    titleRemoteConfigID='tw_account_information_step_company_name_title'
                    subtitleRemoteConfigID='tw_account_information_step_company_name_subtitle'
                    content={this.renderCompanyNameContent()}
                    hidden={!this.state.showCompanyNameSection}
                />

                <Section
                    smallView={this.props.smallView}
                    classes={classes}
                    titleRemoteConfigID='tw_account_information_step_autorized_person_title'
                    subtitleRemoteConfigID='tw_account_information_step_autorized_person_subtitle'
                    title-test-id='tw-section-title-authorized-person'
                    subtitle-test-id='tw-section-subtitle-authorized-person'
                >
                    {this.renderAutorizedPersonContent()}
                </Section>

                <Section
                    smallView={this.props.smallView}
                    classes={classes}
                    titleRemoteConfigID='tw_account_information_step_address_title'
                    subtitleRemoteConfigID='tw_account_information_step_address_subtitle'
                    tooltipRemoteConfigID='configure_tw_account_information_step_address_tooltip'
                    title-test-id='tw-section-title-service-address'
                    subtitle-test-id='tw-section-subtitle-service-address'
                >
                    {this.renderAddressContent()}
                </Section>
                <FSPrivate>
                    <div className='step-row'>
                        <Section
                            smallView={this.props.smallView}
                            classes={classes}
                            titleRemoteConfigID='tw_account_information_step_account_number_title'
                            subtitleRemoteConfigID='tw_account_information_step_account_number_subtitle'
                            tooltipRemoteConfigID='tw_account_information_step_account_number_tooltip'
                            title-test-id='tw-section-title-account-number'
                            subtitle-test-id='tw-section-subtitle-account-number'
                        >
                            {this.renderAccountNumberContent()}
                        </Section>
                        <Section
                            smallView={this.props.smallView}
                            classes={classes}
                            titleRemoteConfigID='tw_account_information_step_account_pin_title'
                            subtitleRemoteConfigID='tw_account_information_step_account_pin_subtitle'
                            title-test-id='tw-section-title-account-pin'
                            subtitle-test-id='tw-section-subtitle-account-pin'
                        >
                            {this.renderAccountPinContent()}
                        </Section>
                    </div>
                </FSPrivate>
                <div className={'wireless-check'}>
                    <Checkbox
                        variant={CheckboxVariant.PRIMARY}
                        label='This is a wireless mobile number'
                        checked={this.state.wirelessNumber}
                        onChange={() => this.toggleWireless()}
                        name='wireless-number-checkbox'
                        value={this.state.wirelessNumber}
                        data-test-id='tw-wireless-check'
                        labelDataTestId='tw-wireless-check-label'
                    />
                    <RemoteInfoTipMui arrow remoteConfigIDs={['configure_tw_account_information_step_wireless_tooltip']} placement={RemoteInfoTipMuiPlacement.TOP}/>
                </div>
            </div>
        )
    }
}

AccountInformationStep.propTypes = {
    classes: PropTypes.object,
    updateWizardStep: PropTypes.func,
    name: PropTypes.string,
    smallView: PropTypes.string,
    saveId: PropTypes.string,
    update: PropTypes.func,
    passData: PropTypes.object,
    stepIndex: PropTypes.number
}

export default withStyles(styles)(AccountInformationStep)
