/**
 * @param {object} theme
 */
const styles = theme => ({
    textareaWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        columnGap: 2
    },
    textarea: {
        fontSize: 12.8,
        padding: 10,
        border: '1px solid gray',
        backgroundColor: '#F4F5F6',
        '&.error': {
            borderColor: '#EA0303',
            borderWidth: 2
        }
    },
    restoreIcon: {
        cursor: 'pointer'
    }
})

export default styles
