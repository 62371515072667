import React from 'react'
import MuiThemeProvider from '@material-ui/styles/ThemeProvider'
import { Theme } from '@material-ui/core/styles'
import { theme } from 'get-theme'

/**
 *
 */

interface ThemeOverride extends Theme {
    isPdcTheme: boolean
}
/**
 *
 */
export interface ThemeProviderProps {
    /**
     * theme being passed down for app being used, should not need to use
     */
    theme?: ThemeOverride
    /**
     * dom children in jsx. do not need to use. wrap your components
     */
    children: React.ReactNode
}

/**
 *
 */
export const ThemeProvider = (props: ThemeProviderProps): React.ReactElement => {
    return (
        <>
            <MuiThemeProvider theme={props.theme?.isPdcTheme ? props.theme : theme }>
                {props.children}
            </MuiThemeProvider>
        </>
    )
}

export default ThemeProvider

/**
 * get-theme wrapper
 */
export function getTheme () : any { return theme }
