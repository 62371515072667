<template>
    <w-autocomplete
        v-if="provinces"
        v-bind="$attrs"
        v-on="$listeners"
        name="canada-provinces-selector"
        :items="provinces"
        :placeholder="placeholder"
        :label="with_label ? placeholder : undefined"
        autocomplete="new-password"
        hide-details="auto"
        data-test-id="canada-provinces-selector"
    >
    </w-autocomplete>
</template>
<script>
import PROVINCES from '../../../../libs/geo/canada_provinces.json';

export default {
    props: {
        with_label: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            provinces: null,
            placeholder: this.$lang.t('app.province', 'Province')
        };
    },
    created() {
        this.$data.provinces = PROVINCES.map((x) => ({value: x.short, text: x.name}));
    },
};
</script>
