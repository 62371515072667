const numberManagerBase = process.env.REACT_APP_NUMBER_MANAGER_API_URL
const namespace = 'porting'

class API {
    static setBadgeValue = async (voipId, namespace, value, expiration = null) => {
        const url = `${numberManagerBase}/voip/${voipId}/set-badge-value`

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `CP ${window.V5PHONECOM.cp_token}`
        }

        const body = {
            namespace: namespace,
            value: value,
            expiration: expiration
        }

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers
        })
            .then(res => res.json())
            .catch(console.warn)
    }

    static getBadgeValues = async (voipId) => {
        const url = `${numberManagerBase}/voip/${voipId}/get-badge-value?`

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `CP ${window.V5PHONECOM.cp_token}`
        }
        try {
            const response = await fetch(url + new URLSearchParams({
                namespace: namespace
            }), {
                method: 'GET',
                headers
            })
            if (!response.ok) {
                return null
            } else {
                const badgeReadValue = await response.json()
                const badgeReadValues = {}
                badgeReadValues[namespace] = badgeReadValue
                return badgeReadValues
            }
        } catch (error) {
            console.log(error)
            return {}
        }
    }
}

export default API
