import React from 'react'
import MuiSwitch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ThemeProvider } from 'theme-provider'
import Typography from 'typography'

/**
 *
 */
// const styles = (theme) => {}

/**
 *
 */
export interface SwitchProps {
    /**
     * If `true`, the component is checked.
     */
    checked: boolean;
    /**
     * @param {object} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value` (string).
     * You can pull out the new checked state by accessing `event.target.checked` (boolean).
     */
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    /**
     * switch label
     */
    label?: string | JSX.Element
    /**
     * If true, the switch will be disabled.
     */
    disabled?: boolean;
    /**
     * Name attribute of the `input` element.
     */
    name?: string;
    /**
     * The value of the component. The DOM API casts this to a string.
     * The browser uses "on" as the default value.
     */
    value?: unknown;
    /**
     * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
     */
    inputProps?: Record<string, unknown>;

    className?: string
}

/**
 *
 */
export const Switch = ({
    checked = false,
    onChange = (): undefined => (undefined),
    label,
    disabled,
    name, value, inputProps, className
}: SwitchProps): React.ReactElement => {
    return (
        <ThemeProvider>
            <FormControlLabel
                control={
                    <MuiSwitch
                        className={className}
                        disableRipple
                        checked={checked}
                        onChange={onChange}
                        name={name || ''}
                        value={value}
                        inputProps={inputProps || {}}
                        disabled={disabled}
                    />
                }
                label = {<Typography variant='body1'>{label}</Typography>}
                labelPlacement='end'
                data-test-id = 'mui-switch'
            />
        </ThemeProvider>
    )
}

export default Switch
