import React from 'react'
import Spinner from 'spinner-2'
import { PlayIcon, PauseIcon } from 'svg-icons'

interface Props {
    classes
    isPlaying: boolean
    loading: boolean
    size: 'small' | 'big'
    onClick: () => void
}

/**
 * @param {Props} props
 */
const ActionButton = (props: Props): JSX.Element => {
    const { classes, loading, isPlaying, size, onClick } = props
    const Icon = loading ? Spinner : isPlaying ? PauseIcon : PlayIcon
    const loadingClass = loading ? 'loading' : ''
    return (
        <div
            className = {`${classes.actionButton} ${loadingClass} ${size}`}
            onClick = {onClick}
            data-test-id = 'audio-player-play-btn'
        >
            <div><Icon/></div>
        </div>
    )
}

export default ActionButton
