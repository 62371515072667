import React, { Component } from 'react'

import PDCButton from 'pdc-button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
    dialog: theme.confirmDialog.paper,
    dialogTitle: theme.confirmDialog.dialogTitle,
    dialogContent: theme.confirmDialog.dialogContent,
    dialogFooter: theme.confirmDialog.dialogFooter,
    rejectButton: {
        cursor: 'pointer',
        width: 'fit-content',
        '&:not(:hover) .hover': {
            display: 'none'
        },
        '&:hover .enabled': {
            display: 'none'
        }
    },
    confirmButton: {
        cursor: 'pointer',
        marginLeft: '20px !important',
        width: 'fit-content',
        '&:not(:hover) .hover': {
            display: 'none'
        },
        '&:not(.disabled):hover  .enabled': {
            display: 'none'
        }
    }
})

class InfoModal extends Component {
    constructor (props) {
        super(props)
        this.state = {
            title: props.title,
            content: props.content
        }
    }

    componentDidUpdate () {
        if (!this.props.isShown) return
        if (this.props.title === this.state.title && this.props.content === this.state.content) return
        this.setState({
            title: this.props.title,
            content: this.props.content
        })
    }

    render () {
        const { classes, isShown } = this.props
        return (
            <Dialog
                open = {isShown}
                classes = {{ paper: `${classes.dialog} ${this.props.size || ''}` }}
            >
                {this.state.title != null && (<DialogTitle classes={{ root: classes.dialogTitle }}>
                    {this.state.title}
                </DialogTitle>)}
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {this.state.content}
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogFooter }}>
                    <PDCButton
                        className = {classes.confirmButton}
                        onClick = {this.props.isShown ? this.props.onConfirm : null}
                        color = {this.props.buttonColor || 'primary'}
                        variant = 'new-primary'
                        figma = {true}
                    >
                        {this.props.buttonText || 'Ok'}
                    </PDCButton>
                </DialogActions>
            </Dialog>
        )
    }
}

InfoModal.propTypes = {
    title: PropTypes.any,
    content: PropTypes.any,
    isShown: PropTypes.any,
    classes: PropTypes.any,
    size: PropTypes.any,
    onConfirm: PropTypes.any,
    buttonColor: PropTypes.any,
    buttonText: PropTypes.any
}

export default withStyles(styles)(InfoModal)
