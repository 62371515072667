import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5537%3A4498
 *
 * @param {SvgIconProps} props - svg props
 */
export const ChevronForwardIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M9.64644 5.01032C9.84251 4.82002 10.155 4.82235 10.3482 5.01555L16.9797 11.647C17.175 11.8423 17.175 12.1589 16.9797 12.3541L10.3535 18.9804C10.1582 19.1756 9.84163 19.1756 9.64637 18.9804L9.02014 18.3541C8.82488 18.1589 8.82488 17.8423 9.02014 17.647L14.6666 12.0006L9.02312 6.32022C8.82689 6.1227 8.8298 5.80294 9.02959 5.60903L9.64644 5.01032Z' fill={color}/>
        </SvgIcon>
    )
}

export default ChevronForwardIcon
