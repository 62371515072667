import React, { Component } from 'react'

class CreditCardCurrentPrimary extends Component {

	render() {
		let content
		if (this.props.loading) {
			content = <p>Loading...</p>
		} else {
			content = <p>Your {this.props.card.brand} ending in {this.props.card.last4} (expiring {this.props.card.exp_month}/{this.props.card.exp_year}) is on file.</p>
		}
		return (
			<div className='currentPrimaryCard'>
				{content}
			</div>
		)
	}
}

export default CreditCardCurrentPrimary