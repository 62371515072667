<template>
	<DefaultModal v-model="show" @close="$emit('close')" :width="$attrs.width || width" data-test-id="confirmation-modal">
		<template v-slot:title>{{title}}</template>
			<slot>{{ message }}</slot>
			<template v-slot:buttons>
				<w-btn @click="$emit('close')" color="secondary" data-test-id="confirmation-modal-cancel-btn">{{l.t('app.cancel', 'Cancel')}}</w-btn>
				<w-btn @click="$emit('confirmed')" :color="confirm_color" data-test-id="confirmation-modal-confirm-btn">{{action_text || l.t('app.confirm', 'Confirm')}}</w-btn>
			</template>
	</DefaultModal>
</template>

<script>
import l from '../../../libs/lang';
import DefaultModal from './DefaultModal.vue';

export default {
	props: {
		'title': {
			'type': String,
			'default': l.t('app.are-you-sure', 'Are you sure?')
		},
		'message': {
			'required': false,
			'type': String,
		},
		'confirm_color': {
			'required': false,
			'type': String,
			'default': 'primary',
		},
		'action_text': {
			'required': false,
			'type': String,
		}
	},
	components: { DefaultModal },
	data() {
		return {
			l,
			show: true,
		};
	},
	computed: {
		width() {
			if (this.$props.size === 'small') return 500;
			if (this.$props.size === 'medium') return 700;
			if (this.$props.size === 'large') return 900;
			return 500;
		}
	}
};
</script>
