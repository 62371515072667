import { theme } from 'get-theme'
const mainTheme = theme

/**
 * These are the styles for the Menu component
 *
 * @param {object} contextTheme - passed by the theme provider
 */
const styles = contextTheme => {
    const theme = (contextTheme.palette.secondary['-300']) ? contextTheme : mainTheme
    return {
        formControl: {
            '&.disabled': {
                '&:not(.error) .MuiInputLabel-root': {
                    color: theme.palette.text.disabled,
                    '&.MuiInputLabel-shrink': {
                        '& > .MuiTypography-root, & > span': {
                            color: `${theme.palette.text.disabled} !important`
                        }
                    }
                },
                '&.error .MuiInputLabel-root': {
                    color: theme.palette.error.border,
                    '&.MuiInputLabel-shrink': {
                        '& > .MuiTypography-root, & > span': {
                            color: `${theme.palette.error.border} !important`
                        }
                    }
                },
                '& [class*="MuiInputBase-root"]': {
                    '& .adorned-end svg': {
                        color: theme.palette.text.disabled
                    }
                }
            },
            '&.error': {
                '& .MuiInputLabel-root': {
                    color: theme.palette.error.main,
                    '&.MuiInputLabel-shrink': {
                        '& > .MuiTypography-root, & > span': {
                            color: `${theme.palette.error.main} !important`
                        }
                    }
                }
            },
            '&:not(.error).open': {
                '& [class*="MuiInputBase-root"]': {
                    background: theme.palette.action.primaryFocus
                }
            }
        },
        select: {
            '&.has-label': {
                padding: '24px 34px 8px 12px'
            },
            '&.resolved': {
                // background: theme.palette.info.background
            },
            '&.use-chips': {
                padding: '8px 42px 7px 12px'
            }
        },
        ulList: {
            padding: 0,
            '& > [class*="MuiMenuItem-root"]': {
                background: theme.palette.paper.gray,
                '&:hover:not(.Mui-disabled)': {
                    background: theme.palette.action.primaryFocus
                },
                '&.Mui-selected': {
                    background: theme.palette.action.primaryFocus
                },
                '&.Mui-focusVisible': {
                    background: 'rgba(0, 0, 0, 0.08)'
                }
            }
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 6
        },
        helperTextDisabled: {
            '&.Mui-error': {
                color: theme.palette.error.border,
                '& svg': {
                    color: theme.palette.error.border
                }
            }
        }
    }
}

export default styles
