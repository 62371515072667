import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=6503%3A16348
 *
 * @param {SvgIconProps} props - svg props
 */
export const EditIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M18.0282 3.24734L20.7196 5.96447C21.0121 6.25972 21.0087 6.7365 20.7121 7.02758L19.0211 8.68704C18.8267 8.87783 18.5153 8.8779 18.3208 8.6872L15.2527 5.67902C15.0545 5.48465 15.0525 5.166 15.2484 4.96925L16.9638 3.24603C17.2575 2.95101 17.7352 2.9516 18.0282 3.24734ZM3.97071 16.6899L3.25505 20.241C3.22067 20.4116 3.36678 20.5643 3.53872 20.5374L7.19088 19.9668C7.50174 19.9182 7.78947 19.7731 8.01333 19.552L17.6327 10.0519C17.9328 9.75552 17.9296 9.26996 17.6254 8.97762L14.9037 6.36129C14.6084 6.07737 14.1398 6.083 13.8514 6.37391L4.37599 15.9301C4.16954 16.1383 4.02863 16.4025 3.97071 16.6899Z' fill={color}/>
        </SvgIcon>
    )
}

export default EditIcon
