import React from 'react'
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core'
import TextareaAutosize, { TextareaAutosizeProps } from '@material-ui/core/TextareaAutosize'
import { SoftAlertIcon } from 'svg-icons'
import Typography from 'typography'
import { ThemeProvider } from 'theme-provider'
import styles from './styles'
import RestoreIcon from '@material-ui/icons/Replay'

const useStyles = makeStyles(styles)

const getHelperText = (helperText, error): JSX.Element => {
    if (!helperText || !error) return helperText
    return <><SoftAlertIcon/><Typography variant='helperText'>{helperText}</Typography></>
}

interface Props extends TextareaAutosizeProps {
    error: boolean
    helperText?: string
    onRestore?: () => void
}

/**
 * Textarea component
 *
 * @param {Props} props - props
 */
export const Textarea = (props: Props): JSX.Element => {
    const { error, helperText, onRestore } = props
    const classes = useStyles()
    const otherProps: TextareaAutosizeProps = { ...props }
    const removePropNames = ['error', 'helperText', 'className']
    removePropNames.forEach((propName: string): boolean => delete otherProps[propName])
    const classNames = `${classes.textarea} ${props.className || ''} ${props.error ? 'error' : ''}`
    return (
        <ThemeProvider>
            <FormControl>
                <div className={classes.textareaWrapper}>
                    <TextareaAutosize {...otherProps} className={classNames}/>
                    {onRestore ? <div title='Reset' className={classes.restoreIcon} onClick={onRestore}><RestoreIcon/></div> : null}
                </div>
                {helperText || error ? <FormHelperText error={error}>{getHelperText(helperText, error)}</FormHelperText> : null}
            </FormControl>
        </ThemeProvider>
    )
}

export default Textarea
