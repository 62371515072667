import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import api from 'api'

class API {
    static getCompanySettings = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/get-account-settings`
        const response = await ajax.post(requestUrl, {})
        return response.data
    }

    static setCompanySettings = async data => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/set-account-settings`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static updateExtensionCallerId = async callerId => {
        const voipId = PhoneComUser.getAPIAccountId()
        const extensionId = PhoneComUser.getExtensionId()
        const baseUrl = PhoneComUser.getv4ApiRoot()
        const url = `${baseUrl}/accounts/${voipId}/extensions/${extensionId}`
        const data = { caller_id: callerId }
        const response = await ajax.patch(url, data)
        return response
    }

    static configureVoicemail = async data => {
        if (!data || !data.voip_recording) return
        const recordingId = data.voip_recording.voip_recording_id
        const extension = await api.getCompanyExtension()
        if (!extension || !extension.id) return
        const payload = {
            extension_id: extension.id,
            voip_recording_id: recordingId
        }
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/configure-voicemail`
        const response = await ajax.post(requestUrl, payload)
        return response.data
    }

    static isValidZipCode = async zipcode => {
        if (!zipcode) return { error: true }
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/sign-up/steps/enter-zip-code/check-zip-code`
        const response = await ajax.post(requestUrl, { zipcode })
        if (response.response && response.response.status >= 400) {
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling invite users endpoint', err)
            return { error: true }
        }
    }
}

export default API
