import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=10071%3A26422
 *
 * @param {SvgIconProps} props - svg props
 */
export const CheckAltIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill={color}/>
            <path d='M7.8829 11.0945C7.78487 10.9993 7.62858 11.0005 7.53196 11.0971L6.34374 12.2853C6.24593 12.3831 6.24614 12.5418 6.3442 12.6393L10.2118 16.4868C10.3112 16.5856 10.4723 16.5836 10.569 16.4822L17.6645 9.04987C17.7583 8.95163 17.7565 8.79649 17.6604 8.70046L16.4734 7.51338C16.3746 7.41455 16.2139 7.41595 16.1168 7.51649L10.3488 13.4891L7.8829 11.0945Z' fill='white'/>
        </SvgIcon>
    )
}

export default CheckAltIcon
