<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <circle cx="20" cy="20" r="20" fill="currentColor"/>
        <mask id="mask0_5237_66545" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="19" width="30" height="15">
        <rect x="5.94727" y="19.4592" width="28.1081" height="14.0541" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_5237_66545)">
        <circle cx="20.0018" cy="19.4593" r="12.5135" stroke="white" stroke-width="2"/>
        </g>
        <path d="M20.002 22.4322V8.10791" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <path d="M13.7852 14.3241L20.0014 8.10791L26.2176 14.3241" stroke="white" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
  export default {};
</script>
