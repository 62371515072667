<template>
  <div class="w-body-2 pa-md-2 pl-0 form-input-label my-auto form-input-label"><slot></slot></div>
</template>

<script>
  export default {};
</script>

<style scoped>

</style>
