import React from "react";
import classNames from "classnames"
import "./filter-button.css"
import { XIcon } from 'pdc-svg-icons'


class FilterButton extends React.Component {

  render() {

    let prettyFilters;
    if (this.props.selectedFilters.length > 0) {
      if (this.props.stringifySelected) {
        prettyFilters = this.props.stringifySelected(this.props.selectedFilters);
      }
      else {
        prettyFilters = this.props.selectedFilters.join(', ');
      }
    }
    if (!prettyFilters) {
      prettyFilters = this.props.defaultText;
    }

    let buttonClass = classNames({
      "link-button": true,
      "selections-made": !!this.props.selectedFilters.length && prettyFilters !== this.props.defaultText,
      "loading": this.props.loading
    });


    let iconClass = classNames({
      "fa": true,
      "fa-fw": true,
      "fa-caret-down": this.props.selectedFilters.length == 0,
      "fa-times": this.props.selectedFilters.length,
      "filter-button-dropdown": true
    })

    if (this.props.sliderFilter){
      if (this.props.selectedFilters[0] == 49 && this.props.selectedFilters[1] == 1000){
        iconClass = classNames({
          "fa": true,
          "fa-fw": true,
          "fa-caret-down": true,
          "fa-times": false
        })
      }
    }
    let buttonIcon = null
    if (this.props.selectedFilters.length == 0){
      buttonIcon = <i id='reset-icon' onClick={this.iconClicked} className={iconClass} aria-hidden="true"/>

    } else {
      buttonIcon = <XIcon id='reset-icon' className='x-icon' onClick={this.iconClicked}/>
    }



    return (
      <div className={`button-wrapper ${this.props.selectedFilters.length > 0 ? 'active': null} ${this.props.open ? 'open': null}`}>
        <span>
          <button
            data-test-id={`filter-button-${this.props['data-test-id']}`}
            className={buttonClass} id="filter-button-no-focus-border"
            onClick={this.handleClick}
          >
            <div className='filter-button-label'>{prettyFilters}</div>
            {buttonIcon}
            {/* <i id='reset-icon' onClick={this.iconClicked} className={iconClass} aria-hidden="true"/> */}
          </button>
        </span>
        {this.props.hoverbox}
      </div>
    );
  }

  handleClick = e => {
    e.persist()
    if (e.currentTarget.id !== 'reset-icon') {
      this.props.handleClick()
    }
  }

  iconClicked = e => {
    e.persist()

    if (e.currentTarget.id === 'reset-icon') {
      this.props.handleIconClick(this.props.sliderFilter ? [49, 1000] : null)
    }
  }
}

export default FilterButton

