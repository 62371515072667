import React, { Component } from 'react'
import api from '../../util/api_v5.js'
import LoaderFull from 'loader-full'
import ConfirmModal from 'confirm-modal'
import Prompt from 'pdc-prompt'
import { theme } from 'get-theme'
import PhoneComUser from 'phone-com-user'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PDCOpenConnection from 'pdc-open-connection'
import UploadIcon from '../../assets/upload-icon.svg'
import UploadedIcon from '../../assets/uploaded-icon.svg'
import styles from './myTransfersNumbersStyles'
import { formatPhoneNumber } from 'phone-numbers'
import TableMui from 'pdc-table-mui'
import { withStyles } from '@material-ui/core'
import { getFeatureEnabled } from 'feature-flag'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'

const mapStateToProps = state => ({
    smallView: state.smallView
})

class StatusHeader extends Component {
    render = () => {
        return (<div style={{ display: 'flex', alignItems: 'center' }}>Status&nbsp;
            <RemoteInfoTipMui
                arrow
                remoteConfigIDs={
                    [
                        'configure_numbers_transfer_requests_status_review',
                        'configure_numbers_transfer_requests_status_info',
                        'configure_numbers_transfer_requests_status_provider'
                    ]
                }
                placement={RemoteInfoTipMuiPlacement.TOP}
            />
        </div>)
    }
}
class COBHeader extends Component {
    render = () => {
        return (<div style={{ display: 'flex', alignItems: 'center' }}>Copy of Bill&nbsp;
            <RemoteInfoTipMui arrow remoteConfigIDs={['configure_numbers_transfer_requests_cob']} placement={RemoteInfoTipMuiPlacement.TOP}/>
        </div>)
    }
}
class CSRHeader extends Component {
    render = () => {
        return (<div style={{ display: 'flex', alignItems: 'center' }}>Customer Service Record&nbsp;
            <RemoteInfoTipMui arrow remoteConfigIDs={['configure_numbers_transfer_requests_csr']} placement={RemoteInfoTipMuiPlacement.TOP}/>
        </div>)
    }
}
class LOAHeader extends Component {
    render = () => {
        return (<div style={{ display: 'flex', alignItems: 'center' }}>LOA&nbsp;
            <RemoteInfoTipMui arrow remoteConfigIDs={['configure_numbers_transfer_requests_loa']} placement={RemoteInfoTipMuiPlacement.TOP}/>
        </div>)
    }
}

function isTollFree (did) {
    const TOLL_FREE_NPAS = ['800', '822', '833', '844', '855', '866', '877', '888']
    return TOLL_FREE_NPAS.includes(did.substr(2, 3))
}

class MyTransferNumbers extends Component {
    constructor (props) {
        super(props)
        this.state = {
            transferNumbers: [],
            redisUploads: {},
            loading: true,
            selectedFile: null,
            selectedFileName: null,
            uploadBool: false,
            uploadType: null,
            uploadDid: null,
            successfullyUploaded: {},
            pdcPromptOpen: false,
            pdcPromptColor: 'tertiary',
            pdcPromptMessage: null,
            uploadPending: false,
            portingFlag: false

        }
    }

    componentDidMount = async () => {
        const voipId = PhoneComUser.getAPIAccountId()
        const res = await api.getTransfers(voipId)
        this.setState({ transferNumbers: res.data.port_requests, redisUploads: res.data.uploaded_file_bools })
        this.setState({ loading: false })

        getFeatureEnabled('new_porting_uploads').then(enabled => {
            this.setState({ portingFlag: enabled })
        })

        PDCOpenConnection.onAccount('new-lnp-file-upload', this.onControlPanelFileUpload)
        if (this.props.routeProps) {
            if (this.props.routeProps.location.pathname.split('/').includes('tw-complete')) {
                this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Thank you. Your transfer request has been received and will be processed by a porting specialist. We will contact you soon with additional information.' })
                this.props.updateRouterHistory('transfer-requests')
            }
        }
    }

    onControlPanelFileUpload = async () => {
        const voipId = PhoneComUser.getAPIAccountId()
        const res = await api.getTransfers(voipId)
        this.setState({ redisUploads: res.data.uploaded_file_bools })
    }

    uploadFile = async (type, did) => {
        this.setState({ uploadPending: true })
        const voipId = PhoneComUser.getAPIAccountId()
        const reader = new FileReader()
        reader.onloadend = async () => {
            const base64String = reader.result
            const res = await api.uploadFile(voipId, base64String, `${type}/${did}`)
            if (res && res.data) {
                this.setState(prevState => ({
                    pdcPromptOpen: true,
                    pdcPromptMessage: 'File uploaded successfully',
                    pdcPromptColor: 'tertiary',
                    uploadBool: false,
                    successfullyUploaded: {
                        ...prevState.successfullyUploaded,
                        [type + '_' + did]: true
                    }
                }))
                const transfers = await api.getTransfers(voipId)
                this.setState({ transferNumbers: transfers.data.port_requests, redisUploads: transfers.data.uploaded_file_bools })
            }
            if (res.error) {
                this.setState({
                    pdcPromptOpen: true,
                    pdcPromptMessage: 'The file you selected is not supported. Please upload a file in any of the following formats: PDF, PNG, JPG, or JPEG ',
                    pdcPromptColor: 'attention',
                    uploadBool: false
                })
            }
            this.setState({ uploadPending: false })
        }
        reader.readAsDataURL(this.state.selectedFile)
    }

    onFileChange = (event, type, did) => {
        this.setState({
            uploadBool: true,
            selectedFile: event.target.files[0],
            selectedFileName: event.target.files[0].name,
            uploadType: type,
            uploadDid: did
        })
    }

    handlePdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null })

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} styles={{ loaderFull: { left: this.props.screenViewType.isMobileView ? '50%' : 'calc(50% + 80px)' } }} size='bigger' />
            </div>
        )
    }

    renderCopyOfBillColumn = transfer => {
        const { classes } = this.props
        let column = ''
        const typeDidPair = 'COB_' + transfer.did
        if (this.state.successfullyUploaded[typeDidPair] || this.state.redisUploads[typeDidPair] === '1') {
            column = (
                <div className={classes.uploadedFile}>
                    <p>
                        <img src={UploadedIcon} alt='Upload Icon' />
                        <span className={classes.spanText}>Uploaded</span>
                    </p>
                </div>
            )
        } else {
            column = (<label className={classes.customFileUpload}>
                <p className={classes.uploadText}>
                    <img src={UploadIcon} alt='Upload Icon' />
                    <span className={classes.spanText}>Upload</span>
                </p>
                <input type="file" key={this.state.selectedFileName || ''} onChange={() => this.onFileChange(event, 'COB', transfer.did)} style={{ display: 'none' }} accept="image/jpeg, image/png, application/pdf" />
            </label>)
        }
        return column
    }

    renderCSRColumn = transfer => {
        const { classes } = this.props
        let column = ''
        const typeDidPair = 'CSR_' + transfer.did
        if (this.state.successfullyUploaded[typeDidPair] || this.state.redisUploads[typeDidPair] === '1') {
            column = (
                <div className={classes.uploadedFile}>
                    <p>
                        <img src={UploadedIcon} alt='Upload Icon' />
                        <span className={classes.spanText}>Uploaded</span>
                    </p>
                </div>
            )
        } else {
            column = (<label className={classes.customFileUpload}>
                <p className={classes.uploadText}>
                    <img src={UploadIcon} alt='Upload Icon' />
                    <span className={classes.spanText}>Upload</span>
                </p>
                <input type="file" key={this.state.selectedFileName || ''} onChange={() => this.onFileChange(event, 'CSR', transfer.did)} style={{ display: 'none' }} accept="image/jpeg, image/png, application/pdf" />
            </label>)
        }
        return column
    }

    renderLoaColumn = transfer => {
        const { classes } = this.props
        let column = ''
        const typeDidPair = 'LOA_' + transfer.did
        if (this.state.successfullyUploaded[typeDidPair] || this.state.redisUploads[typeDidPair] === '1') {
            column = (
                <div className={classes.uploadedFile}>
                    <p>
                        <img src={UploadedIcon} alt='Upload Icon' />
                        <span className={classes.spanText}>Uploaded</span>
                    </p>
                </div>
            )
        } else if (isTollFree(transfer.did)) {
            column = (<label className={classes.customFileUpload}>
                <p className={classes.uploadText}>
                    <img src={UploadIcon} alt='Upload Icon' />
                    <span className={classes.spanText}>Upload</span>
                </p>
                <input type="file" key={this.state.selectedFileName || ''} onChange={() => this.onFileChange(event, 'LOA', transfer.did)} style={{ display: 'none' }} accept="image/jpeg, image/png, application/pdf" />
            </label>)
        } else { column = '-' }
        return column
    }

    generateTableHeadColumns = () => {
        const headColumns = [
            { content: 'Transfer Number', testId: 'porting-number' },
            { content: 'Temporary Number', testId: 'temp-number' },
            { content: 'Date Received', testId: 'date-received' },
            { content: <StatusHeader />, testId: 'status' },
            // { content: 'Notes', testId: 'notes' }, commented out intentionally until further notice
            this.state.portingFlag && { content: <COBHeader />, testId: 'copy-of-bill' },
            this.state.portingFlag && { content: <CSRHeader />, testId: 'csr' },
            this.state.portingFlag && { content: <LOAHeader />, testId: 'loa' }
        ].filter(Boolean)
        return headColumns
    }

    generateOneRow = transfer => {
        const columns = [
            { key: 0, content: formatPhoneNumber(transfer.did), primary: true, stringValue: formatPhoneNumber(transfer.did), testId: 'porting-number' },
            { key: 1, content: transfer.temp_did ? formatPhoneNumber(transfer.temp_did) : 'Processing...', testId: 'temp-number' },
            { key: 2, content: transfer.date_received, testId: 'date-received' },
            { key: 3, content: transfer.port_status, secondary: true, stringValue: transfer.port_status, testId: 'status' },
            // { key: 4, content: transfer.notes, testId: 'notes' },
            this.state.portingFlag && { key: 5, content: this.renderCopyOfBillColumn(transfer), testId: 'copy-of-bill' },
            this.state.portingFlag && { key: 6, content: this.renderCSRColumn(transfer), testId: 'csr' },
            this.state.portingFlag && { key: 7, content: this.renderLoaColumn(transfer), testId: 'loa' }
        ].filter(Boolean)
        return { key: transfer.did, columns }
    }

    generateTableRows = () => {
        const rows = this.state.transferNumbers.map(transfer => {
            return this.generateOneRow(transfer)
        })
        return rows
    }

    renderPortTable = () => {
        const { classes } = this.props
        return (

            <div className={`${classes.mainWrapper} ${this.props.smallView ? 'small' : ''} ${this.props.screenViewType.isTabletView ? 'tablet' : ''}`}>
                <TableMui
                    headColumns={this.generateTableHeadColumns()}
                    rows={this.generateTableRows()}
                    infiniteScroller={false}
                    reverseScroll={false}
                    // loadMore = {this.props.loadMore}
                    // hasMore = {this.props.hasMore}
                    listView={this.props.smallView}
                    dataAttr={'my-numbers'}
                />
            </div>
        )
    }

    renderConfirmModal = () => {
        const typeMap = {
            COB: 'Copy of Bill',
            LOA: 'Electronic Authorization',
            CSR: 'Customer Service Record'
        }

        return (
            <ConfirmModal
                isShown={this.state.uploadBool}
                title={`Upload ${typeMap[this.state.uploadType]}`}
                content={<p>
                    The following file will be uploaded: <br/><b>{this.state.selectedFileName}</b>
                </p>}
                noButtonText='Cancel'
                yesButtonText='Upload'
                loading={this.state.uploadPending}
                onReject={() => this.setState({ uploadBool: false, selectedFile: null, selectedFileName: null })}
                onConfirm={() => this.uploadFile(this.state.uploadType, this.state.uploadDid)}
                size = {this.props.smallView ? 'sizeMobile' : 'size550'}
            />
        )
    }

    render () {
        return (
            <>
                {this.renderConfirmModal()}
                {
                    this.state.loading
                        ? this.renderLoader()
                        : this.renderPortTable()
                }
                <Prompt isOpen={this.state.pdcPromptOpen} color={this.state.pdcPromptColor} position='bottom' onClose={this.handlePdcPromptClose} content={this.state.pdcPromptMessage} />
            </>
        )
    }
}

MyTransferNumbers.propTypes = {
    classes: PropTypes.object,
    smallView: PropTypes.boolean,
    screenViewType: PropTypes.object,
    routeProps: PropTypes.object,
    updateRouterHistory: PropTypes.func
}

export default withStyles(styles)(connect(mapStateToProps)(MyTransferNumbers))
