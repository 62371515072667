import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const BusinessHoursIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#364047'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M20 35.4545C28.5353 35.4545 35.4545 28.5353 35.4545 20C35.4545 11.4646 28.5353 4.54541 20 4.54541C11.4646 4.54541 4.54541 11.4646 4.54541 20C4.54541 28.5353 11.4646 35.4545 20 35.4545ZM20 33.1363C27.255 33.1363 33.1363 27.255 33.1363 20C33.1363 12.7449 27.255 6.86359 20 6.86359C12.7449 6.86359 6.86359 12.7449 6.86359 20C6.86359 27.255 12.7449 33.1363 20 33.1363Z' fill='white'/>
            <ellipse cx='19.9998' cy='30.8181' rx='0.96591' ry='0.965909' fill='white'/>
            <ellipse cx='9.18173' cy='20' rx='0.965909' ry='0.96591' fill='white'/>
            <ellipse cx='30.8182' cy='20' rx='0.965909' ry='0.96591' fill='white'/>
            <circle cx='15.3635' cy='10.7273' r='0.579545' fill='white'/>
            <ellipse cx='11.1135' cy='14.5909' rx='0.579545' ry='0.579546' fill='white'/>
            <ellipse cx='11.1135' cy='25.409' rx='0.579545' ry='0.579546' fill='white'/>
            <ellipse cx='15.3635' cy='29.2727' rx='0.579545' ry='0.579546' fill='white'/>
            <circle cx='24.6362' cy='29.2727' r='0.579546' fill='white'/>
            <ellipse cx='28.8862' cy='25.409' rx='0.579545' ry='0.579546' fill='white'/>
            <ellipse cx='28.8862' cy='14.5909' rx='0.579545' ry='0.579546' fill='white'/>
            <ellipse cx='24.6362' cy='10.7273' rx='0.579546' ry='0.579545' fill='white'/>
            <path d='M19.377 8.78177C19.4015 8.46242 19.6678 8.21582 19.9881 8.21582C20.3181 8.21582 20.5889 8.4771 20.6007 8.80691L20.966 19.034L26.754 23.2435C27.082 23.482 27.1633 23.9368 26.9384 24.2742C26.7269 24.5914 26.3123 24.7015 25.9713 24.531L19.6574 21.3741C18.9301 21.0104 18.4954 20.2426 18.5577 19.4318L19.377 8.78177Z' fill='white'/>
        </SvgIcon>
    )
}

export default BusinessHoursIcon
