<template>
    <DefaultModal v-bind="$attrs" v-on="$listeners" :with_x="false" max-width="560" content-class="choose-vendor-modal">
        <w-loader v-if="loading"/>
        <div v-else>
            <div class="info--text text--darken-1 w-h5 text-center choose-vendor-modal-title">
                <span class="mx-auto">{{$lang.t('lr.which-lr-service-youd-use', 'Which receptionist service would you like to use')}}?</span>
            </div>
            <div v-for="v of vendor.eligible_vendors" class="choose-vendor" @click="$emit('click:on-vendor', v.id)">
                <div class="vendor-details w-100">
                    <v-img :src="v.img ? images[v.img] : images['live-receptionist.svg']" width="100" max-width="100" height="100" />
                    <div class="vendor-text-details">
                        <div class="vendor-title">{{v.name}}</div>
                        <div class="vendor-description w-caption secondary--text text--lighten-1">{{v.description}}</div>
                    </div>
                </div>
                <div class="go-fwd-btn">
                    <v-icon size="26">$vuetify.icons.chevron_forward</v-icon>
                </div>
            </div>
        </div>
    </DefaultModal>
</template>
<script>
import LiveAnswerVendor from '../../../console/src/models/LiveAnswerVendor';
import OnvegoImg from '../../../vue-apps-assets/images/live-receptionist/onvego.svg'
import LRImg from '../../../vue-apps-assets/images/live-receptionist/live-receptionist.svg'
import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';

export default {
    components: {DefaultModal},
    data() {
      return {
        loading: false,
        images: {
            'live-receptionist.svg': LRImg,
            'onvego.svg': OnvegoImg,
        },
        vendor: new LiveAnswerVendor(),
      };
    },
    async created() {
        this.$data.loading = true;
        await this.$data.vendor.init();
        this.$data.loading = false;
    }
  };
</script>
