import React, { Component } from 'react'
import ConfirmModal from 'confirm-modal'
import { XIcon } from 'pdc-svg-icons'
import SelectionControl from 'selection-control'
import TextField from 'pdc-text-field'
import LoaderFull from 'loader-full'
import { convertNumberToE164, formatAsYouType } from 'phone-numbers'
import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import api from '../../util/api_v5'

import { withStyles } from '@material-ui/core'

export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const styles = theme => ({
	loadingDiv: theme.loadingDiv,
	callForwardingSetupModalContent: {
		display:		'flex',
		flexDirection:	'column',
		'& .modal-info-alert': {
			position:		'relative',
			display:		'flex',
			flexDirection:	'column',
			background:		theme.palette.tertiary[-400],
			padding:		'10px 30px 10px 10px',
			border:			'none',
			borderRadius:	4,
			marginBottom:	10,
			'& .modal-info-alert-section': {
				fontSize: 14,
				'&:not(:last-child)': {
					marginBottom: 10
				}
			},
			'& .x-icon': {
				position:	'absolute',
				top:		10,
				right:		10,
				width:		20,
				height:		20,
				cursor:		'pointer',
				color:		theme.palette.secondary[0],
				'&:hover': {
					color:	'black'
				}
			}
		},
		'& .modal-forward-header': {
			fontSize:		18,
			fontWeight:		500,
			marginBottom:	5
		},
		'& .modal-forward-radios-section': {
			display:	'flex',
			alignItems:	'center',
			'& .modal-forward-radio-item': {
				display:	'flex',
				alignItems:	'center',
				cursor:		'pointer',
				'&:first-child': {
					marginRight: 30
				},
				'& .modal-forward-radio-wrapper': {
					padding:		3,
					marginRight:	5
				}
			}
		},
		'& .modal-forward-number-field': {
			maxWidth: 300
		}
	}
})

class CallForwardingSetupModal extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading:							false,
			forwardPhoneNumber:					'',
			forwardNumberError:					false,
			showInfoAlertInCallForwardModal:	localStorage.cf_mu_cf_alert_hidden !== 'true',
			shouldForwardCalls:					false
		}
		this.forwardPhoneNumber = ''
	}

	componentDidMount = async () => {
		this.setState({loading: true})
		let phoneNumber = await this.getForwardNumbers()
		if (phoneNumber) {
			this.setState({
				forwardPhoneNumber:	formatAsYouType(phoneNumber),
				shouldForwardCalls:	true
			})
			this.forwardPhoneNumber = formatAsYouType(phoneNumber)
		}
		this.setState({loading: false})
	}

	getForwardNumbers = async () => {
		let extension = await api.getExtension(this.props.user.extension.data.id)
		try {
			let route = extension.route
			if (!route) return null
			let rules = route.rules
			if (!rules || rules.length === 0) return null
			let actions = rules[0].actions
			if (!actions || actions.length === 0) return null
			if (actions[0].action !== 'forward') return null
			let forwardItems = actions[0].items
			if (!forwardItems || forwardItems.length === 0) return null
			let phoneNumberForwardAction = null
			forwardItems.forEach(item => {
				if (phoneNumberForwardAction) return
				if (item.type !== 'phone_number') return
				phoneNumberForwardAction = item
			})
			if (!phoneNumberForwardAction) return null
			let number = phoneNumberForwardAction.number
			// let screening = phoneNumberForwardAction.screening
			return number
		} catch (e) {
			return null
		}
	}

	saveForwardNumber = async () => {
		let user = this.props.user
		let shouldForwardCalls = this.state.shouldForwardCalls

		if (shouldForwardCalls) {
			let forwardPhoneNumber = this.state.forwardPhoneNumber

			if (!forwardPhoneNumber || (!isValidNumber(forwardPhoneNumber) && !isValidNumber(forwardPhoneNumber, 'US')))
				return this.setState({forwardNumberError: true})

			forwardPhoneNumber = forwardPhoneNumber ? convertNumberToE164(forwardPhoneNumber) : ''

			this.setState({loading: true})
			let asyncCalls = [api.setCallForwarding(forwardPhoneNumber, user.extension.data.id)]
			if (forwardPhoneNumber !== this.forwardPhoneNumber && this.forwardPhoneNumber) {
				asyncCalls.push(api.removeNumberForwarding(convertNumberToE164(this.forwardPhoneNumber), user.extension.data.id))
			}
			await Promise.all(asyncCalls)
			this.forwardPhoneNumber = forwardPhoneNumber
		} else {
			await api.removeNumberForwarding(convertNumberToE164(this.forwardPhoneNumber), user.extension.data.id)
		}

		this.setState({
			// callForwardingSetupModalUser:		null,
			forwardPhoneNumber:					'',
			forwardNumberError:					false,
			showInfoAlertInCallForwardModal:	localStorage.cf_mu_cf_alert_hidden !== 'true',
			loading:							false
		})
		this.props.onClose()
	}

	onReject = () => {
		this.setState({
			forwardNumberError:					false,
			showInfoAlertInCallForwardModal:	true,
			shouldForwardCalls:					false
		})
		this.props.onClose()
	}

	hideForwardInfoAlert = () => {
		localStorage.cf_mu_cf_alert_hidden = 'true'
		this.setState({showInfoAlertInCallForwardModal: false})
	}

	renderLoader = () => {
		const { classes } = this.props
		if (!this.state.loading) return null
		return <div className={classes.loadingDiv}><LoaderFull/></div>
	}

	render() {
		const { classes, user } = this.props
		let shouldForwardCalls = this.state.shouldForwardCalls
		let isAlertHidden = localStorage.cf_mu_cf_alert_hidden === 'true'
		let userName	= `${user.first_name} ${user.last_name}`
		let infoText1	= 'Call forwarding allows to forward calls for a user to an external number.'
		let infoText2	= 'All user\'s devices will still ring if you have call forwarding on (e.g. IP phone).'
		let infoText3	= 'You can only forward to 1 external number.'
		let infoText4	= 'If enabled, user will need to press 1 to accept calls.'
		let error		= this.state.forwardNumberError ? 'Invalid phone number' : ''
		let modalContent = (
			<div className={classes.callForwardingSetupModalContent}>
				{!isAlertHidden ?
					<div className={`modal-info-alert ${this.state.showInfoAlertInCallForwardModal ? '' : 'hidden'}`}>
						<span className='modal-info-alert-section'>{infoText1}</span>
						<span className='modal-info-alert-section'>{infoText2}</span>
						<span className='modal-info-alert-section'>{infoText3}</span>
						<span className='modal-info-alert-section'>{infoText4} <a target='_blank' href='https://www.phone.com/phone-support/my/user-settings#call-forwarding'>Learn why here ↗️</a></span>
						<XIcon className='x-icon' onClick={this.hideForwardInfoAlert}/>
					</div>
				: null}
				<div className='modal-forward-header'>Would you like to forward {userName}'s calls:</div>
				<div className='modal-forward-radios-section'>
					<div className='modal-forward-radio-item' onClick={() => this.setState({shouldForwardCalls: true})}>
						<SelectionControl
							variant		= 'radio'
							checked		= {shouldForwardCalls}
							name		= 'should-forward-calls'
							value		= 'should-forward-calls-on'
							className	= {{wrapper: 'modal-forward-radio-wrapper'}}
						/><span>Yes</span>
					</div>
					<div className='modal-forward-radio-item' onClick={() => this.setState({shouldForwardCalls: false})}>
						<SelectionControl
							variant		= 'radio'
							checked		= {!shouldForwardCalls}
							name		= 'should-forward-calls'
							value		= 'should-forward-calls-off'
							className	= {{wrapper: 'modal-forward-radio-wrapper'}}
						/><span>No</span>
					</div>
				</div>
				{shouldForwardCalls ?
					<TextField
						content			= {this.state.forwardPhoneNumber}
						label			= 'Enter phone number'
						onInputChange	= {forwardPhoneNumber => this.setState({forwardPhoneNumber: formatAsYouType(forwardPhoneNumber)})}
						className		= {{wrapper: 'modal-forward-number-field'}}
						error			= {error}
					/>
				: null}
				{this.renderLoader()}
			</div>
		)
		let confirmButtonDisabled = shouldForwardCalls ? this.forwardPhoneNumber === this.state.forwardPhoneNumber : !Boolean(this.forwardPhoneNumber)
		return (
			<ConfirmModal
				isShown					= {Boolean(user)}
				title					= {`Call forwarding for ${userName}`}
				content					= {modalContent}
				noButtonText			= 'Cancel'
				yesButtonText			= 'Save'
				confirmButtonDisabled	= {confirmButtonDisabled}
				onReject				= {this.onReject}
				onConfirm				= {this.saveForwardNumber}
				size					= 'size550'
			/>
		)
	}
}

export default withStyles(styles)(CallForwardingSetupModal)