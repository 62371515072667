<template>
    <v-tabs v-bind="$attrs" v-on="$listeners" @change="on_input" color="primary" class="custom-tabs" data-test-id="custom-tabs">
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
        <!-- Pass on all scoped slots -->
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </v-tabs>
</template>
<script>
export default {
    methods: {
        on_input(data) {
            this.$emit('input', data);
        }
    }
};
</script>
<style lang="scss">
@import 'vue-apps-assets/styles/scss/vars.scss';
.custom-tabs {
    .v-tab:hover:before,
    .v-tab:hover,
    .v-tab:before {
        background-color: transparent;
    }

    .v-tab:hover:before {
        border-bottom: 4px solid var(--v-primary-base);
        opacity: 0.2 !important;
        -webkit-transition: none;
        transition: none;
        margin-bottom: -1px;
    }

    .v-tabs-slider-wrapper {
        color: var(--v-info-base) !important;
        border-width: 3px;
    }

    .v-tab {
        text-transform: none !important;
        font-weight: 500;
        font-size: 14px !important;
        color: var(--v-primary-darken1) !important;

        border-bottom: $header-border;
        &:hover {
            border-bottom: 1px solid transparent;
        }
    }

    .v-tab--active {
        color: var(--v-text-base) !important;
    }
}
</style>
