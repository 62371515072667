<template>
	<svg v-if="backward" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M14.3533 18.9904C14.1572 19.1807 13.8447 19.1784 13.6515 18.9852L7.02006 12.3537C6.82479 12.1585 6.8248 11.8419 7.02006 11.6466L13.6463 5.02038C13.8415 4.82512 14.1581 4.82512 14.3534 5.02038L14.9796 5.64661C15.1749 5.84187 15.1749 6.15845 14.9796 6.35371L9.33317 12.0002L14.9766 17.6805C15.1729 17.878 15.17 18.1978 14.9702 18.3917L14.3533 18.9904Z" fill="currentColor"/>
	</svg>

	<svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M7.98073 4.23248C8.17679 4.04218 8.48931 4.04452 8.68252 4.23772L14.0918 9.64696C14.287 9.84222 14.287 10.1588 14.0918 10.3541L8.68776 15.7581C8.49249 15.9533 8.17591 15.9533 7.98065 15.7581L7.57664 15.3541C7.38138 15.1588 7.38138 14.8422 7.57664 14.647L12.2231 10.0005L7.57963 5.3267C7.38339 5.12919 7.3863 4.80943 7.58609 4.61551L7.98073 4.23248Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {
	props: ['backward'],
  };
</script>
