<template>
  <v-textarea
    v-bind="$attrs"
    v-on="$listeners"
    @input="input"
    @focus="on_focus"
    @blur="on_blur"
    @keydown.space="prevent_leading_space"
    :clearable="show_clearable && (!$attrs.hasOwnProperty('not-clearable') || $attrs['not-clearable'] === false)"
    clear-icon="mdi-close mdi-18px"
    color="primary"
    class="custom-textarea"
    hide-details="auto"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
  </v-textarea>
</template>
<script>
  import EventBus from 'event-bus/EventBus';

  export default {
    // custom props:
    //    not-clearable -> clearable by default, prevents default behavior
    data() {
      return {
        show_clearable: false,
      };
    },
    methods: {
      input(e) {
        EventBus.$emit('form_input_changed');
        this.$emit('input', e);
      },
      on_focus() {
        this.$data.show_clearable = true;
        this.$emit('focus');
      },
      on_blur() {
        this.$data.show_clearable = false;
        this.$emit('blur');
      },
      prevent_leading_space(e) {
        if (typeof e.target.value === 'string') {
          // only prevent the keypress if the value is blank
          if (!e.target.value) {
            e.preventDefault();
          // otherwise, if the leading character is a space, remove all leading white-space
          } else if (e.target.value[0] === ' ') {
            e.target.value = e.target.value.replace(/^\s*/, '');
          }
        }
      },
    },
  };
</script>
<style lang="scss">
.custom-textarea {
    textarea::placeholder {
      padding-top: 2px;
      line-height: 19px;
      color: var(--v-text-lighten1) !important;
    }
    textarea {
      padding-top: 13px;
      padding-bottom: 13px;
      line-height: 19px;
    }
    &.v-textarea .v-text-field__slot .v-label--active {
      display: none !important;
    }
  }
</style>
