<template>
	<div class="d-flex">
		<w-btn
			v-if="(value === null || (value && Object.values(value).every(x => !x))) && extension_selected"
			@click="dialog=true"
			text
			changes-form
			:color="trigger_btn_color"
			class="justify-start pl-0 my-auto"
		>
			<v-icon :color="trigger_btn_color">mdi-plus-circle</v-icon>  {{ l.t("call-flow.add-address", "Add address") }}
		</w-btn>
		<div v-if="extension_selected && format_address()" :style="{lineHeight: '14px'}" :class="{'my-auto' : !invalid_address}" class="w-body-2 mr-3 w-100">{{ format_address() }}</div>
		<DefaultModal 
			v-model="dialog"
			data-test-id="show_edit_address_modal"
			:width="500"
		>
			<template v-slot:title>{{ l.t('app.add-E911-address-for-this-device', 'Add E911 address for this device') }}</template>
			<AddressSelector
				v-model="shipping_address"
				:items="addresses"
				class="my-4 w-100"
				@new_address_added="on_address_input"
				:label="l.t('orders.e911-address', 'E911 address')"
				:key="JSON.stringify(shipping_address)"
				>
				<template v-slot:title>
					{{ l.t('app.enter-a-new-e911-address', 'Enter a new E911 address') }}
				</template>
			</AddressSelector>
			<template v-slot:buttons>
				<w-btn color="secondary" @click="close_modal">{{l.t('app.cancel', 'Cancel')}}</w-btn>
				<w-btn color="primary" :disabled="!shipping_address" @click="confirm_address">{{l.t('app.confirm', 'Confirm')}}</w-btn>
			</template>
		</DefaultModal>
		<v-spacer v-if="!format_address()"></v-spacer>
		<div class="ma-auto">
			<MoreOptions :options="options"/>
		</div>
	</div>
</template>

<script>
	import {countryName} from 'formatters';
	import {vueComponentEmitter, vueComponentChangeRoute} from 'helpers';
	import l from '../../libs/lang';
	import MoreOptions from './MoreOptions.vue';
	import AddressSelector from './form/AddressSelector.vue';
  	import DefaultModal from './modal/DefaultModal.vue';
	import Contact from '../../models/Contact';

	export default {
		props: {
			value: {
				type: [Object, null],
			},
			validation: {
				type: Boolean,
				default: false,
			},
			trigger_btn_color: {
				type: String,
				default: 'primary',
			},
			invalid_address: {
				type: Boolean,
				default: false,
			},
			extension_selected: {
				type: Boolean,
				default: false,
			}
		},
		components: {
			MoreOptions,
			AddressSelector,
			DefaultModal,
		},
		data() {
			return {
				l,
				empty_address: {
                    line_1: '',
                    line_2: '',
                    city: '',
                    province: '',
                    postal_code: '',
                    country: 'US',
                },
				dialog: false,
				contact: new Contact(this.$session, vueComponentEmitter(this), vueComponentChangeRoute(this)),
				addresses: [],
				shipping_address: null,
			};
		},
		async created() {
			const fetched_addresses = await this.$data.contact.loadAddresses();
      		if (Array.isArray(fetched_addresses) && fetched_addresses.length > 0) {
      		    for (const address of fetched_addresses) {
      		      this.$data.addresses.push(this.convert_address(address.address));
      		    }
      		}
		},
		computed: {
			options() {
				let options = [];
				options.push({
					title: l.t('app.edit-e911-address', 'Edit E911 address'),
					icon: '$vuetify.icons.edit',
					cb: () => this.$data.dialog = true,
					});
				options.push({
					title: l.t('app.remove-device', 'Remove device'),
					icon: '$vuetify.icons.edit',
					cb: () => this.$emit('removed'),
					});
				if (!this.extension_selected) options = options.filter((x) => !x.title.includes('Edit E911 address'));
				return options;
			}
		},
		methods: {
			confirm_address() {
				this.$emit('input', this.$data.shipping_address);
				this.dialog = false;
			},
			convert_address(address) {
				const convertedAddress = {
				...address,
				line_1: address.line_1.toUpperCase(),
				line_2: address.line_2.toUpperCase(),
				city: address.city.toUpperCase(),
				}
				return convertedAddress;
			},
			close_modal() {
				this.$data.dialog = false;
				this.$data.shipping_address = this.$props.value;
			},
			on_address_input(val) {
				this.$emit('input', val);
			},
			show_modal() {
				this.$data.dialog = true;
			},
			format_address() {
				if (!this.$props.value || !this.$props.extension_selected) return '';
				const address = [];
				for (const prop of Object.keys(this.$data.empty_address)) {
					if (this.$props.value[prop]) {
						if (prop === 'country') address.push(countryName(this.$props.value[prop]));
						else address.push(this.$props.value[prop]);
					}
				}
				return address.length ? address.join(', ') : '';
			},
		},
		watch: {
			extension_selected(v) {
				if (v) this.show_modal();
			}
		}
};
</script>
