import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=7886%3A18293
 *
 * @param {SvgIconProps} props - svg props
 */
export const UploadAltIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM14 13V16.5C14 16.7761 13.7761 17 13.5 17H10.5C10.2239 17 10 16.7761 10 16.5V13H7.65149C7.42237 13 7.31394 12.7175 7.48425 12.5642L11.8328 8.65052C11.9278 8.56495 12.0722 8.56495 12.1672 8.65052L16.5158 12.5642C16.6861 12.7175 16.5776 13 16.3485 13H14Z' fill={color}/>
        </SvgIcon>
    )
}

export default UploadAltIcon
