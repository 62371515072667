import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Navigation from './src/Navigation'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { OfflineBar } from 'offline-bar'
import { initializePhoneCom, getAppConfig, redirectLogin, getPhoneCom } from 'phonecom'
import api from './src/util/api'
import API from '../phones/src/components/util/api_phones'
import { initFirebaseRemoteConfig } from 'firebase-utils'
import PDCOpenConnection from 'pdc-open-connection'
import Prompt from 'pdc-prompt'

import Company from 'company'
import PhoneNumbers from 'phone-numbers-app'
import Phones from 'phones'
import Users from 'users-app'
import Account from 'account'
// import StatusColumn from './src/StatusColumn'
import PhoneComUser from 'phone-com-user'
import AppLoader from './src/AppLoader.js'
// import MarketingModal from '../../functional-components/marketing-modal/src/MarketingModal'

import { ThemeProvider } from 'providers'
import { theme } from 'get-theme'
import { ThemeContext } from 'providers'

import { withStyles } from '@material-ui/core'
import Cookies from 'js-cookie'
import Api from 'api'

import { setRollbar } from 'set-rollbar'
import { setFirebaseAnaliticsUserProperties } from 'firebase-utils'
import classNames from 'classnames'
import CompanySetup from 'company-setup'
import FeatureFlag from 'feature-flag'
import TopBar from './src/TopBar/TopBar'

const themeFontFamily = theme.fontFamily || 'Montserrat, ProximaNova, Helvetica, arial, sans-serif'
const styles = theme => ({
    mainDiv: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& *': {
            fontFamily: themeFontFamily
        }
    },
    contentContainer: {
        display: 'flex'
    },
    prompt: {
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: '45%'
        }
    },
    promptError: {
        '&.bottom': {
            bottom: '190px',
            [theme.breakpoints.up('sm')]: {
                bottom: '150px'
            }
        }
    }
})

class Configure extends Component {
    static contextType = ThemeContext
    constructor (props) {
        super(props)
        this.state = {
            screenViewType: {
                isMobileView: false,
                isTabletView: false,
                isTabletPortraitView: false
            },
            loading: true,
            appLoading: true,
            pdcPromptOpen: false,
            pdcPromptMessage: null,
            pdcErrorOpen: false,
            pdcErrorMessage: null,
            isOffline: false,
            navigationShow: false
        }
        this.CHRNotificationsSubscriptions = []
    }

    onConfigureCHRChange = (voipId, payload) => this.CHRNotificationsSubscriptions.forEach(subscription => subscription(payload))

    subscribeForCHRNotifications = (subscription) => {
        this.CHRNotificationsSubscriptions.splice(0, this.CHRNotificationsSubscriptions.length)
        this.CHRNotificationsSubscriptions.push(subscription)
    }

    onOnline = (e) => {
        if (this.state.isOffline) this.setState({ isOffline: 0 })
        // if (this.isOverInactiveMax(this.state.isOffline) || this.isOverInactiveMax(lastActiveTime)) {
        //     return window.location.reload()
        // }
    }

    onOffline = (e) => {
        console.log('offline', Date.now())
        this.setState({ isOffline: Date.now() })
    }

    componentDidMount = () => {
        this.setScreenView()
        window.addEventListener('resize', this.setScreenView)
        this.init()
        window.ononline = this.onOnline
        window.onoffline = this.onOffline
        window.addEventListener('online', this.onOnline)
        window.addEventListener('offline', this.onOffline)
    }

    componentDidUpdate () {
        this.fixUrlPath()
        this.setFavicon()
    }

    setFavicon = () => {
        const theme = this.context
        if (document.getElementsByName('favicon')[0].href !== theme.favicon.default) {
            document.getElementsByName('favicon')[0].href = theme.favicon.default
        }
        if (document.getElementsByName('apple-icon')[0].href !== theme.favicon.default) {
            document.getElementsByName('apple-icon')[0].href = theme.favicon.default
        }
        if (document.getElementsByName('apple-icon')[1].href !== theme.favicon.default) {
            document.getElementsByName('apple-icon')[1].href = theme.favicon.default
        }
    }

    init = async () => {
        const config = await getAppConfig()
        await this.initialLoad(config)
        if (!PDCOpenConnection.accountSubscriptions.includes('cart')) {
            PDCOpenConnection.onAccount('cart', this.onCartNotification)
        }
        PDCOpenConnection.onAccount('configure_chr', this.onConfigureCHRChange)
        //  window.dataLayer.push({'PDC_voip_id': window.V5PHONECOM.voip_phone_id})
    }

    handlepdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null })

    handlepdcErrorClose = () => this.setState({ pdcErrorOpen: false, pdcErrorMessage: null })

    onCartNotification = (eid, payload) => {
        console.log(payload)
        const unavailableDids = payload.unavailable_dids.map(did => did.did).join(', ')
        const purchasedDids = payload.purchased_dids.map(did => did.did).join(', ')
        if (purchasedDids) {
            this.setState({
                pdcPromptOpen: true,
                pdcPromptMessage: (
                    <div data-test-id='purchase-prompt'>
                        <div>The numbers you just ordered have been added to your account!</div>
                        <div>Please make sure to assign or configure them via the Edit pen icon.</div>
                    </div>
                )
            }
            )
        }
        if (unavailableDids) {
            this.setState({
                pdcErrorOpen: true,
                pdcErrorMessage: (
                    <div data-test-id='purchase-error'>
                        <div>Unfortunately, the following number(s) were not available. You were not charged.</div>
                        <div>{unavailableDids}</div>
                    </div>
                )
            }
            )
        }
    }

    onNewPortRequest = () => {
        window.location.reload()
    }

    onE911AddressUpdate = (extension, payload) => {
        this.setE911IncompleteCount()
    }

    saveBadgeValues = (badgeReadValues) => {
        const unreadPorting = badgeReadValues?.porting?.data?.value
        this.setState({ unreadPorting })
    }

    setBadgeValue = async (namespace, value) => {
        const res = await initializePhoneCom()
        const badge = api.setBadgeValue(res.account_id, namespace, value)
        if (badge) {
            this.setState({ unreadPorting: value })
        }
    }

    setE911IncompleteCount = async () => {
        API.getIncompleteE911Count().then((count) => {
            // If the endpoint returned an error, we have no data, so set it as an empty string
            const incompleteE911Count = count?.error === true ? '' : count
            this.setState({ incompleteE911Count })
        })
    }

    initialLoad = async (config = null) => {
        initFirebaseRemoteConfig()
        const res = await initializePhoneCom()
        const V5PHONECOM = await getPhoneCom()
        if (!res) return
        // BLOCKING CALLS CAUSE ISSUES FOR NON ADMINS
        api.getBadgeValues(res.account_id).then((badgeReadValues) => {
            if (badgeReadValues) {
                this.saveBadgeValues(badgeReadValues)
            }
        })

        this.setE911IncompleteCount()
        this.setHasActivePorts(V5PHONECOM.has_active_ports)
        this.setState({ company: res.company })

        PDCOpenConnection.onAccount('new-port-request', this.onNewPortRequest)
        PDCOpenConnection.onAccount('e911_nxt_address_update_success', this.onE911AddressUpdate)
        PDCOpenConnection.onAccount('device_assigned', this.onE911AddressUpdate)
        PDCOpenConnection.onAccount('device_unassigned', this.onE911AddressUpdate)
        PDCOpenConnection.onAccount('device_removed', this.onE911AddressUpdate)

        const isLocalhost = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
        if (!res.user_id || !V5PHONECOM.ac_token) {
            let hostUrl = process.env.REACT_APP_SETTINGS_BASE_URL // send NxT user to AC to get an AC token
            if (res.csr) {
                alert('You are in CSR mode but no user id found. \n Please use the right bar in cp to login to this users Configure Portal')
            }
            // is it localhost and not the test account
            if (isLocalhost && V5PHONECOM.voip_id !== '2477272') {
                hostUrl = null // send to login with redirect to localhost if running in localhost mode, else it is hard to login to configure.
                redirectLogin(hostUrl)
                return
            } else if (res?.account?.type === 'classic' && config?.cpBase) { // this is classic user, send to cp
                window.location.replace(config?.cpBase + '/login')
                return
            }
            redirectLogin(hostUrl)
        } else if (res.role === 'extension') {
            // user is extension, or there is no user id and user is not account or csr
            // redirect to my pdc
            window.location.replace(process.env.REACT_APP_SETTINGS_BASE_URL)
        } else if (res.role === 'extension') {
            if (isLocalhost) this.logout()
            else window.location.replace(process.env.REACT_APP_SETTINGS_BASE_URL)
        }

        const companyExtension = await Api.getCompanyExtension()
        window.V5PHONECOM.companyExtension = companyExtension
        if (window.Rollbar) {
            window.Rollbar.configure({
                payload: {
                    person: {
                        id: res.account_id, //  required
                        voip_id: res.account_id,
                        voip_phone_id: res.extension_id,
                        account_name: res.account.name
                    },
                    fullstory_url: (window.FS && typeof window.FS.getCurrentSessionURL === 'function') ? window.FS.getCurrentSessionURL(true) : null
                }
            })
        }

        res.extensions.forEach(extension => {
            if (extension.extension_id === companyExtension.id) {
                extension.company_name = companyExtension.company_name
                extension.is_company = true
            }
        })

        if (res.user_id) {
            // eslint-disable-next-line no-unused-expressions
            window.userpilot?.identify(res.user_id, { // Unique ID of each user in your database (e.g. 23443 or "590b80e5f433ea81b96c9bf6")
                email: res.email, // Used to connect data coming from various integrations
                name: res.first_name + ' ' + res.last_name, // We will parse this to extra first and surnames (e.g. "James Doe")
                role: res.role, // Send properties useful for targeting types of users (e.g. "Admin")
                project: 'configure' // Send any unique data for a user that might appear in any page URLs (e.g. 09876 or "12a34b56")
            })
        }
        this.fixUrlPath()

        // added a 5sec timeout for the loader cover, incase the the app does not do a call back for when it is done loading
        setTimeout(() => {
            if (!this.state.appLoading) return
            this.setState({ appLoading: false })
        }, 5000)

        const phoneNumbers = Object.keys(res.extension.phone_number)
        PhoneComUser.changePhoneNumber(phoneNumbers)

        setRollbar(res)
        setFirebaseAnaliticsUserProperties(res)

        window.hasProUser = await Api.getAccountHasProUser()

        this.setState({
            loading: false,
            userInfo: res
        })
    }

    fixUrlPath = () => {
        if (!this.state.userInfo) return
        let pathname = window.location.pathname
        const pathnameSplit = pathname.split('/').filter(e => e)

        if (pathnameSplit.length === 0) {
            pathname = `/${pathnameSplit.join('/')}`
        }

        if (pathnameSplit.length === 0) {
            if (pathname[pathname.length - 1] !== '/') pathname += '/'
            pathname += theme.defaultConfigureRoute
        }

        window.history.replaceState('Fix url path', 'Fir url path', pathname)
    }

    setScreenView = () => {
        const windowSize = window.innerWidth
        const { mobileViewSize, tabletViewSize, tabletPortraitViewSize } = this.props.theme.screenViewSizesConfigureApp
        const isMobileView = windowSize <= mobileViewSize
        const isTabletView = mobileViewSize < windowSize && windowSize <= tabletViewSize
        const isTabletPortraitView = mobileViewSize < windowSize && windowSize <= tabletPortraitViewSize
        this.updateScreenViewState({ isMobileView, isTabletView, isTabletPortraitView })
    }

    updateScreenViewState (screenViewState) {
        const currentScreenViewState = this.state.screenViewType
        if (screenViewState.isMobileView !== currentScreenViewState.isMobileView ||
            screenViewState.isTabletView !== currentScreenViewState.isTabletView ||
            screenViewState.isTabletPortraitView !== currentScreenViewState.isTabletPortraitView
        ) this.setState({ screenViewType: screenViewState })
    }

    logout = () => {
        this.setState({ userInfo: null })
        window.APP_CONFIG.cp_session_id = ''
        window.V5PHONECOM.cp_token = ''
        window.APP_CONFIG.ac_session_id = ''
        window.V5PHONECOM.ac_token = ''
        Cookies.remove(window.APP_CONFIG.ac_session_id_cookie_name)
        Cookies.remove(window.APP_CONFIG.cp_session_id_cookie_name)
        const location = window.location
        const host = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
        window.location.href = `${window.APP_CONFIG.redirect_url}${host}`
    }

    redirect = redirectPath => this.setState({ redirectPath })
    unsetRedirectPath = () => this.setState({ redirectPath: null })

    getAppsHeight = () => `calc(100% - ${this.mobileAppBarSpacer()})`

    mobileAppBarSpacer () {
        return '0px'
    }

    returnApp = (currentApp, currentAppName) => {
        if (this.state.currentAppName !== currentAppName) {
            // If it is only this.setState({currentAppName}) then we get react error that cannot set state from within render method
            setTimeout(() => this.setState({ currentAppName }), 0)
        }
        return currentApp
    }

    setHasChange = appHasChange => this.setState({ appHasChange })
    discardChanges = () => this.setHasChange(false)
    updateCompanyName = companyName => setTimeout(() => {
        this.setState({ companyName })
    }, 0)

    setHasActivePorts = hasActivePorts => this.setState({ hasActivePorts })

    renderCompanySetupWizard = () => {
        // if (!process.env.REACT_APP_ENABLE_COMPANY_SETUP_TAB) return <Redirect path='/'/>
        console.log('render csw')
        const { screenViewType, company } = this.state
        return (
            // <FeatureFlag flag='is_first_setup'>
            <CompanySetup screenViewType={screenViewType} companyName={company}/>
            // </FeatureFlag>
        )
    }

    getAppRoutesConfig = () => {
        const generalData = {
            extension: this.state.userInfo?.extension || null,
            extensions: this.state.userInfo?.extensions || [],
            companyName: this.state.userInfo?.company || '',
            isCSR: this.state.userInfo?.csr || false,
            isOffline: Boolean(this.state.isOffline),
            screenViewType: this.state.screenViewType,
            unreadPorting: this.state.unreadPorting,
            hasActivePorts: this.state.hasActivePorts,
            setHasActivePorts: this.setHasActivePorts,
            incompleteE911Count: this.state.incompleteE911Count,
            redirect: this.redirect,
            setHasChange: this.setHasChange,
            updateCompanyName: this.updateCompanyName,
            setBadgeValue: this.setBadgeValue,
            subscribeForCHRNotifications: this.subscribeForCHRNotifications
        }
        return {
            apps: [
                { component: Users, appName: 'users' },
                { component: PhoneNumbers, appName: 'phone-numbers' },
                { component: Phones, appName: 'phones' },
                { component: Company, appName: 'company' },
                { component: Account, appName: 'account' }
            ],
            generalData
        }
    }

    renderAppRoutes = () => {
        const appsConfig = this.getAppRoutesConfig()
        return (
            <>
                {appsConfig.apps.map(appConfig => {
                    const appName = appConfig.appName
                    if (this.state.redirectPath) {
                        const path = this.state.redirectPath
                        this.unsetRedirectPath()
                        return <Redirect to={`/${path}/`} key={appConfig.appName} />
                    }
                    return (
                        <Route key={appName} path={`/${appName}/`} render={props => {
                            const AppComponent = appConfig.component
                            if (props.match.isExact || !this.appRenderKey) this.appRenderKey = Math.random()
                            const app = <AppComponent key={this.appRenderKey} {...appsConfig.generalData} routeProps={props} />
                            return this.returnApp(app, appName)
                        }} />
                    )
                })}
                <Route path='/' render={() => {
                    const pathnameSplit = window.location.pathname.split('/')
                    const appName = pathnameSplit[0] || theme.defaultConfigureRoute.replace('/', '')
                    const path = pathnameSplit.slice(1).join('/') || theme.defaultConfigureRoute.replace('/', '')
                    const appNames = appsConfig.apps.map(a => a.appName)
                    if (appNames.includes(appName)) return <Redirect to={`/${path}`}/>
                    return <Redirect to='/users/'/>
                }} />
            </>
        )
    }

    renderTopBar = () => {
        const userInfo = this.state.userInfo
        const companyName = userInfo?.extensions.find(extension => !!extension.company_name)?.company_name
        return (
            <TopBar
                extension = {userInfo?.extension}
                companyName = {companyName}
                csr = {userInfo?.csr}
                redirect = {this.redirect}
                toggleShowNavigation = {(navigationShow) => this.setState({ navigationShow })}
                navigationShow = {this.state.navigationShow}
            />
        )
    }

    render = () => {
        const { classes } = this.props
        const showLoader = (this.state.loading || this.state.appLoading) && !this.state.isOffline
        return (
            <ThemeProvider theme={this.props.theme}>
                <div className={classes.mainDiv}>
                    {this.state.isOffline ? <OfflineBar /> : null}
                    <AppLoader hidden={!showLoader} request={'Timing'} />
                    {/* Turn this state pass into context */}
                    {this.state.userInfo && !this.state.loading && (
                        <Router>
                            <Switch>
                                <Route path='/setup' render={this.renderCompanySetupWizard}/>
                                <>
                                    {this.renderTopBar()}
                                    <Navigation
                                        userInfo={this.state.userInfo}
                                        screenViewType={this.state.screenViewType}
                                        unreadPorting={this.state.unreadPorting}
                                        currentAppName={this.state.currentAppName}
                                        appHasChange={this.state.appHasChange}
                                        companyName={this.state.companyName}
                                        logout={this.logout}
                                        discardChanges={this.discardChanges}
                                        incompleteE911Count={this.state.incompleteE911Count}
                                        navigationShow={this.state.navigationShow}
                                        toggleShowNavigation={(navigationShow) => this.setState({ navigationShow })}
                                    >
                                        <div style={{ height: this.mobileAppBarSpacer() }} />
                                        <div style={{ height: this.getAppsHeight() }} className={classes.contentContainer}>
                                            {this.renderAppRoutes()}
                                            {/* {!this.state.screenViewType.isMobileView && !this.state.screenViewType.isTabletView ? <StatusColumn/> : null} */}
                                        </div>
                                    </Navigation>
                                </>
                            </Switch>
                            <FeatureFlag flag='company_setup'>
                                <Redirect to='/setup'/>
                            </FeatureFlag>
                        </Router>)}
                    {/* <MarketingModal localStorageKey='hideLiveReceptionist-release' iframeSrc='https://www.phone.com/splash-pages/reception-services/' iframeTitle='Receptionist Services' hubspotFormConfig={{ portalId: '6860053', formId: 'fef49b4f-552d-4743-be09-d300496ce6da', sfdcCampaignId: '7011K0000014F5mQAE' }} onClose={() => true}/> */}
                </div>
                <Prompt classNames={classes.prompt} isOpen={this.state.pdcPromptOpen} color='tertiary' position={'bottom'} onClose={this.handlepdcPromptClose} content={this.state.pdcPromptMessage} />
                <Prompt classNames={classNames(classes.prompt, classes.promptError)} isOpen={this.state.pdcErrorOpen} color='attention' position={'bottom'} onClose={this.handlepdcErrorClose} content={this.state.pdcErrorMessage} />
            </ThemeProvider>
        )
    }
}

Configure.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
}

export default withStyles(styles)(Configure)
