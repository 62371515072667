import axios from 'axios'
import PhoneComUser from './PhoneComUser.js'
import _ from 'lodash'

class API {
    // Query available numbers
    static searchAvailableNumbers = (queries, cancelToken, isInCP) => {
        let apiUrl = 'https://stage.services.phone.com/numbers/search-available-numbers'
        if (isInCP) {
            apiUrl = PhoneComUser.getv5ApiRoot() + '/numbers/search-available-numbers'
        }
        const promises = []
        return new Promise((resolve, reject) => {
            for (const query of queries) {
                promises.push(
                    axios.post(
                        apiUrl,
                        query,
                        { cancelToken: cancelToken.token }
                    )
                )
            }
            Promise.all(promises).then(responses => {
                const response_sets = []
                for (const response of responses) {
                    if (response.data.numbers) {
                        response_sets.push(response.data.numbers)
                    }
                }
                const response_data = this._mergeSearchResponses(response_sets)
                return resolve({
                    results: response_data,
                    loading: false,
                    showGuide: 'guide hidden',
                    errors: ''
                })
            })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log('call was canceled')
                    } else {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return reject({
                            errors:
                        'Something went wrong. Please clear your search and try again.',
                            loading: false
                        })
                    }
                })
        })
    };

    // Merge multiple API responses into a single set of at most 500 results
    static _mergeSearchResponses = (response_sets) => {
        const MAX_RESULT_CNT = 500
        const totalResultCnt = response_sets.reduce(
            (accumulator, resultSet) => accumulator + resultSet.length, 0
        )

        const mergedResponses = response_sets.reduce(
            (accumulator, resultSet) => {
                const sampleSize = Math.round(
                    (resultSet.length / Math.max(totalResultCnt, 1)) *
                    Math.min(totalResultCnt, MAX_RESULT_CNT)
                )
                return accumulator.concat(_.sampleSize(resultSet, sampleSize))
            },
            []
        )
        return _.shuffle(mergedResponses)
    };

    // add number to cart
    static addNumberToCart = (phoneNumber, isVanity) => {
        console.log('addNumberToCart')
        const apiUrl = PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/unified/did-cart/add-did'
        // Use Cart API
        // return {
        //     loading: false,
        //     showGuide: phoneNumber.vendor_id == 2 ? "guide show" : "guide hidden",
        //     guideText: phoneNumber.vendor_id == 2 ? '<b>NOTE:</b> ' + phoneNumber.formatted + ' requires 3 to 7 business days to activate.' : 'Number successfully added to cart'
        // };
        return new Promise((resolve, reject) => {
            axios.post(
                apiUrl,
                {
                    account_id: PhoneComUser.getAPIAccountId(),
                    // extension_id: '1791336',
                    did: phoneNumber.phone_number,
                    price: phoneNumber.price,
                    vendor_id: phoneNumber.vendor_id,
                    vanity: isVanity
                },
                {
                    headers: {
                        Authorization: 'CP ' + PhoneComUser.getToken()
                    }
                }

            )
                .then(response => {
                    if (!('error' in response.data)) {
                        return resolve({
                            loading: false,
                            showGuide: phoneNumber.vendor_id === 2 ? 'guide show' : 'guide hidden'
                        // guideText: phoneNumber.vendor_id == 2 ? '<b>NOTE:</b> ' + phoneNumber.formatted + ' requires 3 to 7 business days to activate.' : 'Number successfully added to cart'
                        })
                    } else {
                        return resolve({
                            errors: 'Oops, looks like someone got to that number first.',
                            loading: false,
                            showGuide: 'guide show'
                        // guideText: "Oops, looks like someone got to that number first."
                        })
                    }
                })
                .catch(error => {
                    console.log(error.message)
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return reject({
                        errors: 'Oops, looks like someone got to that number first.',
                        loading: false,
                        showGuide: 'guide show'
                    // guideText: "Oops, looks like someone got to that number first."
                    })
                })
        })
    }

    // remove number from cart
    static removeNumberFromCart = (phoneNumber) => {
        console.log('removeNumberFromCart')
        const apiUrl = PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/unified/did-cart/remove-did'
        // return {
        //     loading: false,
        //     showGuide: "guide hidden",
        //     guideText: "Number successfully removed from cart"
        // };
        return new Promise((resolve, reject) => {
            axios.post(
                apiUrl,
                {
                    account_id: PhoneComUser.getAPIAccountId(),
                    did: phoneNumber.phone_number
                    // extension_id: '1791336',
                },
                {
                    headers: {
                        Authorization: 'CP ' + PhoneComUser.getToken()
                    }
                }

            )
                .then(response => {
                    if (!('error' in response.data)) {
                        return resolve({
                            loading: false,
                            showGuide: 'guide hidden'
                        // guideText: "Number successfully removed from cart"
                        })
                    } else {
                        return resolve({
                            errors: 'Something went wrong while removing number from cart.',
                            loading: false,
                            showGuide: 'guide show'
                        // guideText: "Something went wrong while removing number from cart, please refresh"
                        })
                    }
                })
                .catch(error => {
                    console.log(error.message)
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return reject({
                        errors: 'Something went wrong while removing number from cart.',
                        loading: false,
                        showGuide: 'guide show'
                    // guideText: "Something went wrong while removing number from cart, please refresh"
                    })
                })
        })
    }

    // get cart and items
    static getCart = (phoneNumber) => {
        console.log('getCart')

        const apiUrl = PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/unified/did-cart/get-cart'
        return new Promise((resolve, reject) => {
            axios.post(
                apiUrl,
                {
                    account_id: PhoneComUser.getAPIAccountId()
                    // extension_id: '1791336',
                },
                {
                    headers: {
                        Authorization: 'CP ' + PhoneComUser.getToken()
                    }
                }

            )
                .then(response => {
                    console.log(response)
                    return resolve(response)
                })
                .catch(error => {
                    console.log(error.message)
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return reject({
                        errors: 'Something went wrong while fetching cart data.',
                        loading: false
                    })
                })
        })
    }

    static purchaseCart = () => {
        console.log('in purchaseCart')
        const apiUrl = PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/unified/did-cart/purchase'
        return new Promise((resolve, reject) => {
            axios.post(
                apiUrl,
                {
                    account_id: PhoneComUser.getAPIAccountId(),
                    via_queue: true
                },
                {
                    headers: {
                        Authorization: 'CP ' + PhoneComUser.getToken()
                    }
                }

            )
                .then(response => {
                    console.log('did-cart/purchase response', response)

                    if (response.data.success === true) {
                        return resolve({
                            purchaseResponse: response.data
                        })
                    } else if (response.data.error_type === 'payment_failed') {
                        return resolve({
                            errors: 'Payment declined.',
                            loading: false,
                            purchasing: false,
                            loadingMessage: null
                        // showGuide: "guide show",
                        // guideText: "We were unable to charge your primary card on file. </br><br/> Please update your credit card on the Payment Information page."
                        })
                    } else if (response.data.error_type === 'no_payment_method_on_file') {
                        return resolve({
                            errors: 'No payment method on file when purchase non-free numbers.',
                            loading: false,
                            purchasing: false,
                            loadingMessage: null
                        // showGuide: "guide show",
                        // guideText: 'We couldn\'t find a payment method on file for your account. </br><br/>To purchase non-free numbers, a payment method needs to be added.'
                        })
                    } else if (response.data.error_type === 'did_purchase_limit_exceeded') {
                        return resolve({
                            errors: "Sorry, it looks like you've reached your daily number purchase limit. If you'd like to request a limit increase, please contact us at 844-746-6312.",
                            loading: false,
                            purchasing: false,
                            loadingMessage: null
                        })
                    } else {
                        return resolve({
                            errors: 'Something went wrong while purchasing number(s).',
                            loading: false,
                            purchasing: false,
                            loadingMessage: null
                        // showGuide: "guide show",
                        // guideText: "Looks like something went wrong.<br/><br/>Please contact our sales at <a href='tel:844-746-6312'>844-746-6312</a> so we can help you out!"
                        })
                    }
                })
                .catch(error => {
                    console.log(error.message)
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return reject({
                        errors: 'Something went wrong while purchasing cart.',
                        loading: false,
                        purchasing: false,
                        loadingMessage: null
                    })
                })
        })
    }

    static getPricingSummary = () => {
        console.log('in getPricingSummary')
        // return {'status': 'success', 'comment': 'remove this RETURN to enable purchase number'}
        const apiUrl = PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/unified/did-cart/price'

        // let pricing_details = {"success": true, "pricing_details": {"total_one_time_charges": 329528, "charges_by_did": {"+14129008000": {"total": 329528, "details": {"price": 9900, "taxes": {"tax_total": 73790, "tax_breakdown": {"Sales Tax": 20476, "FUSF (VoIP)": 52781, "FCC Regulatory Fee (VoIP)": 533}}, "fees": {"fee_total": 6238, "fee_breakdown": {"Regulatory recovery fee": 6238}}}}}}}
        // return {
        //     loading: false,
        //     showGuide: "guide hidden",
        //     // guideText: "Number successfully removed from cart"
        //     pricingSummary: pricing_details.pricing_details
        // }

        return new Promise((resolve, reject) => {
            axios.post(
                apiUrl,
                {
                    account_id: PhoneComUser.getAPIAccountId()
                },
                {
                    headers: {
                        Authorization: 'CP ' + PhoneComUser.getToken()
                    }
                }

            )
                .then(response => {
                    console.log('did-cart/price response', response)
                    if (response.data.success === true) {
                        return resolve({
                            loading: false,
                            showGuide: 'guide hidden',
                            pricingSummary: response.data.pricing_details,
                            showModal: true
                        })
                    } else {
                        return resolve({
                            errors: 'Something went wrong while fetching pricing summary.',
                            loading: false,
                            showGuide: 'guide show',
                            guideText: 'Something went wrong while fetching pricing summary.'
                        })
                    }
                })
                .catch(error => {
                    console.log(error.message)
                    return resolve({
                        errors: 'Something went wrong while fetching pricing summary.',
                        loading: false,
                        showGuide: 'guide show',
                        guideText: 'Something went wrong while fetching pricing summary.'
                    })
                })
        })
    }
}

export default API
