import React, { Component } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import { isValidPhoneNumber } from 'phone-numbers'
import Chip from 'chip-mui'
import TextField from 'text-field-mui'
import PropTypes from 'prop-types'

class ChipField extends Component {
    constructor (props) {
        super(props)
        this.state = {
            newValue: ''
        }
    }

    onInputChange = newValue => {
        const re = /^[0-9]+$/
        if (this.props.numericalsOnly && (newValue === '' || re.test(newValue))) {
            this.props.setValidation(false)
            const submitCharacters = this.props.submitCharacters
            const wantsToSubmit = submitCharacters.includes(newValue.charAt(newValue.length - 1)) && newValue.charAt(newValue.length - 2) !== ')'
            if (wantsToSubmit) return

            this.setState({ newValue })
        }
    }

    onKeyPress = e => {
        const isEnter = e.charCode === 13 && this.props.submitOnEnter
        const wantsToSubmit = isEnter || this.props.submitCharacters?.includes(e.key)
        if (!wantsToSubmit) return
        if (!(this.props.skipValidation || this.validate())) return
        this.submit()
    }

    onBlur = () => this.props.submitOnBlur && (this.props.skipValidation || this.validate()) ? this.submit() : null

    submit = () => {
        const isValidEntry = this.props.onAdd(this.state.newValue)
        if (isValidEntry) this.clearValue()
    }

    clearValue = () => this.setState({ newValue: '' })

    validate = () => {
        return isValidPhoneNumber(this.state.newValue)
    }

    getInputProps = () => {
        const { classes, values } = this.props
        const chipDataTestIdProp = this.props['chip-test-id'] ? { 'data-test-id': this.props['chip-test-id'] } : {}
        return {
            startAdornment: (<>
                {values.map((v, i) => (
                    <Chip { ...chipDataTestIdProp }key={i} classes={{ root: classes.chipRoot }} color={v.color || 'primary'} label={v.label} disabled={v.disabled} onDelete={() => this.props.onDelete(i)} />
                ))}
            </>),
            endAdornment: (<></>)
        }
    }

    render = () => {
        const { classes, disabled, label, helperText, error, values, placeholder } = this.props
        const dataTestIdProp = this.props['data-test-id'] ? { 'data-test-id': this.props['data-test-id'] } : {}
        return (
            <TextField
                classNames = {{ root: `${classes.chipField} chip-field` }}
                onPaste = {this.props.onPaste}
                onChange = {(e) => this.onInputChange(e.target.value)}
                label = {!values.length ? label : ''}
                placeholder = {disabled ? '' : placeholder}
                value = {this.state.newValue}
                disabled = {disabled}
                onKeyPress = {this.onKeyPress}
                onBlur = {this.onBlur}
                error = {error}
                helperText = {helperText}
                InputProps = {this.getInputProps()}
                onXClick = {this.clearValue}
                noLabelOnActiveOrResolved
                { ...dataTestIdProp }
            />
        )
    }
}

ChipField.propTypes = {
    classes: PropTypes.object,
    submitCharacters: PropTypes.func,
    submitOnEnter: PropTypes.func,
    submitOnBlur: PropTypes.fucn,
    skipValidation: PropTypes.bool,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onPaste: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    values: PropTypes.array,
    setValidation: PropTypes.func,
    placeholder: PropTypes.string,
    numericalsOnly: PropTypes.bool,
    'chip-test-id': PropTypes.string,
    'data-test-id': PropTypes.string
}

export default withStyles(styles)(ChipField)
