import React from "react";
import FilterButton from "../filter-button/filter-button"
import HoverBoxWrapper from "../hover-box-wrapper/hover-box-wrapper"
import NpaSelector from "./npa-selector/npa-selector"
import "./tfn-npa-filter.css"

class TfnNpaFilter extends React.Component {

  constructor(props) {
    super(props);
    this.tollfreeNpas = ['800', '833', '844', '855', '866', '877', '888'];
    this.formattedOptions	= this.tollfreeNpas.map(o => ({value: `${o}`, content: `${o}`}))
    this.initialState = {
      selectedNpas: [],
      npaSelectorOpen: false
    };
    this.state = JSON.parse(JSON.stringify(this.initialState));
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  // Hide NpaSelector if click away from component
  handleClick = e => {
    if (this.node.contains(e.target) && this.node !== e.target) {
      // Do nothing - click was within component
      return;
    }
    else {
      this.setState({npaSelectorOpen: false});
    }
  };

  onNpaChange = selections => {
    let selectedNpas = selections.map(s => s.value);
    this.setState({
      selectedNpas
    })
  }

  toggleNpaSelector = () => {
    this.setState({
       npaSelectorOpen: !this.state.npaSelectorOpen
    })
  };

  handleCheckbox = (value, checked) => {
    const npa = value;
    if (!checked) {
      this.setState({
        selectedNpas: this.state.selectedNpas.concat(npa)
      })
    }
    else {
      this.setState({
        selectedNpas: this.state.selectedNpas.filter(item => item !== npa)
      })
    }
  };

  handleResetNpa = () => {
    this.setState(this.initialState)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.reset !== prevProps.reset) {
      this.setState(
        JSON.parse(JSON.stringify(this.initialState))
      )
    }
    if (this.state.selectedNpas !== prevState.selectedNpas) {
      this.props.handleNpaFilterUpdate(this.state.selectedNpas);
    }
  }

  render() {

    const child = <NpaSelector
      selectedNpas={this.state.selectedNpas}
      availableNpas={this.tollfreeNpas}
      handleCheckbox={this.handleCheckbox}
    />;

    return (
      <div
        className="tfn-npa-filter"
        ref={node => this.node = node}
      >
        <FilterButton
          data-test-id={'tfn-npa-filter'}
          selectedFilters={this.state.selectedNpas}
          handleClick={this.toggleNpaSelector}
          handleIconClick={this.state.selectedNpas.length == 0 ? this.toggleNpaSelector : this.handleResetNpa}
          defaultText={"8xx"}
          open={this.state.npaSelectorOpen}
        />
        <HoverBoxWrapper
          children={child}
          open={this.state.npaSelectorOpen}
        />
      </div>
    );
  }

}

export default TfnNpaFilter;