import { theme } from 'get-theme'
const mainTheme = theme

/**
 * @param {object} t
 */
const styles = t => {
    const theme = (t.palette.secondary['-300']) ? t : mainTheme
    return {
        switchWrapper: {
            padding: 6,
            borderRadius: 23,
            background: 'transparent',
            width: 'fit-content',
            cursor: 'pointer',
            '&.error': {
                '&:hover': {
                    backgroundColor: `${theme.palette.attention[-500]}66`
                },
                '&.checked': {
                    '& .switch': {
                        backgroundColor: theme.palette.attention[0],
                        '& .thumb > svg': {
                            color: theme.palette.attention[0]
                        }
                    }
                },
                '&.unchecked': {
                    '& .switch': {
                        backgroundColor: theme.palette.attention['+100']
                    }
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.primary['-400']
            },
            '&.checked': {
                '& .switch': {
                    backgroundColor: theme.palette.primary['0'],
                    '& .thumb': {
                        marginLeft: 20,
                        '& > svg': {
                            fontSize: 16,
                            color: theme.palette.primary['0']
                        }
                    }
                }
            },
            '&.unchecked': {
                '& .switch': {
                    backgroundColor: theme.palette.secondary['+100']
                }
            },
            '& .switch': {
                width: 50,
                height: 30,
                borderRadius: 25,
                padding: 2,
                '& .thumb': {
                    position: 'relative',
                    background: 'white',
                    width: 26,
                    height: 26,
                    borderRadius: 15,
                    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.12)',
                    transition: 'margin .2s',
                    '& > svg': {
                        fontSize: 0,
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }
                }
            },
            '&.disabled': {
                '& .switch .thumb': {
                    boxShadow: 'none'
                },
                '&.checked .switch': {
                    backgroundColor: theme.palette.primary['-300'],
                    '& .thumb > svg': {
                        color: theme.palette.primary['-300']
                    }
                },
                '&.unchecked .switch': {
                    backgroundColor: theme.palette.secondary['-300']
                }
            }
        },
        checkboxWrapper: {
            padding: 12,
            borderRadius: '50%',
            background: 'transparent',
            width: 'fit-content',
            cursor: 'pointer',
            '&.error': {
                '&:not(.no-hover):hover': {
                    backgroundColor: `${theme.palette.attention[-500]}66`
                },
                '&.checked .checkbox': {
                    backgroundColor: theme.palette.attention['+100']
                },
                '& .checkbox': {
                    borderColor: theme.palette.attention['+100']
                }
            },
            '&:not(.no-hover):hover': {
                backgroundColor: theme.palette.secondary['-400']
            },
            '&.checked': {
                '& .checkbox': {
                    backgroundColor: theme.palette.secondary['+100'],
                    '& > svg': {
                        fontSize: 16,
                        color: 'white'
                    }
                }
            },
            '&.unchecked': {
                '& .checkbox': {
                    backgroundColor: 'transparent'
                }
            },
            '& .checkbox': {
                width: 28,
                height: 28,
                border: `1.5px solid ${theme.palette.secondary['+100']}`,
                borderRadius: 4,
                padding: '5.93px 4.1px',
                position: 'relative',
                '& > svg': {
                    fontSize: 0,
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                }
            },
            '&.disabled': {
                '& .checkbox': {
                    borderColor: theme.palette.secondary['-300']
                },
                '&.checked .checkbox': {
                    backgroundColor: theme.palette.secondary['-300']
                }
            }
        },
        radioWrapper: {
            padding: 9,
            borderRadius: '50%',
            background: 'transparent',
            width: 'fit-content',
            cursor: 'pointer',
            '&.error': {
                '&:hover': {
                    backgroundColor: `${theme.palette.attention[-500]}66`
                },
                '& .radio': {
                    borderColor: theme.palette.attention['+100'],
                    '& .check-circle': {
                        backgroundColor: theme.palette.attention['+100']
                    }
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.secondary[-400]
            },
            '&.checked': {
                '& .radio': {
                    '& .check-circle': {
                        width: 14,
                        height: 14
                    }
                }
            },
            '& .radio': {
                width: 26,
                height: 26,
                border: `1px solid ${theme.palette.secondary['+100']}`,
                borderRadius: 14,
                padding: 5,
                position: 'relative',
                backgroundColor: 'transparent',
                '& .check-circle': {
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.secondary['+100'],
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    transition: 'width .1s, height .1s'
                }
            },
            '&.disabled': {
                '& .radio': {
                    borderColor: theme.palette.secondary['-300']
                },
                '&.checked .radio .check-circle': {
                    backgroundColor: theme.palette.secondary['-300']
                }
            }
        }
    }
}

export default styles
