import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=5973%3A4518
 *
 * @param {SvgIconProps} props - svg props
 */
export const NumbersIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.79758 10.4249C6.98901 12.9836 8.97832 15.1076 11.4474 16.4835L13.47 14.5974C13.7182 14.3659 14.0763 14.2984 14.3835 14.4158C15.367 14.7793 16.4357 14.9945 17.5372 15.0329C18.0258 15.05 18.4116 15.4637 18.3946 15.9523L18.2863 19.0526C18.2692 19.5412 17.8555 19.927 17.3669 19.91C9.02536 19.6187 2.50111 12.6223 2.7924 4.28068C2.80947 3.79209 3.22318 3.40629 3.71177 3.42336L6.82099 3.53193C7.30958 3.54899 7.69538 3.96271 7.67831 4.4513C7.63954 5.56174 7.77998 6.63396 8.07392 7.64038C8.16079 7.95472 8.07762 8.29869 7.8202 8.53874L5.79758 10.4249Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.0388 2.75C13.7626 2.75 13.5388 2.97386 13.5388 3.25V4.3752H12.5389C12.2627 4.3752 12.0389 4.59906 12.0389 4.8752V5.3752C12.0389 5.65135 12.2627 5.8752 12.5389 5.8752H13.5388V7.29887H12.5389C12.2627 7.29887 12.0389 7.52273 12.0389 7.79887V8.29887C12.0389 8.57501 12.2627 8.79887 12.5389 8.79887H13.5388V9.75C13.5388 10.0261 13.7626 10.25 14.0388 10.25H14.5388C14.8149 10.25 15.0388 10.0261 15.0388 9.75V8.79887H16.5389V9.75C16.5389 10.0261 16.7627 10.25 17.0389 10.25H17.5389C17.815 10.25 18.0389 10.0261 18.0389 9.75V8.79887H19.0389C19.315 8.79887 19.5389 8.57501 19.5389 8.29887V7.79887C19.5389 7.52273 19.315 7.29887 19.0389 7.29887H18.0389V5.8752H19.0389C19.315 5.8752 19.5389 5.65135 19.5389 5.3752V4.8752C19.5389 4.59906 19.315 4.3752 19.0389 4.3752H18.0389V3.25C18.0389 2.97386 17.815 2.75 17.5389 2.75H17.0389C16.7627 2.75 16.5389 2.97386 16.5389 3.25V4.3752H15.0388V3.25C15.0388 2.97386 14.8149 2.75 14.5388 2.75H14.0388ZM16.5389 5.8752H15.0388V7.29887H16.5389V5.8752Z" fill={color}/>
        </SvgIcon>
    )
}

export default NumbersIcon
