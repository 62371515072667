import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
	tooltip: {
		backgroundColor: '#48B9D5',
		fontSize:	11,
		fontFamily: 'Montserrat',
		fontWeight: 500,
		borderRadius: 6,
		margin: 0,
		maxWidth: 326,
		width: 326,
		height: 64,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},
	tooltipPopper:{
		top: '-3px !important'
	}
})

class PDCTooltip extends Component {

	constructor(props) {
		super(props)
		this.state = {
			open: true
		}
	}

	onTolltipOpen = () => {
		this.timeout = setTimeout(() => {
			this.setState({ open: false });
		  }, 3000);
	}
	onTolltipClose = () => { 
		clearTimeout(this.timeout); this.setState({ open: true })
	}

	render() {
		const { classes } = this.props
		return (
            <Tooltip onOpen={this.onTolltipOpen} onClose={this.onTolltipClose} enterDelay={750} enterNextDelay={750} leaveDelay={0} title={this.state.open ?  this.props.text : ''} disableHoverListener={this.props.disabled} classes={{ tooltip: classes.tooltip, popper: classes.tooltipPopper }} placement='top'>{this.props.children}</Tooltip>
		)
	}
}

export default withStyles(styles)(PDCTooltip) 