/* eslint class-methods-use-this : "off" */
export default class ContactsCsvSetup {
	constructor() {
		this.address_props = [
			'street',
			'street2',
			'city',
			'state',
			'zip',
			'country',
		];
	}

	transform_address(item, type) {
		const address_type = `address_${type}_`;
		for (const prop of this.address_props) {
			const address = `${address_type}${prop}`;
			if (item[address]) {
				return `${item[`${address_type}street`] ? item[`${address_type}street`] : ''}/-/${
					item[`${address_type}street2`] ? item[`${address_type}street2`] : ''
				}/-/${item[`${address_type}city`] ? item[`${address_type}city`] : ''}/-/${
					item[`${address_type}state`] ? item[`${address_type}state`] : ''
				}/-/${item[`${address_type}zip`] ? item[`${address_type}zip`] : ''}/-/${
					item[`${address_type}country`] ? item[`${address_type}country`] : ''
				}`;
			}
		}

		return null;
	}

	jsonCallback(items) {
		for (const item of items) {
			item.address_home = this.transform_address(item, 'home');
			item.address_business = this.transform_address(item, 'business');
			item.group = 'LA Pro';
			for (const prop of this.address_props) {
				delete item[`address_home_${prop}`];
				delete item[`address_business_${prop}`];
			}
		}

		return items;
	}

	contacts_callback(items) {
		for (let i = 0; i < items.length; i++) {
			if (items[i].phone_numbers && !items[i].phone_numbers.length) {
				items.splice(i, 1);
			}
		}
		return items;
	}
}
