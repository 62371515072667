import React, { Component } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import { DefaultArrowTooltip } from 'tooltips'
import Button from 'button'
import { DefaultTooltip } from 'tooltips'

import IconButton from '@material-ui/core/IconButton'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

class AudioRecorder extends Component {
    constructor (props) {
        super(props)
        this.state = {
            recording: props.recording || { isRecording: false },
            isBlocked: null
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.recording !== this.props.recording) {
            this.setState({ recording: this.props.recording || { isRecording: false } })
        }
    }

    getUserMedia = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true },
                () => this.setState({ isBlocked: false }),
                () => this.setState({ isBlocked: true })
            )
        } catch (error) {
            console.log(error)
        }
    }

    getRecordingDuration = () => {
        const start = this.state.recording.start / 1000
        const end = (this.state.recording.end || Date.now()) / 1000
        return (end - start).toFixed(2)
    }

    startRecording = async () => {
        if (this.state.isBlocked) return console.log('Permission Denied')
        try { await Mp3Recorder.start() } catch (e) {
            const isPermissionDeniedError = e.message === 'Permission denied'
            const isFirefoxPermissionDeniedError = e.message === 'The request is not allowed by the user agent or the platform in the current context.'
            if (isPermissionDeniedError || isFirefoxPermissionDeniedError) {
                alert('The microphone is disabled in the browser. Enable it in order to use this feature.')
            }
            return console.error(e)
        }
        const recording = {
            isRecording: true,
            start: Date.now(),
            end: 0
        }
        this.setState({
            recording,
            readyToPlay: false
        })
        this.props.onRecorded(recording)
        this.recordingInterval = setInterval(() => {
            const recording = this.state.recording
            recording.currentDuration = this.getRecordingDuration()
            this.setState(recording)
            this.props.onRecorded(recording)
        }, 25)
    }

    stopRecording = async () => {
        let blob = null
        try {
            const [, blob2] = await Mp3Recorder.stop().getMp3()
            blob = blob2
            this.setState({ isRecording: false })
            clearInterval(this.recordingInterval)
            delete this.recordingInterval
            const recording = this.state.recording
            recording.isRecording = false
            recording.end = Date.now()
            this.setState(recording)
            this.props.onRecorded(recording)

            this.onRecordingComplete(blob)
        } catch (e) { return console.error(e) }
    }

    onRecordingComplete = blob => {
        const reader = new FileReader()
        const filename = `recording ${Date.now()}`
        reader.onload = async () => {
            const result = reader.result
            const recordedAudio = { downloadLink: result, filename }
            const recording = this.state.recording
            recording.recordedAudio = recordedAudio
            this.setState({ recording })
            this.props.onRecorded(recording)
        }
        reader.readAsDataURL(blob)
    }

    removeRecordedAudio = () => {
        this.setState({ recording: { isRecording: false } })
        this.props.onDelete()
    }

    toggleRecord = () => {
        if (this.state.isBlocked === null) this.getUserMedia()

        if (this.state.recording && this.state.recording.isRecording) this.stopRecording()
        else this.startRecording()
    }

    renderRecordSection = () => {
        const { classes, hasError, showIconOnly } = this.props
        const recordingDuration = this.state.recording.currentDuration
        const isRecording = this.state.recording && this.state.recording.isRecording
        const Icon = isRecording ? MicIcon : MicOffIcon
        const disabled = this.state.isBlocked
        return (
            <div className={classes.recordSection}>
                <DefaultArrowTooltip
                    title = {disabled ? 'Enable audio recording in your browser' : ''}
                    placement = 'right'
                >
                    <div>
                        {
                            showIconOnly
                                ? <DefaultTooltip title={`${isRecording ? 'Stop' : 'Start'} recording`} onClick = {this.toggleRecord}>
                                    <IconButton color='primary' size='medium' className='recording-icon-btn'>
                                        <Icon />
                                    </IconButton>
                                </DefaultTooltip>
                                : <Button
                                    color = {hasError ? 'attention' : 'primary'}
                                    icon = {Icon}
                                    disabled = {disabled}
                                    onClick = {this.toggleRecord}
                                >{isRecording ? 'Stop' : 'Start'} recording</Button>
                        }
                    </div>
                </DefaultArrowTooltip>
                <span className={classes.recordInfo}>
                    {this.state.recording.isRecording ? <span>Recording {recordingDuration}s</span> : null}
                </span>
            </div>
        )
    }

    renderRecordingSection = () => {
        const { classes } = this.props
        return (
            <div className={classes.audioSection}>
                <div className={classes.audioPlayerWrapper} style={{ display: this.state.readyToPlay ? 'block' : 'none' }}>
                    <AudioPlayer
                        url = {this.state.recording.recordedAudio.downloadLink || this.state.recording.recordedAudio.download_link}
                        onError = {this.removeRecordedAudio}
                        onReadyToPlay = {() => this.setState({ readyToPlay: true })}
                    />
                </div>
                {!this.state.readyToPlay
                    ? <LoadingBar/>
                    : <DefaultArrowTooltip
                        title = 'Remove'
                        placement = 'right'
                        leaveDelay = {1}
                        enterTouchDelay = {0}
                    >
                        <div className={classes.removeButton} onClick={this.removeRecordedAudio}><CloseIcon/></div>
                    </DefaultArrowTooltip>
                }
            </div>
        )
    }

    render = () => {
        return (!this.state.recording.recordedAudio
            ? this.renderRecordSection()
            : this.renderRecordingSection()
        )
    }
}

AudioRecorder.propTypes = {
    /**
     * Material ui classes
     */
    classes: PropTypes.object,
    recording: PropTypes.object,
    onRecorded: PropTypes.func,
    onDelete: PropTypes.func,
    hasError: PropTypes.bool,
    showIconOnly: PropTypes.bool
}

export default withStyles(styles)(AudioRecorder)
