import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Badge, withStyles } from '@material-ui/core'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'

const StyledBadge = withStyles(() => ({
    badge: {
        right: -12,
        top: -3,
        padding: '3px 7px 3px 7px',
        background: '#F76757',
        boxShadow: '-1px 2px 1px rgba(0, 0, 0, 0.35)',
        borderRadius: '11px',
        color: '#fff'
    }
}))(Badge)

class E911Header extends Component {
    /**
     * Header for E911 Address column; badged with count of incomplete E911 addresses.
     */
    render = () => {
        return (<div style={{ display: 'flex', alignItems: 'center' }} >E911 Address&nbsp;
            <RemoteInfoTipMui arrow remoteConfigIDs={['configure_phones_manage_phones_e911_address']} placement={RemoteInfoTipMuiPlacement.TOP}/>
            {this.props.incompleteE911Count ? <StyledBadge data-test-id="badge" badgeContent={this.props.incompleteE911Count} /> : ''}
        </div>)
    }
}

E911Header.propTypes = {
    incompleteE911Count: PropTypes.number
}

export default E911Header
