import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/JQacodb8V4YdmDt3Awk2zl/Call-handling?node-id=2529%3A10294
 *
 * @param {SvgIconProps} props - svg props
 */
export const RingUsersIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#2A9BB7'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M26.6334 13.0035L27.1461 13.5163C27.3414 13.7115 27.3412 14.0279 27.1457 14.2229L24.7021 16.6605C24.5066 16.8555 24.1898 16.8553 23.9945 16.66L23.4818 16.1473C23.2865 15.952 23.2867 15.6356 23.4822 15.4406L25.9258 13.0031C26.1213 12.8081 26.4381 12.8083 26.6334 13.0035ZM28.4419 18.9272L28.364 19.6825C28.3356 19.9572 28.0893 20.1639 27.8138 20.1443L24.4516 19.905C24.1761 19.8854 23.9758 19.6468 24.0041 19.3722L24.0821 18.6169C24.1104 18.3422 24.3567 18.1355 24.6322 18.1551L27.9945 18.3944C28.2699 18.414 28.4703 18.6526 28.4419 18.9272ZM20.5944 11.0656L21.3485 10.9873C21.6232 10.9587 21.8619 11.1588 21.8816 11.4342L22.1223 14.7944C22.1421 15.0698 21.9354 15.3162 21.6606 15.3447L20.9066 15.423C20.6319 15.4516 20.3932 15.2515 20.3735 14.9761L20.1328 11.6159C20.113 11.3405 20.3197 11.0941 20.5944 11.0656Z' fill='white'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M14.2803 19.5539C15.6767 22.2982 17.9264 24.5382 20.6706 25.9442L22.804 23.8109C23.0658 23.5491 23.4537 23.4618 23.793 23.5782C24.8791 23.937 26.0524 24.1309 27.2549 24.1309C27.7882 24.1309 28.2246 24.5673 28.2246 25.1006V28.4848C28.2246 29.0182 27.7882 29.4545 27.2549 29.4545C18.1494 29.4545 10.77 22.0752 10.77 12.9697C10.77 12.4364 11.2064 12 11.7397 12H15.1337C15.667 12 16.1034 12.4364 16.1034 12.9697C16.1034 14.1818 16.2973 15.3455 16.6561 16.4315C16.7627 16.7709 16.6852 17.1491 16.4137 17.4206L14.2803 19.5539Z' fill='white'/>
        </SvgIcon>
    )
}

export default RingUsersIcon
