<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM11.9999 21.3499C17.1637 21.3499 21.3499 17.1637 21.3499 11.9999C21.3499 6.836 17.1637 2.64987 11.9999 2.64987C6.836 2.64987 2.64987 6.836 2.64987 11.9999C2.64987 17.1637 6.836 21.3499 11.9999 21.3499Z" fill="currentColor"/>
	<ellipse cx="12" cy="19.7002" rx="0.687501" ry="0.6875" fill="currentColor"/>
	<ellipse cx="4.2998" cy="11.9998" rx="0.6875" ry="0.687501" fill="currentColor"/>
	<ellipse cx="19.7002" cy="11.9998" rx="0.6875" ry="0.687501" fill="currentColor"/>
	<circle cx="8.69961" cy="5.40029" r="0.4125" fill="currentColor"/>
	<ellipse cx="5.67471" cy="8.15005" rx="0.4125" ry="0.412501" fill="currentColor"/>
	<ellipse cx="5.67471" cy="15.8505" rx="0.4125" ry="0.4125" fill="currentColor"/>
	<circle cx="8.69961" cy="18.6002" r="0.4125" fill="currentColor"/>
	<ellipse cx="15.2997" cy="18.6002" rx="0.412501" ry="0.4125" fill="currentColor"/>
	<circle cx="18.3246" cy="15.8505" r="0.4125" fill="currentColor"/>
	<ellipse cx="18.3246" cy="8.15005" rx="0.4125" ry="0.412501" fill="currentColor"/>
	<ellipse cx="15.2997" cy="5.40029" rx="0.412501" ry="0.4125" fill="currentColor"/>
	<path d="M11.5569 4.01513C11.5744 3.78783 11.7639 3.6123 11.9919 3.6123C12.2268 3.6123 12.4195 3.79828 12.4279 4.03302L12.6879 11.3123L16.8076 14.3085C17.0411 14.4782 17.099 14.8019 16.9389 15.0421C16.7883 15.2679 16.4933 15.3462 16.2506 15.2249L12.1032 13.1512C11.3759 12.7876 10.9412 12.0197 11.0035 11.209L11.5569 4.01513Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {};
</script>
