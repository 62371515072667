//
//
//
//
//
//
//
//

import l from 'lang';
export default {
  data: function data() {
    return {
      l: l
    };
  }
};