/**
 * These are the styles for the Menu component
 */
const styles = () => {
    return {
        listItemIcon: {
            minWidth: 24,
            '&.left-icon': {
                marginRight: 12
            }
        }
    }
}

export default styles
