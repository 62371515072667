import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const CircleLogo = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M0 25.8856C0.464865 39.2442 11.4152 49.9396 24.8779 50H25.1071C38.8655 49.9383 50.0001 38.7688 50.0001 25.0008C50.0001 11.1927 38.8036 0 24.9925 0C11.4774 0 0.466183 10.7177 0 24.1159L0 25.8856ZM24.4312 17.2807C27.3907 17.2807 29.7891 19.6242 29.7891 22.5149C29.7891 25.4054 27.3907 27.7484 24.4312 27.7484C21.4727 27.7484 19.074 25.4054 19.074 22.5149C19.074 19.6242 21.4727 17.2807 24.4312 17.2807ZM26.7276 33.7732C29.1475 33.3212 36.5212 31.2105 36.5212 22.2791C36.5212 16.2317 31.8339 11.2551 25.815 10.6241C25.3898 10.5792 24.9578 10.5555 24.5203 10.5555C17.8922 10.5555 12.3265 15.8064 12.5186 22.2791C12.7712 30.7802 20.9682 36.9663 29.3435 40.9254C29.734 41.1104 30.1659 40.7676 29.8846 40.3195C29.6902 40.0097 29.3789 39.5809 29.0156 39.0805C28.0711 37.7794 26.7747 35.9938 26.2652 34.5522C26.1391 34.1958 26.3482 33.8446 26.7276 33.7732Z' fill='#3FAE29'/>
        </SvgIcon>
    )
}

export default CircleLogo
