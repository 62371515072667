// React
import React from "react";
import Slider from "rc-slider";
import SelectionControl from 'selection-control';
// Stylesheet
import "rc-slider/assets/index.css";
import "./price-selector.css";

// Create price slider element
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

// Price Filter
class PriceSelector extends React.Component {

  options = [
    // {
    //   content: 'Free',
    //   value: [0, 0]
    // },
    {
      content: 'Any Price',
      value: [0, 1000]
    },
    {
      content: 'Up to $200',
      value: [0, 200]
    },
    {
      content: '$200 - $500',
      value: [200, 500]

    },
    {
      content: '$500 - $1,000',
      value: [500, 999]
    },
    {
      content: '$1,000 or more',
      value: [1000, 1000]
    },
  ];

  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {

    let menuItems = [];
    this.options.map(option => {
      let checked = option.content === this.props.price;
      console.log(option.content, this.props.price);
      let value = option.value;
      let content = option.content;
      menuItems.push(
        <div
          className="form-check form-check-inline tfn-npa-background"
          key={value}
        >
          <div
            data-menu-item = {value}
            value = {value}
            onClick={e => this.props.handlePrice({price: option.content, value: option.value})}
          >
            <SelectionControl
            data-menu-item	= {value}
            classes = {{radioWrapper: 'selection-radio-button'}}
            variant = 'radio'
            checked = {checked}
            name = {`mic-${value}}`}
            value = {`mic-${value}`}
            disableHover = {true}
            onClick={() => {}}
          />
          </div>
          <span>{content}</span>
      </div>
        )})
    return (
      <div className="price-filter">
        {menuItems}
      </div>
    );
  }

}

export default PriceSelector;
