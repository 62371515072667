import React from 'react'
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import { CancelIcon } from 'svg-icons'
import Typography from 'typography'
import { ThemeProvider } from 'theme-provider'

const useStyles = makeStyles(styles)

interface ChipProps extends MuiChipProps {
    /**
     * Show red color
     */
    error?: boolean
    color: 'primary' | 'secondary'
}

const getClasses = (props: ChipProps) => {
    const classNames = useStyles()
    const classesProp = { root: props.error ? classNames.errorChip : '' }
    if (props.classes) {
        if (props.classes.root) classesProp.root += ` ${props.classes.root}`
        const otherClasses = { ...props.classes }
        delete otherClasses.root
        Object.assign(classesProp, otherClasses)
    }
    return classesProp
}

/**
 * Chip component
 *
 * @param {ChipProps} props - props
 */
export const Chip = (props: ChipProps): JSX.Element => {
    const classes = getClasses(props)
    const label = props.label ? <Typography variant='body2'>{props.label}</Typography> : null
    // We need to set onDelete in order the delete icon to show up
    const onDelete = props.onDelete || ((): void => { return null })
    return (
        <ThemeProvider>
            <MuiChip
                deleteIcon = {<CancelIcon/>}
                label = {label}
                onDelete = {onDelete}
                classes = {classes}
                {...props}
            />
        </ThemeProvider>
    )
}

Chip.defaultProps = {
    error: false
}

export default Chip
