/**
 *
 */
const styles = (theme) => ({
    tooltip: {
        backgroundColor: theme.palette.text.primary,
        fontSize: 11,
        fontWeight: 600,
        lineHeight: '16px',
        fontFamily: 'Montserrat'
    },
    arrow: {
        '&:before': {
            border: `1px solid ${theme.palette.text.primary}`
        },
        color: theme.palette.text.primary
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50%',
        '& svg': {
            height: 18,
            width: 18
        }
    },
    tooltipLine: {
        display: 'flex',
        paddingBottom: 5
    }
})

export default styles
