import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { XIcon } from 'pdc-svg-icons'
import PropTypes from 'prop-types'

const styles = theme => ({
    listViewDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowX: 'auto'
    },
    mainRow: {
        fontSize: 34,
        fontWeight: 400,
        lineHeight: '40px',
        letterSpacing: '0.5px',
        marginBottom: 45,
        marginRight: 15
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 30
    },
    rowHeader: {
        fontSize: 14,
        color: '#197F9F',
        marginBottom: 5,
        lineHeight: '22px',
        fontWeight: 500
    },
    iconContainer: {
        marginBottom: '52.49px',
        '& svg': {
            color: theme.palette.primary[0],
            height: 19,
            cursor: 'pointer'
        }
    }

})

class TableListViewDetails extends Component {
    renderRow = (column, index) => {
        const { classes } = this.props
        if (column.primary) return
        return (
            <div key={column.key} className={classes.row}>
                <div className={classes.rowHeader}>{this.props.headColumns[index].content}</div>
                <div className={classes.rowContent}>{column.content}</div>
            </div>
        )
    }

    render () {
        const { classes } = this.props
        const primaryColumn = this.props.row.columns.find(c => c.primary)

        return (
            <div className={classes.listViewDetailsContainer}>
                <div className={classes.iconContainer}><XIcon onClick={() => this.props.closeDetails()}/></div>
                <div key={primaryColumn.key} className={classes.mainRow}>{primaryColumn.content}</div>
                {this.props.row.columns.map((column, index) => (
                    this.renderRow(column, index)
                ))}
            </div>

        )
    }
}

TableListViewDetails.propTypes = {
    classes: PropTypes.any,
    headColumns: PropTypes.array,
    row: PropTypes.object,
    closeDetails: PropTypes.func
}

export default withStyles(styles)(TableListViewDetails)
