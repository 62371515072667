<template>
	<div>
		 <div class="w-h5 mb-3 d-flex justify-center step-title">
            <p>{{ $lang.t('app.configure-the-phones-youre-ordering', 'Configure the phones you\'re ordering') }}</p>
        </div>
		<w-alert
			v-if="!cartLength()"
			class="mt-10"
			level="error"
			:message="$lang.t('devices.please-select-devides-first', 'Please select your devices')"
		/>
		<w-alert
			v-else-if="!current_cart.devices.length"
			:message="$lang.t('devices.you-have-chosen-only-accessories', 'You have chosen only accessories, that don\'t need setup. You may proceed to the next step.')"
			:closable="false"
			level="warning"
			class="mt-10"
		/>
		<v-simple-table v-else class="w-100">
			<thead>
				<tr>
					<th class="w-30">
						{{ $lang.t('app.model', 'Model') }}
					</th>
					<th class="w-30">
						{{ $lang.t('devices.ext-assigned-to', 'Ext / Assigned to') }}
					</th>
					<th>
						{{ $lang.t('devides.emergency-e911-address', 'Emergency E911 address') }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(device, i) of current_cart.devices" :key="`device-${device._original_index}-${device.id}`">
					<td>
						<div class="d-flex">
							<div :style="{width: '45px'}">
								<v-img
								  :src="`https://control.phone.com/images/lang/icon_voip_device_${device.code}.jpg`" alt=""
								  height="32"
								  width="40"
								  contain
								  :data-test-id="`buy-device-item-${i}-image`"
								/>
							</div>
							<div class="my-auto">
								{{generate_device_name(device)}}
							</div>
						</div>
					</td>
					<td>
						<w-select
							:items="filter_extensions(i)"
							:placeholder="$lang.t('selector.select-user-extension', 'Select User/Extension')"
							:value="device._custom_setup.lines.length ? device._custom_setup.lines[0].extension.id : null"
							 @change="add_extension_if_not_assigned(i, $event)"
							:data-test-id="`device-buy-setup-phones-${i}-extensions-selector`"
							:key="`ext-select-input-${!!confirm_extension_assignment}`"
						/>
					</td>
					<td>
						<E911AddressModal
							v-model="device._custom_setup.address"
							:ref="`address_modal_${i}`"
							:validation="true"
							:extension_selected="!device.address && device._custom_setup.lines.length > 0"
							:invalid_address="(device.errors && Object.values(device.errors).length > 0)"
							:trigger_btn_color="device.errors && device.errors.address ? 'error' : undefined"
							@error="address_validation_failed(i, $event)"
							@input="address_input(i)"
							@removed="remove_item(i)"
							@closed="force_enable_next = true"
						/>
						<div  class="validation mt-n1" v-if="device.errors">
							<div v-for="error of Object.values(device.errors)">
								<ValidationError v-if="error">
									{{ error }}
								</ValidationError>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		<StepperButtons
			:disable_continue="disable_continue()"
			@continue="submitted"
			@back="back"
			:key="`submit-${cartLength()}`"
		/>
		<DefaultModal v-if="confirm_extension_assignment" :value="true" @close="confirmation_modal_closed" max-width="500">
			<template v-slot:title>{{$lang.t('devices.assign-phone-to', 'Assign phone to {}', [confirm_extension_assignment.name])}}</template>
			{{$lang.t('devices-already-0has-phone-assigned-by-continuing', '{} already has a phone assigned. By continuing, the current phone will no longer be operational. While waiting for the new device to arrive, users can always make and receive calls on my.phone.com, or via the Phone.com iOS or Android apps.', [confirm_extension_assignment.name])}}
			<template v-slot:buttons>
				<w-btn @click="confirmation_modal_closed" color="secondary">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
				<w-btn @click="assignment_confirmed" color="primary">{{$lang.t('app.assign-phone', 'Assign phone')}}</w-btn>
			</template>
		</DefaultModal>
	</div>
</template>

<script>
import { formatDeviceModelName } from 'formatters';
import Cachier from 'cachier';
import StepperButtons from '../../../console/src/components/elements/StepperButtons.vue';
import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
import E911AddressModal from '../../../console/src/components/elements/E911AddressModal.vue';
import ValidationError from '../../../console/src/components/elements/form/FieldValidationError.vue';

export default {
	props: {
		cart: {
			required: true,
			type: Object,
			validator: (v) => Array.isArray(v.devices) && Array.isArray(v.accessories)
		},
		extensions: {
			required: true,
			type: Array,
		},
		order: {
			required: true,
			type: Object,
		},
	},
	components: {
		E911AddressModal,
		DefaultModal,
		StepperButtons,
		ValidationError
	},
	data() {
		return {
			type: null,
			current_cart: {devices: [], accessories: []},
			address_modal: null,
			confirm_extension_assignment: null,
			cachier: new Cachier(this.$session.user.id),
			force_enable_next: false,
		};
	},
	created() {
		this.put_keys_on_items(this.$props.cart);
	},
	methods: {
		options(i) {
			return [
				{
				cb: () => this.remove_item(i),
				title: this.$lang.t('app.remove-device', 'Remove device'),
				}
			];
		},
		put_keys_on_items(c) {
			const cart = JSON.parse(JSON.stringify(c));
			for (const type of ['devices', 'accessories']) {
				for (let i = 0; i < cart[type].length; i++) {
					cart[type][i]._original_index = i.toString(); // because of deleting same device with different extensions
				}
			}
			this.$data.current_cart = cart;
		},
		cartLength() {
			return this.$data.current_cart.devices.length + this.$data.current_cart.accessories.length
		},
		back() {
			this.$emit('back', this.clean_cart());
		},
		disable_continue() {
			if (this.$data.force_enable_next) return false;
			if (!this.cartLength()) return true;
			if (this.$data.current_cart.devices.find((x) => x.errors && Object.keys(x.errors).length)) return true;
			if (this.$data.current_cart.devices.find((x) => !x._custom_setup.address || !x._custom_setup.lines.length)) return true;
			return false;
		},
		submitted() {
			if (this.$data.current_cart.devices.find((x) => x.errors && Object.keys(x.errors).length)) {
				this.$data.force_enable_next = false;
				return null;
			}
			const valid_form = this.validate_form();
			if (valid_form) {
				this.$emit('submitted', this.clean_cart());
			}
			return this.$forceUpdate();
		},
		validate_form() {
			let valid_form = true;
			for (const device of this.$data.current_cart.devices) {
				device.errors = {};
				const lines_validation = this.validate_lines(device._custom_setup.lines);
				if (lines_validation) device.errors.lines = lines_validation;

				const address_validation = this.validate_address(device._custom_setup.address);
				if (address_validation) device.errors.address = address_validation;

				if (Object.keys(device.errors).length) {
					valid_form = false;
					device.error = this.$lang.t('app.enter-valid-address', 'Enter valid address');
				}
			}

			return valid_form;
		},
		generate_device_name(device) {
			return formatDeviceModelName(device)
		},
		remove_item(index) {
			if (index > -1) {
				this.$data.current_cart.devices.splice(index, 1);
				// const addresses = [];
				// this.$data.current_cart.devices.map((cart) => addresses.push(
				// 	{...cart._custom_setup.address}
				// ));
				// this.$data.cachier.setItem('step_two_address', addresses);
				this.$emit('updated', this.clean_cart());
				if (!this.$data.current_cart.devices.length) {
					this.$data.force_enable_next = false;
				}
				this.put_keys_on_items(this.$data.current_cart);
			}
		},
		clean_cart() {
			const cleaningCb = (x) => {
				delete x._original_index;
				delete x.errors;
				return x;
			};
			return {
				devices: this.$data.current_cart.devices.map(cleaningCb),
				accessories: this.$data.current_cart.accessories.map(cleaningCb),
			}
		},
		validate_address(address) {
			const error = this.$lang.t('app.enter-valid-address', 'Enter valid address');
			if (!address) {
				return error;
			}
			if (['line_1', 'city', 'postal_code', 'country'].find((x) => !address[x])) {
				return error;
			}

			return false;
		},
		validate_lines(lines) {
			const error = this.$lang.t('app.assign-phone-to-user', 'Assign the phone to the user');
			if (!lines) return error;
			if (!lines.length) return error;
			if (!lines[0].extension) return error;
			if (!lines[0].extension.id) return error;

			return false;
		},
		add_extension_if_not_assigned(index, id) {
			const extension = this.$props.extensions.find((x) => x.id === id);
			if (
				extension
				&& extension.device_membership
				&& extension.device_membership.device
				&& extension.device_membership.device.type !== 'generic'
				&& extension.device_membership.device.type !== 'iphone'
				&& extension.device_membership.device.type !== 'softphone'
			) {
				this.$data.confirm_extension_assignment = {
					id,
					index,
					name: extension.name
				};
			} else {
				this.assign_extension(index, id);
				setTimeout(() => {
				if (!this.$data.current_cart.devices[index]._custom_setup.address) {
					const ref = `address_modal_${index}`;
					if (this.$refs[ref] && this.$refs[ref].length) this.$refs[ref][0].show_modal();
				}
			}, 100);
			}
			return true;
		},
		assign_extension(index, id) {
			this.$data.current_cart.devices[index]._custom_setup.lines = [];
			this.$data.current_cart.devices[index]._custom_setup.lines[0] = {
				'line': 1,
				'extension': { id },
			};
			if (this.$data.current_cart.devices[index].errors) {
				delete this.$data.current_cart.devices[index].errors.lines;
			}
			this.$forceUpdate();
		},
		confirmation_modal_closed() {
			this.$data.confirm_extension_assignment = null;
			this.$forceUpdate();
		},
		assignment_confirmed() {
			this.assign_extension(this.$data.confirm_extension_assignment.index, this.$data.confirm_extension_assignment.id);
			if (!this.$data.current_cart.devices[this.$data.confirm_extension_assignment.index]._custom_setup.address) {
				const ref = `address_modal_${this.$data.confirm_extension_assignment.index}`;
				if (this.$refs[ref] && this.$refs[ref].length) this.$refs[ref][0].show_modal();
			}
			this.$data.confirm_extension_assignment = null;
		},
		address_input(index) {
			const addresses = [];
			// eslint-disable-next-line array-callback-return
			this.$data.current_cart.devices.map((item) => {
			if (item._custom_setup.address && Object.values(item._custom_setup.address).length) addresses.push({...item._custom_setup.address});
			});
			const uniqueArray = addresses.filter((value, i) => {
				const _value = JSON.stringify(value);
				return i === addresses.findIndex((obj) => JSON.stringify(obj) === _value);
			});
			this.$data.cachier.setItem('step_two_address', uniqueArray);
			if (this.$data.current_cart.devices[index].errors) {
				delete this.$data.current_cart.devices[index].errors.address;
				this.$forceUpdate();
			}
			this.$props.order.gtm_data_push('PDC_Set_E911_Address', 'Added Address')
		},
		filter_extensions(index) {
			let extensions = this.$props.extensions.map((x) => ({ value: x.id, text: `${x['extension']}: ${x['name']}` }));
			const in_use = this.$data.current_cart.devices.map((x, i) => {
				if (x && i !== index && x._custom_setup && x._custom_setup.lines.length) {
					return x._custom_setup.lines[0].extension.id;
				}

				return null;
			}).filter((x) => x);

			if (in_use.length) {
				extensions = extensions.filter((x) => !in_use.includes(x.value));
			}
			return extensions;
		},
		address_validation_failed(index, data) {
			this.$data.current_cart.devices[index].errors = this.$data.current_cart[index].errors || {};
			if (data) {
				this.$data.current_cart.devices[index].errors.address = this.$lang.t('app.enter-valid-address', 'Enter valid address');
				this.$emit('validation_failed');
			} else {
				this.$data.current_cart.devices[index].errors.address = null;
			}
			this.$forceUpdate();
		},
	},
	filters: {
		price(val) {
			return (val / 100).toFixed(2);
		},
	},
};
</script>

<style lang="scss" scoped>
  .validation{
	position: absolute;
  }
</style>
