import React from 'react'
import { connect } from 'react-redux'
import { updateVoicemail } from '../../actions/voicemail'
import VoicemailEditor from 'voicemail-editor'
import PropTypes from 'prop-types'

const mapStateToProps = state => ({ voicemail: state.voicemail })
const mapDispatchToProps = dispatch => ({ updateVoicemail: voicemail => dispatch(updateVoicemail(voicemail)) })

const Voicemail = (props) => {
    const showAlone = props.routeProps?.location.pathname.split('personal-settings/voicemail')[1].substring(0, 11) === '/show-alone'
    return (
        <VoicemailEditor
            voicemail = {props.voicemail}
            updateVoicemail = {props.updateVoicemail}
            setBusy = {props.setBusy}
            smallView = {props.smallView}
            showAlone = {!!showAlone}
        />
    )
}

Voicemail.propTypes = {
    /**
     * Voicemail info
     */
    voicemail: PropTypes.object,
    /**
     * Update the voicemail
     */
    updateVoicemail: PropTypes.func,
    /**
     * Call it with `true` in case there is an unsaved change
     * or with `false` in case of no changes. It will prevent going away from the app
     * in case the user clicks somewhere.
     */
    setBusy: PropTypes.func,
    /**
     * Is it small / mobile view
     */
    smallView: PropTypes.bool,
    /**
     * Route props
     */
    routeProps: PropTypes.any
}

Voicemail.defaultProps = {
    smallView: false
}

export default connect(mapStateToProps, mapDispatchToProps)(Voicemail)
