// eslint-disable-next-line jsdoc/require-description
/**
 * @param {object} theme
 */
const styles = theme => ({
    stepWizard: {
        '& > .stepper-wrapper': {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 631,
            maxWidth: '100%'
        },
        '& > .content': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: 30,
            // paddingLeft: 150,
            // paddingRight: 150,
            '& > div[class*="BusinessHours-businessHours"]': {
                height: 'calc(100% - 120px)'
            },
            '& .title-wrapper': {
                display: 'flex',
                alignItems: 'center',
                columnGap: 10,
                rowGap: 5,
                '& > svg': {
                    fontSize: 40
                }
            }
        },
        '&.mobile': {
            '& > .content': {
                paddingLeft: 25,
                paddingRight: 25,
                rowGap: 20,
                '& > .titles': {
                    marginBottom: 0
                }
            },
            '& .title-wrapper': {
                flexDirection: 'column'
            }
        }
    },
    transferMyNumberWrapper: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 15,
        fontWeight: 600,
        padding: 50
    },
    row: {
        marginBottom: 15,
        '& .number': {
            fontSize: 30,
            marginRight: 25
        },
        '& .greenText': {
            color: theme.palette.primary[0]
        },
        '& .redirect': {
            cursor: 'pointer'
        }
    },
    menuWrapper: {
        backgroundColor: '#F7F8FA',
        fontWeight: 500,
        fontSize: '14px'
    },
    checklistDesc: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '0px'
    },
    checklistItems: {
        textAlign: 'left',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        whiteSpace: 'pre-line',
        width: '100%',
        '& > ul': {
            marginBottom: 0,
            paddingLeft: 16
        }
    },
    checklistBoldItem: {
        '& > p': {
            fontWeight: '700 !important'
        },
        marginBottom: 5
    },
    checklistDescriptionItem: {
        listStyle: 'none',
        '&:not(:last-child)': {
            marginBottom: 20
        }
    },
    checklistVideoDesc: {
        fontWeight: 'bold',
        fontSize: '14px'
    },

    numberInfoDesc: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '0px',
        paddingBottom: '10px'
    },
    formItemSubtitle: {
        color: theme.palette.text.primary,
        fontFamily: 'Montserrat-medium !important'
    },
    formItemTitle: {
        color: theme.palette.info.dark,
        fontFamily: 'Montserrat-medium !important'
    },
    notificationItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: '7px 0',
        rowGap: 3,
        '& .accept-error-wrapper': {
            fontWeight: 500,
            fontSize: '10.5px',
            color: '#E85646',
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 22,
                height: '17.42px'
            }
        }
    },
    notificationText: {
        display: 'flex',
        flexDirection: 'column',
        '& .main': {
            fontSize: 18
        },
        '& .secondary': {
            fontSize: 14,
            color: 'gray'
        },
        '&.small .main': {
            fontSize: 12
        }
    },
    checklistStep: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 30,
        width: '100%',
        maxWidth: 700,
        '& ol, ul': {
            '-webkit-text-size-adjust': '100%'
        },
        '&.mobile': {
            '& > p': {
                textAlign: 'center',
                width: 340
            }
        }
    },
    numberInformation: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 700,
        width: '100%',
        rowGap: 30
    },
    selectFirstOption: {
        color: theme.palette.text.tertiary
    },
    stepRow: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5,
        justifyContent: 'space-between',
        '& .latest-bill-title-wrapper': {
            display: 'flex',
            columnGap: 5,
            alignItems: 'center'
        },
        '& .chip-field div[class*="MuiChip"] svg[class*="deleteIconColorSecondary"]': {
            cursor: 'default'
        }
    },
    uploadDocumentsRow: {
        display: 'flex',
        gap: 20,
        marginTop: 20
    },
    uploadDocumentBox: {
        border: '1px solid',
        borderColor: theme.palette.secondary.border,
        borderRadius: 10,
        width: '100%',
        height: 180,
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 14px',
        '&.error': {
            borderColor: theme.palette.warning.main
        }
    },
    documentBoxTitle: {
        marginTop: 17,
        display: 'flex',
        alignItems: 'start',
        gap: 4.5,
        '& p': {
            color: theme.palette.info.dark
        }
    },
    fileOnRecord: {
        margin: '14px 0px 0px 0px'
    },
    fileOnRecordText: {
        color: theme.palette.text.secondary
    },
    fileOnRecordRequiredText: {
        color: theme.palette.warning.main
    },
    uploadedFileName: {
        color: theme.palette.text.primary
    },
    fileControlButtons: {
        display: 'flex',
        marginBottom: 8
    },
    completionDateOptions: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 15
    },
    completionDateOption: {
        display: 'flex',
        alignItems: 'center',
        gap: 4.5,
        '& p': {
            fontSize: '11px !important'
        }
    },
    completionDatePicker: {
        display: 'flex',
        justifyContent: 'center',
        width: '80%',
        margin: '0 auto'
    },
    radioButton: {
        '& svg': {
            color: `${theme.palette.text.secondary} !important`
        }
    },
    phoneComLink: {
        pointerEvents: 'all !important',
        color: '#28a745 !important'
    },

    // ACCOUNT INFORMATION STEP START
    accountInfoStep: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 700,
        width: '100%',
        '& .step-row': {
            display: 'flex',
            columnGap: 30,
            justifyContent: 'space-between'
        },
        '& .wireless-check': {
            display: 'flex',
            columnGap: 5,
            alignItems: 'center'
        },
        '& .billing-check': {
            marginBottom: 20,
            '& p': {
                fontSize: '12px !important'
            }
        }
    },
    // ACCOUNT INFORMATION STEP END

    section: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 15,
        marginBottom: 15,
        '& .section-title': {
            paddingTop: '18px',
            display: 'flex',
            alignItems: 'center',
            columnGap: 5,
            marginBottom: 2,
            '& > p': {
                color: theme.palette.info.dark
            },
            '&.mobile': {
                marginBottom: 10
            }
        },
        '& .section-subtitle': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 15,
            '& > p': {
                color: theme.palette.primary.textColor
            },
            '&.mobile': {
                marginBottom: 15
            }
        },
        '& .section-content': {
            display: 'flex',
            flexDirection: 'column',
            '& .section-row': {
                display: 'flex',
                columnGap: 15,
                width: '100%',
                '&:not(:last-child)': {
                    marginBottom: 15
                }
            }
        }
    },
    loadingDiv: Object.assign({}, theme.loadingDiv, { zIndex: 5000, position: 'fixed' }),
    numberAssignment: {
        width: '100%',
        maxWidth: 700
    },
    confirmChargesSubheader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    additionalNumbersCharge: {
        color: theme.palette.info.dark,
        display: 'flex',
        gap: 3,
        fontFamily: 'Montserrat-medium !important'
    },
    phoneNumberDropdownMenu: {
        width: 329
    },
    numberAssignmentTdClas: {
        width: '50%'
    },
    smallNumbersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 30,
        alignItems: 'center',
        '& .number-row': {
            '& > p:nth-child(1)': {
                paddingBottom: 15
            },
            '& > span:nth-child(2)': {
                color: `${theme.palette.info.dark} !important`
            },
            '& > div:nth-child(3)': {
                paddingTop: 10
            }
        }
    },
    // LOA
    loaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 700,
        width: '100%',
        rowGap: 30
    },
    loaTopContent: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        rowGap: 15,
        '& .loa-top-row': {
            display: 'flex',
            justifyContent: 'space-between',
            rowGap: 15,
            '&.small': {
                flexDirection: 'column',
                alignItems: 'center'
            }
        },
        '&.small': {
            textAlign: 'center',
            marginRight: '-25px',
            marginLeft: '-25px',
            background: 'rgba(140, 152, 160, 0.1)',
            paddingTop: 20,
            paddingBottom: 20,
            rowGap: 15
        }
    },
    loaTopContentBox: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5,
        flex: 3,
        '& > p:nth-child(1)': {
            color: `${theme.palette.info.dark} !important`
        },
        '&:last-child': {
            flex: 4
        }
    },
    loaTextContent: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15
    },
    selectBillButton: {
        marginTop: '10px'
    },
    currentlySelectedBill: {
        color: '#197F9F',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '0px',
        paddingTop: '15px'
    },
    removeBillButton: {
        color: '#3FAE29',
        backgroundColor: 'white',
        marginLeft: '-15px',
        '&:hover': {
            backgroundColor: '#CEEFC7'
        }
    },
    uploadButton: {
        color: theme.palette.primary.dark,
        backgroundColor: 'white',
        padding: 0,
        cursor: 'pointer',
        '&:hover': {
            // don't change the background color on hover
            backgroundColor: 'white !important'
        },
        border: 'none !important',
        outline: 'none !important',
        '& span': {
            fontWeight: '600 !important'
        },
        '& svg': {
            fontSize: '16px !important',
            marginRight: '5px'
        },
        '&:active': {
            backgroundColor: 'white !important',
            outline: 'none !important'
        }
    },
    uploadButtonDisabled: {
        color: theme.palette.primary.light,
        backgroundColor: 'white',
        cursor: 'pointer',
        '&:hover': {
            // don't change the background color on hover
            backgroundColor: 'white !important'
        },
        border: 'none !important',
        outline: 'none !important',
        '& svg': {
            fontSize: '16px !important',
            marginRight: '5px'
        },
        '&:active': {
            backgroundColor: 'white !important',
            outline: 'none !important'
        },
        pointerEvents: 'none'
    },
    removeFileButton: {
        color: theme.palette.secondary.dark,
        backgroundColor: 'white',
        cursor: 'pointer',
        marginLeft: 5,
        '&:hover': {
            // don't change the background color on hover
            backgroundColor: 'white !important'
        },
        border: 'none !important',
        outline: 'none !important',
        '& span': {
            fontWeight: '600 !important'
        },
        '& svg': {
            fontSize: '16px !important',
            marginRight: '5px'
        },
        '&:active': {
            backgroundColor: 'white !important',
            outline: 'none !important'
        },
        '&.disabled': {
            color: `${theme.palette.text.disabled} !important`,
            pointerEvents: 'none'
        }
    },
    tempNumberHeaderColumn: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 5,
        '& svg': {
            color: `${theme.palette.text.primary} !important`
        }
    },

    wrapper: {
        position: 'relative',
        width: '100%',
        // height: 'calc(100% - 60px)',
        display: 'flex',
        flexDirection: 'column',
        '& > .content': {
            display: 'flex',
            flexDirection: 'column',
            gap: 50,
            flex: 1,
            overflowY: 'auto',
            marginTop: 100,
            marginBottom: 87,
            paddingBottom: 30,
            alignItems: 'center',
            '& .titles': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: theme.palette.text.primary,
                '& .title': {
                    marginBottom: 15
                }
            }
        },
        '& > .stepper-wrapper': {
            margin: 50,
            marginBottom: 65
        },
        '&.mobile': {
            '& > .content': {
                overflowY: 'auto',
                '& .titles': {
                    marginBottom: 30,
                    color: theme.palette.text.primary,
                    '& .title': {
                        marginBottom: 10
                    },
                    '& .subtitle': {
                        textAlign: 'center',
                        '& > p': {
                            textAlign: 'center'
                        }
                    }
                }
            },
            '& > .stepper-wrapper': {
                margin: '15px 0'
            }
        },
        '& .MuiStep-root': {
            paddingTop: 12
        },
        '& .bottom-navigation': {
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0
        }
    }

})

export default styles
