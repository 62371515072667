<template>
    <div>
        <w-loader v-if="loading" :message="false" size="small"/>
        <template v-else>
            <div v-if="error" class="error--text text--darken-1 cursor-pointer" @click="show_numbers">
                {{error}}. {{$lang.t('app.retry', 'Retry')}}?
            </div>
            <div v-else>
                <div v-if="!phone_numbers.length">
                    {{$lang.t('lr.we-couldnt-find-any-related-number-to-this-script', 'No connected numbers')}}
                </div>
                <div v-else>
                    <div v-for="num of phone_numbers" :key="num.object.id">
                        {{num.real_object.name}}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
    import PromisesQueue from 'console/src/libs/PromisesQueue';

    export default {
        props: {
            id: {
                type: Number,
                required: true,
            },
        },
        data() {
          return {
            loading: false,
            error: null,
            phone_numbers: [],
            loaded: false,
            component_destroyed: true,
          };
        },
        async created() {
            await this.show_numbers();
        },
        beforeDestroy() {
            this.$data.component_destroyed = false;
        },
        methods: {
            async show_numbers() {
                this.$data.loading = true;
                this.$data.error = null;
                if (!this.$data.loaded) {
                    await PromisesQueue.enqueue(() => this.load_numbers())
                }
                this.$data.loading = false;        
            },
            async load_numbers() {
                if (!this.$data.component_destroyed) return null;
                try {
                    const res = await this.$session.get_item(`/live-answer/${this.$props.id}/usage?levels=5`);
                    this.$data.phone_numbers = res.items.filter((x) => x.real_object && x.real_object_type === 'did');
                    this.$data.loaded = true;
                } catch(err) {
                    this.$data.error = this.$lang.t('app.something-went-wrong', 'Something went wrong')
                    console.log('Error loading script related number', err);
                }     
            },
        }
    };
</script>
