import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import ConfirmModal from 'confirm-modal'
import Spinner from 'spinner'
import TextField from 'pdc-text-field'
import PDCButton from 'button'
import RemoteConfigValue from 'remote-config-value'

/***/
export interface Props {
    classes
    user
    number
    selectedNumber
    newNumbers
    addAvailableNumber
    findAvailableNumbers
    onCloseModal
    smallView
    addNumberUser
    addNumberPending
    searchPending
    showModal
}

/***/
export interface State {
    selectedNumber
    areaCode
    areaCodeError
}

class QuickAddNumberModal extends Component<Props, State> {
    /***/
    constructor (props) {
        super(props)
        this.state = {
            areaCode: '',
            areaCodeError: false,
            selectedNumber: null
        }
    }

    hanldeSearchOnEnter = (key) => {
        if (key !== 'Enter') return
        this.findNumbers()
    }

    setAreaCode = areaCode => {
        this.setState({ areaCodeError: false })
        const numberPattern = /\d+/g
        if (areaCode.length > 3) return
        areaCode = (areaCode.match(numberPattern) || [''])[0]
        this.setState({ areaCode })
    }

    findNumbers = () => {
        const areaCode = this.state.areaCode
        if (areaCode.length > 0 && areaCode.length !== 3) {
            this.setState({ areaCodeError: 'Please enter a valid area code' })
            return
        }
        this.setState({ selectedNumber: null })
        this.props.findAvailableNumbers(this.state.areaCode)
    }

    addNumber = async () => {
        const response = await this.props.addAvailableNumber(this.state.selectedNumber, this.props.addNumberUser)
        if (response) {
            this.props.onCloseModal()
            this.setState({ selectedNumber: null, areaCode: '' })
            this.props.findAvailableNumbers()
        }
    }

    renderNoNumberFound = () => {
        const { classes } = this.props
        return (
            <div className={`${classes.noResultsWrapper} ${this.props.smallView ? 'small' : ''}`}>
                <span>We couldn&apos;t find any numbers, please try a different area code.</span>
            </div>
        )
    }

    renderNumberItem = (number, i) => {
        const { classes } = this.props
        const selectedNumber = this.state.selectedNumber || { e164: null }
        return (
            <div
                key = {i}
                className ={`${selectedNumber.e164 === number.e164 ? classes.numberItemSelected : classes.numberItem} ${this.props.smallView ? 'small' : ''}`}
                onClick = {() => this.setState({ selectedNumber: number })}
            >
                <span className='price'>Free</span>
                <span className='number'>{number.formatted}</span>
            </div>
        )
    }

    renderResultsRow = () => {
        const { classes } = this.props
        return (
            <div className={classes.resultsRow}>
                <div className={`${classes.resultsWrapper} ${this.props.smallView ? 'small' : ''}`}>
                    {this.props.newNumbers.length > 0
                        ? this.props.newNumbers.map(this.renderNumberItem)
                        : this.renderNoNumberFound()
                    }
                </div>
            </div>
        )
    }

    renderPendingRow = () => {
        const { classes } = this.props
        return (
            <div className={`${classes.spinnerRow} ${this.props.smallView ? 'small' : ''}`}>
                <Spinner/>
                <span>
                    {this.props.addNumberPending
                        ? 'Please wait while we setup your new number...'
                        : 'Searching for numbers...'
                    }
                </span>
            </div>
        )
    }

    renderModalContent = () => {
        const { classes } = this.props
        return (
            <div className={`${classes.modalMain} ${this.props.smallView ? 'small' : ''}`}>
                <div className={`${classes.filtersRow} ${this.props.smallView ? 'small' : ''}`}>
                    <TextField
                        className = {{ wrapper: classes.textField }}
                        fullWidth = {false}
                        label = 'Area Code'
                        error = {this.state.areaCodeError}
                        editable = {true}
                        onInputChange = {value => this.setAreaCode(value)}
                        content = {this.state.areaCode}
                        disabled = {this.props.addNumberPending || this.props.searchPending}
                        onKeyPress = {event => this.hanldeSearchOnEnter(event.key)}
                    />
                    <div className={`${classes.buttonWrapper} ${this.props.smallView ? 'small' : ''}`}>
                        <PDCButton color='primary' disabled={this.props.searchPending || this.props.addNumberPending} data-button='search-numbers' variant='outlined' icon='search' onClick={() => this.findNumbers()}>Search</PDCButton>
                    </div>
                </div>
                {this.props.searchPending || this.props.addNumberPending ? this.renderPendingRow() : this.renderResultsRow()}
                {this.state.selectedNumber !== null && !this.props.searchPending && !this.props.addNumberPending
                    ? <div className={`${classes.confirmRow} ${this.props.smallView ? 'small' : ''}`}>
                        <span>{`Are you sure you want to add ${this.state.selectedNumber ? this.state.selectedNumber.formatted : 'this number'} to your account?`}</span>
                        <span><RemoteConfigValue valueId={'quick_add_number_widget_message'}/></span>
                    </div>
                    : null}
            </div>
        )
    }

    render () {
        const modalContent = this.renderModalContent()

        return (
            <ConfirmModal
                title = ''
                isShown = {this.props.showModal}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = 'Confirm'
                confirmButtonDisabled = {this.props.addNumberPending || this.props.searchPending || this.state.selectedNumber === null}
                rejectButtonDisabled = {this.props.addNumberPending || this.props.searchPending}
                onReject = {() => this.props.onCloseModal()}
                onConfirm = {() => this.addNumber()}
                size = {this.props.smallView ? 'sizeMobile' : 'custom'}
            />
        )
    }
}

/***/
export default withStyles(styles)(QuickAddNumberModal)
