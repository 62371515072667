// const theme = (contextTheme.palette.secondary['-300']) ? contextTheme : mainTheme

/**
 * @param {object} theme
 */
const styles = (theme) => ({
    wrapper: {
        position: 'relative',
        width: '100%',
        // height: 'calc(100% - 60px)',
        display: 'flex',
        flexDirection: 'column',
        '& > .content': {
            flex: 1,
            overflowY: 'auto',
            marginBottom: 87,
            paddingBottom: 30,
            '& .titles': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: theme.palette.text.primary,
                '& .title': {
                    marginBottom: 15
                },
                '& .subtitle': {
                    textAlign: 'center',
                    width: 538
                }
            }
        },
        '& > .stepper-wrapper': {
            margin: 50,
            marginBottom: 65,
            '@media (max-width: 600px)': {
                margin: 24,
                marginBottom: 36
            },
            '& .MuiMobileStepper-no-padding': {
                padding: 0
            }
        },
        '&.mobile': {
            '& > .content': {
                overflowY: 'auto',
                '& .titles': {
                    marginBottom: 30,
                    color: theme.palette.text.primary,
                    '& .title': {
                        marginBottom: 10
                    },
                    '& .subtitle': {
                        textAlign: 'center',
                        '& > p': {
                            textAlign: 'center'
                        }
                    }
                }
            },
            '& > .stepper-wrapper': {
                margin: '15px 0'
            }
        },
        '& .MuiStep-root': {
            paddingTop: 12
        },
        '& .bottom-navigation': {
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0
        }
    }
})

export default styles
