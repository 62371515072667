import l from '../lang';

export default class CsvItemsDownloader {
	constructor(session, chunk_size = 500) {
		this.session = session;
		this.stop = false;
		this.finished = false;
		this.total = 0;
		this._items_loaded = 0;
		this.chunk_size = chunk_size;
		this.items_loaded_before = 0;
	}

	get items_loaded() {
		return this._items_loaded;
	}

	set items_loaded(val) {
		this.items_loaded_before = this._items_loaded;
		this._items_loaded = val;
	}

	async get_list_all(uri) { // we dont use session.get_list_all because of call_logs endpoint timeout
	    this.stop = false;
	    this.finished = false;
	    let all = [];
	    let res;
	    do {
	        if (this.stop) return 'aborted';
	        res = await this.session.get_list(
	          uri,
	          this.chunk_size,
	          res ? res['offset'] + res['limit'] : 0
	        );
	        this.total = res.total || 0;
	        all = all.concat(res['items']);
	        this.items_loaded += res.items.length;
	    } while (res['total'] > all.length && res['items'].length);
	    this.finished = true;
        // setTimeout(() => this.items_loaded = null, 1000);
	    return {
	        items: all,
	        offset: 0,
	        total: all.length,
	        limit: all.length,
	    };
	}

	get downaloading_message() {
	  const { items_loaded } = this;
	  if (items_loaded === 0) {
	    return l.t('cl.downloading-up-to-items', 'Downloading up to {} items...', [this.chunk_size]);
	  }
	  if (items_loaded === this.total) {
		return l.t('app.downloading-finished', 'Downolad complete. {} items downloaded total.', [this.total]);
	  }
	  if ((items_loaded + this.chunk_size) >= this.total) {
	    return l.t('cl.downloading-last-items', 'Downloading last {} items...', [this.total - items_loaded]);
	  }

	  return l.t('cl.downloading-progressbar-label', 'Downloading {} to {} items...', [items_loaded, items_loaded + this.chunk_size, this.total]);
	}

	get success_message() {
	  const { items_loaded } = this;
	  if (items_loaded === this.total) {
	    return l.t('cl.downloaded-complete', 'Downlaoded all {} items', [this.total]);
	  }
	  if ((items_loaded + this.chunk_size) >= this.items_loaded) {
	    return l.t('cl.success-downloading-items-to-total', 'Working... {} of {} items', [items_loaded, this.items_loaded]);
	  }

	  return l.t('cl.success-downloading-items-to-total', 'Working... {} of {} items', [items_loaded, this.total]);
	}
}
