// React
import React from "react";
import FilterButton from "../filter-button/filter-button"
import HoverBoxWrapper from "../hover-box-wrapper/hover-box-wrapper"
import PriceSelector from "./price-selector/price-selector"


class PriceFilter extends React.Component {

  constructor(props) {
    super(props);
    this.initialState = {
      selectorOpen: false,
      reset: this.props.reset
    };
    this.state = JSON.parse(JSON.stringify(this.initialState));
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  // Hide HoverBoxWrapper if click away from component
  handleClick = e => {
    if (this.node.contains(e.target)) {
      // Do nothing - click was within component
      return;
    }
    else {
      this.setState({selectorOpen: false});
    }
  };

  toggleSelector = () => {
    this.setState({
       selectorOpen: !this.state.selectorOpen
    })
  };

  componentDidUpdate(prevProps) {

    if (this.props.reset !== prevProps.reset) {
      this.props.handlePrice({price: 'Any Price', value: [0, 1000]})
    }
  }

  render() {

    const child = <PriceSelector
      price={this.props.price}
      handlePrice={this.props.handlePrice}
      handleCheckbox={this.props.handleCheckbox}
      reset={this.state.reset}  // TODO: Fix reset
    />;

    let iconClick = this.toggleSelector
    if (this.props.price != 'Any Price'){
        iconClick = () => this.props.handlePrice({price: 'Any Price', value: [0, 1000]})
    }

    return (
      <div
        ref={node => this.node = node}
        style={{'position': 'relative'}}
      >
          <FilterButton
            data-test-id={'price-filter'}
            selectedFilters={this.props.price != 'Any Price' ? this.props.price : []}
            stringifySelected={
              () => {return this.props.price}
            }
            handleClick={this.toggleSelector}
            defaultText={this.props.price}
            open={this.state.selectorOpen}
            handleIconClick={iconClick}
            />
          <HoverBoxWrapper
            children={child}
            open={this.state.selectorOpen}
          />
      </div>
    );
  }
}

export default PriceFilter;
