import React from 'react'
import { MicIcon, StopSquareIcon } from 'svg-icons'

interface Props {
    classes
    isRecording: boolean
    onClick: () => void
}

/**
 * @param {Props} props
 */
const ActionButton = (props: Props): JSX.Element => {
    const { classes, isRecording, onClick } = props
    const Icon = isRecording ? StopSquareIcon : MicIcon
    const recordingClass = isRecording ? 'recording' : ''
    return (
        <div
            className = {`${classes.actionButton} ${recordingClass}`}
            onClick = {onClick}
            data-test-id = {`audio-player-${isRecording ? 'stop-recording' : 'record'}-btn`}
            title = 'Start recording'
        >
            <div><Icon/></div>
        </div>
    )
}

export default ActionButton
