import React from "react";
import "./hover-box-wrapper.css";


class HoverBoxWrapper extends React.Component {

  render() {

    if (this.props.open) {
      return (
        <div className="hover-box-wrapper">
          {this.props.children}
        </div>
      );
    }
    else {
      return (<div/>);
    }
  }

}

export default HoverBoxWrapper;
