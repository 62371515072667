<template>
    <transition name="collapsable">
        <div v-if="value" class="light-background d-flex buy-device-no-extensions-alert">
            <v-icon color="info darken-1">$vuetify.icons.infotip_hot</v-icon>
            <div class="w-body-2 info--text text--darken-1">
                <slot></slot>
            </div>
            <span v-if="closable" class="close-alert cursor-pointer ml-2">
                <v-icon size="24" color="info lighten-1" @click="$emit('input', false)">mdi mdi-close</v-icon>
            </span>
          </div>
    </transition>
</template>
<script>
  export default {
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        closable: {
            type: Boolean,
            default: true,
        }
    },
    data() {
      return {
      };
    },
  };
</script>

<style lang="scss" scoped>
.buy-device-no-extensions-alert {
	padding: 15px;
	max-width: 600px;
	margin: 14px auto 20px auto;
	.v-icon {
		margin-right: 6px;
	}
}
</style>
