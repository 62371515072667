import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5663%3A6970
 *
 * @param {SvgIconProps} props - svg props
 */
export const MenuIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M4 6.74976C4 6.33554 4.33579 5.99976 4.75 5.99976H19.25C19.6642 5.99976 20 6.33554 20 6.74976V7.24976C20 7.66397 19.6642 7.99976 19.25 7.99976H4.75C4.33579 7.99976 4 7.66397 4 7.24976V6.74976ZM4 11.7498C4 11.3355 4.33579 10.9998 4.75 10.9998H19.25C19.6642 10.9998 20 11.3355 20 11.7498V12.2498C20 12.664 19.6642 12.9998 19.25 12.9998H4.75C4.33579 12.9998 4 12.664 4 12.2498V11.7498ZM20 16.7498C20 16.3355 19.6642 15.9998 19.25 15.9998H4.75C4.33579 15.9998 4 16.3355 4 16.7498V17.2498C4 17.664 4.33579 17.9998 4.75 17.9998H19.25C19.6642 17.9998 20 17.664 20 17.2498V16.7498Z' fill={color}/>
        </SvgIcon>
    )
}

export default MenuIcon
