import Branding from './setup'
import BrandingThemes from './themes'

/**
 *
 */
export {
    Branding,
    BrandingThemes
}
