<template>
  <div>
    <IndexPage
        :resource="order"
        :disable_add_new="true"
        :disable_filters="true"
        :disable_mass_operations="true"
        class="list-page orders"
        data-test-id="orders-list"
    >
        <template v-slot:list>
        <div class="w-h5 d-flex justify-center mb-7">{{$lang.t('orders.order-history', 'Order history')}}</div>
        <v-simple-table class="list-table" data-test-id="orders-table">
            <thead data-test-id="table-head">
            <tr data-test-id="head-row">
                <th data-test-id="order-number">{{$lang.t('orders.order-number', 'Order number')}}</th>
                <th data-test-id="status">{{$lang.t('orders.status', 'Status')}}</th>
                <th data-test-id="shipping-method">{{$lang.t('app.shipping-method', 'Shipping method')}}</th>
                <th data-test-id="contact">{{$lang.t('app.contact', 'Contact')}}</th>
                <th data-test-id="number-of-devices">{{$lang.t('orders.items', 'Items')}}</th>
                <th data-test-id="tracking-number">{{$lang.t('orders.tracking-number', 'Tracking number')}}</th>
                <th class="minimal-width" data-test-id="more-options"><br/></th>
            </tr>
            </thead>
            <tbody data-test-id="table-body">
            <tr v-for="(item, i) in order.items" :key="item.id" :data-test-id="item.id">
                <td data-test-id="link">
                <w-link
                  @click="on_link_click(item)"
                  tag="a"
                  :to="{
                  name: 'devices.my-orders.show',
                  params: { id: item.id, _order: item }
                  }"
                  class="list-link"
                  :data-test-id="`orders-item-${i}-link`"
                >
                    {{ item.id }}
                </w-link>
                </td>
                <td data-test-id="status">
                {{order.status_translations[item.status] || format_status(item.status)}}
                &nbsp;
                <span class="text--text text--lighten-1">{{format_date(item.status_date)}}</span>
                </td>
                <td data-test-id="shipping-method">
                {{ order.formatShippingMethod(item.shipping_method) }}
                </td>
                <td data-test-id="contact">
                {{ item.contact ? item.contact.name : '—' }}
                </td>
                <td data-test-id="devices">{{ itemsNumber(item) }}</td>
                <td data-test-id="tracking-number">
                <a
                    v-if="item.tracking_number"
                    :href="`https://www.fedex.com/fedextrack/?tracknumbers=${item.tracking_number}`"
                    target="_blank"
                    class="w-body-2"
                    data-test-id="tracking-number-link"
                >
                    {{item.tracking_number}}
                </a>
                <span v-else class="w-body-2 text--text text--lighten-1" data-test-id="available-after-shipping">
                    {{$lang.t('orders.available-after-shipping', 'Available after shipping')}}
                </span>
                </td>
                <td data-test-id="more-options-orders">
                <MoreOptions :disabled="item.status !== 'new'" :options="more_options(item)" />
                </td>
            </tr>
            </tbody>
        </v-simple-table>
        </template>
    </IndexPage>
    <DefaultModal v-model="show_edit_address_modal" data-test-id="show_edit_address_modal" :width="500">
      <template v-slot:title>{{$lang.t('orders.change-your-shipping-address', 'Change your shipping address')}}</template>
      <AddressSelector
					v-model="shipping_address"
					:items="addresses"
					class="my-4 w-100"
					:label="$lang.t('orders.shipping-address', 'Shipping address')"
          :key="JSON.stringify(shipping_address)"
				/>
        <template v-slot:buttons>
					<w-btn color="secondary" @click="show_edit_address_modal = false">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
					<w-btn color="primary" :disabled="disable_submit()" @click="change_address">{{$lang.t('app.confirm', 'Confirm')}}</w-btn>
				</template>
    </DefaultModal>
    <DefaultModal v-model="show_cancel_order_modal" data-test-id="show_cancel_order_modal" :width="300">
      <span>{{$lang.t('orders.are-you-sure-you-want-to-cancel-your-order', 'Are you sure you want to cancel your order?')}} 
        <br/> {{$lang.t('orders.this-action-cannot-be-undone', 'This action cannot be undone.')}} </span>
        <template v-slot:buttons>
            <w-btn color="secondary" @click="show_cancel_order_modal = false">{{$lang.t('app.no', 'No')}}</w-btn>
            <w-btn color="primary" @click="cancel_order">{{$lang.t('app.yes', 'Yes')}}</w-btn>
        </template>
    </DefaultModal>
</div>
</template>

<script>
  import { vueComponent } from 'helpers';
  import Order from '../../../console/src/models/Order';
  import Contact from '../../../console/src/models/Contact';
  import MoreOptions from '../../../console/src/components/elements/MoreOptions.vue';
  import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
  import AddressSelector from '../../../console/src/components/elements/form/AddressSelector.vue';
  export default {
    props: {
      clickOnShowPageLink: {
        type: Function,
      },
    },
    components: {
      MoreOptions,
      DefaultModal,
      AddressSelector,
      IndexPage: () => import('../../../console/src/components/elements/IndexPage.vue'),
    },
    data() {
      return {
        order: new Order(this.$session, vueComponent(this)),
        show_edit_address_modal: false,
        show_cancel_order_modal: false,
        shipping_address: null,
        addresses: [],
        contact: new Contact(this.$session, vueComponent(this)),
        curret_order: null,
      };
    },
    async mounted() {
      await this.$data.order.loadShippingMethods();
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.order.handleUserRedirect(to, from, next);
      });
    },

    async created() {
      const fetched_addresses = await this.$data.contact.loadAddresses();
      if (Array.isArray(fetched_addresses) && fetched_addresses.length > 0) {
          for (const address of fetched_addresses) {
            this.$data.addresses.push(this.convert_address(address.address));
          }
      }
    },
    methods: {
      on_link_click(item) {
        if (typeof this.$props.clickOnShowPageLink === 'function') this.$props.clickOnShowPageLink(item)
      },
      format_date(val) {
        const date = new Date(val * 1000);
        return date.toLocaleString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
      },
      format_status(val) {
        switch(val) {
            case 'pending':
              return this.$lang.t('orders.pending', 'Pending')
            case 'shipped':
              return this.$lang.t('orders.shipped', 'Shipped')
            case 'delivered':
              return this.$lang.t('orders.delivered', 'Delivered')
            case 'new':
              return this.$lang.t('orders.new', 'New')
            case 'deleted':
              return this.$lang.t('orders.cancelled', 'Cancelled')
            default:
              return '';
        }
      },
      async change_address() {
        this.$data.show_edit_address_modal = false;
        const payload = {
          address: this.$data.shipping_address,
          company: this.curret_order.contact.company,
          name: this.curret_order.contact.name,
          phone: this.curret_order.contact.phone
        }
        const new_contact = await this.$session.create_item('/contacts', payload);
        const successfull_change = await this.$data.order.change_address(this.$data.curret_order, new_contact.id);
        if (successfull_change) this.$data.order.items.find(x => x.id === this.$data.curret_order.id).contact = payload;
        this.$forceUpdate();
      },
      async cancel_order() {
        this.$data.show_cancel_order_modal = false;
        const updated_order = await this.$data.order.cancel_order(this.$data.curret_order);
        if (this.$data.order.alert && this.$data.order.alert.level === 'success') {
          this.$data.order.items.find(x => x.id === this.$data.curret_order.id).status = 'deleted'
          if (this.$props.postSaveCb) {
            this.$props.postSaveCb(updated_order);
          }
        }
        this.$forceUpdate();
      },
      open_edit_address_modal(item) {
        this.$data.shipping_address = this.convert_address(item.contact.address);
        this.$data.show_edit_address_modal = true;
        this.curret_order = item;
      },
      convert_address(address) {
        const convertedAddress = {
          ...address,
          line_1: address.line_1.toUpperCase(),
          line_2: address.line_2.toUpperCase(),
          city: address.city.toUpperCase(),
        }
        return convertedAddress;
      },
      open_cancel_order_modal(item) {
        this.$data.show_cancel_order_modal = true;
        this.curret_order = item;
      },
      disable_submit() {
        if (this.$data.curret_order && this.$data.curret_order.contact) {
          return !this.$data.shipping_address || JSON.stringify(this.$data.shipping_address).toLowerCase() === JSON.stringify(this.$data.curret_order.contact.address).toLowerCase();
        }
        return false;
      },
      more_options(item) {
        const items = [
          {
            cb: () => this.open_edit_address_modal(item),
            title: this.$lang.t('orders.edit-shipping-address', 'Edit Shipping Address'),
          },
          {
            cb: () => this.open_cancel_order_modal(item),
            title: this.$lang.t('orders.cancel-order', 'Cancel order'),
          },
        ];
        return items;
      },
      itemsNumber(item) {
        let count = 0;
        if (item.devices && item.devices.length) {
          count += item.devices.length;
        }
        if (item.accessories && item.accessories.length) {
          count += item.accessories.length;
        }

        return count;
      }

    },
  };
</script>
