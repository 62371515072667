import LiveReceptionistCreate from './components/screens/create.vue'
import LiveReceptionistShow from './components/screens/show.vue'
import LiveReceptionistIndex from './components/screens/index.vue'

/**
 *
 */
export {
    LiveReceptionistCreate,
    LiveReceptionistShow,
    LiveReceptionistIndex
}
