import React from 'react'
import { mappedUSATimezones, timezoneMenuItems } from './timezones'
import { Autocomplete } from 'autocomplete-mui'
import PropTypes from 'prop-types'
import TimezoneFieldOld from './TimezoneFieldOld'

const DEFAULT_TIMEZONE = timezoneMenuItems[0].value

/**
 * Menu field that groups the american timezones
 *
 * @param {object} props - react props
 */
const TimezoneField = (props) => {
    const getTimezone = timezone => {
        if (!(timezone in mappedUSATimezones)) {
            const generalUSATimezones = Object.keys(mappedUSATimezones)
            const USATimezone = generalUSATimezones.find(t => mappedUSATimezones[t].includes(timezone))
            if (USATimezone) return USATimezone
        }
        return timezone
    }

    const savedTimezone = getTimezone(props.savedTimezone)

    const onChangeTimezone = timezone => {
        if (timezone in mappedUSATimezones) {
            const newTimezone = getTimezone(timezone)
            if (newTimezone === savedTimezone) timezone = props.savedTimezone
            else timezone = mappedUSATimezones[timezone][0]
        }
        props.onChange(timezone)
    }

    const timezoneValue = getTimezone(props.timezoneValue || DEFAULT_TIMEZONE)
    const selectedTimezone = timezoneMenuItems.find(t => t.value === timezoneValue)

    if (props.origin === 'general-settings') return <TimezoneFieldOld {...props}/>

    const otherProps = { ...props }
    const removeProps = ['savedTimezone', 'onChange', 'timezoneValue', 'origin']
    removeProps.forEach(p => delete otherProps[p])

    return (
        <Autocomplete
            {...otherProps}
            id = 'timezone-field'
            options = {timezoneMenuItems}
            getOptionLabel = {option => option.content}
            value = {selectedTimezone}
            onChange = {timezone => onChangeTimezone(timezone?.value || null)}
            style = {{ width: props.fieldWidth || 300 }}
            label = {props.label}
        />
    )
}

TimezoneField.propTypes = {
    // Will be sent to the Menu
    className: PropTypes.object,
    // This is the saved timezone
    savedTimezone: PropTypes.string,
    // This is the timezone which is locally set / changed by the user
    timezoneValue: PropTypes.string,
    // Called on any change of the timezone
    onChange: PropTypes.func,
    origin: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    fieldWidth: PropTypes.integer,
    label: PropTypes.string
}

export default TimezoneField
