<template>
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
        d="M14.5 29.0323C6.49187 29.0323 0 22.5332 0 14.5161C0 6.49909 6.49187 0 14.5 0C22.5081 0 29 6.49909 29 14.5161C29 17.8882 27.8515 20.9917 25.9247 23.4561C25.9247 23.4561 28.4636 27.2451 29.6463 29.3414C29.7643 29.5506 29.5409 29.7821 29.3195 29.6889C26.1745 28.3652 20.9634 27.5139 20.9634 27.5139C19.0174 28.4856 16.8224 29.0323 14.5 29.0323Z"
        fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd"
        d="M14.5002 11.1293C15.568 11.1293 16.4336 10.2627 16.4336 9.19379C16.4336 8.12485 15.568 7.2583 14.5002 7.2583C13.4325 7.2583 12.5669 8.12485 12.5669 9.19379C12.5669 10.2627 13.4325 11.1293 14.5002 11.1293ZM16.4336 19.6706C16.4336 20.8325 15.568 21.7744 14.5002 21.7744C13.4325 21.7744 12.5669 20.8325 12.5669 19.6706V15.1685C12.5669 14.0067 13.4325 13.0648 14.5002 13.0648C15.568 13.0648 16.4336 14.0067 16.4336 15.1685V19.6706Z"
        fill="white" />
</svg>
</template>
<script>
  export default {
    props: [],
    data() {
      return {
      };
    },
  };
</script>
