<template>
    <v-menu
      v-if="options.length"
      :top="isConsole"
      :left="!isConsole"
      :offset-x="isConsole"
      v-bind="$attrs"
      v-on="$listeners"
      :content-class="`menu-options-list ${isConsole ? 'console-menu-options-list' : ''}`"
    >
      <template v-slot:activator="{ on, attrs }">
        <w-btn
          :height="btn_height"
          :width="btn_height"
          icon
          :ripple="false"
          v-bind="attrs"
          v-on="on"
          class="more-options-button input-helper-btn"
          :style="{'margin-top': mt, 'margin-bottom': '0'}"
          :disabled="disabled"
          :class="{activator_classes, 'disable-button' : disabled}"
          data-test-id="more-options-trigger"
        >
          <v-icon color="text" size="18">{{icon || '$vuetify.icons.more_vert'}}</v-icon>
        </w-btn>
      </template>
      <div
          v-for="(option, i) in options"
          @click="option.cb()"
          class="w-body-2 menu-list-item"
          :data-test-id="`more-option-item-${i}`"
          :key="`more-options-item-${i}`"
      >
          {{option.title}}
      </div>
    </v-menu>
</template>
<script>
  export default {
    props: {
        options: {
            required: true,
            type: Array,
            validator: (v) => v.every((x) => x && typeof x === 'object' && typeof x.cb === 'function' && x.title),
        },
        activator_classes: {
          type: String,
          required: false,
        },
        icon: {
          type: String,
        },
        disabled: {
          type: Boolean,
          default: false,
        }
    },
    data() {
      return {
        btn_height: 32,
      }
    },
    computed: {
      mt() {
        return `${Math.abs(48 - this.$data.btn_height) / 2}px`;
      },
      isConsole() {
        return !!document.getElementById('console')
      }
    }
  };
</script>

<style lang="scss" s>
  .disable-button {
    opacity: 0.4 !important;
  }
</style>
