import { mainTheme } from '../main-theme/MainTheme'
import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const fontFamily = 'Montserrat-medium, Helvetica, arial, sans-serif'

const signUpTheme = createMuiTheme(mainTheme, {
    appName: 'Sign Up for Phone.com',
    fontFamily,
    breakpoints: createBreakpoints({
        values: {
            xl: 1920,
            lg: 960,
            md: 768,
            sm: 300,
            xs: 0
        }
    }),
    typography: {
        fontFamily: [fontFamily],
        pdcVariants: {
            root: {
                fontFamily,
                color: '#364047',
                fontStyle: 'normal'
            },
            subtitle3: {
                fontFamily: 'Montserrat-semi-bold',
                fontWeight: 'normal',
                fontSize: '11px',
                lineHeight: '16px',
                letterSpacing: '0px'
            },
            subtitle4: {
                fontFamily: 'Montserrat-medium, Helvetica, arial, sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '11px'
            }
        },
        h4: {
            fontFamily: 'Montserrat, Helvetica, arial, sans-serif !important',
            fontSize: '30px !important',
            lineHeight: '38px !important',
            letterSpacing: '-0.3px !important'
        },
        h5: {
            fontFamily: 'Montserrat, Helvetica, arial, sans-serif !important'
        },
        h6: {
            fontFamily: 'Montserrat, Helvetica, arial, sans-serif !important'
        }
    }
})

/**
 *
 */
export { signUpTheme }
