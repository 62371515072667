import React from 'react'
import Typography from 'typography'
import { CancelIcon } from 'svg-icons/src'
import { UploadAltIcon } from 'svg-icons/src'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'

/***/
export interface SectionProps {
    classes: any
    fileName: string
    uploadedDoc: boolean
    hiddenSelectInput: any
    onFileSelect: any
    handleSelectClick: any
    removeFile: any
    titleRemoteConfigID: string
    infoTipRemoteConfigID: string
    required?: boolean
    requiredRemoteConfigID?: string
    requiredSubtextRemoteConfigID?: string
    error?: boolean
}

/***/
export const UploadDocumentBox = (props: SectionProps): JSX.Element => {
    const { classes, fileName, uploadedDoc, hiddenSelectInput, onFileSelect, handleSelectClick, removeFile, titleRemoteConfigID, infoTipRemoteConfigID, required } = props
    const errorClass = props.error ? 'error' : ''
    const uploadDocumentBoxClass = `${classes.uploadDocumentBox} ${errorClass}`

    return (
        <div className={uploadDocumentBoxClass}>
            <div className={classes.documentBoxTitle}>
                <Typography data-test-id='tw-recent-bill-title' variant='body2' classes={{ root: classes.formItemSubtitle }} remoteConfigID={titleRemoteConfigID}/>
                <RemoteInfoTipMui arrow={false} remoteConfigIDs={[infoTipRemoteConfigID]} placement={RemoteInfoTipMuiPlacement.TOP}/>
            </div>
            <div className={classes.fileOnRecord}>
                {uploadedDoc
                    ? (<>
                        <Typography data-test-id='tw-file-on-record' variant='caption' classes={{ root: classes.fileOnRecordText }} remoteConfigID='transfer_number_number_info_file_on_record'/>
                        <Typography data-test-id='tw-file-on-record' variant='caption' classes={{ root: classes.uploadedFileName }}>{fileName}</Typography>
                    </>
                    )
                    : !required
                        ? (
                            <Typography data-test-id='tw-file-on-record' variant='caption' classes={{ root: classes.fileOnRecordText }} remoteConfigID='transfer_number_number_info_no_file_on_record'/>
                        )
                        : (<>
                            <Typography data-test-id='tw-file-on-record' variant='caption' classes={{ root: classes.fileOnRecordRequiredText }} remoteConfigID={props.requiredRemoteConfigID}/>
                            <Typography data-test-id='tw-file-on-record' variant='caption' classes={{ root: classes.fileOnRecordText }} remoteConfigID={props.requiredSubtextRemoteConfigID}/>
                        </>)
                }
            </div>
            <hr style={{ width: '100%', marginTop: 'auto' }}/>
            <div className={classes.fileControlButtons}>
                <div> {/* <div className={classes.selectBillButton}> */}
                    <input
                        accept="image/jpeg,image/jpg,image/png,application/pdf"
                        className={classes.input}
                        ref={hiddenSelectInput}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={onFileSelect}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="raised-button-file">
                        <button data-test-id='tw-upload-file-button' className={classes.uploadButton} disabled={uploadedDoc} onClick={handleSelectClick}>
                            <UploadAltIcon/>
                            <Typography data-test-id='tw-upload-file-button-text' variant='buttonSmall'>Upload</Typography>
                        </button>
                    </label>
                </div>
                <div>
                    <button data-test-id='tw-remove-file-button' className={uploadedDoc ? classes.removeFileButton : `${classes.removeFileButton} disabled`} onClick={removeFile}>
                        <CancelIcon/>
                        <Typography data-test-id='tw-remove-file-button-text' variant='buttonSmall'>Remove</Typography>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UploadDocumentBox
