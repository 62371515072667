import React, { Component } from 'react'
import { AddIcon, LogoutIcon, PlayIcon, SearchIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
    pdcButton: {
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 34.5,
        borderRadius: 25,
        border: 'solid 1.5px',
        backgroundColor: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.31,
        letterSpacing: -0.1,
        textAlign: 'center',
        padding: '5px 20px 5px 20px',
        '&:focus': {
            outline: 'none'
        },
        '&.figma': {
            fontFamily: 'NTR',
            borderWidth: 2,
            borderRadius: 22,
            cursor: 'pointer',
            padding: '13px 28px 11px',
            lineHeight: '16px',
            fontWeight: 'normal',
            fontSize: 20,
            minWidth: 100,
            '-webkit-text-stroke-width': '0.3px'
        },
        '&.icon-space': {
            paddingLeft: 49
        },
        '&.graphic': {
            minWidth: 60,
            '&.addGraphicButton': {
                width: 74,
                height: 44,
                padding: '14px 29px',
                '& .pdc-button-icon': {
                    width: 23,
                    height: 23,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }
            },
            '&.playGraphicButton': {
                width: 60,
                height: 60,
                padding: '19px 19px 19px 24px',
                borderRadius: 30,
                '& .pdc-button-icon': {
                    width: 17,
                    height: 22,
                    top: '50%',
                    left: 22,
                    transform: 'translateY(-50%)'
                }
            },
            '&.primaryGraphicButton': {
                background: theme.palette.primary['-400'],
                '&:hover': {
                    background: theme.palette.primary['-300']
                },
                '& svg': {
                    color: theme.palette.primary['0']
                }
            },
            '&.secondaryGraphicButton': {
                background: theme.palette.secondary['-400'],
                '&:hover': {
                    background: theme.palette.secondary['-300']
                },
                '& svg': {
                    color: theme.palette.secondary['0']
                }
            },
            '&.tertiaryGraphicButton': {
                background: theme.palette.tertiary['-400'],
                '&:hover': {
                    background: theme.palette.tertiary['-300']
                },
                '& svg': {
                    color: theme.palette.tertiary['0']
                }
            },
            '&.disabled': {
                background: theme.palette.secondary['-400'],
                '& svg': {
                    color: `${theme.palette.secondary['-200']} !important`
                }
            }
        }
    },
    primaryButton: {
        boxShadow: '0 0 0 0 #238a12',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        }
    },

    tertiaryButton: {
        boxShadow: `0 0 0 0 ${theme.palette.tertiary.dark}`,

        borderColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.main,

        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.tertiary.main,
            color: 'white'
        }
    },
    outlinedButton: {
        '&.figma.disabled': {
            color: theme.palette.secondary['-300'],
            backgroundColor: 'white',
            borderColor: theme.palette.secondary['-300']
        }
    },
    primaryOutlinedButton: {
        '&:not(.figma):hover': {
            backgroundColor: theme.palette.tertiary.main,
            borderColor: theme.palette.tertiary.main,
            color: 'white'
        },
        '&.figma:hover': {
            borderColor: theme.palette.primary['+100'],
            color: theme.palette.primary['+100'],
            background: 'white'
        }
    },
    secondaryOutlinedButton: {
        borderColor: theme.palette.secondary['0'],
        color: theme.palette.secondary['0'],
        '&:hover': {
            borderColor: theme.palette.secondary['+100'],
            color: theme.palette.secondary['+100']
        }
    },
    attentionOutlinedButton: {
        borderColor: theme.palette.attention['0'],
        color: theme.palette.attention['0'],
        '&:hover': {
            borderColor: theme.palette.attention['+100'],
            color: theme.palette.attention['+100']
        }
    },
    importantOutlinedButton: {
        borderColor: theme.palette.important['0'],
        color: theme.palette.important['0'],
        '&:hover': {
            borderColor: theme.palette.important['+100'],
            color: theme.palette.important['+100']
        }
    },
    tertiaryOutlinedButton: {
        '&:not(.figma):hover': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: 'white'
        },
        '&.figma': {
            borderColor: theme.palette.tertiary['0'],
            color: theme.palette.tertiary['0'],
            '&:hover': {
                borderColor: theme.palette.tertiary['+100'],
                color: theme.palette.tertiary['+100'],
                background: 'white'
            }
        }
    },
    flatButton: {
        boxShadow: '0 0 0 0',
        backgroundColor: 'transparent',
        border: 'solid 0px'
    },
    primaryFlatButton: {
        backgroundColor: 'transparent',
        '&:hover': {
            color: theme.palette.tertiary.main,
            backgroundColor: 'transparent'

        }
    },
    tertiaryFlatButton: {
        backgroundColor: 'transparent',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'

        }
    },
    primaryRaisedButton: {
        boxShadow: `-2.5px 2.5px 0 0 ${theme.palette.primary.dark}`

    },
    tertiaryRaisedButton: {
        boxShadow: `-2.5px 2.5px 0 0 ${theme.palette.tertiary.dark}`
    },

    standardButton: {
        boxShadow: '0 0 0 0',
        border: 'solid 0px',
        color: 'white'
    },
    primaryStandardButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.primary.main
        }
    },
    disabled: {
        '&:not(.figma)': {
            backgroundColor: theme.palette.tertiary.xxlite,
            borderColor: theme.palette.tertiary.xxlite,
            color: 'white'
        }
    },
    newPrimaryButton: {
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.hoverButtonColor,
            borderColor: theme.palette.primary.hoverButtonColor
        }
    },
    dangerNewPrimaryButton: {
        backgroundColor: theme.modalButtons.danger.baseColor,
        borderColor: theme.modalButtons.danger.baseColor,
        '&:hover': {
            backgroundColor: theme.modalButtons.danger.hoverColor,
            borderColor: theme.modalButtons.danger.hoverColor
        }
    },
    tertiaryNewPrimaryButton: {
        backgroundColor: theme.modalButtons.cancel.baseColor,
        borderColor: theme.modalButtons.cancel.baseColor,
        '&:hover': {
            backgroundColor: theme.modalButtons.cancel.hoverColor,
            borderColor: theme.modalButtons.cancel.hoverColor
        }
    },
    filledButton: {
        borderColor: 'transparent',
        color: 'white',
        '&.disabled': {
            color: 'white',
            backgroundColor: theme.palette.secondary['-300']
        }
    },
    primaryFilledButton: {
        background: theme.palette.primary['0'],
        '&:hover': {
            background: theme.palette.primary['+100']
        }
    },
    secondaryFilledButton: {
        background: theme.palette.secondary['0'],
        '&:hover': {
            background: theme.palette.secondary['+100']
        }
    },
    tertiaryFilledButton: {
        background: theme.palette.tertiary['0'],
        '&:hover': {
            background: theme.palette.tertiary['+100']
        }
    },
    attentionFilledButton: {
        background: theme.palette.attention['0'],
        '&:hover': {
            background: theme.palette.attention['+100']
        }
    },
    importantFilledButton: {
        background: theme.palette.important['0'],
        '&:hover': {
            background: theme.palette.important['+100']
        }
    },
    icon: {
        position: 'absolute',
        left: 20,
        top: '50%',
        transform: 'translateY(-50%)',
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            maxHeight: '100%',
            maxWidth: '100%'
        }
    }
})

/**
 *
 * @deprecated - Use newer version [Button](/#button)
 *
 * This component is the My Phone.com styled button, given the color and variant it will return the properly
 * styled button.
 *
 * This component supports all props the normal <button/> component can.
 *
 * Example Uses:
 *  <PDCButton variant='raised' color='tertiary' onClick={()=>{alert('hi')}}>
 *      Click here for Alert
 *   </PDCButton>
 *
 *    <PDCButton variant='outlined' color='primary' onClick={()=>{alert('hi')} disabled={true}>
 *      Not Enabled
 *   </PDCButton>
 *
 * Defaults to variant='raised' color='primary'
 * @color [primary (green) | tertiary (grey)] the color color palette the button should use
 * @variant [raised | outlined | flat] The style of the button
 */
class PDCButton extends Component {
    getButtonClasses (color, variant) {
        const { classes, graphic } = this.props

        let buttonColorClass = ''
        if (color === 'primary') {
            buttonColorClass = classes.primaryButton
        } else if (color === 'tertiary') {
            buttonColorClass = classes.tertiaryButton
        }

        if (variant === 'raised') {
            buttonColorClass += ` ${classes[color + 'RaisedButton']}`
        } else if (variant === 'outlined') {
            buttonColorClass += ` ${classes[color + 'OutlinedButton']} ${classes.outlinedButton}`
        } else if (variant === 'flat') {
            buttonColorClass += ` ${classes[color + 'FlatButton']} + ${classes.flatButton} `
        } else if (variant === 'standard') {
            buttonColorClass += ` ${classes[color + 'StandardButton']} + ${classes.primaryButton} `
        } else if (variant === 'new-primary') {
            buttonColorClass += ` ${classes[color + 'NewPrimaryButton']} ${classes.newPrimaryButton} `
        } else if (variant === 'filled') {
            buttonColorClass += ` ${classes[color + 'FilledButton']} ${classes.filledButton} `
        }

        if (graphic) {
            const graphicClass = `graphic ${graphic}GraphicButton ${color}GraphicButton`
            buttonColorClass += ` ${graphicClass}`
        }

        return buttonColorClass
    }

    getIcon = name => {
        const { classes } = this.props
        const mappedIcons = { add: AddIcon, logout: LogoutIcon, search: SearchIcon, play: PlayIcon }
        const Icon = mappedIcons[name]
        if (!Icon) return null
        return <div className={`${classes.icon} pdc-button-icon`}><Icon/></div>
    }

    render () {
        const variant = this.props.variant || 'raised'
        const color = this.props.color || 'primary'

        const { classes } = this.props
        let buttonColorClass
        if (this.props.disabled || this.props.variant === 'disabled') {
            buttonColorClass = `${classes.disabled} disabled `
            if (this.props.figma) buttonColorClass += this.getButtonClasses(color, variant)
        } else {
            buttonColorClass = this.getButtonClasses(color, variant)
        }

        // The figma class will be needed until we remove everything else from the previous design
        const figmaClass = this.props.figma ? 'figma' : ''

        let Icon = null
        const icon = this.props.icon
        if (icon) {
            Icon = typeof (icon) === 'string' ? this.getIcon(icon) : icon
        }

        const graphic = this.props.graphic
        if (graphic) Icon = this.getIcon(graphic)

        const iconSpaceClass = Icon ? 'icon-space' : ''

        const classNames = `${classes.pdcButton} ${buttonColorClass} ${figmaClass} ${this.props.className} ${iconSpaceClass}`

        return (
            <button {...this.props} className={classNames}>
                {Icon}{this.props.children}
            </button>
        )
    }
}

PDCButton.propTypes = {
    /**
     * The variant of the button
     * 'filled' or 'outlined'
     */
    variant: PropTypes.string.isRequired,
    /**
     * The color of the button
     * 'primary', 'secondary', 'tertiary', 'attention' or 'important'
     */
    color: PropTypes.string.isRequired,
    /**
     * Icon component that will be placed at the beginning of the button.
     * If string provided then it can be 'add' or 'logout';
     * Otherwise it should be a pdc svg component.
     */
    icon: PropTypes.oneOf([PropTypes.string, 'custom']),
    /**
     * Is it disabled
     * true or false
     */
    disabled: PropTypes.bool,
    /**
     * Graphic icon
     * 'add' or 'play'
     */
    graphic: PropTypes.string,
    /**
     * Not needed, passed in by context
     */
    classes: PropTypes.object,
    children: PropTypes.node,
    figma: PropTypes.bool,
    className: PropTypes.string

}

PDCButton.defaultProps = {
    disabled: false,
    icon: null,
    graphic: null
}

export default withStyles(styles)(PDCButton)
