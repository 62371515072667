import Api from 'api'

const isElectron = window.ipcRenderer

/**
 * @param notificationType
 * @param payload
 */
const sendElectronNotification = (notificationType, payload = []) => {
    if (isElectron) {
        window.ipcRenderer.send('asynchronous-message', {
            eventName: notificationType,
            payload: payload
        })
    }
}

/**
 * @param notificationType
 * @param payload
 */
const sendElectronNotificationSync = (notificationType, payload = []) => {
    if (isElectron) {
        return window.ipcRenderer.sendSync('synchronous-message', {
            eventName: notificationType,
            payload: payload
        })
    }
}

/**
 * @param callback
 */
const electronNotificationListener = (callback = null) => {
    if (isElectron) {
        window.ipcRenderer.on('asynchronous-message', (event, res) => {
            console.log(event, res)
            switch (res.type) {
                    case 'replyNotification':
                    // TODO: handle error of message
                        Api.sendMessage(res.payload)
                        break
                    default:
                        callback(event, res)
                        break
            }
        })
    }
}

/**
 * @param type
 * @param callback
 */
const addElectronEventListener = (type, callback) => {
    if (isElectron) {
        return window.ipcRenderer.on(type, callback)
    }
}

/**
 * @param callback
 */
const isUpdateAvailable = (callback) => {
    sendElectronNotification('isUpdateAvailable')
    addElectronEventListener('isUpdateAvailable', (type, data) => {
        console.log(type, data)
        callback(data)
    })
}

/**
 *
 */
export { isElectron, sendElectronNotification, electronNotificationListener, addElectronEventListener, isUpdateAvailable, sendElectronNotificationSync }
