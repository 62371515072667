var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order.loading)?_c('w-loader'):_c('div',{staticClass:"order show-page",attrs:{"data-test-id":"orders-show-page"}},[(_vm.router_exists())?_c('BackButton'):_vm._e(),_vm._v(" "),(_vm.item)?[_c('div',{staticClass:"d-flex justify-center w-h5 mb-1"},[_vm._v("\n    "+_vm._s(_vm.$lang.t('app.order-ID', 'Order ID'))+"-"+_vm._s(_vm.item.id)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center w-caption"},[_vm._v("\n      "+_vm._s(_vm.$lang.t('orders.tracking-number', 'Tracking number'))+": \n      "),(_vm.item.tracking_number)?_c('a',{staticClass:"w-subtitle-3",attrs:{"href":("https://www.fedex.com/fedextrack/?tracknumbers=" + (_vm.item.tracking_number)),"target":"_blank"}},[_vm._v("\n        "+_vm._s(_vm.item.tracking_number)+"\n      ")]):_c('span',{staticClass:"text--text text--lighten-1"},[_vm._v(_vm._s(_vm.$lang.t('orders.available-after-shipping', 'Available after shipping')))])])]:_vm._e(),_vm._v(" "),(_vm.order.alert)?_c('w-alert',{staticClass:"mb-6",attrs:{"message":_vm.order.alert.message,"level":_vm.order.alert.level,"closable":_vm.order.enable_close_alert},on:{"closed":function($event){_vm.order.alert = null}}}):_vm._e(),_vm._v(" "),(_vm.item)?_c('div',{staticClass:"mt-9",class:{'d-flex justify-center': _vm.$vuetify.breakpoint.mdAndUp}},[_c('VerticalTable',{style:({width: _vm.$vuetify.breakpoint.mdAndUp ? '350px' : '100%', marginRight: _vm.$vuetify.breakpoint.mdAndUp ? '37px' : 0}),attrs:{"items":[
        {text: _vm.$lang.t('orders.details-contact-information', 'Details & Contact information'), title: true},
        {text: _vm.$lang.t('app.voIP-id', 'VoIP ID'), value: _vm.item.voip_id},
        {text: _vm.$lang.t('app.status', 'Status'), value: _vm.order.status_translations[_vm.item.status] ? _vm.order.status_translations[_vm.item.status] : _vm.item.status},
        {text: _vm.$lang.t('orders.shipping-method', 'Shipping method'), value: _vm.order.formatShippingMethod(_vm.item.shipping_method)},
        {text: _vm.$lang.t('orders.shipping-address', 'Shipping address'), value: _vm.order.constructor.stringifyAddress(_vm.item.contact.address)},
        {text: _vm.$lang.t('app.name', 'Name'), value: _vm.item.contact.name},
        {text: _vm.$lang.t('app.phone', 'Phone'), value: _vm.format_phone_number(_vm.item.contact.phone)},
        {text: _vm.$lang.t('app.email-address', 'Email address'), value: _vm.item.contact.primary_email ? _vm.item.contact.primary_email : _vm.primary_email},
        {text: _vm.$lang.t('orders.signature-required', 'Signature required'), value: _vm.item.signature_required ? _vm.$lang.t('app.yes', 'Yes') : _vm.$lang.t('app.no', 'No')} ]}}),_vm._v(" "),_c('VerticalTable',{style:({width: _vm.$vuetify.breakpoint.mdAndUp ? '350px' : '100%', marginTop: !_vm.$vuetify.breakpoint.mdAndUp ? '40px' : 0}),attrs:{"items":_vm.devices_items()}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }