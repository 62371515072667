// React
import React from "react";
import SelectionControl from 'selection-control';

// Stylesheet
import "./number-type-selector.css";

// Number Type Filter
class NumberTypeSelector extends React.Component {
  checked_tollfree = function() {
    var toll_free = this.props.tollfree;
    if (toll_free === true) {
      return "checked";
    }
  };

  checked_local = function() {
    var local = this.props.local;
    if (local === true) {
      return "checked";
    }
  };

  render() {
    let options = [
      {content: 'Toll-free', value: 'toll_free', checked: this.props.tollfree},
      {content: 'Local', value: 'local', checked: this.props.local}]
    let menuItems = [];
    options.map(option => {
      let checked = option.checked;
      let value = option.value;
      let content = option.content;
      menuItems.push(
        <div className="form-check form-check-inline tfn-npa-background">
                
          <div
            data-menu-item	= {value}
            value			= {value}
            key				= {value}
            
            // className		= {`${menuItemClassNames} ${selectedClass}`}
            onClick={e => this.props.handleCheckbox({target: {name: value, checked: !checked}})}
          >
            <SelectionControl
            data-menu-item	= {value}
            variant			= 'checkbox'
            checked			= {checked}
            classes = {{checkboxWrapper: 'selection-checkbox-button'}}
            name			= {`mic-${value}}`}
            value			= {`mic-${value}`}
            disableHover	= {true}
            onClick={() => {}}
          />
          </div>
          <span>{content}</span>
      </div>
        )})
    
    return (
      <div className="number-type-selector">
        {menuItems}
      </div>
    );
  }
}

export default NumberTypeSelector;
