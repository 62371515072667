import React from 'react'
import Typography from 'typography'
import RemoteInfoTip from 'remote-info-tip'

/***/
export interface SectionProps {
    classes: any
    icon: any
    remoteConfigID: string
    remoteInfoTipID?: string
    children: any
    screenViewType: any
    'data-test-id': string
}

/***/
export const Section = (props: SectionProps): JSX.Element => {
    const { classes, icon, remoteConfigID, remoteInfoTipID, children, screenViewType } = props
    const dataTestIdProp = props['data-test-id'] ? { 'data-test-id': props['data-test-id'] } : {}
    return (
        <div className={classes.section} {...dataTestIdProp}>
            <div className={`section-header ${screenViewType.isMobileView ? 'mobile' : ''}`}>
                {!screenViewType.isMobileView && icon}
                <Typography variant='h5' remoteConfigID={remoteConfigID}/>
                {remoteInfoTipID ? <RemoteInfoTip id={remoteInfoTipID}/> : null}
            </div>
            <div className={`section-content ${screenViewType.isMobileView ? 'mobile' : ''}`}>{children}</div>
        </div>
    )
}

export default Section
