<template>
  <DefaultModal v-bind="$attrs" v-on="$listeners" max-width="500">
        <div class="w-subtitle-1 mb-1">
            {{$lang.t('lr-nxt-redirection-modal-title-complete-setup', 'Complete setup by applying your script to one or more of your phone numbers.')}}
        </div>
        <APIAutocomplete
            @input="on_change"
            :return_object="true"
            class="w-100"
            mode="phone_numbers"
            data-test-id="live-answer-nxt-redirection-modal-phone-numbers-selector"
        />
        <template v-slot:buttons>
            <w-btn class="mt-3" color="secondary" @click="$emit('input', false)">
                {{$lang.t('app.i-ll-do-this-later', 'I’ll do this later')}}
            </w-btn>
            <w-btn :href="href" @click="on_click_confirm" target="_blank" :disabled="!phone_number" class="white--text mt-3" color="primary">
                {{$lang.t('app.complete-setup', 'Complete setup')}}
                <v-icon size="18" class="btn-icon-right">$vuetify.icons.open_in_new</v-icon>
            </w-btn>
        </template>
  </DefaultModal>
</template>
<script>
import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
import APIAutocomplete from '../../../console/src/components/elements/form/APIAutocomplete.vue';

export default {
    components: { APIAutocomplete, DefaultModal },
    data() {
        return {
            phone_number: null,
        };
    },
    methods: {
        on_change(data) {
            if (data && data.phone_number) {
                this.$data.phone_number = data.phone_number;
            } else {
                this.$data.phone_number = null;
            }
        },
        on_click_confirm() {
            this.$emit('input', false);
        },
    },
    computed: {
        href() {
            const url = `https://${this.is_preprod ? 'preprod.' : ''}configure.phone.com`;
            const token = this.$session.user.token.slice(7);
            return `${url}/phone-numbers/my-numbers/${this.$data.phone_number}/#access_token=${token}&token_type=Bearer`;
        },
    }
};
</script>
