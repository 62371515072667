import dateFormat from 'dateformat'

/**
 *
 * @param {number} unix_timestamp
 * @param {boolean} longFormat
 * @returns
 */
const getFormattedTime = (unix_timestamp, longFormat = false) => {
    const now = new Date()
    const myTime = new Date(unix_timestamp * 1000)
    const msdiff = now - myTime

    if (!longFormat) {
        if (msdiff < 1.08e+7) { // 3 hours
            if (msdiff < 3.6e+6) { // 1 hour
                if (msdiff < 60000) { // 1 minute
                    return 'now'
                }
                return '' + Math.floor(msdiff / 60000) + ' min ago'
            }
            return '' + Math.floor(msdiff / 3.6e+6) + ' hr ago'
        }
    } else {
        if (msdiff < 300000) { // 5 minutes
            if (msdiff < 60000) { // 1 minute
                return 'now'
            }
            return '' + Math.floor(msdiff / 60000) + ' min ago'
        }
    }

    const diffFromMonday = now.getTime() - getMonday(now).getTime()

    let format = 'h:MMTT'
    let formattedTime = ''
    if (!(now.toDateString() === myTime.toDateString())) {
        if (now.getFullYear() === myTime.getFullYear()) {
            const yesterday = new Date()
            yesterday.setDate(now.getDate() - 1)
            if (myTime.toDateString() === yesterday.toDateString()) {
                if (!longFormat) return 'YESTERDAY'
                else return 'YESTERDAY ' + dateFormat(myTime, format)
            } else if (msdiff < diffFromMonday) {
                format = longFormat ? 'dddd, mmmm d h:MMTT' : 'ddd'
            } else {
                format = longFormat ? 'mmmm d h:MMTT' : 'mmm d'
            }
        } else {
            format = longFormat ? 'mmmm d, yyyy h:MMTT' : 'yyyy'
        }
        formattedTime = dateFormat(myTime, format)
    } else {
        formattedTime = `TODAY ${dateFormat(myTime, format)}`
    }
    return formattedTime
}

/**
 *
 * @param {number} unix_timestamp
 * @returns
 */
const getFormattedTime2 = unix_timestamp => {
    const myTime = new Date(unix_timestamp * 1000)
    const format = 'mmmm d, yyyy h:MM tt'
    const formattedTime = dateFormat(myTime, format)
    return formattedTime
}

/**
 *
 * @param {number} d
 * @returns
 */
const getMonday = d => {
    const date = new Date(d)
    const day = date.getDay() || 7
    if (day !== 1) date.setHours(-24 * (day - 1))
    date.setHours(0, 0, 0, 0)
    return date
}

/**
 *
 * @param {number} unix_timestamp
 * @returns
 */
const getShortFormattedTime = (unix_timestamp) => {
    const now = new Date()
    const myTime = new Date(unix_timestamp * 1000)
    const msdiff = now - myTime

    if (msdiff < 1200000) { // 20 minutes
        if (msdiff < 60000) { // 1 minute
            return 'now'
        }
        return `${Math.floor(msdiff / 60000)} min ago`
    }

    let format = 'mmm yyyy'
    // if (msdiff < 4.32e+7) { // 12 hrs
    if (now.toDateString() === myTime.toDateString()) {
        format = 'h:MMTT'
    } else if (now.getFullYear() === myTime.getFullYear()) {
        format = 'mmm d'
    }
    return dateFormat(myTime, format)
}

/**
 * DEPRICATED, please use formatters.formatDate
 *
 * @param {number} unixTimestamp
 * @returns
 */
const getUsDateFormat = (unixTimestamp) => {
    const myTime = new Date(unixTimestamp * 1000)
    const format = 'mm/dd/yyyy'
    const formattedTime = dateFormat(myTime, format)
    return formattedTime
}

/**
 *
 */
export { getFormattedTime, getFormattedTime2, getShortFormattedTime, getUsDateFormat }
