// requires an icon, a label, and a route- <Route location, render /> or item to render.

import React from 'react'
import { makeStyles } from '@material-ui/core'
import MuiTabs, { TabsProps as MuiTabsProps } from '@material-ui/core/Tabs'
import { theme } from 'get-theme'
import { ThemeProvider } from 'theme-provider'

const useStyles = makeStyles({
    root: theme.secondaryOverrides.MuiTabs.root,
    indicator: theme.secondaryOverrides.MuiTabs.indicator
})

/**
 *
 */
export interface TabsProps extends MuiTabsProps {
    /**
     * Can use instead of wrapping jsx.
     */
    children?: React.ReactNode | any // TODO: remove any once you figure out type error
    /**
     * Theme selection
     */
    theme?: 'primary' | 'secondary'
    /**
     * css
     */
    classes: any
}

const getClasses = (propsClasses, theme: 'primary' | 'secondary') => {
    if (theme === 'primary') return propsClasses
    const formattedClasses = { ...useStyles() }
    if (propsClasses) {
        if (propsClasses.root) formattedClasses.root += ` ${propsClasses.root}`
        if (propsClasses.indicator) formattedClasses.indicator += ` ${propsClasses.indicator}`
        const otherClasses = { ...propsClasses }
        delete otherClasses.root
        delete otherClasses.indicator
        Object.assign(formattedClasses, otherClasses)
    }
    return formattedClasses
}

/***/
export const Tabs = ({
    children,
    value = false,
    theme = 'primary',
    onChange,
    classes,
    ...props
}: TabsProps): React.ReactElement => {
    // utility function used to pass theme type to all children.
    const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { theme } as any)
        }
        return child
    })
    classes = getClasses(classes, theme)
    return (
        <ThemeProvider>
            <MuiTabs
                {...props}
                value={value}
                scrollButtons='on'
                onChange={onChange}
                classes={classes}
            >
                {childrenWithProps}
            </MuiTabs>
        </ThemeProvider>
    )
}
export default Tabs
