<template>
    <DeleteDisableScriptModal
        v-bind="$attrs"
        v-on="$listeners"
        :phrase="$lang.t('app.disable-me', 'Disable me')"
        :btn_text="$lang.t('app.disable-script', 'Disable script')"
        :success_message="$lang.t('lr.successfully-disabled', 'Successfully disabled')"
    >
        <template v-slot:title>
            {{$lang.t('app.disable-script', 'Disable script')}}
        </template>
        <template v-if="$attrs.item.vendor_id && $attrs.item.vendor_id !== 4 && !['avo', 'avo_master'].includes(this.$branding.data.code)" v-slot:question>
            <br/>
            <br/>
            {{$lang.t('lr.to-enable-this-script-in-the-future-you-will-need-our-customer-support', 'If you need to enable this script in the future, you will need our customer support team to assist you. Are you sure you want to proceed with disabling this script?')}}
        </template>
    </DeleteDisableScriptModal>
</template>
<script>
import DeleteDisableScriptModal from './DeleteDisableScriptModal.vue';

export default {
    components: {
        DeleteDisableScriptModal,
    }
};
</script>
