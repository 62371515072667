export default class ValidationException extends Error {
  info = {};

  // eslint-disable-next-line class-methods-use-this
  get suppressed() {
    return true;
  }

  constructor(msg, info) {
    super(msg);
    this.info = info;
  }
}
