import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/JQacodb8V4YdmDt3Awk2zl/Numbers%E2%80%93Call-handling?node-id=3162%3A13703
 *
 * @param {SvgIconProps} props - svg props
 */
export const ForwardToANumberIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#2A9BB7'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M12.62 19.7901C14.06 22.6201 16.38 24.9301 19.21 26.3801L21.41 24.1801C21.68 23.9101 22.08 23.8201 22.43 23.9401C23.55 24.3101 24.76 24.5101 26 24.5101C26.55 24.5101 27 24.9601 27 25.5101V29.0001C27 29.5501 26.55 30.0001 26 30.0001C16.61 30.0001 9 22.3901 9 13.0001C9 12.4501 9.45 12.0001 10 12.0001H13.5C14.05 12.0001 14.5 12.4501 14.5 13.0001C14.5 14.2501 14.7 15.4501 15.07 16.5701C15.18 16.9201 15.1 17.3101 14.82 17.5901L12.62 19.7901Z' fill='white'/>
            <path d='M28.2342 15.873L30.4449 17.3642L29.4031 10.5634L22.7073 12.1451L24.9733 13.6735C22.954 16.6673 20.6562 18.1664 17.7121 18.3585C20.8794 19.6238 24.8545 19.6915 28.2342 15.873Z' fill='white'/>
        </SvgIcon>
    )
}

export default ForwardToANumberIcon
