import { mainTheme } from 'themes'
import { createMuiTheme } from '@material-ui/core/styles'
import './css/FontImport.css'
// Do NOT add values that are not in the mainTheme. It will cause problems down the line. If something is missing add it to the main object then override it here.
let configureAppTheme = JSON.parse(JSON.stringify(mainTheme))
configureAppTheme.favicon.default = '/icons/configure-favicon.ico'
configureAppTheme.favicon.unread = '/icons/configure-favicon-unread.ico'

configureAppTheme = createMuiTheme(configureAppTheme)

/** @module configure-app-theme */
/**
 * configure app theme
 */
export { configureAppTheme }
