import React from 'react'
import { avatarSingleSvg } from 'pdc-icons'
import { makeStyles } from '@material-ui/core/styles'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'

import styles, { convertWidthToEm } from './styles'

const useStyles = makeStyles(styles)

const AvatarIcon = props => {
  const classes = useStyles()

  const type			= props.type || 'available'
  const isUnavailable	= type === 'unavailable'

  const imageUrl		= props.imageUrl

  const opacity			= props.opacity || 1
  const height			= convertWidthToEm(props.height || 55)
  const width			= convertWidthToEm(props.width || 55)
  const imageStyle		= { opacity, height, width, minWidth: width, minHeight: height, maxWidth: width, maxHeight: height }
  if (!imageUrl) imageStyle.backgroundColor = 'gray'

  const fontSize = props.fontSize || 1

  const style = { ...(props.style || {}) }
  style.fontSize = fontSize

  const hiddenFileInput = React.useRef(null)

  const handleClick = () => hiddenFileInput.current.click()

  return (
    <div className={classes.container} style={style}>
      <div className={classes.avatarWrapper} style={isUnavailable ? { borderColor: '#E87D90' } : {}}>
        <div className={`${classes.imageWrapper} ${props.noBorder ? 'noBorder' : ''}`} style={isUnavailable ? { borderColor: '#DC3452' } : {}}>
          <img
            src={imageUrl || avatarSingleSvg}
            style={imageStyle}
            className={classes.image}
            alt='Avatar'
          />
        </div>
      </div>
      {props.text
        ? <div className={classes.textWrapper}>
          <span className='text'>{props.text}</span>
        </div>
			  : null}

      {props.edittable
        ? <div className={classes.editWrapper} onClick={() => handleClick()}>
          <PhotoCameraIcon classes={{ root: classes.cameraIcon }} />
          <input
            type='file'
            ref={hiddenFileInput}
            onChange={(e) => props.selectImage(e)}
            style={{ display: 'none' }}
            accept='image/*'
          />
        </div>
			  : null}
    </div>
  )
}

export default AvatarIcon
