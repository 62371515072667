import React from 'react'
import Spinner from 'spinner'
import { makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
    loaderFull: theme.loaderFull,
    spinnerText: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '21px',
        marginTop: 12

    }
})

const useStyles = makeStyles(styles)

interface Props {
    text?: string
    color?: string
    styles?
    size
    'data-test-id'?: string
}

/**
 * @param {Props} props
 */
const LoaderFull = (props: Props): JSX.Element => {
    const { text } = props
    const classes = useStyles()
    const styles = props.styles || {}
    const colorProp = props.color ? { color: props.color } : {}
    const dataTestIdProps = props['data-test-id'] ? { 'data-test-id': props['data-test-id'] } : {}
    return (
        <div {...dataTestIdProps} className={classes.loaderFull} style={styles.loaderFull}>
            <Spinner {...colorProp} size={props.size}/>
            {text ? <span className={classes.spinnerText}>{text}</span> : null}
        </div>
    )
}

export default LoaderFull
