import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import ConfirmModal from 'confirm-modal'
import RemoteConfigValue, { getValue } from 'remote-config-value'
import { defaultAddress } from './util'
import PropTypes from 'prop-types'
import { stateOptions, provinceOptions, conutryOptions } from './util'
import { TextField } from 'text-field-mui'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'

class EmergencyAddressModal extends Component {
    constructor (props) {
        super(props)
        this.state = { saving: false, address: props.address || defaultAddress }
    }

    componentDidMount () {
        const address = { ...this.state.address }
        if (address.country === null || address.country === '') {
            this.setState({ address: defaultAddress })
        }
    }

    liveFormatZip = (zip) => {
        let formatedZip = ''
        if (this.state.address.country === 'US') {
            formatedZip = zip.replace(/[^\d]/g, '')
            if (formatedZip.length === 6) return formatedZip.slice(0, -1)
            if (formatedZip.length > 6) return ''
            return formatedZip
        } else {
            zip = zip.replace(/[^a-zA-Z\d\s:]/g, '')
            let isLastNumeric = true
            Array.from(zip).forEach(karakter => {
                if (formatedZip.length === 7) return
                if (isLastNumeric) {
                    if (/^\d+$/.test(karakter)) return
                } else {
                    if (/^[a-zA-Z]+$/.test(karakter)) return
                }
                if (formatedZip.length === 3) formatedZip += ' '
                if (karakter === ' ') return
                formatedZip += karakter.toUpperCase()
                if (formatedZip.length === 3 && this.state.address.postal_code.length === 2) formatedZip += ' '
                isLastNumeric = !isLastNumeric
            })
            return formatedZip
        }
    }

    onAddressFieldChange = (fieldName, value) => {
        value = fieldName === 'postal_code' ? this.liveFormatZip(value) : value
        let address = { ...this.state.address }
        address[fieldName] = value
        if (fieldName === 'country') {
            address = defaultAddress
            address.country = value
            this.resetValidationErrors()
        }
        this.setState({ address, [`${fieldName}Error`]: '' })
    }

    checkHasChange = () => {
        const savedAddress = this.props.address
        const editedAddress = this.state.address
        const keys1 = Object.keys(savedAddress || {})
        const keys2 = Object.keys(editedAddress)
        if (keys1.length !== keys2.length) return true
        return keys1.some(k => savedAddress[k] !== editedAddress[k])
    }

    resetValidationErrors = () => {
        this.setState({ address1Error: false, cityError: false, zipError: false, state_or_provinceError: false })
    }

    validateAddress = () => {
        let isValid = true
        const address = this.state.address
        let address1Error; let cityError; let zipError = ''; let state_or_provinceError = ''
        if (address.address1 === null || address.address1.length === 0) {
            address1Error = 'Please enter a valid address'
            isValid = false
        }
        if (address.city === null || address.city.length === 0) {
            cityError = 'Please enter a valid city'
            isValid = false
        }
        if (address.state_or_province === '') {
            state_or_provinceError = address.country === 'CA' ? 'Please enter a valid provinvce' : 'Please enter a valid state'
            isValid = false
        }
        if (address.country === 'US' && (address.postal_code === null || address.postal_code.length !== 5)) {
            zipError = 'Please enter a valid zip code'
            isValid = false
        }
        if (address.country === 'CA' && (address.postal_code === null || address.postal_code.length !== 7)) {
            zipError = 'Please enter a valid postal code'
            isValid = false
        }
        this.setState({ address1Error, cityError, zipError, state_or_provinceError })
        return isValid
    }

    isPartOfStates = (states, state) => states.some(el => el.value === state)

    onConfirm = async () => {
        if (!this.validateAddress()) return
        const { address } = this.state
        this.setState({ saving: true })
        await this.props.onSave(address)
        this.setState({ saving: false })
    }

    onClose = () => {
        if (this.state.saving) return
        this.props.onClose()
    }

    renderModalContent = () => {
        const { classes } = this.props
        const address = this.state.address
        const stateItems = address.country === 'US' ? stateOptions : provinceOptions
        return (
            <div>
                <div className={classes.infoMessage} data-test-id='e911-info-graf-1'><RemoteConfigValue valueId='emergency_address_info_message_1'/></div>
                <div className={classes.infoMessage} data-test-id='e911-info-graf-2'><RemoteConfigValue valueId='emergency_address_info_message_2'/>
                    &nbsp;<RemoteInfoTipMui arrow remoteConfigIDs={['configure_phones_manage_phones_e911_test']} placement={RemoteInfoTipMuiPlacement.TOP}/></div>
                <div className={classes.infoMessage} data-test-id='e911-info-graf-3'><RemoteConfigValue valueId='emergency_address_info_message_3'/><b><RemoteConfigValue valueId='emergency_address_info_message_3_1'/></b></div>
                <div className={classes.fieldsWrapper}>
                    <div className='address-row'>
                        <TextField
                            label = 'Street address'
                            value = {address.address1}
                            onChange = {e => this.onAddressFieldChange('address1', e.target.value)}
                            onXClick = {() => this.onAddressFieldChange('address1', '')}
                            error = {this.state.address1Error}
                            helperText = {this.state.address1Error}
                            data-test-id = 'address1'
                        />
                    </div>
                    <div className='address-row'>
                        <TextField
                            label = 'Location Details (Address Line 2)'
                            value = {address.address2}
                            onChange = {e => this.onAddressFieldChange('address2', e.target.value)}
                            onXClick = {() => this.onAddressFieldChange('address2', '')}
                            data-test-id = 'address2'
                        />
                    </div>
                    <div className={`address-row ${this.props.smallView ? 'small-view' : 'address-row-2'}`}>
                        <TextField
                            label = 'City'
                            value = {address.city}
                            onChange = {e => this.onAddressFieldChange('city', e.target.value)}
                            onXClick = {() => this.onAddressFieldChange('city', '')}
                            error = {this.state.cityError}
                            helperText = {this.state.cityError}
                            data-test-id = 'city'
                        />
                        <Select
                            labelId="state_or_province-label"
                            id="state_or_province"
                            value={this.isPartOfStates(stateItems, address.state_or_province) ? address.state_or_province : stateItems[0].value }
                            label={address.country === 'US' ? 'State' : 'Province'}
                            onChange={e => this.onAddressFieldChange('state_or_province', e.target.value)}
                            error = {this.state.state_or_provinceError}
                            helperText = {this.state.state_or_provinceError}
                        >
                            {stateItems.map((state, i) => (
                                <MenuItem key={i} value={state.value}>{state.content}</MenuItem>
                            ))}

                        </Select>
                    </div>
                    <div className={`address-row ${this.props.smallView ? 'small-view' : 'address-row-3'}`}>
                        <TextField
                            label = {address.country === 'US' ? 'Zip code' : 'Postal code'}
                            value = {address.postal_code}
                            onChange = {e => this.onAddressFieldChange('postal_code', e.target.value)}
                            onXClick = {() => this.onAddressFieldChange('postal_code', '')}
                            error = {this.state.zipError}
                            helperText = {this.state.zipError}
                            data-test-id = 'postal_code'
                        />
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={address.country || conutryOptions[0].value}
                            label="Country"
                            onChange={e => this.onAddressFieldChange('country', e.target.value)}
                        >
                            {conutryOptions.map((country, i) => (
                                <MenuItem key={i} value={country.value}>{country.content}</MenuItem>
                            ))}

                        </Select>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        const modalTitle = getValue('emergency_address_modal_title')
        const hasChange = this.checkHasChange()
        return (
            <ConfirmModal
                title = {modalTitle}
                titleInfoTip = {<RemoteInfoTipMui arrow remoteConfigIDs={['configure_phones_manage_phones_e911_e911_title']} placement={RemoteInfoTipMuiPlacement.TOP}/>}
                isShown = {true}
                content = {this.renderModalContent()}
                noButtonText = 'Cancel'
                yesButtonText = 'Confirm'
                yesButtonColor = 'attention'
                confirmButtonDisabled = {!hasChange || this.state.saving}
                rejectButtonDisabled = {this.state.saving}
                onReject = {this.onClose}
                onConfirm = {this.onConfirm}
                size = {this.props.smallView ? 'sizeMobile' : 'size3'}
                showLoader = {this.state.saving}
            />
        )
    }
}

EmergencyAddressModal.propTypes = {
    // Material ui classes
    classes: PropTypes.any,
    // On modal close
    onClose: PropTypes.func,
    // Called on save
    onSave: PropTypes.func,
    // Address info
    address: PropTypes.object,
    // Is small view ?
    smallView: PropTypes.bool
}

export default withStyles(styles)(EmergencyAddressModal)
