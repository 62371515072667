<template>
  <w-btn
    v-bind="$attrs"
    v-on="$listeners"
    icon
    :height="btn_size"
    :width="btn_size"
    class="prevent-toggle"
    :class="btn_classes"
    data-test-id="download-btn"
  >
    <v-icon :class="icon_classes" :color="icon_color" :size="icon_size" class="prevent-toggle">{{icon}}</v-icon>
  </w-btn>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        default: '$vuetify.icons.download_cloud',
      },
      gray: {
        type: Boolean,
        default: false,
      },
      icon_size: {
        type: Number,
        default: 16,
      },
      btn_size: {
        type: Number,
        default: 24,
      },
      icon_color: {
        type: String,
      }
    },
    computed: {
      btn_classes() {
        if (this.$props.gray) {
          return 'secondary lighten-2';
        }

        return 'primary';
      },
      icon_classes() {
        if (this.$props.gray) {
          return 'secondary--text text--darken-1';
        }
        return 'primary--text';
      },
    },
  };
</script>
