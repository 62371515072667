<template>
  <div class="do-not-print w-h6 page-title mt-2 mb-8" :class="{'info--text text--darken-1': !no_color}" @click="$emit('click')" data-test-id="page-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    'no_color': {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {};
  },
};
</script>
<style>
  .page-title {
    letter-spacing: -0.2px !important;
  }
</style>
