import Vue from 'vue'
import Alert from '../../elements/error-components/Alert.vue'
import Loader from '../../elements/design-components/LoaderBar.vue'
import Btn from '../components/btn.vue'
import Tabs from '../components/tabs.vue'
import Form from '../components/form.vue'
import Link from '../components/link.vue'
import Switch from '../components/switch.vue'
import Select from '../components/select.vue'
import Tooltip from '../components/tooltip.vue'
import Stepper from '../components/stepper.vue'
import Combobox from '../components/combobox.vue'
import Checkbox from '../components/checkbox.vue'
import Textarea from '../components/textarea.vue'
import FileInput from '../components/file-input.vue'
import TextField from '../components/text-field.vue'
import RadioGroup from '../components/radio-group.vue'
import Autocomplete from '../components/autocomplete.vue'

Vue.component('w-btn', Btn)
Vue.component('w-tabs', Tabs)
Vue.component('w-form', Form)
Vue.component('w-alert', Alert)
Vue.component('w-link', Link)
Vue.component('w-loader', Loader)
Vue.component('w-switch', Switch)
Vue.component('w-select', Select)
Vue.component('w-tooltip', Tooltip)
Vue.component('w-stepper', Stepper)
Vue.component('w-textarea', Textarea)
Vue.component('w-checkbox', Checkbox)
Vue.component('w-combobox', Combobox)
Vue.component('w-text-field', TextField)
Vue.component('w-file-input', FileInput)
Vue.component('w-radio-group', RadioGroup)
Vue.component('w-autocomplete', Autocomplete)
