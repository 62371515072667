import React from 'react'
import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ChevronForwardIcon from 'svg-icons/src/navigation/ChevronForwardIcon'
import styles from './styles'
import Typography from 'typography'
import { Checkbox, Variant as CheckboxVariant } from 'checkbox-mui'
import { theme } from 'get-theme'
import { ThemeProvider } from 'theme-provider'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'

const useStyles = makeStyles(styles)

interface MenuItemProps extends MuiMenuItemProps {
    /**
     * Show the '>' icon on the right side;
     */
    action?: boolean | React.ReactNode
    /**
     * Show a checkbox on the left;
     */
    checkbox?: boolean
    /**
     * If checkbox is true then this shows if the checkbox is checked;
     */
    checked?: boolean
    /**
     * Show an icon on the left;
     */
    icon?: typeof React.Component | typeof JSX.Element
    /**
     * Should the menu item be disabled;
     */
    disabled?: boolean
    /**
     * If set then info tip icon will be shown on the right of the menu item
     */
    remoteInfoTipId?: string
}

/**
 * Should render a checkbox in front of the menu item
 *
 * @param {boolean} checked - is the checkbox checked
 * @param {boolean} disabled - will make it gray
 */
const renderCheckbox = (checked: boolean, disabled: boolean): JSX.Element => {
    const classNames = useStyles()
    return <ListItemIcon classes={{ root: `${classNames.listItemIcon} left-icon` }}><Checkbox variant={CheckboxVariant.PRIMARY} checked={checked} disabled={disabled}/></ListItemIcon>
}

/**
 * Should render an icon in front of the menu item
 *
 * @param {React.ReactElement} Icon - icon to be placed at the beginning
 * @param {boolean} disabled - will make it gray
 */
const renderIcon = (Icon: typeof React.Component, disabled: boolean): JSX.Element => {
    const classNames = useStyles()
    const color = disabled ? theme.palette.text.disabled : theme.palette.text.primary
    return <ListItemIcon classes={{ root: `${classNames.listItemIcon} left-icon` }}><Icon color={color} style={{ fontSize: 24, color }}/></ListItemIcon>
}

/**
 * Should render an chevron forward icon in the end of the menu item
 *
 * @param {boolean} disabled - will make it gray
 */
const renderActionIcon = (disabled: boolean): JSX.Element => {
    const classNames = useStyles()
    const color = disabled ? theme.palette.text.disabled : theme.palette.text.primary
    return <ListItemIcon classes={{ root: classNames.listItemIcon }}><ChevronForwardIcon color={color} style={{ fontSize: 26 }}/></ListItemIcon>
}

/**
 * MenuItem component
 *
 * @param {MenuItemProps} props - props
 */
export const MenuItem = (props: MenuItemProps): JSX.Element => {
    const { children, checkbox, checked, action, icon, disabled, remoteInfoTipId } = props
    const muiMenuItemProps = { ...props }
    delete muiMenuItemProps.checkbox
    delete muiMenuItemProps.action
    delete muiMenuItemProps.icon
    return (
        <ThemeProvider>
            <MuiMenuItem
                disableRipple
                {...muiMenuItemProps}
            >
                {checkbox
                    ? renderCheckbox(!!checked, disabled)
                    : icon ? renderIcon(icon, disabled) : null
                }
                <div style={{ flex: 1 }}><Typography variant='body1'>{children}</Typography></div>
                {action ? (typeof action === 'boolean' ? renderActionIcon(disabled) : action) : null}
                {remoteInfoTipId ? <RemoteInfoTipMui arrow remoteConfigIDs={[remoteInfoTipId]} placement={RemoteInfoTipMuiPlacement.TOP}/> : null}
            </MuiMenuItem>
        </ThemeProvider>
    )
}

const defaultMenuItemProps = {
    action: null,
    checkbox: null,
    checked: false,
    icon: null,
    disabled: false
}

MenuItem.defaultProps = defaultMenuItemProps

export default MenuItem
