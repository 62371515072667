import React from "react";
import SelectionControl from 'selection-control'
import { withStyles } from '@material-ui/core'

import "./npa-selector.css"

const styles = theme => ({
	menuWrapper: {
		// width:		250,
		minWidth:		70,
		display:		'flex',
		flexDirection:	'column',
		position:		'relative',
		'&.full-width': {
			width:		'100%'
		},
		'& .menu-items-wrapper': {
			position:					'absolute',
			left:						0,
			width:						'100%',
			background:					theme.palette.secondary['-500'],
			boxShadow:					'0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
			borderBottomLeftRadius:		4,
			borderBottomRightRadius:	4,
			zIndex:						999,
			padding:					'10px 0',
			'& .menu-item': {
				position:	'relative',
				display:	'flex',
				alignItems:	'center',
				fontSize:	14,
				lineHeight:	'24px',
				padding:	'9px 15px',
				cursor:		'pointer',
				'&:hover': {
					background: '#b4e8a980'
				},
				'&.selected:not(.multi-check)': {
					fontWeight: 'bold',
					'-webkit-text-stroke-width': '0.3px'
				},
				'&.multi-check': {
					paddingLeft: 54
				}
			}
		},
		'& .menu-items-wrapper-flipped': {
			position:					'absolute',
			left:						0,
			width:						'100%',
			background:					theme.palette.secondary['-500'],
			boxShadow:					'0px 3px 14px rgba(0, 0, 0, 0.12), 0px 0px 10px rgba(0, 0, 0, 0.14)',
			borderTopLeftRadius:		4,
			borderTopRightRadius:		4,
			zIndex:						999,
			padding:					'10px 0',
			'& .menu-item': {
				position:	'relative',
				display:	'flex',
				alignItems:	'center',
				fontSize:	14,
				lineHeight:	'24px',
				padding:	'9px 15px',
				cursor:		'pointer',
				'&:hover': {
					background: '#b4e8a980'
				},
				'&.selected:not(.multi-check)': {
					fontWeight: 'bold',
					'-webkit-text-stroke-width': '0.3px'
				},
				'&.multi-check': {
					paddingLeft: 54
				}
			}
		}
	},
	expandIcon: {
		position:	'absolute',
		right:		14,
		top:		'50%',
		transform:	'translateY(-50%)',
		width:		30,
		height:		24
	},
	xIcon: {
		width:		14,
		height:		14,
		position:	'absolute',
		right:		15,
		top:		'50%',
		transform:	'translateY(-50%)',
		color:		theme.palette.primary['+100']
	},
	input: {
		border:		'none',
		outline:	'none',
		background:	'transparent',
		cursor:		'pointer',
		'&.active': {
			background:	theme.palette.primary['-500'],
			cursor:		'text'
		}
	},
	checkbox: {
		marginRight:	13,
		padding:		0,
		position:		'absolute',
		left:			13,
		top:			'50%',
		transform:		'translateY(-50%)'
	},
	calculationDiv: {
		position:		'fixed',
		left:			'-9999%',
		top:			'-9999%',
		pointerEvents:	'none',
		display:		'flex',
		'& > span:not(:last-child)': {
			marginRight: 5
		}
	}
})

class NpaSelector extends React.Component {

  render() {
    const { classes } = this.props
    const npas = [];
    let menuItemClassNames			= `menu-item`
    for (const [index, value] of this.props.availableNpas.entries()) {
      let checked = this.props.selectedNpas.includes(value);
      let selectedClass	= checked ? 'selected' : ''
      npas.push(
        <li key={index} onClick={() => this.props.handleCheckbox(value, checked)}>
          <div className="form-check form-check-inline tfn-npa-background">
									<div
										data-menu-item	= {value}
										value			= {value}
                    key				= {index}
                    
										// className		= {`${menuItemClassNames} ${selectedClass}`}
                    onClick={e => this.props.handleCheckbox(e)}
									>
                    <SelectionControl
                    data-menu-item	= {value}
                    classes = {{checkboxWrapper: 'selection-checkbox-button'}}
                    variant			= 'checkbox'
                    checked			= {checked}
                    name			= {`mic-${index}}`}
                    value			= {`mic-${value}`}
                    disableHover	= {true}
                    onClick={() => {}}
                  />
                  </div>
                  {value}

          </div>
        </li>
      );
    }

    return (
        <ul className="list-unstyled tfn-npa-selector">
          {npas}
        </ul>
    );
  }

}

export default withStyles(styles)(NpaSelector);
