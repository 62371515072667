import React, { Component } from 'react'
import { UpdateData } from 'step-wizard/src/StepWizard'
import TableMui from 'pdc-table-mui'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import { PassData } from './CompanySetup'
import { TextField } from 'text-field-mui'
import Api from 'api'
import Prompt from 'pdc-prompt'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import Tooltip from '@material-ui/core/Tooltip'
import RemoteConfigValue from 'remote-config-value'
import EditExtensionField from 'edit-extension-field'
/***/
export interface UserSetupData {
    someData: any
}

interface Props {
    saveId: number | string
    update: (data: UpdateData) => void
    classes
    smallView: boolean
    passData: PassData
}

interface State {
    users,
    originalUsers
    pdcPromptOpen
    pdcPromptMessage
    isLoading
    extensionSaving
    pdcPromptColor
}

class UserSetupStep extends Component<Props, State> {
    constructor (props) {
        super(props)
        this.state = {
            users: this.props.passData.extraData.users.sort((e1, e2) => e1.displayName() < e2.displayName() ? -1 : 1).sort(u => u.is_admin ? -1 : 1),
            originalUsers: [],
            pdcPromptOpen: false,
            pdcPromptMessage: null,
            pdcPromptColor: 'tertiary',
            isLoading: false,
            extensionSaving: false
        }
    }

    componentDidMount = () => {
        const copyUsers = JSON.parse(JSON.stringify([...this.state.users]))
        this.setState({ originalUsers: copyUsers })
    }

    componentDidUpdate = (prevProps: Props): void => {
        const hasSaveRequest: boolean = this.props.saveId && (prevProps.saveId !== this.props.saveId) && !this.state.isLoading && !this.state.extensionSaving
        if (hasSaveRequest) this.save()
    }

    save = async () => {
        if (!(this.validateUsers())) {
            return
        }
        this.setState({ isLoading: true })
        const changedUsers = await this.getUserForUpdate()
        if (await this.updateUsers(changedUsers)) {
            this.props.update({ completed: true, gotoNext: true, saveData: null })
        }
    }

    updateUsers = async (users) => {
        if (users.length === 0) return true
        const response = await Api.updateUsers(users)
        if (
            response.error &&
            response.error.message &&
            response.error.message.toLowerCase().includes('unique')
        ) {
            if (response.error.message) {
                this.state.users.forEach((u) => {
                    if (u.email !== '' && response.error.message.includes(u.email)) {
                        this.changeValueInUser('email_error', u, 'Email is already in use.')
                    }
                })
            }
            this.setState({ isLoading: false })
            return false
        } else if (response.error) {
            this.setState({ isLoading: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while configuring users.' })
            return false
        }

        await Promise.all(users.map(u1 => {
            const user = this.state.users.find(u2 => u2.id === u1.id)
            if (!user) return null
            user.status = u1.status
            const orgUser = this.state.originalUsers.find(orgUser => orgUser.id === user.id)
            if (orgUser && (orgUser.first_name !== user.first_name || orgUser.last_name !== user.last_name)) {
                const orgName = `${orgUser.first_name} ${orgUser.last_name}`.trim()
                const newName = `${user.first_name} ${user.last_name}`.trim()
                return Api.updateAsignedNumberNames(orgName, newName, user.extension.id)
            }
            return null
        }))

        return true
    }

    validateUsers = () => {
        let isValid = true
        this.state.users.forEach((user) => {
            isValid = !this.validateFirstName(user) ? false : isValid
            isValid = !this.validateLastName(user) ? false : isValid
            isValid = !this.validateEmailForUser(user) ? false : isValid
        })
        if (!isValid) setTimeout(this.scrollToErrorElement, 10)
        return isValid
    }

    scrollToErrorElement = () => {
        const errorElement = document.getElementsByClassName('error')[0]
        const muiErrorElement = document.getElementsByClassName('Mui-error')[0]
        if (errorElement) errorElement.scrollIntoView()
        else if (muiErrorElement) muiErrorElement?.scrollIntoView()
    }

    validateFirstName = user => {
        if (user.first_name.length === 0) {
            const error = 'Please enter a First Name.'
            this.changeValueInUser('first_name_error', user, error)
            return false
        }
        return true
    }

    validateLastName = user => {
        if (user.last_name.length === 0) {
            const error = 'Please enter a Last Name.'
            this.changeValueInUser('last_name_error', user, error)
            return false
        }
        return true
    }

    validateEmailForUser = user => {
        if (user.email === '') {
            return true
        }
        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(String(user.email).toLowerCase())) {
            const error = 'Not a valid email.'
            this.changeValueInUser('email_error', user, error)
            return false
        }
        const tempEmails = this.state.users.map((u) => { return u.id !== user.id ? u.email : null })
        if (tempEmails.includes(user.email)) {
            this.changeValueInUser('email_error', user, 'Duplicate email.')
            return false
        }
        return true
    }

    getUserForUpdate = () => {
        const users = JSON.parse(JSON.stringify([...this.state.users]))
        const orgUsers = this.state.originalUsers
        const usersForUpdate = []; let i; let item; let j; let len
        if (JSON.stringify(orgUsers) === JSON.stringify(users)) {
            return usersForUpdate
        }
        for (i = j = 0, len = users.length; j < len; i = ++j) {
            item = users[i]
            if (JSON.stringify(item) !== JSON.stringify(orgUsers[i])) {
                usersForUpdate.push(item)
            }
        }

        // formatForUpdateRequest
        usersForUpdate.forEach((u) => {
            delete u.firstNameError
            delete u.lastNameError
            delete u.emailError
            delete u.devices
            delete u.extension
            delete u.numbers
            delete u.plan
            delete u.timezone
            delete u.voicemail_password
            delete u.auth_center_id
            u.first_name = u.first_name.trim()
            u.last_name = u.last_name.trim()
            u.email = u.email.trim()
            if (!u.email) delete u.email
            if (u.email && u.status === 'new') {
                u.status = 'invite_pending'
            }
        })

        return usersForUpdate
    }

    updateUserExtension = async (user, extension) => {
        let updatedExtension = null
        try {
            this.setState({ extensionSaving: true })
            updatedExtension = await Api.setVoipPhoneExtension(user.extension.id, extension)
            user.extension.number = updatedExtension
            this.changeValueInUser('extension', user, user.extension)
        } catch (err) {
            console.log('###', err)
            setTimeout(() => { this.setState({ pdcPromptOpen: true, pdcPromptMessage: err.message, pdcPromptColor: 'red' }) }, 100)
            // setTimeout(() => { this.handlepdcPromptClose() }, 5000)
        }
        this.setState({ extensionSaving: false })
        return updatedExtension
    }

    generateTableHeadColumns = () => {
        const headColumns = [
            { content: 'First name', testId: 'lfirst-name-col' },
            { content: 'Last Name', testId: 'last-name-col' },
            { content: 'Email Address', testId: 'email-col' },
            { content: 'Plan', testId: 'plan-col' },
            { content: 'Extension', testId: 'extension-col' }
        ]
        return headColumns
    }

    generateTableRows = () => {
        const rows = this.state.users.map(user => {
            return this.generateOneRow(user)
        })
        return rows
    }

    generateOneRow = (user) => {
        const columns = [
            { key: 0, content: this.renderInlineField('first_name', user), primary: true, stringValue: `${user.first_name} ${user.last_name}` },
            { key: 1, content: this.renderInlineField('last_name', user) },
            { key: 2, content: this.renderInlineField('email', user), secondary: true, stringValue: user.email },
            { key: 3, content: this.renderPlanField(user) },
            { key: 4, content: this.renderExtensionField(user) }

        ]
        return { key: user.id, columns }
    }

    renderPlanField = user => {
        return <div className='column-div'>{user.plan.description}</div>
    }

    renderExtensionField = user => {
        const { classes } = this.props
        const userExtension = (user.extension?.number || '').toString()
        return (
            <div className={classes.extensionColumn}>
                <EditExtensionField
                    value = {userExtension}
                    onChange = {async (extension) => await this.updateUserExtension(user, extension) }
                />
            </div>
        )
    }

    changeValueInUser = (field: string, user, value: string) => {
        const users = [...this.state.users]
        if (field === 'email') {
            users.forEach((u) => {
                if (u.email === user.email) {
                    delete u.email_error
                }
            })
        }

        if (['first_name', 'last_name', 'email'].includes(field)) {
            value = value.trimStart()
            delete user[field + '_error'] // reset validation
        }
        user[field] = value
        this.setState(users)
    }

    renderInlineField = (field: string, user): JSX.Element => {
        const { classes } = this.props
        let label = field.split('_').join(' ')
        label = `${label[0].toUpperCase()}${label.substring(1)}`
        const wrapperClass = `${field.split('_').join('')}Wrapper`
        const tooltipText = user.status === 'invite_pending' && field === 'email' ? <RemoteConfigValue valueId={'csw_user_step_invited_email_tooltip'}/> : ''

        return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipText} placement='top'>
                <div className={wrapperClass}>
                    <TextField
                        label = {label}
                        value = {user[field]}
                        onChange = {(e) => this.changeValueInUser(field, user, e.target.value)}
                        onXClick = {() => this.changeValueInUser(field, user, '')}
                        disabled = {((user.status === 'active' || user.status === 'invite_pending') && field === 'email') || this.state.isLoading}
                        noLabelOnActiveOrResolved
                        error={user[field + '_error']}
                        helperText={user[field + '_error']}
                    />
                </div>
            </Tooltip>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull data-test-id='csw-spinner-icon' text='Please wait...' color={theme.palette.secondary.main} size='bigger'/>
            </div>
        )
    }

    render = (): JSX.Element => {
        const { classes, smallView } = this.props
        return (
            <div className={`${classes.userSetup} ${smallView ? 'mobile' : ''}`}>
                {this.state.isLoading ? this.renderLoader() : null}
                <TableMui
                    headColumns = {this.generateTableHeadColumns()}
                    rows = {this.generateTableRows()}
                    infiniteScroller = {false}
                    reverseScroll = {false}
                    listView = {smallView}
                    dataAttr = {'users-table'}
                    tdClasses = {classes.userStepTableTd}
                />
                <Prompt
                    promptProps={{ 'data-prompt': 'users-step' }}
                    isOpen={this.state.pdcPromptOpen}
                    color={this.state.pdcPromptColor}
                    position={'bottom'}
                    onClose={() => { this.setState({ pdcPromptOpen: false, pdcPromptMessage: null, pdcPromptColor: 'tertiary' }) }}
                    content={this.state.pdcPromptMessage}
                />
            </div>
        )
    }
}

export default withStyles(styles)(UserSetupStep)
