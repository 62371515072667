<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M3.30005 16.5827L11 20.1663V10.9998L3.30005 7.22534V16.5827ZM11 10.9999L18.6999 7.22541V16.5828L11 20.1664V10.9999Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M11 3.45088L18.7 7.22535L11 10.9998L14.3 12.6174L22 8.84298L18.7 7.22535L22 5.60772L14.3 1.83325L11 3.45088ZM0 5.60781L7.69998 1.83334L11 3.45097L3.29999 7.22544L0 5.60781ZM7.69998 12.6178L0 8.84332L3.29999 7.22569L11 11.0002L7.69998 12.6178Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {};
</script>
