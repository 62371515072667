import { mainTheme } from 'themes'
import { createMuiTheme } from '@material-ui/core/styles'
import './css/FontImport.css'
// Do NOT add values that are not in the mainTheme. It will cause problems down the line. If something is missing add it to the main object then override it here.
let myPdcTheme = JSON.parse(JSON.stringify(mainTheme))
myPdcTheme.favicon.default = '/icons/my-pdc-favicon.ico'
myPdcTheme.favicon.unread = '/icons/my-pdc-favicon-unread.ico'

myPdcTheme = createMuiTheme(myPdcTheme)

/** @module my-pdc-theme */
/**
 * my pdc theme
 */
export { myPdcTheme }
