import { combineReducers } from 'redux'
import { settings, voicemail, holdMusic } from './general-settings'
import { currentTab } from './company'
import { smallView } from './view'

export default combineReducers({
    settings,
    voicemail,
    holdMusic,
    currentTab,
    smallView
})
