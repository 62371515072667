import React, { Component } from 'react'
import { Checkbox, Variant as CheckboxVariant } from 'checkbox-mui'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styles from './styles'
import Typography from 'typography'
import { SoftAlertIcon } from 'svg-icons'
import { formatPhoneNumber } from 'phone-numbers'

class LOAStep extends Component {
    constructor (props) {
        super(props)
        const passData = this.props.passData.LOA
        this.state = {
            iAccept: passData?.iAccept || false,
            acceptError: false
        }
    }

    componentDidUpdate = (prevProps) => {
        const hasSaveRequest = this.props.saveId && (prevProps.saveId !== this.props.saveId)
        if (hasSaveRequest) {
            if (!this.state.iAccept && this.props.saveId.split('#')[1] === 'submit') {
                this.setState({ acceptError: 'Please accept the EA before continuing.' })
                setTimeout(this.scrollToErrorElement, 10)
            } else {
                this.props.update({
                    gotoNext: true,
                    saveData: { iAccept: this.state.iAccept }
                })
            }
        }
    }

    scrollToErrorElement = () => {
        const errorElement = document.getElementsByClassName('accept-error-wrapper')[0]
        if (errorElement) errorElement.scrollIntoView()
    }

    setIAccept = (value) => {
        this.setState({ iAccept: value, acceptError: false })
    }

    renderTopContentBox = (title, content, titleTestId, contentTestId) => {
        const { classes } = this.props
        return (
            <div className={classes.loaTopContentBox}>
                <Typography data-test-id={titleTestId} variant='subtitle2'>{title}</Typography>
                <Typography data-test-id={contentTestId} variant='body2'>{content}</Typography>
            </div>
        )
    }

    renderPhoneNumber = (number, index, last) => {
        return (
            <span key={index}>{formatPhoneNumber(number)}{last ? '' : ', '}</span>
        )
    }

    renderTopContent = () => {
        const { classes } = this.props
        const { providerName } = this.props.passData.numberInfo || {}
        const phoneNumbers = [...this.props.passData?.numberInfo?.phoneNumbers || []]
        const { isBusinessNumber, companyName, firstName, lastName, address, address2, city, state, zip } = this.props.passData.accountInfo || {}
        const companyNameBox = this.renderTopContentBox('Company name', companyName, 'tw-loa-company-name', 'tw-loa-company-name-value')
        const authPersonBox = this.renderTopContentBox('Authorized person', `${firstName} ${lastName}`, 'tw-loa-authorized-person', 'tw-loa-authorized-person-value')
        const addressBox = this.renderTopContentBox('Service address', `${address}${address2 ? ' ' + address2 : ''} ${city}, ${state} ${zip}`, 'tw-loa-service-address', 'tw-loa-service-address-value')
        const providerBox = this.renderTopContentBox('Service provider', providerName, 'tw-loa-service-provider', 'tw-loa-service-provider-value')
        const transferNumbersBox = this.renderTopContentBox('Number(s) to transfer', phoneNumbers.map((number, i) => (this.renderPhoneNumber(number, i, i + 1 === phoneNumbers.length))), 'tw-loa-number-to-transfer', 'tw-loa-number-to-transfer-value')
        const emptyBox = this.renderTopContentBox('', '', '', '')
        const boxItems = isBusinessNumber
            ? [companyNameBox, authPersonBox, addressBox, providerBox, transferNumbersBox, emptyBox]
            : [providerBox, transferNumbersBox, authPersonBox, addressBox, emptyBox]
        return (
            <div className={`${classes.loaTopContent}${this.props.smallView ? ' small' : ''}`}>
                <div className={`loa-top-row${this.props.smallView ? ' small' : ''}`}>
                    {boxItems[0]}
                    {boxItems[1]}
                    {boxItems[2]}
                </div>
                <div className={`loa-top-row${this.props.smallView ? ' small' : ''}`}>
                    {boxItems[3]}
                    {boxItems[4]}
                    {boxItems[5]}
                </div>
            </div>
        )
    }

    renderTextContent = () => {
        const { classes } = this.props
        return (
            <div className={classes.loaTextContent}>
                <div className='text-content-row'>
                    <Typography variant='body2'>
                        I authorize Phone.com to act on my behalf to transfer the above telephone number to Phone.com. I understand that I can have only one telecommunications provider for any one telephone number, and that by transferring the number to Phone.com, my existing service on that phone number will be affected. This authorization shall remain in effect until I provide further written notice.
                    </Typography>
                </div>
                <div className='text-content-row'>
                    <Typography variant='body2'>
                        I assert that the information I have provided on this form is true and accurate to the best of my knowledge. I acknowledge that any attempt to port numbers for which I am not authorized constitutes <strong>criminal identity theft</strong> and is punishable by law.
                    </Typography>
                </div>
                <div className='text-content-row'>
                    <Typography variant='body2'>
                        I understand that <strong>the standard duration of a transfer request is 10-15 days, but could take longer due to rejections and delays in providing the required information,</strong> and I agree to keep my account in good standing with my current telecommunications provider, during this transfer period. I understand that I will receive a temporary number from Phone.com during the transfer period, and I agree to pay all standard monthly, usage, and recovery fees, even though my phone number has not yet been transferred.
                    </Typography>
                </div>
                <div className='text-content-row'>
                    <Typography variant='body2'>
                        I understand that my current telecommunications provider may or may not cancel my existing wireless or wireline service on which this phone number is associated, depending on their policies.
                    </Typography>
                </div>
                <div className='text-content-row'>
                    <Typography variant='body2'>
                        Once I have verified that the transfer is complete, and my number is routing to Phone.com, I agree to contact my current telecommunications provider to confirm their cancellation of my service. I understand that I am responsible for any cancellation, termination, or transfer fees that my current telecommunications provider may charge me.
                    </Typography>
                </div>
                <div className='text-content-row'>
                    <Typography variant='body2'>
                        I understand that if I wish to return this telephone number to my current telecommunications provider, I may be required to pay a reconnection charge to that company.
                    </Typography>
                </div>
                <div className='text-content-row'>
                    <Typography variant='body2'>
                        I also understand that Phone.com may have different rates and charges than my current telephone company, and I am willing to be billed accordingly.
                    </Typography>
                </div>
                <div className='text-content-row'>
                    <Typography variant='body2'>
                        By checking the box below, and submitting this form, I grant electronic authorization for Phone.com to act as my agent to complete this change, and I direct my current telecommunications provider to work with Phone.com to effect this change. I specifically grant Phone.com authority to sign the Electronic Authorization (EA) on my behalf.
                    </Typography>
                </div>
            </div>
        )
    }

    renderAcceptError = () => this.state.acceptError ? <span data-test-id='tw-loa-acceptance-validation-error' className='accept-error-wrapper'><SoftAlertIcon/>{this.state.acceptError}</span> : null

    render () {
        const variant = CheckboxVariant.PRIMARY
        const { classes } = this.props
        // const accountInfo = this.props.passData.accountInfo
        // const numberInfo = this.props.passData.numberInfo
        return (
            <div className={`${classes.loaWrapper}${this.props.smallView ? 'mobile' : ''}`}>
                {this.renderTopContent()}
                {this.renderTextContent()}
                <div className={classes.notificationItem}>
                    <Checkbox
                        data-test-id={`${this.state.iAccept ? 'tw-loa-accept-checked' : 'tw-loa-accept-unchecked'}`}
                        checked={this.state.iAccept}
                        onChange={() => this.setIAccept(!this.state.iAccept)}
                        variant={variant}
                        label='I Accept'
                        labelDataTestId='tw-loa-accept-check-label'
                    />
                    {this.renderAcceptError()}
                </div>
            </div>
        )
    }
}

LOAStep.propTypes = {
    classes: PropTypes.object,
    iAccept: PropTypes.bool,
    name: PropTypes.string,
    passData: PropTypes.object,
    saveData: PropTypes.object,
    saveId: PropTypes.string,
    smallView: PropTypes.string,
    update: PropTypes.func
}

export default withStyles(styles)(LOAStep)
