import gtmDataPush from 'gtm-events'
import HelpPopup from './HelpPopup'
import Navigation from 'pdc-navigation'
import PropTypes from 'prop-types'
import { getFeatureEnabled } from 'feature-flag'
import React, { Component } from 'react'
import { ThemeContext } from 'providers'

import {
    UsersIcon as UsersNavIcon,
    NumbersIcon as PhoneNumbersNavIcon,
    PhonesIcon as PhonesNavIcon,
    CompanyIcon as CompanyNavIcon,
    AccountIcon as AccountNavIcon,
    AdvancedSettingsIcon as AdvancedSettingsNavIcon,
    MyPdcIcon as MyPdcNavIcon,
    SupportIcon as HelpNavIcon
} from 'svg-icons'

import { withStyles } from '@material-ui/core'

const appTitles = {
    company: 'Company',
    users: 'Users',
    phoneNumbers: 'Numbers',
    phones: 'Phones',
    account: 'Account',
    helpModal: 'Help'
}

const styles = theme => ({
    header: {
        fontFamily: 'Montserrat-medium !important',
        fontSize: '10px',
        fontWeight: '600',
        lineHeight: '12px',
        padding: '15px 15px',
        letterSpacing: '0.4px',
        textTransform: 'uppercase'
    }
})

class CNavigation extends Component {
    static contextType = ThemeContext
    constructor (props) {
        super(props)
        this.state = {
            mobileOpen: false,
            appBarLabel: this.getInitialAppBarLabel(),
            showHelp: false,
            hideAdvancedSettings: false
        }

        this.helpSectionRef = React.createRef()
    }

    componentDidMount () {
        getFeatureEnabled('configure.hide_advanced_settings').then(res => this.setState({ hideAdvancedSettings: res }))
    }

    componentDidUpdate () {
        this.resetAppBarLabel()
    }

    resetAppBarLabel = () => {
        const selectedAppBarLabel = this.getInitialAppBarLabel()
        if (this.state.appBarLabel !== selectedAppBarLabel) this.setState({ appBarLabel: selectedAppBarLabel })
    }

    getInitialAppBarLabel = () => {
        const routerConfig = this.getTabs()
        // eslint-disable-next-line
        for (const index in routerConfig) {
            const route = routerConfig[index]
            if (window.location.pathname.indexOf(route.link) !== -1) {
                return route.text
            }
        }
        return 'Phone Numbers'
    }

    getTabs = () => {
        // if this.state not undefined, console log
        const companyTab = {
            link: '/company',
            icon: CompanyNavIcon,
            text: appTitles.company,
            enabled: true,
            active: this.props.currentAppName === 'company',
            testId: 'company-tab'
        }

        const usersTab = {
            testId: 'users-tab',
            link: '/users',
            icon: UsersNavIcon,
            text: appTitles.users,
            enabled: true,
            active: this.props.currentAppName === 'users'
        }

        const phoneNumbersTab = {
            testId: 'phone-numbers-tab',
            link: '/phone-numbers',
            icon: PhoneNumbersNavIcon,
            text: appTitles.phoneNumbers,
            enabled: true,
            unread: (this.props.unreadPorting === '1') ? '!' : '', // prop of whether user has port request
            active: this.props.currentAppName === 'phone-numbers'
        }

        const phonesTab = {
            testId: 'phones-tab',
            link: '/phones',
            icon: PhonesNavIcon,
            text: appTitles.phones,
            enabled: !!process.env.REACT_APP_ENABLE_PHONES_TAB,
            active: this.props.currentAppName === 'phones',
            unread: this.props.incompleteE911Count
        }

        const accountTab = {
            testId: 'account-tab',
            link: '/account',
            icon: AccountNavIcon,
            text: appTitles.account,
            enabled: true,
            active: this.props.currentAppName === 'account'
        }

        return [
            usersTab,
            phoneNumbersTab,
            phonesTab,
            companyTab,
            accountTab
        ]
    }

    onMenuChange = route => {
        if (!this.checkPopout(route)) this.setState({ appBarLabel: route.text })

        // SPA support for userpilot
        // https://docs.userpilot.com/article/59-installation-guide-for-single-page-applications-spas
        if (window.userpilot) {
            window.userpilot.reload()
        }

        // analytics
        gtmDataPush({
            event: 'PDC_Category_Change',
            PDC_Action: route.text.toLowerCase()
        })
    }

    onHelpClick = (supportTabLink) => {
        if (supportTabLink !== 'https://www.phone.com/support/') {
            window.open(supportTabLink, '_blank')
        } else {
            this.setState({ showHelp: !this.state.showHelp })
            if (this.state.showHelp) {
                this.handleDrawerToggle()
            }
        }
    }

    toggleHelp = () => {
        this.setState({ showHelp: !this.state.showHelp })
        if (this.state.showHelp) {
            this.handleDrawerToggle()
        }
    }

    checkPopout = route => {
        if (route.link.indexOf('https') !== -1) {
            window.open(route.link, route.text)
            return true
        }
        return false
    }

    handleDrawerToggle = () => {
        const { isMobileView, isTabletView } = this.props.screenViewType
        if (isMobileView || isTabletView) this.props.toggleShowNavigation(!this.props.navigationShow) // this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    extensionSelectHandler = data => this.props.extensionSelectHandler(data)

    renderNavHeader = () => {
        const { classes } = this.props
        return (
            <div className={classes.header} style={{ color: false }}>Configure</div>

        )
    }

    render () {
        const theme = this.context

        const accessToken = window?.V5PHONECOM?.cp_token

        let advancedSettingsLink = theme.advancedSettingsLink
        if (advancedSettingsLink && advancedSettingsLink.includes('console')) {
            advancedSettingsLink = `${advancedSettingsLink}#access_token=${accessToken}&token_type=Bearer`
        }
        const supportTabLink = theme.supportTabLink
        const showPoweredByFooter = theme.showPoweredByFooter

        const myPhoneComLink = new URL(process.env.REACT_APP_SETTINGS_BASE_URL)
        myPhoneComLink.hash = `access_token=${accessToken}&token_type=Bearer`

        const links = [
            ...this.state.hideAdvancedSettings
                ? []
                : [
                    {
                        address: advancedSettingsLink,
                        icon: AdvancedSettingsNavIcon,
                        text: theme.advancedSettingsText,
                        testId: 'advanced-settings'
                    }
                ],
            {
                address: myPhoneComLink.toString(),
                icon: MyPdcNavIcon,
                text: 'My.phone.com',
                testId: 'my-phone-com'
            },
            {
                onClick: () => this.onHelpClick(supportTabLink),
                icon: HelpNavIcon,
                text: 'Help',
                testId: 'help-button',
                ref: this.helpSectionRef
            }
        ]

        return (
            <>
                <Navigation
                    screenViewType = {this.props.screenViewType}
                    appBarLabel = {this.state.appBarLabel}
                    mobileOpen = {this.props.navigationShow} // this.state.mobileOpen
                    showAvatar = {true}
                    links = {links}
                    appHasChange = {this.props.appHasChange}
                    tabs = {this.getTabs()}
                    renderNavHeader = {this.renderNavHeader}
                    poweredByFooter = {showPoweredByFooter}
                    onMenuChange = {this.onMenuChange}
                    handleDrawerToggle = {this.handleDrawerToggle}
                    discardChanges = {this.props.discardChanges}
                    hideAppBar={true}
                >{this.props.children}</Navigation>

                <HelpPopup
                    onClose = {this.toggleHelp}
                    display = {this.state.showHelp}
                    anchorRef = {this.helpSectionRef}
                    extension = {this.props.extension}
                />
            </>
        )
    }
}

CNavigation.propTypes = {
    currentAppName: PropTypes.string,
    unreadPorting: PropTypes.string,
    hasActiveDevices: PropTypes.bool,
    incompleteE911Count: PropTypes.number,
    classes: PropTypes.object,
    screenViewType: PropTypes.func,
    extensionSelectHandler: PropTypes.func,
    userInfo: PropTypes.object,
    companyName: PropTypes.string,
    container: PropTypes.any,
    appHasChange: PropTypes.bool,
    discardChanges: PropTypes.func,
    logout: PropTypes.func,
    extension: PropTypes.string,
    children: PropTypes.any,
    navigationShow: PropTypes.bool,
    toggleShowNavigation: PropTypes.func
}

export default withStyles(styles)(CNavigation)
