export function users (state = [], action) {
  let userIndex = -1
  switch (action.type) {
    case 'SET_USERS':
      return action.users.slice()
    case 'UPDATE_USER':
      userIndex = state.findIndex(u => u.id === action.user.id)
      if (userIndex === -1) return state
      return [
        ...state.slice(0, userIndex),
        {
          ...action.user,
          direct_number:	action.user.direct_number ? { ...action.user.direct_number } : null,
          extension:	action.user.extension ? { ...action.user.extension } : null,
          user_plan:	action.user.user_plan ? { ...action.user.user_plan } : null,
          meeting_plan:	action.user.meeting_plan ? { ...action.user.meeting_plan } : null,
          device:	action.user.device ? { ...action.user.device } : null
        },
        ...state.slice(userIndex + 1)
      ]
    default:
      return state
  }
}

