import React from "react";
import successIcon from './success-icon.svg';
import "./reservation-notification.css";

export default function ReservationNotification(props) {
    const fadeClass = props.visibilityStatus == 2  ? 'fade-out' : ''
    return (
        <div className={`reservation-notification-wrapper ${fadeClass}`}>
            <div data-test-id='reserve-notification' className='spinner'>
                <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} src={successIcon}/>
            </div>
            <div data-test-id='reserve-notification-message' className='reservation-notification-message'>
                <span>We reserved</span>
                <span style={{fontWeight: "bolder", margin: '0px 3px'}} dangerouslySetInnerHTML={{__html: props.number}}/>
                <span>for 60 minutes</span>
            </div>
        </div>
    )
}
