import Cachier from 'cachier'

const language_cache_key = 'console-language'
/* eslint-disable */
export default new class {

  get default_lang () {
    return 'en';
  }

  get available () {
    return {
      'en': 'en',
      // 'he': 'he',
      // 'ru': 'ru',
      'es': 'es'
    };
  }

  get data () {
    return this._data || {};
  }

  set data (value) {
    this._data = value;
  }

  choose_language(code, save) {
    code = this.available.hasOwnProperty(code) ? this.available[code] : this.default_lang;
    const cachier = new Cachier();
    save && cachier.setItem(language_cache_key, code);
    this.data = require(`./../assets/lang/${code}.json`);
  }

  detect() {
    const cachier = new Cachier();
    const lang = cachier.getItem(language_cache_key);
    return lang ? lang : navigator.language.split('-')[0];
  }

  t(key, def, params) {
    let str = this.data.hasOwnProperty(key) ? this.data[key] : (def ? def : key);
    if (params) {
      params.forEach(param => {
        str = str.replace('{}', param)
      })
    }
    return str;
  }

  get_languages_list() {
    return {
      'en': 'English',
      // 'he': 'עברית',
      // 'ru': 'Русский',
      'es': 'Español',
    }
  }

}