/**
 * @param {string} tab
 */
export function switchTab (tab) {
    return {
        type: 'SWITCH_TAB',
        tab
    }
}

/**
 * @param {object} phoneNumbers
 */
export function addPhoneNumbers (phoneNumbers) {
    return {
        type: 'ADD_PHONE_NUMBERS',
        phoneNumbers
    }
}

/**
 * @param {object} phoneNumber
 */
export function updatePhoneNumber (phoneNumber) {
    return {
        type: 'UPDATE_PHONE_NUMBER',
        phoneNumber
    }
}

/**
 * @param {object} phoneNumber
 */
export function deletePhoneNumber (phoneNumber) {
    return {
        type: 'DELETE_PHONE_NUMBER',
        phoneNumber
    }
}

/***/
export function resetPhoneNumbers () {
    return {
        type: 'RESET_PHONE_NUMBERS'
    }
}
