import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5554%3A52
 *
 * @param {object} props - svg props
 */
export const AlertOutlinedIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M22.0002 12C22.0002 17.5228 17.523 22 12.0001 22C6.47719 22 2 17.5228 2 12C2 6.47715 6.47719 2 12.0001 2C17.523 2 22.0002 6.47715 22.0002 12ZM19.5 12.0001C19.5 16.1422 16.1421 19.5001 12 19.5001C7.85786 19.5001 4.5 16.1422 4.5 12.0001C4.5 7.85803 7.85786 4.50019 12 4.50019C16.1421 4.50019 19.5 7.85803 19.5 12.0001Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M12 7.25C11.3096 7.25 10.75 7.80964 10.75 8.5V12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12V8.5C13.25 7.80964 12.6904 7.25 12 7.25ZM12 14.25C11.3096 14.25 10.75 14.8096 10.75 15.5C10.75 16.1904 11.3096 16.75 12 16.75C12.6904 16.75 13.25 16.1904 13.25 15.5C13.25 14.8096 12.6904 14.25 12 14.25Z' fill={color}/>
        </SvgIcon>
    )
}

export default AlertOutlinedIcon
