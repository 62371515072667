<template>
  <div class="d-flex justify-center mt-10">
    <template v-if="!no_back">
	    <slot name="back">
			<w-btn
				color="secondary"
				class="pl-3"
				@click="$emit('back')"
			>
				<v-icon class="stepper-icon" size="16">$vuetify.icons.chevron_backward</v-icon>{{ l.t('app.back', 'Back') }}
			</w-btn>
	    </slot>
    </template>
	<slot name="continue">
		<w-btn
		  class="ml-5 pl-7"
		  color="primary"
		  :disabled="disable_continue"
		  @click="$emit('continue')"
		>
		  {{ continue_text || l.t('app.continue', 'Next') }} <v-icon class="stepper-icon" size="16">$vuetify.icons.chevron_forward</v-icon>
		</w-btn>
	</slot>
  </div>
</template>

<script>
  import l from '../../libs/lang';

  export default {
	props: {
		  no_back: {
			type: Boolean,
			default: false,
		  },
		  disable_continue: {
			  type: Boolean,
			  default: false,
		  },
		  continue_text: {
			  type: String,
			  default: '',
		  },
	},
    data() {
      return {
		l,
      };
    },
  };
</script>

<style scoped>
.stepper-icon {
	padding-top: 2px;
}
</style>
