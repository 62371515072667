import React from 'react'
import { makeStyles } from '@material-ui/core'
import './LoadingBar.css'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
    loader: {
        height: '4px',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '25px',
        '&:before': {
            display: 'block',
            position: 'absolute',
            content: '\'\'',
            left: '-200px',
            width: '200px',
            height: '4px',
            backgroundColor: theme.palette.primary.main,
            animation: 'loading 2s linear infinite'
        }
    }
}))

/**
 * @param {object} props
 */
export default function LoadingBar (props) {
    const classes = useStyles()
    return (
        <div data-test-id= 'loading-bar' className={`${classes.loader} ${props.className}`}/>
    )
}

LoadingBar.propTypes = { className: PropTypes.string }
