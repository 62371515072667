import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import icon from './infotip.svg'
/**
 * Component for rendering an info tooltip
 *
 * Connects to user pilot right now
 *
 * @param {object} props - contains the id
 */
function RemoteInfoTip (props) {
    useEffect(() => {
        // TODO should really move these functions to some until component
        // this will call userpilot reload everytime a new info tip is add, fixing the issue of not having user pilot info tips working
        // https://docs.userpilot.com/article/59-installation-guide-for-single-page-applications-spas
        const userPilotReload = window?.userpilot?.reload
        if (userPilotReload && typeof userPilotReload === 'function') { userPilotReload() }
    })
    return <span data-user-pilot-id={props.id}><img style={{ width: 18, height: 18 }} src={icon} alt="&#9432;"/></span>
}
RemoteInfoTip.propTypes = {
    /** used for User Pilot anchor, used by product */
    id: PropTypes.string.isRequired
}

export default RemoteInfoTip
