import React, { Component } from 'react'
import HeaderTabs from 'header-tabs'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { setSmallView, switchView } from '../actions/view'
import { switchExtension } from '../actions/pdcuser'
import { AddUserIcon } from 'pdc-svg-icons'
import { ManageUsersIcon } from 'pdc-svg-icons'
import ConfirmModal from 'confirm-modal'
import ResizeAware from 'react-resize-aware'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentTabFromRoute } from 'app-tabs'
import ManageUsers from './ManageUsers/ManageUsers'
import AddUsers from './AddUsers/AddUsers'
import PropTypes from 'prop-types'

const styles = theme => ({
    appWrapper: {
        flex: 1,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        width: '100%',
        position: 'relative'
    },
    appContent: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100% - 60px)',
        width: '100%',
        '&.small': {
            height: 'calc(100% - 50px)'
        }
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView,
    currentView: state.currentView,
    currentExtensionRedux: state.currentExtension
})

const mapDispatchToProps = dispatch => ({
    setSmallView: boolVal => dispatch(setSmallView(boolVal)),
    switchView: view => dispatch(switchView(view)),
    switchExtension: extension => dispatch(switchExtension(extension))
})

const tabs = [
    { id: 'manage-users', icon: ManageUsersIcon, default: true },
    { id: 'add-users', icon: AddUserIcon }
]

class App extends Component {
    constructor (props) {
        super(props)
        const currentTab = getCurrentTabFromRoute('users', this.props.routeProps, tabs)
        if (!currentTab) {
            const currentTab = tabs.find(t => t.default).id
            this.updateRouterHistory(currentTab)
        }
        this.state = { isBusy: false }
    }

    onTabSwitch = newTab => {
        const currentTab = getCurrentTabFromRoute('users', this.props.routeProps, tabs)
        if (currentTab === newTab) return
        if (this.state.isBusy) {
            this.setState({ exitAttempt: newTab })
            return false
        }
        this.updateRouterHistory(newTab)
    }

    getBasePath = () => this.props.routeProps.match.path.replace(/\/+$/g, '')

    updateRouterHistory = path => {
        const basePath = this.getBasePath()
        this.props.routeProps.history.push(`${basePath}/${path}`)
    }

    renderApp = () => {
        const basePath = this.getBasePath()
        const defaultTabId = tabs.find(t => t.default).id
        return (
            <Switch>
                <Route path={`${basePath}/manage-users`} render={props => (
                    <ManageUsers
                        extension = {this.props.extension}
                        companyName = {this.props.companyName}
                        isCSR = {this.props.isCSR}
                        setBusy = {this.setBusy}
                        liveFormatNumber = {this.liveFormatNumber}
                        routeProps = {props}
                    />
                )}/>
                <Route path={`${basePath}/add-users`} render={props => (
                    <AddUsers
                        setBusy = {this.setBusy}
                        swtichTab = {this.onTabSwitch}
                        routeProps = {props}
                    />
                )}/>
                <Route path='/' render={() => <Redirect to={`${basePath}/${defaultTabId}`}/>}/>
            </Switch>
        )
    }

    discardChanges = () => {
        const newTab = this.state.exitAttempt
        this.setState({ isBusy: false, exitAttempt: null }, () => this.onTabSwitch(newTab))
    }

    renderDiscardChangesModal = () => {
        return (
            <ConfirmModal
                isShown = {Boolean(this.state.exitAttempt)}
                title = 'Discard changes?'
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {() => this.setState({ exitAttempt: null })}
                onConfirm = {this.discardChanges}
                size = 'size2' // size440
            />
        )
    }

    setBusy = isBusy => {
        this.setState({ isBusy })
        this.props.setHasChange(isBusy)
    }

    liveFormatNumber = (number, prevNumber) => {
        let length = number.length
        prevNumber = (prevNumber.match(/\d/g) || [])
        prevNumber = prevNumber.join('')

        if (prevNumber.length === 3 && length === 3) {
            number = number.slice(0, -1)
            length = number.length
        }
        if (length > 0) {
            number = number.slice(0, 0) + '(' + number.slice(0)
        }
        if (length > 2) {
            number = number.slice(0, 4) + ')' + number.slice(4)
        }
        if (length > 3) {
            number = number.slice(0, 5) + ' ' + number.slice(5)
        }
        if (length > 6) {
            number = number.slice(0, 9) + '-' + number.slice(9)
        }

        return number
    }

    handleResize = size => {
        const needToExpand = this.props.smallView && !this.props.screenViewType.isMobileView
        const needToShrink = !this.props.smallView && this.props.screenViewType.isMobileView
        if (needToShrink) this.props.setSmallView(true)
        else if (needToExpand) this.props.setSmallView(false)
    }

    render = () => {
        const { classes } = this.props
        const currentTab = getCurrentTabFromRoute('users', this.props.routeProps, tabs)
        const navigationHeight = this.props.smallView ? '0px' : '0px'
        return (
            <div className={classes.appWrapper}>
                <ResizeAware
                    className='resize-aware'
                    style={{ height: `calc(100% - ${navigationHeight})` }}
                    onResize={this.handleResize}
                >
                    <HeaderTabs selectedTabId={currentTab} smallView={this.props.smallView} tabs={tabs} onTabSwitch={this.onTabSwitch}/>
                    <div className={`${classes.appContent} ${this.props.smallView ? 'small' : ''}`}>
                        {this.renderApp()}
                        {this.renderDiscardChangesModal()}
                    </div>
                </ResizeAware>
            </div>
        )
    }
}

App.propTypes = {
    classes: PropTypes.object,
    screenViewType: PropTypes.string,
    setSmallView: PropTypes.func,
    smallView: PropTypes.bool,
    changeUserPlan: PropTypes.func,
    pendingFindAvailableNumbers: PropTypes.bool,
    newNumbers: PropTypes.array,
    extension: PropTypes.object,
    routeProps: PropTypes.object,
    liveFormatNumber: PropTypes.func,
    isCSR: PropTypes.bool,
    companyName: PropTypes.string,
    setHasChange: PropTypes.func
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
