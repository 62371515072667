import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5628%3A762
 *
 * @param {SvgIconProps} props - svg props
 */
export const PlayIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M8.79409 4.57752C8.4636 4.33716 8 4.57324 8 4.98189V19.0181C8 19.4268 8.4636 19.6628 8.79409 19.4225L18.444 12.4044C18.7185 12.2047 18.7185 11.7953 18.444 11.5956L8.79409 4.57752Z' fill={color}/>
        </SvgIcon>
    )
}

export default PlayIcon
