<template>
    <App v-bind="$attrs" v-on="$listeners" :sessionId="sessionId">
        <OrdersCreate
            v-if="page === 'create'"
            :postSaveCb="postSaveCb"
        />
        <OrdersShow
            v-else-if="page === 'show'"
            :postSaveCb="postSaveCb"
            :id="id"
        />
        <OrdersIndex v-else :clickOnShowPageLink="clickOnShowPageLink"/>
    </App>    
</template>
  
  <script>
  import {OrdersCreate, OrdersShow, OrdersIndex} from 'orders-components'
  import App from '../elements/functional-components/App.vue';
  
  export default {
    props:{
       id: {
        type: Number,
      },
      page: {
        type: String,
        required: true,
        validator: (v) => ['create', 'show', 'index'].includes(v),
      },
      sessionId: {
        type: String,
        required: false,
      },
      postSaveCb: {
        type: Function,
      },
      clickOnShowPageLink: {
        type: Function,
      }
    },
    components: {
      App,
      OrdersShow,
      OrdersIndex,
      OrdersCreate,
    },
  };
  </script>

  