import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5537%3A4520
 *
 * @param {SvgIconProps} props - svg props
 */
export const CheckboxIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z' fill={color}/>
            <path d='M7.2696 10.9406C7.17157 10.8454 7.01528 10.8465 6.91866 10.9431L5.51049 12.3513C5.41268 12.4491 5.41289 12.6078 5.51095 12.7053L9.98235 17.1534C10.0817 17.2522 10.2428 17.2502 10.3395 17.1488L18.4979 8.60307C18.5917 8.50484 18.5899 8.3497 18.4939 8.25367L17.0869 6.84664C16.988 6.7478 16.8274 6.7492 16.7303 6.84974L10.113 13.7018L7.2696 10.9406Z' fill='white'/>
        </SvgIcon>
    )
}

export default CheckboxIcon
