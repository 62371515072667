import React, { forwardRef, MutableRefObject, useCallback, useContext, useEffect, useImperativeHandle, useReducer, useState } from 'react'
import { AutoReplyData, ListenerData, ListenerName, reducer, ReducerAction } from './autoReplyUtil'

import { Alert, Color as AlertColor } from 'alert-mui'
import RemoteConfigValue, { getValue } from 'remote-config-value'
import { Switch } from 'switch'
import { Textarea } from 'textarea-mui'
import api from '../../util/api_v5'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import Typography from 'typography'
import { ScreenSizeContext } from 'providers'

const useStyles = makeStyles(styles)

// eslint-disable-next-line
const DEFAULT_RATE_LIMIT = 8 * 60 * 60 // 8 hours in seconds

interface Props { setHasChange: (hasChange: boolean) => void, onLoaded: () => void }

const InfoAlert = (): JSX.Element | null => {
    const classes = useStyles()
    const [showAutoReplyAlert, setShowAutoReply] = useState(true)
    if (!showAutoReplyAlert) return null
    return (
        <Alert
            soft
            classes = {{ root: classes.autoReplyAlert }}
            content = {<RemoteConfigValue valueId='user_settings_notifications_auto_reply_info_alert_text'/>}
            color = {AlertColor.INFO}
            onClose = {() => setShowAutoReply(false)}
            data-test-id = 'auto-reply-info-alert'
        />
    )
}
type SectionProps =
    { name: ListenerName } &
    ListenerData &
    { dispatch: (data: ReducerAction) => void } &
    { showValidationErrors: boolean }

const Section = (props: SectionProps): JSX.Element => {
    const { name, enabled, text, showValidationErrors } = props
    const toggle = (e, enabled: boolean) => {
        props.dispatch({ type: 'TOGGLE', name, enabled })
    }
    const onTextChange = (e) => {
        const text = e.target.value
        props.dispatch({ type: 'SET_TEXT', name, text })
    }
    const classes = useStyles()
    const error = showValidationErrors && !text ? 'Can\' be empty' : ''
    return (
        <div className={classes.autoReplySection} data-test-id={`auto-reply-${name}-subsection`}>
            <Switch
                label = {<RemoteConfigValue valueId={`user_settings_notifications_auto_reply_${name}_label`}/>}
                checked={enabled}
                onChange={toggle}
                name={`auto-reply-incoming-${name}`}
                value={enabled ? 'on' : 'off'}
            />
            {enabled &&
                <Textarea
                    placeholder = {getValue(`user_settings_notifications_auto_reply_${name}_placeholder`)}
                    error = {!!error}
                    helperText = {error}
                    className = 'auto-reply-textarea'
                    value = {text}
                    onChange = {onTextChange}
                    // onRestore = {console.log}
                />
            }
        </div>
    )
}

const getDefaultListenerData = (): ListenerData => ({ enabled: false, text: '', rateLimit: DEFAULT_RATE_LIMIT })
const getDefaultData = (): AutoReplyData => ({ calls: getDefaultListenerData(), messages: getDefaultListenerData() })

const areListenersEqual = (storedData: ListenerData, localData: ListenerData): boolean => {
    const isTextEqual = storedData.text === localData.text
    const isRateLimitEqual = storedData.rateLimit === localData.rateLimit
    if (storedData.enabled !== localData.enabled && (storedData.enabled || !isTextEqual || !isRateLimitEqual)) return false
    return isTextEqual && isRateLimitEqual
}

const useAutoReplyData = (props: Props) => {
    const [loading, setLoading] = useState(true)
    const [state, dispatch] = useReducer(reducer, { storred: getDefaultData(), local: getDefaultData() })

    const load = useCallback(async () => {
        const autoReplyData: AutoReplyData = {
            calls: { enabled: false, text: '', rateLimit: DEFAULT_RATE_LIMIT },
            messages: { enabled: false, text: '', rateLimit: DEFAULT_RATE_LIMIT }
        }
        const response = await api.getAutoResponderEvents()
        const autoResponderLiteners = response.items
        const messagesListener = autoResponderLiteners.find(listener => listener[':subscriptions']?.[0]?.[':tags']?.includes('message'))
        const callsListener = autoResponderLiteners.find(listener => listener[':subscriptions']?.[0]?.[':tags']?.includes('call'))
        const listeners: { calls: any, messages: any } = { messages: messagesListener, calls: callsListener }

        for (const listenerType in listeners) {
            const listener = listeners[listenerType]
            const callbackData = listener?.[':callback']?.config?.parameters
            if (callbackData) {
                const message = callbackData.message
                const rateLimit = callbackData['rate-limit']
                autoReplyData[listenerType].enabled = true
                autoReplyData[listenerType].text = message
                autoReplyData[listenerType].rateLimit = rateLimit
                autoReplyData[listenerType].listenerId = listener.id
            }
        }

        dispatch({ type: 'SET', data: autoReplyData })
        setLoading(false)
        props.onLoaded()
    }, [dispatch])

    useEffect(() => { load() }, [])

    useEffect(() => {
        const hasChange = !areListenersEqual(state.storred.calls, state.local.calls) ||
            !areListenersEqual(state.storred.messages, state.local.messages)
        props.setHasChange(hasChange)
    }, [state])

    return {
        callsData: state.local.calls,
        messagesData: state.local.messages,
        storredCallsListenerId: state.storred.calls.listenerId,
        storredMessagesListenerId: state.storred.messages.listenerId,
        hasCallsChange: !areListenersEqual(state.storred.calls, state.local.calls),
        hasMessagesChange: !areListenersEqual(state.storred.messages, state.local.messages),
        dispatch,
        loading
    }
}

const useSave = (
    ref: ((instance: AutoReplyHandle) => void) | MutableRefObject<AutoReplyHandle>,
    callsData: ListenerData,
    messagesData: ListenerData,
    storredCallsListenerId: number,
    storredMessagesListenerId: number,
    hasCallsChange: boolean,
    hasMessagesChange: boolean,
    dispatch: (data: ReducerAction) => void
) => {
    const [showValidationErrors, setShowValidationErrors] = useState(false)

    const isValid = (): boolean => {
        return Boolean(
            (!messagesData.enabled || messagesData.text) &&
            (!callsData.enabled || callsData.text)
        )
    }

    useImperativeHandle(ref, () => ({
        save: async () => {
            const valid = isValid()
            if (!valid) {
                setShowValidationErrors(true)
                return
            }
            const autoReplyPromises = []
            let messagesListenerId, callsListenerId
            const saveData: { [key in ListenerName]?: ListenerData } = {}
            if (hasCallsChange) saveData[ListenerName.CALLS] = callsData
            if (hasMessagesChange) saveData[ListenerName.MESSAGES] = messagesData
            for (const dataType in saveData) {
                const data: ListenerData = saveData[dataType]
                if (data.enabled) {
                    const promise = new Promise((resolve) => {
                        const createAutoReply = async () => {
                            const callbackResponse = await api.addAutoResponderCallback(data.text, data.rateLimit)
                            const listenerResponse = await api.addAutoResponderListener(callbackResponse.id)
                            /* const subscriptionResponse =  */await api.addAutoResponderSubscription(listenerResponse.id, dataType.toString())
                            /* const subscriptionId = subscriptionResponse.id */
                            if (dataType === 'calls') callsListenerId = listenerResponse.id
                            else messagesListenerId = listenerResponse.id
                        }
                        createAutoReply().then(resolve)
                    })
                    autoReplyPromises.push(promise)
                }
            }

            if (hasMessagesChange && storredMessagesListenerId) autoReplyPromises.push(api.deleteAutoRespondedListener(storredMessagesListenerId))
            if (hasCallsChange && storredCallsListenerId) autoReplyPromises.push(api.deleteAutoRespondedListener(storredCallsListenerId))

            await Promise.all(autoReplyPromises)
            setShowValidationErrors(false)

            dispatch({ type: 'SAVE', callsListenerId, messagesListenerId })
        }
    }))

    return { showValidationErrors }
}

/***/
export interface AutoReplyHandle { save: () => Promise<void> }

/***/
export const AutoReply: React.ForwardRefRenderFunction<AutoReplyHandle, Props> = (props: Props, ref): JSX.Element => {
    const classes = useStyles()
    const { callsData, messagesData, storredCallsListenerId, storredMessagesListenerId, hasCallsChange, hasMessagesChange, dispatch, loading } = useAutoReplyData(props)
    const { showValidationErrors } = useSave(ref, callsData, messagesData, storredCallsListenerId, storredMessagesListenerId, hasCallsChange, hasMessagesChange, dispatch)
    if (loading) return null
    const screenSizeContext = useContext(ScreenSizeContext)
    const titleTypography = screenSizeContext.mobile ? 'h6' : 'body1'
    return (
        <div className={classes.section} data-test-id='auto-reply-section'>
            <div className={classes.sectionTitle}><Typography variant={titleTypography} remoteConfigID='user_settings_notifications_auto_reply_header_text'/></div>
            <InfoAlert/>
            <Section name={ListenerName.CALLS} dispatch={dispatch} {...props} {...callsData} showValidationErrors={showValidationErrors}/>
            <Section name={ListenerName.MESSAGES} dispatch={dispatch} {...props} {...messagesData} showValidationErrors={showValidationErrors}/>
        </div>
    )
}

const AutoReply2 = forwardRef(AutoReply)

export default AutoReply2
