const default_primary_color = {
    base: '#3FAE29',
    lighten1: '#79C669',
    lighten3: '#3FAE29',
    lighten5: '#e6f6e2',
    darken1: '#29921B',
    darken4: '#267919'
}
const default_colors = {
    primary: default_primary_color,
    secondary: {
        base: '#8C98A0',
        lighten1: '#B6BEC2',
        lighten2: '#E0E4E6', // secondary/background
        lighten3: '#85929A',
        lighten5: '#85929A',
        darken1: '#6A757D',
        darken4: '#4F5B62'
    },
    error: {
        base: '#E85646',
        lighten1: '#F76757',
        lighten3: '#E85646',
        lighten5: '#FCDEDA',
        darken1: '#C54132',
        darken4: '#A2382C'
    },
    info: {
        base: '#2A9BB7',
        lighten1: '#6AB9CD',
        lighten3: '#2A9BB7',
        lighten5: '#E1F1F5',
        darken1: '#197F9F',
        darken4: '#186A82'
    },
    success: default_primary_color,
    warning: {
        base: '#F66B16',
        lighten1: '#FF7F37',
        lighten3: '#FF6D20',
        lighten5: '#FF6D20',
        darken1: '#DB5706',
        darken4: '#BD4403'
    },
    action: {
        base: '#85929A',
        lighten1: '#EEF0F1', // disabledText
        lighten2: '#F3F4F5', // disabledBg
        lighten3: '#E7F7E3', // primaryFocus
        lighten4: '#E4E7E9', // secondaryFocus
        lighten5: '#D0E8EF', // infoFocus
        darken1: '#FDE3D3', // warningFocusFocus
        darken2: '#FCE3E1' // errorFocusFocus
    },
    text: {
        base: '#364047', // primary
        lighten1: '#6E7A82', // secondary
        lighten2: '#8C98A0', // tertiary
        lighten3: '#AFB3B5' // textDisabled
    }
}

const themes = {
    default: {
        light: default_colors
    },
    avo: {
        light: {
            ...default_colors,
            primary: '#248085',
            info: '#9499A1'
        }
    },
    idseal: {
        light: {
            ...default_colors
        }
    },
    tailor: {
        light: {
            ...default_colors,
            primary: '#FB6C84',
            secondary: '#CBCCD3',
            info: '#1F90F8'
            // secondary: '#CBCCD3',
        }
    },
    ringboost: {
        light: {
            ...default_colors,
            primary: '#205DA0',
            info: '#7CBC54'
        }
    },
    pfm: {
        light: {
            ...default_colors,
            primary: '#F7297F',
            info: '#24272C'
        }
    }
}

export default themes
