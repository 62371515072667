// This is used in 'user settings' in order all the fields to look the same.
// Once we decide to use the new components in all fields we'll remove this file.

import React from 'react'
import Menu from 'pdc-menu'
import { mappedUSATimezones, timezoneMenuItems } from './timezones'
import PropTypes from 'prop-types'

const DEFAULT_TIMEZONE = timezoneMenuItems[0].value

/**
 * Menu field that groups the american timezones
 *
 * @param {object} props - react props
 */
const TimezoneField = (props) => {
    const getTimezone = timezone => {
        if (!(timezone in mappedUSATimezones)) {
            const generalUSATimezones = Object.keys(mappedUSATimezones)
            const USATimezone = generalUSATimezones.find(t => mappedUSATimezones[t].includes(timezone))
            if (USATimezone) return USATimezone
        }
        return timezone
    }

    const savedTimezone = getTimezone(props.savedTimezone)

    const onChangeTimezone = timezone => {
        if (timezone in mappedUSATimezones) {
            const newTimezone = getTimezone(timezone)
            if (newTimezone === savedTimezone) timezone = props.savedTimezone
            else timezone = mappedUSATimezones[timezone][0]
        }
        props.onChange(timezone)
    }

    const timezoneValue = getTimezone(props.timezoneValue || DEFAULT_TIMEZONE)
    const timezoneMenuItems2 = timezoneMenuItems.map(i => {
        if (i.divider) return { type: 'separator' }
        return i
    })

    return (
        <Menu
            controlable
            emptyOnOpen
            label='Timezone'
            items={timezoneMenuItems2}
            variant='input'
            default={timezoneValue}
            onChange={item => onChangeTimezone(item.value)}
            className={props.className}
            dataAttr='timezone-select'
        />
    )
}

TimezoneField.propTypes = {
    // Will be sent to the Menu
    className: PropTypes.object,
    // This is the saved timezone
    savedTimezone: PropTypes.string,
    // This is the timezone which is locally set / changed by the user
    timezoneValue: PropTypes.string,
    // Called on any change of the timezone
    onChange: PropTypes.func
}

export default TimezoneField
