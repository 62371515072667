<template>
	<div :class="alignment">
		<w-btn
		  v-on="$listeners"
		  class="mt-3 pl-0"
		  color="primary"
		  :disabled="disabled"
		  text
		  changes-form
		  min-width="100px"
		  data-test-id="add-to-list-button"
		>
		    <v-icon small>{{ icon }}</v-icon>
		    <slot></slot>
		</w-btn>
	</div>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			default: '$vuetify.icons.add',
		},
		disabled: {
			type: Boolean,
		}
	},
	computed: {
		alignment() {
			if (Object.prototype.hasOwnProperty.call(this.$attrs, 'center')) return 'd-flex justify-center';
			if (Object.prototype.hasOwnProperty.call(this.$attrs, 'right')) return 'd-flex justify-end';

			return '';
		}
	}
};
</script>
