import { Extension } from './Extension'
/**
 *
 */
export class Extensions {
    public items: Map<string, Extension>
    public hasMore: boolean
    public total: number
    public cursor: string | null
    /**
     * @param {any[]} items
     */
    public constructor (items: any[] = []) {
        const extensionItems = new Map()
        items.forEach(i => {
            const e = Extension.fromJson(i)
            extensionItems.set(e.extension_id, e)
        })
        this.items = extensionItems
        this.total = items.length
        this.hasMore = false
        this.cursor = null
    }

    /**
     * @param {string} filter
     */
    public filter = (filter: string) => {
        const filtered = new Extensions([])
        const q = filter.toLowerCase().trim()
        this.items.forEach((e, id) => {
            if (e.extension.toString().includes(q) || e.extension_name.toLowerCase().includes(q)) {
                filtered.items.set(id, e)
                filtered.total += 1
            }
        })
        return filtered
    }

    public contains = (ext: number) : boolean => {
        let found = false
        this.items.forEach(e => {
            if (e.extension === ext) found = true
        })
        return found
    }
}
