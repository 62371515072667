import React, { Component } from 'react'
import App from './src/components/App.js'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

const store = createStore(rootReducer)

class Company extends Component {
    render = () => {
        return (
            <Provider store={store}>
                <App
                    extensions={this.props.extensions}
                    screenViewType={this.props.screenViewType}
                    standalone={this.props.standalone}
                    redirect={this.props.redirect}
                    setHasChange={this.props.setHasChange}
                    routeProps={this.props.routeProps}
                    updateCompanyName={this.props.updateCompanyName}
                />
            </Provider>
        )
    }
}

export default Company
