<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask id="mask0_5918_14" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M24.0001 7.90358e-05L7.00009 0L6.73692 1.44745C6.59334 2.23719 6.93581 3.03625 7.60673 3.4769L20.5838 12.0001H24.0001V7.90358e-05ZM0 24.0001H16.6461L17.0534 22.6882C17.3179 21.8366 16.9866 20.9129 16.2413 20.4234L3.41631 12.0001H0L0 24.0001Z" fill="#C4C4C4"/>
	</mask>
	<g mask="url(#mask0_5918_14)">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M12 23.5C18.3513 23.5 23.5 18.3512 23.5 12C23.5 5.64872 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64872 0.5 12C0.5 18.3512 5.64873 23.5 12 23.5ZM13 22.25C18.1086 22.25 21.25 17.1086 21.25 12C21.25 6.89137 16.1086 1.75 11 1.75C5.89137 1.75 2.75 6.89137 2.75 12C2.75 17.1086 7.89137 22.25 13 22.25Z" fill="currentColor"/>
	</g>
	<path d="M0.0938663 12.7495L1.32401 9.46916C1.39642 9.27607 1.6583 9.24874 1.76901 9.42272L4.63938 13.9333C4.76372 14.1287 4.57846 14.3735 4.35662 14.307L0.25611 13.0768C0.117523 13.0352 0.0430627 12.885 0.0938663 12.7495Z" fill="currentColor"/>
	<path d="M23.9061 11.2505L22.676 14.5308C22.6036 14.7239 22.3417 14.7513 22.231 14.5773L19.3606 10.0667C19.2363 9.87129 19.4215 9.62647 19.6434 9.69302L23.7439 10.9232C23.8825 10.9648 23.9569 11.115 23.9061 11.2505Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M7.70314 10.6247C8.78287 12.9435 10.5857 14.8684 12.8233 16.1153L14.6562 14.406C14.8812 14.1963 15.2058 14.1351 15.4842 14.2415C16.3754 14.5709 17.3439 14.7659 18.3422 14.8008C18.785 14.8162 19.1346 15.1911 19.1192 15.6339L19.0211 18.4436C19.0056 18.8864 18.6307 19.236 18.1879 19.2206C10.6283 18.9566 4.71571 12.6161 4.9797 5.05653C4.99516 4.61374 5.37009 4.26412 5.81288 4.27958L8.6306 4.37798C9.07339 4.39344 9.42302 4.76837 9.40756 5.21115C9.37241 6.21748 9.49969 7.18919 9.76608 8.10126C9.84479 8.38612 9.76943 8.69785 9.53614 8.9154L7.70314 10.6247Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {};
</script>
