import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setSmallView } from '../../actions/view'
import { setUsers, updateUser } from '../../actions/users'
import { setPhones, updatePhone } from '../../actions/phones'
import { withStyles } from '@material-ui/core'
import LoaderFull from 'loader-full'
import MyPhonesContent from './MyPhonesContent'
import { theme } from 'get-theme'
import api from '../util/api_phones'
import Prompt from 'pdc-prompt'
import EmergencyAddressModal from '../EmergencyAddressModal/EmergencyAddressModal'
import PropTypes from 'prop-types'
import { TextField } from 'text-field-mui'
import ConfirmModal from 'confirm-modal'
import RemoteConfigValue from 'remote-config-value'

const mapStateToProps = state => ({
    smallView: state.smallView,
    phones: state.phones,
    users: state.users
})

const mapDispatchToProps = dispatch => ({
    setSmallView: boolVal => dispatch(setSmallView(boolVal)),
    setPhones: phones => dispatch(setPhones(phones)),
    updatePhone: phone => dispatch(updatePhone(phone)),
    setUsers: users => dispatch(setUsers(users)),
    updateUser: user => dispatch(updateUser(user))
})

const styles = theme => ({
    myPhonesContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    phoneDeviceNameField: {
        cursor: 'text',
        width: '100%',
        minWidth: 160,
        '&.smallDevice': {
            minWidth: 160,
            '& div>label>span': {
                fontSize: '20px !important'
            },
            '& div>div>input': {
                fontSize: '20px !important'
            }
        }
    },
    textFieldWrapper: {
        minWidth: 180
    },
    e911ErrorPrompt: {
        fontWeight: 500
    },
    boldDialogText: {
        fontWeight: 'bold',
        color: 'black'
    }
})

class MyPhones extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: !this.props.phones.length,
            silentLoading: false,
            hasMore: true,
            pdcPromptOpen: false,
            pdcPromptColor: 'tertiary',
            pdcPromptMessage: null,
            editAddresPhoneDeviceId: null,
            nameEdit: null,
            assignLoading: null,
            assignAction: null
        }
    }

    componentDidMount () {
        this.init()
    }

    init = async () => {
        this.setState({ silentLoading: true })
        const [phonesResponse, usersResponse] = await Promise.all([api.loadPhones(10, 0), api.loadUsers(100, 0)])
        let phones = []
        let users = []
        if (phonesResponse) {
            if (phonesResponse.error) {
                this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while fetching phones.', pdcPromptColor: 'red' })
            } else {
                phones = phonesResponse.data
            }
        }
        if (usersResponse) {
            if (usersResponse.error) {
                // this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while fetching users.' })
                console.log('Something went wrong while fetching users.')
            } else {
                users = usersResponse.data.map(rawUser => this.formatUser(rawUser))
            }
        }
        this.props.setPhones(phones)
        this.props.setUsers(users)
        this.setState({ loading: false, silentLoading: false })
    }

    loadMore = async () => {
        if (this.state.silentLoading) return
        let currentPhones = this.props.phones
        const response = await api.loadPhones(10, currentPhones.length)
        if (response.error) {
            this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while fetching phones.', pdcPromptColor: 'red' })
            return
        }
        const phones = response.data
        if (phones.length < 10) this.setState({ hasMore: false })
        currentPhones = currentPhones.concat(phones)
        this.props.setPhones(currentPhones)
    }

    handlepdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null, pdcPromptColor: 'tertiary' })

    renderLoader = () => {
        const { classes, smallView } = this.props
        if (!this.state.loading) return null
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} styles={!smallView ? { loaderFull: { left: 'calc(50% + 120px - 40px)' } } : {}} size='bigger'/>
            </div>
        )
    }

    formatUser = (userApiResource) => {
        // Format a raw User resource from the Users API in a way that's easier
        // to work with -- This extracts direct_number, meeting_plan, and device
        // from user.relationships... on the assumption there is 0 or 1 of each

        const user = { ...userApiResource }

        // We'll find 'numbers', 'devices', and 'add_ons' under user.relationships
        // if listUsers was called with ?include=numbers,devices,add_ons in the
        // query string of the listUsers URL
        let directNumbers
        try {
            directNumbers = user.relationships.numbers.data.filter(
                number => number.route_type === 'direct-line'
            )
        } catch (e) {
            directNumbers = []
        }

        let meetingPlans
        try {
            meetingPlans = user.relationships.add_ons.data.filter(
                add_on => add_on.description === 'Meetings'
            )
        } catch (e) {
            meetingPlans = []
        }

        let devices
        try {
            devices = user.relationships.devices.data.filter(
                // TODO: We probably want filter to make sure the device is
                //  actually a desk phone, and not a cell, and is active/connected
                device => true // Placeholder
            )
        } catch (e) {
            devices = []
        }

        let plan = {}
        try {
            plan = user.relationships.plan
        } catch (e) {
            plan = {
                data: {
                    id: 1,
                    plan_description: 'Basic',
                    extension_billing_code: 17108
                }
            }
        }
        user.plan = plan

        let extension = {}
        try {
            extension = user.relationships.extension
        } catch (e) {
            extension = {
                data: {}
            }
        }
        user.extension = extension

        const lists = {
            direct_number: directNumbers,
            meeting_plan: meetingPlans,
            device: devices
        }

        // eslint-disable-next-line
        for (const key in lists) {
            if (lists[key].length === 1) {
                user[key] = lists[key][0]
            } else if (lists[key].length > 1) {
                console.warn(`Found more than one ${key} for user - expected 0 or 1.`)
                user[key] = lists[key][0]
            } else {
                user[key] = null
            }
        }

        return user
    }

    unassignDevice = async (phoneDevice, user) => {
        if (!user) return
        this.setState({ assignLoading: phoneDevice.id })
        let promptMessage = `Device unassigned from ${user.first_name} ${user.last_name}`
        let pdcPromptColor = this.state.pdcPromptColor
        const response = await api.unassignDevice(phoneDevice.id, user.id)
        if (response.error) {
            promptMessage = 'Something went wrong while unassigning device.'
            pdcPromptColor = 'red'
        } else { this.unasignLocal(phoneDevice, user) }
        setTimeout(() => this.setState({ assignAction: null, assignLoading: null, pdcPromptOpen: true, pdcPromptMessage: promptMessage, pdcPromptColor }), 100)
    }

    unasignLocal = (phoneDevice, user) => {
        if (!user) return
        user.device = null
        phoneDevice.assigned_to = null
        phoneDevice.extension = null
        this.props.updateUser(user)
        this.props.updatePhone(phoneDevice)
    }

    assignDevice = async (phoneDevice, user) => {
        if (!user) return
        const currentlyAssignedUser = this.props.users.find(u => u.id === phoneDevice.assigned_to)
        if (phoneDevice.assigned_to === user.id) return
        this.setState({ assignLoading: phoneDevice.id })
        const response = await api.assignDevice(phoneDevice.id, user.id)
        // Refresh phones data from back-end to prevent spurious "+" in E911 column
        this.init()
        let promptMessage = (
            <div>
                <div style={{ fontSize: 14 }}>Phone assignment successful</div>
                <div>If this phone is being relocated, make sure to verify the E911 emergency address.</div>
            </div>
        )
        let pdcPromptColor = this.state.pdcPromptColor
        if (response.error) {
            promptMessage = (
                <div>
                    <div style={{ fontSize: 14 }}>Unable to assign phone</div>
                    <div>Please try again. If you continue to experience issues, please contact support by clicking on the Help option.</div>
                </div>
            )
            pdcPromptColor = 'red'
        } else {
            const currentlyAssignedDevice = this.props.phones.find(p => p.assigned_to === user.id)
            this.unasignLocal(phoneDevice, currentlyAssignedUser)
            if (currentlyAssignedDevice) {
                currentlyAssignedDevice.assigned_to = null
                currentlyAssignedDevice.extension = null
                this.props.updatePhone(currentlyAssignedDevice)
            }
            phoneDevice.assigned_to = user.id
            phoneDevice.extension = user.extension.data.extension_number
            user.device = { id: phoneDevice.id }
            this.props.updateUser(user)
            this.props.updatePhone(phoneDevice)
        }
        setTimeout(() => this.setState({ assignAction: null, assignLoading: null, pdcPromptOpen: true, pdcPromptMessage: promptMessage, pdcPromptColor }), 100)
    }

    showEditAddressModal = phoneDeviceId => this.setState({ editAddresPhoneDeviceId: phoneDeviceId })

    renderPhoneNameField = (phoneDevice) => {
        const { classes } = this.props
        const id = phoneDevice.id
        const name = this.state.nameEdit?.id === id ? this.state.nameEdit.name : phoneDevice.name

        const onChange = value => {
            const name = value
            this.setState({ nameEdit: { id, name } })
        }

        const onSave = async () => {
            if (!this.state.nameEdit) return
            if (this.state.nameEdit.name === phoneDevice.name) return this.setState({ nameEdit: null })
            let { id, name } = this.state.nameEdit
            phoneDevice.saving = true
            this.props.updatePhone(phoneDevice)
            const response = await api.updateDeviceName(id, name)
            let promptMessage = 'Device label successfully updated'
            let pdcPromptColor = this.state.pdcPromptColor
            if (response.error) {
                name = phoneDevice.name
                promptMessage = 'Something went wrong while updating device name.'
                pdcPromptColor = 'red'
            }
            phoneDevice.saving = false
            phoneDevice.name = name
            this.props.updatePhone(phoneDevice)
            this.setState({ nameEdit: null, pdcPromptOpen: true, pdcPromptMessage: promptMessage, pdcPromptColor })
        }

        const onKeyPress = e => e.key === 'Enter' ? onSave() : null

        return (
            <div className={`${classes.phoneDeviceNameField} ${this.props.smallView ? 'smallDevice' : ''}`}>
                <TextField
                    label = 'Label'
                    value = {name}
                    onChange = {(e) => onChange(e.target.value)}
                    onXClick = {() => onChange('')}
                    showLoading = {phoneDevice.saving}
                    disabled = {phoneDevice.saving}
                    onBlur = {onSave}
                    onKeyPress = {onKeyPress}
                    noLabelOnActiveOrResolved
                />
            </div>
        )
    }

    renderMain = () => {
        if (this.state.loading) return
        // const MyPhonesSubcomponent = this.props.smallView ? MyPhonesSmall : MyPhonesLarge
        return (
            <MyPhonesContent
                loadMore = {this.loadMore}
                hasMore = {this.state.hasMore}
                showEditAddressModal = {this.showEditAddressModal}
                showAssignActionDialog = {this.showAssignActionDialog}
                renderPhoneNameField = {this.renderPhoneNameField}
                nameEdit = {this.state.nameEdit}
                assignDevice = {this.assignDevice}
                unassignDevice = {this.unassignDevice}
                assignLoading = {this.state.assignLoading}
                incompleteE911Count={this.props.incompleteE911Count}
            />

        )
    }

    showAssignActionDialog = (action, deviceId, userId) => this.setState({ assignAction: action ? { action, deviceId, userId } : null })

    getAssignScenario = (device, user) => {
        // Based on the scenario we show a specific message to the user
        if (!device.assigned_to && !user.device) return 1
        if (device.assigned_to && !user.device) return 2
        if (!device.assigned_to && user.device) return 3
        if (device.assigned_to && user.device) return 4
    }

    renderAssignActionDialog = () => {
        if (!this.state.assignAction) return null
        const { classes } = this.props
        const action = this.state.assignAction.action
        const phoneDevice = this.props.phones.find(p => p.id === this.state.assignAction.deviceId)
        const userId = this.state.assignAction.userId
        const user = userId === 0 ? this.props.users.find(u => u.device?.id === phoneDevice.id) : this.props.users.find(u => u.id === userId)
        const currentlyAssignedUser = userId === 0 ? user : this.props.users.find(u => u.device?.id === phoneDevice.id)
        let modalTitle = ''
        let modalContent = ''

        if (action === 'unassign') {
            modalTitle = `Unassign phone from ${user?.first_name} ${user?.last_name}`
            modalContent = <><p><RemoteConfigValue valueId={'unassign_dialog_content'}/></p><span className={classes.boldDialogText}><RemoteConfigValue valueId={'unassign_dialog_footer'}/></span></>
        } else {
            modalTitle = `Assign phone to ${user?.first_name} ${user?.last_name}`
            switch (this.getAssignScenario(phoneDevice, user)) {
                    case 1:
                        modalContent = <>
                            <p>The phone will inherit {user?.first_name} {user?.last_name}&apos;s settings. You will need to reboot the phone for changes to take effect immediately.</p>
                            <span className={classes.boldDialogText}><RemoteConfigValue valueId={'assign_dialog_footer'}/></span>
                        </>
                        break
                    case 2:
                    // TODO correct name
                        modalContent = <>
                            <p>
                                <span className={classes.boldDialogText}>This phone is currently assigned to {currentlyAssignedUser?.first_name} {currentlyAssignedUser?.last_name}. </span>
                                If you continue, this phone&apos;s configuration will be replaced with {user?.first_name} {user?.last_name}&apos;s settings. You will need to reboot your phones for changes to take effect immediately.
                            </p>
                            <span className={classes.boldDialogText}><RemoteConfigValue valueId={'assign_dialog_footer'}/></span>
                        </>
                        break
                    case 3:
                    // TODO correct name
                        modalContent = <>
                            <p>
                                <span className={classes.boldDialogText}>{user?.first_name} {user?.last_name} already has a phone assigned. </span>
                            If you continue, the currently assigned phone will no longer be operational.  You will need to reboot your phones for changes to take effect immediately.
                            </p>
                            <span className={classes.boldDialogText}><RemoteConfigValue valueId={'assign_dialog_footer'}/></span>
                        </>
                        break
                    case 4:
                    // TODO correct name
                        modalContent = <>
                            <p>
                                <span className={classes.boldDialogText}>This phone is currently assigned to {currentlyAssignedUser?.first_name} {currentlyAssignedUser?.last_name}. </span>
                                If you continue, this phone&apos;s configuration will be replaced with {user?.first_name} {user?.last_name}&apos;s settings.
                            </p>
                            <p>
                                <span className={classes.boldDialogText}>{user?.first_name} {user?.last_name} already has a phone assigned. </span>
                                If you continue, the currently assigned phone will no longer be operational.
                            </p>
                            <p>You will need to reboot your phones for changes to take effect immediately.</p>
                            <span className={classes.boldDialogText}><RemoteConfigValue valueId={'assign_dialog_footer'}/></span>
                        </>

                        break
                    default:
                        modalContent = ''
            }
        }

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.assignAction !== null}
                content = {modalContent}
                loading = {this.state.assignLoading}
                confirmButtonDisabled = {this.state.assignLoading}
                noButtonText = 'Cancel'
                yesButtonText = {action === 'assign' ? 'Assign' : 'Unassign'}
                onReject = {() => this.setState({ assignAction: null })}
                onConfirm = {() => action === 'assign' ? this.assignDevice(phoneDevice, user) : this.unassignDevice(phoneDevice, user)}
                size = {this.props.smallView ? 'sizeMobile' : 'size550'}
            />
        )
    }

    renderEmergencyAddressModal = () => {
        const { classes } = this.props
        const editAddresPhoneDeviceId = this.state.editAddresPhoneDeviceId
        if (!editAddresPhoneDeviceId) return null
        const phones = [...this.props.phones]
        const index = phones.findIndex(p => p.id === editAddresPhoneDeviceId)
        const phoneDevice = { ...phones[index] }

        const onSave = async address => {
            const response = await api.submitE911(phoneDevice.id, address)
            if (response.error) {
                console.log('Error response from submitE911', response)
                const errorMessage = <span className={classes.e911ErrorPrompt}>We were unable to validate your address. Please update and save again.<br></br>If you&apos;re having difficulty saving your address, contact us by selecting the <b>Help option on the left navigation bar.</b></span>
                this.setState({ pdcPromptOpen: true, pdcPromptMessage: errorMessage, pdcPromptColor: 'red' })
                return response
            }
            phoneDevice.e911_address = response.data.e911_address
            this.props.updatePhone(phoneDevice)
            this.setState({ editAddresPhoneDeviceId: null, pdcPromptOpen: true, pdcPromptMessage: 'E911 address successfully saved' })
            return response.data
        }

        return (
            <EmergencyAddressModal
                address = {phoneDevice.e911_address}
                deviceId = {phoneDevice.id}
                onClose = {() => this.setState({ editAddresPhoneDeviceId: null })}
                onSave = {onSave}
                smallView = {this.props.smallView}
            />
        )
    }

    render () {
        const { classes } = this.props
        return (
            <div className={classes.myPhonesContainer}>
                {this.renderLoader()}
                {this.renderMain()}

                <Prompt
                    isOpen = {this.state.pdcPromptOpen}
                    color = {this.state.pdcPromptColor}
                    position = 'bottom'
                    content = {this.state.pdcPromptMessage}
                    onClose = {this.handlepdcPromptClose}
                />
                {this.renderEmergencyAddressModal()}
                {this.renderAssignActionDialog()}
            </div>

        )
    }
}

MyPhones.propTypes = {
    // Material ui classes
    classes: PropTypes.any,
    // Array of phone devices
    phones: PropTypes.array,
    users: PropTypes.array,
    // Is small view or not
    smallView: PropTypes.bool,
    // Set the phone devices
    setPhones: PropTypes.func,
    // Update the phone device item
    updatePhone: PropTypes.func,
    updateUser: PropTypes.func,
    setUsers: PropTypes.func,
    incompleteE911Count: PropTypes.number
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyPhones))
