/* eslint-disable react/prop-types */
/* eslint-disable jsdoc/require-param-type */

import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'

function arrowGenerator (color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '2em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`
            }
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '2em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`
            }
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '2em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`
            }
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '2em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`
            }
        }
    }
}

const tooltipStyle = {
    position: 'relative',
    color: '#f2f5f6',
    height: '27px',
    display: 'flex',
    alignItems: 'center'
}

const arrowStyle = {
    position: 'absolute',
    fontSize: 6,
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid'
    }
}

const useStylesArrowError = makeStyles(theme => ({
    tooltip: Object.assign(tooltipStyle, {
        background: 'rgba(223, 29, 8, 0.75)',
        height: 'fit-content'
    }),
    arrow: arrowStyle,
    popper: arrowGenerator('rgba(223, 29, 8, 0.75)')
}))

/**
 * @param props
 */
export function ErrorArrowTooltip (props) {
    const { arrow, ...classes } = useStylesArrowError()
    const [arrowRef, setArrowRef] = React.useState(null)

    return (
        <Tooltip
            classes={classes}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef
                        }
                    }
                }
            }}
            {...props}
            title={
                props.title
                    ? <>
                        {props.title}
                        <span className={arrow} ref={setArrowRef} />
                    </>
                    : null
            }
            placement={props.placement || 'bottom'}
        />
    )
}

const useStylesArrowDefault = makeStyles(theme => ({
    tooltip: Object.assign(tooltipStyle, {
        fontWeight: 'bold',
        height: 'fit-content'
    })
}))

/**
 * @param props
 */
export function DefaultArrowTooltip (props) {
    const { classes } = useStylesArrowDefault()
    return (
        <Tooltip
            classes={classes}
            {...props}
            arrow
            title={props.title || ''}
            placement={props.placement || 'bottom'}
        />
    )
}

/**
 *
 */
export const DefaultTooltip = withStyles(theme => ({
    tooltip: {
        background: '#364047',
        height: 'fit-content',
        fontSize: 12
    }
}))(Tooltip)

/**
 *
 */
export const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#666666',
        color: 'white',
        width: 230,
        height: 'fit-content',
        fontSize: 11,
        fontFamily: 'NTR, sans-serif',
        lineHeight: 1.18,
        border: '1px solid #666666'
    }
}))(Tooltip)

/**
 *
 */
export const WebComTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#666666',
        color: 'white',
        maxWidth: 230,
        height: 'fit-content',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'OpenSans, sans-serif',
        lineHeight: 1.18,
        border: '1px solid #666666'
    }
}))(Tooltip)

const useStylesWebComNumberPicker = makeStyles(theme => ({
    tooltip: Object.assign(tooltipStyle, {
        backgroundColor: '#6a8cc6',
        color: 'white',
        width: 150,
        height: 'fit-content',
        fontSize: 12,
        border: '1px solid #6a8cc6'
    }),
    arrow: arrowStyle,
    popper: arrowGenerator('#6a8cc6')
}))

/**
 * @param props
 */
export function WebComArrowTooltipNumberPicker (props) {
    const { arrow, ...classes } = useStylesWebComNumberPicker()
    const [arrowRef, setArrowRef] = React.useState(null)

    return (
        <Tooltip
            classes={classes}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef
                        }
                    }
                }
            }}
            {...props}
            title={props.title
                ? <>
                    {props.title}
                    <span className={arrow} ref={setArrowRef} />
                </>
                : ''}
            placement={props.placement || 'bottom'}
        />
    )
}

/**
 *
 */
export const WebComTooltipYellow = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#FDBF45',
        color: 'black',
        width: 150,
        height: 'fit-content',
        fontSize: 12,
        border: '1px solid #FDBF45'
    }
}))(Tooltip)

/**
 *
 */
export const ErrorTooltip = withStyles(theme => ({
    tooltip: {
        background: 'rgba(223, 29, 8, 0.75)',
        height: 'fit-content',
        fontWeight: 'bold',
        fontSize: 12
    }
}))(Tooltip)

/**
 *
 */
export const PriceBreakdownTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#000000',
        color: '#ffffff',
        maxWidth: 1200,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9'
    }
}))(Tooltip)

const useStylesWebArrowDefault = makeStyles(theme => ({
    tooltip: Object.assign(tooltipStyle, {
        fontWeight: '400',
        // background:    theme.palette.tertiary.dark,
        height: 'fit-content',
        maxWidth: 210,
        fontSize: 14,
        color: 'white',
        textAlign: 'center'
    }),
    arrow: arrowStyle
    // popper: arrowGenerator(theme.palette.tertiary.dark)
}))

/**
 * @param props
 */
export function WebArrowTooltip (props) {
    const { arrow, ...classes } = useStylesWebArrowDefault()
    const [arrowRef, setArrowRef] = React.useState(null)

    return (
        <Tooltip
            classes={classes}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef
                        }
                    }
                }
            }}
            {...props}
            title={props.title
                ? <>
                    {props.title}
                    <span className={arrow} ref={setArrowRef} />
                </>
                : ''}
            placement={props.placement || 'bottom'}
        />
    )
}

const useStylesArrowMeetingsDefault = makeStyles(theme => ({
    tooltip: Object.assign(tooltipStyle, {
        fontWeight: 600,
        background: theme.meetingsAdminApp.defaultTooltipBackgroundColor,
        height: 'fit-content',
        color: 'white'
    }),
    arrow: arrowStyle,
    popper: arrowGenerator(theme.meetingsAdminApp.defaultTooltipBackgroundColor)
}))

/**
 * @param props
 */
export function MeetingsDefaultArrowTooltip (props) {
    const { arrow, ...classes } = useStylesArrowMeetingsDefault()
    const [arrowRef, setArrowRef] = React.useState(null)

    return (
        <Tooltip
            classes={classes}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef
                        }
                    }
                }
            }}
            {...props}
            title={props.title
                ? <>
                    {props.title}
                    <span className={arrow} ref={setArrowRef} />
                </>
                : ''}
            placement={props.placement || 'bottom'}
        />
    )
}

Tooltip.PropTypes = {
    title: PropTypes.string,
    placement: PropTypes.string
}
