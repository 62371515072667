import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5532%3A1156
 *
 * @param {SvgIconProps} props - svg props
 */
export const CancelIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.73298 7.63195C8.5572 7.45602 8.27204 7.45602 8.09625 7.63195L7.63168 8.09692C7.45624 8.27251 7.45609 8.55698 7.63133 8.73276L10.8886 11.9999L7.63133 15.2671C7.45609 15.4429 7.45624 15.7273 7.63168 15.9029L8.09625 16.3679C8.27204 16.5438 8.5572 16.5438 8.73298 16.3679L12.0008 13.0973L15.2687 16.368C15.4445 16.544 15.7297 16.544 15.9055 16.368L16.37 15.9031C16.5455 15.7275 16.5456 15.443 16.3704 15.2672L13.1131 12.0001L16.3704 8.7329C16.5456 8.55713 16.5455 8.27265 16.37 8.09707L15.9055 7.63209C15.7297 7.45616 15.4445 7.45616 15.2687 7.63209L12.0009 10.9027L8.73298 7.63195Z' fill={color}/>
        </SvgIcon>
    )
}

export default CancelIcon
