export function updateSettings(settings) {
	return {
		type: 'UPDATE_SETTINGS',
		settings
	}
}

export function updateVoicemail(voicemail) {
	return {
		type: 'UPDATE_VOICEMAIL',
		voicemail
	}
}

export function updateHoldMusic(holdMusic) {
	return {
		type: 'UPDATE_HOLD_MUSIC',
		holdMusic
	}
}