import React from 'react'
import Typography from 'typography'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'

/***/
export interface SectionProps {
    classes: any
    titleRemoteConfigID: string
    subtitleRemoteConfigID: string
    tooltipRemoteConfigID: string
    children: any
    smallView: any
}

/***/
export const Section = (props: SectionProps): JSX.Element => {
    const { classes, titleRemoteConfigID, subtitleRemoteConfigID, tooltipRemoteConfigID, children, smallView } = props
    const dataTestIdProp = props['data-test-id'] ? { 'data-test-id': props['data-test-id'] } : {}
    const titleDataTestIdProp = props['title-test-id'] ? { 'data-test-id': props['title-test-id'] } : {}
    const subtitleDataTestIdProp = props['subtitle-test-id'] ? { 'data-test-id': props['subtitle-test-id'] } : {}

    return (
        <div className={classes.section} {...dataTestIdProp}>
            <div className={`section-title ${smallView ? 'mobile' : ''}`}>
                <Typography { ...titleDataTestIdProp } variant='h6' remoteConfigID={titleRemoteConfigID}/>
                {tooltipRemoteConfigID ? <RemoteInfoTipMui arrow remoteConfigIDs={[tooltipRemoteConfigID]} placement={RemoteInfoTipMuiPlacement.TOP}/> : null}
            </div>
            <div className={`section-subtitle ${smallView ? 'mobile' : ''}`}>
                <Typography { ...subtitleDataTestIdProp } variant='body2' remoteConfigID={subtitleRemoteConfigID}/>
            </div>
            <div className='section-content'>{children}</div>
        </div>
    )
}

export default Section
