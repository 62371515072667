/**
 * @param {object} theme
 */
const styles = theme => ({
    fieldsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        '& .address-row': {
            display: 'flex',
            margin: '12px 0',
            '& > *': {
                flex: 1
            }
        },
        '& .small-view': {
            flexDirection: 'column',
            rowGap: 24
        },
        '& .address-row-2': {
            columnGap: 20,
            '& > *': {
                '&:first-child': {
                    flex: 2
                },
                '&:last-child': {
                    flex: 1
                }
            }
        },
        '& .address-row-3': {
            columnGap: 20,
            '& > *': {
                '&:last-child': {
                    flex: 2
                }
            }
        },
        '& .standardized-row': {
            display: 'flex',
            margin: '12px 0',
            '& > div:not(:last-child)': {
                marginRight: 30
            }
        }
    },
    infoMessage: {
        margin: '15px 0'
    }
})

export default styles
