import React from 'react'
import { SpinnerIcon2 } from 'svg-icons'
// import { SpinnerIcon } from '../../pdc-svg-icons/SvgIcons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({ spinner: { animation: 'rotate-spinner-3298fadkj 1s infinite steps(12)' } }))

/**
 * @param {Props} props
 */
const Spinner = (props: any): JSX.Element => {
    const classes = useStyles()
    let className = classes.spinner
    if (props.className) className += ` ${props.className}`
    return <SpinnerIcon2 {...props} className={className}/>
}

export default Spinner
