import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=5973%3A4518
 *
 * @param {SvgIconProps} props - svg props
 */
export const AccountIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.0417 6.87492C16.0417 4.84987 14.4 3.20825 12.375 3.20825C10.35 3.20825 8.70833 4.84987 8.70833 6.87492C8.70833 8.89996 10.35 10.5416 12.375 10.5416C14.4 10.5416 16.0417 8.89996 16.0417 6.87492ZM14.4375 6.87492C14.4375 5.73583 13.5141 4.81242 12.375 4.81242C11.2359 4.81242 10.3125 5.73583 10.3125 6.87492C10.3125 8.014 11.2359 8.93742 12.375 8.93742C13.5141 8.93742 14.4375 8.014 14.4375 6.87492ZM1.375 5.49992C0.615609 5.49992 0 6.11553 0 6.87492C0 7.63431 0.615609 8.24992 1.375 8.24992H6.875C7.63439 8.24992 8.25 7.63431 8.25 6.87492C8.25 6.11553 7.63439 5.49992 6.875 5.49992H1.375ZM16.5 6.87492C16.5 6.11553 17.1156 5.49992 17.875 5.49992H20.625C21.3844 5.49992 22 6.11553 22 6.87492C22 7.63431 21.3844 8.24992 20.625 8.24992H17.875C17.1156 8.24992 16.5 7.63431 16.5 6.87492ZM11.4583 15.1249C11.4583 13.0999 9.81671 11.4583 7.79167 11.4583C5.76662 11.4583 4.125 13.0999 4.125 15.1249C4.125 17.15 5.76662 18.7916 7.79167 18.7916C9.81671 18.7916 11.4583 17.15 11.4583 15.1249ZM9.85417 15.1249C9.85417 13.9858 8.93075 13.0624 7.79167 13.0624C6.65258 13.0624 5.72917 13.9858 5.72917 15.1249C5.72917 16.264 6.65258 17.1874 7.79167 17.1874C8.93075 17.1874 9.85417 16.264 9.85417 15.1249ZM1.375 13.7499C0.615608 13.7499 0 14.3655 0 15.1249C0 15.8843 0.615609 16.4999 1.375 16.4999H2.29167C3.05106 16.4999 3.66667 15.8843 3.66667 15.1249C3.66667 14.3655 3.05106 13.7499 2.29167 13.7499H1.375ZM11.9167 15.1249C11.9167 14.3655 12.5323 13.7499 13.2917 13.7499H20.625C21.3844 13.7499 22 14.3655 22 15.1249C22 15.8843 21.3844 16.4999 20.625 16.4999H13.2917C12.5323 16.4999 11.9167 15.8843 11.9167 15.1249Z" fill={color}/>
        </SvgIcon>
    )
}

export default AccountIcon
