/**
 * @param {object} theme
 */
const styles = theme => ({
    recordInfo: {
        marginLeft: 10
    },
    removeButton: {
        cursor: 'pointer',
        marginLeft: 10,
        color: '#ff000077',
        '&:hover': {
            color: '#ff0000'
        }
    },
    audioPlayerWrapper: {
        width: '100%'
    },
    recordSection: {
        display: 'flex',
        alignItems: 'center',
        marginTop: (props) => props.showIconOnly ? 0 : 20,
        '@media (hover: none)': {
            '& .recording-icon-btn:hover': {
                backgroundColor: theme.palette.primary.dark
            }
        }
    },
    audioSection: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 0
    }
})

export default styles
