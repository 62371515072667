import React from 'react'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import Tooltip from '@material-ui/core/Tooltip'
import RemoteConfigValue from 'remote-config-value'
import { InfotipTooltipIcon } from 'svg-icons'

/***/
export enum Placement {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom'
}

interface Props {
    /**
     * list of firebase remote id's
     */
    remoteConfigIDs: string[]
    /**
     * left,right,top,bottom
     */
    placement: Placement
    /**
     * display arrow
     */
    arrow?: boolean
}

const useStyles = makeStyles(styles)

/**
 *
 */
export function RemoteInfoTipMui (props: Props) {
    const classes = useStyles()
    const infotipContent = props.remoteConfigIDs.map((configId, index) => (
        <span key={index} className={classes.tooltipLine}><RemoteConfigValue valueId={configId}/></span>
    ))
    return (
        <Tooltip
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            title={infotipContent}
            placement={props.placement}
            leaveDelay={200}
            enterTouchDelay={0}
            interactive
            arrow={!!props.arrow}
        >
            <div className={classes.iconWrapper}>
                <InfotipTooltipIcon/>
            </div>
        </Tooltip>
    )
}

export default RemoteInfoTipMui
