import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5537%3A4540
 *
 * @param {SvgIconProps} props - svg props
 */
export const RadioButtonCheckedIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM11.9998 16.9995C14.7612 16.9995 16.9998 14.7609 16.9998 11.9995C16.9998 9.23809 14.7612 6.99951 11.9998 6.99951C9.23833 6.99951 6.99976 9.23809 6.99976 11.9995C6.99976 14.7609 9.23833 16.9995 11.9998 16.9995Z' fill={color}/>
        </SvgIcon>
    )
}

export default RadioButtonCheckedIcon
