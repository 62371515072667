import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'

const apiBase = process.env.REACT_APP_USER_API_URL
const numberManagerBase = process.env.REACT_APP_NUMBER_MANAGER_API_URL

class API {
    static loadUsers = async (pageSize, offset) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/users?page_size=${pageSize}&offset=${offset}&order_by=status.asc&include=extension,plan,add_ons,devices,numbers`
        const response = await ajax.get(requestUrl)
        if (response.response && response.response.status >= 400) {
            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling invite users endpoint', err)
            return { error: true }
        }
    }

    static inviteUsers = async (users) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/users`

        // Since Ajax.js::Ajax.onError() RETURNs a caught axios error, rather than
        // re-THROWing it, this will never throw -- but response may be an error
        // object. Instead of using try / catch  (which won't catch the _returned_
        // error) we'll need to inspect the object to see if it's an error or not
        const response = await ajax.post(requestUrl, users)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling invite users endpoint', err)
            return { error: true }
        }
    }

    static resendInvite = async (userId) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/users/${userId}/invitation`

        // Since Ajax.js::Ajax.onError() RETURNs a caught axios error, rather than
        // re-THROWing it, this will never throw -- but response may be an error
        // object. Instead of using try / catch  (which won't catch the _returned_
        // error) we'll need to inspect the object to see if it's an error or not
        const response = await ajax.post(requestUrl, {})
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling resend invite endpoint', err)
            return { error: true }
        }
    }

    static updateUser = async (user) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/users`
        const response = await ajax.put(requestUrl, [user])
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling reset password endpoint', err)
            return { error: true }
        }
    }

    static reassignAdminUser = async (user_id) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/admin/reassign/${user_id}`
        const response = await ajax.post(requestUrl, {})
        if (response.response?.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            return response.response.data
        }

        try {
            return response.data || { error: true }
        } catch (err) {
            return { error: true }
        }
    }

    static resetUserPassword = async (id) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/users/${id}/resetpassword`
        const response = await ajax.post(requestUrl, {})
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            return { error: true }
        }
    }

    static deleteUser = async (id) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/users/${id}`
        const response = await ajax.delete(requestUrl, {})
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling delete users endpoint', err)
            return { error: true }
        }
    }

    static getUsersStats = async () => {
        const accountId = PhoneComUser.getAPIAccountId()
        // let requestUrl = `${PhoneComUser.getv5ApiRoot()}`
        const requestUrl = `${apiBase}/voip/${accountId}/users/statistics`
        const response = await ajax.get(requestUrl)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling statistics endpoint', err)
            return { error: true }
        }
    }

    static listUnassignedNumbers = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/list-phone-numbers-2-2`
        const response = await ajax.post(requestUrl, { filters: { 'route-type': 'no-route' } })
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling list-phone-numbers endpoint', err)
            return { error: true }
        }
    }

    static findAvailableNumbers = async (area_code = null, limit = 9) => {
        // let requestUrl = `${PhoneComUser.getv5ApiRoot()}/numbers/get-available-numbers`
        const requestUrl = `${numberManagerBase}/get-available-numbers`
        const payload = { limit, area_code }
        const response = await ajax.postAccount(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling get-available-number endpoint', err)
            return { error: true }
        }
    }

    static addNumberToAccountAndAssignToUser = async (numberId, userId) => {
        // let requestUrl = `${PhoneComUser.getv5ApiRoot()}/numbers/add-available-number`
        const requestUrl = `${numberManagerBase}/add-available-number`
        const payload = { search_result_id: numberId, voip_user_id: userId }
        const response = await ajax.postAccount(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling add-available-number endpoint', err)
            return { error: true }
        }
    }

    static assignNumberToExtension = async (number, voipPhoneId) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
        const payload = {
            voip_did_id: number.voip_did_id,
            assign_to: voipPhoneId,
            number_type: 'direct-line'
        }
        const response = await ajax.post(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling configure-calling endpoint', err)
            return { error: true }
        }
    }

    static unassignNumber = async (voip_did_id, voip_phone_ids) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
        const response = await ajax.post(requestUrl, { unassign: true, voip_did_id, voip_phone_ids })
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling configure-calling endpoint', err)
            return { error: true }
        }
    }

    static updatePhoneNumber = async (payload) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/update-phone-number`
        return await ajax.post(requestUrl, payload)
    }

    static addMeet = async (email, extension_id, plan_code, name) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/video/add-user`
        const account_id = PhoneComUser.getAPIAccountId()
        const payload = { email, account_id, extension_id, plan_code, name }

        const response = await ajax.post(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling add-user endpoint', err)
            return { error: true }
        }
    }

    static setAccountSettings = data => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/set-account-settings`
        return ajax.post(requestUrl, data).then(response => response.data)
    }

    static setVoipPhoneExtension = async (voipPhoneId, extension) => {
        try {
            parseInt(extension)
        } catch (err) {
            throw new Error('Invalid extension value')
        }

        const reservedExtensions = [411, 611, 711, 800, 911, 988]
        if (reservedExtensions.includes(parseInt(extension))) {
            if (parseInt(extension) === 988) {
                throw new Error('988 cannot be used as an extension number as it is a reserved dial out number.')
            } else {
                throw new Error('This extension number is reserved for other services.')
            }
        }

        const accountId = PhoneComUser.getAPIAccountId()
        // due to issues with v4 in stage this allows to override default v4 url
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const requestUrl = `${v4Root}/accounts/${accountId}/extensions/${voipPhoneId}`
        const payload = {
            extension: parseInt(extension)
        }
        const response = await ajax.patchClean(requestUrl, payload)
        if (response.request &&
            response.status >= 200 &&
            response.status < 300) {
            try {
                const ret = response.data.extension
                return ret
            } catch (err) {
                throw new Error('Invalid value returned')
            }
        }
        const errMsg = response?.response?.data?.['@error']?.['@message'] || 'Update request failed'
        throw new Error(errMsg)
    }

    static uploadAvatar = async (user, base64image) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/users/${user.id}/avatar`
        const response = await ajax.put(requestUrl, { image: base64image })
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling upload-avatar endpoint', err)
            return { error: true }
        }
    }

    static getExpressServiceCode = async () => {
        const baseUrl = PhoneComUser.getv4ApiRoot()
        const accountId = PhoneComUser.getAPIAccountId()
        const extensionId = PhoneComUser.getExtensionId()

        const url = `${baseUrl}/accounts/${accountId}/extensions/${extensionId}/express-service-codes?modes=full`
        const response = await ajax.get(url)

        if (response && response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            return response.data
        }
        try {
            return response.data.items.pop().express_service_code
        } catch (err) {
            return ''
        }
    }

    static setCallForwarding = async (phoneNumber, extensionId) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-device-calling`
        const data = {
            device_type: 'web',
            call_mode: 'voip',
            caller_id_mode: 'calling_number',
            phone_number: { phone_number: phoneNumber, screening: true }, // Screening should be set to true by default
            extension_id: extensionId
        }
        const response = await ajax.post(requestUrl, data)
        return response
    }

    static removeNumberForwarding = async (phoneNumber, extensionId) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/remove-number-forwarding`
        const data = { phone_number: phoneNumber, extension_id: extensionId }
        const response = await ajax.post(requestUrl, data)
        return response
    }

    static getExtension = async extensionId => {
        const voipId = PhoneComUser.getAPIAccountId()
        extensionId = extensionId || parseInt(PhoneComUser.getExtensionId())
        const baseUrl = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const requestUrl = `${baseUrl}/accounts/${voipId}/extensions/${extensionId}`
        const response = await ajax.get(requestUrl, 'Bearer')
        return response.data
    }

    static listExtensions = async extensionIds => {
        const voipId = PhoneComUser.getAPIAccountId()
        const baseUrl = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const filtersQuery = extensionIds ? `?filters[id]=in:${extensionIds.join(',')}` : ''
        const requestUrl = `${baseUrl}/accounts/${voipId}/extensions${filtersQuery}`
        const response = await ajax.get(requestUrl, 'Bearer')
        return response.data
    }
}

export default API
