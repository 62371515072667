class Cachier {
    /**
     *
     * @param {string, number} unique_key usually user.id
     */
    constructor (unique_key) {
        this.unique_key = unique_key || 'console.phone.com'
        this.console_cache = 'console.phone.com_cache_keys'
        this.storage = localStorage
    }

    /**
     *
     * @param {string} key
     * @returns string
     */
    personalizedCacheKey (key) {
        return `${key}-${this.unique_key}`
    }

    /**
     *
     * @param {string} key
     * @param {any} value
     * @returns boolean
     */
    setItem (key, value) {
        try {
            const cache = this.storage.setItem(this.personalizedCacheKey(key), JSON.stringify(value))
            this._addKeyToUsersCache(key)

            return cache
        } catch (err) {
            if (err && err.message && err.message.includes('exceeded the quota')) {
                console.log(err)
                console.error('Cache is not updated. Local storage is full.')
            }
        }

        return true
    }

    /**
     *
     * @param {string} key
     * @returns object if the stored value was an obj, if not - any
     */
    getItem (key) {
        const cache = this.storage.getItem(this.personalizedCacheKey(key))

        return cache ? JSON.parse(cache) : cache
    }

    /**
     *
     * @param {string} key
     * @returns undefined
     */
    removeItem (key) {
        return this.storage.removeItem(this.personalizedCacheKey(key))
    }

    /**
     * adds to array of items for specific nique key. USed to remove them on calling purge method
     *
     * @param {string} key
     * @returns undefined
     */
    _addKeyToUsersCache (key) {
        let user_cache = this.storage.getItem(this.console_cache) || JSON.stringify([])
        user_cache = JSON.parse(user_cache)
        user_cache.push(this.personalizedCacheKey(key))
        user_cache = [...new Set(user_cache)]

        return this.storage.setItem(this.console_cache, JSON.stringify(user_cache))
    }

    /**
     * removes all cache created before last call of this method
     *
     * @returns boolean
     */
    purgeCache () {
        let cache = this.storage.getItem(this.console_cache)
        if (cache) {
            cache = JSON.parse(cache)
            for (const key of cache) {
                this.storage.removeItem(key)
            }
            this.storage.removeItem(this.console_cache)
        }

        return true
    }

    /**
     *
     * @param {string} key
     * @param {any} value
     * @param {string} object_key if stored obj is object or array and we want to update only that specific prop/item
     * @returns undefined/null
     */
    updateCache (key, value, object_key) {
        const cache = this.getItem(key)
        if (cache) {
            if (cache && object_key && (typeof cache === 'object' || Array.isArray(cache))) {
                cache[object_key] = value
                return this.setItem(key, cache)
            }
            return this.setItem(key, value)
        }

        return null
    }

    /**
     *
     * @param {string} key
     * @param {any} value
     * @param {string} object_key if stored obj is object or array and we want to add only that specific prop/item
     * @returns undefined/null
     */
    addToCache (key, value, object_key) {
        const cache = this.getItem(key)
        if (cache) {
            if (cache && object_key && (typeof cache === 'object')) {
                cache[object_key] = value
                return this.setItem(key, cache)
            }
            if (Array.isArray(cache)) {
                cache.push(value)
                return this.setItem(key, cache)
            }
            return this.setItem(key, value)
        }

        return null
    }

    /**
     *
     * @param {string} key
     * @param {any} value
     * @param {string} object_key if stored obj is object or array and we want to remove only that specific prop/item
     * @returns undefined/null
     */
    removeFromCache (key, value, object_key) {
        const cache = this.getItem(key)
        if (cache) {
            if (cache && object_key && (typeof cache === 'object')) {
                delete cache[object_key]
                return this.setItem(key, cache)
            }
            if (Array.isArray(cache)) {
                const index = cache.findIndex((x) => x === value)
                if (index > -1) {
                    cache.splice(index, 1)
                    return this.setItem(key, cache)
                }
            }
            return this.setItem(key, value)
        }

        return null
    }
}
export default Cachier
