<template>
	<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="30" cy="30" r="30" id="avatar-icon-circle" fill="none" fill-opacity="0.5"/>
		<path fill-rule="evenodd" clip-rule="evenodd" id="avatar-icon-face" d="M32.0824 28.5134C35.2966 28.3534 37.6201 24.3905 37.415 20.2913C37.2099 16.1921 34.4758 13.1582 30.9254 13.335C27.375 13.5118 25.0344 16.4533 25.2395 20.5524C25.4446 24.6516 28.8681 28.6735 32.0824 28.5134ZM26.8523 28.6144C27.032 28.4941 27.182 28.3937 27.3041 28.3371C27.9767 30.9235 30.5324 33.9033 33.1479 33.7333C35.0794 33.6077 35.6715 32.1136 35.4839 28.9143C35.6963 29.0186 35.7397 29.1266 35.7863 29.2427C35.8321 29.3568 35.8811 29.4786 36.0967 29.6125C36.8485 30.0056 37.5041 30.2487 38.0821 30.463C40.3949 31.3207 41.4668 31.7181 42.5033 39.4227C42.6194 40.2858 42.3541 41.1829 41.6944 41.7513C35.9228 46.724 27.0207 43.8019 21.2021 41.8918C20.3403 41.6089 19.5462 41.3483 18.8399 41.1387C17.9198 40.8658 17.2291 40.036 17.2568 39.0768C17.3809 34.7936 18.1098 32.8378 19.5494 31.1622C20.793 29.7147 22.3578 29.6618 23.7138 29.6159C23.9495 29.608 24.1789 29.6002 24.3993 29.5854C25.5138 29.5103 26.3155 28.9737 26.8523 28.6144Z" fill="none"/>
	</svg>
</template>

<script>
  export default {
  };
</script>
<style scoped>
	#avatar-icon-circle {
		fill: var(--v-primary-lighten1);
	}
	#avatar-icon-face {
		fill: var(--v-primary-darken1);
	}
</style>
