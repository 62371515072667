import React from 'react'
import classNames from 'classnames'

const addClassAttr = (child, classAttr) => {
	let className = classNames(
		child.props.className,
		classAttr
	)
	let props = {className}
	return React.cloneElement(child, props)
}

// Hide contents on Fullstory. Put on all private info
const FSPrivate = props => {
	return React.Children.map(props.children, child => addClassAttr(child, 'fs-block'))
}

export {FSPrivate}