import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5989%3A7307
 *
 * @param {object} props - svg props
 */
export const AlertIcon = (props: SvgIconProps & { secondaryColor?: string }): JSX.Element => {
    const color = props.color || 'currentColor'
    const secondaryColor = props.secondaryColor || 'white'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    delete svgProps.secondaryColor
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M12 6C11.3096 6 10.75 6.55964 10.75 7.25V12.75C10.75 13.4404 11.3096 14 12 14C12.6904 14 13.25 13.4404 13.25 12.75V7.25C13.25 6.55964 12.6904 6 12 6ZM12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9403 11.3096 17.5 12 17.5C12.6904 17.5 13.25 16.9403 13.25 16.25C13.25 15.5596 12.6904 15 12 15Z' fill={secondaryColor}/>
        </SvgIcon>
    )
}

/***/
export const AlertFilledIcon = AlertIcon

export default AlertIcon
