import 'core-js'
import * as React from 'react'
import VueWrapper from 'vue-wrapper'
import App from './app.vue'

/**
 *
 *
 * @param {string} page - create, show or index
 */
const getVueWrapper = function (page) {
    /**
     * The main app for the console jsx to react from vuew
     *
     * @param {object | null} props  - react props
     */
    // eslint-disable-next-line react/display-name
    return function (props) {
        return (
            <div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                {VueWrapper({ ...props, page }, App)}
            </div>
        )
    }
}

const OrdersCreate = getVueWrapper('create')

/**
 * The main app for the console jsx to react from vuew
 *
 * @param {object | null} props  - react props
 */
const OrdersShow = getVueWrapper('show')

/**
 * @param {object | null} props
 */
const OrdersIndex = getVueWrapper('index')

/**
 *
 */
export {
    OrdersCreate,
    OrdersShow,
    OrdersIndex
}
