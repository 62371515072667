<template>
  <div class="live-answer-destination d-flex">
		  <div class="d-md-flex w-100">
				<w-select
					v-model="data.type"
					:items="types"
					:disabled="disabled"
					:placeholder="$lang.t('la.destination-type', 'Destination type')"
					:class="$vuetify.breakpoint.mdAndUp ? 'lr-contact-first-field' : 'mb-5'"
					class="destination-type-select"
					@change="destination_change($event)"
					:rules="[$validation.required()]"
					hide-details="auto"
					data-test-id="lr-c-destination-type-select"
				/>
				<div
					class="d-md-flex ml-md-5"
					:class="{
						'lr-contact-second-field': $vuetify.breakpoint.mdAndUp,
						'w-100': !$vuetify.breakpoint.mdAndUp,
						'destination-with-warm-transfer': ['extension', 'phone_number'].includes(data.type)
					}"
				>
				  <Tel
					v-if="data.type === 'phone_number'"
					:disabled="disabled"
					:value="data.destination"
					@changed="data.destination = $event.clean_number"
					:required="true"
					:validation="true"
					data-test-id="lr-c-destination-tel-input"
					class="w-100 destination-phone-number"
				  />
				  <w-text-field
						v-if="data.type === 'email'"
						type="email"
						:rules="[$validation.required(), $validation.email()]"
						v-model="data.destination"
						:disabled="disabled"
						required
						placeholder="example@email.org"
						hide-details="auto"
						data-test-id="lr-c-destination-email-select"
						class="email-input"
					/>
				  <APIAutocomplete
						v-if="['extension', 'voicemail'].includes(data.type)"
						mode="extensions"
						:disabled="disabled"
						:value="Number.parseInt(data.destination)"
						@input="on_extension_change"
						:placeholder="$lang.t('app.select-user-extension', 'Select a User/Extension')"
						:no_update="true"
						:rules="[$validation.required()]"
						data-test-id="lr-c-destination-extension-selector"
						class="w-100 extension-selector"
						:class="data.type === 'voicemail' ? 'voicemail-selector' : ''"
				  />
				  <w-checkbox
						v-if="['extension', 'phone_number'].includes(data.type)"
						v-model="data.is_warm_transfer"
						:disabled="disabled"
						inset
						dense
						class="ml-4 lr-contact-third-field"
						color="primary"
						hide-details="auto"
						data-test-id="lr-c-destination-warmtransfer-checkbox"
				  >
						<template v-slot:label>
							<span class="w-overline text--text">
								{{ $lang.t('la.warm-transfer', 'WARM TRANSFER') }}
							</span>
						</template>
				  </w-checkbox>
				</div>
		  </div>
		 <w-btn
			v-if="deletable && !disabled"
			@click="on_click_delete"
			fab
			color="#F7675733"
			width="24"
			height="24"
			class="my-auto ml-4"
		>
			<v-icon size="16" color="error">mdi mdi-close</v-icon>
		 </w-btn>
  </div>
</template>

<script>
  import Tel from '../../../console/src/components/elements/Tel.vue';
  import APIAutocomplete from '../../../console/src/components/elements/form/APIAutocomplete.vue';

  export default {
    props: ['item', 'disabled', 'deletable', 'allow_email', 'allow_voicemail'],
    components: {
		Tel,
		APIAutocomplete,
    },
    data() {
      return {
				data: null
			};
    },
    created() {
			this.$data.data = this.$props.item;
    },
	methods: {
		destination_change(e) {
			if (e === 'email' || e === 'phone_number') {
				this.data.destination = '';
			}
		},
		on_click_delete() {
			this.$emit('remove');
			this.$global_emitter.$emit('form_input_changed');
		},
		on_extension_change(data) {
			this.$data.data.destination = data ? data.toString() : null
		}
	},
    computed: {
			types() {
				const types = [
					{value: 'phone_number', text: this.$lang.t('app.phone-number', 'Phone number')},
					{value: 'extension', text: this.$lang.t('app.user-extension', 'User/Extension')},
				];
				if (this.$props.allow_voicemail) {
					types.push({value: 'voicemail', text: this.$lang.t('la.leave-a-voicemail-on-user-extension', 'Leave a voicemail on User/Extension')});
				}
				if (this.$props.allow_email) {
					types.push({value: 'email', text: this.$lang.t('app.message-email', 'Email a message')});
				}
				return types;
			}
    },
    watch: {
			data: {
				deep: true,
				handler(val) {
					this.$emit('changed', val);
				},
			},
    },
  };
</script>
<style lang="scss">
.live-answer-destination {
	margin-top: 17px;
	.destination-type-select {
		max-width: 218px !important;
		width: 218px !important;
	}
}
</style>
