import { useEffect, useRef } from 'react'

/***/
export const useEventListener = (
    eventType = '',
    listener: (event: any) => void,
    target: typeof window | HTMLElement | EventTarget = window,
    options = null,
    dependencies: any[] = []
): void => {
    const listenerRef = useRef<(event: any) => void>()

    useEffect(() => { listenerRef.current = listener }, [listener, ...dependencies])

    useEffect(() => {
        if (!target?.addEventListener) return
        if (!eventType) return

        const eventListener = (event: any) => listenerRef.current?.(event)

        target.addEventListener(eventType, eventListener, options || undefined)

        return () => target.removeEventListener(eventType, eventListener, options || undefined)
    }, [eventType, target, options, ...dependencies])
}

export default useEventListener
