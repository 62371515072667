<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9Z" stroke="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M10.125 5.625C10.125 6.24632 9.62132 6.75 9 6.75C8.37868 6.75 7.875 6.24632 7.875 5.625C7.875 5.00368 8.37868 4.5 9 4.5C9.62132 4.5 10.125 5.00368 10.125 5.625ZM10.125 11.9837C10.125 12.614 9.62132 13.125 9 13.125C8.37868 13.125 7.875 12.614 7.875 11.9837V9.01631C7.875 8.38598 8.37868 7.875 9 7.875C9.62132 7.875 10.125 8.38598 10.125 9.01631V11.9837Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {};
</script>
