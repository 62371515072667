/* eslint-disable @typescript-eslint/explicit-member-accessibility */
interface TimeItemValues {
    hours: number;
    minutes: number;
    m?: 'am' | 'pm';
}

/**
 *
 */
export class TimeItem {
    hours: number
    minutes: number
    /**
     * @param value
     */
    constructor (value: string | TimeItemValues) {
        if (typeof value === 'string') value = TimeItem.stringToObject(value)
        let hours = value.hours
        if (value.m) {
            if (hours === 12) hours = 0
            if (value.m === 'pm') hours += 12
        }
        this.hours = hours
        this.minutes = value.minutes
    }

    /**
     *
     */
    getHours (): number { return this.hours }
    /**
     *
     */
    getMinutes (): number { return this.minutes }

    /**
     * @param string
     */
    static stringToObject (string: string): TimeItemValues {
        const split = string.split(':')
        const hours = parseInt(split[0])
        const minutes = parseInt(split[1].substring(0, 2))
        const m = split[1].substring(2).trim()
        const timeItemValues: TimeItemValues = { hours, minutes, m }
        return timeItemValues
    }

    /**
     * @param hours
     * @param minutes
     */
    static generate12HoursString (hours: number, minutes: number) {
        const m = hours > 11 ? 'pm' : 'am'
        if (hours === 0) hours = 12
        if (hours > 12) hours -= 12
        let formattedMinutes = `${minutes}`
        if (minutes < 10) formattedMinutes = `0${minutes}`
        return `${hours}:${formattedMinutes} ${m}`
    }

    toObject24 = () => {
        const hours = this.getHours()
        const minutes = this.getMinutes()
        return { hours, minutes }
    }

    /**
     *
     */
    toObject12 = () => {
        const minutes = this.getMinutes()
        let hours = this.getHours()
        let m = 'am'
        if (hours > 11) {
            hours -= 12
            m = 'pm'
        }
        if (hours === 0) hours = 12
        return { hours, minutes, m }
    }

    /**
     *
     */
    toArray () {
        const object12 = this.toObject12()
        return [object12.hours, object12.minutes, object12.m]
    }

    /**
     *
     */
    toString24 () {
        const hours = this.getHours()
        const minutes = this.getMinutes()
        return `${hours}:${minutes}`
    }

    /**
     *
     */
    toString12 () {
        const object12 = this.toObject12()
        let m = object12.m
        let hours = object12.hours
        let minutes = object12.minutes
        if (!m) {
            if (hours > 11) {
                hours = hours === 12 ? 12 : hours - 12
                m = 'pm'
            } else {
                if (hours === 0) hours = 12
                m = 'am'
            }
        }
        minutes = parseInt(`${minutes}`)
        let formattedMinutes = `${minutes}`
        if (minutes < 10) formattedMinutes = `0${minutes}`
        const formatted = `${hours}:${formattedMinutes} ${m}`
        return formatted
    }
}

/**
 *
 */
export class TimeItems {
    /**
     * Compares two TimeItems
     *
     * @param {TimeItem} item1 - first time item
     * @param {TimeItem} item2 - second time item
     */
    static compare (item1: TimeItem, item2: TimeItem): -1 | 0 | 1 {
        let [hours1, minutes1, m1] = item1.toArray()
        let [hours2, minutes2, m2] = item2.toArray()
        if (m1 !== m2) return m1 === 'am' ? -1 : 1
        if (hours1 === 12) hours1 = 0
        if (hours2 === 12) hours2 = 0
        if (hours1 !== hours2) return hours1 < hours2 ? -1 : 1
        if (minutes1 !== minutes2) return minutes1 < minutes2 ? -1 : 1
        return 0
    }

    /**
     * Returns distance between the 2 TimeItems in float
     *
     * @param {TimeItem} item1 - first time item
     * @param {TimeItem} item2 - second time item
     */
    static getTimeDistance (item1: TimeItem, item2: TimeItem): number {
        const hours1: number = item1.getHours()
        const minutes1: number = item1.getMinutes()
        const hours2: number = item2.getHours()
        const minutes2: number = item2.getMinutes()
        const overflow: number = TimeItems.compare(item1, item2) !== -1 ? 24 : 0
        const first = hours1 + (minutes1 === 59 ? 1 : 0) + (minutes1 === 30 ? 0.5 : 0)
        const second = hours2 + (minutes2 === 59 ? 1 : 0) + (minutes2 === 30 ? 0.5 : 0) + overflow
        return second - first
    }
}

/**
 *
 */
export class Range {
    startTimeItem: TimeItem
    endTimeItem: TimeItem
    /**
     * @param startTimeItem
     * @param endTimeItem
     */
    constructor (startTimeItem: TimeItem, endTimeItem: TimeItem) {
        this.startTimeItem = startTimeItem
        this.endTimeItem = endTimeItem
    }
}

/**
 *
 */
export class Ranges {
    /**
     * @param range1
     * @param range2
     */
    static getIntersection (range1: Range, range2: Range): Range {
        // First range
        const startTimeItem1 = range1.startTimeItem
        const endTimeItem1 = range1.endTimeItem
        const fromHours1 = startTimeItem1.getHours()
        const fromMinutes1 = startTimeItem1.getMinutes()
        const toHours1 = endTimeItem1.getHours()
        const toMinutes1 = endTimeItem1.getMinutes()
        // Second range
        const startTimeItem2 = range2.startTimeItem
        const endTimeItem2 = range2.endTimeItem
        const fromHours2 = startTimeItem2.getHours()
        const fromMinutes2 = startTimeItem2.getMinutes()
        const toHours2 = endTimeItem2.getHours()
        const toMinutes2 = endTimeItem2.getMinutes()
        // Intersection
        const fromHours = Math.max(fromHours1, fromHours2)
        const fromMinutes = fromHours1 === fromHours2 ? Math.max(fromMinutes1, fromMinutes2) : fromHours1 > fromHours2 ? fromMinutes1 : fromMinutes2
        const toHours = Math.min(toHours1, toHours2)
        const toMinutes = toHours1 === toHours2 ? Math.min(toMinutes1, toMinutes2) : toHours1 < toHours2 ? toMinutes1 : toMinutes2
        const startTimeItem = new TimeItem({ hours: fromHours, minutes: fromMinutes, m: null })
        const endTimeItem = new TimeItem({ hours: toHours, minutes: toMinutes, m: null })
        const responseRange: Range = new Range(startTimeItem, endTimeItem)
        return responseRange
    }
}
