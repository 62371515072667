import React, { Component } from 'react'
import { Chip } from 'chip-mui'
import { MenuItem } from 'menu-item-mui'
import { Select } from 'select-mui'
import { withStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import styles from './styles'
import Typography from 'typography'
import { convertNumberToE164, formatAsYouType, isValidPhoneNumber } from 'phone-numbers'
import ChipField from 'pdc-chip-field'
import { Radio, Variant as RadioVariant } from 'radio-mui'
import api from '../../util/api_v5'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import Prompt from 'pdc-prompt'
import UploadDocumentBox from './subcomponents/UploadDocumentBox'

const NUMBER_PLACEHOLDER = 'e.g. (941) 555-1234'
class NumberInformationStep extends Component {
    constructor (props) {
        super(props)
        this.providers = [
            { content: 'Choose provider...', value: 'None', checked: true, disabled: true },
            { content: 'Access Line Communications', value: 'access_line_communications', checked: false },
            { content: 'American Voice Mail', value: 'american_voice_mail', checked: false },
            { content: 'AT&T', value: 'at&t', checked: false },
            { content: 'Bandwidth.com', value: 'bandwidth.com', checked: false },
            { content: 'Bellsouth', value: 'bellsouth', checked: false },
            { content: 'Birch Communications', value: 'birch_communications', checked: false },
            { content: 'Boost', value: 'boost', checked: false },
            { content: 'Bright House Networks Information Services', value: 'bright_house_networks_information_services', checked: false },
            { content: 'Cavalier Telephone', value: 'cavalier_telephone', checked: false },
            { content: 'Cbeyond Communications', value: 'cbeyond_communications', checked: false },
            { content: 'Centurylink Formally Embarq', value: 'centurylink_formally_embarq', checked: false },
            { content: 'Charter Communications', value: 'charter_communications', checked: false },
            { content: 'Cingular Wireless', value: 'cingular_wireless', checked: false },
            { content: 'Comcast', value: 'comcast', checked: false },
            { content: 'Cox Communications', value: 'cox_communications', checked: false },
            { content: 'Cricket Communications', value: 'cricket_communications', checked: false },
            { content: 'Frontier Communications', value: 'frontier_communications', checked: false },
            { content: 'Global Crossing', value: 'global_crossing', checked: false },
            { content: 'Integra Telecom Inc', value: 'integra_telecom_inc', checked: false },
            { content: 'Level(3) Communications LLC', value: 'level(3)_communications_llc', checked: false },
            { content: 'Magic Jack', value: 'magic_jack', checked: false },
            { content: 'Matrix Business Technologies', value: 'matrix_business_technologies', checked: false },
            { content: 'MCI Worldcom Communications Inc', value: 'mci_worldcom_communications_inc', checked: false },
            { content: 'MetroPCS', value: 'metropcs', checked: false },
            { content: 'Mpower Communications', value: 'mpower_communications', checked: false },
            { content: 'Nextiva', value: 'nextiva', checked: false },
            { content: 'Nuvox Communications', value: 'nuvox_communications', checked: false },
            { content: 'One Box', value: 'one_box', checked: false },
            { content: 'Ooma', value: 'ooma', checked: false },
            { content: 'Optimum Online / Cable Vision', value: 'optimum_online_/_cable_vision', checked: false },
            { content: 'Packet 8 (8x8, Inc.)', value: 'packet_8', checked: false },
            { content: 'Paetec Communications Inc', value: 'paetec_communications_inc', checked: false },
            { content: 'Qwest Communications', value: 'qwest_communications', checked: false },
            { content: 'Ring Central', value: 'ring_central', checked: false },
            { content: 'Skype', value: 'skype', checked: false },
            { content: 'Sprint / Nextel', value: 'sprint_/_nextel', checked: false },
            { content: 'TDS Metro Communications', value: 'tds_metro_communications', checked: false },
            { content: 'Time Warner Cable', value: 'time_warner_cable', checked: false },
            { content: 'T-Mobile', value: 't-mobile', checked: false },
            { content: 'TW Telecom', value: 'tw_telecom', checked: false },
            { content: 'Verizon', value: 'verizon', checked: false },
            { content: 'Virgin Mobile', value: 'virgin_mobile', checked: false },
            { content: 'Voice Connect Inc', value: 'voice_connect_inc', checked: false },
            { content: 'Vonage', value: 'vonage', checked: false },
            { content: 'Windstream Communications', value: 'windstream_communications', checked: false },
            { content: 'XO Communications Inc', value: 'xo_communications_inc', checked: false },
            { content: 'Ymax Communications', value: 'ymax_communications', checked: false },
            { content: 'Other', value: '__other__', checked: false }
        ]

        this.hiddenSelectLatestBillInput = React.createRef()
        this.hiddenSelectCustomerServiceRecordInput = React.createRef()

        const passData = this.props.passData?.numberInfo
        this.state = {
            selectedProvider: passData?.provider || 'None',
            providerName: passData?.providerName || '',
            providerError: '',
            phoneNumbers: passData?.phoneNumbers || [],
            latestBill: passData?.latestBill || null,
            customerServiceRecord: passData?.customerServiceRecord || null,
            completionDate: passData?.completionDate || new Date().toLocaleDateString('en-US'),
            completionRequestDateOption: passData?.completionRequestDateOption || 'asap',
            isLoading: false,
            pdcPromptOpen: false,
            pdcPromptMessage: null,
            pdcPromptColor: 'tertiary',
            hasChange: false
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.state.phoneNumbers.length > 0 || this.state.selectedProvider !== 'None' || this.state.latestBill || this.state.customerServiceRecord) {
            this.props.passData.extraData.setBusy({ busy: true, title: 'Exit Transfer number?', content: 'All information you entered will be lost. Would you still like to exit?', cancelText: 'Cancel', yesText: 'Exit' })
        }
        const hasSaveRequest = this.props.saveId && (prevProps.saveId !== this.props.saveId)
        this.markThisAndNextStepsAsNotComplete(prevState)
        if (hasSaveRequest) {
            const movingForward = (parseInt(this.props.saveId.split('#')[1]) > this.props.stepIndex)
            let frontEndValid = false
            let backEndValid = false
            // Only do validation for Next, not Back
            if (movingForward) {
                frontEndValid = await this.validateFrontEnd()
                if (!frontEndValid) return setTimeout(this.scrollToErrorElement, 10)
                this.setState({ isLoading: true })
                backEndValid = await this.validateBackEnd()
                if (!backEndValid) {
                    this.setState({ isLoading: false })
                    return setTimeout(this.scrollToErrorElement, 10)
                }
            }
            // Save human-friendly provider name for use in later steps
            const providerName = this.providers.filter((e) => { return e.value === this.state.selectedProvider })[0].content
            const completed = movingForward ? true : this.props.passData?.numberInfo?.completed
            this.props.update({
                completed: completed,
                gotoNext: true,
                saveData: {
                    completed: completed,
                    provider: this.state.selectedProvider,
                    providerName: providerName,
                    phoneNumbers: this.state.phoneNumbers,
                    latestBill: this.state.latestBill,
                    customerServiceRecord: this.state.customerServiceRecord,
                    completionDate: this.state.completionDate,
                    completionRequestDateOption: this.state.completionRequestDateOption
                }
            })
        }
    }

    markThisAndNextStepsAsNotComplete = (prevState) => {
        if (this.state.hasChange) return
        if (
            prevState.phoneNumbers !== this.state.phoneNumbers ||
            prevState.selectedProvider !== this.state.selectedProvider ||
            prevState.providerName !== this.state.providerName ||
            prevState.latestBill !== this.state.latestBill ||
            prevState.customerServiceRecord !== this.state.customerServiceRecord ||
            prevState.completionDate !== this.state.completionDate ||
            prevState.completionRequestDateOption !== this.state.completionRequestDateOption
        ) {
            const saveData = { ...this.props.passData.numberInfo }
            saveData.completed = false
            this.props.update({
                completed: false
            })
            this.setState({ hasChange: true })
        }
    }

    validateFrontEnd = async () => {
        let isValid = true
        if (this.state.selectedProvider === 'None') {
            this.setState({ providerError: 'Required field' })
            isValid = false
        }
        if (!this.state.phoneNumbers?.length) {
            this.setState({ phoneNumbersError: 'Please enter at least one number to transfer.' })
            isValid = false
        }
        if (this.state.phoneNumbersError) {
            isValid = false
        }
        if (!this.state.latestBill) {
            this.setState({ latestBillError: true })
            isValid = false
        }

        return isValid
    }

    validateBackEnd = async () => {
        let isValid = true
        const phoneNumbers = [...this.state.phoneNumbers]
        const payload = {
            provider: this.state.providerName,
            phone_numbers: phoneNumbers
        }
        const response = await api.validateNumberInfoStep(payload)
        if (response.error?.error_details) {
            isValid = false
        } else if (response?.error) {
            isValid = false
            setTimeout(() => { this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong.', pdcPromptColor: 'red' }) }, 100)
        } else if (response.data?.invalid_numbers?.length) {
            const invalid_numbers = []
            response.data.invalid_numbers.forEach(number => {
                invalid_numbers.push(formatAsYouType(number))
            })
            const promptMessage = 'A number entered on ‘Numbers to Transfer’ is already associated with another Phone.com account. Please review the numbers you are transferring. If you are entering a correct number, contact us by clicking on the Help option.'
            setTimeout(() => { this.setState({ phoneNumbersError: `Invalid numbers: ${invalid_numbers.join(', ')}`, pdcPromptOpen: true, pdcPromptMessage: promptMessage, pdcPromptColor: 'red' }) }, 100)
            isValid = false
        }
        return isValid
    }

    scrollToErrorElement = () => {
        const errorElement = document.getElementsByClassName('error')[0]
        const muiErrorElement = document.getElementsByClassName('Mui-error')[0]
        if (muiErrorElement) muiErrorElement.scrollIntoView(false)
        else if (errorElement) errorElement?.scrollIntoView(false)
    }

    onProviderChange = (event) => {
        const selectedProvider = event.target.value
        this.setState({ selectedProvider, providerError: false })
    }

    removePhoneNumber = number => {
        const phoneNumbers = this.state.phoneNumbers.filter(item => item !== number)
        this.setState({ phoneNumbers })
    }

    handleSelectLatestBillClick = event => {
        this.hiddenSelectLatestBillInput.current.click()
        this.setState({ latestBillError: false })
    };

    handleSelectCustomerServiceRecordClick = event => {
        this.hiddenSelectCustomerServiceRecordInput.current.click()
    };

    onLatestBillSelect = event => {
        this.setState({ latestBill: event.target.files[0] })
    }

    removeLatestBill = () => {
        this.setState({ latestBill: null })
    }

    onCustomerServiceRecordSelect = event => {
        this.setState({ customerServiceRecord: event.target.files[0] })
    }

    removeCustomerServiceRecord = () => {
        this.setState({ customerServiceRecord: null })
    }

    renderChips = () => {
        return <>
            {this.state.phoneNumbers.map(number => {
                return (
                    <Chip style={{ margin: '0 5px' }} key={number} label={number} color='primary' onDelete={() => this.removePhoneNumber(number)}/>
                )
            })}
        </>
    }

    onAddNumberPaste = (event) => {
        const phoneNumbers = [...this.state.phoneNumbers]
        const clipboardData = event.clipboardData || window.clipboardData
        const newPhoneNumber = convertNumberToE164(clipboardData.getData('Text'))
        if (isValidPhoneNumber(newPhoneNumber)) {
            if (phoneNumbers.includes(newPhoneNumber)) return true
            phoneNumbers.push(newPhoneNumber)
            this.setState({ phoneNumbers })
            return true
        } else {
            this.setState({ phoneNumbersError: `Invalid numbers: ${newPhoneNumber}` })
        }
    }

    addNumber = (value) => {
        const phoneNumbers = [...this.state.phoneNumbers]
        const newPhoneNumber = convertNumberToE164(value)
        if (!isValidPhoneNumber(newPhoneNumber)) {
            // avoid marking as invalid if we haven't begun typing yet
            if (value !== '') {
                this.setState({ phoneNumbersError: `Invalid numbers: ${value}` })
            }
            return false
        }
        if (phoneNumbers.includes(newPhoneNumber)) return true
        phoneNumbers.push(newPhoneNumber)
        this.setState({ phoneNumbers })
        return true
    }

    formatItemsForChipField = () => {
        const items = []
        this.state.phoneNumbers.forEach((number) => {
            items.push({ label: formatAsYouType(number), disabled: false })
        })
        return items
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary.main} size='bigger'/>
            </div>
        )
    }

    renderRadio = (option, classes) => (
        (<Radio
            variant={RadioVariant.SECONDARY}
            checked={this.state.completionRequestDateOption === option.value}
            onChange={(e) => {
                this.setState({ completionRequestDateOption: e.target.value })
                if (e.target.value === 'asap') {
                    this.setState({ completionDate: new Date().toLocaleDateString('en-US') })
                }
            }}
            name = 'receptionist-options'
            value = {option.value}
            classes={{ root: classes.radioButton }}
        />)
    )

    completionDateOptions = [
        { label: 'As soon as possible', value: 'asap' },
        { label: 'Complete no sooner than', value: 'eta' }
    ]

    disableAfterMonth = (date) => {
        const today = new Date()
        const monthFromNowDate = new Date(today.setMonth(today.getMonth() + 1))
        return date > monthFromNowDate
    }

    render () {
        const { classes } = this.props
        let billName = this.state?.latestBill?.name
        if (billName?.length > 51) {
            billName = `${this.state?.latestBill?.name.slice(0, 24)}...${this.state?.latestBill?.name.slice(-24)}`
        }
        let customerServiceRecordName = this.state?.customerServiceRecord?.name
        if (customerServiceRecordName?.length > 51) {
            customerServiceRecordName = `${this.state?.customerServiceRecord?.name.slice(0, 24)}...${this.state?.customerServiceRecord?.name.slice(-24)}`
        }
        return (
            <div className={`${classes.numberInformation} ${this.props.smallView ? 'mobile' : ''}`}>
                {this.state.isLoading ? this.renderLoader() : null}
                <div className={classes.stepRow}>
                    <Typography data-test-id='tw-service-provider-title' variant='h6' classes={{ root: classes.formItemTitle }} remoteConfigID='transfer_number_number_info_service_provider'/>
                    <Typography data-test-id='tw-service-provider-sub-title' variant='body2' classes={{ root: classes.formItemSubtitle }} remoteConfigID='transfer_number_number_info_service_provider_subtitle'/>
                    <Select
                        className={this.state.selectedProvider === 'None' && classes.selectFirstOption}
                        value = {this.state.selectedProvider}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        onChange = {(e, i) => { this.onProviderChange(e) }}
                        multiple = {false}
                        controlled
                        error = {this.state.providerError}
                        helperText = {this.state.providerError}
                        data-test-id = 'tw-service-provider-select'
                    >
                        {this.providers.map((provider, i) => (
                            <MenuItem
                                key = {i}
                                value = {provider.value}
                                checked = {provider.checked}
                                disabled = {provider.disabled}
                                data-test-id = 'service-provider-list'
                            >{provider.content}</MenuItem>
                        ))}
                    </Select>
                </div>
                {<div className={classes.stepRow}>
                    <Typography data-test-id='tw-numbers-to-transfer-title' variant='h6' classes={{ root: classes.formItemTitle }} remoteConfigID='transfer_number_number_info_transfer_numbers'/>
                    <Typography data-test-id='tw-numbers-to-transfer-sub-title' variant='body2' classes={{ root: classes.formItemSubtitle }} remoteConfigID='transfer_number_number_info_transfer_numbers_subtitle'/>
                    <ChipField
                        placeholder = {NUMBER_PLACEHOLDER}
                        values = {this.formatItemsForChipField()}
                        submitCharacters = {[',', ';', ' ']}
                        onPaste={this.onAddNumberPaste}
                        onAdd={this.addNumber}
                        onDelete={(i) => {
                            const phoneNumbers = [...this.state.phoneNumbers]
                            if (i === -1) return
                            phoneNumbers.splice(i, 1)
                            this.setState({ phoneNumbers, phoneNumbersError: false })
                        }}
                        helperText={this.state.phoneNumbersError}
                        error={this.state.phoneNumbersError}
                        setValidation={(value) => this.setState({ phoneNumbersError: value })}
                        disabled = {this.state.phoneNumbers.length === 15}
                        numericalsOnly={true}
                        submitOnEnter
                        submitOnBlur
                        skipValidation
                        data-test-id='tw-numbers-to-transfer-field'
                        chip-test-id='tw-numbers-to-transfer-chip'
                    />
                </div>
                }
                <div className={classes.stepRow}>
                    <div className='latest-bill-title-wrapper'> {/* TODO: update this */}
                        <Typography data-test-id='tw-upload-documents-title' variant='subtitle1' classes={{ root: classes.formItemTitle }} remoteConfigID='transfer_number_number_info_documents_title'/>
                    </div>
                    <div className={classes.uploadDocumentsRow}>
                        <UploadDocumentBox
                            classes={classes}
                            fileName={billName}
                            uploadedDoc={this.state.latestBill}
                            hiddenSelectInput={this.hiddenSelectLatestBillInput}
                            onFileSelect={this.onLatestBillSelect}
                            handleSelectClick={this.handleSelectLatestBillClick}
                            removeFile={this.removeLatestBill}
                            titleRemoteConfigID='transfer_number_number_info_latest_bill'
                            infoTipRemoteConfigID='transfer_number_number_info_latest_bill_infotip'
                            required={true}
                            requiredRemoteConfigID='transfer_number_number_info_latest_bill_required'
                            requiredSubtextRemoteConfigID='transfer_number_number_info_latest_bill_required_subtext'
                            error={this.state.latestBillError}
                        />
                        <UploadDocumentBox
                            classes={classes}
                            fileName={customerServiceRecordName}
                            uploadedDoc={this.state.customerServiceRecord}
                            hiddenSelectInput={this.hiddenSelectCustomerServiceRecordInput}
                            onFileSelect={this.onCustomerServiceRecordSelect}
                            handleSelectClick={this.handleSelectCustomerServiceRecordClick}
                            removeFile={this.removeCustomerServiceRecord}
                            titleRemoteConfigID='transfer_number_number_info_customer_service_record'
                            infoTipRemoteConfigID='transfer_number_number_info_customer_service_record_infotip'
                        />
                        <div className={classes.uploadDocumentBox}>
                            <div className={classes.documentBoxTitle}>
                                <Typography data-test-id='tw-requested_completion_date_title' variant='body2' classes={{ root: classes.formItemSubtitle }} remoteConfigID='transfer_number_number_info_requested_completion_date'/>
                                <RemoteInfoTipMui arrow={false} remoteConfigIDs={['transfer_number_number_info_requested_completion_date_infotip']} placement={RemoteInfoTipMuiPlacement.TOP}/>
                            </div>
                            <div className={classes.completionDateOptions}>
                                {
                                    this.completionDateOptions.map((option, index) => {
                                        return (
                                            <div key={index} className={classes.completionDateOption}>
                                                {this.renderRadio(option, classes)}
                                                <Typography data-test-id={`tw-requested_completion_date_option_${option.value}_label`} variant='body2' classes={{ root: classes.formItemSubtitle }}>
                                                    {option.label}
                                                </Typography>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={classes.completionDatePicker}>
                                {this.state.completionRequestDateOption !== 'asap' &&
                                (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            variant = 'inline'
                                            value = {this.state.completionDate}
                                            inputProps={{ style: { textAlign: 'center', padding: '15.5px 8px 15.5px 8px' } }}
                                            format = 'MM/dd/yyyy'
                                            onChange = {value => {
                                                const completionDate = new Date(value).toLocaleDateString('en-US')
                                                this.setState({ completionDate })
                                            }}
                                            label=''
                                            disablePast
                                            shouldDisableDate={this.disableAfterMonth}
                                        />
                                    </MuiPickersUtilsProvider>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Prompt
                    promptProps={{ 'data-prompt': 'number-info-step' }}
                    isOpen={this.state.pdcPromptOpen}
                    color={this.state.pdcPromptColor}
                    position={'bottom'}
                    onClose={() => { this.setState({ pdcPromptOpen: false, pdcPromptMessage: null, pdcPromptColor: 'tertiary' }) }}
                    content={this.state.pdcPromptMessage}
                />
            </div>
        )
    }
}

NumberInformationStep.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string,
    smallView: PropTypes.string,
    saveId: PropTypes.string,
    update: PropTypes.func,
    passData: PropTypes.object,
    stepIndex: PropTypes.string
}

export default withStyles(styles)(NumberInformationStep)
