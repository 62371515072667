// React
import React from "react";
import PDCButton from "button";
import Guide from "../guide/guide";

// Stylesheet
import "./actions.css";

// Actions/Buttons
class Actions extends React.Component {

  confirm = () => {
    this.setState({
      disablePurchase:true,
      purchaseButtonClass:'btn disabled'
    })
    this.props.toggleSummaryModal()
    this.props.purchasePhoneNumbers(this.props.reservedNumbers)
  }


  render() {
    let numbers = this.props.reservedNumbers;
    let disabled = numbers.length < 1 || this.props.purchasing || this.props.loading;
    let buttonText = "Review order" + (numbers.length ? ` (${numbers.length})` : '')
    if (this.props.purchasing) buttonText = 'Confirm order';

    let checkoutActions = <>
    <div className="actions-back-button">
      <div className='back-wrapper'>
        <PDCButton
          data-test-id='back-button'
          color='primary'
          variant='graphic'
          icon='back'
          onClick={this.props.toggleSummaryModal}
        >
        </PDCButton>
        <span className='back-text'>Back</span>
      </div>
    </div>
    <div className='confirm-button'>
        <PDCButton
          data-test-id='confirm-button-purchase'
          color='primary'
          variant='filled'
          disabled={disabled}
          onClick={this.confirm}
        >
          Confirm Order
        </PDCButton>
        </div>
    </>

    let defaultActions = <>
      <div className="guide">
        <Guide
          showGuide={this.props.showGuide}
          guideText={this.props.guideText}
          onClick={this.props.backButton}
        />
      </div>
      <div className='confirm-button'>
        <PDCButton
          data-test-id='confirm-button-review'
          color='primary'
          variant='filled'
          disabled={disabled}
          onClick={() => {
            this.props.toggleSummaryModal();
          }}
        >
          {buttonText}
        </PDCButton>
      </div>
    </>

    return (
      <div className="actions-wrapper">
        <div className="actions row">
          {
            this.props.showPricingPage ?
            checkoutActions :
            defaultActions
          }
        </div>
      </div>
    );
  }
}

export default Actions;
