import React, { Component } from 'react'
import { connect } from 'react-redux'
import PersonalSettings from 'personal-settings'
import AvatarIcon from 'avatar-icon'
import ConfirmModal from 'confirm-modal'
import { ArrowBackIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'

const mapStateToProps = state => ({
    users: state.users
})

const mapDispatchToProps = dispatch => ({})

const styles = theme => ({
    wrapper: {
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        minHeight: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(90deg, ${theme.palette.tertiary[-300]} 0%, ${theme.palette.tertiary[-400]} 40%, ${theme.palette.tertiary[-400]} 60%, ${theme.palette.tertiary[-300]} 100%)`,
        '& .back-button': {
            position: 'absolute',
            left: 20,
            cursor: 'pointer',
            color: theme.palette.tertiary[-200],
            '&:hover': {
                color: theme.palette.tertiary[0]
            },
            '& > svg': {
                width: 38,
                height: 38
            }
        },
        '& .identity': {
            display: 'flex',
            alignItems: 'center'
        }
    }
})

class UserSettings extends Component {
    constructor (props) {
        super(props)
        const users = this.props.users
        const userId = this.props.userId
        const user = users.find(u => u.id === this.props.userId)
        const extensionData = user.extension.data
        const extension = {
            extension_name: extensionData.extension_name,
            extension_id: extensionData.id,
            extension: extensionData.number,
            userId
        }
        this.state = {
            user,
            extension,
            isBusy: false,
            isDiscardChangesModalShown: false
        }
    }

    onClose = () => {
        if (this.state.isBusy) {
            this.setState({ isDiscardChangesModalShown: true })
            return false
        }
        this.close()
    }

    close = () => {
        this.props.onClose()
    }

    setBusy = isBusy => {
        this.setState({ isBusy })
        this.props.setBusy(isBusy)
    }

    discardChanges = () => {
        this.setState({ isBusy: false, isDiscardChangesModalShown: false })
        this.close()
    }

    renderDiscardChangesModal = () => {
        return (
            <ConfirmModal
                isShown = {this.state.isDiscardChangesModalShown}
                title = 'Discard changes?'
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {() => this.setState({ isDiscardChangesModalShown: false })}
                onConfirm = {this.discardChanges}
                size = 'size2' // size440
            />
        )
    }

    renderUserHeaderInfo = () => {
        const { classes } = this.props
        const user = this.state.user
        const name = `${user.first_name} ${user.last_name}`
        return (
            <div className={classes.header}>
                <span className='back-button' onClick={this.onClose}>
                    <ArrowBackIcon/>
                </span>
                <div className='identity'>
                    <AvatarIcon
                        imageUrl = {user.avatar_url}
                        noBorder = {true}
                        opacity = {user.status === 'invite_pending' ? 0.7 : 1}
                        style = {{ marginRight: 20 }}
                        fontSize = {0.83}
                    />
                    <span><b>{name}&apos;s</b> User Settings</span>
                </div>
            </div>
        )
    }

    render = () => {
        const { classes } = this.props
        const extension = this.state.extension
        return (
            <div className={classes.wrapper}>
                {this.renderUserHeaderInfo()}
                <PersonalSettings
                    extension = {extension}
                    onLoaded = {() => {}}
                    origin = 'configure-app'
                    // basePathname = '/users/manage-users'
                    setHasChange = {this.setBusy}
                    routeProps = {this.props.routeProps}
                />
                {this.renderDiscardChangesModal()}
            </div>
        )
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UserSettings))
