<template>
<v-dialog
  v-bind="$attrs"
  v-on="$listeners"
  @keydown.esc="on_close"
  @click:outside="on_close"
  data-test-id="default-modal"
>
  <div class="default-modal-content" :id="id">
    <div v-if="$slots['title']" class="default-modal-title">
      <slot name="title" class="dialog-title"/>
    </div>
    <v-icon v-if="with_x" @click="on_close('x')" class="close-default-modal-x" size="20" color="secondary"
      data-test-id="close-default-modal-x">
      $vuetify.icons.close
    </v-icon>
    <div class="w-body-2"><slot /></div>
    <div v-if="$slots['buttons']" class="default-modal-buttons">
      <slot name="buttons" class="dialog-buttons"/>
    </div>
  </div>
  <template slot="activator" slot-scope="scope">
    <slot name="activator" v-bind="scope" />
  </template>
</v-dialog>
</template>
<script>
  const createId = (num) => `modal-${num}-element`
  export default {
    props: {
      with_x: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        id: createId(Date.now()),
      };
    },
    mounted() {
      document.addEventListener('click', this.clickHandler);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.clickHandler);
    },
    methods: {
      clickHandler(e) { // sharing components with configure produces produces an issue with z-indexes and closing modal on click outside that has to be handled by us
        const targetElement = e.target;
        if (targetElement && targetElement.classList.contains('v-overlay__scrim') && document.getElementById(this.$data.id)) {
          const dialogs = document.querySelectorAll('.v-dialog--active');
          if (dialogs.length > 1) {
            let modals = [];
            for (let i = 0; i < dialogs.length; i++) {
              modals = modals.concat([...dialogs[i].querySelectorAll('.default-modal-content')]); 
            }
            if (modals.length > 1) {
              const ids = modals.map((x) => x.id)
              const times = (ids.map((x) => x.split('-')[1]))
              times.sort()
              const max = times.pop();
              if (this.$data.id !== createId(max)) {
                return null;
              }
            }
          }
          this.on_close('outside');
        }
      },
      on_close(element) {
        if (element) this.$emit(`click:${element}`);
        if (
          !Object.prototype.hasOwnProperty.call(this.$attrs, 'persistent')
          || (
            Object.prototype.hasOwnProperty.call(this.$attrs, 'persistent')
              && this.$attrs.persistent !== ''
              && !this.$attrs.persistent
          )
        ) {
          this.close_modal();
        }
      },
      close_modal() {
        this.$emit('input', false);
        this.$emit('close');
      }
    }
  };
</script>
