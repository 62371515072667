/**
 * @param {string} appName
 * @param {object} routeProps
 * @param {Array} tabs
 */
const getCurrentTabFromRoute = (appName, routeProps, tabs) => {
    const pathParts = routeProps.location.pathname.split('/')
    const appNameIndex = pathParts.findIndex(e => e === appName)
    const afterAppName = pathParts.slice(appNameIndex + 1).join('/')
    if (!afterAppName) return
    const tabName = afterAppName.split('/').filter(e => e)[0]
    if (!tabName) return
    if (!tabs.map(t => t.id).includes(tabName)) return
    return tabName
}

/**
 *
 */
export { getCurrentTabFromRoute }
