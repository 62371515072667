import React, { Component } from 'react'
import { CloseCircleIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
    chip: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 20,
        padding: '9px 15px 10px',
        fontSize: 14,
        lineHeight: '21px',
        minWidth: 89,
        cursor: 'pointer',
        color: theme.palette.secondary['+200'],
        background: theme.palette.primary['-400'],
        '&:hover:not(.drag):not(.disabled)': {
            background: theme.palette.primary['-300']
        },
        '&.disabled': {
            color: theme.palette.secondary['-100'],
            background: theme.palette.secondary['-400']
        },
        '&.text': {
            paddingRight: 50,
            minWidth: 130,
            whiteSpace: 'nowrap',
            '&.has-icon': {
                minWidth: 156
            }
        },
        '&.drag': {
            cursor: 'grabbing',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.05), 0px 7px 7px rgba(0, 0, 0, 0.15), 0px 5px 5px rgba(0, 0, 0, 0.2)'
        },
        '&.has-icon': {
            paddingBottom: 9
        },
        '& .remove-icon svg': {
            color: theme.palette.primary['0']
        },
        '&.drag, &:hover': {
            '& .remove-icon svg': {
                color: theme.palette.primary['+100']
            }
        },
        '&.disabled .remove-icon svg': {
            color: theme.palette.secondary['-200']
        }
    },
    leftIcon: {
        marginRight: 5,
        height: 22,
        width: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        '& > svg': {
            height: '100%'
        }
    },
    removeIcon: {
        position: 'absolute',
        right: 10,
        top: '50%',
        display: 'flex',
        transform: 'translateY(-50%)',
        '& svg': {
            width: 20,
            height: 20
        }
    }
})
/**
 * @deprecated - Use newer version [Chip](/#chip-1)
 */
class Chip extends Component {
    state = { hover: false }

    renderIcon = () => {
        const { classes, Icon } = this.props
        if (!Icon) return null
        return (
            <div className={classes.leftIcon}>
                <Icon/>
            </div>
        )
    }

    renderRemoveIcon = () => {
        const { classes } = this.props
        return (
            <div
                className={`${classes.removeIcon} remove-icon`}
                onClick={this.props.onXClick}
            >
                <CloseCircleIcon/>
            </div>
        )
    }

    toggleHover = hover => this.setState({ hover })

    render () {
        const { classes, className } = this.props
        let variant = this.props.variant
        if (!['text', 'choice'].includes(variant)) variant = 'choice'
        const disabledClass = this.props.disabled ? 'disabled' : ''
        const dragClass = this.props.drag ? 'drag' : ''
        const hasIconClass = this.props.Icon ? 'has-icon' : ''
        const classNames = `${classes.chip} ${variant} ${dragClass} ${hasIconClass} ${disabledClass} ${className || ''}`
        return (
            <div
                className={classNames}
                onMouseEnter={() => this.toggleHover(true)}
                onMouseLeave={() => this.toggleHover(false)}
            >
                {this.renderIcon()}{this.props.children}{variant === 'text' ? this.renderRemoveIcon() : null}
            </div>
        )
    }
}

Chip.propTypes = {
    /**
     * The variant of the Chip
     * 'choice' or 'text'
     */
    variant: PropTypes.string,
    /**
     * Is it disabled
     * true or false
     */
    disabled: PropTypes.bool,
    /**
     * Is it being dragged
     * true or false
     */
    drag: PropTypes.bool,
    /**
     * Pdc svg component that will be placed at the beginning
     */
    Icon: PropTypes.func,
    className: PropTypes.string,
    classes: PropTypes.object,
    children: PropTypes.node,
    onXClick: PropTypes.func
}

Chip.defaultProps = {
    variant: 'choice',
    disabled: false,
    drag: false,
    icon: null
}

export default withStyles(styles)(Chip)
