import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { DefaultArrowTooltip } from 'tooltips'

const useStyles = makeStyles(theme => {
    return {
        mpcMenuPaper: theme.dropdownMenu.paper,
        mpcMenuList: theme.dropdownMenu.list,
        mpcMenuItem: theme.dropdownMenu.list.item,
        configureMenuPaper: theme.configureDropdownMenu.paper,
        configureMenuList: theme.configureDropdownMenu.list,
        configureMenuItem: theme.configureDropdownMenu.list.item,
        configureSeparator: theme.configureDropdownMenu.separator
    }
})

/**
 * @param {object} props
 */
const DropdownMenu = props => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)

    function handleClose () {
        setAnchorEl(null)
    }

    function showDropdown (e) {
        setAnchorEl(e.currentTarget)
    }

    function onItemClick (itemOnClick) {
        itemOnClick()
        handleClose()
    }
    const ButtonComponent = props.button || MoreVert
    const button = <ButtonComponent aria-controls={props.dropdownId} aria-haspopup='true' className={props.button ? '' : 'options-dropdown-btn'} onClick={showDropdown} />

    const dropdownStyle = props.dropdownStyle || 'mpc'
    return (
        <div>
            {button}
            <Menu
                id = {props.dropdownId}
                anchorEl = {anchorEl}
                open = {Boolean(anchorEl)}
                onClose = {handleClose}
                classes = {{ paper: classes[`${dropdownStyle}MenuPaper`], list: classes[`${dropdownStyle}MenuList`] }}
                getContentAnchorEl = {null}
                anchorOrigin = {{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin = {{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
            >
                {props.menuItems.map((item, i) => (
                    item.type === 'separator'
                        ? <hr key={item.type + i} className={classes[`${dropdownStyle}Separator`]}/>
                        : <DefaultArrowTooltip key={item.text + i} title={item.tooltipText}>
                            <span>
                                <MenuItem
                                    disabled={item.disabled == null ? false : item.disabled}
                                    data-test-id={item.text}
                                    classes={{ root: `${classes[`${dropdownStyle}MenuItem`]} ${item.className || ''}` }}
                                    dense
                                    onClick = {() => onItemClick(item.onClick)}>{item.text}</MenuItem>
                            </span>
                        </DefaultArrowTooltip>

                ))}
            </Menu>
        </div>
    )
}

DropdownMenu.propTypes = {
    classes: PropTypes.object,
    menuItems: PropTypes.array,
    dropdownId: PropTypes.int,
    button: PropTypes.object,
    dropdownStyle: PropTypes.object
}

export default DropdownMenu
