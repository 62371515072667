import { mainTheme } from 'themes'

/**
 * @param {object} theme
 */
const styles = theme => ({
    audioControlContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        minHeight: 30
    },
    progressAndTimingWrapper: {
        width: '85%',
        marginLeft: 20
    },
    audioControlSeekbar: {
        cursor: 'pointer',
        height: 9,
        borderRadius: 6,
        position: 'relative',
        display: 'flex',
        background: theme.palette.primary.borderColor,
        '& .progress-bar': {
            background: theme.audioPlayer.progressFillColor,
            borderRadius: 6,
            position: 'relative',
            overflow: 'unset',
            height: '100%',
            width: 0,
            paddingBottom: 1,
            transition: 'background .1s',
            '& .player-control': {
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                opacity: 0,
                zIndex: 1,
                width: '40px !important',
                '&.force-show': {
                    opacity: 1,
                    '& svg': {
                        width: 40
                    }
                },
                '& svg': {
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    display: 'block',
                    userSelect: 'none',
                    userDrag: 'none',
                    width: 0,
                    transition: 'width .1s'
                }
            }
        },
        '&:hover .progress-bar': {
            '& .player-control': {
                display: 'block',
                width: 'fit-content',
                opacity: 1,
                '& svg': {
                    width: 40
                }
            }
        },
        '& .temp-progress-bar': {
            background: 'white',
            opacity: 0.35,
            borderRadius: 6,
            position: 'absolute',
            left: 0,
            top: 0,
            height: 9,
            width: 0
        }
    },
    timeInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 15,
        '& span': {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.33
        }
    },
    seekBarErrorBorder: {
        border: theme ? theme.palette.error.flatBorder : mainTheme.palette.error.flatBorder
    },
    controlButtonWrapper: {
        cursor: 'pointer'
    },
    controlButtonImg: {
        maxHeight: 50,
        maxWidth: 50,
        height: '100%',
        width: '100%'
    },
    downloadStyle: {
        marginLeft: 5,
        marginRight: 5
    }
})

export default styles
