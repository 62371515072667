
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import SelectionControl from 'selection-control'
import PropTypes from 'prop-types'

const styles = theme => ({
    header: theme.settingsApp.header,
    settingWrapper: theme.settingsApp.settingWrapper,
    cancelAccountWrapper: {
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    toggleLine: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 15
    },
    textBox: {
        marginTop: 15,
        width: 280,
        padding: 15,
        fontSize: 15,
        background: '#F0F9FB',
        display: 'flex',
        flexDirection: 'column',
        '&.csr': {
            width: 340
        },
        '& span': {
            color: theme.palette.primary[0],
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView
})

const mapDispatchToProps = dispatch => ({
})

class InternationalDialing extends Component {
 state = {
     internationalDialing: false
 }

 onToggleInternationalDialing = () => {
     if (this.props.isCSR) return
     this.setState({ internationalDialing: !this.state.internationalDialing })
 }

 renderCSRTextBox = () => {
     const { classes } = this.props
     return (
         <div className={`${classes.textBox} csr`}>
             <div><b>Staff: </b>Please make sure to set the 200$</div>
             <div>deposit on the account before activating</div>
             <div>international dialing.</div>
         </div>
     )
 }

 renderRegularTextBox = () => {
     const { classes } = this.props
     return (
         <div className={classes.textBox}>
             <div>Please contact <span onClick={() => window.open('https://www.phone.com/support-request/', '_blank')}>our support team</span></div>
             <div>to enable international dialing</div>
         </div>
     )
 }

 render () {
     const textBox = this.props.isCSR ? this.renderCSRTextBox() : this.renderRegularTextBox()
     const { classes } = this.props
     return (
         <div className={classes.cancelAccountWrapper}>
             <div className={classes.toggleLine}>
                 {/* TODO: deprecate, use PdcSwitch */}
                 <SelectionControl
                     disabled = {this.props.isCSR}
                     variant = 'switch'
                     checked = {this.state.internationalDialing}
                     onClick = {() => this.onToggleInternationalDialing()}
                     name = 'toggle-international-dialing'
                     value = {'toggle-international-dialing'}
                 />
                 <span>
      Allow international outbound calls
                 </span>
             </div>
             {textBox}
         </div>
     )
 }
}

InternationalDialing.propTypes = {
    isCSR: PropTypes.bool,
    classes: PropTypes.object
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InternationalDialing))
