import App from './src/components/App'
import React from 'react'
import rootReducer from './src/reducers'
import withTheme from '@material-ui/core/styles/withTheme'
import { createStore } from 'redux'
import { PropTypes } from 'prop-types'
import { Provider } from 'react-redux'

const store = createStore(rootReducer)

class Phones extends React.Component {
    render () {
        return (
            <Provider store={store}>
                <App
                    extension={this.props.extension}
                    screenViewType={this.props.screenViewType}
                    appData={this.props.appData}
                    routeProps={this.props.routeProps}
                    incompleteE911Count={this.props.incompleteE911Count}
                />
            </Provider>
        )
    }
}

Phones.propTypes = {
    extension: PropTypes.string,
    screenViewType: PropTypes.string,
    appData: PropTypes.object,
    routeProps: PropTypes.object,
    incompleteE911Count: PropTypes.number
}

export default withTheme(Phones)
