<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="8.5" y="8.5" width="7" height="7" rx="1" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {
  };
</script>
