import { NumberTypeOption, OnCallOption, OnNothingSelected, ScheduleOption, areArraysSame, areRulesUnsupported } from './configure_util'
import { Props as ConfugureNumberProps } from './ConfigureCalling'
import { Greeting, TTS, UploadAudio } from 'audio-component-2'
import { Teammate } from 'models/src'

const formatUserKey = (props, extensionId): string => {
    const user = props.users.find(user => user.extension.id === parseInt(extensionId))
    if (!user) return ''
    return `${user.extension.id}-${user.first_name.replace(/\s/g, '')}-${user.last_name.replace(/\s/g, '')}`
}

/* THIS IS A TEMPORARY HACK -> THE REAL FIX IS TO NOT MANIPULATE THE ID AND JUST USE IT */
const formatAnyKey = (props, extensionId): string => {
    if (props.companyExtension && (extensionId === props.companyExtension.id)) {
        return `${extensionId}-Company-Inbox`
    } else {
        return formatUserKey(props, extensionId)
    }
}

/***/
export interface RulesProps {
    numberTypeOption: NumberTypeOption | ''
    useSchedules: boolean
    onCallOptionGroups: { [key in ScheduleOption]: RuleProps }
    selectedScheduleOption: ScheduleOption
    smsForwardUser: string,
    liveAnswerConfiguration: { planId: number, callHandlingNotes: string }
}

/**
 *
 */
export enum CallerId {
    CallersID = 'calling_number',
    CalledNumber = 'called_number'
}

/***/
export interface RuleProps {
    onCallOption: OnCallOption | ''
    forward: { users: string[], type: string, duration: number | '', holdMusic: any, enabled?: boolean }[]
    forwardExternal: { phoneNumber: string, callerId: CallerId, voiceTag: string, screening: boolean }
    assignVoicemailUser: string
    menu: any
    hasGreeting: boolean
    greeting: Greeting
    sameAsClosedHours?: boolean
}

const setCSWDefaults = (rules, props) => {
    rules.numberTypeOption = NumberTypeOption.MainCompanyNumber
    const { users, companyExtension, areBusinessHoursSet, companyName } = props
    const menuGreetingText = 'Please listen carefully to the following menu options.\nFor sales, press 1.\nFor support, press 2.'
    const welcomeGreetingText = `Thank you for calling${companyName ? ` ${companyName}` : ''}`
    const setScheduleDefaults = (scheduleOption: ScheduleOption) => {
        rules.onCallOptionGroups[scheduleOption].menu.greeting.setText(menuGreetingText)
        rules.onCallOptionGroups[scheduleOption].onCallOption = scheduleOption === ScheduleOption.OpenHours ? OnCallOption.Forward : OnCallOption.Voicemail
        rules.onCallOptionGroups[scheduleOption].greeting.setText(welcomeGreetingText)
        if (users.length === 1) {
            // Forward defaults
            const userKey = formatAnyKey(props, users[0].extension.id)
            rules.smsForwardUser = userKey
            rules.onCallOptionGroups[scheduleOption].forward[0].users.push(userKey)
            rules.onCallOptionGroups[scheduleOption].assignVoicemailUser = userKey
            // Voicemail defaults
            rules.onCallOptionGroups[scheduleOption].assignVoicemailUser = userKey
            // Menu defaults
            rules.onCallOptionGroups[scheduleOption].menu.actions[1] = 'ring'
            rules.onCallOptionGroups[scheduleOption].menu.menuActions[1] = `${userKey}|${userKey}`
            rules.onCallOptionGroups[scheduleOption].menu.onNothingSelected.option = OnNothingSelected.Voicemail
            rules.onCallOptionGroups[scheduleOption].menu.onNothingSelected.voicemailUser = userKey
        } else if (users.length > 1) {
            // Forward defaults
            const companyExtensionKey = formatAnyKey(props, companyExtension.id)
            rules.smsForwardUser = companyExtensionKey
            users.slice(0, 10).forEach((user: Teammate) => {
                const userKey = formatAnyKey(props, user.extension.id)
                rules.onCallOptionGroups[scheduleOption].forward[0].users.push(userKey)
            })
            // Voicemail defaults
            rules.onCallOptionGroups[scheduleOption].assignVoicemailUser = companyExtensionKey
            // Menu defaults
            rules.onCallOptionGroups[scheduleOption].menu.actions[1] = 'ring'
            const userKeys = users.slice(0, 10).map((user: Teammate) => formatAnyKey(props, user.extension.id)).join(',')
            rules.onCallOptionGroups[scheduleOption].menu.menuActions[1] = `${userKeys}|${companyExtensionKey}`
            rules.onCallOptionGroups[scheduleOption].menu.onNothingSelected.option = OnNothingSelected.Voicemail
            rules.onCallOptionGroups[scheduleOption].menu.onNothingSelected.voicemailUser = companyExtensionKey
        }
    }
    setScheduleDefaults(ScheduleOption.OpenHours)
    if (areBusinessHoursSet) setScheduleDefaults(ScheduleOption.ClosedHours)
}

const getDefaultRules = (props): RulesProps => {
    const getCallOptionsDefaultObject = (): RuleProps => ({
        onCallOption: '',
        forward: [
            { users: [], type: '', duration: '', holdMusic: null },
            { users: [], type: '', duration: '', holdMusic: null, enabled: false }
        ],
        forwardExternal: { phoneNumber: '', callerId: CallerId.CallersID, voiceTag: '', screening: true },
        assignVoicemailUser: '',
        menu: {
            menuActions: {},
            actions: {},
            greeting: new TTS(),
            onNothingSelected: { option: '', ringUsers: [], voicemailUser: '' }
        },
        hasGreeting: false,
        greeting: new TTS() // welcome greeting
    })
    const rules = {
        numberTypeOption: NumberTypeOption.Other,
        useSchedules: props.origin === 'company-setup' && props.areBusinessHoursSet,
        onCallOptionGroups: { // The schedules are for company number only
            [ScheduleOption.OpenHours]: getCallOptionsDefaultObject(),
            [ScheduleOption.ClosedHours]: getCallOptionsDefaultObject(),
            [ScheduleOption.Holidays]: Object.assign({ sameAsClosedHours: true }, getCallOptionsDefaultObject()),
            [ScheduleOption.LunchBreak]: Object.assign({ sameAsClosedHours: true }, getCallOptionsDefaultObject())
        },
        selectedScheduleOption: ScheduleOption.OpenHours,
        smsForwardUser: '',
        liveAnswerConfiguration: { planId: 0, callHandlingNotes: '' },
        scriptId: -1,
        scriptName: ''
    }
    if (props.origin === 'company-setup') setCSWDefaults(rules, props)
    return rules
}

const shouldNotProcessRules = (props): boolean => {
    const phoneNumber = props.phoneNumber
    const hasNoRules = !phoneNumber?.call_rules.rule_groups?.length
    const hasUnsupportedRules = areRulesUnsupported(phoneNumber)
    return hasNoRules || hasUnsupportedRules
}

const setAssignTo = (props, RULES): void => {
    const phoneNumber = props.phoneNumber
    if (!phoneNumber.sms_forwarding) return
    // The extension provider has it as extension.extension_id
    const forwardingExtensionId = phoneNumber.sms_forwarding.extension.id || phoneNumber.sms_forwarding.extension.extension_id
    RULES.smsForwardUser = formatAnyKey(props, forwardingExtensionId)
}

const getPhoneNumberType = (props): NumberTypeOption => {
    const phoneNumber = props.phoneNumber
    if (phoneNumber.type === 'Main company number') return NumberTypeOption.MainCompanyNumber
    if (phoneNumber.type === 'Fax') return NumberTypeOption.FaxLine
    // if (phoneNumber.type === 'Direct Number') return NumberTypeOption.UserNumber
    if (['Voicemail', 'Menu', 'Users', 'Multiple rules set', 'External', 'Receptionist Service', 'AI-Connect'].includes(phoneNumber.type)) return NumberTypeOption.Other
}

const getRulesGroupType = (rulesGroup): string => {
    const groupType = rulesGroup.type
    if (groupType === 'all') return ScheduleOption.ClosedHours
    if (groupType === 'schedule') {
        const scheduleName = rulesGroup.schedule.name
        return {
            'open-hours': ScheduleOption.OpenHours,
            holidays: ScheduleOption.Holidays,
            'custom-holidays': ScheduleOption.ClosedHours,
            'lunch-break': ScheduleOption.LunchBreak
        }[scheduleName]
    }
}

const addMenuRules = (props, groupRules, numberRulesGroup): void => {
    groupRules.onCallOption = OnCallOption.Menu
    groupRules.menu.menuActions = {}
    const playMenuAction = numberRulesGroup.rules.actions.find((a): boolean => a.type === 'play-menu')
    const playMenuData = playMenuAction.data

    const addGreeting = (): void => {
        const responseMenuGreeting = playMenuData.greeting
        if (responseMenuGreeting.type === 'tts') {
            groupRules.menu.greeting = new TTS(
                responseMenuGreeting.id,
                responseMenuGreeting.text,
                'Joanna',
                responseMenuGreeting.download_link
            )
        } else {
            groupRules.menu.greeting = new UploadAudio(
                responseMenuGreeting.id,
                responseMenuGreeting.name,
                responseMenuGreeting.download_link
            )
        }
    }

    const addOptions = (): void => {
        const options = playMenuData.options
        Object.keys(options).forEach((mo): void => {
            if (['s', 'i'].includes(mo)) return
            groupRules.menu.actions[mo] = 'vm'
            let optionRule = options[mo]
            if (!optionRule) return
            // Currently for menu options we support only 1 rule group ('all') that's why we have `[0]`
            optionRule = optionRule.rule_groups[0].rules
            const ruleTypes = optionRule.rule_types
            const ringUsersOptionAction = optionRule.actions.find(a => a.type === 'ring-users')
            const extensionIds = ringUsersOptionAction ? ringUsersOptionAction.data.extensions.map(e => e.extension_id) : null
            let transferExtensionIds = []
            if (ruleTypes.includes('ring-users') && extensionIds && !extensionIds.includes(NaN)) {
                transferExtensionIds = extensionIds
                groupRules.menu.actions[mo] = 'ring'
            }
            const voicemailOptionAction = optionRule.actions.find(a => a.type === 'voicemail')
            if (!voicemailOptionAction) console.log('ROLLBAR LOG:', JSON.stringify(optionRule), JSON.stringify(props.phoneNumber))
            const inboxExtensionId = voicemailOptionAction.data.extension_id
            groupRules.menu.menuActions[mo] = `${transferExtensionIds.map(e => formatAnyKey(props, e)).join(',')}|${formatAnyKey(props, inboxExtensionId)}`
        })
    }

    const addOnNothingSelected = (): void => {
        let onNothingSelected = playMenuData.on_nothing_selected
        // Currently for 'on nothing selected' we support only 1 rule group ('all') that's why we have `[0]`
        onNothingSelected = onNothingSelected.rule_groups[0].rules
        if (onNothingSelected.rule_types.some(type => type === 'ring-users')) {
            groupRules.menu.onNothingSelected.option = OnNothingSelected.Forward
            const onNothingSelectedRingUsersAction = onNothingSelected.actions.find((a): boolean => a.type === 'ring-users')
            groupRules.menu.onNothingSelected.ringUsers = onNothingSelectedRingUsersAction.data.extensions.map((e): string => formatAnyKey(props, e.extension_id))
            const onNothingSelectedVoicemailAction = onNothingSelected.actions.find((a): boolean => a.type === 'voicemail')
            if (onNothingSelectedVoicemailAction) {
                const extensionId = onNothingSelectedVoicemailAction.data.extension_id
                groupRules.menu.onNothingSelected.voicemailUser = formatAnyKey(props, extensionId)
            }
        } else if (onNothingSelected.rule_types[0] === 'voicemail') {
            groupRules.menu.onNothingSelected.option = OnNothingSelected.Voicemail
            const onNothingSelectedVoicemailAction = onNothingSelected.actions.find((a): boolean => a.type === 'voicemail')
            const extensionId = onNothingSelectedVoicemailAction.data.extension_id
            groupRules.menu.onNothingSelected.voicemailUser = formatAnyKey(props, extensionId)
        } else if (onNothingSelected.rule_types[0] === 'disconnect') {
            groupRules.menu.onNothingSelected.option = OnNothingSelected.Disconnect
        }
    }

    addGreeting()
    addOptions()
    addOnNothingSelected()
}

const addRingUsersAndExternalRules = (props, groupRules, numberRulesGroup): void => {
    // ring-users and ring-external are different just in the first group with type 'ring-users' (forward)
    // so that for ring-users it has 1 or more extensions (users) in it and no phone numbers
    // and for ring-external it has no extensions (users) in it and only 1 number
    const rungUsersActions = numberRulesGroup.rules.actions.filter((a): boolean => a.type === 'ring-users')
    if (rungUsersActions[0].data.extensions.length) {
        const firstExtensionIds = rungUsersActions[0].data.extensions.map(e => e.extension_id)
        groupRules.forward[0].users = firstExtensionIds.map((extensionId): string => formatAnyKey(props, extensionId))
        groupRules.onCallOption = OnCallOption.Forward
    } else if (rungUsersActions[0].data.phone_numbers.length === 1) { // it has to have length 1 because it is validated
        const phoneNumberData = rungUsersActions[0].data.phone_numbers[0]
        groupRules.forwardExternal.phoneNumber = phoneNumberData.number
        groupRules.forwardExternal.callerId = phoneNumberData.caller_id === 'calling_number' ? CallerId.CallersID : CallerId.CalledNumber
        groupRules.forwardExternal.voiceTag = phoneNumberData.voice_tag || ''
        groupRules.forwardExternal.screening = phoneNumberData.screening
        groupRules.onCallOption = OnCallOption.ForwardExternal
    }
    if (rungUsersActions[1]) {
        const secondExtensionIds = rungUsersActions[1].data.extensions.map(e => e.extension_id) || []
        groupRules.forward[1].users = secondExtensionIds.map((extensionId): string => formatAnyKey(props, extensionId))
        groupRules.forward[1].enabled = true
    }
    const voicemailAction = numberRulesGroup.rules.actions.find((a): boolean => a.type === 'voicemail')
    if (voicemailAction) groupRules.assignVoicemailUser = formatAnyKey(props, voicemailAction.data.extension_id)
}

const addVoicemailRules = (props, extensionId, groupRules): void => {
    groupRules.onCallOption = OnCallOption.Voicemail
    groupRules.assignVoicemailUser = formatAnyKey(props, extensionId)
}

const addLiveAnswerRules = (RULES, props, groupRules, numberRulesGroup): void => {
    const liveAnswerAction = numberRulesGroup.rules.actions.find((a): boolean => a.type === 'live_answer')
    const rungUsersActions = numberRulesGroup.rules.actions.filter((a): boolean => a.type === 'ring-users')
    groupRules.onCallOption = (liveAnswerAction.data?.code === 19087 || liveAnswerAction.data?.code === 19088) ? OnCallOption.VirtualAnswer : (liveAnswerAction.data?.code === 19110 ? OnCallOption.LiveAnswerPlus : OnCallOption.LiveAnswer)
    RULES.liveAnswerConfiguration = {
        planId: liveAnswerAction.data?.code,
        callHandlingNotes: liveAnswerAction.data?.notes
    }
    RULES.scriptId = liveAnswerAction.data?.id
    // if ring-users is present, handle that
    if (rungUsersActions[0]?.data?.extensions?.length) {
        const firstExtensionIds = rungUsersActions[0].data.extensions.map(e => e.extension_id)
        groupRules.forward[1].users = firstExtensionIds.map((extensionId): string => formatAnyKey(props, extensionId))
        groupRules.forward[1].enabled = true
    }
    const voicemailAction = numberRulesGroup.rules.actions.find((a): boolean => a.type === 'voicemail')
    if (voicemailAction) groupRules.assignVoicemailUser = formatAnyKey(props, voicemailAction.data.extension_id)
}

const addMultipleRules = (props, RULES: any): any => {
    const areRulesSame = (schedule1Rules, schedule2Rules): boolean => {
        if (!schedule1Rules || !schedule2Rules) return false

        if (schedule1Rules.onCallOption !== schedule2Rules.onCallOption) return false

        if (!schedule1Rules.greeting.isEqualTo(schedule2Rules.greeting)) return false

        const onCallOption = schedule1Rules.onCallOption
        if (onCallOption === OnCallOption.Voicemail) {
            if (schedule1Rules.assignVoicemailUser !== schedule2Rules.assignVoicemailUser) return false
        } else if (onCallOption === OnCallOption.Forward) {
            if (!areArraysSame(schedule1Rules.forward[0].users, schedule2Rules.forward[0].users)) return false
            if (schedule1Rules.assignVoicemailUser !== schedule2Rules.assignVoicemailUser) return false
        } else if (onCallOption === OnCallOption.Menu) {
            // Compare menu greetings
            if (!schedule1Rules.menu.greeting.isEqualTo(schedule2Rules.menu.greeting)) return false
            // Compare menu options
            const lunchMenuActions = schedule1Rules.menu.menuActions
            const closedHoursMenuActions = schedule2Rules.menu.menuActions
            const lunchMenuOptions = Object.keys(lunchMenuActions)
            const closedHoursMenuOptions = Object.keys(closedHoursMenuActions)
            if (!areArraysSame(lunchMenuOptions, closedHoursMenuOptions)) return false
            if (lunchMenuOptions.some((menuOption): boolean => lunchMenuActions[menuOption] !== closedHoursMenuActions[menuOption])) return false
            // Compare menu on user nothing selected
            if (schedule1Rules.menu.onNothingSelected.option !== schedule2Rules.menu.onNothingSelected.option) return false
            const userNothingSelectedOption = schedule1Rules.menu.onNothingSelected.option
            if (userNothingSelectedOption === OnNothingSelected.Forward) {
                if (!areArraysSame(schedule1Rules.menu.onNothingSelected.ringUsers, schedule2Rules.menu.onNothingSelected.ringUsers)) return false
            } else if (userNothingSelectedOption === OnNothingSelected.Voicemail) {
                if (schedule1Rules.menu.onNothingSelected.voicemailUser !== schedule2Rules.menu.onNothingSelected.voicemailUser) return false
            } // else if (userNothingSelectedOption === OnNothingSelected.Disconnect) {}
        } else if (onCallOption === OnCallOption.ForwardExternal) {
            if (schedule1Rules.forwardExternal.phoneNumber !== schedule2Rules.forwardExternal.phoneNumber) return false
            if (schedule1Rules.forwardExternal.callerId !== schedule2Rules.forwardExternal.callerId) return false
            if (schedule1Rules.forwardExternal.voiceTag !== schedule2Rules.forwardExternal.voiceTag) return false
            if (schedule1Rules.forwardExternal.screening !== schedule2Rules.forwardExternal.screening) return false
        }
        return true
    }

    const customHolidaysSchedule = 'custom-holidays'
    const phoneNumber = props.phoneNumber
    const isMainCompanyNumber = phoneNumber.type === 'Main company number'
    const numberRulesGroups = isMainCompanyNumber ? props.companyExtensionRules : phoneNumber.call_rules.rule_groups
    if (!numberRulesGroups?.length) return
    const schedules = props.schedules
    let holidaysRulesGroup, lunchBreakRulesGroup, closedHoursRulesGroup
    numberRulesGroups.forEach((numberRulesGroup): any => {
        const numberRules = numberRulesGroup.rules
        const groupType = (schedules?.length && numberRulesGroups.length > 1) ? getRulesGroupType(numberRulesGroup) : ScheduleOption.OpenHours
        if (numberRulesGroup.type === 'schedule' && numberRulesGroup.schedule.name === customHolidaysSchedule) return
        const groupRules = RULES.onCallOptionGroups[groupType]
        const numberRuleTypes = numberRules.rule_types
        if (numberRuleTypes.includes('play-menu')) {
            addMenuRules(props, groupRules, numberRulesGroup)
        } else if (numberRuleTypes.includes('live_answer')) { // TODO: standardize this
            addLiveAnswerRules(RULES, props, groupRules, numberRulesGroup)
        } else if (numberRuleTypes.includes('ring-users')) {
            addRingUsersAndExternalRules(props, groupRules, numberRulesGroup)
        } else if (numberRuleTypes.includes('voicemail')) {
            const voicemailExtensionId = numberRules.actions.find((a): boolean => a.type === 'voicemail').data.extension_id
            addVoicemailRules(props, voicemailExtensionId, groupRules)
        }
        const playRecordingAction = numberRulesGroup.rules.actions.find((a): boolean => a.type === 'play-recording')
        if (playRecordingAction) {
            groupRules.hasGreeting = true
            const responseGreeting = playRecordingAction.data
            if (responseGreeting.type === 'tts') {
                groupRules.greeting = new TTS(
                    responseGreeting.id,
                    responseGreeting.text,
                    'Joanna',
                    responseGreeting.download_link
                )
            } else {
                groupRules.greeting = new UploadAudio(
                    responseGreeting.id,
                    responseGreeting.name,
                    responseGreeting.download_link
                )
            }
        }
        if (groupType === ScheduleOption.Holidays) holidaysRulesGroup = groupRules
        if (groupType === ScheduleOption.LunchBreak) lunchBreakRulesGroup = groupRules
        if (groupType === ScheduleOption.ClosedHours) closedHoursRulesGroup = groupRules
    })
    if (schedules?.length) {
        RULES.onCallOptionGroups[ScheduleOption.LunchBreak].sameAsClosedHours = numberRulesGroups.length === 1 || areRulesSame(lunchBreakRulesGroup, closedHoursRulesGroup)
        RULES.onCallOptionGroups[ScheduleOption.Holidays].sameAsClosedHours = numberRulesGroups.length === 1 || areRulesSame(holidaysRulesGroup, closedHoursRulesGroup)
    }
    if (isMainCompanyNumber || numberRulesGroups.length > 1) RULES.useSchedules = true
}

const addUserNumberRules = (props, RULES): void => { /* Handled above - is same as the forwarding extension */ }
const addFaxLineRules = (props, RULES): void => { /* Handled above - is same as the forwarding extension */ }

/**
 * Get the current number rule
 *
 * @param {ConfugureNumberProps} props
 */
export const getCurrentNumberRule = (props: ConfugureNumberProps): RulesProps => {
    const RULES = getDefaultRules(props)
    if (shouldNotProcessRules(props)) return RULES

    setAssignTo(props, RULES)

    const numberType: NumberTypeOption = getPhoneNumberType(props)
    RULES.numberTypeOption = numberType

    const addRulesFunctions = {
        [NumberTypeOption.MainCompanyNumber]: addMultipleRules,
        [NumberTypeOption.UserNumber]: addUserNumberRules,
        [NumberTypeOption.FaxLine]: addFaxLineRules,
        [NumberTypeOption.Other]: addMultipleRules
    }

    addRulesFunctions[numberType](props, RULES)
    return RULES
}
