<template>
    <v-container fluid class="pa-0 w-100">
        <v-row>
            <v-col md="12">
                <Line1
                    v-model="val.line_1"
                    :disabled="disabled"
                    :rules="is_answernet ? [$validation.required()] : []"
                    hide-details="auto"
                    autocomplete="new-password"
                    data-lpignore="true"
                    data-test-id="lr-street-address-input"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12">
                <Line2
                    v-model="val.line_2"
                    :disabled="disabled"
                    hide-details="auto"
                    autocomplete="new-password"
                    data-lpignore="true"
                    data-test-id="lr-street-address-2-input"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-md-flex pr-0 w-100">
                <City
                    v-model="val.city"
                    :disabled="disabled"
                    :rules="is_answernet ? [$validation.required()] : []"
                    hide-details="auto"
                    autocomplete="new-password"
                    data-lpignore="true"
                    data-test-id="lr-city-input"
                    :class="{'w-60': $vuetify.breakpoint.mdAndUp}"
                />
                <State
                    v-model="val.province"
                    :country="val.country"
                    :disabled="disabled"
                    :rules="is_answernet ? [$validation.required()] : []"
                    hide-details="auto"
                    autocomplete="new-password"
                    data-lpignore="true"
                    data-test-id="lr-state-input"
                    :class="{'w-40 ml-3': $vuetify.breakpoint.mdAndUp}"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-md-flex pr-0">
                <Zip
                    :country="val.country"
                    v-model="val.postal_code"
                    :disabled="disabled"
                    :rules="is_answernet ? [$validation.required()] : []"
                    hide-details="auto"
                    autocomplete="new-password"
                    data-lpignore="true"
                    data-test-id="lr-zip-input"
                    :class="{'w-40': $vuetify.breakpoint.mdAndUp}"
                />
                <Country
                    :disabled="disabled"
                    :available_countries="['US', 'CA']"
                    :value="val.country"
                    :rules="is_answernet ? [$validation.required()] : []"
                    @input="val.country = $event"
                    data-test-id="lr-country-selector"
                    :class="{'w-60 ml-3': $vuetify.breakpoint.mdAndUp}"
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import {emptyAddress} from 'helpers'
    import LiveAnswerVendor from 'console/src/models/LiveAnswerVendor'
    import Line1 from 'console/src/components/elements/form/address/Line1.vue'
    import Line2 from 'console/src/components/elements/form/address/Line2.vue'
    import City from 'console/src/components/elements/form/address/City.vue'
    import State from 'console/src/components/elements/form/address/State.vue'
    import Zip from 'console/src/components/elements/form/address/Zip.vue'
    import Country from 'console/src/components/elements/form/address/Country.vue'
  export default {
    props: {
        value: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        vendor: {
            type: Object,
            required: true,
        }
    },
    components: {
        Line1, 
        Line2,
        City,
        Zip,
        State,
        Country,
    },
    data() {
      return {
        val: this.$props.value || {...emptyAddress(), country: 'US'},
      };
    },
    computed: {
        is_answernet() {
            return LiveAnswerVendor.vendor_group(this.$props.vendor) === 'ANSWERNET'
        },
    },
    watch: {
        val: {
            deep: true,
            handler: function(v) {
                this.$emit('input', v);
            }
        }
    }
  };
</script>

<style scoped>

</style>