<template>
    <div v-if="closable || allow_short_code" class="d-flex">
        <w-text-field
            type="tel"
            v-model="phone_number"
            @keydown="on_key_down"
            :placeholder="computed_placeholder"
            :disabled="disabled"
            :label="label"
            :maxlength="(clean_number(phone_number).length > 12) && maxlength"
            :rules="rules"
            hide-details='auto'
            data-test-id="phone-number-input"
        />
        <RemoveFromListButton v-if="Object.keys(more_options).length === 1 && more_options[0].title === l.t('app.remove', 'Remove')" @click="more_options[0].cb()" class="ml-3 mt-2" icon/>
        <MoreOptions
            v-else-if="Object.keys(more_options).length > 1"
            :options="more_options"
            :activator_classes="helper_buttons_classes"
        />
        <w-btn
            v-else-if="more_options.length"
            @click="more_options[0].cb()"
            class="input-helper-btn"
            icon
        >
          <v-icon :color="more_options[0].icon === '$vuetify.icons.trash' ? 'error' : 'text'" size="18">{{more_options[0].icon}}</v-icon>
        </w-btn>
        <a v-if="allow_short_code" @click="short_code_on = !short_code_on" class="pt-3 ml-3 my-auto">
            {{ short_code_on ? l.t('tel.use-phone-number', 'Regular number') : l.t('tel.use-short-code', 'Short code') }}
        </a>
    </div>
    <div class="d-flex" v-else>
      <w-text-field
        type="tel"
        @keydown="on_key_down"
        v-model="phone_number"
        :placeholder="computed_placeholder"
        :disabled="disabled"
        :rules="rules"
        :maxlength="(clean_number(phone_number).length > 12) && maxlength"
        :label="label"
        ref="tel_input"
        hide-details='auto'
        data-test-id="phone-number-input"
      />
       <MoreOptions
           v-if="phone_number"
           class="my-auto"
           :options="more_options"
           :activator_classes="helper_buttons_classes"
       />
    </div>
</template>

<script>
import {formatPhoneNumberAsYouType} from 'formatters';
import l from '../../libs/lang';
import RemoveFromListButton from './RemoveFromListButton.vue';
import MoreOptions from './MoreOptions.vue';

const filter_and_format_phone_number = (num) => formatPhoneNumberAsYouType(num);
export default {
    props: ['value', 'required', 'allow_short_code', 'disabled', 'closable', 'validation', 'label', 'placeholder', 'additional_options', 'maxlength', 'readonly'],
    components: {
      RemoveFromListButton,
      MoreOptions
    },
    data() {
      return {
        l,
        phone_number: null,
        short_code_on: false,
        rules: [],
        helper_buttons_classes: 'ml-3 mt-2 selector-helper-btn',
        more_options: [],

      };
    },
  created() {
      if (this.$props.allow_short_code && this.$props.value && (this.$props.value.length >= 4) && this.$props.value.length <= 6) {
        this.$data.short_code_on = true;
      }
      if (this.$props.value && !this.$data.short_code_on) {
        this.$data.phone_number = filter_and_format_phone_number(this.$props.value);
      } else if (this.$props.value && this.$data.short_code_on) {
        this.$data.phone_number = this.$props.value;
      } else {
        this.$data.phone_number = '';
      }
      if (this.$props.required) this.$data.rules.push(this.$validation.required());
      if (this.$props.validation && !this.$data.short_code_on) this.$data.rules.push(this.$validation.phone_number());
      this.$data.more_options = this.generate_options();
    },
    methods: {
      clean_number(number) {
        if (!number) return number;
        return number.replace(/[- )(]/g, '').trim();
      },
      on_key_down(e) {
        if (this.is_ctrl_pressed(e) && [88, 86, 67, 65].includes(e.keyCode)) { // ctrl + a, x, c, v
          return null;
        }
        if (e.key === '+') return null; // key codes made problems on mac
        if (!e.key) {
          e.preventDefault();
          return null;
        }
        if (
          e.key.length === 1
          && ![
            8, // 8 => backspace
            35, 36, // 36, 36 => home, end
            37, 39, // 37, 39 => left, right arrow
            46, // 46 => delete
            48, 49, 50, 51, 52, 53, 54, 55, 56, 57, // number 0-9
            96, 97, 98, 99, 100, 101, 102, 103, 104, 105, // 96-105 => numberic keyboard numbers
            107, 109, // 107, 109 => +, -
          ].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
        this.set_cursor_position(e);
        this.$data.more_options = this.generate_options();

        return true;
      },
      set_cursor_position(e) {
        if ([
          8, 46, // backspace, delete
          48, 49, 50, 51, 52, 53, 54, 55, 56, 57, // number 0-9
          96, 97, 98, 99, 100, 101, 102, 103, 104, 105,
        ].includes(e.keyCode)) {
          const el = this.$refs.tel_input ? this.$refs.tel_input.$el.querySelector('input') : null;
          let cursor_position = el ? el.selectionStart : null;
          const cursor_position_end = el ? el.selectionEnd : null;
          const prev_value = this.phone_number;
          if (el && prev_value !== null && prev_value.length !== cursor_position) {
            setTimeout(() => {
              if (e.keyCode === 8) {
                if (!prev_value.startsWith('+1') && this.$data.phone_number.startsWith('+1')) {
                  cursor_position = 2;
                } else if (!/[ ()\\-]/.test(prev_value) && /[ ()\\-]/.test(this.phone_number)) {
                  const looking_for = prev_value.substring(0, cursor_position - 1);
                  for (let i = 0; i < this.phone_number.length; i++) {
                    if (
                      this.$data.phone_number.substring(0, i).replace(/[^0-9+]/g, '') === looking_for
                      && !Number.isNaN(this.$data.phone_number[i])
                    ) {
                      cursor_position = i;
                      break;
                    }
                  }
                } else {
                  cursor_position -= 1;
                }
              } else if (e.keyCode !== 46) {
                if (this.$data.phone_number !== '+1' && this.$data.phone_number === this.$data.phone_number.replace(/[^0-9+]/g, '')) {
                  const substring = prev_value.substring(0, cursor_position);
                  cursor_position = substring.replace(/[^0-9+]/g, '').length + 1;
                } else if (
                  cursor_position_end
                  && cursor_position_end !== cursor_position
                ) {
                    cursor_position += 1;
                } else {
                    if ((!prev_value || (prev_value && !prev_value.startsWith('+1'))) && this.$data.phone_number.startsWith('+1')) {
                      cursor_position += 1;
                  }
                  const add = this.phone_number.length - prev_value.length;
                  cursor_position += add > 0 ? 1 : add;
                  if (this.phone_number[cursor_position] === ')') {
                    cursor_position += 2;
                  } else if (this.phone_number[cursor_position] === ' ') {
                    cursor_position += 2;
                  } else if (this.phone_number[cursor_position] === '-') {
                    cursor_position += 1;
                  } else if (
                    this.phone_number[cursor_position - 1] !== '+'
                    && ['-', ' '].includes(this.phone_number[cursor_position - 1])
                  ) {
                    cursor_position += 1;
                  }
                }
              }

              el.setSelectionRange(cursor_position, cursor_position);
            }, 1);
          }
        }
      },
      is_apple() {
        const expression = /(Mac|iPhone|iPod|iPad)/i;
        return expression.test(navigator.platform);
      },
      is_ctrl_pressed(event) {
          if (this.is_apple()) {
              return event.metaKey;
          }
          return event.ctrlKey;
      },
      generate_options() {
        const options = []
        if (this.$props.closable && !this.$props.readonly) {
          options.push({
              title: l.t('app.remove', 'Remove'),
              icons: '$vuetify.icons.trash',
              cb: () => this.$emit('closed'),
          })
        }
        if (this.$data.phone_number && Array.isArray(this.$props.additional_options)) {
          for (const opt of this.$props.additional_options) options.push(opt);
        }
        return options;
      },
    },
    watch: {
      required(val) {
        if (val) this.$data.rules.push(this.$validation.required());
        else this.$data.rules = [];
      },
      phone_number(val) {
        let value = val ? val.replace(/[a-zA-Z]/g, '') : val;
        if (!this.$data.short_code_on) {
          value = filter_and_format_phone_number(value);
        }
        this.$data.phone_number = value;

        this.$emit('changed', {
          value,
          clean_number: this.clean_number(value),
        });
      },
      short_code_on(val) {
        this.$data.rules = this.$props.required ? [this.$validation.required()] : [];
        if (val) {
            this.$data.phone_number = this.$data.phone_number.split('-').join('').split('+1').join('');
        } else {
          if (this.$props.validation) this.$data.rules.push(this.$validation.phone_number());
          this.$data.phone_number = filter_and_format_phone_number(this.$data.phone_number);
        }
        this.$emit('changed', {
            value: this.$data.phone_number,
            clean_number: this.clean_number(this.$data.phone_number),
        });
      },
    },
    computed: {
      computed_placeholder() {
        if (this.$props.label) return undefined;
        if (this.$data.short_code_on) return '555123';
        return this.$props.placeholder || '+1 (555) 555-1234';
      },
    }
};
</script>
