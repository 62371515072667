import USStates from '../../../../../util/us-states/USStates'
import CanadaProvinces from '../../../../../util/canada-provinces/CanadaProvinces'

const languageOptions = [
    {
        value: 'english',
        content: 'English'
    },
    {
        value: 'spanish',
        content: 'Spanish'
    }
]

const voiceOptions = {
    english: [
        {
            value: 'joanna',
            content: 'Joanna'
        },
        {
            value: 'joey',
            content: 'Joey'
        },
        {
            value: 'kendra',
            content: 'Kendra'
        },
        {
            value: 'kimberly',
            content: 'Kimberly'
        },
        {
            value: 'matthew',
            content: 'Matthew'
        },
        {
            value: 'salli',
            content: 'Salli'
        }
    ],
    spanish: [
        {
            value: 'lupe',
            content: 'Lupe'
        },
        {
            value: 'penelope',
            content: 'Penelop'
        },
        {
            value: 'miguel',
            content: 'Miguel'
        }
    ]
}

const stateOptions = USStates.map(s => ({ content: s.name, value: s.abbreviation }))

const provinceOptions = CanadaProvinces.map(p => ({ content: p.name, value: p.abbreviation }))

const conutryOptions = [
    {
        content: 'United States',
        value: 'US'
    },
    {
        content: 'Canada',
        value: 'CA'
    }
]

/**
 *
 */
export { languageOptions, voiceOptions, stateOptions, provinceOptions, conutryOptions }
