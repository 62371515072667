import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=8843%3A20778
 *
 * @param {SvgIconProps} props - svg props
 */
export const CallOutgoingIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M6.32437 11.3725C7.62411 14.1638 9.79426 16.481 12.4878 17.982L14.6942 15.9244C14.965 15.6719 15.3557 15.5982 15.6909 15.7263C16.7637 16.1228 17.9296 16.3576 19.1313 16.3995C19.6643 16.4181 20.0852 16.8695 20.0665 17.4025L19.9484 20.7846C19.9298 21.3177 19.4785 21.7385 18.9455 21.7199C9.84558 21.4021 2.72822 13.7697 3.04599 4.66979C3.06461 4.13678 3.51593 3.71591 4.04894 3.73453L7.44081 3.85297C7.97382 3.87159 8.39469 4.32291 8.37608 4.85592C8.33377 6.0673 8.48698 7.237 8.80765 8.33492C8.90241 8.67783 8.81168 9.05307 8.53086 9.31495L6.32437 11.3725Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M15.7555 5.0087C15.4677 5.00653 15.2315 4.77099 15.2286 4.48315L15.2179 3.45049C15.2149 3.16321 15.4454 2.93274 15.7327 2.93571L20.8093 3.00101C21.0966 3.00398 21.3319 3.23927 21.3348 3.52654L21.4002 8.60503C21.4031 8.89159 21.1738 9.12177 20.8872 9.11982L19.848 9.11275C19.5591 9.11079 19.3224 8.87386 19.3206 8.58503L19.2956 5.78344L14.656 10.4231C14.5584 10.5207 14.4001 10.5207 14.3025 10.4231L13.9128 10.0334C13.8152 9.93577 13.8152 9.77747 13.9128 9.67984L18.5534 5.03921L15.7555 5.0087Z' fill={color}/>
        </SvgIcon>
    )
}

export default CallOutgoingIcon
