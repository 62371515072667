/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable jsdoc/require-description */
import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { PhoneNumber } from 'models/src/phone-numbers/PhoneNumber'

// const REACT_APP_USER_API_URL = process.env.REACT_APP_USER_API_URL
const PAGE_LIMIT = 100
// const DEFAULT_CURSOR = 'default'

/**
 *
 */
export class PhoneNumbers {
    items: Map<number, PhoneNumber> = new Map()
    hasMore = false
    total = 0
    cursor: string = PhoneNumbers.encodeCursor(PAGE_LIMIT, 0, '', false)

    /**
     * @param {PhoneNumbers | null} t
     */
    constructor (t: PhoneNumbers | null = null) {
        if (t != null) this.update(t)
    }

    /**
     * @param cursor
     */
    static load = async (cursor: string) : Promise<PhoneNumbers> => {
        const { page_size, offset, didFilter, hasMore } = PhoneNumbers.decodeCursor(cursor)
        const phoneNumbers = new PhoneNumbers()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/list-phone-numbers-2-2`
        const response: any = await ajax.post(requestUrl, { limit: 500 })
        if (response.message === 'Network Error') {
            console.log('phoneNumbers error: ', didFilter, response)
        } else {
            const items = response?.data?.items
            if (!items) return phoneNumbers
            items.forEach(t => {
                t = PhoneNumber.fromJson(t)
                phoneNumbers.items.set(t.id, t)
            })
            phoneNumbers.total = phoneNumbers.items.size
            phoneNumbers.hasMore = phoneNumbers.total === page_size
            phoneNumbers.cursor = PhoneNumbers.encodeCursor(page_size, offset + page_size, didFilter, hasMore)
        }
        return phoneNumbers
    }

    /**
     * @param {number} page_size
     * @param {number} offset
     * @param {string} didFilter
     * @param {boolean} hasMore
     */
    static encodeCursor = (page_size: number, offset: number, didFilter: string, hasMore: boolean): string => {
        return page_size.toString() + '@' + offset.toString() + '@' + (didFilter + ' ') + '@' + hasMore
    }

    /**
     * @param {string} cursor
     */
    static decodeCursor = (cursor: string): any => {
        const s = cursor.split('@')
        if (s.length !== 4) return { page_size: 0, offset: 0, didFilter: '', hasMore: false }
        return {
            page_size: Number.parseInt(s[0]),
            offset: Number.parseInt(s[1]),
            didFilter: s[2].trim(),
            hasMore: s[3] === 'true'
        }
    }

    /**
     * @param updated
     */
    update (updated: PhoneNumbers) : PhoneNumbers {
        if (updated != null) return this
        const combined = new PhoneNumbers()
        combined.items = this.items
        updated.items.forEach((v, k) => {
            this.items.set(k, v)
        })
        combined.hasMore = updated.hasMore
        combined.cursor = updated.cursor
        combined.total = combined.items.size
        return combined
    }

    /**
     * @param t
     */
    add (t: PhoneNumber) : void {
        this.items.set(t.id, t)
        this.total += 1
    }

    /**
     * @param {number} t_id
     */
    remove (t_id: number) : void {
        this.items.delete(t_id)
        this.total -= 1
    }

    /**
     * @param {string} didFilter
     */
    filter = (didFilter: string): PhoneNumbers => {
        const filtered = new PhoneNumbers()
        this.items.forEach((phoneNumber: PhoneNumber) => {
            didFilter = didFilter.toString()
            if (phoneNumber.phone_number === didFilter) filtered.add(phoneNumber)
        })
        filtered.total = filtered.items.size
        filtered.cursor = PhoneNumbers.encodeCursor(PAGE_LIMIT, 0, didFilter, true)
        return filtered
    }
}
