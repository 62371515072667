const getFullStoryUrl = () => {
	if (!window.FS || typeof window.FS.getCurrentSessionURL !== 'function') return null
	return window.FS.getCurrentSessionURL(true)
}

const setRollbar = data => {
	if (!window.Rollbar) return

	let personData = {
		id: data.account_id, // required
		voip_id: data.account_id,
		voip_phone_id: data.extension_id,
		account_name: data.account.name
	}

	window.Rollbar.configure({
		payload: {
			person: personData,
			fullstory_url: getFullStoryUrl()
		}
	})
}

export { setRollbar }