<template>
  <div class="vertical-table" v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, i) of items">
      <div
        v-if="item.title"
        class="w-body-1 info--text text--darken-1 vertical-table-row vertical-table-title"
        :key="`vertical-table-row-i-${i}`"
      >
        {{item.text}}
      </div>
      <div v-else-if="item.divider" class="vertical-table-divider" :key="`vertical-table-row-i-${i}`"></div>
      <div
        v-else
        class="d-flex justify-evenly vertical-table-row items"
        :key="`vertical-table-row-i-${i}`"
      >
        <div class="w-subtitle-3 font-weight-500 info--text text--darken-1" :style="{width: '50%'}">{{item.text}}</div>
        <div class="w-caption" :style="{width: '50%'}">{{item.value}}</div>
      </div>
    </template>
    <div v-if="additional_rows" class="d-flex justify-evenly vertical-table-row items">
      <div
        v-for="(slot, i) in Object.keys(additional_rows)"
        :class="{
          'w-subtitle-3 font-weight-500 info--text text--darken-1': slot.includes('text'),
          'w-caption': slot.includes('value'),
        }"
        :style="{width: '50%'}"
        :key="`slot-${i}`"
      >
        <slot :name="slot" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
        items: {
            required: true,
            type: Array,
            validator: (val) => {
              return (
                val.every((x) => x.divider || (
                  Object.prototype.hasOwnProperty.call(x, 'text') && (
                    Object.prototype.hasOwnProperty.call(x, 'value') || Object.prototype.hasOwnProperty.call(x, 'title')
                  )
                ))
              )
            },
        },
        title: {
            type: String,
        }
    },
    computed: {
      additional_rows() {
        const additional_rows_keys = Object.keys(this.$slots).filter((x) => x.startsWith('additional_row_'));
        if (additional_rows_keys.length) {
          const slots = {};
          additional_rows_keys.map((x) => slots[x] = this.$slots[x]);

          return slots;
        }
        return null;
      },
    }
  };
</script>

<style lang="scss" scoped>
    .vertical-table {
        .vertical-table-row {
            padding: 8px 0px;
            align-items: center;
        }
        .vertical-table-title {
          border-top: 2px solid var(--v-info-base);
          &:not(:first-child) {
            margin-top: -1px;
          }
        }
        .items {
          box-shadow: inset 0px -1px 0px rgba(133, 146, 154, 0.2);
          height: 50px;
        }
        .vertical-table-divider {
          box-shadow: inset 0px -1px 0px var(--v-info-lighten1);
          height: 1px;
          width: 100%;
          margin-top: -1px;
        }
    }
</style>
