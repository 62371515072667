import React from "react";
import FilterButton from "../filter-button/filter-button"
import HoverBoxWrapper from "../hover-box-wrapper/hover-box-wrapper"
import NpaSelector from "./npa-selector/npa-selector"


class NpaFilter extends React.Component {

  constructor(props) {
    super(props);
    this.tollfreeNpas = ['800', '833', '844', '855', '866', '877', '888'];
    this.initialState = {
      selectedNpas: [],
      npaSelectorOpen: false
    };
    this.state = JSON.parse(JSON.stringify(this.initialState));
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  // Hide NpaSelector if click away from component
  handleClick = e => {
    if (this.node.contains(e.target)) {
      // Do nothing - click was within component
      return;
    }
    else {
      this.setState({npaSelectorOpen: false});
    }
  };

  toggleNpaSelector = () => {
    if (this.props.all_area_codes.length === 0 ) return
    this.setState({
       npaSelectorOpen: !this.state.npaSelectorOpen
    })
  };


  handleCheckbox = (value, checked) => {
    const npa = value;
    if (!checked) {
      if (this.state.selectedNpas.length >= 3) {
        return;
      }
      this.setState({
        selectedNpas: this.state.selectedNpas.concat(npa)
      })
    }
    else {
      this.setState({
        selectedNpas: this.state.selectedNpas.filter(item => item !== npa)
      })
    }
  };

  handleSelectChange = (value) => {
    if (value == ""){
      this.setState({
        selectedNpas: []
      })
      this.props.updateSelectedNpas([])
    }
    else{
      if (this.props.availableAreaCodes.length > 0){
        if (!this.props.availableAreaCodes.includes(parseInt(value))){
          this.props.handleResetCity()
        }
      }
      this.setState({
        selectedNpas: [value]
      })
      this.props.updateSelectedNpas([value])

    }
    this.toggleNpaSelector()
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.reset !== prevProps.reset || this.props.resetNpa !== prevProps.resetNpa ) {
      this.setState(
        JSON.parse(JSON.stringify(this.initialState))
      )
      this.props.updateSelectedNpas([])
    }
    if (this.state.selectedNpas !== prevState.selectedNpas) {
      this.props.handleNpaFilterUpdate(this.state.selectedNpas);
    }
  }

  render() {

    const child = <NpaSelector
      selectedNpas={this.state.selectedNpas}
      allNpas={this.props.all_area_codes.map(e => e.toString())}
      filteredNpas={this.props.availableAreaCodes.map(e => e.toString())}
      handleSelectChange={this.handleSelectChange}
      handleCheckbox={this.handleCheckbox}
      AreaCodeSearch={this.props.AreaCodeSearch}
      invalidAreaCodeMessage={this.props.invalidAreaCodeMessage}
    />;

    const hoverbox=<HoverBoxWrapper
      children={child}
      open={this.state.npaSelectorOpen}
    />


    return (
      <div
        ref={node => this.node = node}
        style={{'position': 'relative'}}
      >
        <FilterButton
          data-test-id={'npa-filter'}
          selectedFilters={this.state.selectedNpas}
          handleClick={this.toggleNpaSelector}
          handleIconClick={this.state.selectedNpas.length == 0 ? this.toggleNpaSelector : this.props.handleResetNpa}
          defaultText={"Area Code"}
          open={this.state.npaSelectorOpen}
          hoverbox={hoverbox}
          loading={this.props.all_area_codes.length === 0}
        />

      </div>
    );
  }

}

export default NpaFilter;
