import React, { Component } from 'react'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import ForwardToANumberIcon from './icons/ForwardToANumberIcon'
import Section from './Section'
import Typography from 'typography'
import gtmDataPush from 'gtm-events'
import TextField, { FormatType as TextFieldFormatType } from 'text-field-mui'
import { convertNumberToE164, isValidPhoneNumber } from 'phone-numbers'
import { EditIcon } from 'svg-icons'
import ConfirmModal from 'confirm-modal'
import RemoteInfoTip from 'remote-info-tip'
import { Switch } from 'switch'
import { CallerId } from './current_rules'
import RemoteConfigValue from 'remote-config-value'

const GtmAppName = 'configure-number'

/***/
export enum CallUsersType {
    PARALLEL = 'parallel',
    SEQUENTIAL = 'sequential'
}

interface Props {
    classes: any
    selectedScheduleOption: any
    onCallOptionGroups: any
    users: Array<any>
    showValidationErrors: boolean
    formatUserString: (u: any) => string
    updateOnCallOptionGroups: (a: any) => void
    screenViewType: any
    companyExtension: any
}

interface State {
    draggingUserId: number
    dragDownOnIndex: number
    dragUpOnIndex: number
    showForwardNumberOptions: boolean
    callerIdCopy: CallerId
    voiceTagCopy: string
    screeningCopy: boolean
}

class ForwardExternalSection extends Component<Props, State> {
    /**
     *
     */
    constructor (props: Props) {
        super(props)
        const forwardExternal = props.onCallOptionGroups[props.selectedScheduleOption].forwardExternal
        this.state = {
            draggingUserId: null,
            dragDownOnIndex: -1,
            dragUpOnIndex: -1,
            showForwardNumberOptions: false,
            callerIdCopy: forwardExternal.callerId,
            voiceTagCopy: forwardExternal.voiceTag,
            screeningCopy: forwardExternal.screening
        }
    }

    renderForwardOptions = (): JSX.Element => {
        if (!this.state.showForwardNumberOptions) return null
        const { classes, screenViewType, selectedScheduleOption } = this.props
        const { isMobileView } = screenViewType
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        const close = (onCancel = true) => {
            if (onCancel) {
                const callerIdChange = this.state.callerIdCopy !== onCallOptionGroup.forwardExternal.callerId
                const voiceTagChange = this.state.voiceTagCopy !== onCallOptionGroup.forwardExternal.voiceTag
                const screeningChange = this.state.screeningCopy !== onCallOptionGroup.forwardExternal.screening
                // eslint-disable-next-line
                gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'forward-external-number-edit-on-cancel', PDC_Value: `${callerIdChange};${voiceTagChange};${screeningChange}` })
                const forwardExternal = this.props.onCallOptionGroups[this.props.selectedScheduleOption].forwardExternal
                this.setState({
                    showForwardNumberOptions: false,
                    callerIdCopy: forwardExternal.callerId,
                    voiceTagCopy: forwardExternal.voiceTag,
                    screeningCopy: forwardExternal.screening
                })
            }
            this.setState({ showForwardNumberOptions: false })
        }
        const storeChanges = () => {
            const { callerIdCopy, voiceTagCopy, screeningCopy } = this.state
            // eslint-disable-next-line
            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'forward-external-number-edit-on-done', PDC_Value: `${callerIdCopy};${!!voiceTagCopy};${screeningCopy}` })
            const forwardExternal = { ...onCallOptionGroup.forwardExternal }
            forwardExternal.callerId = callerIdCopy
            forwardExternal.voiceTag = voiceTagCopy
            forwardExternal.screening = screeningCopy
            onCallOptionGroup.forwardExternal = forwardExternal
            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
            this.props.updateOnCallOptionGroups(onCallOptionGroups)
            close(false)
        }
        const content = (
            <div className={`${classes.forwardOptionsModalContent} ${isMobileView ? 'mobile' : ''}`}>
                <div className='caller-id-section'>
                    <div className='title-wrapper'>
                        <Typography variant='body1' remoteConfigID='configure_number_forward_external_modal_caller_id_text'/>
                        <RemoteInfoTip id='configure.numbers.edit-number.call-handling.caller-id'/>
                    </div>
                    <Select
                        value = {this.state.callerIdCopy}
                        onChange = {e => this.setState({ callerIdCopy: e.target.value as CallerId })}
                        formControlClasses = {{ root: 'caller-id-select' }}
                    >
                        <MenuItem
                            value = {CallerId.CallersID}
                            checked = {this.state.callerIdCopy === CallerId.CallersID}
                        >Caller&apos;s ID</MenuItem>
                        <MenuItem
                            value = {CallerId.CalledNumber}
                            checked = {this.state.callerIdCopy === CallerId.CalledNumber}
                        >Called Number</MenuItem>
                    </Select>
                </div>
                <div className='voice-tag-section'>
                    <div className='title-wrapper'>
                        <Typography remoteConfigID='configure_number_forward_external_modal_voice_tag_text'/>
                        <RemoteInfoTip id='configure.numbers.edit-number.call-handling.voice-tag'/>
                    </div>
                    <TextField
                        fullWidth
                        label = 'e.g. Call from business line'
                        value = {this.state.voiceTagCopy}
                        onChange = {e => this.setState({ voiceTagCopy: e.target.value.substring(0, 40) })}
                        onXClick = {() => this.setState({ voiceTagCopy: '' })}
                        inputProps = {{ maxlength: 40 }}
                        noLabelOnActiveOrResolved
                    />
                </div>
                <div className='screening-section'>
                    <div className='title-wrapper'>
                        <Switch
                            label={<RemoteConfigValue valueId='configure_number_forward_external_modal_screening_label'/>}
                            checked={this.state.screeningCopy}
                            onChange={(e, checked: boolean) => this.setState({ screeningCopy: checked })}
                            name='screening-status'
                            value={this.state.screeningCopy ? '1' : '0'}
                        />
                        <RemoteInfoTip id='configure.numbers.edit-number.call-handling.call-screening'/>
                    </div>
                </div>
            </div>
        )
        return (
            <ConfirmModal
                title = {<Typography variant='h5' remoteConfigID='configure_number_forward_external_modal_title'/>}
                isShown
                content = {content}
                noButtonText = 'Cancel'
                yesButtonText = 'Done'
                onReject = {close}
                onConfirm = {storeChanges}
                size = {isMobileView ? 'sizeMobile' : 'size550'}
            />
        )
    }

    renderMainSection = (): JSX.Element => {
        const { classes, screenViewType } = this.props
        const { isMobileView } = screenViewType
        const selectedScheduleOption = this.props.selectedScheduleOption
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        const mobileClass = isMobileView ? 'mobile' : ''
        const onChange = (phoneNumber: string): void => {
            // eslint-disable-next-line
            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'forward-external-number', PDC_Value: !!phoneNumber })
            const forwardExternal = { ...onCallOptionGroup.forwardExternal }
            forwardExternal.phoneNumber = convertNumberToE164(phoneNumber).substring(0, 12)
            onCallOptionGroup.forwardExternal = forwardExternal
            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
            this.props.updateOnCallOptionGroups(onCallOptionGroups)
        }
        const externalNumber = onCallOptionGroup.forwardExternal.phoneNumber
        const error = !this.props.showValidationErrors ? '' : !externalNumber ? 'Required field' : !isValidPhoneNumber(externalNumber) ? 'Invalid phone number' : ''
        return (
            <Section
                screenViewType = {screenViewType}
                classes = {classes}
                icon = {<ForwardToANumberIcon/>}
                remoteConfigID = 'configure_number_forward_external_header_text'
            >
                <Typography classes={{ root: 'question' }} remoteConfigID='configure_number_forward_external_title' variant='subtitle1'/>
                <div className={`${classes.forwardNumberContent} ${mobileClass}`}>
                    <TextField
                        label = 'e.g. (941) 555-1234'
                        value = {onCallOptionGroup.forwardExternal.phoneNumber}
                        onChange = {e => onChange(e.target.value)}
                        onXClick = {() => onChange('')}
                        classNames = {{ root: 'forward-external-text-field' }}
                        error = {!!error}
                        noLabelOnActiveOrResolved
                        helperText = {error}
                        formatType = {TextFieldFormatType.PhoneNumberUSChip}
                        onClear = {() => onChange('')}
                    />
                    {/* Workaround before we finish the button mui component  */}
                    <div className={classes.editButton} onClick={() => this.setState({ showForwardNumberOptions: true })}><EditIcon/></div>
                </div>
                {this.renderForwardOptions()}
            </Section>
        )
    }

    render = (): JSX.Element => {
        return (
            <>
                {this.renderMainSection()}
            </>
        )
    }
}

export default ForwardExternalSection
