import l from '../lang';

const csv_object_value = (subject) => {
    let value;
    if (typeof subject === 'string') {
        value = subject.split('\\"').join('');
        value = value.replace(/"/g, '');
    } else if (Number.parseInt(subject)) {
        value = Number.parseInt(subject);
    } else if (subject === 'null') {
        value = null;
    } else {
        value = subject;
    }

    return value;
};

const clean_rs = (values_array) => values_array.map((x) => x.replace(/(\r|\r)/gm, ''));

const validate_line_length = (line, headers, line_index) => {
    if (line.length !== headers.length && line.length !== 1 && line[0] !== '') {
        return l.t(
                'csv.incorrect-line',
                'Line {}: line has {} values. {} required.',
                [line_index + 1, line.length, headers.length] // 0 line is header
            );
    }

    return 'OK';
};

const take_clean_headers = (file) => {
	const lines = file.split('\n');
	const headers = lines[0].split(',').map((x) => csv_object_value(x));
	return clean_rs(headers);
};

export default {
	clean_rs,
	csv_object_value,
	take_clean_headers,
	validate_line_length,
};
