/* eslint-disable @typescript-eslint/naming-convention */ // disabled because camel case is used everywhere for json object
/* eslint-disable camelcase */
import { ScheduleOption, NumberTypeOption, OnCallOption, OnNothingSelected, OnNothingSelectedRequestMap, liveReceptionistPresent } from './configure_util'
import Api from 'api'
import { getValue } from 'remote-config-value'
import { TTS, Recording, UploadAudio, Greeting } from 'audio-component-2'
import { getCurrentNumberRule } from './current_rules'
import { Teammate } from 'models'
interface ScheduledNumberDataType {
    open_hours: any
    closed_hours: any
    holidays: any
    custom_holidays?: any
    lunch_break: any
    assign_to: number
    voip_did_id: number
    has_schedules: boolean
}

class SaveNumberConfig {
    configureNumberInstance: any
    /**
     * Class for with save methods
     *
     * @param {object} configureNumberInstance - Instance of ConfigureNumber
     */
    constructor (configureNumberInstance: any) {
        this.configureNumberInstance = configureNumberInstance
        this.configureNumberInstance.props.subscribeForCHRNotifications(this.onSaveProgress)
    }

    setNumberLabel = (): void => {
        const _this: any = this.configureNumberInstance
        const phoneNumber = _this.props.phoneNumber
        if (phoneNumber.name && phoneNumber.name !== 'Unassigned') return
        let split = _this.state.smsForwardUser.split('-')
        split = split.slice(1, split.length)
        const name = split.join(' ')
        phoneNumber.name = name
        Api.updatePhoneNumber({ id: phoneNumber.id, name })
        _this.props.updatePhoneNumber(phoneNumber)
    }

    generateSchedulesData = async (schedules: any, useSchedules: boolean): Promise<any> => {
        const _this: any = this.configureNumberInstance

        const getRingUsersData = (scheduleName: ScheduleOption): any => {
            const scheduleState = _this.state.onCallOptionGroups[scheduleName]
            if (!scheduleState.forward[0].users.length || !_this.state.smsForwardUser) return
            const ringExtensionIds = scheduleState.forward[0].users.map((cu): number => parseInt(cu.split('-')[0]))
            const ringExtensionIds2 = scheduleState.forward[1].enabled ? scheduleState.forward[1].users.map((cu): number => parseInt(cu.split('-')[0])) : []
            const forwardGroups = [ringExtensionIds.filter(e => e), ringExtensionIds2.filter(e => e)]
            return {
                mode: 'forward',
                voip_phone_ids: forwardGroups,
                voicemail_extension_id: parseInt(scheduleState.assignVoicemailUser.split('-')[0])
            }
        }

        const getRecordingId = async (scheduleName: ScheduleOption, isMenu: boolean): Promise<number> => {
            const savedRules = getCurrentNumberRule(_this.props)
            const savedGreeting: Greeting = isMenu ? savedRules.onCallOptionGroups[scheduleName].menu.greeting : savedRules.onCallOptionGroups[scheduleName].greeting
            const onCallOptionGroups = { ..._this.state.onCallOptionGroups }
            const scheduleState = { ...onCallOptionGroups[scheduleName] }
            const { greeting } = isMenu ? scheduleState.menu : scheduleState

            // Check if this schedule used the same greeting as ClosedHours and if yes then now it has to create a new one
            const hasToBeNew = [ScheduleOption.Holidays, ScheduleOption.LunchBreak].includes(scheduleName) &&
                savedRules.onCallOptionGroups[scheduleName].sameAsClosedHours

            const prependSilence = isMenu ? '2' : '2'
            const appendSilence = isMenu ? null : '2'
            const savedGreetingId = hasToBeNew ? null : savedGreeting.getId() as number
            let id = savedGreetingId || null
            const isNew = hasToBeNew || !greeting.getId()
            let greetingPromise
            if (greeting instanceof TTS) {
                if (isNew || greeting.hasChange()) {
                    const defaultVoice = 'Joanna'
                    greetingPromise = Api.createTTSGreeting({ text: greeting.getText(), voice: defaultVoice, name: '', generateCallMenuAudio: isMenu, prependSilence, appendSilence, id: savedGreetingId })
                }
            } else if (greeting instanceof Recording) {
                const recordedAudio = greeting.recordedAudio
                const downloadLink = recordedAudio.downloadLink
                const filename = greeting.getName()
                const base64Data = downloadLink.split(';')[1].split(',')[1]
                greetingPromise = Api.createFileGreeting({ name: filename, file: base64Data, generateCallMenuAudio: isMenu, prependSilence, appendSilence, id: savedGreetingId })
            } else if (greeting instanceof UploadAudio) {
                if (isNew) {
                    greetingPromise = Api.createFileGreeting({ name: greeting.getName(), file: greeting.getBase64Data(), generateCallMenuAudio: isMenu, prependSilence, appendSilence, id: savedGreetingId })
                }
            }
            if (!savedGreetingId) id = (await greetingPromise).voip_recording_id
            greeting.setId(id)
            onCallOptionGroups[scheduleName] = scheduleState
            _this.setState({ onCallOptionGroups })

            if (isNew) {
                const saveProgressMessages = [..._this.state.saveProgressMessages]
                if (savedGreetingId) {
                    greetingPromise.then(() => {
                        saveProgressMessages.push(`Updated ${isMenu ? 'menu' : 'welcome'} greeting for ${scheduleName.split('-').join(' ')}`)
                        _this.setState({ saveProgressMessages })
                    })
                } else {
                    saveProgressMessages.push(`Created ${isMenu ? 'menu' : 'welcome'} greeting for ${scheduleName.split('-').join(' ')}`)
                    _this.setState({ saveProgressMessages })
                }
            }
            return id
        }

        const getMenuData = async (scheduleName: ScheduleOption): Promise<any> => {
            const scheduleState = _this.state.onCallOptionGroups[scheduleName]
            const menuActions = scheduleState.menu.menuActions
            const options = {}
            Object.keys(menuActions).forEach((mo): void => {
                const menuActionSplit = menuActions[mo].split('|')
                const inboxExtensionId = menuActionSplit[1].split('-')[0]

                // array of extension support
                let extensionIds = []
                if (menuActionSplit[0]) {
                    const extensionArrayStr = menuActionSplit[0].split(',')
                    extensionIds = extensionArrayStr.map((e): number => parseInt(e.split('-')[0])).filter(e => e)
                }

                options[mo] = {
                    transfer_to: extensionIds,
                    inbox: inboxExtensionId
                }
            })
            const userNothingSelectedOption = OnNothingSelectedRequestMap[scheduleState.menu.onNothingSelected.option]
            const on_nothing_selected: any = { type: userNothingSelectedOption }
            if (userNothingSelectedOption === OnNothingSelectedRequestMap[OnNothingSelected.Forward]) {
                on_nothing_selected.extension_ids = scheduleState.menu.onNothingSelected.ringUsers.map(e => e.split('-')[0]).filter(e => e)
                on_nothing_selected.voicemail_extension_id = parseInt(scheduleState.menu.onNothingSelected.voicemailUser.split('-')[0])
            } else if (userNothingSelectedOption === OnNothingSelectedRequestMap[OnNothingSelected.Voicemail]) on_nothing_selected.extension_id = scheduleState.menu.onNothingSelected.voicemailUser.split('-')[0]

            const greeting_id = await getRecordingId(scheduleName, true)
            return {
                mode: 'menu',
                menu_actions: {
                    greeting_id,
                    options,
                    on_nothing_selected
                }
            }
        }

        const getSendToVoicemailData = async (scheduleName: ScheduleOption): Promise<any> => {
            const scheduleState = _this.state.onCallOptionGroups[scheduleName]
            const { assignVoicemailUser } = scheduleState
            const voicemailExtensionId = parseInt(assignVoicemailUser.split('-')[0])
            return { mode: 'voicemail', voicemail_extension_id: voicemailExtensionId }
        }

        const getForwardExternalData = async (scheduleName: ScheduleOption): Promise<any> => {
            const scheduleState = _this.state.onCallOptionGroups[scheduleName]
            const { forwardExternal } = scheduleState
            if (!_this.state.smsForwardUser) return
            const ringExtensionIds = scheduleState.forward[1].enabled ? scheduleState.forward[1].users.map((cu): number => parseInt(cu.split('-')[0])) : []
            return {
                mode: 'forward-external',
                phone_number: forwardExternal.phoneNumber,
                caller_id: forwardExternal.callerId,
                voice_tag: forwardExternal.voiceTag,
                screening: forwardExternal.screening,
                voip_phone_ids: ringExtensionIds,
                voicemail_extension_id: parseInt(scheduleState.assignVoicemailUser.split('-')[0])
            }
        }

        const getLiveAnswerData = async (scheduleName: ScheduleOption): Promise<any> => {
            const scheduleState = _this.state.onCallOptionGroups[scheduleName]
            const ringExtensionIds = scheduleState.forward[1].enabled ? scheduleState.forward[1].users.map((cu): number => parseInt(cu.split('-')[0])) : []
            return {
                mode: 'live-answer',
                voicemail_extension_id: parseInt(scheduleState.assignVoicemailUser.split('-')[0]),
                voip_phone_ids: ringExtensionIds
            }
        }

        const getScheduleData = async (scheduleName: ScheduleOption): Promise<any> => {
            const scheduleData = _this.state.onCallOptionGroups[scheduleName]
            if (scheduleName === ScheduleOption.Holidays && scheduleData.sameAsClosedHours) return { same_as: 'closed_hours' }
            if (scheduleName === ScheduleOption.LunchBreak && scheduleData.sameAsClosedHours) return { same_as: 'closed_hours' }
            const onCallOption = scheduleData.onCallOption
            if (!onCallOption) return null
            const methods = {
                [OnCallOption.Forward]: getRingUsersData,
                [OnCallOption.Menu]: getMenuData,
                [OnCallOption.Voicemail]: getSendToVoicemailData,
                [OnCallOption.ForwardExternal]: getForwardExternalData,
                [OnCallOption.LiveAnswer]: getLiveAnswerData,
                [OnCallOption.VirtualAnswer]: getLiveAnswerData,
                [OnCallOption.LiveAnswerPlus]: getLiveAnswerData
            }
            const usingReceptionist = [OnCallOption.LiveAnswer, OnCallOption.VirtualAnswer, OnCallOption.LiveAnswerPlus].includes(onCallOption)
            try {
                const [data, welcomeGreetingId] = await Promise.all([
                    methods[onCallOption](scheduleName),
                    (!usingReceptionist && scheduleData.hasGreeting) ? getRecordingId(scheduleName, false) : null
                ])
                if (welcomeGreetingId) data.welcome_greeting_id = welcomeGreetingId
                return data
            } catch (e) {
                throw new Error('Failed creating a greeting. Please try again')
            }
        }

        const hasSchedules = Boolean(schedules?.length) && useSchedules
        const activeSchedules = schedules?.filter((s): boolean => s.status === 'on') || []
        const hasClosedHoursSchedule = activeSchedules.length > 1 || (activeSchedules.length === 1 && !_this.isOpen24Schedule(activeSchedules))
        const hasHolidaysSchedule = Boolean(schedules?.find((s): boolean => s.name === ScheduleOption.Holidays && s.status === 'on'))
        const hasLunchBreakSchedule = Boolean(schedules?.find((s): boolean => s.name === ScheduleOption.LunchBreak && s.status === 'on'))
        const [openHoursData, closedHoursData, holidaysScheduleData, lunchScheduleData] = await Promise.all([
            getScheduleData(ScheduleOption.OpenHours),
            hasClosedHoursSchedule ? getScheduleData(ScheduleOption.ClosedHours) : hasSchedules ? { same_as: 'open_hours' } : null,
            hasHolidaysSchedule ? getScheduleData(ScheduleOption.Holidays) : hasSchedules ? { same_as: 'closed_hours' } : null,
            hasLunchBreakSchedule ? getScheduleData(ScheduleOption.LunchBreak) : hasSchedules ? { same_as: 'closed_hours' } : null
        ])

        const data: ScheduledNumberDataType = {
            open_hours: openHoursData,
            closed_hours: closedHoursData,
            holidays: holidaysScheduleData,
            custom_holidays: { same_as: 'closed_hours' },
            lunch_break: lunchScheduleData,
            assign_to: _this.state.smsForwardUser.split('-')[0],
            voip_did_id: _this.props.phoneNumber.voip_did_id,
            has_schedules: hasSchedules
        }
        return data
    }

    saveMainCompanyNumber = async (schedules: any): Promise<any> => {
        const data = await this.generateSchedulesData(schedules, true)
        data.save_id = this.configureNumberInstance.state.saveId
        data.script_id = this.configureNumberInstance.state.scriptId
        return Api.configureCalling('company', data)
    }

    saveOther = async (schedules: any): Promise<any> => {
        const useSchedules = this.configureNumberInstance.state.useSchedules
        const data = await this.generateSchedulesData(schedules, useSchedules)
        data.save_id = this.configureNumberInstance.state.saveId
        data.script_id = this.configureNumberInstance.state.scriptId
        return Api.configureCalling('voice-line', data)
    }

    saveUserNumber = (): any => {
        const _this: any = this.configureNumberInstance
        const data = {
            voip_did_id: _this.props.phoneNumber.voip_did_id,
            assign_to: _this.state.smsForwardUser.split('-')[0],
            save_id: _this.state.saveId,
            script_id: _this.state.scriptId
        }
        return Api.configureCalling('direct-line', data)
    }

    saveFaxNumber = (): any => {
        const _this: any = this.configureNumberInstance
        const userId = parseInt(_this.state.smsForwardUser.split('-')[0])
        const data = {
            voip_did_id: _this.props.phoneNumber.voip_did_id,
            assign_to: userId,
            fax_user_id: userId,
            save_id: _this.state.saveId
        }
        return Api.configureCalling('fax-line', data)
    }

    createContacts = (teammates: Teammate[]): any => {
        const contacts = []
        console.log('teammates', teammates)
        for (const teammate of teammates) {
            if (teammate.status !== 'active') continue
            const contact = {
                id: teammate.id,
                first_name: teammate.first_name,
                last_name: teammate.last_name,
                destinations: [
                    {
                        type: 'extension',
                        id: teammate.extension?.id,
                        destination: `ext:${teammate.extension?.number}`,
                        is_warm_transfer: true
                    }, // extension
                    {
                        type: 'voicemail',
                        id: teammate.extension?.id,
                        destination: `${teammate.extension?.id}`,
                        is_warm_transfer: true
                    } // voicemail
                ],
                is_default: teammate.is_admin
            }
            contacts.push(contact)
        }
        return contacts
    }

    // if scriptName is already in existingReceptionistScripts, increment the name
    incrementScriptName = (scriptName: string): string => {
        const _this = this.configureNumberInstance
        const existingScriptNames = _this.state.existingReceptionistScripts.map(s => s.name)
        const newScriptNameIncrement = existingScriptNames.filter(name => name.includes(scriptName)).length + 1
        const newScriptNameWithIncrement = newScriptNameIncrement > 1 ? `${scriptName} ${newScriptNameIncrement}` : scriptName
        return newScriptNameWithIncrement
    }

    configureReceptionist = async (): Promise<any> => {
        const _this = this.configureNumberInstance
        const companySettings = await Api.getCompanySettings()
        const contacts = this.createContacts(_this.props.users)
        const scriptType = (_this.state.receptionistOption === 'live') ? 'Live Receptionist' : (_this.state.receptionistOption === 'live-plus') ? 'Live Answer-Connect' : 'AI-Connect'
        const scriptName = `${scriptType} for ${companySettings.company}`
        const newScriptNameWithIncrement = this.incrementScriptName(scriptName)
        _this.setState({ scriptName: newScriptNameWithIncrement })
        const greeting = (_this.state.receptionistOption === 'live' || _this.state.receptionistOption === 'live-plus') ? `Thank you for calling ${companySettings.company}. ${getValue('configure_number_live_receptionist_greeting')}` : `${getValue('configure_number_virtual_receptionist_greeting')} ${companySettings.company},`
        const liveAnswerParams = {
            name: newScriptNameWithIncrement,
            code: _this.state.receptionistOption === 'live' ? 19074 : (_this.state.receptionistOption === 'live-plus' ? 19110 : 19088),
            call_handling_notes: '',
            greeting,
            business_name: companySettings.company,
            business_contact: {
                name: companySettings.company,
                company: companySettings.company,
                address: {
                    line_1: companySettings.address?.street,
                    city: companySettings.address?.city,
                    province: companySettings.address?.state,
                    postal_code: companySettings.address?.zip,
                    country: companySettings.address?.country
                }
            },
            contacts,
            vendor: {
                id: (_this.state.receptionistOption === 'live') ? 1 : (_this.state.receptionistOption === 'live-plus' ? 8 : 4)
            },
            appointments_enabled: false
        }
        try {
            const scriptId = await Api.createLiveAnswerScript(liveAnswerParams)
            return scriptId
        } catch (error) {
            console.error('Error creating live receptionist script', error)
            const errorPromptMessage = error.message
            _this.setState({ errorPromptMessage, saving: false, saveProgress: 0, saveProgressMessages: [], saveId: null, liveReceptionistChargesPopupShow: false })
            return null
        }
    }

    apply = (): void => {
        const _this = this.configureNumberInstance
        const saveId = `${parseInt(`${Math.random() * 10000}`)}`
        _this.setState({ saving: true, saveId }, async (): Promise<void> => {
            setTimeout(() => _this.setState({ saveProgress: 10 }), 500)
            if (liveReceptionistPresent(_this) && (_this.state.scriptId === -1)) {
                try {
                    const scriptId = await this.configureReceptionist()
                    _this.setState({ scriptId })
                } catch (error) {
                    console.error('Error configuring receptionist', error)
                    const errorPromptMessage = error.message
                    _this.setState({ errorPromptMessage, saving: false, saveProgress: 0, saveProgressMessages: [], saveId: null, liveReceptionistChargesPopupShow: false, scriptId: -1 })
                }
            }
            if (!liveReceptionistPresent(_this) && _this.state.scriptId !== -1) {
                // deactivate script
                Api.deactivateLiveAnswerScript(_this.state.scriptId)
                _this.setState({ scriptId: -1 })
            }
            try {
                const saveMethods = {
                    [NumberTypeOption.MainCompanyNumber]: this.saveMainCompanyNumber.bind(this, _this.props.schedules),
                    [NumberTypeOption.UserNumber]: this.saveUserNumber,
                    [NumberTypeOption.FaxLine]: this.saveFaxNumber,
                    [NumberTypeOption.Other]: this.saveOther.bind(this, _this.props.schedules)
                }
                const response = await saveMethods[_this.state.numberTypeOption]()
                if (response?.errors && response.http_code === 422) {
                    if (response.errors.voip_did_id === 'inactive') return // Handled by the websockets
                    _this.setState({ liveReceptionistChargesPopupShow: false })
                    throw new Error(`Failed configuring the number. (${JSON.stringify(response.errors)})`)
                }
            } catch (error) {
                console.error(error)
                const errorPromptMessage = _this.state.errorPromptMessage || error.message
                // delete the script if it was created
                if (_this.state.scriptId) {
                    try {
                        await Api.deleteLiveAnswerScript(_this.state.scriptId)
                    } catch (error) {
                        console.error('error deleting live answer script', error)
                    }
                }
                _this.setState({ errorPromptMessage, saving: false, saveProgress: 0, saveProgressMessages: [], saveId: null, liveReceptionistChargesPopupShow: false, scriptId: -1 })
            }
        })
    }

    onSaveProgress = (payload) => {
        console.log('#### CHR progress:', payload)
        const _this = this.configureNumberInstance
        if (_this.state.saveId !== payload.save_id) {
            return _this.setState({ saving: false, saveProgress: 0, saveProgressMessages: [], saveId: null })
        }
        const currentVoipDidId = _this.props.phoneNumber.voip_did_id
        if (payload.voip_did_id !== currentVoipDidId) return
        const saveProgressMessages = [..._this.state.saveProgressMessages]
        if (payload.error) {
            console.error('Error while saving number CHR', payload.error, '\nSave progress:', saveProgressMessages)
            const errorPromptMessage = payload.error === 'CHR_UPDATE_FAILURE'
                ? 'Failure updating the call handling rules'
                : payload.error === 'MAIN_COMPANY_NUMBER_ALREADY_SET'
                    ? 'Main company number is already set'
                    : payload.error === 'INACTIVE_VOIP_DID_ID'
                        ? 'The phone number is not active'
                        : getValue('configure_number_save_failure_prompt_message')
            // return _this.setState(
            //     { errorPromptMessage },
            //     () => setTimeout(() => _this.setState({ saving: false, saveProgress: 0, saveProgressMessages: [], saveId: null }), 5000)
            // )
            _this.props.onError?.()
            return _this.setState({ errorPromptMessage, saving: false, saveProgress: 0, saveProgressMessages: [], saveId: null })
        }
        const formatMessage = message => message
            .split('closed-hours').join('after hours')
            .split('open-hours').join('open hours')
            .split('custom-holidays').join('custom closed days')
            .split('lunch-break').join('lunch break')
        if (payload.message) saveProgressMessages.push(formatMessage(payload.message))
        _this.setState({ saveProgress: payload.progress, saveProgressMessages, errorPromptMessage: null })
        if (payload.progress === 100) this.onSaved()
    }

    onSaved = () => {
        this.setNumberLabel()
        const _this = this.configureNumberInstance
        _this.props.onSaved?.()
        if (_this.state.numberTypeOption === NumberTypeOption.MainCompanyNumber) {
            _this.props.updateMainCompanyNumberVoipDidId(_this.props.phoneNumber.voip_did_id)
        } else if (_this.props.phoneNumber.type === 'Main company number') {
            _this.props.updateMainCompanyNumberVoipDidId(null)
        }
        const newNumberData = this.getNewNumberData()
        _this.props.updateNumberRules(newNumberData)
        _this.resetRulesState()
        if (_this.wrapperRef.current) _this.wrapperRef.current.scrollTop = 0
        setTimeout(() => _this.setState({
            saving: false,
            saveProgress: 0,
            saveProgressMessages: [],
            pdcPromptOpen: true,
            pdcPromptMessage: 'Configuration saved.',
            saveId: null,
            liveReceptionistChargesPopupShow: false
        }), 2000)
        this.loadGreetingUrls()
    }

    getNewNumberData = (): any => {
        const _this = this.configureNumberInstance
        const newNumberData: any = {}
        const newSmsForwarding: any = { type: 'extension', extension: {} }
        const smsForwardExtensionId = parseInt(_this.state.smsForwardUser.split('-')[0])
        const smsForwardUser = _this.props.users.find(u => u.extension.id === smsForwardExtensionId)
        const companyExtension = _this.props.companyExtension
        const smsForwardUserExtension = (companyExtension && smsForwardExtensionId === companyExtension.id) ? companyExtension : smsForwardUser.extension
        newSmsForwarding.extension.extension = smsForwardUserExtension.extension_number || smsForwardUserExtension.number
        newSmsForwarding.extension.id = smsForwardUserExtension.id
        newSmsForwarding.extension.name = smsForwardUserExtension.extension_name || smsForwardUserExtension.name
        const newNumberRulesGroups = []
        let rulesGroupNumber = 1

        const addRulesGroupData = (scheduleState, hasSchedule, scheduleOption): void => {
            const newNumberRuleTypes = []
            const newNumberRules: any = { rule_types: newNumberRuleTypes, actions: [] }
            const type = hasSchedule ? 'schedule' : 'all'
            const numberRulesGroup: any = { type, number: rulesGroupNumber++, group_id_value: 0, rules: newNumberRules }
            if (hasSchedule) numberRulesGroup.schedule = { name: scheduleOption }
            newNumberRulesGroups.push(numberRulesGroup)

            if (scheduleState.hasGreeting) newNumberRules.actions.push({ data: scheduleState.greeting.toJSON(), type: 'play-recording' })

            const addUsersAction = (users) => {
                newNumberRules.actions.push({
                    type: 'ring-users',
                    data: {
                        extensions: users.map(u => ({ extension_id: parseInt(u.split('-')[0]), caller_id: 'calling_number', screening: false })),
                        phone_numbers: []
                    }
                })
            }

            if (scheduleState.onCallOption === OnCallOption.Forward) {
                newNumberRuleTypes.push(...['ring-users', 'voicemail'])
                addUsersAction(scheduleState.forward[0].users)
                if (scheduleState.forward[1].enabled) addUsersAction(scheduleState.forward[1].users)
                newNumberRules.actions.push({ type: 'voicemail', data: { extension_id: parseInt(scheduleState.assignVoicemailUser.split('-')[0]) } })
            } else if (scheduleState.onCallOption === OnCallOption.Voicemail) {
                newNumberRuleTypes.push('voicemail')
                newNumberRules.actions.push({ type: 'voicemail', data: { extension_id: parseInt(scheduleState.assignVoicemailUser.split('-')[0]) } })
            } else if (scheduleState.onCallOption === OnCallOption.Menu) {
                newNumberRuleTypes.push('play-menu')
                // Set greeting
                const playMenuData = {
                    greeting: scheduleState.menu.greeting.toJSON(),
                    options: {},
                    on_nothing_selected: {
                        rule_groups: [{ type: 'all', number: 1, group_id_value: 0, rules: { actions: [], rule_types: [] } }]
                    }
                }
                // Set options
                const menuActions = scheduleState.menu.menuActions
                Object.keys(menuActions).forEach((mo): any => {
                    playMenuData.options[mo] = {
                        rule_groups: [
                            {
                                type: 'all',
                                number: 1,
                                group_id_value: 0,
                                rules: {
                                    actions: [{
                                        type: 'ring-users',
                                        data: {
                                            extensions: menuActions[mo].split('|')[0].split(',').map(e => ({ extension_id: parseInt(e.split('-')[0]), caller_id: 'calling_number', screening: false })),
                                            phone_numbers: []
                                        }
                                    }, {
                                        type: 'voicemail',
                                        data: { extension_id: parseInt(menuActions[mo].split('|')[1].split('-')[0]) }
                                    }],
                                    rule_types: ['ring-users', 'voicemail']
                                }
                            }
                        ]
                    }
                })
                // Set on_nothing_selected
                const onNothingSelected = playMenuData.on_nothing_selected
                const onNothingSelectedRules = onNothingSelected.rule_groups[0].rules
                if (scheduleState.menu.onNothingSelected.option === OnNothingSelected.Disconnect) {
                    onNothingSelectedRules.rule_types = ['disconnect']
                    onNothingSelectedRules.actions.push({ type: 'disconnect' })
                } else if (scheduleState.menu.onNothingSelected.option === OnNothingSelected.Voicemail) {
                    onNothingSelectedRules.rule_types = ['voicemail']
                    const extensionId = parseInt(scheduleState.menu.onNothingSelected.voicemailUser.split('-')[0])
                    onNothingSelectedRules.actions.push({ type: 'voicemail', data: { extension_id: extensionId } })
                } else if (scheduleState.menu.onNothingSelected.option === OnNothingSelected.Forward) {
                    onNothingSelectedRules.rule_types = ['ring-users', 'voicemail']
                    const ringUsersData = {
                        extensions: scheduleState.menu.onNothingSelected.ringUsers.map((u): any => ({ extension_id: parseInt(u?.split('-')[0]), caller_id: 'calling_number', screening: false })),
                        phone_numbers: []
                    }
                    onNothingSelectedRules.actions.push({ type: 'ring-users', data: ringUsersData })
                    const extensionId = parseInt(scheduleState.menu.onNothingSelected.voicemailUser.split('-')[0])
                    onNothingSelectedRules.actions.push({ type: 'voicemail', data: { extension_id: extensionId } })
                }
                newNumberRules.actions.push({ type: 'play-menu', data: playMenuData })
            } else if (scheduleState.onCallOption === OnCallOption.ForwardExternal) {
                const forwardExternal = scheduleState.forwardExternal
                newNumberRuleTypes.push('ring-users')
                newNumberRules.actions.push({
                    type: 'ring-users',
                    data: {
                        phone_numbers: [{
                            number: forwardExternal.phoneNumber,
                            caller_id: forwardExternal.callerId,
                            voice_tag: forwardExternal.voiceTag,
                            screening: forwardExternal.screening
                        }],
                        extensions: []
                    }
                })
                if (scheduleState.forward[1].enabled) addUsersAction(scheduleState.forward[1].users)
                newNumberRules.actions.push({ type: 'voicemail', data: { extension_id: parseInt(scheduleState.assignVoicemailUser.split('-')[0]) } })
                newNumberRuleTypes.push('voicemail')
            } else if (scheduleState.onCallOption === OnCallOption.LiveAnswer || scheduleState.onCallOption === OnCallOption.VirtualAnswer || scheduleState.onCallOption === OnCallOption.LiveAnswerPlus) {
                newNumberRuleTypes.push('live_answer')
                const planId = _this.state.receptionistOption === 'live' ? 19074 : (_this.state.receptionistOption === 'live-plus' ? 19110 : 19088)
                newNumberRules.actions.push({
                    type: 'live_answer',
                    data: {
                        code: planId,
                        notes: _this.state.liveAnswerConfiguration.callHandlingNotes,
                        id: _this.state.scriptId
                    }
                })
                if (scheduleState.forward[1].enabled) addUsersAction(scheduleState.forward[1].users)
                newNumberRules.actions.push({ type: 'voicemail', data: { extension_id: parseInt(scheduleState.assignVoicemailUser.split('-')[0]) } })
                // add script to existing receptionist scripts
                if (_this.state.scriptId && _this.state.scriptName) {
                    const script = {
                        id: _this.state.scriptId,
                        name: _this.state.scriptName,
                        enabled: true,
                        vendor: {
                            id: (_this.state.receptionistOption === 'live') ? 1 : (_this.state.receptionistOption === 'live-plus' ? 8 : 4)
                        }
                    }
                    const existingReceptionistScripts = _this.state.existingReceptionistScripts
                    // if script is not already in the list, add it
                    if (!existingReceptionistScripts.find(s => s.id === script.id)) {
                        existingReceptionistScripts.push(script)
                        _this.setState({ existingReceptionistScripts })
                    }
                }
            }
        }

        const numberTypeOption = _this.state.numberTypeOption
        if (numberTypeOption === NumberTypeOption.FaxLine) {
            const newNumberRules: any = {
                actions: [{ type: 'fax', data: { extension_id: newSmsForwarding.extension.id } }],
                rule_types: ['fax']
            }
            const numberRulesGroup = { type: 'all', number: rulesGroupNumber, group_id_value: 0, rules: newNumberRules }
            newNumberRulesGroups.push(numberRulesGroup)
        } else if (numberTypeOption === NumberTypeOption.UserNumber) {
            const newNumberRules: any = {
                actions: [
                    { type: 'ring-users', data: { extensions: [{ extension_id: newSmsForwarding.extension.id }] } },
                    { type: 'voicemail', data: { extension_id: newSmsForwarding.extension.id } }
                ],
                rule_types: ['ring-users', 'voicemail']
            }
            const numberRulesGroup = { type: 'all', number: rulesGroupNumber, group_id_value: 0, rules: newNumberRules }
            newNumberRulesGroups.push(numberRulesGroup)
        } else if ([NumberTypeOption.MainCompanyNumber, NumberTypeOption.Other].includes(numberTypeOption)) {
            const hasSchedules = numberTypeOption === NumberTypeOption.MainCompanyNumber || _this.state.useSchedules
            const scheduleNames = hasSchedules
                ? ['custom-holidays', ScheduleOption.Holidays, ScheduleOption.LunchBreak, ScheduleOption.OpenHours, ScheduleOption.ClosedHours]
                : [ScheduleOption.OpenHours]
            scheduleNames.forEach((scheduleName: ScheduleOption | 'custom-holidays'): void => {
                const activeSchedules = _this.props.schedules?.filter((s): boolean => s.status === 'on') || []
                const isOpen24 = activeSchedules.length === 1 && _this.isOpen24Schedule(activeSchedules)
                let scheduleStateName = scheduleName
                if (isOpen24) scheduleStateName = ScheduleOption.OpenHours
                else {
                    scheduleStateName = scheduleName
                    if (scheduleStateName === 'custom-holidays') scheduleStateName = ScheduleOption.ClosedHours
                    if (
                        [ScheduleOption.LunchBreak, ScheduleOption.Holidays, 'custom-holidays'].includes(scheduleName) &&
                        _this.state.onCallOptionGroups[scheduleStateName].sameAsClosedHours
                    ) scheduleStateName = ScheduleOption.ClosedHours
                }
                const scheduleState = _this.state.onCallOptionGroups[scheduleStateName]
                const isClosedHours = scheduleName === ScheduleOption.ClosedHours
                const hasSchedule = hasSchedules && !isClosedHours
                addRulesGroupData(scheduleState, hasSchedule, scheduleName)
            })

            if (numberTypeOption === NumberTypeOption.MainCompanyNumber) {
                _this.props.updateCompanyExtensionRules([...newNumberRulesGroups])
                newNumberRulesGroups.length = 0

                const companyExtensionId = _this.props.companyExtension.id
                const newNumberRules: any = {
                    actions: [
                        { type: 'ring-users', data: { extensions: [{ extension_id: companyExtensionId }] } },
                        { type: 'voicemail', data: { extension_id: companyExtensionId } }
                    ],
                    rule_types: ['ring-users', 'voicemail']
                }
                const numberRulesGroup = { type: 'all', number: rulesGroupNumber, group_id_value: 0, rules: newNumberRules }
                newNumberRulesGroups.push(numberRulesGroup)
            }
        }

        newNumberData.numberRulesGroups = newNumberRulesGroups
        newNumberData.smsForwarding = newSmsForwarding
        return newNumberData
    }

    loadGreetingUrls = (): void => {
        const _this = this.configureNumberInstance
        const loadPromises = []
        const idPromisesMap = {}
        const onCallOptionGroups = { ..._this.state.onCallOptionGroups }
        const loadGreetingUrl = async (greeting: Greeting) => {
            const id = greeting.getId()
            if (greeting instanceof Recording || !id) return
            greeting.setUrl('')
            const isBeingLoaded = !!idPromisesMap[id]
            if (!isBeingLoaded) idPromisesMap[id] = Api.getMusicOnHoldLink(id)
            if (greeting instanceof TTS || greeting instanceof UploadAudio) greeting.setLoadingUrl(true)
            const urlResponse = await idPromisesMap[id]
            greeting.setUrl(urlResponse.download_link)
            if (greeting instanceof TTS) greeting.onSaved()
            _this.setState({ onCallOptionGroups: { ...onCallOptionGroups } })
        }
        for (const key in ScheduleOption) {
            const scheduleOption = ScheduleOption[key]
            const welcomeGreeting = onCallOptionGroups[scheduleOption].greeting
            if (welcomeGreeting && welcomeGreeting.getId()) loadPromises.push(loadGreetingUrl(welcomeGreeting))
            if (onCallOptionGroups[scheduleOption].onCallOption === OnCallOption.Menu) {
                const menuGreeting = onCallOptionGroups[scheduleOption].menu.greeting
                if (menuGreeting && menuGreeting.getId()) loadPromises.push(loadGreetingUrl(menuGreeting))
            }
        }
        Promise.all(loadPromises).then(() => _this.setState({ onCallOptionGroups: { ...onCallOptionGroups } }))
    }
}

export default SaveNumberConfig
