<template>
    <w-select 
      v-bind="$attrs"
      v-on="$listeners"
      v-model="val"
      :items="items"
      :placeholder="$lang.t('app.select-service-type', 'Select your service type')"
    />
</template>
<script>
import LiveAnswerVendor from '../../../console/src/models/LiveAnswerVendor';

export default {
    props: ['value', 'item'],
    data() {
        let code;
        if (this.$props.item && this.$props.item.id) code = this.$props.item.code;
        return {
            items: [],
            val: null,
            vendor: new LiveAnswerVendor(code),
        };
    },
    async created() {
        const items = [];
        let vendors = this.$data.vendor.vendors;
        if (this.$props.item && !this.$props.item.id) {
            await this.$data.vendor.init();
            vendors = this.$data.vendor.eligible_vendors;
        }
        for (const vendor of vendors) {
            items.push({
                text: vendor.name,
                value: vendor,
            });
        }
        this.items = items;
        if (this.$props.value) {
            const found_value = this.$data.items.find((x) => x.value.id === this.$props.value.id);
            this.$data.val = found_value ? found_value.value : null;
        }
    },
    watch: {
        val(v) {
            this.$emit('input', v);
        },
    }
};
</script>
