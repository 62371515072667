import { useEffect, useState } from 'react'

function getSavedValue (key: string, initialValue: unknown | Function) { // eslint-disable-line
    const savedValue = JSON.parse(localStorage.getItem(key))
    if (savedValue) return savedValue
    if (initialValue instanceof Function) return initialValue()
    return initialValue
}

/***/
export function useLocalStorage (key: string, initalValue: unknown | Function): [string, (newValue: unknown) => void] { // eslint-disable-line
    const [value, setValue] = useState(() => getSavedValue(key, initalValue))
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [value])
    return [value, setValue]
}

export default useLocalStorage
