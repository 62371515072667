import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/JQacodb8V4YdmDt3Awk2zl/Call-handling?node-id=2529%3A10205
 *
 * @param {SvgIconProps} props - svg props
 */
export const WelcomeMessageIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#2A9BB7'/>
            <path d='M10.54 24.2085C10.5466 24.2001 10.5494 24.1966 10.5498 24.1961L10.5582 24.1875L10.5665 24.1736C10.6848 23.9763 10.8571 23.8313 11.0747 23.7504C11.2322 23.6919 11.3886 23.6818 11.5415 23.7073L11.5571 23.7099L11.5631 23.7093L11.5832 23.7111C11.6034 23.7131 11.6327 23.7162 11.6702 23.7202C11.7452 23.7282 11.8521 23.74 11.9828 23.7547C12.2443 23.7839 12.6006 23.8244 12.9873 23.8686C13.7607 23.957 14.6549 24.0601 15.1525 24.1176C15.4205 24.1486 15.6416 23.8717 15.5657 23.6055L13.1409 15.1003C12.9637 14.4786 13.3136 13.7918 13.9273 13.5638C14.541 13.3357 15.1779 13.6556 15.3552 14.2773L16.9434 19.8481C17.0002 20.0473 17.2036 20.1503 17.3977 20.0781L17.7452 19.949C17.9393 19.8768 18.0507 19.6569 17.9939 19.4577L15.814 11.8114C15.6368 11.1897 15.9867 10.503 16.6004 10.2749C17.214 10.0468 17.851 10.3667 18.0282 10.9884L20.2081 18.6347C20.2649 18.8339 20.4683 18.9368 20.6624 18.8647L21.0099 18.7355C21.2041 18.6634 21.3154 18.4434 21.2586 18.2443L19.3154 11.4282C19.1382 10.8065 19.4881 10.1197 20.1018 9.8916C20.7154 9.66351 21.3524 9.98349 21.5296 10.6052L23.4729 17.4213C23.5296 17.6204 23.733 17.7234 23.9272 17.6513L24.2747 17.5221C24.4688 17.4499 24.5802 17.23 24.5234 17.0308L23.2902 12.7052C23.1129 12.0836 23.4629 11.3968 24.0765 11.1687C24.6902 10.9406 25.3272 11.2606 25.5044 11.8823L28.6522 22.9236C29.0486 24.3138 28.978 25.4947 28.486 26.4485C27.9938 27.4027 27.067 28.1543 25.7036 28.661L20.743 30.5048C19.894 30.8203 18.9948 30.775 18.2321 30.3879L10.5788 25.714C10.1489 25.4515 10.0585 24.8392 10.3868 24.4081C10.4344 24.3456 10.475 24.2924 10.5047 24.2539L10.54 24.2085ZM11.5568 23.7087L11.5573 23.7088C11.5552 23.7087 11.5547 23.7086 11.5568 23.7087Z' fill='white' stroke='#E1F1F5' strokeWidth='0.25'/>
        </SvgIcon>
    )
}

export default WelcomeMessageIcon
