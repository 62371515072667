import React, { useEffect, useRef } from 'react'
import { getValue } from 'remote-config-value'
import ConfirmModal from 'confirm-modal'
/* import { useTheme } from '@material-ui/core' */
import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'configure-calling;iam-promo-modal' // eslint-disable-line @typescript-eslint/naming-convention

interface IABPromoModalProps {
    onConfirm: () => void;
    onReject: () => void;
    phoneNumber: string;
}

/**
 * Confirmation modal to ask if the user wants to opt into AI-Connect (IAB) for an additional montly cost
 */
const IABPromoModal = ({ onConfirm, onReject, phoneNumber }: IABPromoModalProps): JSX.Element => {
    const confirmButtonRef = useRef()
    /* const theme = useTheme() */

    useEffect(() => {
        gtmDataPush({
            PDC_Action: GTM_APP_NAME, // eslint-disable-line @typescript-eslint/naming-convention
            PDC_Label: 'modal-shown' // eslint-disable-line @typescript-eslint/naming-convention
        })
    }, [])

    useEffect(() => {
        if (confirmButtonRef.current) {
            confirmButtonRef.current.focus()
        }
    }, [confirmButtonRef])

    const reject = () => {
        localStorage.setItem(`numberConfigured${phoneNumber}`, 'true')
        gtmDataPush({
            PDC_Action: GTM_APP_NAME, // eslint-disable-line @typescript-eslint/naming-convention
            PDC_Label: 'cancel-button-clicked' // eslint-disable-line @typescript-eslint/naming-convention
        })
        onReject()
    }

    const confirm = () => {
        localStorage.setItem(`numberConfigured${phoneNumber}`, 'true')
        gtmDataPush({
            PDC_Action: GTM_APP_NAME, // eslint-disable-line @typescript-eslint/naming-convention
            PDC_Label: 'confirm-button-clicked' // eslint-disable-line @typescript-eslint/naming-convention
        })
        onConfirm()
    }

    const content = (
        <div>
            {getValue('configure_number_iab_promo_1')} {/* <a style={{ color: theme.palette.primary.main }} href='https://support.phone.com/what-is-the-intelligent-answer-bot' rel='noreferrer' target='_blank'>Learn more.</a> */}
            <br />
            <br />
            {getValue('configure_number_iab_promo_2')}
        </div>
    )

    return (
        <ConfirmModal
            title = 'AI-Connect'
            isShown = {true}
            isIabPromo = {true}
            content = {content}
            noButtonText = 'No, Thank You'
            yesButtonText = 'Yes, add this feature for $19.99/month'
            onReject = {reject}
            onConfirm = {confirm}
            size = 'size600'
            confirmRef={confirmButtonRef}
        />
    )
}

export default IABPromoModal
