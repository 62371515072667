import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: No link at the moment
 *
 * @param {SvgIconProps} props - svg props
 */
export const VoicemailIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#2A9BB7'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M14 25.5C10.9624 25.5 8.5 23.0376 8.5 20C8.5 16.9624 10.9624 14.5 14 14.5C17.0376 14.5 19.5 16.9624 19.5 20C19.5 21.0018 19.2322 21.941 18.7642 22.75H21.2358C20.7678 21.941 20.5 21.0018 20.5 20C20.5 16.9624 22.9624 14.5 26 14.5C29.0376 14.5 31.5 16.9624 31.5 20C31.5 23.0376 29.0376 25.5 26 25.5H14ZM14 22.75C15.5188 22.75 16.75 21.5188 16.75 20C16.75 18.4812 15.5188 17.25 14 17.25C12.4812 17.25 11.25 18.4812 11.25 20C11.25 21.5188 12.4812 22.75 14 22.75ZM26 22.75C27.5188 22.75 28.75 21.5188 28.75 20C28.75 18.4812 27.5188 17.25 26 17.25C24.4812 17.25 23.25 18.4812 23.25 20C23.25 21.5188 24.4812 22.75 26 22.75Z' fill='white'/>
        </SvgIcon>
    )
}

export default VoicemailIcon
