// React
import React from "react";
import Spinner from 'spinner';

// Stylesheet
import "./loader.css";

// Loader
class MessageLoader extends React.Component {

  renderLeasing() {
    return (
      <div className='message-loader-wrapper'>
        <div className='spinner'>
            <span>Hold on while we purchase your number</span>
            <span>{this.props.purchasing}</span>
        </div>
      </div>
    )
  }

  renderPurchasing() {}

  renderSearching() {}

  renderReserving() {
    let message = 'One moment while we reserve your new number...';
  }

  renderReserved() {
    return (
      <div>We’ve reserved (877) 90-LEASE for 60 minutes</div>
    )
  }

  render() {
    return (
      <div className='message-loader-wrapper'>
          <div className='spinner'>
            <Spinner data-test-id='spinner' style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} size={'bigger'} color={'gray'}/>
          </div>
          <span data-test-id='spinner-message' className='loader-spinner-message'>{this.props.message}</span>
          <span data-test-id='spinner-submessage' className='loader-spinner-submessage'>{this.props.submessage}</span>
      </div>
    )
  }
}

export default MessageLoader;
