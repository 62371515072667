<template>
    <div>
        <div class="w-body-2">{{$lang.t('app.confirm-the-action-by-typing', 'Confirm the action by typing:')}} <span class="font-weight-bold">{{phrase}}</span></div>
        <w-text-field v-bind="$attrs" v-on="$listeners" v-model="user_input" />
    </div>
</template>
<script>
    import lang from 'lang';

  export default {
    props: {
        phrase: {
            type: String,
            default: lang.t('app.delete-me', 'Delete me'),
        }
    },
    data() {
      return {
        user_input: '',
      };
    },
    watch: {
        user_input(v) {
            this.$emit('input', v.toLowerCase() === this.$props.phrase.toLowerCase());
        }
    }
  };
</script>
