import { Greeting, Recording, TTS, UploadAudio } from './Greeting'

const MinTextareaHeight = 100
const MaxTextAreaHeight = 250

/**
 * @param {string} value
 */
export const computeTextareaHeight = (value: string): string => {
    let height = `${MinTextareaHeight}px`
    const tmpSpan = document.createElement('span')
    tmpSpan.innerText = `${value}.`
    document.getElementsByTagName('body')[0].appendChild(tmpSpan)
    const contentHeight = tmpSpan.offsetHeight
    tmpSpan.remove()
    const totalHeight = contentHeight + 2 * 10 + 2 * 1 + 5 // 10 is padding, 1 is border

    // The '+ 2' is because of 1px border top and 1px border bottom
    if (totalHeight >= MinTextareaHeight && totalHeight <= MaxTextAreaHeight) height = `${totalHeight}px`
    else if (totalHeight < MinTextareaHeight) height = `${MinTextareaHeight}px`
    else if (totalHeight > MaxTextAreaHeight) height = `${MaxTextAreaHeight}px`
    return height
}

/***/
export const createGreeting = (greetingData): Greeting => {
    if (!greetingData) return
    const recordingData = greetingData.voip_recording
    if (!recordingData) return new TTS()
    const id = recordingData.voip_recording_id
    const url = greetingData.url
    const filename = recordingData.name || recordingData.filename
    if (recordingData.origin === 'tts') {
        return new TTS(id, recordingData.tts_text, recordingData.tts_voice, url)
    } else if (filename.substring(0, 9) === 'recording') {
        const recordedAudio = { downloadLink: url, filename }
        return new Recording(id, recordedAudio)
    } else {
        return new UploadAudio(id, filename, url)
    }
}
