interface ListenerData {
    enabled: boolean
    text: string
    rateLimit: number
    listenerId?: number
}

interface AutoReplyData {
    calls: ListenerData
    messages: ListenerData
}

enum ListenerName { CALLS = 'calls', MESSAGES = 'messages' }

type ReducerAction = {
    type: 'SET'
    data: {
        [ListenerName.CALLS]: ListenerData,
        [ListenerName.MESSAGES]: ListenerData
    }
} | {
    type: 'TOGGLE'
    name: ListenerName
    enabled: boolean
} | {
    type: 'SET_TEXT'
    name: ListenerName
    text: string
} | {
    type: 'SET_RATE_LIMIT'
    name: ListenerName
    rateLimit: number
} | {
    type: 'SAVE'
    callsListenerId?: number
    messagesListenerId?: number
}

interface ReducerState { storred: AutoReplyData, local: AutoReplyData }

/***/
const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
    switch (action.type) {
            case 'SET':
                return {
                    storred: action.data,
                    local: { calls: { ...action.data.calls }, messages: { ...action.data.messages } }
                }
            case 'TOGGLE':
                return {
                    ...state,
                    local: {
                        ...state.local,
                        [action.name]: {
                            ...state.local[action.name],
                            enabled: action.enabled
                        }
                    }
                }
            case 'SET_TEXT':
                return {
                    ...state,
                    local: {
                        ...state.local,
                        [action.name]: {
                            ...state.local[action.name],
                            text: action.text
                        }
                    }
                }
            case 'SET_RATE_LIMIT':
                return {
                    ...state,
                    local: {
                        ...state.local,
                        [action.name]: {
                            ...state.local[action.name],
                            rateLimit: action.rateLimit
                        }
                    }
                }
            case 'SAVE':
                return {
                    storred: {
                        calls: { ...state.local.calls, listenerId: action.callsListenerId },
                        messages: { ...state.local.messages, listenerId: action.messagesListenerId }
                    },
                    local: {
                        calls: { ...state.local.calls, listenerId: action.callsListenerId },
                        messages: { ...state.local.messages, listenerId: action.messagesListenerId }
                    }
                }
            default:
                return state
    }
}

/***/
export { ListenerData, AutoReplyData, ListenerName, ReducerAction, reducer }
