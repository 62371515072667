import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setSmallView } from '../actions/view'
import ResizeAware from 'react-resize-aware'

import Billing from './Billing/Billing'
import Notifications from './Notifications/Notifications'
import BlockList from './BlockList/BlockList'

import HeaderTabs from 'header-tabs'
import ConfirmModal from 'confirm-modal'
import PhoneComUser from 'phone-com-user'
import { BillingIcon, NotificationIcon, BlockListIcon } from 'pdc-svg-icons'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentTabFromRoute } from 'app-tabs'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
    wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto'
    }
})

const mapStateToProps = state => ({
    currentTab: state.currentTab,
    smallView: state.smallView

})
const mapDispatchToProps = dispatch => ({
    setSmallView: boolVal => dispatch(setSmallView(boolVal))

})

const tabs = [
    { id: 'billing', icon: BillingIcon, default: true },
    // { id: 'notifications', icon: NotificationIcon },
    { id: 'block-list', icon: BlockListIcon }
]

if (process.env.REACT_APP_ENABLE_ACCOUNT_NOTIFICATIONS) tabs.splice(1, 0, { id: 'notifications', icon: NotificationIcon })

class App extends Component {
    constructor (props) {
        super(props)
        const currentTab = getCurrentTabFromRoute('account', this.props.routeProps, tabs)
        if (!currentTab) {
            const currentTab = tabs.find(t => t.default).id
            this.updateRouterHistory(currentTab)
        }
        this.state = { isBusy: false }
        this.defaultExtension = props.extension || null
        if (this.defaultExtension && this.defaultExtension.extension_id !== PhoneComUser.getExtensionId()) {
            PhoneComUser.changeExtension(this.defaultExtension.extension_id)
        }
    }

    onTabSwitch = newTab => {
        const currentTab = getCurrentTabFromRoute('account', this.props.routeProps, tabs)
        if (currentTab === newTab) return
        if (this.state.isBusy) {
            this.setState({ exitAttempt: newTab })
            return false
        }
        this.updateRouterHistory(newTab)
    }

    getBasePath = () => this.props.routeProps.match.path.replace(/\/+$/g, '')

    updateRouterHistory = tabName => {
        const basePath = this.getBasePath()
        this.props.routeProps.history.push(`${basePath}/${tabName}`)
    }

    setBusy = isBusy => this.setState({ isBusy })

    onExitAttemptAnswer = () => {}

    handleResize = size => {
        const needToExpand = this.props.smallView && !this.props.screenViewType.isMobileView
        const needToShrink = !this.props.smallView && this.props.screenViewType.isMobileView

        if (needToShrink) {
            this.props.setSmallView(true)
            // if (this.props.currentConversation) this.props.switchView('content')
        } else if (needToExpand) {
            this.props.setSmallView(false)
        }
    }

    getBasePath = () => this.props.routeProps.match.path.replace(/\/+$/g, '')

    renderApp = () => {
        const basePath = this.getBasePath()
        const defaultTabId = tabs.find(t => t.default).id
        return (
            <Switch>
                <Route path={`${basePath}/billing`} render={props => <Billing isCSR={this.props.isCSR} setBusy={this.setBusy}/>}/>
                <Route path={`${basePath}/notifications`} render={props => <Notifications setBusy={this.setBusy}/>}/>
                <Route path={`${basePath}/block-list`} render={props => <BlockList setBusy={this.setBusy}/>}/>
                <Route path='/' render={() => <Redirect to={`${basePath}/${defaultTabId}`}/>}/>
            </Switch>
        )
    }

    discardChanges = () => {
        const newTab = this.state.exitAttempt
        this.setState({ isBusy: false, exitAttempt: null }, () => this.onTabSwitch(newTab))
    }

    renderDiscardChangesModal = () => {
        return (
            <ConfirmModal
                isShown = {Boolean(this.state.exitAttempt)}
                title = 'Discard changes?'
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {() => this.setState({ exitAttempt: null })}
                onConfirm = {this.discardChanges}
                size = 'size2' // size440
            />
        )
    }

    render () {
        const { classes } = this.props
        const navigationHeight = this.props.smallView ? '0px' : '0px'
        const currentTab = getCurrentTabFromRoute('account', this.props.routeProps, tabs)
        return (
            <div className={classes.wrapper}>
                <ResizeAware
                    className='resize-aware'
                    style={{ height: `calc(100% - ${navigationHeight})` }}
                    onResize={this.handleResize}
                >
                    {currentTab ? <HeaderTabs smallView={this.props.smallView} selectedTabId={currentTab} tabs={tabs} onTabSwitch={this.onTabSwitch}/> : null}
                    {this.renderApp()}
                    {this.renderDiscardChangesModal()}
                </ResizeAware>
            </div>
        )
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
