<template>
<svg width="64" height="20" viewBox="0 0 64 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3435_51085)">
<rect width="64" height="20" rx="2" fill="currentColor"/>
<path d="M13.1316 12.4645V13.5H8.14407V6.85H12.9986V7.8855H9.37907V9.6145H12.5901V10.631H9.37907V12.4645H13.1316ZM20.6173 6.85V13.5H19.6008L15.9338 8.997V13.5H14.7083V6.85H15.7248L19.3918 11.353V6.85H20.6173ZM26.9122 11.961H23.5872L22.9317 13.5H21.6587L24.6512 6.85H25.8672L28.8692 13.5H27.5772L26.9122 11.961ZM26.5037 10.992L25.2497 8.085L24.0052 10.992H26.5037ZM34.627 10.042C34.9943 10.1623 35.2856 10.365 35.501 10.65C35.7163 10.9287 35.824 11.277 35.824 11.695C35.824 12.2713 35.6023 12.7178 35.159 13.0345C34.7156 13.3448 34.0696 13.5 33.221 13.5H29.915V6.85H33.031C33.8163 6.85 34.4211 7.00517 34.8455 7.3155C35.2698 7.6195 35.482 8.04067 35.482 8.579C35.482 8.90833 35.406 9.19967 35.254 9.453C35.102 9.70633 34.893 9.90267 34.627 10.042ZM31.15 7.819V9.6525H32.898C33.3286 9.6525 33.658 9.5765 33.886 9.4245C34.1203 9.26617 34.2375 9.03817 34.2375 8.7405C34.2375 8.4365 34.1203 8.2085 33.886 8.0565C33.658 7.89817 33.3286 7.819 32.898 7.819H31.15ZM33.145 12.531C34.1013 12.531 34.5795 12.2112 34.5795 11.5715C34.5795 10.9318 34.1013 10.612 33.145 10.612H31.15V12.531H33.145ZM37.342 6.85H38.577V12.455H42.054V13.5H37.342V6.85ZM48.2165 12.4645V13.5H43.229V6.85H48.0835V7.8855H44.464V9.6145H47.675V10.631H44.464V12.4645H48.2165ZM49.7933 6.85H52.7003C53.4096 6.85 54.0398 6.98933 54.5908 7.268C55.1418 7.54033 55.5693 7.92983 55.8733 8.4365C56.1773 8.93683 56.3293 9.51633 56.3293 10.175C56.3293 10.8337 56.1773 11.4163 55.8733 11.923C55.5693 12.4233 55.1418 12.8128 54.5908 13.0915C54.0398 13.3638 53.4096 13.5 52.7003 13.5H49.7933V6.85ZM52.6433 12.455C53.131 12.455 53.5585 12.3632 53.9258 12.1795C54.2995 11.9895 54.5845 11.7235 54.7808 11.3815C54.9835 11.0332 55.0848 10.631 55.0848 10.175C55.0848 9.719 54.9835 9.32 54.7808 8.978C54.5845 8.62967 54.2995 8.36367 53.9258 8.18C53.5585 7.99 53.131 7.895 52.6433 7.895H51.0283V12.455H52.6433Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_3435_51085">
<rect width="64" height="20" rx="2" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
  export default {};
</script>
