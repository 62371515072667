import React, { useContext } from 'react'
import { AppBar, makeStyles, Toolbar } from '@material-ui/core'
import styles from './styles'
import IdentityArea from '../../../../functional-components/identity-area/IdentityArea'
import { formatPhoneNumber } from 'phone-numbers'
import { MenuIcon } from 'svg-icons'
import { FeatureEventsContext, ScreenSizeContext } from 'providers'

const useStyles = makeStyles(styles)

interface Props {
    extension: {
        extension_id: number // eslint-disable-line
        phone_number: any // eslint-disable-line
        extension: number
        extension_name: string // eslint-disable-line
        is_virtual: 1 | 0 // eslint-disable-line
        is_default: 1 | 0 // eslint-disable-line
        caller_id: string // eslint-disable-line
        userId: number
    }
    companyName: string | null
    csr: boolean
    redirect: (path: string) => void
    toggleShowNavigation: (navigationShow: boolean) => void
    navigationShow: boolean
    callContext
}

/***/
export interface UserInfo {
    name: string
    number: string
    company: string
    accountId: number
    csr: boolean
}

const getUserInfo = (props: Props): UserInfo => {
    const V5PHONECOM = window.V5PHONECOM
    if (!V5PHONECOM) return { name: '', company: '', number: '', accountId: 0, csr: false }
    const isBJ = Boolean(V5PHONECOM.user_id)
    const name = isBJ ? `${V5PHONECOM.first_name} ${V5PHONECOM.last_name}` : (props.extension?.extension_name || '')
    const company = (props.companyName == null) ? V5PHONECOM.company : props.companyName
    const number = isBJ ? formatPhoneNumber(V5PHONECOM.direct_number) : (props.extension ? `Ext. ${props.extension.extension}` : '')
    const accountId = V5PHONECOM.true_account_id
    return { name, number, company, accountId, csr: !!props.csr }
}

const ToggleNav = (props: Props): JSX.Element | null => {
    const screenSizeContext = useContext(ScreenSizeContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    if (!screenSizeContext.mobile && !screenSizeContext.tablet) return null
    return (
        <div
            className = 'menu-button'
            onClick = {() => {
                featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-toggle-show-navigation-click', value: !props.navigationShow })
                props.toggleShowNavigation(!props.navigationShow)
            }
            }
            data-test-id = 'toggle-nav-hamburger-button'
        >
            <MenuIcon/>
        </div>
    )
}

/***/
export const TopBar = (props: Props): JSX.Element => {
    const classes = useStyles()
    return (
        <>
            <AppBar
                color = 'default'
                classes = {{ root: classes.appBar }}
                position = 'static'
            >
                <Toolbar classes={{ root: classes.toolbar }}>
                    <div className='top-bar-left'>
                        <ToggleNav {...props}/>
                        <IdentityArea {...props} userInfo={getUserInfo(props)} />
                    </div>
                    <div className='top-bar-right'>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default TopBar
