// React
import React from 'react'
// Styles
import './guide.css'
import PropTypes from 'prop-types'

// Guide
class Guide extends React.Component {
    renderDefaultTip = () => {
        return (
            <div className='guide-default-tip'>
                <span style={{ fontWeight: 'bold' }}>Pro Tip! </span>
                <span style={{ fontWeight: 'normal' }}>For international numbers, </span>
                <a href='https://www.phone.com/support-request/' target="_blank" style={{ color: '#3FAE29', fontWeight: 'normal' }} rel="noreferrer">contact our support team</a>
            </div>
        )
    }

    render = () => {
        if (!this.props.guideText) {
            return this.renderDefaultTip()
        }

        return (
            <div>
                <span className="guide-error">
                    <span dangerouslySetInnerHTML={{ __html: this.props.guideText }} />
                </span>
            </div>
        )
    }
}

Guide.propTypes = {
    guideText: PropTypes.string
}

export default Guide
