<template>
    <w-select
		v-on="$listeners"
		v-bind="$attrs"
		:items="items"
		class="descriptive-selector"
	>
		<template v-slot:selection="{ item, index }">
			<ShippingSelectorOption
				:text="item.text"
				:description="item.description"
				:label="item.label"
			/>
		</template>
		<template v-slot:item="{ active, item, attrs, on }">
			<ShippingSelectorOption
				:text="item.text"
				:description="item.description"
				:label="item.label"
			/>
		</template>
	</w-select>
</template>
<script>
  import ShippingSelectorOption from './SelectorOption.vue';

  export default {
    props: {
		items: {
			type: Array,
			validator: (v) => v.every((x) => Object.prototype.hasOwnProperty.call(x, 'text') && Object.prototype.hasOwnProperty.call(x, 'value'))
		}
	},
	components: {
		ShippingSelectorOption,
	},
    data() {
      return {
      };
    },
  };
</script>

<style lang="scss">
.descriptive-selector {
	.v-input__slot {
		height: 57px !important;
		max-height: 57px !important;
	}
}
</style>
