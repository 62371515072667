import React, { createContext } from 'react'
import gtmDataPush from 'gtm-events'

interface EventData {
    event?: string
    appName?: string
    key?: string
    value?: string | number | boolean
}

interface GTMData {
    event?: string
    PDC_Action?: string // eslint-disable-line
    PDC_Label?: string // eslint-disable-line
    PDC_Value?: string | number | boolean // eslint-disable-line
}

/***/
export interface FeatureEventsProviderContext { pushEvent: (data: EventData) => void }

interface Props { children: React.ReactElement }

/***/
export const FeatureEventsContext = createContext<FeatureEventsProviderContext>({ pushEvent: (data: EventData) => { /**/ } })

/***/
export const FeatureEventsConsumer = FeatureEventsContext.Consumer

const pushGTMEvent = (data: EventData) => {
    const gtmData: GTMData = {}
    if (data.event) gtmData.event = data.event
    if (data.appName) gtmData.PDC_Action = data.appName
    if (data.key) gtmData.PDC_Label = data.key
    if (data.value) gtmData.PDC_Value = data.value
    gtmDataPush(gtmData)
}

const pushEvent = (data: EventData) => {
    pushGTMEvent(data)
}

/***/
export const FeatureEventsProvider = (props: Props): JSX.Element => {
    return (
        <FeatureEventsContext.Provider value={{ pushEvent }}>
            {props.children}
        </FeatureEventsContext.Provider>
    )
}
