<template>
    <w-text-field
        :rules="rules"
        @keydown="auto_correct"
        v-model="val"
        :placeholder="placeholder"
        :label="with_label ? placeholder : undefined"
        :disabled="disabled"
        maxlength="7"
        hide-details='auto'
        data-test-id="canadian-postal-code-input"
    />
</template>
<script>
    import {specialKeys} from 'helpers';

  export default {
    props: {
        validation: {
            type: Boolean,
            default: false,
          },
          value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
        },
        with_label: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            val: '',
            placeholder: this.$lang.t('app.postal-code', 'Postal code')
      };
    },
    created() {
        this.$data.val = this.$props.value;
    },
    methods: {
        validate(v) {
            if (v) {
                const postal_regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]\d[ABCEGHJ-NPRSTV-Z]\d$/i; // h2z 1b8
                // postal_regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]\d[ABCEGHJ-NPRSTV-Z]\d$/i; // h2z 1b8, h2t-1b8
                // postal_regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i // h2z 1b8, h2t-1b8, H2Z1B8
                if (!postal_regex.test(v)) {
                    return this.$lang.t('app.invalid-postal-code', 'Invalid postal code');
                }
            }

            return true;
        },
        auto_correct(e) {
            const always_allowed = specialKeys;
            if (
                !e.ctrlKey
                && !e.altKey
                && !e.shiftKey
                && !e.metaKey
                && !always_allowed.includes(e.key)
            ) {
                e.preventDefault();
                const selObj = window.getSelection().toString();
                let value = selObj !== '' ? e.key : (this.$data.val || '') + e.key;
                if (!value.length) return this.$emit('input', '');

                const index = value.length - 1;
                const last_char = value[index];
                const letter_regex = /[ABCEGHJ-NPRSTV-Z]$/i;
                const num_regex = /^-?\d+$/;
                if (index === 0 && !/^[ABCEGHJ-NPRSTVXY]$/i.test(last_char)) {
                    return null;
                }
                if (index === 1 && !num_regex.test(last_char)) {
                    return null;
                }
                if (index === 2) {
                    if (!letter_regex.test(last_char)) {
                    return null;
                    }
                    if (e.keyCode !== 8) {
                        value += ' ';
                    }
                } else if (index === 3 && e.keyCode !== 8) {
                    value = `${value.substring(0, index)} ${value.substring(index + 1)}`;
                } else if (index === 4 && !num_regex.test(last_char)) {
                    return null;
                } else if (index === 5 && !letter_regex.test(last_char)) {
                    return null;
                } else if (index === 6 && !num_regex.test(last_char)) {
                    return null;
                }
                value = value.toUpperCase();
                if (value.length > 7) {
                    value = value.substring(0, 7);
                }
                this.$data.val = value.toUpperCase();
                this.$emit('input', this.$data.val);
            }
            return true;
        }
    },
    watch: {
        val(v) {
            this.$emit('input', v);
        }
    },
    computed: {
        rules() {
            let rules = [this.validate]
            if (Array.isArray(this.$attrs.rules)) {
                rules = rules.concat(this.$attrs.rules);
            }
            return rules;
        }
    }
  };
</script>
