<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M3.5 7.01169C3.22386 7.01169 3 7.23554 3 7.51169V10.4883C3 10.7645 3.22386 10.9883 3.5 10.9883H4.84167C5.37007 10.9883 5.877 11.1974 6.25173 11.57L8.14748 13.4546C8.46289 13.7682 9 13.5448 9 13.1V4.89995C9 4.45521 8.46289 4.23181 8.14748 4.54537L6.25173 6.43004C5.877 6.80258 5.37007 7.01169 4.84167 7.01169H3.5ZM12.6667 9C12.6667 8.11537 12.28 7.32497 11.6667 6.77941C11.3894 6.53271 11 6.76476 11 7.13594V10.8623C11 11.2323 11.3873 11.4645 11.6646 11.2196C12.2791 10.6768 12.6667 9.88563 12.6667 9ZM10.9353 3.35672C10.6317 3.25399 10.3333 3.49234 10.3333 3.81287V4.17931C10.3333 4.40082 10.4802 4.59335 10.6869 4.67306C12.4297 5.34518 13.6667 7.0298 13.6667 9C13.6667 10.9702 12.4297 12.6548 10.6869 13.3269C10.4802 13.4066 10.3333 13.5992 10.3333 13.8207V14.1871C10.3333 14.5077 10.6317 14.746 10.9353 14.6433C13.3004 13.843 15 11.6193 15 9C15 6.38069 13.3004 4.15695 10.9353 3.35672Z" fill="currentColor"/>
	</svg>

</svg>

</template>

<script>
  export default {};
</script>
