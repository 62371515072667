// import Contact from './contacts/Contact'
// import Contacts from './contacts/Contacts'

export * from './teammates/Teammate'
export * from './teammates/Teammates'

export * from './extensions/Extension'
export * from './extensions/Extensions'

export * from './MyPhoneContact'

export * from './contacts/Contact'
export * from './contacts/Contacts'

export * from './phone-numbers/PhoneNumber'
export * from './phone-numbers/PhoneNumbers'

/**
 *
 */
// export {
//     MyPhoneContact,
//     Teammate, Teammates,
//     Contact, Contacts,
//     Extension, Extensions
// }
