import { theme } from 'get-theme'
const mainTheme = theme

/**
 * Material ui styles
 *
 * @param {object} contextTheme - passed by the theme provider
 */
const styles = contextTheme => {
    const theme = (contextTheme.palette.secondary['-300']) ? contextTheme : mainTheme

    return {
        wrapper: {
            height: 87,
            borderTop: `1px solid ${theme.palette.secondary.border}`,
            paddingLeft: 65,
            paddingRight: 65,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: 15,
            background: theme.palette.paper.gray,
            '& .info-wrapper': {
                flex: 1,
                height: '100%',
                padding: '10.5px 0',
                display: 'flex',
                alignItems: 'center',
                columnGap: 15,
                '& img': {
                    height: '100%'
                },
                '& > p': {
                    display: 'inline-block',
                    maxHeight: '100%',
                    overflowY: 'auto'
                }
            },
            '& .buttons-wrapper': {
                display: 'flex',
                alignItems: 'center',
                columnGap: 20
            },
            '&.mobile': {
                justifyContent: 'center'
            }
        }
    }
}

export default styles
