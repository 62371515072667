import React, { Component } from 'react'
import { getValue } from 'firebase-utils'
import PropTypes from 'prop-types'

/**
 * gets the remote config from firebase for the given key, will also update the value if the state changes
 *
 * @property {string} valueId
 * @example
 * <RemoteConfigValue valueId='messages_tab_title'/>
 */
class RemoteConfigValue extends Component {
    /**
     * @property {sting} valueId the id of the remote config value being requested
     * @param {react-props | object} props
     */
    constructor (props) {
        super(props)
        this.state = {
            value: ''

        }
    }

    /**
     * @param {*} prevProps
     */
    componentDidUpdate (prevProps) {
        if (prevProps.valueId !== this.props.valueId) {
            this.setState({ value: getValue(this.props.valueId, this.handleValueUpdate) })
        }
    }

    /**
     *
     */
    componentDidMount () {
        this.setState({ value: getValue(this.props.valueId, this.handleValueUpdate) })
    }

    /**
     * @param {string} newValue
     */
    handleValueUpdate = (newValue) => {
        this.setState({ value: newValue })
    }

    /**
     *
     */
    render () {
        return (
            <>{this.state.value}</>
        )
    }
}

RemoteConfigValue.propTypes = {
    /**
     * the id of the remote config value being requested
     * ensure there is a default for this value, it can be added in utils/firebase-utils/
     */
    valueId: PropTypes.string.isRequired
}

export default RemoteConfigValue
/**
 * getValue - passed down from firebase-utils
 */
export { getValue }
