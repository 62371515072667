import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setSmallView } from '../../actions/view'
import { withStyles } from '@material-ui/core'
import TableMui from 'pdc-table-mui'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import PDCButton from 'button'
import { EditIcon } from 'svg-icons'
import E911Header from '../E911Header.js'
import PropTypes from 'prop-types'
import RemoteConfigValue from 'remote-config-value'
import { Tooltip } from '@material-ui/core'

const mapStateToProps = state => ({
    smallView: state.smallView,
    phones: state.phones,
    users: state.users
})

const mapDispatchToProps = dispatch => ({
    setSmallView: boolVal => dispatch(setSmallView(boolVal))
})

const styles = () => ({
    mainWrapper: {
        height: '100%',
        width: '100%'
    },
    table: {
        height: '100%'
    },
    tableHeadRow: {
        height: 50,
        fontSize: 16,
        fontWeight: 600
    },
    tableBody: {
        overflowX: 'auto',
        '& .pdc-loader-wrapper': {
            minHeight: 40,
            display: 'flex',
            alignItems: 'center',
            overflow: 'unset',
            margin: 'unset',
            transform: 'unset'
        }
    },
    tableRow: {
        minHeight: 64
    },
    e911_column: {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        '& .edit-button': {
            borderRadius: '50%',
            width: 30,
            height: 30,
            minWidth: 30,
            minHeight: 30,
            display: 'grid',
            placeItems: 'center',
            backgroundColor: 'rgb(133 146 154 / 15%)',
            '& > svg': {
                fontSize: 20,
                color: '#6E7A82'
            },
            '&:hover': {
                backgroundColor: '#29921B',
                '& > svg': {
                    color: 'white'
                }

            }
        }
    },
    e911_link: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '15ch',
        marginRight: 5
    },
    tooltip: {
        backgroundColor: '#48B9D5',
        fontSize: 12
    },
    menuItem: {
        padding: 0,
        pointerEvents: 'unset !important'
    },
    tooltipInnerDiv: {
        padding: '6px 12px',
        height: '100%',
        width: '100%'
    }

})

class MyPhonesContent extends Component {
    constructor (props) {
        super(props)
        this.state = {
            assignAction: false
        }
    }

    showEditAddressModal = phoneDeviceId => this.props.showEditAddressModal(phoneDeviceId)

    onAssignChange = (userId, deviceId) => {
        const user = this.props.users.find(u => u.id === userId)
        if (user?.user_plan_id === 1) return

        const action = userId === 0 ? 'unassign' : 'assign'
        this.props.showAssignActionDialog(action, deviceId, userId)
    }

    getAssignToDropdownItems = () => {
        const assignedToMenuItems = [
            { value: 0, content: 'Unassigned' }
        ]
        let basicUserTooltip = {}
        this.props.users.forEach(user => {
            basicUserTooltip = {
                title: <><p><RemoteConfigValue valueId={'configure_phones_manage_phones_assigned_to_basic_1'}/></p><RemoteConfigValue valueId={'configure_phones_manage_phones_assigned_to_basic_2'}/></>,
                placement: 'right'
            }
            assignedToMenuItems.push({ value: user.id, content: `${user.first_name} ${user.last_name} - ${user.extension.data.extension_number}`, tooltip: user.user_plan_id === 1 ? basicUserTooltip : null, disabled: user.user_plan_id === 1 })
        })

        return assignedToMenuItems
    }

    generateTableHeadColumns = () => {
        const headColumns = [
            { content: 'Label', testId: 'label-col' },
            { content: 'Ext', testId: 'ext-col' },
            { content: 'Assigned To', testId: 'assigned-col' },
            { content: 'Model', testId: 'model-col' },
            { content: 'Mac Address', testId: 'mac-col' },
            { content: <E911Header incompleteE911Count={this.props.incompleteE911Count} />, testId: 'e911-col' }
        ]
        return headColumns
    }

    renderAssignedToColumn = phoneDevice => {
        const { classes } = this.props
        let assignedToDefault = 0
        this.props.users.forEach(user => {
            if (phoneDevice?.assigned_to === user.id) {
                assignedToDefault = user.id
            }
        })

        return (
            <Select
                labelId="assign-device-select-label"
                id="assign-device-select"
                disabled={this.props.assignLoading}
                fullWidth
                value={assignedToDefault}
                label={''}
                showAssignActionDialog
                onChange={e => this.onAssignChange(e.target.value, phoneDevice.id)}
            >
                {this.getAssignToDropdownItems().map((item, i) => (
                    <MenuItem classes= {{ root: classes.menuItem }} data-menu-item={item.content} key={i} value={item.value} disabled={item.disabled}>
                        <Tooltip classes={{ tooltip: classes.tooltip }} enterTouchDelay={0} interactive disableFocusListener key={i} title={item.tooltip?.title || ''} placement={item.tooltip?.placement}>
                            <div className={classes.tooltipInnerDiv} onClick={(e) => e.preventDefault()}>
                                {item.content}
                            </div>
                        </Tooltip>
                    </MenuItem>

                ))}
            </Select>
        )
    }

    renderE911Column = phoneDevice => {
        const { classes } = this.props
        return (
            phoneDevice.assigned_to !== null
                ? phoneDevice.e911_address.address1
                    ? <div className={classes.e911_column} data-button='edit-button' onClick={() => this.showEditAddressModal(phoneDevice.id)}><span className={classes.e911_link} title={phoneDevice.e911_address.address1}>{phoneDevice.e911_address.address1}</span><div className='edit-button'><EditIcon/></div></div>
                    : <PDCButton variant='graphic' onClick={() => this.showEditAddressModal(phoneDevice.id)} data-button='open-e911-modal' icon='add' color='attention'></PDCButton>
                : '-'
        )
    }

    generateOneRow = phoneDevice => {
        let assigned_to = 'Unassigned'
        this.props.users.forEach(user => {
            if (phoneDevice?.assigned_to === user.id) {
                assigned_to = `Assigned to: ${user.first_name} ${user.last_name}`
            }
        })

        const columns = [
            { key: 0, content: this.props.renderPhoneNameField(phoneDevice), primary: true, stringValue: phoneDevice.name, testId: 'label-cell' },
            { key: 1, content: phoneDevice.extension ? phoneDevice.extension : '-', testId: 'extension-cell' },
            { key: 2, content: this.renderAssignedToColumn(phoneDevice), secondary: true, stringValue: assigned_to, testId: 'assigned-to-cell' },
            { key: 3, content: phoneDevice.model, testId: 'model-cell' },
            { key: 4, content: phoneDevice.mac_address || '-', testId: 'mac-address-cell' },
            { key: 5, content: this.renderE911Column(phoneDevice), testId: 'e911-cell' }
        ]
        return { key: phoneDevice.id, columns }
    }

    generateTableRows = () => {
        const rows = this.props.phones.map(phoneDevice => {
            return this.generateOneRow(phoneDevice)
        })
        return rows
    }

    render () {
        const { classes } = this.props
        return (
            <div className={classes.mainWrapper}>
                <TableMui
                    headColumns = {this.generateTableHeadColumns()}
                    rows = {this.generateTableRows()}
                    infiniteScroller = {true}
                    reverseScroll = {false}
                    loadMore = {this.props.loadMore}
                    hasMore = {this.props.hasMore}
                    listView = {this.props.smallView}
                    dataAttr = {'phones-table'}
                />
            </div>

        )
    }
}

MyPhonesContent.propTypes = {
    classes: PropTypes.object,
    hasMore: PropTypes.bool,
    incompleteE911Count: PropTypes.number,
    loadMore: PropTypes.bool,
    phones: PropTypes.array,
    renderPhoneNameField: PropTypes.func,
    showEditAddressModal: PropTypes.func,
    smallView: PropTypes.bool,
    users: PropTypes.array,
    assignLoading: PropTypes.bool,
    unassignDevice: PropTypes.func,
    assignDevice: PropTypes.func,
    showAssignActionDialog: PropTypes.func
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyPhonesContent))
