import React, { useContext } from 'react'
import { ScreenSizeContext } from 'providers'
import { makeStyles } from '@material-ui/core'

const styles = () => ({
    appWrapper: {
        width: '100%',
        height: '100%',
        maxWidth: 1050,
        padding: '50px 65px 65px',
        position: 'relative',
        overflowY: 'auto',
        '&.tablet-view': {
            padding: '30px 50px 65px'
        },
        '&.mobile-view': {
            padding: '30px 25px 50px'
        }
    }
})

const useStyles = makeStyles(styles)

interface Props {
    children: React.ReactElement
}

/***/
export const AppWrapper = (props: Props): JSX.Element => {
    const classes = useStyles()
    const screenSizeContext = useContext(ScreenSizeContext)
    return <div {...props} data-test-id='app-wrapper' className={`${classes.appWrapper} ${screenSizeContext.classes}`}>{props.children}</div>
}

export default AppWrapper
