
import React, { useEffect, useState } from 'react'
import { getPhoneCom } from 'phonecom'
// import { useToggle } from 'hooks'
import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getValue } from 'firebase-utils'
declare global{
    interface Process {
        env: Map<string, string>
    }
}

interface Props {
    flag: string
    flagDisabled?: boolean
    children: React.ReactElement
}

interface FeatureQuery {
    voipId: string
    voipPhoneId?: string
    userId?: string
    feature: string
}

const queryFeatureCache = new Map<string, boolean>()
// eslint-disable-next-line func-call-spacing
const pendingQueryCallbacks = new Map<string, (Array<(isEnabled)=>void>)>()
// todo - add service worker support for query call and update queryUpdateCallback to actually update
// queryUpdateCallback - is currently a stub for when then sw is updated to support query calls and push for cache updates
const queryFeatureEnabled = async (query: (FeatureQuery), queryUpdateCallback: (isEnabled) => void = (isEnabled) => null): Promise<boolean> => {
    const feature = query.feature
    const V5PhoneComFeatures = (await getPhoneCom()).features
    const requestUrl = `${PhoneComUser?.getv5ApiRoot()?.replace('services', 'app')}/feature-toggle/get-feature-toggle`

    // if response was cached use cached response
    if (queryFeatureCache.has(feature) || V5PhoneComFeatures.has(feature)) {
        // console.log('queryFeatureCache', { feature, queryFeatureCache, V5PhoneComFeatures })
        const cachedValue = queryFeatureCache.get(query.feature) || V5PhoneComFeatures.has(feature)
        if (typeof cachedValue === 'boolean') {
            return Promise.resolve(cachedValue)
        }
    }
    // console.log('queryFeatureCache no cache', { feature, queryFeatureCache, V5PhoneComFeatures })
    // if there are no pending requests for this feature, make a new request
    if (!pendingQueryCallbacks.has(feature)) {
        pendingQueryCallbacks.set(feature, [])
        ajax.post(requestUrl, query).then(response => {
            const isEnabled = response?.data?.enabled
            if (isEnabled !== undefined && isEnabled !== null) {
                queryFeatureCache.set(query.feature, isEnabled)
            }

            pendingQueryCallbacks.get(feature)?.forEach(callback => {
                callback(isEnabled)
            })
            // delete callback queue as any fucture requests will use the cacahe
            pendingQueryCallbacks.delete(feature) // reduces memory usage
        })
    }

    // add a callback to the pending request for this feature
    return new Promise(function (resolve) {
        pendingQueryCallbacks.get(feature)?.push(
            (isEnabled) => {
                resolve(isEnabled)
            }
        )
    })
}
interface FeatureToggle {
    voipId: string
    voipPhoneId?: string
    userId?: string
    enable: boolean
}

// eslint-disable-next-line @typescript-eslint/naming-convention
let REQUESTED_V4_FEATURES = false
const setConsoleFeature = async () => {
    const phoneCom = await getPhoneCom()
    if (process.env.REACT_APP_PHOENIX_URL && !REQUESTED_V4_FEATURES && phoneCom.ac_token) {
        REQUESTED_V4_FEATURES = true
        let features = []

        const url = `${process.env.REACT_APP_PHOENIX_URL}/v4/accounts/${phoneCom.voip_id}/feature-toggles`
        const headers = {
            Authorization: `Bearer ${phoneCom.ac_token}`,
            'Content-Type': 'application/json'
        }
        const res = await fetch(url, {
            method: 'GET',
            headers
        })
        if (res.ok) {
            features = await res.json()
        }

        if (Array.isArray(features)) phoneCom.features = new Set([phoneCom.features, new Set(features)])
    }
}

/**
 * set feature toggle
 *
 * @param request the feature toggle request
 */
export const toggleSoftphoneFeature = async (request: FeatureToggle): Promise<boolean> => {
    const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/feature-toggle/softphone-feature-toggle`
    const response = await ajax.post(requestUrl, request)
    return response?.success
}
/**
 * get feature flag by name
 *
 * @param feature feature name
 */
export const getFeatureEnabled = async (feature: string, flagUpdatedCallback: (isEnabled)=>void = () => null): Promise<boolean> => {
    const remoteConfigValueCallback = (value) => {
        if (value && ['true', 'false', true, false].includes(value)) {
            flagUpdatedCallback(`${value}` === 'true')
        }
    }

    return getPhoneCom().then(async phoneCom => {
        const featureQuery = {
            feature: feature,
            voipId: phoneCom.voip_id.toString(),
            voipPhoneId: phoneCom.voip_phone_id.toString(),
            userId: phoneCom.user_id.toString()
        }
        setConsoleFeature()
        const userFeatureEnabled = await queryFeatureEnabled(featureQuery, flagUpdatedCallback)
        const buildFeatureEnabled = `${process?.env[feature]}`.toLowerCase() === 'true'
        const remoteFeatureEnabled = getValue(feature, remoteConfigValueCallback, false) === 'true'
        return (userFeatureEnabled || buildFeatureEnabled || remoteFeatureEnabled)
    })
}

/***/
const FeatureFlag = ({ flag, flagDisabled, children }: Props): JSX.Element => {
    const [featureEnabled, setFeatureEnabled] = useState(false)
    const updateFlag = (isEnabled) => {
        if (featureEnabled !== isEnabled)setFeatureEnabled(isEnabled)
    }
    const GetFlag = () => {
        getFeatureEnabled(flag, updateFlag).then(updateFlag)
    }

    useEffect(() => { GetFlag() }, [flagDisabled, flag])
    const onFalse = flagDisabled === true
    const showFeature = (featureEnabled && !onFalse) || (!featureEnabled && onFalse)
    return showFeature ? children : (<></>)
}

export default FeatureFlag
