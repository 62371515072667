/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
// eslint-disable @typescript-eslint/explicit-function-return-source

import { Contact } from './contacts/Contact'
import { Teammate } from './teammates/Teammate'
import { Extension } from './extensions/Extension'
import { formatPhoneNumber } from 'phone-numbers'
class PhoneNumber {
    number = ''
    type = 'home'
    name = ''
    // extension, home, mobile, business
}
/**
 *
 */
export class MyPhoneContact {
    displayName = ''
    editable = false
    numbers: Map<string, PhoneNumber> = new Map()
    source = 'contact'
    // contact, teammate, legacy, native
    id = ''
    data: any = {}
    avatarUrl: string = null

    /**
     * @param c
     */
    static fromContact = (c: Contact): MyPhoneContact => {
        const contact = new MyPhoneContact()
        contact.source = 'pdcContacts'
        contact.editable = true
        contact.displayName = c.name.display
        contact.id = c.id + '@contact'
        contact.data.ownerId = c.voip_phone_id
        c.numbers.forEach((v, k) => {
            const p = new PhoneNumber()
            p.number = formatPhoneNumber(v.number)
            p.name = v.number
            p.type = v.type
            contact.numbers.set(k, p)
        })
        return contact
    }

    /**
     * @param t
     */
    static fromTeammate = (t: Teammate): MyPhoneContact => {
        const contact = new MyPhoneContact()
        contact.source = 'pdcExtensions'
        contact.displayName = t.displayName()
        contact.id = t.id + '@extension'
        contact.avatarUrl = t.avatar_url
        t.numbers.forEach((v, k) => {
            const p = new PhoneNumber()
            p.number = v.number
            p.name = v.name
            p.type = v.route_type
            contact.numbers.set(k, p)
        })
        const ext = new PhoneNumber()
        const t_ext = t.extension
        ext.number = String(t_ext.number)
        ext.name = t_ext.name
        ext.type = 'extension'
        contact.numbers.set(`+${ext.number}`, ext)
        return contact
    }

    /**
     * @param e
     */
    static fromExtension = (e: Extension): MyPhoneContact => {
        const contact = new MyPhoneContact()
        contact.source = 'pdcExtensions'
        contact.displayName = e.extension_name
        contact.id = e.extension_id + '@extension'
        const ext = new PhoneNumber()
        ext.number = String(e.extension)
        ext.name = e.extension_name
        ext.type = 'extension'
        contact.numbers.set(`+${ext.number}`, ext)
        return contact
    }

    hasNumber = (number: string) : boolean => {
        number = `+${number.replace(/[^\d]/g, '')}`
        return this.numbers.has(number)
    }
}
