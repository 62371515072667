import React from 'react'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import { withStyles } from '@material-ui/core'
import styles from './styles'

interface Props {
    selectedValue
    onChange: (event) => void
    classes
    users
    showValidationErrors: boolean

    label?: string
    isMultiple?: boolean
    companyExtension?
    dataTestId?
    dataTestIdMenuItemPrefix?
}

const RequiredFieldErrorText = 'Required field'

/**
 * Will render a Select field with users
 *
 * @param {Props} props
 */
const UsersDropdown = (props: Props): JSX.Element => {
    const {
        classes,
        users,
        label,
        selectedValue,
        isMultiple,
        onChange,
        companyExtension,
        dataTestId,
        dataTestIdMenuItemPrefix,
        showValidationErrors
    } = props
    const validationError = showValidationErrors && (isMultiple ? selectedValue.length === 0 : selectedValue === '') ? RequiredFieldErrorText : null
    const formattedUsers = users.map(u => ({
        // It is important in the value to have the extension id at the beginning followed by a dash (-).
        value: `${u.extension.id}-${u.first_name.replace(/\s/g, '')}-${u.last_name.replace(/\s/g, '')}`,
        content: (u.first_name || u.last_name) ? `${u.first_name} ${u.last_name}` : `Ext: ${u.extension.number}`
    }))
    if (companyExtension) {
        formattedUsers.unshift({
            value: `${companyExtension.id}-Company-Inbox`,
            content: 'Company inbox'
        })
    }
    const dataTestIdProp = dataTestId ? { 'data-test-id': dataTestId } : {}
    return (
        <Select
            label = {label || null}
            value = {selectedValue}
            onChange = {onChange}
            error = {!!validationError}
            helperText = {validationError || null}
            multiple = {!!isMultiple}
            MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
            formControlClasses = {{ root: `${classes.dropdownMenu} size-lg` }}
            {...dataTestIdProp}
        >
            {formattedUsers.map((user, i) => {
                return (
                    <MenuItem
                        key = {i}
                        value = {user.value}
                        checked = {isMultiple ? selectedValue.includes(user.value) : (user.value === selectedValue)}
                        checkbox = {!!isMultiple}
                        data-test-id = {`${dataTestIdMenuItemPrefix || ''}${user.value}`}
                    >{user.content}</MenuItem>
                )
            })}
        </Select>
    )
}

export default withStyles(styles)(UsersDropdown)
