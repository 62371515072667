import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { ChevronRight } from 'pdc-svg-icons'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import TableListViewDetails from './TableListViewDetails'
import PropTypes from 'prop-types'

const styles = theme => ({
    listViewContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 64,
        width: '100%',
        marginBottom: 15,
        '&:hover': {
            backgroundColor: '#ceefc780',
            cursor: 'pointer'
        }
    },
    rowLeft: {
        display: 'flex',
        flexDirection: 'column',
        '& .primary': {
            fontSize: 16,
            lineHeight: '26px',
            fontWeight: 500,
            color: '#364047'
        },
        '& .secondary': {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '19px',
            color: '#6E7A82'
        }
    },
    rowRight: {
        '& svg': {
            color: '#364047',
            height: '14.6px'
        }
    }

})

class TableListView extends Component {
    constructor (props) {
        super(props)
        this.state = {
            detailsRowKey: null
        }
    }

    closeDetails = () => this.setState({ detailsRowKey: null })

    renderOneRow = (row) => {
        const { classes } = this.props
        const primaryColumn = row.columns.find(c => c.primary)
        const secondaryColumn = row.columns.find(c => c.secondary)
        return (
            <div key={row.key} className={classes.rowContainer} onClick={() => { this.setState({ detailsRowKey: row.key }) }}>
                <div className={classes.rowLeft}>
                    <div className='primary'>{primaryColumn?.stringValue}</div>
                    <div className='secondary'>{secondaryColumn?.stringValue}</div>
                </div>
                <div className={classes.rowRight}>
                    <ChevronRight/>
                </div>
            </div>
        )
    }

    renderList = () => {
        const { classes } = this.props
        return (
            <div className={classes.listViewContainer}>
                <InfiniteScroller
                    reverseScroll = {false}
                    loadMore = {this.props.loadMore}
                    hasMore = {this.props.hasMore}
                    loader = {<Spinner/>}
                >
                    {this.props.rows.map(this.renderOneRow)}
                </InfiniteScroller>
            </div>
        )
    }

    renderDetails = () => {
        return (
            <TableListViewDetails
                row={this.props.rows.find(r => r.key === this.state.detailsRowKey)}
                headColumns={this.props.headColumns}
                closeDetails={this.closeDetails}
            />
        )
    }

    render () {
        return this.state.detailsRowKey ? this.renderDetails() : this.renderList()
    }
}

TableListView.propTypes = {
    classes: PropTypes.any,
    headColumns: PropTypes.array,
    rows: PropTypes.array,
    closeDetails: PropTypes.func,
    loadMore: PropTypes.func,
    hasMore: PropTypes.func
}

export default withStyles(styles)(TableListView)
