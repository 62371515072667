<template>
    <div>
        <div class="address-form">
            <Country
                :available_countries="available_countries"
                auto-select-first
                :value="address.country"
                @input="(v) => on_address_input('country', v)"
                @change="on_country_change"
                :with_label="with_labels"
                :rules="[$validation.required()]"
                :disabled="disabled"
                class="w-100"
                autocomplete="new-password"
                data-test-id="acc-contact-country-selector"
            />
            <Line1
                :value="address.line_1"
                @input="(v) => on_address_input('line_1', v)"
                @change="(v) => on_address_input('line_1', v)"
                :with_label="with_labels"
                :rules="[$validation.required()]"
                :disabled="disabled"
                class="w-100"
                autocomplete="street-address"
                required
            />
            <Line2
                :value="address.line_2"
                @input="(v) => on_address_input('line_2', v)"
                :placeholder="l.t('app.apt-suite-etc', 'Apt, Suite, etc')"
                :disabled="disabled"
                :with_label="with_labels"
                class="w-100"
            />
            <div class="d-flex justify-space-between bottom-inputs">
                <City
                    :value="address.city"
                    @input="(v) => on_address_input('city', v)"
                    :rules="[$validation.required()]"
                    :disabled="disabled"
                    :with_label="with_labels"
                    class="city-input"
                    required
                />
                <State
                    :value="address.province"
                    :country="address.country"
                    @input="(v) => on_address_input('province', v)"
                    :rules="[$validation.required()]"
                    :disabled="disabled"
                    :with_label="with_labels"
                    class="state-input"
                />
                <Zip
                    :value="address.postal_code"
                    :country="address.country"
                    @input="(v) => on_address_input('postal_code', v)"
                    :rules="[$validation.required()]"
                    :validation="true"
                    :disabled="disabled"
                    :with_label="with_labels"
                    class="postal-code-input"
                    required                
                />
            </div>
        </div>
    </div>
</template>
<script>
    import {emptyAddress} from 'helpers';
    import l from '../../../libs/lang';
    import Zip from './address/Zip.vue';
    import City from './address/City.vue';
    import Line1 from './address/Line1.vue';
    import Line2 from './address/Line2.vue';
    import State from './address/State.vue';
    import Country from './address/Country.vue';
    export default {
        props: {
            value: {
                type: [Object, null],
            },
            disabled: {
                type: Boolean,
            },
            with_labels: {
                type: Boolean,
                default: false,
            },
            available_countries: {
                required: false,
            }
        },
        components: {
            Zip,
            City,
            Line1,
            Line2,
            State,
            Country,
        },
        data() {
            return {
                l,
                empty_address: emptyAddress(),
                address: {},
          };
        },
        created() {
            if (this.$props.value) this.$data.address = JSON.parse(JSON.stringify(this.$props.value));
        },
        methods: {
            on_address_input(prop, v) {
                this.$data.address[prop] = v;
                this.$emit('input', this.$data.address);
                this.$forceUpdate();
            },
            on_country_change(val) {
                for (const key of Object.keys(this.$data.empty_address)) {
                    if (key !== 'country') {
                        this.$data.address[key] = '';
                    }
                }
                this.$data.address.country = val;
                this.on_address_input('country', val)
            },
        },
    };
</script>
