<template>
    <USStatesSelector
        v-if="country === 'US'"
        v-bind="$attrs"
        v-on="$listeners"
        :with_label="with_label"
    />
    <CanadaProvincesSelector
        v-else-if="country === 'CA'"
        v-bind="$attrs"
        v-on="$listeners"
        :with_label="with_label"
    />
    <w-text-field
        v-else
        :placeholder="placeholder"
        :label="with_label ? placeholder : undefined"
        />
    </template>
    <script>
    import USStatesSelector from './USStatesSelector.vue'
    import CanadaProvincesSelector from './CanadaProvincesSelector.vue'
    export default {
        props: {
            country: {
                type: String,
                validator: (v) => v ? v.length === 2 : true
            },
            with_label: {
                type: Boolean,
                default: false
            }
    },
    data() {
        return {
            placeholder: this.$lang.t('app.state', 'State')
        }
    },
    components: {
        USStatesSelector,
        CanadaProvincesSelector,
    },
  };
</script>
