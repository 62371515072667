<template>
  <div v-if="has_feature" id="onvego-greeting-helper-container" class="onvego-greeting-helper-container">
    <div class="w-h6">
        {{ greeting }}
        <span class="primary--text">, {{additional_text}}</span>
    </div>
  <div class="iab-response">
    <v-icon color="info" size="18">$vuetify.icons.transcript</v-icon>
    <span class="iab-response-text w-btn-small info--text">{{$lang.t('lr.ai-connect-response', 'AI-Connect response')}}</span>
  </div>
  </div>
</template>
<script>
import {getFeatureEnabled} from 'feature-flag';
    export default {
    props: {
        greeting: {
            type: String,
        },
        contacts: {
            type: Array,
        },
        code: {
            type: Number,
            required: true,
        },
        appointments_enabled: {
            type: Boolean,
        }
    },
    data() {
      return {
        has_feature: false,
        has_IDK_feature: false,
        additional_text: '',
    };
    },
    async created() {
        this.$data.has_feature = await getFeatureEnabled('console.1.1_call_flow');
        this.$data.has_IDK_feature = await getFeatureEnabled('console.i_dont_know');
        this.$data.additional_text = this.prepare_additional_text();
    },
    methods: {
        prepare_additional_text() {
            if (this.$props.contacts.length === 1) {
                return this.$lang.t(
                    'lr.Im-happy-to-connect-you',
                    'I\'m happy to connect you.'
                );
            }
            const company_name = this.$session.user.account.contact.company
            const common_greeting = this.$lang.t(
                'lr.onvego-greeting-multiple-contacts-with-idk-ft',
                'Thank you for calling {}, I\'m happy to connect you, just say the name of the person or team you\'d like to reach. If you don\'t know, say "I don\'t know". So, who would you like to speak with?',
                [company_name]
            );
            if (this.$props.code === 19087) { // standard
                if (this.$data.has_IDK_feature) {
                    return common_greeting
                }
                return this.$lang.t(
                    'lr.onveg-ogreeting-multiple-contacts-without-idk-ft',
                    'Thank you for calling {} , I\'m happy to connect you, just say the name of the person or team you\'d like to reach.  So, who would you like to speak with?',
                    [company_name]
                )
            }
            // enhanced
            if (!this.$props.appointments_enabled) {
                if (this.$data.has_IDK_feature) {
                    return common_greeting
                }
                return this.$lang.t(
                    'lr.onvego-greeting-enhanced-calendar-off-without-idk-ft',
                    'Thank you for calling {}, I\'m happy to connect you, just say the name of the person or team you\'d like to reach. If you don\'t know, say "I don\'t know". So, who would you like to speak with?',
                    [company_name]
                    )
                }
                
            return this.$lang.t(
                'lr.onveg-greeting-enhanced-calendar-on',
                'Thank you for calling {}, I can connect you to the appropriate person or team, schedule a new appointment or assist with rescheduling or canceling an existing one. What would you like to do?',
                [company_name]
            )
        }
    }
  }
</script>

<style lang="scss" scoped>
    $carret-height: 20px;
    .onvego-greeting-helper-container {
        position: relative;
        background: #E6F7E3;
        filter:
            drop-shadow(0px 2px 0px #9ECE95)
            drop-shadow(0px -1px 0px #9ECE95)
            drop-shadow(1px 0px 0px #9ECE95)
            drop-shadow(-1px 0px 0px #9ECE95);
        margin-top: calc($carret-height + 11px);
        border-radius: 10px;
        padding: 20px;
    }
    .onvego-greeting-helper-container:after, .onvego-greeting-helper-container:before {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .onvego-greeting-helper-container:after {
        border-bottom-color: #E6F7E3;
        border-width: $carret-height;
        margin-left: -$carret-height;
    }
    .onvego-greeting-helper-container:before {
        border-width: 36px;
        margin-left: -36px;
    }
    .iab-response {
        margin-top: 18px;
        .iab-response-text {
            margin-left: 6px;
        }
    }
</style>
