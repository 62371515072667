//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import l from 'lang';
export default {
  props: {
    'message': {
      'type': String,
      'required': true
    },
    'level': {
      'type': String,
      'required': false,
      validator: function validator(val) {
        return ['error', 'warning', 'success'].includes(val);
      }
    },
    'closable': {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      l: l,
      show: true
    };
  },
  computed: {
    icon: function icon() {
      if (this.$props.level === 'success') {
        return '$vuetify.icons.check';
      }
      if (this.$props.level === 'warning') {
        return '$vuetify.icons.infotip_stylized';
      }
      return '$vuetify.icons.exclamation';
    },
    icon_classes: function icon_classes() {
      var classes = {
        'my-auto white--text': this.$props.level !== 'warning'
      };
      classes[this.icon_color] = true;
      return classes;
    },
    icon_color: function icon_color() {
      if (this.$props.level === 'success') {
        return 'info';
      }
      if (this.level === 'warning') {
        return 'warning--text text--darken1';
      }
      return this.$props.level;
    }
  },
  watch: {
    show: {
      // eslint-disable-next-line object-shorthand
      handler: function handler(val) {
        if (!val) {
          this.$emit('closed');
        }
      }
    }
  }
};