import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import StripeCreditCard from 'stripe-credit-card'
import Statements from '../Statements/Statements'
import CancelAccount from '../CancelAccount/CancelAccount'
import InternationalDialing from '../InternationalDialing/InternationalDialing'

const styles = theme => ({
	mainWrapper: {
		padding:	40,
		height:		'100%',
		oveflowY:	'auto',
		'&.small': {
			marginTop:		10,
			marginLeft: 	10,
			marginRight:	10
		}
	},
	section:{
		marginBottom: 50,
		'&.cardSection':{
			minHeight: 270
		},
		'&.last': {
			height: 185,
			marginBottom: 0
		}
	},
	sectionHeader: {
		background:	theme.palette.secondary[-100],
		color:			'white',
		fontSize:		14,
		fontWeight:		'bold',
		lineHeight:		'18px',
		padding:		20,
		borderRadius:	2,
		textTransform:	'uppercase'
	},
	sectionContent:{
		display:		'flex',
		flexDirection:	'column',
		marginTop:	20,
		'&.small': {
			justifyContent:	'center',
			alignItems:		'center',
			textAlignt:		'center'
		}
	},
	infotText: {
		fontSize: 20,
		marginBottom: 10,
		'& span': {
			color: theme.palette.primary[0],
			cursor: 'pointer',
			'&:hover': {
				color: theme.palette.primary['+100'],
			}
		}
	}
})

const mapStateToProps = state => ({
	smallView:	state.smallView,
})

const mapDispatchToProps = dispatch => ({
})
class Billing extends Component {

	updateCurrentPrimaryCard = (res) => {
	}

	render() {
		const { classes } = this.props
		let cfg = window.APP_CONFIG
		let cpUrl = cfg.cpBase
		let redirectUrl = `${cpUrl}/account/billing`
		return (
			<div className={`${classes.mainWrapper} ${this.props.smallView ? 'small' : ''}`}>
				<div className={classes.section}>
					<div className={classes.sectionHeader}>STATEMENTS</div>
					<div className={classes.sectionContent}>
						<Statements/>					
					</div>
				</div>
				<div className={`${classes.section} cardSection &`}>
					<div className={classes.sectionHeader}>CARD ON FILE</div>
					<div className={`${classes.sectionContent} ${this.props.smallView ? 'small': ''}`}>
						<StripeCreditCard currentPrimaryCard={null} updateCurrentPrimaryCard={this.updateCurrentPrimaryCard} smallView={false}/>
					</div>
				</div>
				{/* <div className={classes.section}>
					<div className={classes.sectionHeader}>INTERNATIONAL DIALING</div>
					<div className={classes.sectionContent}>
						<InternationalDialing isCSR={this.props.isCSR}/>					
					</div>
				</div> */}
				<div className={`${classes.section} last`}>
					<div className={classes.sectionHeader}>CANCEL ACCOUNT</div>
					<div className={classes.sectionContent}>
						<CancelAccount/>					
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Billing))