import React from "react";

import "./city-selector.css"
import Loader from '../../util/loader/loader'
import Spinner from 'spinner';
import SelectionControl from 'selection-control';

class CitySelector extends React.Component {

  state = {
    searchValue: ""
  }
  
  componentDidMount = () => {
    if (this.props.selectedCities.length > 0) {
      this.setState({searchValue: ''})
    }
  }

  onCitiesSearch = (e) => {
    let targetValue = e.currentTarget.value.trimLeft()
    let searchTerm = ''
    Array.from(targetValue).forEach(c => (c !== ',' || !searchTerm.includes(',')) ? searchTerm += c : null)
    this.setState({searchValue: searchTerm})
    if (searchTerm.length < 2 && searchTerm.length > 0 || targetValue !== searchTerm || searchTerm.slice(-1) == ',' || searchTerm.slice(-1) == ' ') {
      // Only search if user has entered at least three characters
      clearTimeout(this.timeoutValue)
      return;
    }



    clearTimeout(this.timeoutValue)
    this.timeoutValue = setTimeout(this.props.CitySearch.bind(this, searchTerm), 400)

  }


  keyPress = (e) => {
    if(e.keyCode == 13){
      let searchTerm = e.currentTarget.value
      if (this.props.availableCities.includes(searchTerm) || searchTerm == ""){
        this.props.handleSelectChange(searchTerm)
      }
    }
  }
  
  render() {

    const cities = [];
    let selectedCitiesList = [];
    let hasSelectedCities = this.props.selectedCities.length > 0;
    let hasSearchResults = this.props.availableCities.length > 0;
    let displayed_cities = this.props.availableCities;
    if (this.props.filteredCities.length > 0 && this.props.selectedCities.length > 0 && this.props.selectedCities[0] == this.state.searchValue){
      displayed_cities = this.props.filteredCities
    }
    displayed_cities = displayed_cities.filter(val => !this.props.selectedCities.includes(val.city + ', ' + val.region))
    this.props.selectedCities.forEach((value, index) => {
      selectedCitiesList.push(
        <div
          key={`selected-city-${value.area_code}`}
          className={"form-check item-name form-check-inline city-background"}
          onClick={() => this.props.handleCheckbox(value, value.area_code, true)}
        >
          <div
            data-menu-item	= {value}
            value			= {value}
            key				= {index}
          >
            <SelectionControl
            data-menu-item	= {value}
            classes = {{checkboxWrapper: 'selection-checkbox-button'}}
            variant			= 'checkbox'
            checked			= {true}
            name			= {`mic-${index}}`}
            value			= {`mic-${value}`}
            disableHover	= {true}
            onClick={() => {}}
          />
          </div>
          {value}
        </div>
        )
    })

    displayed_cities.forEach((value, index) => {

      let checked = this.props.selectedCities.includes(value);
      let selected = ''
      if (this.props.selectedCities.includes(value))
      {
        selected = 'selected-item'
      }
      cities.push(
        <li key={`city-${value.area_code}`}>
          <div
            className={"form-check item-name form-check-inline selectable-item " + selected}
            onClick={() => this.props.handleCheckbox(value.city + ', ' + value.region, value.area_code, checked)}
          >
            <div
              data-menu-item={value.area_code}
              value={value}
            >
              <SelectionControl
              data-menu-item	= {value.area_code}
              classes = {{checkboxWrapper: 'selection-checkbox-button'}}
              variant = 'checkbox'
              checked = {checked}
              name = {`mic-${index}}`}
              value = {`mic-${value.area_code}`}
              disableHover = {true}
              onClick={() => {}}
            />
          </div>
          {value.city + ', ' + value.region}
        </div>
      </li>
      );
    });

    

    return (
      <div className="cities-selector">
          <span className="input-wrapper">
            <input data-test-id='city-input' onChange={this.onCitiesSearch} onKeyDown={this.keyPress} className="search-cities" type="text" value={this.state.searchValue} id="search-cities" name="search-cities" placeholder="Enter a city..." autoComplete="off" autoFocus/>
          </span>

        {hasSelectedCities &&
          <ul className="list-unstyled npa-list selected-npa-list">
            {selectedCitiesList}
          </ul>
        }
        {hasSelectedCities && hasSearchResults && <div className='npa-divider'></div>}

        {this.props.loading ?
          <Spinner/> : 
          <ul className="list-unstyled npa-list">
            {cities}
          </ul>
        }
      </div>
      
    );
  }

}

export default CitySelector;
