import { mainTheme } from 'themes'
import { createMuiTheme } from '@material-ui/core/styles'
import './css/FontImport.css'
// Do NOT add values that are not in the mainTheme. It will cause problems down the line. If something is missing add it to the main object then override it here.
let consoleAppTheme = JSON.parse(JSON.stringify(mainTheme))
consoleAppTheme.appName = 'Console - Phone.com'

consoleAppTheme = createMuiTheme(consoleAppTheme)

/** @module configure-app-theme */
/**
 * configure app theme
 */
export { consoleAppTheme }
