let instance

class Queue {
    constructor () {
        if (instance) {
            throw new Error('New instance cannot be created.')
        }

        this.setup = {
            queue: [],
            working_on_promise: false
        }
        instance = this
    }

    enqueue (promise) { // promise must be sent as callback enqueue(() => promise(arg))
        return new Promise((resolve, reject) => {
            this.setup.queue.push({
                promise,
                resolve,
                reject
            })
            this.dequeue()
        })
    }

    dequeue () {
        if (this.setup.working_on_promise) {
            return false
        }
        const item = this.setup.queue.shift()
        if (!item) {
            return false
        }
        try {
            this.setup.working_on_promise = true
            item.promise()
                .then((value) => {
                    this.setup.working_on_promise = false
                    item.resolve(value)
                    this.dequeue()
                })
                .catch((err) => {
                    this.setup.working_on_promise = false
                    item.reject(err)
                    this.dequeue()
                })
        } catch (err) {
            console.log(err)
            this.setup.working_on_promise = false
            item.reject(err)
            this.dequeue()
        }
        return true
    }
}

const PromisesQueue = Object.freeze(new Queue())

export default PromisesQueue
