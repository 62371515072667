import React, { createContext, useCallback } from 'react'
import { useEventListener } from 'hooks'

/***/
export interface ScreenSizeProviderContext {
    mobile: boolean
    tablet: boolean
    tabletPortrait: boolean
    width: number
    classes: string
}

interface Sizes {
    mobileViewSize: number
    tabletPortraitViewSize: number
    tabletViewSize: number
    tabletPortraitViewSizes?: number
}

interface Props {
    sizes: Sizes,
    children: React.ReactElement
}

const DEFAULT_MOBILE_WIDTH = 600 // eslint-disable-line
const DEFAULT_TABLET_PORTRAIT_WIDTH = 768 // eslint-disable-line
const DEFAULT_TABLET_WIDTH = 1000 // eslint-disable-line

const getClasses = (isMobile: boolean, isTablet: boolean, isTabletPortrait: boolean): string => {
    if (isMobile) return 'mobile-view'
    if (isTablet) return 'tablet-view' + (isTabletPortrait ? ' tablet-portrait-view' : '')
    return ''
}

const getCurrentState = (sizes: Sizes): ScreenSizeProviderContext => {
    const width = window.innerWidth
    const mobileMaxWidth = sizes.mobileViewSize
    const tabletMaxWidth = sizes.tabletViewSize
    const tabletPortraitMaxWidth = sizes.tabletPortraitViewSize
    const screenSizeData: ScreenSizeProviderContext = {
        width,
        mobile: width <= mobileMaxWidth,
        tablet: width <= tabletMaxWidth && width > mobileMaxWidth,
        tabletPortrait: width <= tabletPortraitMaxWidth && width > mobileMaxWidth,
        classes: ''
    }
    screenSizeData.classes = getClasses(screenSizeData.mobile, screenSizeData.tablet, screenSizeData.tabletPortrait)
    return screenSizeData
}

const getDefaultSizes = (): Sizes => ({
    mobileViewSize: DEFAULT_MOBILE_WIDTH,
    tabletPortraitViewSize: DEFAULT_TABLET_PORTRAIT_WIDTH,
    tabletViewSize: DEFAULT_TABLET_WIDTH
})

/***/
export const ScreenSizeContext = createContext<ScreenSizeProviderContext>(getCurrentState(getDefaultSizes()))

/***/
export const ScreenSizeConsumer = ScreenSizeContext.Consumer

/***/
export const ScreenSizeProvider = (props: Props): JSX.Element => {
    const [screenSizeData, setScreenSizeData] = React.useState(getCurrentState(Object.assign(getDefaultSizes(), (props.sizes || {}))))
    const onResize = useCallback(() => setScreenSizeData(getCurrentState(Object.assign(getDefaultSizes(), (props.sizes || {})))), [])
    useEventListener('resize', onResize)
    return (
        <ScreenSizeContext.Provider value={{ ...screenSizeData }}>
            {props.children}
        </ScreenSizeContext.Provider>
    )
}
