import InfiniteScroller from 'pdc-infinite-scroller'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Spinner from 'spinner'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableListView from './TableListView'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
    container: {
        height: '100%',
        maxWidth: 1050
    },
    fullWidthContainer: {},
    headColumn: {
        fontSize: '11.5px',
        fontWeight: 600,
        color: theme.palette.info.main,
        padding: '14.5px 8px 12.5px',
        lineHeight: '12px',
        borderBottom: `1px solid ${theme.palette.info.main}`,
        backgroundColor: '#fff',
        textTransform: 'uppercase'
    },
    defaultColumn: {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: '22px',
        padding: '18px 8px',
        borderBottom: '1px solid #85929a33'
    }
})

class TableMui extends Component {
    constructor (props) {
        super(props)
        this.tableContainerRef = React.createRef()
    }

    PaperComponent = props => <Paper {...props} elevation={0} ref={this.tableContainerRef}/>

    renderFullTable = () => {
        const { classes } = this.props
        const tdClasses = `${classes.defaultColumn} ${this.props.tdClasses || ''}`
        return (
            <TableContainer classes={{ root: this.props.fullWidth ? classes.fullWidthContainer : classes.container }} component={this.PaperComponent}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {this.props.headColumns.map((column, index) => (
                                <TableCell
                                    key = {index}
                                    data-test-id = {column.testId}
                                    classes={{ root: classes.headColumn }}
                                >
                                    {column.content}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <InfiniteScroller
                        reverseScroll = {this.props.reverseScroll}
                        loadMore = {this.props.loadMore}
                        hasMore = {this.props.hasMore}
                        loader = {<Spinner/>}
                        setToChild = {true}
                        childType = 'element'
                        muiTable = {true}
                        data-test-id = {this.props.dataAttr}
                        scrollRef = {this.tableContainerRef}
                    >
                        <TableBody>
                            {this.props.rows.map((row) => (
                                <TableRow onClick={ this.props.onRowClick != null ? () => { this.props.onRowClick(row) } : undefined} data-table-row={row.key} key={row.key}>
                                    {row.columns.map(column => (
                                        <TableCell
                                            classes={{ root: tdClasses }}
                                            component="td"
                                            data-test-id={column.testId}
                                            key={column.key}
                                            scope="row"
                                        >
                                            {column.content}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </InfiniteScroller>
                </Table>
            </TableContainer>
        )
    }

    renderListView = () => {
        return (
            <TableListView
                rows={this.props.rows}
                headColumns={this.props.headColumns}
                loadMore={this.props.loadMore}
                hasMore={this.props.hasMore}
            />
        )
    }

    render = () => {
        const renderView = this.props.listView ? this.renderListView() : this.renderFullTable()
        return renderView
    }
}

TableMui.propTypes = {
    classes: PropTypes.object,
    dataAttr: PropTypes.string,
    fullWidth: PropTypes.number,
    hasMore: PropTypes.bool,
    headColumns: PropTypes.array,
    listView: PropTypes.bool,
    loadMore: PropTypes.bool,
    reverseScroll: PropTypes.bool,
    rows: PropTypes.array,
    tdClasses: PropTypes.object,
    onRowClick: PropTypes.func
}

export default withStyles(styles)(TableMui)
