/***/
export abstract class Greeting {
    /***/
    constructor (public className: string, private id: number | string = null) { if (id) this.setId(id) }
    getId = (): number | string => this.id
    setId = (id: number | string): number | string => (this.id = id)
    abstract isValid(): boolean
    abstract copy(): Greeting
    /**
     * JSON format of the greeting
     */
    toJSON (): {[key: string]: any} { return { id: this.getId() } }
    /**
     * In this parent class just compare the subclass types / classNames
     *
     * @param {Greeting} greeting2
     */
    isEqualTo (greeting2: Greeting): boolean { return this.className === greeting2.className }
    /**
     * Check if there is a local change
     */
    hasChange (): boolean { return !this.id }
}

// const voices = ['Joanna', 'Salli', 'Joey', 'Matthew']
// const languages = ['English']

const DEFAULT_VOICE = 'Joanna' // eslint-disable-line
/***/
export class TTS extends Greeting {
    private storedInfo = { id: 0, url: '', text: '', voice: DEFAULT_VOICE }
    private loadingUrl = false
    /***/
    constructor (
        id?: number,
        private text: string = '',
        private voice: string = DEFAULT_VOICE,
        private url: string = '',
        private readyToPlayAudio: boolean = false
    ) {
        super('TTS', id)
        if (url) {
            this.storedInfo.id = id
            this.storedInfo.url = url
            this.storedInfo.text = text
            this.storedInfo.voice = voice
        }
    }

    static className = 'TTS'

    /**
     * Checks if it is valid
     */
    isValid (): boolean { return Boolean(this.getText() && this.getVoice()) }

    /**
     * Compares 2 objects if they are equal
     *
     * @param {TTS} tts2 - another instance
     */
    isEqualTo (tts2: TTS): boolean {
        return super.isEqualTo(tts2) && Boolean(this.getText() === tts2.getText() && this.getVoice() === tts2.getVoice())
    }

    /**
     * Gives another instance with the same attributes
     */
    copy (): TTS {
        const copy = new TTS(this.getId() as number, this.getText(), this.getVoice(), this.getUrl())
        if (this.storedInfo.url) copy.storedInfo = JSON.parse(JSON.stringify(this.storedInfo))
        return copy
    }

    /**
     * Compare the text
     */
    hasChange (): boolean { return this.storedInfo.text !== this.getText() }

    /**
     * JSON format as it is in the back-end greeting response
     */
    toJSON (): {[key: string]: any} {
        return Object.assign({
            type: 'tts',
            name: '',
            text: this.getText(),
            // eslint-disable-next-line
            download_link: this.getUrl()
        }, super.toJSON())
    }

    getText = (): string => this.text
    setText = (text: string): void => {
        this.text = text
        if (this.text === this.storedInfo.text) {
            this.setUrl(this.storedInfo.url)
            this.setId(this.storedInfo.id)
            this.setVoice(this.storedInfo.voice)
        } else {
            this.setUrl(null)
            this.setId(null)
            this.setVoice(DEFAULT_VOICE)
        }
    }

    isLoadingUrl = (): boolean => this.loadingUrl
    setLoadingUrl = (loadingUrl: boolean): void => { this.loadingUrl = loadingUrl }

    getVoice = (): string => this.voice
    setVoice = (voice: string): string => (this.voice = voice)
    getUrl = (): string => this.url
    setUrl = (url: string): void => {
        this.url = url
        if (url) this.setLoadingUrl(false)
        if (!url) this.readyToPlayAudio = false
    }

    setReadyToPlay = (): boolean => (this.readyToPlayAudio = true)
    isReadyToPlay = (): boolean => this.readyToPlayAudio

    getStoredId = (): number => this.storedInfo.id

    onSaved = (): void => {
        this.storedInfo.id = this.getId() as number
        this.storedInfo.text = this.getText()
        this.storedInfo.url = this.getUrl()
        this.storedInfo.voice = this.getVoice()
    }

    reset = (): void => {
        this.setText(this.storedInfo.text)
    }
}

/***/
export class Recording extends Greeting {
    /***/
    constructor (
        id?: number,
        public recordedAudio?: { downloadLink: string | ArrayBuffer, filename: string },
        public start?: number,
        public end?: number,
        public isRecording = false,
        public currentDuration?: number | string
    ) { super('Recording', id) }

    static className = 'Recording'

    /**
     * Checks if it is valid
     */
    isValid (): boolean { return !!(this.getName() && this.getUrl()) }

    /**
     * Compares 2 objects if they are equal
     *
     * @param {TTS} recording2 - another instance
     */
    isEqualTo (recording2: Recording): boolean {
        return super.isEqualTo(recording2) && recording2.getUrl() === this.getUrl()
    }

    /**
     * Gives another instance with the same attributes
     */
    copy (): Recording { return new Recording(this.getId() as number, this.recordedAudio, this.start, this.end, this.isRecording, this.currentDuration) }

    /**
     * JSON format as it is in the back-end greeting response
     */
    toJSON (): {[key: string]: any} {
        return Object.assign({
            type: 'file',
            name: this.getName(),
            text: '',
            // eslint-disable-next-line
            download_link: this.getUrl()
        }, super.toJSON())
    }

    getName = (): string => this.recordedAudio?.filename || ''
    getUrl = (): string | ArrayBuffer => this.recordedAudio?.downloadLink
}

/***/
export class UploadAudio extends Greeting {
    private loadingUrl = false
    /***/
    constructor (
        id?: number,
        private filename?: string,
        private url?: string,
        private base64Data?: string
    ) { super('UploadAudio', id) }

    static className = 'UploadAudio'

    /**
     * Checks if it is valid
     */
    isValid (): boolean { return !!(this.getId() || (this.getName() && this.getUrl())) }

    /**
     * Compares 2 objects if they are equal
     *
     * @param {TTS} uploadAudio2 - another instance
     */
    isEqualTo (uploadAudio2: UploadAudio): boolean {
        if (!super.isEqualTo(uploadAudio2)) return false
        if (Boolean(this.getId()) !== Boolean(uploadAudio2.getId())) return false
        if (this.getId()) return true
        return this.getUrl() === uploadAudio2.getUrl()
    }

    /**
     * Gives another instance with the same attributes
     */
    copy (): UploadAudio { return new UploadAudio(this.getId() as number, this.getName(), this.getUrl(), this.getBase64Data()) }

    /**
     * JSON format as it is in the back-end greeting response
     */
    toJSON (): {[key: string]: any} {
        return Object.assign({
            type: 'file',
            name: this.getName(),
            text: '',
            // eslint-disable-next-line
            download_link: this.getUrl()
        }, super.toJSON())
    }

    isLoadingUrl = (): boolean => this.loadingUrl
    setLoadingUrl = (loadingUrl: boolean): void => { this.loadingUrl = loadingUrl }

    getName = (): string => this.filename
    setName = (name: string): string => (this.filename = name)
    getUrl = (): string => this.url
    setUrl = (url: string): void => {
        if (url) this.setLoadingUrl(false)
        this.url = url
    }

    getBase64Data = (): string => this.base64Data
    setBase64Data = (base64Data: string): string => (this.base64Data = base64Data)
}

/***/
export class StoredFile extends Greeting {
    /***/
    constructor (
        id: number | string,
        private name: string,
        private url: string,
        private selected: boolean = false,
        private readyToPlayAudio: boolean = false
    ) { super('StoredFile', id) }

    static className = 'StoredFile'

    /**
     * Checks if it is valid
     */
    isValid (): boolean { return true }

    /**
     * Compares 2 objects if they are equal
     *
     * @param {TTS} file2 - another instance
     */
    isEqualTo (file2: StoredFile): boolean { return file2.getId() === this.getId() }

    /**
     * Gives another instance with the same attributes
     */
    copy (): StoredFile { return new StoredFile(this.getId(), this.getName(), this.getUrl(), this.isSelected(), this.isReadyToPlay()) }

    /**
     * JSON format as it is in the back-end greeting response
     */
    toJSON (): {[key: string]: any} { // TODO
        return super.toJSON()
    }

    getName = (): string => this.name
    setName = (name: string): string => (this.name = name)
    getUrl = (): string => this.url
    setUrl = (url: string): string => (this.url = url)
    isSelected = (): boolean => this.selected
    toggleSelect = (selected: boolean): boolean => (this.selected = selected)
    isReadyToPlay = (): boolean => this.readyToPlayAudio
    setReadyToPlay = (): boolean => (this.readyToPlayAudio = true)
    /**
     * The '(Ringing tone)' and 'none' do not have an audio / url
     */
    hasAudio = (): boolean => this.getId() !== '0' && this.getId() !== 11
}
