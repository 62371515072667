import React from 'react'

import desktopImg from './images/desktop.svg'
import tabletImg from './images/tablet.svg'
import tabletPortraitImg from './images/tablet-portrait.svg'
import mobileImg from './images/mobile.svg'
import allSetMobile from './images/all-set-mobile.svg'
import allSetDesktop from './images/all-set-desktop.svg'
import Button from 'button-mui'
import Typography from 'typography'

import { withStyles } from '@material-ui/core'
import styles from './styles'
import { ArrowForwardIosIcon, LogoIcon } from 'svg-icons'
import { getValue } from 'remote-config-value'

type SetupProps = {
    classes: Record<string, string>,
    name: string,
    screenViewType: { isMobileView: boolean, isTabletView: boolean, isTabletPortraitView: boolean }
    onNext: () => void
}

class SplashScreen extends React.Component<SetupProps, Record<string, unknown>> {
    renderMobileView = () => {
        const { classes, onNext } = this.props
        const thankYouMessage = getValue('csw_splash_thank_you_message')
        const thankYouMessageSplit = thankYouMessage.split('Phone.com')
        return (
            <div className={`${classes.splashScreen} mobile`}>
                <div className='up'>
                    <img className='main-image' src={mobileImg}/>
                    <img className='all-set-text' src={allSetMobile}/>
                </div>
                <div className='down'>
                    <div className='thank-you-wrapper'>
                        <LogoIcon className='pdc-logo'/>
                        <Typography variant='h5'>
                            {thankYouMessageSplit.map((text, i) => (
                                <>
                                    {text}
                                    {i !== thankYouMessageSplit.length - 1 ? <span className='phonecom-bold'>Phone.com</span> : null}
                                </>
                            ))}
                        </Typography>
                    </div>
                    <hr className='separator'/>
                    <Typography variant='h4' remoteConfigID='csw_splash_info_message'/>
                    <Button
                        style = {{ backgroundColor: 'black', color: 'white' }}
                        endIcon = {<ArrowForwardIosIcon/>}
                        onClick = {onNext}
                        data-test-id = 'get-started-button'
                    >
                        {getValue('csw_splash_button_text')}
                    </Button>
                </div>
            </div>
        )
    }

    renderTabletView = (tabletType: string) => { return this.renderDesktopView(tabletType) }

    renderDesktopView = (tabletType = null) => {
        const { classes, name, onNext } = this.props
        const welcomeText = `Welcome${name ? `, ${name}!` : '!'}`
        return (
            <div className={`${classes.splashScreen} ${tabletType || ''}`}>
                <div className='content'>
                    <div className='left' data-test-id='left-splash-screen'>
                        <img className='main-image' src={!tabletType ? desktopImg : tabletType === 'tablet' ? tabletImg : tabletPortraitImg}/>
                        <div className='shadow-div'/>
                        <img className='all-set-text' src={tabletType ? allSetMobile : allSetDesktop}/>
                        <Typography classes={{ root: 'thank-you-text' }} variant='subtitle1' remoteConfigID='csw_splash_thank_you_message'/>
                        <LogoIcon className='pdc-logo'/>
                    </div>
                    <div className='right' data-test-id='right-splash-screen'>
                        <Typography variant='h5'>{welcomeText}</Typography>
                        <Typography variant='subtitle1' remoteConfigID='csw_splash_info_message'/>
                        <Button
                            style = {{ backgroundColor: 'black', color: 'white' }}
                            endIcon = {<ArrowForwardIosIcon/>}
                            onClick = {onNext}
                            data-test-id = 'get-started-button'
                        >
                            {getValue('csw_splash_button_text')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    render () {
        const { isMobileView, isTabletView, isTabletPortraitView } = this.props.screenViewType
        if (isMobileView) return this.renderMobileView()
        if (isTabletView) return this.renderTabletView(isTabletPortraitView ? 'tablet tablet-portrait' : 'tablet')
        return this.renderDesktopView()
    }
}

export default withStyles(styles)(SplashScreen)
