// This is a javascript library to create a listener-only open connection with PDC's IoT core based on account credentials.
// To be used as a websocket. From a use perspective, any IoT or AWS references should be abstracted away.
// Websocket connection is stored as a global to prevent multiple websockets from opening at the same time.

// Usage:
// Connect to the websocket with PDCOpenConnection.connect()
// Subscribe to topics with PDCOpenConnection.subscribe(topicName)
// For every topic, create a callback that handles messages under that topic
// PDCOpenConnection.on(topicName, callback), where m is the message in json
import PhoneComUser from 'phone-com-user'
import ajax from 'ajax'
import mqtt from 'mqtt'

const rb_debug_log = async (message, extraData = {}) => {
    console.log(message)
    console.log(extraData)

    if (window?.Rollbar && typeof window?.Rollbar.debug === 'function' && (process.env.REACT_APP_REPORT_OPEN_CONNECTION_EVENTS || window?.V5PHONECOM?.features?.has('report_open_connection_events'))) {
        window.Rollbar.debug(message, extraData)
    }
}

class PDCOpenConnection {
    constructor () {
        this.client = null
        this.connected = false
        this.connecting = false
        this.subscriptions = []
        this.accountSubscriptions = []
        this.pendingSubs = []
        this.pendingAccountSubs = []
        this.topicCallbacks = {}
        this.lostConnection = false
        this.retryingConnection = false
        this.reconnectCallbacks = []
        this.connectionClosedtCallbacks = []
        this.onConnectCallbacks = []
        this.clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 32)

        if (window.pdcOpenConnection) {
            this.client = window.pdcOpenConnection.client
            this.connecting = window.pdcOpenConnection.connecting
            this.topicCallbacks = window.pdcOpenConnection.topicCallbacks
            this.subscriptions = window.pdcOpenConnection.subscriptions
            this.pendingSubs = window.pdcOpenConnection.pendingSubs
            this.accountSubscriptions = window.pdcOpenConnection.subscriptions
            this.pendingAccountSubs = window.pdcOpenConnection.pendingSubs
            this.connected = window.pdcOpenConnection.connected
            this.lostConnection = window.pdcOpenConnection.lostConnection
            this.reconnectCallbacks = window.pdcOpenConnection.reconnectCallbacks || []
            this.onConnectCallbacks = window.pdcOpenConnection.onConnectCallbacks || []
            this.connectionClosedtCallbacks = window.pdcOpenConnection.connectionClosedtCallbacks || []
            this.clientId = window.pdcOpenConnection.clientId
        } else {
            window.pdcOpenConnection = this
        }

        this.extensionIds = [PhoneComUser.getExtensionId()]
    }

   subscribe = topic => {
       if (this.subscriptions.includes(topic)) return

       if (!this.client) {
           this.pendingSubs.push(topic)
           return
       }

       if (!PhoneComUser.getExtensionId()) return

       const roots = this.getTopicRoots()

       roots.forEach(root => {
           const fullTopic = `${root}/${topic}`

           let subscriptionErr
           this.client.subscribe(fullTopic, err => {
               if (!err) return
               this.retryingConnection = true
               subscriptionErr = err
               this.subscribe(topic)
           })

           if (subscriptionErr) return

           this.subscriptions.push(topic)
       })
   }

   subscribeAccount = topic => {
       if (PhoneComUser.getRole() !== 'account') { return }

       if (this.accountSubscriptions.includes(topic)) { return }

       if (!this.client) {
           this.pendingAccountSubs.push(topic)
           return
       }

       const root = this.topicAccountRoot()
       const fullTopic = `${root}/${topic}`

       let subscriptionErr
       this.client.subscribe(fullTopic, err => {
           if (!err) return
           this.retryingConnection = true
           subscriptionErr = err
           this.subscribeAccount(topic)
       })

       if (subscriptionErr) return

       this.accountSubscriptions.push(topic)
   }

   topicAccountRoot = () => {
       const account_id = PhoneComUser.getAPIAccountId()
       const stage = PhoneComUser.getStage()
       return `${stage}/account/${account_id}`
   }

   getExtensionIds = () => {
       const extensionId = PhoneComUser.getExtensionId()
       const extensionIds = Array.from(new Set(this.extensionIds.concat([extensionId]))).filter(e => e)
       this.extensionIds = extensionIds
       return extensionIds
   }

   getTopicRoots = () => {
       const account_id = PhoneComUser.getAPIAccountId()
       const extensionIds = this.getExtensionIds()
       const stage = PhoneComUser.getStage()
       return extensionIds.map(extension_id => `${stage}/account/${account_id}/extension/${extension_id}`)
   }

   // This is a hard reset for switching users or resetting bad connections
   hardReset = async () => {
       this.connected = false
       this.connecting = false
       if (this.client) {
           this.client.end()
           delete this.client
           this.client = null
       }
       console.log('hard reset')
       return this.connect()
   }

    connect = async () => {
        rb_debug_log('connecting to Open Connection')

        if (this.connecting || this.connected) {
            console.log('already connected')
            return this.client
        }
        this.connecting = true

        // make a request to signing endpoint with pdc credentials
        const websocketURL = `${PhoneComUser.getv5ToolsRoot()}/messaging/get-websocket-connection`

        return ajax.postAccount(websocketURL, {})
            .then(response => {
                if (!response?.data) {
                    console.error('error when connecting to Open Connection', response)
                    throw (new Error(`response issue ${response}`))
                }

                const url = response.data.url
                console.log('id', this.clientId)
                const client = mqtt.connect(url, {
                    clientId: this.clientId,
                    reconnectPeriod: 0,
                    clean: false,
                    keepalive: 120 // increase keepalive to 120 seconds to prevent disconnects when chrome is throttling timers to 1 minute
                })

                this.client = client
                window.pdcOpenConnection = this

                client.on('connect', () => {
                    this.connected = true
                    this.connecting = false
                    // Subscribe to all topics that were created before the connection was made
                    this.pendingSubs.forEach(this.subscribe)
                    this.pendingAccountSubs.forEach(this.subscribeAccount)

                    if (this.lostConnection && this.reconnectCallbacks.length) {
                        this.reconnectCallbacks.forEach(callback => callback(this.retryingConnection))
                    }
                    this.retryingConnection = false
                    this.lostConnection = false
                    this.onConnectCallbacks.forEach(callback => callback())
                })

                client.on('message', (fullTopic, message) => {
                    console.log('got message', fullTopic, message)
                    const fullTopicSplit = fullTopic.split('/')
                    const topic = fullTopicSplit.pop()
                    const extensionId = parseInt(fullTopicSplit.pop())
                    const callbacks = this.topicCallbacks[topic]
                    if (callbacks) { callbacks.forEach(callback => callback(extensionId, JSON.parse(message.toString('utf8')))) }
                })

                client.on('close', e => {
                    console.log('closing')
                    let shouldReset = false
                    rb_debug_log('OpenConnection close', e)
                    this.clientId = Math.random().toString(16).substr(2, 32)
                    this.pendingSubs = (this.subscriptions && this.subscriptions.length > 0) ? this.subscriptions : this.pendingSubs // stops overriding pendingSubs with empty sub list
                    this.pendingAccountSubs = (this.accountSubscriptions && this.accountSubscriptions.length > 0) ? this.accountSubscriptions : this.pendingAccountSubs
                    this.accountSubscriptions = []
                    this.subscriptions = []
                    if (this.connected || !this.lostConnection) {
                        this.connected = false
                        this.lostConnection = true
                        shouldReset = true
                    }
                    this.connectionClosedtCallbacks.map((callback) => {
                        return callback()
                    })
                    if (shouldReset) {
                        this.hardReset()
                    }
                })

                client.on('error', (e) => {
                    rb_debug_log('OpenConnection error', e)

                    // error
                })

                client.on('reconnect', (e) => {
                    // error
                    rb_debug_log('OpenConnection reconnecting', e)
                })

                return client
            },
            error => {
                console.error('error when connecting to Open Connection', error)
                throw (error)
            }).catch((err) => {
                this.connecting = false
                this.connected = false
                this.lostConnection = true
                this.retryingConnection = true
                console.warn('error when connecting to Open Connection, retrying in 5 seconds', err)
                setTimeout(() => { this.connect() }, 5000)
            })
    }

   close = () => this.client.end()

   // on Topic
   // Connect if not connected
   // subscribe if not subscribed
   on = (topic, callback) => {
       if (!this.connected) this.connect()

       this.subscribe(topic)

       if (!this.topicCallbacks[topic]) { this.topicCallbacks[topic] = [callback] } else if (!this.topicCallbacks[topic].includes(callback)) {
           this.topicCallbacks[topic].push(callback)
       }
   }

   onAccount = (topic, callback) => {
       if (!this.connected) this.connect()

       this.subscribeAccount(topic)

       if (!this.topicCallbacks[topic]) { this.topicCallbacks[topic] = [callback] } else if (!this.topicCallbacks[topic].includes(callback)) {
           this.topicCallbacks[topic].push(callback)
       }
   }

   // Probably shouldn't use this. removes based on function comparison
   // Would prefer if we managed whether to exec inside the callback
   removeCallback = (topic, callback) => {
       if (!this.topicCallbacks[topic]) return
       this.topicCallbacks[topic].filter(cb => cb !== callback)
   }

   onConnect = callback => {
       if (this.client) {
           this.client.on('connect', callback)
       }
       this.onConnectCallbacks.push(callback)
   }

   onReconnect = callback => this.reconnectCallbacks.push(callback)

   onClose = callback => this.connectionClosedtCallbacks.push(callback)

   registerExtensions = ids => {
       if (!Array.isArray(ids)) ids = [ids]
       let extensionIds = [...this.extensionIds]
       extensionIds = extensionIds.concat(ids)
       this.extensionIds = Array.from(new Set(extensionIds))
   }
}

export default new PDCOpenConnection()
