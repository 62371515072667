import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'

class API {
	static getCurrentPrimaryCard = async () => {
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/account/credit-card/get-card-on-file`
		let response	= await ajax.post(requestUrl, {})
		return response.data
	}

	static updatePrimaryCard = async stripeCardToken => {
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/account/credit-card/update-primary-card`
		let response	= await ajax.post(requestUrl, {token: stripeCardToken})
		if (response.data) return response.data
		else if (response.payment_decline) return {error: response.payment_decline}
		else return {error: 'We were unable to verify this card'}
	}
}

export default API