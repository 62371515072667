<template>
    <FormModal
        v-bind="$attrs"
        v-on="$listeners"
        max-width="400"
    >
        <div class="delete-default-contact-model-content">
            <div class="w-body-2">
                {{$lang.t('lr.x-is-your-default-designated-contact', '{} is currently set as your designated contact for unmatched callers. Please choose another contact as a designee, or this feature will be disabled.', [`${contacts[deleting_contact_index].first_name} ${contacts[deleting_contact_index].last_name}`])}}
            </div>
            <w-select
                v-if="items.length"
                v-model="default_contact"
                :items="items"
                :placeholder="$lang.t('lr.select-different-default-contact', 'Select a different designated contact')"
                class="delete-default-contact-select w-100"
            />
        </div>
        <template v-slot:buttons>
            <w-btn @click="$emit('input', false)" color="secondary">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
            <w-btn @click="$emit('confirmed', deleting_contact_index)" color="error">{{$lang.t('app.delete', 'Delete')}}</w-btn>
        </template>
    </FormModal>
</template>
<script>
    import FormModal from '../../../console/src/components/elements/modal/FormModal.vue';

  export default {
    props: {
        contacts: {
            type: Array,
            required: true,
        },
        deleting_contact_index: {
            type: Number,
            required: true,
        }
    },
    components: {FormModal},
    data() {
      return {
        default_contact: null,
      };
    },
    computed: {
        items() {
            const items = [];
            for (let i = 0; i < this.$props.contacts.length; i++) {
                if (i !== this.$props.deleting_contact_index) {
                    const c = this.$props.contacts[i];
                    if (c.first_name && c.last_name && Array.isArray(c.destinations) && c.destinations.length) {
                        items.push({
                            text: `${c.first_name} ${c.last_name}`,
                            value: i,
                        });
                    }
                }
            }

            return items;
        }
    },
    watch: {
        default_contact(v) {
            this.$emit('changed', v);
        }
    }
  };
</script>
<style lang="scss" scoped>
.delete-default-contact-model-content {
    max-width: 298px;
    margin-right: auto;
    margin-left: auto;
    .delete-default-contact-select {
        margin-top: 10px;
    }
}
</style>
