/**
 * @param {object} theme
 */
const styles = theme => ({
    loadingDiv: theme.loadingDiv,
    container: {
        borderRadius: 6,
        width: 555,
        '&.small-view': {
            width: '100%',
            '& .tab': {
                padding: 10,
                '&:not(.selected)': {
                    flex: 'unset',
                    padding: '10px 20px'
                }
            }
        }
    },
    header: {
        display: 'flex',
        border: '1px solid lightgray',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        '&.round-corners': {
            borderRadius: 6,
            '& .tab:first-child': {
                borderBottomLeftRadius: 6
            },
            '& .tab:last-child': {
                borderBottomRightRadius: 6
            }
        },
        '& .tab': {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '16px 24px',
            margin: 0,
            fontSize: 18,
            color: 'black',
            cursor: 'pointer',
            '&.selected': {
                color: 'white',
                background: theme.palette.primary.main
            },
            '&:first-child': {
                borderTopLeftRadius: 6
            },
            '&:last-child': {
                borderTopRightRadius: 6
            },
            '&:not(:last-child)': {
                borderRight: '1px solid lightgray'
            }
        }
    },
    customFileWrapper: {},
    customOptionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
        fontSize: 20,
        cursor: 'pointer'
    },
    audioPlayerContainer: {
        width: '100%',
        padding: '20px 10px'
    }
})

export default styles
