/**
 * Material ui styles
 *
 * @param {object} theme - Theme object
 */
const styles = theme => ({
    // loadingDiv: Object.assign({}, theme.loadingDiv, { background: 'white' }),
    loadingDiv: theme.loadingDiv,
    notifications: {
        position: 'relative',
        overflowY: 'auto',
        height: 'calc(100% - 59px)',
        display: 'flex',
        flexDirection: 'column'
    },
    settingWrapper: {
        ...theme.personalSettingsApp.settingWrapper,
        padding: '50px 50px 100px',
        flexDirection: 'column',
        height: '100%',
        '&.hidden': {
            display: 'none'
        }
    },
    header: {
        background: theme.palette.secondary[-100],
        color: 'white',
        fontSize: 14,
        fontFamily: 'Montserrat-bold !important',
        fontWeight: 'bold',
        lineHeight: '18px',
        padding: 20,
        borderRadius: 2,
        textTransform: 'uppercase'
    },
    addressMenu: {
        marginLeft: 30
    },
    dropdownItemsWrapper: {
        maxHeight: 300,
        overflowY: 'auto'
    },
    menuItem: {},
    section: {
        display: 'flex',
        flexDirection: 'column'
    },
    sectionContent: {
        padding: '20px 25px 50px'
    },
    subsection: {
        display: 'flex',
        flexDirection: 'column',
        '&:not(:first-child)': {
            marginTop: 40
        },
        '&:not(:last-child)': {
            marginBottom: 40
        }
    },
    question: {
        fontSize: 16,
        marginBottom: 20,
        lineHeight: '24px',
        letterSpacing: -0.05,
        '&.error': {
            color: '#EA0303',
            '& .em': {
                color: '#EA0303'
            }
        },
        '& .em': {
            fontFamily: 'Montserrat-bold',
            color: 'black',
            fontSize: 18,
            lineHeight: '26px'
        }
    },
    footer: {
        height: 83,
        background: theme.palette.secondary[-500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: `2px solid ${theme.palette.secondary[-300]}`,
        '& button': {
            width: 150
        }
    },
    textField: {
        maxWidth: 400,
        flex: 1
    }
})

export default styles
