// React
import React from "react";

// Components
import NumberTypeFilter from "./number-type-filter/number-type-filter";
import CityFilter from "./city-filter/city-filter"
import PriceFilter from "./price-filter/price-filter";
import ResetFilters from "./reset-filters/reset-filters"
import TfnNpaFilter from "./tfn-npa-filter/tfn-npa-filter"
//import SortFilter from "./sort-filter/sort-filter";

// Stylesheet
import "./search-filters.css";
import NpaFilter from "./npa-filter/npa-filter";

// Filters
class SearchFilters extends React.Component {

  state = {
    selectedNpas: []
  }

  updateSelectedNpas = (npas) => {

    this.setState({selectedNpas: npas})

  }

  render() {
    let filterClass = "search-filters row align-items-center";
    if (this.props.is_free === "true") {
      filterClass = "search-filters hidden";
    }

    let lefthandFilter;
    if (this.props.mode === "tollfree_nonvanity") {

      lefthandFilter = <div className={`col-sm-10 col-xs-12 search-filters-filter ${this.props.isMobile ? 'mobile' : ''}`}>
          <TfnNpaFilter
            reset={this.props.reset}
            handleNpaFilterUpdate={this.props.handleNpaFilterUpdate}
          />
        </div>
    }
    else if (this.props.mode === "local_nonvanity") {
      lefthandFilter = <div className={`col-sm-10 col-xs-12 search-filters-filter ${this.props.isMobile ? 'mobile' : ''}`}>

        <NpaFilter
          reset={this.props.reset}
          handleNpaFilterUpdate={this.props.handleNpaFilterUpdate}
          all_area_codes={this.props.all_area_codes}
          availableAreaCodes={this.props.area_codes}
          AreaCodeSearch={this.props.AreaCodeSearch}
          handleResetCity={this.props.handleResetCity}
          handleResetNpa={this.props.handleResetNpa}
          updateSelectedNpas={this.updateSelectedNpas}
          resetNpa={this.props.resetNpa}
          invalidAreaCodeMessage={this.props.invalidAreaCodeMessage}

        />
        <CityFilter
          reset={this.props.reset}
          handleCitiesFilterUpdate={this.props.handleCitiesFilterUpdate} // TODO
          availableCities={this.props.cities}
          CitySearch={this.props.CitySearch}
          loading={this.props.city_filter_loading}
          resetCity={this.props.resetCity}
          handleResetCity={this.props.handleResetCity}
          handleResetNpa={this.props.handleResetNpa}
          filteredCities={this.props.filteredCities}
          selectedNpas={this.state.selectedNpas}
        />
      </div>
    }
    else {
      lefthandFilter =
        <div className="col-12 left-hand-filter">
          <NumberTypeFilter
            handleCheckbox={this.props.handleCheckbox.bind(this)}
            local={this.props.local}
            tollfree={this.props.tollfree}
            handleResetNumberTypeFilter={this.props.handleResetNumberTypeFilter}
          />
          <PriceFilter
            priceSlider={this.props.priceSlider}
            price={this.props.price}
            handleSlider={this.props.handleSlider.bind(this)}
            handlePrice={this.props.handlePrice}
            handleCheckbox={this.props.handleCheckbox.bind(this)}
            reset={this.props.reset}
          />
        </div>
    }

    return (
      <div className={filterClass}>
        {lefthandFilter}
        {/* <ResetFilters
          handleReset={this.props.handleReset}
        /> */}
      </div>
    );
  }
}

export default SearchFilters;
