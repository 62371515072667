import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styles from './styles'
import Typography from 'typography'

class ChecklistStep extends Component {
    constructor (props) {
        super(props)
        this.videoUrl = 'https://www.phone.com/wp-content/uploads/2021/06/Phone.com-DS-FN.mp4'
    }

    componentDidUpdate = (prevProps) => {
        const hasSaveRequest = this.props.saveId && (prevProps.saveId !== this.props.saveId)
        if (hasSaveRequest) {
            this.props.update({
                completed: true,
                gotoNext: true,
                saveData: {
                    checked: true
                }
            })
        }
    }

    render () {
        const { classes, smallView } = this.props
        return (
            <div className={`${classes.checklistStep} ${smallView ? 'mobile' : ''}`}>
                <div className={classes.checklistItems}>
                    <ul>
                        <li className={classes.checklistBoldItem}><Typography variant='body1' remoteConfigID='transfer_number_checklist_heading_1'/></li>
                        <li className={classes.checklistDescriptionItem}><Typography variant='body1' remoteConfigID='transfer_number_checklist_text_1'/></li>
                        <li className={classes.checklistBoldItem}><Typography variant='body1' remoteConfigID='transfer_number_checklist_heading_2'/></li>
                        <li className={classes.checklistDescriptionItem}><Typography variant='body1' remoteConfigID='transfer_number_checklist_text_2'/></li>
                    </ul>
                    {/* phoneComLink class prevents einsteinBot.css from deactivating link containing "Phone.com" */}
                </div>
            </div>
        )
    }
}

ChecklistStep.propTypes = {
    classes: PropTypes.object,
    updateWizardStep: PropTypes.func,
    name: PropTypes.string,
    smallView: PropTypes.string,
    saveId: PropTypes.string,
    update: PropTypes.func
}

export default withStyles(styles)(ChecklistStep)
