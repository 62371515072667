<template>
    <USZipCode
        v-if="country === 'US'"
        v-bind="$attrs"
        v-on="$listeners"
        :with_label="with_label"
        />
        <CanadianPostalCode
        v-else-if="country === 'CA'"
        v-bind="$attrs"
        v-on="$listeners"
        :with_label="with_label"
    />
    <w-text-field
        v-else
        v-bind="$attrs"
        v-on="$listeners"
        :placeholder="placeholder"
        :label="with_label ? placeholder : undefined"
        />
    </template>
    <script>
import USZipCode from './USZipCode.vue';
import CanadianPostalCode from './CanadianPostalCode.vue';
export default {
    props: {
        country: {
            type: String,
            validator: (v) => v ? v.length === 2 : true
        },
        with_label: {
            type: Boolean,
            default: false
        }
    },
    components: {
        USZipCode,
        CanadianPostalCode,
    },
    data() {
        return {
            placeholder: this.$lang.t('app.psotal-code', 'Postal code'),
        }
    }
  };
</script>
