import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { logout } from 'phonecom'
import { setSmallView } from '../../actions/view'
import { setUsers, updateUser, deleteUser, addUser } from '../../actions/users'
import { setNumbers, setNewNumbers } from '../../actions/numbers'
import { getFeatureEnabled } from 'feature-flag'
import api from '../../util/api_v5'
import LoaderFull from 'loader-full'
import ManageUsersLarge from './ManageUsersLarge'
import ManageUsersSmall from './ManageUsersSmall'
import Prompt from 'pdc-prompt'
import Spinner from 'spinner'
import PhoneComUser from 'phone-com-user'
import ProgressBar from 'progress-bar'

import InviteUserModal from './InviteUserModal'
import ConfirmModal from 'confirm-modal'
import InfoModal from 'info-modal'
import { theme } from 'get-theme'
import Api from 'api'
import { Route, Switch } from 'react-router-dom'

import PDCOpenConnection from 'pdc-open-connection'
import UserSettings from './UserSettings'
import SipCredentialsModal from './SipCredentialsModal'
import PropTypes from 'prop-types'

const styles = theme => ({
    manageUsersContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '50px min(65px, 5%) 65px'
    },
    manageUsersContainerMobile: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '10px min(65px, 5%) 10px'
    },
    confirmSpinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 50,
        '& svg': {
            color: theme.palette.primary[0]
        }
    },
    loadingBar: {
        width: 192,
        marginLeft: 'calc(50% - 96px)',
        marginTop: 25
    },
    loadingBarDesktop: {
        width: 850,
        marginTop: 25
    },
    appLoader: {
        transition: 'opacity .5s ease-out',
        overflow: 'hidden',
        opacity: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000,
        backgroundColor: theme.appLoader.backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    appLoader_appLoaderHidden: {
        opacity: 0,
        pointerEvents: 'none'
    },
    appLoaderIcon: theme.appLoader.logo,
    loader: {
        width: '100%',
        height: '100%'
    },
    progressBar: {
        width: 200,
        height: 8,
        borderRadius: 6.5,
        marginTop: 25,
        background: theme.appLoader.progressBar.backgroundColor,
        '& .app-loader-progress-fill': {
            height: '100%',
            width: 0,
            background: theme.appLoader.progressBar.fillColor,
            borderRadius: 6.5
        }
    },
    loadingText: theme.appLoader.loadingText
})

const mapStateToProps = state => ({
    smallView: state.smallView,
    users: state.users,
    numbers: state.numbers,
    newNumbers: state.newNumbers
})

const mapDispatchToProps = dispatch => ({
    setSmallView: boolVal => dispatch(setSmallView(boolVal)),
    setUsers: users => dispatch(setUsers(users)),
    addUser: user => dispatch(addUser(user)),
    updateUser: user => dispatch(updateUser(user)),
    deleteUser: user => dispatch(deleteUser(user)),
    setNumbers: numbers => dispatch(setNumbers(numbers)),
    setNewNumbers: newNumbers => dispatch(setNewNumbers(newNumbers))

})

class ManageUsers extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: !this.props.users.length,
            silentLoading: false,
            showInviteUserModal: false,
            showDeleteDialog: false,
            showInfoDialog: false,
            showResetPwDialog: false,
            showReInviteDialog: false,
            showUnassignNumDialog: false,
            showAssignNumDialog: false,
            showAddMeetingDialog: false,
            showAdminDialog: false,
            pdcPromptOpen: false,
            pdcPromptColor: 'tertiary',
            pdcPromptMessage: null,
            pdcPromptTestId: 'manage-users-prompt',
            inviteUserId: null,
            deleteUser: null,
            resetPwUser: null,
            reInviteUser: null,
            addMeetingUser: null,
            unassignNumUser: null,
            reassignAdminUser: null,
            assignNumUser: null,
            assignNumber: null,
            forceProPlan: false,
            lastAssignedNumber: null,
            hasMore: true,
            editPending: false,
            pendingDelete: false,
            pendinfResetPw: false,
            pendingReInvite: false,
            pendingUnassignNum: false,
            pendingAssignNum: false,
            pendingAddMeeting: false,
            pendingFindAvailableNumbers: false,
            pendingAddAvailableNumber: false,
            pendingAdmin: false,
            loadingBarStep: 'start',
            loadingBar: false,
            showSipCredentialsId: null
        }
    }

    componentDidMount = () => {
        this.init()
    }

    init = async () => {
        this.setState({ silentLoading: true })
        const forceProPlan = await getFeatureEnabled('configure.force_pro_plan')

        if (forceProPlan) {
            this.setState({
                forceProPlan: true
            })
        }
        const [usersResponse, numbersResponse] = await Promise.all([api.loadUsers(10, 0), api.listUnassignedNumbers()])
        this.initNotifications()
        if (this.props.newNumbers.length === 0) this.findAvailableNumbers()

        let users = []
        if (usersResponse) {
            users = await this.setExtensionDevice(usersResponse)
        }

        this.props.setUsers(users)
        this.props.setNumbers(numbersResponse ? numbersResponse.items : [])
        this.setState({ loading: false, silentLoading: false })
    }

    setExtensionDevice = async (usersResponse) => {
        const extensionIds = usersResponse.data.map(e => e.relationships.extension.data.id)
        const extensions = await api.listExtensions(extensionIds)

        let users = []
        if (usersResponse.error) {
            this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while fetching users.' })
        } else {
            users = usersResponse.data.map(rawUser => {
                const extension = extensions.items.find(extension => extension.id === rawUser.relationships?.extension?.data?.id)
                if (extension?.device_membership?.device) rawUser.extensionDevice = extension.device_membership.device
                return this.formatUser(rawUser)
            })
        }
        return users
    }

    loadMore = async () => {
        if (this.state.silentLoading) return
        let currentUsers = this.props.users
        const response = await api.loadUsers(10, currentUsers.length)
        if (response.error) {
            this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while fetching users.' })
            return
        }
        let users = []
        if (response) {
            users = await this.setExtensionDevice(response)
        }
        if (users.length < 10) this.setState({ hasMore: false })
        currentUsers = currentUsers.concat(users)
        this.props.setUsers(currentUsers)
    }

    handlepdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null, pdcPromptColor: 'tertiary', pdcPromptTestId: 'manage-users-prompt' })

    formatUser = (user_api_resource) => {
        // Format a raw User resource from the Users API in a way that's easier
        // to work with -- This extracts direct_number, meeting_plan, and device
        // from user.relationships... on the assumption there is 0 or 1 of each

        const user = { ...user_api_resource }

        // We'll find 'numbers', 'devices', and 'add_ons' under user.relationships
        // if listUsers was called with ?include=numbers,devices,add_ons in the
        // query string of the listUsers URL
        let direct_numbers
        try {
            direct_numbers = user.relationships.numbers.data.filter(
                number => number.route_type === 'direct-line'
            )
        } catch (e) {
            direct_numbers = []
        }

        let meeting_plans
        try {
            meeting_plans = user.relationships.add_ons.data.filter(
                add_on => add_on.description === 'Meetings'
            )
        } catch (e) {
            meeting_plans = []
        }

        let devices
        try {
            devices = user.relationships.devices.data.filter(
                // TODO: We probably want filter to make sure the device is
                //  actually a desk phone, and not a cell, and is active/connected
                device => true // Placeholder
            )
        } catch (e) {
            devices = []
        }

        let plan = {}
        try {
            plan = user.relationships.plan
        } catch (e) {
            plan = {
                data: {
                    id: 1,
                    plan_description: 'Basic',
                    extension_billing_code: 17108
                }
            }
        }
        user.plan = plan

        let extension = {}
        try {
            extension = user.relationships.extension
        } catch (e) {
            extension = {
                data: {}
            }
        }
        user.extension = extension

        // let avatar_url
        // try {
        //     avatar_url = user.avatar_url ? `https://pdc-users-avatar.s3-us-west-2.amazonaws.com/${user.avatar_url}` : null
        // } catch (e) {
        //     avatar_url = null
        // }
        // user.avatar_url = avatar_url

        const lists = {
            direct_number: direct_numbers,
            meeting_plan: meeting_plans,
            device: devices
        }
        // eslint-disable-next-line
        for (const key in lists) {
            if (lists[key].length === 1) {
                user[key] = lists[key][0]
            } else if (lists[key].length > 1) {
                console.warn(`Found more than one ${key} for user - expected 0 or 1.`)
                user[key] = lists[key][0]
            } else {
                user[key] = null
            }
        }

        return user
    }

    handleUserCreated = async () => {
        const usersResponse = await api.loadUsers(10, 0)
        let users = []
        if (usersResponse) {
            if (usersResponse.error) { this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while fetching users.' }) } else { users = usersResponse.data.map(raw_user => this.formatUser(raw_user)) }
        }
        this.props.setUsers(users)
    }

    handleUserInviteDeleted = (extensionId, event) => {
        return null
    }

    handleInviteSent = (extensionId, event) => {
        return null
    }

    handleUserUpdated = (extensionId, event) => {
        const rawUser = event.data.new_values
        // new_values not correct?
        const user = this.formatUser(rawUser)
        this.props.updateUser(user)
    }

    handleUserActivated = (extensionId, event) => {
        const user = this.props.users.find(u => u.id === event.user_id)
        if (user) {
            user.status = 'active'
            this.props.updateUser(user)
        }
    }

    handleUserDeleted = (extensionId, event) => {
        const user_id = parseInt(event.user_id, 0)
        this.props.deleteUser(user_id)
    }

    initNotifications = () => {
        PDCOpenConnection.onAccount('user_created', this.handleUserCreated)
        PDCOpenConnection.onAccount('user_updated', this.handleUserUpdated)
        PDCOpenConnection.onAccount('user_activated', this.handleUserActivated)
        PDCOpenConnection.onAccount('user_deleted', this.handleUserDeleted)
        PDCOpenConnection.onAccount('invite_sent', this.handleInviteSent)
        PDCOpenConnection.onAccount('invite_deleted', this.handleUserInviteDeleted)
    }

    reassignAdminUser = async (user) => {
        if (this.state.pendingAdmin || user.is_admin || user.status !== 'active') return
        if (!this.state.showAdminDialog) {
            this.setState({ showAdminDialog: true, reassignAdminUser: user })
            return
        }

        this.setState({ pendingAdmin: true })
        const response = await api.reassignAdminUser(user.id)
        if (response.error) {
            this.setState({ showAdminDialog: false, pendingAdmin: false, reassignAdminUser: null, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong. Admin was not reassigned.' })
            return
        }
        logout()
    }

    deleteUser = async (user) => {
        if (this.state.pendingDelete) return

        if (user.is_admin && user.status === 'active') {
            const userStatsResponse = await api.getUsersStats()
            if (userStatsResponse.data.admin_count < 2) {
                if (userStatsResponse.error) {
                    this.setState({ showDeleteDialog: false, deleteUser: null, pendingDelete: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong. User was not deleted.' })
                    return
                }
                this.setState({ showInfoDialog: true })
                return
            }
        }

        if (!this.state.showDeleteDialog) {
            this.setState({ showDeleteDialog: true, deleteUser: user })
            return
        }
        this.setState({ pendingDelete: true })

        const response = await api.deleteUser(user.id)
        this.setState({ pending: false })
        if (response.error) {
            this.setState({ showDeleteDialog: false, deleteUser: null, pendingDelete: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong. User was not deleted.' })
            return
        }
        this.removeUserFromState(user.id)
        this.setState({ showDeleteDialog: false, deleteUser: null, pendingDelete: false, pdcPromptOpen: true, pdcPromptMessage: 'User deleted', pdcPromptTestId: 'prompt-user-delete-successfull' })
    }

    resetPassword = async (user) => {
        if (this.state.pendingResetPw) return
        if (!this.state.showResetPwDialog) {
            this.setState({ showResetPwDialog: true, resetPwUser: user })
            return
        }

        this.setState({ pendingResetPw: true })

        const response = await api.resetUserPassword(user.id)
        if (response.error) {
            this.setState({ showResetPwDialog: false, resetPwUser: null, pendingResetPw: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while resetting password.' })
            return
        }
        this.setState({ showResetPwDialog: false, resetPwUser: null, pendingResetPw: false, pdcPromptOpen: true, pdcPromptMessage: `Password reset link was sent to ${user.email} .`, pdcPromptTestId: 'prompt-password-reset-link-sent-successfull' })
    }

    resendInvite = async (user) => {
        if (this.state.pendingReInvite) return
        if (!this.state.showReInviteDialog) {
            this.setState({ showReInviteDialog: true, reInviteUser: user })
            return
        }

        this.setState({ pendingReInvite: true })

        const response = await api.resendInvite(user.id)
        if (response.error) {
            this.setState({ showReInviteDialog: false, reInviteUser: null, pendingReInvite: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while sending invitation.' })
            return
        }
        this.setState({ showReInviteDialog: false, reInviteUser: null, pendingReInvite: false, pdcPromptOpen: true, pdcPromptMessage: `Invite sent to ${user.email}.`, pdcPromptTestId: 'prompt-invite-sent-successfull' })
    }

    updateUserExtension = async (user, extension) => {
        let updatedExtension = null
        try {
            updatedExtension = await api.setVoipPhoneExtension(user.extension.data.id, extension)
            user.extension.data.extension_number = updatedExtension
            this.props.updateUser(user)
        } catch (err) {
            this.setState({ pdcPromptOpen: true, pdcPromptMessage: err.message, pdcPromptColor: 'red' })
            setTimeout(() => { this.handlepdcPromptClose() }, 5000)
        }
        return updatedExtension
    }

    addMeeting = async (user) => {
        if (this.state.pendingAddMeeting) return
        if (!this.state.showAddMeetingDialog) {
            this.setState({ showAddMeetingDialog: true, addMeetingUser: user })
            return
        }

        this.setState({ pendingAddMeeting: true })

        let meetingPlan = 19091
        const username = user.first_name + ' ' + user.last_name
        if (user.user_plan_id === 2) {
            meetingPlan = 19092
        } else if (user.user_plan_id === 3) {
            meetingPlan = 19093
        }
        const response = await api.addMeet(user.email, user.extension.data.id, meetingPlan, username)

        if (response.error) {
            this.setState({ showAddMeetingDialog: false, addMeetingUser: null, pendingAddMeeting: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while adding \'Meeting\' to user.' })
            return
        }
        if (response.meeting_url && response.meeting_url.length > 0) {
            user.meeting_plan = {}
            user.meeting_plan.add_on_configuration = {}
            user.meeting_plan.add_on_configuration.meeting_url = response.meeting_url
            this.props.updateUser(user)
        }
        this.setState({ showAddMeetingDialog: false, addMeetingUser: null, pendingAddMeeting: false, pdcPromptOpen: true, pdcPromptMessage: '\'Meeting\' added to user .', pdcPromptTestId: 'prompt-meeting-activation-successfull' })
    }

    toggleAdminUser = async (user) => {
        const updatedUser = { ...user }
        updatedUser.is_admin = !updatedUser.is_admin

        if (!updatedUser.is_admin && user.status === 'active') {
            const userStatsResponse = await api.getUsersStats()
            if (userStatsResponse.error) {
                this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while updating user.' })
                return
            }
            if (userStatsResponse.data.admin_count < 2) {
                this.setState({ showInfoDialog: true })
                return
            }
        }

        const response = await api.updateUser(this.cleanUser(updatedUser))
        if (response.error) {
            this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while updating user.' })
            return
        }
        // TODO check if success response then update user in State
        this.changeValueInUser(updatedUser, 'is_admin', updatedUser.is_admin)
        const pdcPromptMessage = updatedUser.is_admin ? `${updatedUser.first_name} ${updatedUser.last_name} was given admin status.` : `${updatedUser.first_name} ${updatedUser.last_name} is no longer an admin.`
        this.setState({ pdcPromptOpen: true, pdcPromptMessage })
    }

    removeUserFromState = user => {
        this.props.deleteUser(user)
    }

    cleanUser = (user) => {
        const extraKeys = [
            'relationships', 'direct_number', 'meeting_plan', 'device', 'expanded'
        ]
        extraKeys.forEach((key) => {
            delete user.key
        })
        return user
    }

    findAvailableNumbers = async (area_code = null, limit = 9) => {
        this.setState({ pendingFindAvailableNumbers: true })
        area_code = area_code === '' ? null : area_code
        const response = await api.findAvailableNumbers(area_code, limit)
        if (response.error) {
            this.setState({ pendingFindAvailableNumbers: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while fetching available numbers.' })
            return
        }
        let numbers = response ? response.numbers : []
        if (this.state.lastAssignedNumber) numbers = numbers.filter((n) => this.state.lastAssignedNumber.e164 !== n.e164)
        this.props.setNewNumbers(numbers)
        this.setState({ pendingFindAvailableNumbers: false })
    }

    addAvailableNumber = async (number, user) => {
        this.setState({ pendingAddAvailableNumber: true })
        const response = await api.addNumberToAccountAndAssignToUser(number.search_result_id, user.id)
        if (response.error) {
            this.setState({ pendingAddAvailableNumber: false, pdcPromptOpen: true, pdcPromptMessage: response.error.customer_message ? response.error.customer_message : 'Something went wrong while adding number.' })
            return false
        }
        user.direct_number = { e164_number: number.e164, voip_did_id: response.voip_did_id }
        this.props.updateUser(user)
        this.setState({ lastAssignedNumber: number, pendingAddAvailableNumber: false, pdcPromptOpen: true, pdcPromptMessage: `Number ${number.formatted} successfully assigned to ${user.first_name} ${user.last_name}.`, pdcPromptTestId: 'prompt-reserved-number-assign-successfull' })
        return true
    }

    assignNumber = async (user, number) => {
        if (this.state.pendingAssignNum) return

        if (!this.state.showAssignNumDialog) return this.setState({ showAssignNumDialog: true, assignNumUser: user, assignNumber: number })

        this.setState({ pendingAssignNum: true })

        const response = await api.assignNumberToExtension(number, user.extension.data.id)

        if (response.error) {
            this.setState({ showAssignNumDialog: false, assignNumUser: null, assignNumber: null, pendingAssignNum: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while assigning number.' })
            return
        }

        api.updatePhoneNumber({ id: number.id, name: `${user.first_name} ${user.last_name}` })

        user.direct_number = { e164_number: number.phone_number, voip_did_id: number.voip_did_id, id: number.id }
        this.props.updateUser(user)

        const numbersList = this.props.numbers
        numbersList.splice(numbersList.findIndex(num => num.voip_did_id === number.voip_did_id), 1)
        this.props.setNumbers(numbersList)

        this.setState({ showAssignNumDialog: false, assignNumUser: null, assignNumber: null, pendingAssignNum: false, pdcPromptOpen: true, pdcPromptMessage: `Number ${this.prettyFormatNumberOnLoad(number.phone_number)} successfully assigned to ${user.first_name} ${user.last_name}.`, pdcPromptTestId: 'prompt-get-new-number-assign-successfull' })

        const numbersResponse = await api.listUnassignedNumbers()
        this.props.setNumbers(numbersResponse ? numbersResponse.items : this.props.numbers)
    }

    unassignPhoneNumber = async (user) => {
        if (this.state.pendingUnassignNum) return

        if (!this.state.showUnassignNumDialog) return this.setState({ showUnassignNumDialog: true, unassignNumUser: user })

        this.setState({ pendingUnassignNum: true })

        const response = await api.unassignNumber(user.direct_number.voip_did_id, [user.extension.data.id])
        if (response.error) {
            this.setState({ showUnassignNumDialog: false, unassignNumUser: null, pendingUnassignNum: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while unassigning number.', pdcPromptTestId: 'prompt-number-unassign-failed' })
            return
        }
        api.updatePhoneNumber({ id: user.direct_number.id, name: 'Unassigned' })
        user.direct_number = null
        this.props.updateUser(user)
        this.setState({ showUnassignNumDialog: false, unassignNumUser: null, pendingUnassignNum: false, pdcPromptOpen: true, pdcPromptMessage: 'Phone number unassigned.', pdcPromptTestId: 'prompt-number-unassigned-successfully' })
        const numbersResponse = await api.listUnassignedNumbers()
        this.props.setNumbers(numbersResponse ? numbersResponse.items : this.props.numbers)
    }

    changeValueInUser = (user, key, value) => {
        const users = this.props.users
        // eslint-disable-next-line
        for (const i in users) {
            if (users[i].id === user.id) {
                users[i][key] = value
                this.props.updateUser(users[i])
                break
            }
        }
    }

    prettyFormatNumberOnLoad = phoneNumber => {
        let number = (phoneNumber.match(/\d/g) || [])
        number = this.props.liveFormatNumber(number.join('').substring(1), '')
        return number
    }

    getBasePath = () => {
        const basePath = this.props.routeProps.match.path.replace(/\/+$/g, '')
        return basePath
    }

    updateRouterHistory = path => {
        this.props.routeProps.history.push(`${this.getBasePath()}/${path}`)
    }

    onOpenUserSettings = (user, tabName) => {
        const extensionId = user.relationships?.extension?.data?.id
        if (!extensionId) return console.error('No extension found for user:', user)
        this.updateRouterHistory(`e${extensionId}/personal-settings/${tabName}`)
    }

    closeUserSettings = () => {
        this.setState({ loading: true })
        PhoneComUser.changeExtension(this.props.extension.extension_id)
        this.updateRouterHistory('')
        this.init()
    }

    openInviteUserModal = inviteUserId => { this.setState({ showInviteUserModal: true, inviteUserId }) }

    onInviteUser = async (user) => {
        const response = await api.updateUser(this.cleanUser(user))
        if (response.error) {
            if (response.error.customer_message.length > 1 && response.error.customer_message.includes('unique')) return 'email_taken'
            this.setState({ pdcPromptOpen: true, pdcPromptMessage: response.error.customer_message.length > 1 ? response.error.customer_message : 'Something went wrong while inviting user.' })
            return
        }
        this.closeInviteUserModal()
        this.props.updateUser(user)
        this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'User was invited by email, make sure they join!' })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    changeUserPlan = async (user, planId) => {
        if (planId === 1 && !!user?.device) return null
        this.setState({ loadingBar: true, loadingBarStep: 'start' })
        const oldPlan = user.user_plan_id
        user.user_plan_id = planId
        this.props.updateUser(user)
        const response = await api.updateUser(this.cleanUser(user))
        if (response.error) {
            user.user_plan_id = oldPlan
            this.props.updateUser(user)
            this.setState({ loadingBar: false, pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while updating the user plan.' })
            return
        }
        window.hasProUser = await Api.getAccountHasProUser()
        await this.sleep(7000)
        this.setState({ loadingBarStep: 'middle' })
        await this.sleep(7000)
        this.setState({ loadingBarStep: 'end' })
        await this.sleep(500)
        this.setState({ loadingBar: false, pdcPromptOpen: true, pdcPromptMessage: 'Plan changed! Cost change will reflect in your next bill.', pdcPromptTestId: 'plan-chaned-successfully' })
    }

    closeInviteUserModal = () => { this.setState({ showInviteUserModal: false, inviteUserId: null }) }

    renderUnassignNumberDialog = () => {
        const { classes } = this.props
        const user = this.state.unassignNumUser
        let modalContent = <div><br/>The phone number will be unassigned from the user and will not be available to them.</div>
        const modalTitle = this.state.pendingUnassignNum ? '' : `Unassign phone number ${user ? this.prettyFormatNumberOnLoad(user.direct_number.e164_number) : ''} from ${user ? user.first_name + ' ' + user.last_name : 'user'}?`
        modalContent = this.state.pendingUnassignNum ? <div className={classes.confirmSpinner}><Spinner color='action'/></div> : modalContent

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.showUnassignNumDialog}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = {'Unassign number'}
                yesButtonColor = {this.state.pendingUnassignNum ? 'secondary' : 'primary'}
                onReject = {() => this.setState({ showUnassignNumDialog: false })}
                onConfirm = {() => this.unassignPhoneNumber(user)}
                size = 'size3'
            />
        )
    }

    renderAssignNumberDialog = () => {
        const { classes } = this.props
        const user = this.state.assignNumUser
        const number = this.state.assignNumber
        let display = null
        if (number) display = this.prettyFormatNumberOnLoad(number.phone_number)
        let modalContent = <div><br/></div>
        const modalTitle = this.state.pendingAssignNum ? '' : `Are you sure you want to assign ${display || 'this number'} to ${user ? user.first_name + ' ' + user.last_name : 'the user'}?`
        modalContent = this.state.pendingAssignNum ? <div className={classes.confirmSpinner}><Spinner color='action'/></div> : modalContent

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.showAssignNumDialog}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = {'Assign number'}
                yesButtonColor = {this.state.pendingAssignNum ? 'secondary' : 'primary'}
                onReject = {() => this.setState({ showAssignNumDialog: false })}
                onConfirm = {() => this.assignNumber(user, number)}
                size = 'size3'
            />
        )
    }

    renderAddMeetDialog = () => {
        const { classes } = this.props
        const user = this.state.addMeetingUser
        let modalContent = <div><br/>{'The "Meeting" feature will be enabled for this user.'}</div>
        const modalTitle = this.state.pendingAddMeeting ? '' : `Add meeting to ${user ? user.first_name : ''} ${user ? user.last_name : ''}?`
        modalContent = this.state.pendingAddMeeting ? <div className={classes.confirmSpinner}><Spinner color='action'/></div> : modalContent

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.showAddMeetingDialog}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = {'Add Meeting'}
                yesButtonColor = {this.state.pendingAddMeeting ? 'secondary' : 'primary'}
                onReject = {() => this.setState({ showAddMeetingDialog: false })}
                onConfirm = {() => this.addMeeting(user)}
                size = 'size3'
            />
        )
    }

    renderResendInviteDialog = () => {
        const { classes } = this.props
        const user = this.state.reInviteUser
        let modalContent = <div><br/>{'An invitation link will be sent to the user\'s email.'}</div>
        const modalTitle = this.state.pendingReInvite ? '' : `Resend invitation to ${user ? user.email : 'user\'s email'} ?`
        modalContent = this.state.pendingReInvite ? <div className={classes.confirmSpinner}><Spinner color='action'/></div> : modalContent

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.showReInviteDialog}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = {'Send invitation'}
                yesButtonColor = {this.state.pendingReInvite ? 'secondary' : 'primary'}
                onReject = {() => this.setState({ showReInviteDialog: false })}
                onConfirm = {() => this.resendInvite(user)}
                size = 'size3'
            />
        )
    }

    renderResetPasswordDialog = () => {
        const { classes } = this.props
        const user = this.state.resetPwUser
        let modalContent = <div><br/>A reset link will be sent to {user ? user.email : 'the user\'s email'}.</div>
        const modalTitle = this.state.pendingResetPw ? '' : `Reset password for ${user ? user.first_name : ''} ${user ? user.last_name : ''}?`
        modalContent = this.state.pendingResetPw ? <div className={classes.confirmSpinner}><Spinner color='action'/></div> : modalContent

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.showResetPwDialog}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = {'Send reset link'}
                yesButtonColor = {this.state.pendingResetPw ? 'secondary' : 'primary'}
                onReject = {() => this.setState({ showResetPwDialog: false })}
                onConfirm = {() => this.resetPassword(user)}
                size = 'size3'
            />
        )
    }

    renderDeleteDialog = () => {
        const { classes } = this.props
        const user = this.state.deleteUser
        const hasPhoneContent = user?.device === null ? null : <p>{user ? user.first_name : 'This user'} {user ? user.last_name : ''} has an assigned phone. If you proceed this phone will be automatically unassigned and <b>will not work.</b></p>
        let modalContent = <div> {hasPhoneContent} <p style={{ fontWeight: 'bold' }}><br/>This action cannot be undone.</p></div>
        const modalTitle = this.state.pendingDelete ? '' : `Delete ${user ? user.first_name : ''} ${user ? user.last_name : ''}?`
        modalContent = this.state.pendingDelete ? <div className={classes.confirmSpinner}><Spinner color='action'/></div> : modalContent

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.showDeleteDialog}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = {`Delete ${user ? user.first_name : ''}`}
                yesButtonColor = {this.state.pendingDelete ? 'secondary' : 'attention'}
                onReject = {() => this.setState({ showDeleteDialog: false })}
                onConfirm = {() => this.deleteUser(user)}
                size = {this.props.smallView ? 'sizeMobile' : 'size550'}
            />
        )
    }

    renderAdminDialog = () => {
        const { classes } = this.props
        const user = this.state.reassignAdminUser
        const firstLineContent = `Phone.com supports only 1 admin user. If you set ${user ? user.first_name : 'this user'} as the new admin, you will lose access to this configuration tool.`
        let modalContent = <div> {firstLineContent} <p style={{ fontWeight: 'bold' }}><br/>Are you sure?</p></div>
        const modalTitle = this.state.pendingAdmin ? '' : `Make ${user ? user.first_name : ''} ${user ? user.last_name : 'this user'} the admin ?`
        modalContent = this.state.pendingAdmin ? <div className={classes.confirmSpinner}><Spinner color='action'/></div> : modalContent

        return (
            <ConfirmModal
                title = {modalTitle}
                isShown = {this.state.showAdminDialog}
                content = {modalContent}
                noButtonText = 'Cancel'
                yesButtonText = {'Change admin'}
                yesButtonColor = {this.state.pendingAdmin ? 'secondary' : 'attention'}
                onReject = {() => this.setState({ showAdminDialog: false })}
                onConfirm = {() => this.reassignAdminUser(user)}
                size = 'size3'
            />
        )
    }

    renderInfoDialog = () => {
        const content = <div>You cannot remove admin rights from yourself as you are the only admin</div>
        return (
            <InfoModal
                title = 'At lest 1 admin is required'
                isShown = {this.state.showInfoDialog}
                content = {content}
                buttonText = 'Got it'
                onConfirm = {() => this.setState({ showInfoDialog: false })}
                size = 'size1'
            />
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} styles={{ loaderFull: { left: this.props.smallView ? '50%' : 'calc(50% + 120px)' } }} size='bigger'/>
            </div>
        )
    }

    renderBarLoader = () => {
        const { classes, hidden } = this.props
        const onProgressChange = width => { document.getElementsByClassName('app-loader-progress-fill')[0].style.width = `${width}%` }
        return (
            <div className={`${classes.appLoader} ${hidden ? classes.appLoader_appLoaderHidden : ''}`}>
                <div className={classes.appLoaderIcon}></div>
                <span className={classes.progressBar}>
                    <ProgressBar
                        progressThresholds = {{ start: { min: 0, max: 55 }, middle: { min: 55, max: 88 }, end: { min: 88, max: 100 } }}
                        intervalSpeeds = {{ SLOW: 80 }}
                        currentStep = {this.state.loadingBarStep}
                        lastStep = 'end'
                        onProgress = {onProgressChange}
                        showSlider = {true}
                    >
                        <div className='app-loader-progress-fill'></div>
                    </ProgressBar>
                </span>
                <span className={classes.loadingText}>Configuring new user plan...<span className='name'></span></span>
            </div>
        )
    }

    renderInviteUserModal = () => {
        if (!this.state.showInviteUserModal) return null
        const user = this.props.users.find(u => u.id === this.state.inviteUserId)
        return (
            <InviteUserModal
                showModal = {true}
                user = {user}
                onCloseModal = {this.closeInviteUserModal}
                onInviteUser = {this.onInviteUser}
            />
        )
    }

    showSIPCredentials = user => {
        if (!user.extensionDevice) return
        this.setState({ showSipCredentialsId: user.id })
    }

    renderManageUsersSubcomponent = () => {
        if (this.state.loading || this.state.loadingBar) return
        const ManageUsersSubcomponent = this.props.smallView ? ManageUsersSmall : ManageUsersLarge
        return (
            <ManageUsersSubcomponent
                changeValueInUser = {this.changeValueInUser}
                prettyFormatNumberOnLoad = {this.prettyFormatNumberOnLoad}
                toggleAdminUser = {this.toggleAdminUser}
                unassignPhoneNumber = {this.unassignPhoneNumber}
                resetPassword = {this.resetPassword}
                resendInvite = {this.resendInvite}
                deleteOneUser = {this.deleteUser}
                reassignAdminUser = {this.reassignAdminUser}
                addMeeting = {this.addMeeting}
                updateUserExtension = {this.updateUserExtension}
                changeUserPlan = {this.changeUserPlan}
                openInviteUserModal = {this.openInviteUserModal}
                onOpenUserSettings = {this.onOpenUserSettings}
                assignNumber = {this.assignNumber}
                findAvailableNumbers = {this.findAvailableNumbers}
                addAvailableNumber = {this.addAvailableNumber}
                pendingFindAvailableNumbers = {this.state.pendingFindAvailableNumbers}
                pendingAddAvailableNumber = {this.state.pendingAddAvailableNumber}
                loadMore = {this.loadMore}
                hasMore = {this.state.hasMore}
                showSIPCredentials = {this.showSIPCredentials}
                isCSR = {this.props.isCSR}
                forceProPlan = {this.state.forceProPlan}
            />
        )
    }

    renderUserSettings = (props) => {
        if (this.state.loading) return
        const extensionId = props.match.params.extensionId
        const user = this.props.users.find(e => e.relationships?.extension?.data?.id === parseInt(extensionId))
        if (!user) return this.updateRouterHistory('')
        return (
            <UserSettings
                userId = {user.id}
                onClose = {this.closeUserSettings}
                setBusy = {this.props.setBusy}
                routeProps = {props}
            />
        )
    }

    renderSipCredentialsModal = () => {
        const { showSipCredentialsId, loading } = this.state
        const { users } = this.props
        if (!showSipCredentialsId || loading) return null
        const user = users.find(u => u.id === showSipCredentialsId)
        const sipAuthentication = user?.extensionDevice?.sip_authentication
        if (!sipAuthentication) return null
        const data = {
            name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
            sipProxy: sipAuthentication.host,
            username: sipAuthentication.username,
            password: sipAuthentication.password,
            onClose: () => this.setState({ showSipCredentialsId: false })
        }
        return <SipCredentialsModal {...data}/>
    }

    renderUsersView = () => {
        const { classes } = this.props
        return (
            <div className={this.props.smallView ? classes.manageUsersContainer : classes.manageUsersContainerMobile}>
                {this.renderManageUsersSubcomponent()}
                {this.renderInviteUserModal()}
                {this.renderAddMeetDialog()}
                {this.renderUnassignNumberDialog()}
                {this.renderAssignNumberDialog()}
                {this.renderResendInviteDialog()}
                {this.renderResetPasswordDialog()}
                {this.renderDeleteDialog()}
                {this.renderInfoDialog()}
                {this.renderSipCredentialsModal()}
                {this.renderAdminDialog()}

                {this.state.loading ? this.renderLoader() : null}
                {this.state.loadingBar ? this.renderBarLoader() : null}

                <Prompt
                    isOpen = {this.state.pdcPromptOpen}
                    color = {this.state.pdcPromptColor}
                    position = 'bottom'
                    content = {this.state.pdcPromptMessage}
                    onClose = {this.handlepdcPromptClose}
                    testId = {this.state.pdcPromptTestId}
                />
            </div>
        )
    }

    render = () => {
        return (
            <Switch>
                <Route
                    path={`${this.getBasePath()}/e:extensionId/personal-settings/`}
                    render={this.renderUserSettings}
                />
                <Route path={`${this.getBasePath()}`} render={this.renderUsersView}/>
            </Switch>
        )
    }
}

ManageUsers.propTypes = {
    classes: PropTypes.object,
    hasMore: PropTypes.bool,
    changeValueInUser: PropTypes.func,
    loadMore: PropTypes.bool,
    numbers: PropTypes.array,
    smallView: PropTypes.bool,
    users: PropTypes.array,
    pendingAddAvailableNumber: PropTypes.bool,
    addAvailableNumber: PropTypes.func,
    findAvailableNumbers: PropTypes.func,
    prettyFormatNumberOnLoad: PropTypes.func,
    openInviteUserModal: PropTypes.func,
    updateUserExtension: PropTypes.func,
    addMeeting: PropTypes.func,
    deleteOneUser: PropTypes.func,
    assignNumber: PropTypes.func,
    onOpenUserSettings: PropTypes.func,
    unassignPhoneNumber: PropTypes.func,
    resendInvite: PropTypes.func,
    resetPassword: PropTypes.func,
    changeUserPlan: PropTypes.func,
    pendingFindAvailableNumbers: PropTypes.bool,
    newNumbers: PropTypes.array,
    setNewNumbers: PropTypes.func,
    setUsers: PropTypes.func,
    setNumbers: PropTypes.func,
    updateUser: PropTypes.func,
    deleteUser: PropTypes.func,
    setBusy: PropTypes.func,
    hidden: PropTypes.bool,
    extension: PropTypes.object,
    routeProps: PropTypes.object,
    liveFormatNumber: PropTypes.func,
    isCSR: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ManageUsers))
