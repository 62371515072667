import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=6024%3A8458
 *
 * @param {object} props - svg props
 */
export const AfterHoursIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0)'>
                <path d='M16.1593 10.529C14.3696 7.02369 15.336 3.87977 16.2219 2.15423C16.3919 1.82899 16.1145 1.45859 15.7476 1.50376C11.2556 2.11809 7.81944 6.08413 8.00735 10.8271C8.0163 10.8271 8.0163 10.8271 8.0163 10.8361C8.00735 10.8271 8.00735 19.0781 16.9557 19.5C19.7565 19.5 22.2531 18.1991 23.8996 16.1664C24.1322 15.8773 23.9354 15.4527 23.5685 15.4256C21.3403 15.3081 17.9489 14.0433 16.1593 10.529Z' fill={color}/>
                <path d='M14.4621 15.2082L14.5976 15.8596L15.2615 15.9031C16.9097 16.0112 18.125 17.2794 18.125 18.75C18.125 20.2928 16.7886 21.625 15.0417 21.625H4.75C2.56765 21.625 0.875 19.9553 0.875 18C0.875 16.173 2.34503 14.5998 4.3231 14.4006L4.78064 14.3545L5.00218 13.9515C5.8361 12.4347 7.52727 11.375 9.5 11.375C11.9909 11.375 14.013 13.0494 14.4621 15.2082Z' fill='white' stroke={color} strokeWidth='1.75'/>
            </g>
            <defs>
                <clipPath id='clip0'>
                    <rect width='24' height='24' fill='white'/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default AfterHoursIcon
