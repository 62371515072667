import React, { Component } from 'react'

import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
// import Menu from 'pdc-menu' // Use this instead of material-ui's Autocomplete
// import { languageOptions, voiceOptions } from './util'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
// import TextField from '@material-ui/core/TextField'
// import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import PropTypes from 'prop-types'

// Should remove this two bellow because we should use the ones from 'util'. Anyway not needed yet.
const voices = ['Joanna', 'Salli', 'Joey', 'Matthew']
const languages = ['English']

class AudioTts extends Component {
    constructor (props) {
        super(props)
        this.state = {
            text: props.text || '',
            voice: props.voice || voices[0],
            language: props.language || languages[0]
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.text !== this.props.text) this.setState({ text: this.props.text })
    }

    renderAudioPlayer = audio => {
        const { classes } = this.props
        return <div className={classes.audioPlayerContainer}>
            <div style={{ display: this.state.readyToPlay ? 'block' : 'none' }}>
                <AudioPlayer
                    key = {audio.voip_recording_id || audio.voipRecordingId}
                    url = {audio.download_link || audio.downloadLink}
                    onPlay = {() => { /**/ }}
                    onReadyToPlay = {() => this.setState({ readyToPlay: true })}
                />
            </div>
            {!this.state.readyToPlay ? <LoadingBar/> : null}
        </div>
    }

    onTtsChange = e => {
        const text = e.target.value
        this.setState({ text })
        const tts = {
            text,
            voice: this.state.voice,
            language: this.state.language
        }
        this.props.updateTts(tts)
    }

    render = () => {
        const { classes, showValidationErrors } = this.props
        const error = showValidationErrors && !this.state.text
        return (
            <div className={classes.ttsContent}>
                <textarea
                    id = 'tts-textarea'
                    value = {this.state.text}
                    onChange = {this.onTtsChange}
                    className = {`tts-textarea ${error ? 'error' : ''}`}
                />
                {!this.props.hideTtsOptions
                    ? <>
                        <hr className={classes.separator}/>
                        <div className={classes.languageOptions}>
                            <div>{this.state.language}</div>
                            <div>{this.state.voice}</div>
                            {/* <Autocomplete
                                id = 'language-autocomplete'
                                options = {languages}
                                value = {this.state.ttsLanguage}
                                getOptionLabel = {option => option}
                                classes = {{ root: `${classes.autocomplete} lang-autocomplete` }}
                                onChange = {(e, language) => this.setState({ language })}
                                renderInput = {params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        InputProps = {{
                                            ...params.InputProps,
                                            placeholder: 'Language',
                                            classes: {
                                                root: classes.autocompleteInputParent,
                                                focused: classes.autocompleteInputParent,
                                                input: classes.autocompleteInput
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                id = 'tts-voice-autocomplete'
                                options = {[voices[0]]}
                                value = {this.state.voice}
                                getOptionLabel = {option => option}
                                classes = {{ root: `${classes.autocomplete} voice-autocomplete` }}
                                onChange = {(e, voice) => this.setState({ voice })}
                                renderInput = {params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        InputProps = {{
                                            ...params.InputProps,
                                            placeholder: 'Speaker',
                                            classes: {
                                                root: classes.autocompleteInputParent,
                                                focused: classes.autocompleteInputParent,
                                                input: classes.autocompleteInput
                                            }
                                        }}
                                    />
                                )}
                            /> */}
                        </div>
                        {this.props.audio && this.props.audio.type === 'tts' ? this.renderAudioPlayer(this.props.audio) : null}
                        <div className={classes.infoSection}>
                            <InfoOutlinedIcon classes={{ root: classes.infoIcon }}/>At this time we only support English TTS voices
                        </div>
                    </>
                    : this.props.audio && this.props.audio.type === 'tts' ? this.renderAudioPlayer(this.props.audio) : null}
            </div>
        )
    }
}

AudioTts.propTypes = {
    /**
     * Material ui classes
     */
    classes: PropTypes.object,
    audio: PropTypes.object,
    hideTtsOptions: PropTypes.bool,
    updateTts: PropTypes.func,
    text: PropTypes.string,
    voice: PropTypes.string,
    language: PropTypes.string,
    showValidationErrors: PropTypes.bool
}

export default withStyles(styles)(AudioTts)
