import React, { Component } from 'react'
import { CheckmarkIcon2 } from 'pdc-svg-icons'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

class SelectionControl extends Component {
    componentDidMount = () => {
        this.setSelection()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.checked !== prevProps.checked) this.setSelection()
    }

    setSelection = () => {
        const elements = Array.from(document.getElementsByName(this.props.name))
        const element = elements.find(e => e.getAttribute('value') === this.props.value.toString())
        element.checked = this.props.checked
    }

    // {/* TODO: deprecate, use PdcSwitch */}
    renderSwitch = () => {
        const { classes, checked, disabled, name, value, className, error } = this.props
        const checkedClass = checked ? 'checked' : 'unchecked'
        const disabledClass = disabled ? 'disabled' : ''
        const errorClass = error ? 'error' : ''
        let classNames = `${classes.switchWrapper} ${checkedClass} ${disabledClass} ${errorClass}`
        if (className && className.wrapper) classNames += ` ${className.wrapper}`
        return (
            <div
                data-selection-conrol = 'switch'
                className = {classNames}
                onClick = {this.props.onClick}
            >
                <div
                    className = 'switch'
                    tabIndex = {0}
                    onKeyUp = {this.onKeyUp}
                    onKeyDown = {this.onKeyDown}
                >
                    <div className='thumb'>
                        <CheckmarkIcon2/>
                        <input readOnly type='checkbox' name={name} value={value} id={value.toString()} checked={checked} style={{ display: 'none' }}/>
                    </div>
                </div>
            </div>
        )
    }

    onKeyDown = e => {
        if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
            e.preventDefault()
        }
    }

    onKeyUp = e => {
        if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
            e.preventDefault()
            this.props.onClick(e)
        }
    }

    renderCheckbox = () => {
        const { classes, checked, disabled, name, value, disableHover, className, error } = this.props
        const checkedClass = checked ? 'checked' : 'unchecked'
        const disabledClass = disabled ? 'disabled' : ''
        const disableHoverClass = disableHover ? 'no-hover' : ''
        const errorClass = error ? 'error' : ''
        let classNames = `${classes.checkboxWrapper} ${checkedClass} ${disabledClass} ${disableHoverClass} ${errorClass}`
        if (className && className.wrapper) classNames += ` ${className.wrapper}`
        return (
            <div
                data-selection-conrol = 'checkbox'
                className = {classNames}
                onClick = {this.props.onClick}
            >
                <div
                    className = 'checkbox'
                    tabIndex = {0}
                    onKeyUp = {this.onKeyUp}
                    onKeyDown = {this.onKeyDown}
                ><CheckmarkIcon2/></div>
                <input readOnly type='checkbox' name={name} value={value} id={value.toString()} checked={checked} style={{ display: 'none' }}/>
            </div>
        )
    }

    renderRadio = () => {
        const { classes, checked, disabled, name, value, className, error } = this.props
        const checkedClass = checked ? 'checked' : 'unchecked'
        const disabledClass = disabled ? 'disabled' : ''
        const errorClass = error ? 'error' : ''
        let classNames = `${classes.radioWrapper} ${checkedClass} ${disabledClass} ${errorClass}`
        if (className && className.wrapper) classNames += ` ${className.wrapper}`
        return (
            <div
                data-selection-conrol = 'radio'
                className = {classNames}
                onClick = {this.props.onClick}
            >
                <div
                    className = 'radio'
                    tabIndex = {0}
                    onKeyUp = {this.onKeyUp}
                    onKeyDown = {this.onKeyDown}
                >
                    <div className='check-circle'></div>
                </div>
                <input readOnly type='radio' name={name} value={value} id={value.toString()} checked={checked} style={{ display: 'none' }}/>
            </div>
        )
    }

    render = () => {
        const { variant } = this.props
        return (
            variant === 'switch'
                ? this.renderSwitch()
                : variant === 'checkbox'
                    ? this.renderCheckbox()
                    : variant === 'radio'
                        ? this.renderRadio()
                        : null
        )
    }
}

SelectionControl.propTypes = {
    /**
     * The variant of the SelectionControl
     * 'switch', 'checkbox' or 'radio'
     */
    variant: PropTypes.string,
    /**
     * Is it checked
     * true or false
     */
    checked: PropTypes.bool,
    /**
     * Is it disabled
     * true or false
     */
    disabled: PropTypes.bool,
    /**
     * The name of the group
     */
    name: PropTypes.string.isRequired,
    /**
     * A unique value
     */
    value: PropTypes.string.isRequired,
    /**
     * A function which will be called when the component is clicked.
     */
    onClick: PropTypes.func,
    /**
     * The object props can be in: ['wrapper']
     */
    className: PropTypes.object,
    /**
     * Show error color if true
     */
    error: PropTypes.bool
}

SelectionControl.defaultProps = {
    variant: 'vertical',
    disabled: false,
    checked: false
}

SelectionControl.propTypes = {
    classes: PropTypes.object,
    disableHover: PropTypes.bool
}
export default withStyles(styles)(SelectionControl)
