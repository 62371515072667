import { CallIcon, CheckAltIcon } from 'svg-icons'
import { formatPhoneNumber } from 'phone-numbers'
import { UpdateData } from 'step-wizard/src/StepWizard'
import { withStyles } from '@material-ui/core/styles'
import api from '../../util/api_v5.js'
import ConfirmModal from 'confirm-modal'
import MenuItem from 'menu-item-mui'
import PhoneComUser from 'phone-com-user'
import React, { Component } from 'react'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import Select from 'select-mui'
import styles from './styles'
import TableMui from 'pdc-table-mui'
import Typography from 'typography'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'

interface Props {
    saveId: string
    update: (data: UpdateData) => void
    passData: any
    classes
    extension: any
    smallView: boolean
    stepIndex: any
}

class NumberAssignmentStep extends Component<Props, any> {
    constructor (props) {
        super(props)
        this.state = {
            phoneNumbers: this.props.passData?.numberAssignment?.unassignedNumbers || [],
            assignmentList: this.formatAssignmentList(),
            showConfirmModal: false,
            isLoading: !this.props.passData?.numberAssignment?.unassignedNumbers,
            hasChange: false
        }
    }

    componentDidMount = async () => {
        this.getUnassignedNumbers()
    }

    setAssignmentState = async () => {
        this.setState({ isLoading: true })
        const voipId = PhoneComUser.getAPIAccountId()
        const payload = this.payloadFromAssignmentList()
        const response = await api.validateNumberAssignmentList(voipId, payload)
        if (!response?.data) {
            this.setState({ isLoading: false })
            console.log('No data received from validateNumberAssignmentList')
            return
        }
        const additionalCharge = `$${response.data.additional_monthly_recurring_charge_cents / 100}`
        this.setState({ unaccountedNumbers: response.data.unaccounted_numbers, additionalCharge: additionalCharge, isLoading: false })
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const hasSaveRequest = this.props.saveId && (prevProps.saveId !== this.props.saveId)
        const movingForward = (parseInt(this.props.saveId?.split('#')[1]) > this.props.stepIndex)
        this.markThisAndNextStepsAsNotComplete(prevState)
        if (hasSaveRequest) {
            if (!movingForward) {
                this.save()
            } else {
                await this.setAssignmentState()
                if (this.state.unaccountedNumbers) {
                    this.setState({ showConfirmModal: true })
                } else {
                    this.save()
                }
            }
        }
    }

    save = () => {
        this.props.update({
            completed: true,
            gotoNext: true,
            saveData: {
                assignmentList: Object.entries(this.state.assignmentList).map(([number, assignedValue]) => {
                    return {
                        numberToTransfer: number,
                        assignedValue: assignedValue
                    }
                }),
                unassignedNumbers: this.state.phoneNumbers
            }
        })
    }

    markThisAndNextStepsAsNotComplete = (prevState) => {
        if (this.state.hasChange) return
        if (
            prevState.assignmentList !== this.state.assignmentList ||
            prevState.phoneNumbers !== this.state.phoneNumbers
        ) {
            this.props.update({
                completed: false
            })
            this.setState({ hasChange: true })
        }
    }

    payloadFromAssignmentList = () => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const list = Object.entries(this.state.assignmentList).map(([number, tempnum]) => ({ number_to_transfer: number, temporary_number: tempnum }))
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { assignment_list: list }
    }

    // TODO delete this function if not in use
    // Recover any temporary-number selections previously made on this step
    reconstructAssignmentData = () => {
        if (this.props.passData.numberAssignment) {
            const reconstructed = []
            this.props.passData.numberAssignment.assignmentList.forEach((i) => { reconstructed[i.numberToTransfer] = i.assignedValue })
            return reconstructed
        } else { return false }
    }

    formatAssignmentList = () => {
        const phoneNumbers = this.props.passData?.numberInfo?.phoneNumbers || []
        const passAssignmentList = this.props.passData?.numberAssignment?.assignmentList || []

        const assignmentList = {}

        const passAssignmentObject = {}
        passAssignmentList.forEach(e => (passAssignmentObject[e.numberToTransfer] = e.assignedValue))
        phoneNumbers.forEach((number) => {
            assignmentList[number] = passAssignmentObject[number] || 'UNASSIGNED'
        })
        return assignmentList
    }

    onAssignment = (e, number) => {
        const selectedValue = e.target.value
        const newAssignmentList = { ...this.state.assignmentList, [number]: selectedValue }
        this.setState({ assignmentList: newAssignmentList })
    }

    // Numbers which are not currently used as temporary numbers,
    // and numbers which do not have temporary numbers assigned to them.
    getUnassignedNumbers = async () => {
        const prevUnassignedNumbers = this.props.passData?.numberAssignment?.unassignedNumbers
        if (!prevUnassignedNumbers) this.setState({ isLoading: true })
        const numbersResponse = await api.loadPhoneNumbers(null, { 'include-lrdn': false }, 100, 'asc')
        const filteredNumbers = numbersResponse.items.filter(number => number.temporary_number !== null)
        const assignedTemporaryNumbers = filteredNumbers.map(number => number.temporary_number)
        const validNumbers = numbersResponse.items.filter(number => number.temporary_number === null)
        const unusedNumbers = validNumbers.filter(number => !assignedTemporaryNumbers.includes(number.phone_number))
        const unassignedNumbers = unusedNumbers.map(number => {
            return {
                number: number.phone_number,
                name: number.name
            }
        })

        return this.setState({ phoneNumbers: unassignedNumbers, isLoading: prevUnassignedNumbers ? this.state.isLoading : false })
    }

    generateTableRows = () => {
        // Sample data
        // const phoneNumbers = this.state.phoneNumbers
        const getColumns = (phoneNumberDid: string, assignedValue: string) => ([
            { key: 0, content: formatPhoneNumber(phoneNumberDid), stringValue: formatPhoneNumber(phoneNumberDid), primary: true, testId: 'tw-number-assignment-number-to-transfer' },
            { key: 1, content: this.renderSelect(phoneNumberDid, assignedValue), testId: 'tw-number-assignment-temporary-number' }
        ])
        return Object.entries(this.state.assignmentList).map(([number, assignedValue]) => ({ key: number, columns: getColumns(number, assignedValue) }))
        // return phoneNumbers.map((n, i) => i ? ({ key: n.number, columns: getColumns(n.number, n.number) }) : ({ key: n.number, columns: getColumns(n.number, 'UNASSIGNED') }))
    }

    renderSelect = (phoneNumberDid, assignedValue) => {
        const { classes } = this.props
        const phoneNumbers = this.state.phoneNumbers
        const currentAssignment = this.state.assignmentList[phoneNumberDid]

        const alreadyAssigned = Object.values(this.state.assignmentList)
        const filteredNumbers = phoneNumbers.filter(phoneNumber => (
            !alreadyAssigned.includes(phoneNumber.number) || this.state.assignmentList[phoneNumberDid] === phoneNumber.number
        ))

        const itemsData = filteredNumbers.map(phoneNumber => ({
            key: phoneNumber.number,
            value: phoneNumber.number,
            checked: phoneNumber.number === currentAssignment,
            icon: phoneNumber.number === currentAssignment ? CheckAltIcon : CallIcon,
            content: `${formatPhoneNumber(phoneNumber.number)} - ${phoneNumber.name}`
        }))
        itemsData.unshift({
            key: 'UNASSIGNED',
            value: 'UNASSIGNED',
            checked: assignedValue === 'UNASSIGNED',
            icon: assignedValue === 'UNASSIGNED' ? CheckAltIcon : CallIcon,
            content: 'Auto-assign number',
            remoteInfoTipId: !this.props.smallView ? 'configure_numbers_submit_transfer_auto_assign_number' : null
        })
        return (
            <Select
                label = {null}
                value = {assignedValue}
                onChange = {(e) => { this.onAssignment(e, phoneNumberDid) }}
                // MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                formControlClasses = {{ root: classes.phoneNumberDropdownMenu }}
                data-test-id='tw-number-assignment-temporary-number-lsit'
            >
                {itemsData.map(itemData => (
                    <MenuItem
                        key = {itemData.key}
                        value = {itemData.value}
                        checked = {itemData.checked}
                        icon = {itemData.icon}
                        remoteInfoTipId = {itemData.remoteInfoTipId || null}
                        data-test-id = 'tw-number-assignment-temporary-number-list-item'
                    >{itemData.content}</MenuItem>
                ))}
            </Select>
        )
    }

    renderMobileView = () => {
        const { classes } = this.props
        return (
            <div className={classes.smallNumbersWrapper}>
                {Object.entries(this.state.assignmentList).map(([number, assignedValue]) => (
                    <div key={number} className='number-row'>
                        <Typography variant='h6'>{formatPhoneNumber(number)}</Typography>
                        <Typography variant='overline'>TEMPORARY NUMBER</Typography>
                        <div>{this.renderSelect(number, assignedValue)}</div>
                    </div>
                ))}
            </div>
        )
    }

    renderTable = () => {
        const { classes } = this.props
        const headColumns = [
            { content: 'Number to transfer', testId: 'number-to-transfer' },
            { content: <span className={classes.tempNumberHeaderColumn}>Temporary number <RemoteInfoTipMui arrow remoteConfigIDs={['configure_numbers_submit_transfer_temporary_number']} placement={RemoteInfoTipMuiPlacement.TOP}/></span>, testId: 'temporary-number' }
        ]
        return (
            <TableMui
                headColumns = {headColumns}
                rows = {this.generateTableRows()}
                infiniteScroller = {false}
                reverseScroll = {false}
                listView = {false}
                tdClasses = {classes.numberAssignmentTdClas}
            />
        )
    }

    renderConfirmModal = (classes) => {
        const infotip = <RemoteInfoTipMui arrow remoteConfigIDs={['configure_numbers_submit_transfer_is_additional_charges']} placement={RemoteInfoTipMuiPlacement.TOP}/>
        return (
            <ConfirmModal
                isShown={this.state.showConfirmModal}
                title={'Confirm additional charges'}
                content={<div>
                    <div className={classes.confirmChargesSubheader}>
                        <span className={classes.additionalNumbersCharge}>{this.state.unaccountedNumbers} x Additional numbers &ndash; monthly fee {infotip}</span>
                        <span> {this.state.additionalCharge}</span>
                    </div>
                    <hr/>
                    <Typography remoteConfigID='transfer_number_number_assignment_confirm_text_1'/>
                    <br/>
                    <Typography remoteConfigID='transfer_number_number_assignment_confirm_text_2'/>
                    <br/>
                    <Typography remoteConfigID='transfer_number_number_assignment_confirm_text_3'/>
                </div>}
                noButtonText='Cancel'
                yesButtonText='Continue'
                onReject={() => this.setState({ showConfirmModal: false })}
                onConfirm={() => { this.setState({ confirmed: true }); this.save() }}
                size={this.props.smallView ? 'sizeMobile' : 'size550'}
                noTitleMargin={true}
            />
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary.main} size='bigger'/>
            </div>
        )
    }

    render () {
        const { classes, smallView } = this.props
        return (
            <div className={classes.numberAssignment}>
                {this.state.isLoading ? this.renderLoader() : null}
                {this.renderConfirmModal(classes)}
                {smallView ? this.renderMobileView() : this.renderTable()}
            </div>
        )
    }
}

export default withStyles(styles)(NumberAssignmentStep)
