// React
import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { initializePhoneCom } from 'phonecom';
import api from './components/util/api';
import Modal from './components/util/modal/modal'
import Actions from "./components/actions/actions";
import PDCOpenConnection from 'pdc-open-connection';
import { isMobile } from 'react-device-detect';


// Stylesheet
import "./styles.css";

// Components
import NumberSearch from "./components/number-search";
import { object } from "prop-types";
import BrowserNotSupported from './components/browser-not-supported/BrowserNotSupported'
import { LabelCountIcon } from "pdc-svg-icons";


// Set up cookies
const cookies = new Cookies();

// Get Parameters
const root = document.getElementById("number_search");
const freeParam = root ? root.getAttribute("free") : null;
const localParam = root ? root.getAttribute("local") : null;
const tollfreeParam = root ? root.getAttribute("tollfree") : null;

let filter_by = {'price_min': 0, 'price_max': 500000};
if (freeParam === "true") {
  filter_by["free_only"] = true;
} else {
  filter_by["vanity"] = true;
}
if (localParam === "true") {
  filter_by["local"] = true;
}
if (tollfreeParam === "true") {
  filter_by["toll_free"] = true;
}


const initial_filter_by = Object.assign({}, filter_by);

let initial_query = {
  source: "",
  order_by: "",
  filter_by: initial_filter_by
};

const RESERVE_POP_STATES = {
  OFF: 0,
  ON: 1,
  FADE_OUT: 2
}

// App Wrapper
class NumberSearchApp extends React.Component {
  constructor(props) {
    super(props);

    if (props.source === "control_panel") {
      initial_query.source = "control_panel";
      if (document.title !== 'Add Number - Phone.com') {
        document.title = 'Add Number - Phone.com';
      }
    }

    if (props.mode === "tollfree_nonvanity") {
      initial_query.filter_by.local = false;
      initial_query.filter_by.toll_free = true;
      initial_query.filter_by.vanity = false;
    }
    else if (props.mode === "local_nonvanity") {
      initial_query.filter_by.local = true;
      initial_query.filter_by.toll_free = false;
      initial_query.filter_by.vanity = false;
    } else if (props.mode === "vanity") {
      initial_query.filter_by.local = false;
      initial_query.filter_by.toll_free = false;
      initial_query.filter_by.vanity = true;
    }

    this.initialCities = [
      {"city":"Abbeville","region":"LA","area_code":[337]},
      {"city":"Abbeville","region":"SC","area_code":[864]},
      {"city":"Aberdeen","region":"ID","area_code":[208]}
    ];
    this.initialAreaCodes = []

    this.initial_query = initial_query;
    this.state = {
      query: JSON.parse(JSON.stringify(initial_query)),
      results: [],
      reservedNumbers: [],
      cities: this.initialCities,
      filteredCities:[],
      all_area_codes:[],
      area_codes: [],
      filtered_area_codes: this.initialAreaCodes,
      priceSlider: [49, 1000],
      price: 'Any Price',
      loading: true,
      loadingMessage: null,
      showGuide: "guide show",
      guideText: null,
      apiCall: null,
      session: "",
      errors: "",
      sessionReserved: "",
      reset: Date.now(),
      resetNpaFilter: Date.now(),
      resetCityFilter: Date.now(),
      mode: props.mode,
      city_filter_loading: false,
      showModal: false,
      pricingSummary:{},
      reservePop: RESERVE_POP_STATES.OFF,
      newestReservation: null,
      purchasing: false,
      removingNumber: false,
      purchaseProgress: 0
    };

  }

  initialLoad = async () => {
      // let res = await initializePhoneCom()
      let res = window.V5PHONECOM;
      this.setState({
          // loading: false,
          userInfo: res,
      });
  }

  
  // Get Numbers - API Call
  APIcall = async (queries) => {
    let apiCall = this.state.apiCall;
    if (apiCall !== null) {
      apiCall.cancel();  // Multiple requests can be cancelled with same cancel token
      this.setState({
        apiCall: null
      });
    }
    let cancelToken = axios.CancelToken.source();
    this.setState({
      apiCall: cancelToken
    });

    try {
      let response = await api.searchAvailableNumbers(queries, cancelToken, this.props.source === "control_panel");
      if (this.state.reservedNumbers.length == 0 && window.APP_CONFIG.is_vanity_search){
        response.showGuide = "guide show"
      }
      this.setState({guideText: null, ...response});
    } catch (error) {
      this.setState({guideText: null, ...error});
    }
  }

  // Reformat a single query into a list of one or more queries that correspond
  // to the payload accepted by the search-available-numbers endpoint
  reformatQuery = (rawQuery) => {
    let area_codes = rawQuery.filter_by.area_code;
    let cities = rawQuery.filter_by.city;
    if (cities && area_codes) {
      // Intersection
      area_codes = cities.filter(x => area_codes.indexOf(x.toString()) !== -1)
      if (!area_codes.length) return null; 
    } else if (cities) {
      area_codes = cities;
    }
    let reformattedQueries = [];
    if (area_codes && area_codes.constructor === Array) {
      if (area_codes.length > 0) {
        // Make separate queries for each area code
        reformattedQueries = area_codes.map(
          npa => {
            let areaCodeQuery = {
              ...rawQuery,
              filter_by: {
                ...rawQuery.filter_by,
                area_code: npa  // We're only updating rawQuery.filter_by.area_code
              }
            }
            delete areaCodeQuery.filter_by.city;
            return areaCodeQuery
          }
        )
      }
      else {
        // No area codes, so delete the area_code property from filter_by
        reformattedQueries = [{...rawQuery}]
        delete reformattedQueries[0]["filter_by"]["city"];
        delete reformattedQueries[0]["filter_by"]["area_code"];
      }
    }

    if (!reformattedQueries.length) {
      reformattedQueries = [{...rawQuery}];
    }

    return reformattedQueries
  };


  // Compare Filters
  isEquivalent = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  // Update Filter
  updateFilter = (query) => {
    let current_query = this.state.query;
    let new_query;

    if (query.filter_by.contains === "none") {
      let clone = Object.assign({}, query);
      delete clone.filter_by["contains"];
      new_query = clone;
    } else {
      new_query = query;
    }
    if (query.filter_by.area_code === "none") {
      let clone = Object.assign({}, query);
      delete clone.filter_by["area_code"];
      new_query = clone;
    } else {
      new_query = query;
    }
    if (query.filter_by.city === "none") {
      let clone = Object.assign({}, query);
      delete clone.filter_by["city"];
      new_query = clone;
    } else {
      new_query = query;
    }
    if (query.filter_by.state === "none") {
      let clone = Object.assign({}, query);
      delete clone.filter_by["state"];
      new_query = clone;
    } else {
      new_query = query;
    }
    if (query.filter_by.is_like === "+1..........") {
      let clone2 = Object.assign({}, new_query);
      delete clone2.filter_by["is_like"];
      new_query = clone2;
    } else {
      new_query = query;
    }
    // if (query.filter_by.toll_free === false) {
    //   let clone3 = Object.assign({}, new_query);
    //   delete clone3.filter_by["toll_free"];
    //   new_query = clone3;
    // } else if (query.filter_by.local === false) {
    //   let clone4 = Object.assign({}, new_query);
    //   delete clone4.filter_by["local"];
    //   new_query = clone4;
    //   console.log("no local");
    // }

    const reformattedQueries = this.reformatQuery(query);
    if (!reformattedQueries) {
      this.setState({
        numbers: [],
        results: [],
        total: 0,
        loading: false
      });
      return;
    }
    this.setState(
      {
        loading: true,
        loadingMessage: '',
        reservePop: RESERVE_POP_STATES.OFF,
        showGuide: "guide show",
        guideText: "",
        results: [],
        query: new_query,
        errors: ""
      },
      () => {
        console.log("Filter updated.");
      }
    );
    this.APIcall(reformattedQueries);
  };

  // Search by one or more NPAs, including toll-free NPAs
  multipleNpaSearch = (npas) => {
    let modelQuery = this.state.query;

    let queryCopy = JSON.parse(JSON.stringify(modelQuery));
    if (npas.length < 1){
      queryCopy.filter_by.area_code = "none";

    }
    else{
      queryCopy.filter_by.area_code = npas;
    }
    if (npas.length == 1) {
      this.CitySearch('', npas[0])
    }
    
    this.setState({filteredCities: JSON.parse(JSON.stringify(this.state.cities))})
    this.updateFilter(queryCopy);
  };

  // Search by one or more cities
  multipleCitiesSearch = (cities, npas) => {
    let modelQuery = this.state.query;
    // if (cities.length === 0) {
    //   modelQuery = this.initial_query;
    // }
    // else {
    //   modelQuery = this.state.query;
    // }

    let queryCopy = JSON.parse(JSON.stringify(modelQuery));

    //TODO curently state search only works for ONE city/state, 
    // need to update search-available-numbers endpoint to add multiple search feature
    let cityName = 'none'
    let stateName = 'none'
    let areaCode = 'none'
    cities.forEach((value, index) => {
      value = value.split(', ')
      cityName = value[0]
      stateName = value[1]
      areaCode = npas
      // this.setState({filtered_area_codes: npas[0]})
      
    });
    this.setState({filteredCities: JSON.parse(JSON.stringify(this.state.cities))})
    queryCopy.filter_by.city = areaCode;
    // queryCopy.filter_by.state = stateName

    // if (cities.length === 0){
    //   this.setState({filtered_area_codes: this.initialAreaCodes})
    // }

    this.updateFilter(queryCopy);
  };


  // Keyword Search Input
  keywordSearch = (keyword) => {
    keyword = keyword.toUpperCase();
    if (keyword === "") {
      keyword = "none";
    }
    let query = {
      ...this.state.query,
      filter_by: {
        ...this.state.query.filter_by,
        contains: keyword
      }
    };
    this.updateFilter(query);
  };

  // Get Area Codes
  getAreaCodes = () => {
    axios
      .post("https://services.phone.com/numbers/search-area-codes")
      .then(response => {
        let response_data;
        if (response.data) {
          if (tollfreeParam === "true") {
            response_data = response.data.tollfree;
          } else {
            response_data = response.data.local;
          }
        } else {
          response_data = [];
        }
        this.setState(
          {
            area_codes: response_data,
            // filtered_area_codes: response_data
          },
          () => {
            console.log("Successfully retrieved area codes.");
          }
        );
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log("call was canceled");
        } else {
        }
      });
  };

  invalidAreaCodeMessage = (show) => {

    if(show){
      this.setState(
        {
          showGuide: "guide show",
          guideText: "I'm not familiar with that area code",
        })
    }
    else if(this.state.guideText == "I'm not familiar with that area code"){
      this.setState(
        {
          showGuide: "guide hidden",
          // guideText: "Please select a number",
        })
    }

  }
  


  // Area Code Search
  AreaCodeSearch = (code) => {


    if (code in ['800', '833', '844', '855', '866', '877', '888']){
      this.setState(
        {
          loading: false,
          results: [],
          errors: "Are you looking for a toll free number?",
          showGuide: "guide hidden",
          guideText: null
        },
        () => {
          console.log("User searched toll free");
        }
      );
    } else if (code === "") {
      let areacode = "none";
      let query = {
        ...this.state.query,
        filter_by: {
          ...this.state.query.filter_by,
          area_code: areacode
        }
      };
      this.setState(
        {
          filtered_area_codes: this.state.area_codes
        },
        () => {
          console.log("Searching all numbers.");
        }
      );
    } else {
      let area_codes = this.state.area_codes;
      let results = area_codes.filter(item => {
        return item.toString().startsWith(code);
      });
      this.setState(
        {
          filtered_area_codes: results
        },
        () => {
          console.log("Successfully retrieved area codes.");
        }
      );
    }
  };

  // City Search
  CitySearch = (city, npa) => {

    console.log('city or region search', city)
    if (city.length == 0){
      this.setState({cities: this.initialCities, city_filter_loading: false})
      return;
    }

    // split city value into region/city
    let cityState = ''
    if (city.includes(',')){
      let citySearchArray   = city.split(',')
      let leftSideOfComma   = citySearchArray[0].trim()
      let rightSideOfComma  = citySearchArray[1].trim()
      city                  = leftSideOfComma
      if (leftSideOfComma.length === 2 && rightSideOfComma.length > 2){
        cityState = leftSideOfComma.toUpperCase()
        city      = rightSideOfComma
      }
      if (leftSideOfComma.length === 2 && rightSideOfComma.length === 1){
        cityState = leftSideOfComma.toUpperCase()
        city      = ''
      }
      else if (rightSideOfComma.length == 2){
        cityState = rightSideOfComma.toUpperCase()
      }
    }else if (city.trim().length === 2){
      cityState = city.trim().toUpperCase()
      city = ''
    }
  

    let cancelToken = axios.CancelToken.source();
  
    let filterBy = {}
    if (city){
      filterBy['city'] = city
    }
    if (npa){
      filterBy['area_code'] = npa
    }
    if (cityState){
      filterBy['region'] = cityState
    }



    this.setState({ city_filter_loading: true }, () => {
      axios
        .post(
          "https://services.phone.com/numbers/search-regions",
          JSON.stringify({
            filter_by: filterBy
          }),
          { cancelToken: cancelToken.token }
        )
        .then(response => {
          let response_data;
          if (response.data) {
            response_data = response.data;
            if ('error' in response_data){
              response_data = []
            }
          } else {
            response_data = [];
          }

          let city_combined = {}
          response_data.forEach((value, index) => {

            let key = `${value.city}_${value.region}`
            value.area_code = [value.area_code]
            if(!city_combined[key]){
              city_combined[key] = value
            }
            else{
              city_combined[key].area_code.push(...value.area_code)
            }

          });

          let formated_response = Object.values(city_combined)

          this.setState(
            {
              cities: formated_response,
              city_filter_loading: false

            },
            () => {
              console.log("Successfully retrieved cities.", formated_response);
            }
          );
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log("call was canceled");
          } else {
          }
        });

      });
    };
 
  // City Number Search
  CityNumberSearch = (city) => {
    if (city === "") {
      city = "none";
    }
    let query = {
      ...this.state.query,
      filter_by: {
        ...this.state.query.filter_by,
        area_code: city
      }
    };
    this.updateFilter(query);
  };

  // Position Search
  searchPosition = (is_like) => {
    let query = {
      ...this.state.query,
      filter_by: {
        ...this.state.query.filter_by,
        is_like: is_like
      }
    };
    this.updateFilter(query);
  };

  // Toll Free Checkboxes
  handleCheckbox = (event) => {
    let value = event.target.checked;
    let name = event.target.name;
    let query = {
      ...this.state.query,
      filter_by: {
        ...this.state.query.filter_by,
        [name]: value
      }
    };
    this.updateFilter(query);
  };

  // Price Checkbox
  handlePrice = (event) => {
    this.setState({ price: event.price });
    const priceMin = event.value[0];
    const priceMax1 = event.value[1];
    let priceMax;
    if (priceMax1 === 1000) {
      priceMax = 500000;
    } else {
      priceMax = priceMax1;
    }
    let query = {
      ...this.state.query,
      filter_by: {
        ...this.state.query.filter_by,
        price_min: priceMin,
        price_max: priceMax
      }
    };
    this.updateFilter(query);
  };

  // Sort Dropdown
  handleSelect = (event) => {
    let value = event.target.value;
    let query = {
      ...this.state.query,
      order_by: value
    };
    this.updateFilter(query);
  };

  // Price Slider
  handleSlider = (event) => {
    this.setState({ priceSlider: event });
    const priceMin = event[0];
    const priceMax1 = event[1];
    let priceMax;
    if (priceMax1 === 1000) {
      priceMax = 500000;
    } else {
      priceMax = priceMax1;
    }
    let query = {
      ...this.state.query,
      filter_by: {
        ...this.state.query.filter_by,
        price_min: priceMin,
        price_max: priceMax
      }
    };
    this.updateFilter(query);
  };


  // Convert E164 to PDC
  E164toPDC = (phoneNumber) => {
    return (
      '(' +
      phoneNumber.substring(2, 5) +
      ") " +
      phoneNumber.substring(5, 8) +
      "-" +
      phoneNumber.substring(8)
    );
  };

  // Convert PDC to E164
  PDCtoE164 = (phoneNumber) => {
    return "+1" + phoneNumber.replace(/-/g, "");
  };

  // Convert Alphas to Numberes
  vanityToNumeric = (phoneNumber) => {
    let phoneNumber_final = "";
    let substitutions = [
      { numeric: "2", alphas: ["A", "B", "C"] },
      { numeric: "3", alphas: ["D", "E", "F"] },
      { numeric: "4", alphas: ["G", "H", "I"] },
      { numeric: "5", alphas: ["J", "K", "L"] },
      { numeric: "6", alphas: ["M", "N", "O"] },
      { numeric: "7", alphas: ["P", "Q", "R", "S"] },
      { numeric: "8", alphas: ["T", "U", "V"] },
      { numeric: "9", alphas: ["W", "X", "Y", "Z"] }
    ];
    substitutions.forEach(function(substitution, index) {
      let number = substitution.numeric;
      substitution.alphas.forEach(function(alpha, index) {
        phoneNumber_final = phoneNumber.replace(new RegExp(alpha, "g"), number);
      });
    });
    return phoneNumber_final;
  };

  // Format Numbers with Alpha
  prettyFormat = (number) => {
    let this_number = number;
    this_number.formatted = this.E164toPDC(this_number.phone_number);
    this_number.pretty_formatted_mask = this.E164toPDC(this_number.format_mask);
    this_number.pretty_formatted = "";
    let alpha_list = ['.', '-', '(', ')', ' '];
    for (var c = 0; c < this_number.formatted.length; c++) {
      if (alpha_list.indexOf(this_number.pretty_formatted_mask.charAt(c)) === -1) {
        this_number.pretty_formatted =
          this_number.pretty_formatted +
          "<span class='alpha'>" +
          this_number.pretty_formatted_mask.charAt(c) +
          "</span>";
      } else {
        this_number.pretty_formatted =
          this_number.pretty_formatted + this_number.formatted.charAt(c);
      }
    }
    return this_number.pretty_formatted;
  };

  // Move Numbers to Selected Numbers Section
  moveNumber = (number, cartItem=false) => {
    if (this.state.reservedNumbers.length >= 10) {
      this.setState(
        {
          showGuide: "guide show",
          guideText: "You may only select ten numbers at a time."
        },
        () => {
          console.log(this.state.guideText);
        }
      );
    } else {
      var joined = this.state.reservedNumbers.concat(number);
      this.setState({ reservedNumbers: joined }, () => {
      });
      var array = [...this.state.results];
      var index = array.indexOf(number);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ results: array });
        if(!cartItem && this.props.source === "control_panel"){
          this.addNumberToCart(number)
        }
      }
    }

  };

  // Remove Selected Number
  removeNumber = async (num_to_be_removed, inCart=true) => {
    this.setState(
      {reservedNumbers: this.state.reservedNumbers.filter(
          num => num.phone_number !== num_to_be_removed.phone_number
      )}
    );

    if (this.props.source === "control_panel" && inCart == true){
      await this.removeNumberFromCart(num_to_be_removed)
    }
  };

  removeNumberAndReprice = async (removedNumber) => {
    let closePricingPage = false;
    if (this.state.reservedNumbers.length === 1)
      closePricingPage = true;

    await this.removeNumber(removedNumber, true);

    if (closePricingPage) {
      this.setState({showModal: false})
      this.props.toggleHeaders(true)
    } else {
      let summaryResponse = await api.getPricingSummary();
      this.setState(summaryResponse);
    }
  }

  // For use in Control Panel
  reservePhoneNumbersCP = (numbers) => {
    console.log('In reservePhoneNumbersCP...');
    let prettyNumbers = numbers.map(num => num.phone_number);
    this.setState(
      {
        showGuide: "guide show",
        // guideText: "TEMP: This is where we'd add numbers to cart: \n" + prettyNumbers,
        errors: ""
      },
      () => {
        console.log(this.state.guideText);
      }
    );
  };

  onNumberProcessed = (eid, payload) => {
    console.log('Number processed')
    console.log(payload)
    this.setState({purchaseProgress: payload.sequence})

    if (payload.sequence >= payload.total) {
      this.props.switchTab('my-numbers')
    }

  }

  purchasePhoneNumbers = async (phoneNumbers) => {

    if (!PDCOpenConnection.accountSubscriptions.includes('number-purchase')) {
      PDCOpenConnection.onAccount('number-purchase', this.onNumberProcessed)
    }

    this.setState({
      showGuide: "guide show",
      loading: true,
      reservePop: RESERVE_POP_STATES.OFF,
      loadingMessage: 'Hold on while we checkout your cart',
      purchasing: true,
      errors: null
      // guideText: "Purchasing number(s)..."
    })
    this.props.toggleHeaders(false)

    let successfulPurchases = []
    let failedPurchases = []
    let response = await api.purchaseCart()
    this.setState(response)

    if('purchaseResponse' in response && response.purchaseResponse.success == true){
      let purchaseResponse = response.purchaseResponse
      purchaseResponse.purchased_dids.forEach((purchased_did, index) => {
        successfulPurchases.push(purchased_did.did)
      });
    }
    else {
      return;
    }

    phoneNumbers.forEach((phoneNumber, index) => {
      if (!successfulPurchases.includes(phoneNumber.did)){
        failedPurchases.push(phoneNumber.did)
      }
    })
    setTimeout(() => { 
      this.setState({
      showGuide: "guide show",
      // guideText: "Success!"
      loading: false,
      loadingMessage: null
    }) }, 3000);

    // let urlObject = {'s': successfulPurchases, 'f': failedPurchases}
    // ';purchased_numbers=' + encodeURIComponent(JSON.stringify(urlObject))
    this.setState({
      did_cart: null,
      purchasing: true,
    })
    // this.props.toggleHeaders(true)
    // this.props.switchTab('my-numbers')
  }


  // BEGIN CART FUNCTIONS
  addNumberToCart = async (phoneNumber) => {
    this.setState({
      guideText: null,
      loading: true,
      reservePop: RESERVE_POP_STATES.OFF,
      loadingMessage: 'One moment while we reserve your new number...',
      errors: null
    })
    let response = await api.addNumberToCart(phoneNumber, this.props.mode == 'vanity')
    this.setState(response)
    if ('errors' in response){
      this.removeNumber(phoneNumber, false)
      return;
    }
    this.setState({
      loading: false,
      reservePop: RESERVE_POP_STATES.ON,
      newestReservation: phoneNumber.pretty_formatted,
      loadingMessage: ''
    })
    setTimeout(() => {
      this.setState({reservePop: RESERVE_POP_STATES.FADE_OUT})
      setTimeout(()=>{
        this.setState({
          reservePop: RESERVE_POP_STATES.OFF, 
          newestReservation: null
        })
      }, 1000)
    }, 2500);
  }

  removeNumberFromCart = async (phoneNumber) => {
    this.setState({
      'loading': true,
      showGuide: "guide show",
      guideText: null,
      removingNumber: true,
      errors: null
    });
    let response = await api.removeNumberFromCart(phoneNumber);
    if(!('errors' in response)){
      this.setState(response)
    }
    this.setState({removingNumber: false});
  }

  getCart = async () => {

    let response = await api.getCart()
    if ('errors' in response){
      this.setState(response)
      return
    }

    response.data.did_cart.did_cart_items.forEach((cart_item, index) => {
      cart_item.phone_number = cart_item.did
      cart_item.format_mask = '............'
      cart_item.vendor_id = cart_item.voip_did_vendor_id
      this.prettyFormat(cart_item)
      this.moveNumber(cart_item, true)

    });

      // this.setState({reservedNumbers: cartNumbers})   

  }
  // END CART FUNCTIONS

  toggleSummaryModal = async () => {

    if (this.state.showModal == false){
      this.setState({
        loading: true,
        showGuide: "guide show",
        errors: null
        // guideText: "Calculating pricing summary..."
      });
      let summaryResponse = await api.getPricingSummary()
      this.setState(summaryResponse)
      if (!('errors'  in summaryResponse)){
        this.props.toggleHeaders(false)
      }
    }
    else{
      this.setState({showModal: false})
      this.props.toggleHeaders(true)
    }
  }

  handleReset = () => {
    this.setState({"reset": Date.now(), cities:this.initialCities});
    this.updateFilter(this.initial_query);
  };

  resetCityFilter = () => {
    this.setState({"resetCityFilter": Date.now(), cities:this.initialCities});
  }

  resetNpaFilter = () => {
    let area_codes = this.state.query.filter_by.city ? this.state.filtered_area_codes : this.initialAreaCodes
    this.setState({"resetNpaFilter": Date.now(), filtered_area_codes: area_codes});

  }

  resetNumberTypeFilter = () => {
    let query = {
      ...this.state.query,
      filter_by: {
        ...this.state.query.filter_by,
        ['local']: false,
        ['toll_free']: false

      }
    };
    this.updateFilter(query);
  }

  resetErrors = () => {
    this.setState({
      loading: true,
      errors: ''
    });
    const reformattedQueries = this.reformatQuery(this.state.query);
    if (!reformattedQueries) {
      this.setState({
        results: [],
        numbers: [],
        total: 0,
        loading: false
      });
      return;
    }
    this.APIcall(reformattedQueries);
  }


  // On Load
  componentDidMount = async () => {
    await this.initialLoad();
    this.APIcall([this.state.query]);
    this.getAreaCodes();
    this.getCart();
  }

  componentWillUnmount () {
    PDCOpenConnection.removeCallback('number-purchase', this.onNumberProcessed)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading !== this.state.loading) this.props.setLoading(this.state.loading)
  }
  
  // Render
  render() {
    var isIE = /*@cc_on!@*/false || !!document.documentMode;  
    let renderApp = 'There was an error loading the requested application'
    if (isIE){
      renderApp =  <BrowserNotSupported browser={"ie"}/>
    }
    else {
       renderApp =  <>
        <div className={`pc-ns ${isMobile ? 'mobile' : ''}`}>
                      <NumberSearch
                        initialFilterBy={this.initial_query.filter_by}
                        filterBy={this.state.query.filter_by}
                        showPricing={this.state.showModal}
                        is_free={freeParam}
                        is_tollfree={tollfreeParam}
                        is_local={localParam}
                        AreaCodeSearch={this.AreaCodeSearch}
                        CitySearch={this.CitySearch}
                        CityNumberSearch={this.CityNumberSearch}
                        cities={this.state.cities}
                        filteredCities={this.state.filteredCities}
                        resetFilteredCities={this.resetFilteredCities}
                        all_area_codes={this.state.area_codes}
                        area_codes={this.state.filtered_area_codes}
                        keywordSearch={this.keywordSearch}
                        handleCheckbox={this.handleCheckbox}
                        handleSelect={this.handleSelect}
                        sortBy={this.state.sort_by}
                        local={this.state.query.filter_by.local}
                        tollfree={this.state.query.filter_by.toll_free}
                        priceSlider={this.state.priceSlider}
                        price={this.state.price}
                        handleSlider={this.handleSlider}
                        handlePrice={this.handlePrice}
                        resultData={this.state.loading == false ? this.state.results: []}
                        //alpha={this.state.query.filter_by.contains}
                        prettyFormat={this.prettyFormat}
                        E164toPDC={this.E164toPDC}
                        searchPosition={this.searchPosition}
                        handleNpaFilterUpdate={this.multipleNpaSearch}
                        handleCitiesFilterUpdate={this.multipleCitiesSearch}
                        moveNumber={this.moveNumber}
                        removeNumber={this.removeNumber}
                        reservedNumbers={this.state.reservedNumbers}
                        loading={this.state.loading}
                        loadingMessage={this.state.loadingMessage}
                        reservePop={this.state.reservePop}
                        newestReservation={this.state.newestReservation}
                        city_filter_loading={this.state.city_filter_loading}
                        showGuide={this.state.showGuide}
                        guideText={this.state.guideText}
                        reservePhoneNumber={this.reservePhoneNumbersCP}
                        toggleSummaryModal={this.toggleSummaryModal}
                        purchasePhoneNumbers={this.purchasePhoneNumbers}
                        errors={this.state.errors}
                        handleReset={this.handleReset}
                        reset={this.state.reset}
                        resetErrors={this.resetErrors}
                        handleResetNpa={this.resetNpaFilter}
                        resetNpa={this.state.resetNpaFilter}
                        handleResetCity={this.resetCityFilter}
                        resetCity={this.state.resetCityFilter}
                        handleResetNumberTypeFilter={this.resetNumberTypeFilter}
                        invalidAreaCodeMessage = {this.invalidAreaCodeMessage}
                        mode={this.state.mode}
                        removeNumberAndReprice={this.removeNumberAndReprice}
                        removingNumber={this.state.removingNumber}
                        purchasing={this.state.purchasing}
                        pricingSummary={this.state.pricingSummary}
                        purchaseProgress={this.state.purchaseProgress}
                        isMobile={isMobile}
                      />
                  </div>
                  <div className={`${false ? 'actions-row-mobile' : 'actions-row'}`}>
                    <Actions
                      showPricingPage={this.state.showModal}
                      loading={this.state.loading}
                      showGuide={true}
                      guideText={this.state.guideText}
                      reservedNumbers={this.state.reservedNumbers}
                      toggleSummaryModal={this.toggleSummaryModal}
                      purchasePhoneNumbers={this.purchasePhoneNumbers}
                      purchasing={this.state.purchasing}
                    />
                  </div>
              </>
    }

    return (
      renderApp
    );
  }
}

// Render Number Search App
// ReactDOM.render(<NumberSearchApp />, document.getElementById("number_search"));

export default NumberSearchApp;