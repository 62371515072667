import { mainTheme } from 'themes'

/**
 * @param {object} theme
 */
const styles = theme => ({
    audioControlContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        minHeight: 30,
        '&.fit-content': {
            width: 'fit-content'
        }
    },
    progressAndTimingWrapper: {
        width: '70%',
        marginLeft: 10,
        marginBottom: 5
    },
    audioControlSeekbar: {
        cursor: 'pointer',
        height: 9,
        borderRadius: 6,
        position: 'relative',
        display: 'flex',
        background: theme.palette.primary.borderColor,
        '& .progress-bar': {
            background: theme.palette.primary.main,
            borderRadius: 6,
            position: 'relative',
            overflow: 'unset',
            height: '100%',
            width: 0,
            paddingBottom: 1,
            transition: 'background .1s',
            '& .player-control': {
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                opacity: 0,
                zIndex: 1,
                width: '40px !important',
                '&.force-show': {
                    opacity: 1,
                    '& svg': {
                        width: 40
                    }
                },
                '& svg': {
                    color: theme.palette.primary.dark,
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    display: 'block',
                    userSelect: 'none',
                    userDrag: 'none',
                    width: 0,
                    transition: 'width .1s',
                    fontSize: 30
                }
            }
        },
        '&:hover .progress-bar': {
            '& .player-control': {
                display: 'block',
                width: 'fit-content',
                opacity: 1,
                '& svg': {
                    width: 40
                }
            }
        },
        '& .temp-progress-bar': {
            background: 'white',
            opacity: 0.35,
            borderRadius: 6,
            position: 'absolute',
            left: 0,
            top: 0,
            height: 9,
            width: 0
        }
    },
    timeInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 15,
        '& span': {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.33,
            color: theme.palette.secondary.textDark
        }
    },
    seekBarErrorBorder: {
        border: theme ? theme.palette.error.flatBorder : mainTheme.palette.error.flatBorder
    },
    controlButtonWrapper: {
        cursor: 'pointer'
    },
    actionButton: {
        padding: 8,
        backgroundColor: 'white',
        borderRadius: '50%',
        minWidth: 62,
        minHeight: 62,
        width: 62,
        height: 62,
        cursor: 'pointer',
        transition: 'background .5s',
        '&:not(.loading):hover': {
            backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.background}, ${theme.palette.paper.green})`,
            '& > div': {
                backgroundColor: `${theme.palette.primary.main}61`,
                '& > svg': {
                    color: theme.palette.info.textDark
                }
            }
        },
        '&:not(.loading):active': {
            backgroundImage: `linear-gradient(180deg, #B1D7A9, ${theme.palette.primary.background})`
        },
        '&.small': {
            width: 40,
            height: 40,
            minWidth: 40,
            minHeight: 40,
            padding: 3,
            '& > div': {
                '& > svg': {
                    fontSize: 22
                }
            }
        },
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.background,
            transition: 'background .5s',
            '& > svg': {
                color: theme.palette.info.dark,
                fontSize: 26,
                transition: 'color .5s'
            }
        },
        '&.loading': {
            cursor: 'default',
            '& > div': {
                backgroundColor: 'initial'
            }
        }
    },
    controlButtonImg: {
        maxHeight: 50,
        maxWidth: 50,
        height: '100%',
        width: '100%'
    },
    downloadStyle: {
        marginLeft: 5,
        marginRight: 5
    },
    labelWrapper: {
        marginBottom: 8,
        '& > p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    }
})

export default styles
