<template>
    <DefaultModal
        v-bind="$attrs"
        v-on="$listeners"
        width="350"
        @click:outside="$emit('closed')"
        @keydown.esc="$emit('closed')"
        :with_x="false"
        data-test-id="discard-changes-modal"
    >
        <template v-slot:title>{{ title || l.t('app.are-you-sure-you-want-to-leave-this-page', 'Are you sure you want to leave this page?')}}</template>
        {{ text || l.t('app.you-have-unsaved-changes', 'You have unsaved changes that will be lost.')}}
        <template v-slot:buttons>
            <w-btn @click="$emit('closed')" color="grey" class="white--text"
                data-test-id="discard-changes-modal-cancel-btn">
                {{ l.t('app.cancel', 'Cancel') }}
            </w-btn>
            <w-btn @click="$emit('discard')" class="white--text" color="error"
                data-test-id="discard-changes-modal-btn">
                {{ l.t('app.discard-changes', 'Discard changes') }}
            </w-btn>
        </template>
    </DefaultModal>
</template>
<script>
import l from '../../../libs/lang';
import DefaultModal from './DefaultModal.vue';

export default {
    props: {
        title:{
            type: String,
        },
        text: {
            type: String,
        }

    },
    components: {DefaultModal},
    data() {
        return {
            l,
        };
    },
  };
</script>
