export function setUsers(users) {
	return {
		type: 'SET_USERS',
		users
	}
}
export function updateUser(user) {
	return {
		type: 'UPDATE_USER',
		user
	}
}
