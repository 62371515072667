// React
import React from "react";

// Components
import SearchResult from "./search-result/search-result";
import MessageLoader from './loader/message-loader';
import ErrorPage from './error-page/error-page';
import ReservationNotification from './reservation-notification/reservation-notification';

// Stylesheet
import "./search-results.css";

const NoResults = () => {
  return (
    <div className='no-results'>
      <div className='no-results-header'>No results found.</div>
      <div className='results-message'>
        We currently don’t have any numbers that match your search. Try removing some filters to return better results.
      </div>
    </div>
  );
}

// Search Results List
class SearchResults extends React.Component {

  render() {
    let hideReserved = (this.props.reservedNumbers.length > 4 && this.props.mode == 'vanity');
    let reservePop = this.props.reservePop;
    let loading = this.props.loading;
    let loadingMessage = this.props.loadingMessage;
    let errorMessage = this.props.errors;
    let hasResults = this.props.resultData && this.props.resultData.length > 0 && !errorMessage;
    let noResults = !hasResults && !errorMessage && !loading;
    let content = <MessageLoader data-test-id='message-loader' message={loadingMessage}/>;

    if (loading) {
      content = <MessageLoader data-test-id='message-loader' message={loadingMessage}/>;
    } else if (reservePop) {
      content = <ReservationNotification visibilityStatus={reservePop} number={this.props.newestReservation}/>;
    } else if (errorMessage) {
      content = <ErrorPage resetErrors={this.props.resetErrors} error={errorMessage}/>;
    } else if (noResults) {
      content = <NoResults/>;
    } else if (hasResults) {
        content = <SearchResult
          errors={this.props.errors}
          loading={this.props.loading}
          reservePop={this.props.reservePop}
          resultData={this.props.resultData}
          alpha={this.props.alpha}
          prettyFormat={this.props.prettyFormat.bind(this)}
          E164toPDC={this.props.E164toPDC.bind(this)}
          moveNumber={this.props.moveNumber.bind(this)}
        />;
    }
    return (
      <div className="results-wrapper">
        <div className={`search-results ${this.props.mode == 'vanity' ? 'short-results' : ''}`} style={{overflow: (loading || reservePop) ? 'hidden' : 'scroll'}}>
          {content}
        </div>
      </div>
    );
  }
}

export default SearchResults;
