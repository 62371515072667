<template>
  <w-autocomplete
    v-if="type"
    v-bind="$attrs"
    v-on="$listeners"
    name="coutry-selector"
    id="country-selector"
    :disabled="!!disabled"
    :items="countries"
    :placeholder="placeholder"
    :label="computed_label"
    update:search-input
    autocomplete="new-password"
    hide-details="auto"
    data-test-id="country-selector"
  >
  </w-autocomplete>
</template>

<script>
  import l from '../../../../libs/lang';
  import Countries from 'countries';

  export default {
    props: ['_type', 'disabled', 'additional_value', 'available_countries', 'label', 'with_label'],
    data() {
      return {
        l,
        type: null,
        search: false,
        searchTerm: null,
        filteredCountries: [],
        filtersApplied: false,
        countries: [],
        placeholder: this.$lang.t('app.country', 'Country')
      };
    },
    created() {
      if (Array.isArray(this.$props.available_countries)) {
        this.$data.countries = Countries.filter((x) => this.$props.available_countries.includes(x.alpha2));
      } else {
        this.$data.countries = Countries;
      }
      this.$data.type = this.$props._type || 'alpha2';
      // eslint-disable-next-line arrow-body-style
      this.$data.countries = this.$data.countries.map((x) => {
        return {
          value: x[this.$data.type],
          text: x.name,
        };
      });
      if (this.$props.additional_value && !this.$data.countries.find((x) => x.value === this.$props.additional_value)) {
        this.$data.countries.unshift({value: this.$props.additional_value, text: this.$props.additional_value});
      }
    },
    computed: {
      computed_label() {
        if (!this.$props.with_label) return undefined;
        if (this.$props.label) return this.$props.label;
        return this.$data.placeholder;
      }
    }
  };
</script>
