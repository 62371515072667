<template>
  <v-autocomplete
    v-bind="$attrs"
    color="primary"
    v-on="$listeners"
    @input="input"
    ref="autocomplete"
    :no-data-text="l.t('app.no-data-available', 'No data available')"
    :append-icon="$attrs.disabled ? '' : '$vuetify.icons.dropdown_arrow_black'"
    class="custom-autocomplete"
    hide-details="auto"
  >
  <!-- Pass on all named slots -->
  <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  <!-- Pass on all scoped slots -->
  <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
</v-autocomplete>
</template>
<script>
import EventBus from 'event-bus/EventBus';
import l from 'lang';

export default {
  data() {
    return {
      l,
    };
  },
  methods: {
    input(e) {
      if(!this.$refs.autocomplete.$el.closest('.main-extension-selector')) {
        EventBus.$emit('form_input_changed');
      }
      this.$emit('input', e);
    }
  }
};
</script>
<style lang="scss">
  .custom-autocomplete {
    .v-input__icon--append {
      margin-right: 9px;
    }
  }
</style>