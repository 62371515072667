
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import { SwipeableDrawer, ListItem, List, ListItemIcon, ListItemText, Chip } from '@material-ui/core'
import { XIcon, ChevronRight, NewTabTargetIcon } from 'pdc-svg-icons'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography/Typography'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import AppBar from '@material-ui/core/AppBar/AppBar'

const useStyles = makeStyles(styles)

const isASencondLevelView = () => {
    const pathNameSplit = window.location.pathname.split('/')
    let isASencondLevelView = false
    let depthLevel = 4
    if (window.location.pathname.includes('fax/sent') || window.location.pathname.includes('fax/received')) {
        depthLevel = 5
    }
    if (window.location.pathname.includes('/people')) {
        depthLevel = 5
    }

    if (window.location.pathname.includes('/personal-settings')) {
        return false
    }
    if (window.location.pathname.includes('/voicemail')) {
        return false
    }

    isASencondLevelView = pathNameSplit.length > depthLevel - 1 && !(pathNameSplit.length === depthLevel && !pathNameSplit[depthLevel - 1])
    return isASencondLevelView
}

/**
 * @param props
 */
export default function PDCAppBar (props) {
    const classes = useStyles()

    const { isMobileView, isTabletView } = props.screenViewType
    const isSecondLevelView = process.env.REACT_APP_HIDE_APP_BAR_IN_2ND_LEVEL && isASencondLevelView()
    if ((!isMobileView && !isTabletView) || (isMobileView && isSecondLevelView)) return null
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar classes={{ root: classes.toolbar }}>
                    <IconButton
                        color='inherit'
                        aria-label='Open drawer'
                        edge='start'
                        onClick={props.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h6' noWrap>
                        {props.appBarLabel}
                    </Typography>
                    {/* {this.props.showAvatar */}
                    {/* ? */}
                    {/* <div data-navbar='avatar' onClick={()=>this.setState({openStatusDrawer: true})} className={classes.avatarWrapper}> */}
                    {/* <AvatarIcon */}
                    {/* imageUrl     = {window.V5PHONECOM.avatar_url ? window.V5PHONECOM.avatar_url : null} */}
                    {/* height          = {35} */}
                    {/* width          = {35} */}
                    {/* type          = {'available'} */}
                    {/* noBorder     = {true} */}
                    {/* /> */}
                    {/* </div> */}
                    {/*: */}
                    {/* null */}
                    {/* } */}

                </Toolbar>
            </AppBar>
        </div>
    )
}
