import React from 'react'
import errorCharacter from './error-character.svg'
import PropTypes from 'prop-types'

class ErrorPage extends React.Component {
    paymentErrors = [
        'Something went wrong while fetching pricing summary.',
        'Payment declined.',
        'No payment method on file when purchase non-free numbers.'
    ];

    genericErrors = [
        'Something went wrong. Please try again.',
        'Internal Server Error',
        'Something went wrong while purchasing number(s).',
        'Something went wrong while purchasing cart.'
    ];

    rateErrors = [
        "Sorry, it looks like you've reached your daily number purchase limit. If you'd like to request a limit increase, please contact us at 844-746-6312."
    ];

    numberErrors = [
        'Are you looking for a toll free number?'
    ];

  onErrorAction = (reset, redirect) => {
      if (reset) {
          this.props.resetErrors()
      } else if (redirect.length > 0) {
          window.location.href = redirect
      }
  }

  /**
   *
   */
  render () {
      const error = this.props.error
      let message = error
      let header = 'Oh no...'
      let action = 'Continue looking'
      let redirect = ''
      let resetErrors = false

      if (this.paymentErrors.includes(error)) {
          // Pricing and Purchase
          header = 'We are unable to process your request'
          message = 'We were unable to charge your primary card on file. Please update your payment method on the payment information page.'
          action = 'Update payment method'
          redirect = '/account/billing'
      } else if (this.numberErrors.includes(error)) {
          // Default
          resetErrors = true
      } else if (this.rateErrors.includes(error)) {
          message = <>Sorry, it looks like you&apos;ve reached your daily number purchase limit. <br/><br/>If you&apos;d like to request a limit increase, please contact us at <a href='tel:844-746-6312'>844-746-6312</a>.</>
          redirect = ''
          resetErrors = true
      } else {
          // Generic errors
          message = 'It looks like someone else reserved that number.'
          // redirect = '/phone-numbers/add-numbers'
          redirect = ''
          resetErrors = true
      }

      return (
          <div className='search-error-page'>
              <img className='search-error-character' src={errorCharacter}/>
              <div className='search-error-message-wrapper'>
                  <div className='search-error-header'>
                      {header}
                  </div>
                  <div className='search-error-message'>
                      {message}
                  </div>
                  <a onClick={() => this.onErrorAction(resetErrors, redirect)} className='search-error-action'>{action}</a>
              </div>
          </div>
      )
  }
}

ErrorPage.propTypes = {
    resetErrors: PropTypes.func,
    error: PropTypes.string
}

export default ErrorPage
