<template>
    <w-loader v-if="loading" size="small" :message="false" class="ml-3"/>
    <div
      v-else
      class="player prevent-toggle"
      data-test-id="player"
    >
      <DefaultModal v-model="show_modal" @close="show_modal = false" max-width="500">
        <template v-slot:title>{{l.t('app.error', 'Error')}}</template>
        {{ error }}
        <template v-slot:buttons>
          <w-btn @click="show_modal = false" color="secondary">{{l.t('app.close', 'Close')}}</w-btn>
        </template>
      </DefaultModal>
      <div v-if="!just_player" :class="{ busy: busy }" class="d-flex player prevent-toggle">
       <w-tooltip v-if="!no_download" top no-carret small>
        <template v-slot:activator>
          <DownloadBtn
            :href="saved_url || '#'"
            :download="download_name"
            @click="download"
            :gray="gray"
            :icon_size="icon_size"
            :icon="downloading ? $vuetify.icons.spinning_loader : download_icon"
            :btn_size="btn_size"
            target="_blank"
            class="player-download-btn"
            :key="downloading"
            data-test-id="player-download-btn"
          />
          </template>
          <span>{{l.t('player.download-recording', 'Download recording')}}</span>
        </w-tooltip>
        <w-btn
          v-if="player.playing"
          @click="player.stop()"
          :title="l.t('player.stop', 'Stop')"
          class="prevent-toggle"
          :class="btn_classes"
          icon
          :height="btn_size"
          :width="btn_size"
          data-test-id="player-stop-btn"
        >
          <v-icon :class="icon_classes" :size="icon_size" class="prevent-toggle">$vuetify.icons.stop</v-icon>
        </w-btn>
        <w-tooltip top v-else no-carret small>
          <template v-slot:activator>
            <w-btn
              @click.prevent="play"
              class="prevent-toggle"
              :class="btn_classes"
              icon
              :disabled="playing && player.downloading"
              :height="btn_size"
              :width="btn_size"
              data-test-id="player-pause-btn"
            >
              <v-icon :class="icon_classes" :size="icon_size" class="prevent-toggle">
                {{playing && player.downloading ? '$vuetify.icons.spinning_loader' : '$vuetify.icons.play'}}
              </v-icon>
            </w-btn>
           </template>
           <span>{{l.t('player.play-recording', 'Play recording')}}</span>
          </w-tooltip>
      </div>
      <w-btn
        v-else
        @click="toggle_play"
        :title="l.t('player.play', 'Play')"
        class="prevent-toggle action lighten-1 ml-3"
        icon
        :height="btn_size"
        :width="btn_size"
        data-test-id="selector-search-btn"
      >
        <v-icon :class="icon_classes" :size="icon_size" :key="`play-as-btn-${player.downloading}-${player.playing}`">
          {{ player.downloading ? '$vuetify.icons.spinning_loader' : player.playing ? '$vuetify.icons.stop' : '$vuetify.icons.loudspeaker' }}
        </v-icon>
      </w-btn>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import FileHandler from '../../libs/FileHandler';
  import DefaultModal from './modal/DefaultModal.vue';
  import DownloadBtn from './buttons/download-icon.vue';

  export default {
    components: { DefaultModal, DownloadBtn },
    props: {
      'url': {
        type: String,
      },
      'just_player': {
        type: Boolean,
        default: false,
      },
      'gray': {
        type: Boolean,
        default: false,
      },
      'icon_size': {
        type: Number,
        default: 16,
      },
      'btn_size': {
        type: Number,
        default: 24,
      },
      'download_icon': {
        type: String,
        default: '$vuetify.icons.download_cloud',
      },
      'no_download': {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        l,
        player: new FileHandler(this.$props.url, this.$session),
        loading: false,
        saved_url:
          this.$props.url.slice(0, 4) === 'http' ? this.$props.url : null,
        download_name: 'download.mpeg',
        busy: false,
        error: null,
        show_modal: false,
        playing: false,
        downloading: false,
      };
    },
    computed: {
      btn_classes() {
        if (this.$props.gray) {
          return 'secondary lighten-2';
        }

        return 'primary';
      },
     icon_classes() {
        if (this.$props.gray) {
          return 'secondary--text text--darken-1';
        }
        return 'white--text';
      },
    },
    watch: {
      error(val) {
        this.$data.show_modal = !!val;
      },
    },
    beforeDestroy() {
      this.$data.player.revoke_urls();
    },
    methods: {
      async play() {
        if (this.$data.busy) {
          return;
        }
        this.$emit('opened');
        this.$emit('status', { playing: this._uid });
        this.$data.playing = true;
        try {
          await this.$data.player.play();
        } catch (err) {
          console.log(err);
          this.$data.error = l.t('app.generic-error', 'Something went wrong');
          this.$emit('status', { playing: null });
        }
        this.$data.playing = false;
      },
      async download(e) {
        this.$data.downloading = true;

        if (this.$data.busy) {
          return;
        }
        this.$emit('opened');
        try {
            if (e) e.preventDefault(); // todo
            await this.$data.player.download();
        } catch (err) {
          console.log(err);
          this.$data.error = l.t('app.something-went-wrong-while-downloading', 'Something went wrong while downloading your file.');
        }
        this.$data.downloading = false;
      },
      toggle_play() {
        if (!this.$data.player.playing) return this.play();
        return this.$data.player.stop();
      }
    },
  };
</script>
