//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: [],
  data: function data() {
    return {};
  }
};