/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { TimeItem, Ranges, Range } from './timeItem.ts'

/**
 *
 */
export class ScheduleItem {
    from: TimeItem
    to: TimeItem
    status: string
    text: string
    extra: any
    /**
     * @param params
     */
    constructor (params: any) {
        this.from = params.from
        if (typeof params.from === 'string') this.from = new TimeItem(params.from)
        this.to = params.to
        if (typeof params.to === 'string') this.to = new TimeItem(params.to)
        this.status = params.status
        this.text = params.text
        const otherParams = { ...params }
        delete otherParams.from
        delete otherParams.to
        delete otherParams.status
        delete otherParams.text
        this.extra = otherParams
    }

    /**
     *
     */
    getFrom (): TimeItem { return this.from }
    /**
     *
     */
    getTo (): TimeItem { return this.to }
    /**
     *
     */
    getStatus (): string { return this.status }
    /**
     *
     */
    getText (): string { return this.text }
    /**
     *
     */
    getExtra (): any { return this.extra }

    isOn = (): boolean => this.getStatus() === 'on'

    isFullDay = (): boolean => {
        const from: TimeItem = this.from
        const to: TimeItem = this.to
        if (from.getHours() !== 0 || from.getMinutes() !== 0) return false
        if (to.getHours() !== 0 || to.getMinutes() !== 0) return false
        return true
    }
}

/**
 *
 */
export class ScheduleItems {
    scheduleItems: ScheduleItem[] = []
    /**
     * @param itemsParams
     */
    constructor (itemsParams: any[]) {
        itemsParams.forEach((itemParams: any) => {
            const scheduleItem = new ScheduleItem(itemParams)
            this.scheduleItems.push(scheduleItem)
        })
    }

    /**
     * @param scheduleItem1
     * @param scheduleItem2
     */
    static getIntersectionTimes (scheduleItem1: ScheduleItem, scheduleItem2: ScheduleItem) {
        const fromTimeItem1 = new TimeItem(scheduleItem1.from, true)
        const toTimeItem1 = new TimeItem(scheduleItem1.to, true)
        const fromTimeItem2 = new TimeItem(scheduleItem2.from, true)
        const toTimeItem2 = new TimeItem(scheduleItem2.to, true)
        const range1: Range = new Range(fromTimeItem1, toTimeItem1)
        const range2: Range = new Range(fromTimeItem2, toTimeItem2)
        const intersectionRange: Range = Ranges.getIntersection(range1, range2)
        const response = { from: intersectionRange.startTimeItem.toString12(), to: intersectionRange.endTimeItem.toString12() }
        return response
    }

    /**
     *
     */
    hasCustomScheduleItem (): boolean {
        const found = this.scheduleItems.find((scheduleItem: ScheduleItem) => !scheduleItem.isOn() || !scheduleItem.isFullDay())
        return Boolean(found)
    }

    /**
     *
     */
    areSame (): boolean {
        const are2ItemsSame = (item1: ScheduleItem, item2: ScheduleItem): boolean => {
            if (item1.getFrom().toString24() !== item2.getFrom().toString24()) return false
            if (item1.getTo().toString24() !== item2.getTo().toString24()) return false
            if (item1.getStatus() !== item2.getStatus()) return false
            if (item1.getText() !== item2.getText()) return false
            const extra1: any = item1.getExtra()
            const extra2: any = item2.getExtra()
            const extra1Keys: string[] = Object.keys(extra1)
            const extra2Keys: string[] = Object.keys(extra2)
            if (extra1Keys.length !== extra2Keys.length) return false
            for (let i = 0; i < extra1Keys.length; i++) {
                if (extra1[extra1Keys[i]] !== extra2[extra2Keys[i]]) return false
            }
            return true
        }

        const scheduleItems = this.scheduleItems
        if (scheduleItems.length === 0) throw new Error('No items')
        if (scheduleItems.length === 1) return true
        const firstScheduleItem = scheduleItems[0]
        for (let i = 1; i < scheduleItems.length; i++) {
            if (!are2ItemsSame(firstScheduleItem, scheduleItems[i])) return false
        }
        return true
    }
}
