export function setNumbers(numbers) {
	return {
		type: 'SET_NUMBERS',
		numbers
	}
}
export function deleteNumber(numberId) {
	return {
		type: 'DELETE_NUMBER',
		numberId
	}
}

export function setNewNumbers(newNumbers) {
	return {
		type: 'SET_NEW_NUMBERS',
		newNumbers
	}
}
export function deleteNewNumber(newNumberId) {
	return {
		type: 'DELETE_NEW_NUMBER',
		newNumberId
	}
}