/**
 * @param {object} theme
 */
const styles = theme => ({
    wrapper: {
    },
    chipsWrapper: {
        display: 'flex',
        gap: 10
    },
    chipField: {
        '& > [class*="MuiInputBase-root"]': {
            height: 'fit-content',
            flexFlow: 'wrap',
            gap: 5,
            paddingTop: 5,
            paddingBottom: 5,
            '& > [class*="MuiInputBase-input"]': {
                width: 145,
                height: 39
            }
        },
        '& > [class*="MuiInputLabel-shrink"]': {
            transform: 'translate(0, 16px) scale(1)',
            textTransform: 'lowercase'
        }
    },
    chipRoot: {
        // width: 135,
    }
})

export default styles
