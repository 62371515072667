import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Button from 'button'
import Chip from 'pdc-chip'
import api from '../../util/api_v5'
import Spinner from 'spinner'
import TextField from 'pdc-text-field'
import LoaderFull from 'loader-full'
import InfiniteScroller from 'pdc-infinite-scroller'
import { theme } from 'get-theme'

import { formatPhoneNumber } from 'phone-numbers'


const styles = theme => ({
	loadingDiv: theme.loadingDiv,
	wrapper: {
		padding:		20,
		height:			'100%',
		maxHeight:		'calc(100% - 80px)',
		display:		'flex',
		flexDirection:	'column'
	},
	blockedNumbersSection: {
		marginTop:	20,
		maxHeight:	'calc(100% - 84px)',
		height:		'100%',
		overflowY:	'auto',
		'& .infinite-scroller': {
			display:		'flex',
			alignItems:		'center',
			alignContent:	'flex-start',
			flexWrap:		'wrap',
			maxHeight:		'calc(100% - 49px)',
			height:			'fit-content'
		}
	},
	title: {
		fontSize:		20,
		marginBottom:	9
	},
	blockNumberFields: {
		display:	'flex',
		alignItems:	'center'
	},
	searchField: {
		width:			400,
		marginRight:	20
	},
	chip: {
		width:			'fit-content',
		marginRight:	10,
		marginBottom:	10
	},
	errorMessage: {
		color:			'#df1d08',
		margin:			'30px 45px 0',
		fontSize:		14,
		fontWeight:		'bold',
		lineHeight:		1.29,
		letterSpacing:	-0.1,
		textAlign:		'center'
	},
	scrollMessage: {
		textAlign:	'center',
		color:		'gray'
	}
})

const mapStateToProps = state => ({
	smallView: state.smallView,
})

const mapDispatchToProps = dispatch => ({
})

class BlockList extends Component {

	state = {
		blockedItems:		[],
		newBlockedNumber:	'',
		invalidInput:		false,
		errorMessage:		'',
		hasMore: 			true
	}

	componentDidMount =  async () => {
		this.setState({loadingInit: true})
		await this.getBlockedItems(false)
		this.setState({loadingInit: false})
	}


	getBlockedItems = async (scroll=true) => {
		if (this.state.loading) return
		let cursor = 0
		if (this.state.blockedItems.length > 0) {
			cursor = this.state.blockedItems[this.state.blockedItems.length - 1]['cursor']
		}
		let response = await api.getBlocklist(cursor)
		if (response && response.data.length > 0) {
			this.setState({
				blockedItems: [...this.state.blockedItems, ...response.data],
				hasMore: true
			})
			if (scroll) this.scrollToBottom()
		} else {
			this.setState({hasMore: false})
		}
		return response
	}

	removeBlockedItem = async item => {
		if (this.state.loading) return
		this.setState({loading: true})
		await api.removeFromBlocklist(item.voip_blacklist_id)
		this.setState({loading: false})
		let blockedItems = this.state.blockedItems
		let index = blockedItems.indexOf(item)
		if (index !== -1) blockedItems.splice(index, 1)
		this.setState({blockedItems})
	}

	addItemToBlockList = async () => {
		
		if (!this.validateNewNumber()) {
			this.setState({invalidInput: true, errorMessage: 'Please enter a valid number'})
			return
		}
		this.setState({loading: true, errorMessage: ''})
		let response = await api.addToBlocklist(this.state.newBlockedNumber)
		this.setState({loading: false})
		if (response && response.status === 'success'){
			let blockedItem = response.data[0] //always 1 item
			this.setState({
				blockedItems: [...[blockedItem], ...this.state.blockedItems],
				newBlockedNumber: '',
				invalidInput: false
			  })
		}
		else if(response){
			this.setState({invalidInput: true, errorMessage: response.message})
		}
		else{
			this.setState({invalidInput: true, errorMessage: 'Something went wrong while blocking number!'})
		}
	}

	validateNewNumber = () => {
		if (this.state.newBlockedNumber.length === 14) return true
		return false
		// let originalInputValue = inputValue

		// if (isValidNumber(inputValue) || isValidNumber(inputValue, 'US')) {

		// 	let parsedNumber
		// 	if (isValidNumber(inputValue, 'US') && inputValue[0] !== '+1') {
		// 		parsedNumber = PARSENUMBER(inputValue, 'US')
		// 	} else {
		// 		parsedNumber = PARSENUMBER(inputValue)
		// 	}

		// 	inputValue = FORMATNUMBER(parsedNumber, 'International')
		// 	inputValue = inputValue.split(' ').join('')
		// 	return true
		// }

		// inputValue = originalInputValue.replace(/^0+|0+$/g, '')
		// if (this._isShortCode(inputValue)) {
		// 	return true
		// }

		// if (!inputValue) {
		// 	console.log(' Error message: Type in a phone number.')
		// 	return false
		// }

		// console.log(' Error message: The phone number is invalid')
		// return false
	}

	onNewBlockNumberChange = value => {
		// if (value.substring(0, 2) === '+1') value = value.substring(2)
		// if (this.state.invalidInput && this.validateNewNumber(value)) this.setState({invalidInput: false, errorMessage: ''})
		// if (value.length === 4 && value[0] === '(' && !isNaN(value.substring(1))) value = value.substring(1, 3)
		// let formattedNumber = new AsYouType('US').input(value)
		this.setState({newBlockedNumber: value})
	}

	_isShortCode = string => string.match(/^[1-9][0-9]{2,5}$/g)

	scrollToBottom = () => {
		this.itemsEnd.scrollIntoView({ behavior: 'smooth' })
	}

	renderLoader = () => {
		const { classes } = this.props
		return (
			this.state.loading || this.state.loadingInit ?
				<div className={classes.loadingDiv}>
					<LoaderFull text='Please wait...' color={theme.palette.secondary[0]} styles={{loaderFull: {left: this.props.smallView ? '50%' : 'calc(50% + 120px)'}}} size='bigger'/>
				</div>
			: null
		)
	}

	renderBlockANumberSection = () => {
		const { classes } = this.props
		return (
			<div>
				<div className={classes.title}>Block a number</div>
				<div className={classes.blockNumberFields}>
					<TextField
						type			= 'phone-number-us'
						label			= 'Add a number to block'
						content			= {this.state.newBlockedNumber}
						className		= {{wrapper: classes.searchField}}
						onInputChange	= {this.onNewBlockNumberChange}
					></TextField>
					<Button
						onClick		= {this.addItemToBlockList} 
						color		= 'attention'
						disabled	= {Boolean(this.state.loading || !this.validateNewNumber())}
					>BLOCK</Button>
				</div>
			</div>
		)
	}

	renderBlockedNumbersSection = () => {
		const { classes } = this.props

		return (
			<div className={classes.blockedNumbersSection}>
				<div className={classes.title}>List of blocked numbers</div>
				<InfiniteScroller
					reverseScroll	= {false}
					loadMore		= {this.getBlockedItems}
					hasMore			= {this.state.hasMore}
					loader			= {<Spinner/>}
				>
					{this.state.blockedItems.map((item, index) => {
						return (
							<Chip
								key			= {index}
								variant		= 'text'
								className	= {classes.chip}
								onXClick	= {() => this.removeBlockedItem(item)}
							>{formatPhoneNumber(item.pattern)}</Chip>
						)
					})}
				</InfiniteScroller>
				{this.state.hasMore ? <em className={classes.scrollMessage}>Scroll to load more</em> : null}
				<div style={{float:'left', clear: 'both'}}
					ref={el => this.itemsEnd = el}>
				</div>
			</div>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.wrapper}>
				{this.renderLoader()}
				{!this.state.loadingInit && !this.state.loading ?
					<>
						{this.renderBlockANumberSection()}
						{this.renderBlockedNumbersSection()}
						{this.state.errorMessage ? <div className={classes.errorMessage}>{this.state.errorMessage}</div> : null}
					</>
				: null}

				
				
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BlockList))