import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5650%3A4505
 *
 * @param {object} props - svg props
 */
export const SoftAlertIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M11.8455 3.63019L21.0312 18.1742C21.4517 18.84 20.9733 19.7082 20.1857 19.7082H1.81436C1.0268 19.7082 0.548319 18.84 0.968868 18.1742L10.1545 3.63019C10.547 3.00885 11.4531 3.00885 11.8455 3.63019ZM10.0834 9.28348C10.0834 8.77722 10.4938 8.36682 11 8.36682C11.5063 8.36682 11.9167 8.77722 11.9167 9.28348V12.9501C11.9167 13.4564 11.5063 13.8668 11 13.8668C10.4938 13.8668 10.0834 13.4564 10.0834 12.9501V9.28348ZM10.0834 15.7001C10.0834 15.1939 10.4938 14.7835 11 14.7835C11.5063 14.7835 11.9167 15.1939 11.9167 15.7001C11.9167 16.2064 11.5063 16.6168 11 16.6168C10.4938 16.6168 10.0834 16.2064 10.0834 15.7001Z' fill={color}/>
        </SvgIcon>
    )
}

export default SoftAlertIcon
