import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props
 */
export const SpinnerIcon2 = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'>
            <g fill={color} fillRule='evenodd'>
                <rect width='5.268' height='16.098' x='27.366' opacity='.3' rx='2.634' />
                <rect width='5.268' height='16.098' x='27.366' y='43.902' opacity='.8' rx='2.634' transform='matrix(1 0 0 -1 0 103.902)' />
                <rect width='16.098' height='5.268' y='27.366' rx='2.634' />
                <rect width='16.098' height='5.268' x='43.902' y='27.366' opacity='.6' rx='2.634' />
                <path d='M45 4.02a2.634 2.634 0 0 1 .964 3.598l-5.414 9.378a2.634 2.634 0 1 1-4.563-2.634l5.415-9.379A2.634 2.634 0 0 1 45 4.02z' opacity='.4' />
                <path d='M15 55.98c1.26.728 2.87.296 3.598-.963l5.415-9.379a2.634 2.634 0 0 0-4.563-2.634l-5.414 9.378A2.634 2.634 0 0 0 15 55.981z' opacity='.85' />
                <path d='M45 55.98a2.634 2.634 0 0 0 .964-3.598l-5.414-9.378a2.634 2.634 0 0 0-4.563 2.634l5.415 9.379A2.634 2.634 0 0 0 45 55.98z' opacity='.75' />
                <path d='M15 4.02a2.634 2.634 0 0 1 3.598.963l5.415 9.379a2.634 2.634 0 1 1-4.563 2.634l-5.414-9.378A2.634 2.634 0 0 1 15 4.019z' opacity='.2' />
                <path d='M7.618 14.036l9.378 5.414a2.634 2.634 0 1 1-2.634 4.563l-9.379-5.415a2.634 2.634 0 0 1 2.635-4.562z' opacity='.1' />
                <path d='M45.638 35.987l9.379 5.415a2.634 2.634 0 0 1-2.635 4.562l-9.378-5.414a2.634 2.634 0 1 1 2.634-4.563z' opacity='.7' />
                <path d='M52.382 14.036l-9.378 5.414a2.634 2.634 0 0 0 2.634 4.563l9.379-5.415a2.634 2.634 0 1 0-2.635-4.562z' opacity='.5' />
                <path d='M14.362 35.987l-9.379 5.415a2.634 2.634 0 1 0 2.635 4.562l9.378-5.414a2.634 2.634 0 0 0-2.634-4.563z' opacity='.9' />
            </g>
        </SvgIcon>
    )
}

export default SpinnerIcon2
