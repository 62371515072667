import React, { useContext, useEffect } from 'react'
import styles from './styles'
import Button from 'button'
import PersonAvatarComponent from 'person-avatar-component'
import { XIcon, LinkArrow } from 'pdc-svg-icons'
import { ClickAwayListener, Dialog, Fade } from '@material-ui/core'
import { isElectron } from 'pdc-electron-utils'
import { reableAppClientVersion, readableAppVersion } from 'phonecom'
import Spinner from 'spinner'
import { FeatureEventsContext, ScreenSizeContext, ThemeContext } from 'providers'
import { makeStyles } from '@material-ui/core'
import { logout } from 'phonecom'
import { UserInfo } from './TopBar'
import api from 'users-app/src/util/api_v5'

interface Props {
    open: boolean
    onClose: () => void
    userInfo: UserInfo
}

const useStyles = makeStyles(styles)

const VersionLabels = (): JSX.Element => (
    <div>
        <span data-test-id="identity-app-version" className='secondary-text'>Version: {readableAppVersion()}</span>
        <br/>
        {(isElectron)
            ? <span className='secondary-text'>Client Version: {reableAppClientVersion()}</span>
            : null
        }
    </div>
)

const links = [
    {
        name: 'Help',
        url: 'https://www.phone.com/support/'
    },
    {
        name: 'Get The Mobile App',
        url: 'https://www.phone.com/mobile/'
    }
]

const updateLinks = (csr: boolean, supportTabLink: string) => {
    if (supportTabLink) links[0].url = supportTabLink
    if ((process.env.REACT_APP_DOWNLOAD_LINKS_HIDDEN && !csr) || links.length !== 2) return
    links.splice(0, 0,
        {
            name: 'Get Windows My Phone.com App',
            url: process.env.REACT_APP_WINDOWS_DESKTOP_APP_DOWNLOAD_LINK as string
        },
        {
            name: 'Get Mac My Phone.com App',
            url: process.env.REACT_APP_MAC_DESKTOP_APP_DOWNLOAD_LINK as string
        },
        {
            name: 'Get Mac My Phone.com App (M1 Chip)',
            url: process.env.REACT_APP_MAC_M1_DESKTOP_APP_DOWNLOAD_LINK as string
        },
        {
            name: 'Get Linux My Phone.com App',
            url: process.env.REACT_APP_LINUX_DESKTOP_APP_DOWNLOAD_LINK as string
        }
    )
}

const Content = (props: Props): JSX.Element => {
    const [signingOut, setSigningOut] = React.useState(false)
    const [expressCode, setExpressCode] = React.useState('')
    const screenSizeContext = useContext(ScreenSizeContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const theme = useContext(ThemeContext)
    const supportTabLink = theme?.supportTabLink || 'https://www.phone.com/support/'
    useEffect(() => {
        updateLinks(props.userInfo.csr, supportTabLink)
        api.getExpressServiceCode().then(setExpressCode)
    }, [])
    const classes = useStyles()
    const isMobileView = screenSizeContext.mobile
    const avatarUrl = window.V5PHONECOM.avatar_url || null
    const mobileClass = isMobileView ? 'mobile-view' : ''
    const voipId = window.V5PHONECOM.voip_id
    const { name, number, accountId, company } = props.userInfo
    const signOut = () => {
        setSigningOut(true)
        logout()
    }
    return (
        <>
            <div className={`${classes.identityPopupUserInfo} ${mobileClass}`}>
                <PersonAvatarComponent url={avatarUrl} size={isMobileView ? 80 : 60} color='primary'/>
                <span className='secondary-text'>{name}</span>
                <span className='secondary-text' data-test-id='identity-phone-number'>{company}</span>
                <span className='secondary-text' data-test-id='identity-phone-number'>{number}</span>
                <br/>
                <span className='secondary-text'>Voip ID: {voipId}</span>
                <span className='secondary-text'>Account ID: {accountId}</span>
                {expressCode && <span className='secondary-text'>Express service code: {expressCode}</span>}
            </div>
            <div className={`${classes.identityPopupOptions} ${mobileClass}`}>
                {links.map((link, i) => (
                    <div
                        key = {i}
                        className = 'identity-popup-option'
                        onClick = {() => window.open(link.url, link.name)}
                    >
                        {link.name}
                        <LinkArrow style={{ fontSize: 16, paddingLeft: 9 }}/>
                    </div>
                ))}
            </div>
            <div className='identity-popup-actions'>
                <Button data-test-id='signout-button' onClick={signOut} disabled={signingOut} className={classes.signOutButton}>Sign out</Button>
                {signingOut ? <Spinner/> : null}
            </div>
            <div className={`${classes.versionInfo} ${mobileClass}`}>
                <VersionLabels/>
            </div>
            <div
                data-test-id = 'identity-popup-close-button'
                className = 'x-icon-wrapper'
                onClick = {() => {
                    // For mobile view it is handled down in MobilePopup
                    if (!isMobileView) featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-indentity-popup-close', value: 'desktop-close' })
                    props.onClose()
                }}
            >
                <XIcon className='x-icon'/>
            </div>
        </>
    )
}

const MobilePopup = (props: Props): JSX.Element => {
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    const classNames = `${classes.dialogPaper} ${classes.identityPopup} mobile-view`
    return (
        <Dialog
            classes = {{ paper: classNames }}
            open = {props.open}
            onClose = {() => {
                featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-indentity-popup-close', value: 'mobile' })
                props.onClose()
            }}
            TransitionComponent={(p) => <Fade {...p} timeout={200}/>}
        >
            <Content {...props}/>
        </Dialog>
    )
}

const DesktopPopup = (props: Props): JSX.Element | null => {
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    const classNames = `${classes.identityPopup} ${classes.identityPopupDesktopPosition}`
    return (
        <ClickAwayListener
            onClickAway = {() => {
                featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-indentity-popup-close', value: 'desktop-click-away' })
                props.onClose()
            }}
        >
            <div className={classNames}/*  style={{ opacity }} */>
                <Content {...props}/>
            </div>
        </ClickAwayListener>
    )
}

/***/
export const IdentityPopup = (props: Props): JSX.Element => {
    const screenSizeContext = useContext(ScreenSizeContext)
    return (
        screenSizeContext.mobile
            ? <MobilePopup {...props}/>
            : <DesktopPopup {...props}/>
    )
}

export default IdentityPopup
