<template>
	<svg v-if="circle" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect width="32" height="32" rx="16" fill="#8C98A0" fill-opacity="0.15"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M20.6542 9.56985L22.4074 11.3397C22.6998 11.635 22.6964 12.1117 22.3998 12.4028L21.3535 13.4296C21.1591 13.6204 20.8478 13.6205 20.6533 13.4298L18.5297 11.3476C18.3314 11.1533 18.3295 10.8346 18.5254 10.6379L19.5898 9.56854C19.8835 9.27352 20.3613 9.27411 20.6542 9.56985ZM9.95597 19.6277L9.45916 22.0929C9.42477 22.2635 9.57088 22.4162 9.74282 22.3893L12.2738 21.9938C12.5846 21.9453 12.8724 21.8002 13.0962 21.5791L20.0877 14.6743C20.3879 14.3778 20.3846 13.8923 20.0805 13.5999L18.311 11.899C18.0157 11.6151 17.5471 11.6207 17.2587 11.9116L10.3613 18.8679C10.1548 19.0761 10.0139 19.3402 9.95597 19.6277Z" fill="#6A757D"/>
	</svg>
	<svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M18.0282 3.24758L20.7197 5.96471C21.0122 6.25997 21.0088 6.73674 20.7122 7.02782L19.0212 8.68729C18.8268 8.87807 18.5154 8.87814 18.3209 8.68745L15.2528 5.67926C15.0546 5.4849 15.0526 5.16625 15.2485 4.96949L16.9639 3.24627C17.2575 2.95125 17.7353 2.95184 18.0282 3.24758ZM3.97071 16.69L3.25505 20.2411C3.22067 20.4117 3.36678 20.5644 3.53872 20.5375L7.19088 19.9669C7.50174 19.9183 7.78946 19.7732 8.01333 19.5521L17.6327 10.052C17.9328 9.7556 17.9296 9.27004 17.6254 8.9777L14.9037 6.36137C14.6084 6.07746 14.1398 6.08308 13.8514 6.374L4.37599 15.9302C4.16953 16.1384 4.02863 16.4026 3.97071 16.69Z" fill="currentColor"/>
	</svg>

</template>

<script>
  export default {
    props: ['circle'],
  };
</script>
