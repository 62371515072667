let instance;

class Queue {
    constructor() {
        if (instance) {
            throw new Error('New instance cannot be created.');
        }

        this.setup = {
            // ...Queue.create_empty_setup(),
        };
        instance = this;
    }

    static create_empty_setup() {
        return {
            queue: [],
            working_on_promise: false,
        };
    }

    enqueue(mode, promise) { // promise must be sent as callback enqueue(mode, () => promise(arg))
        return new Promise((resolve, reject) => {
            this.setup[mode] = this.setup[mode] || Queue.create_empty_setup();
            this.setup[mode].queue.push({
                promise,
                resolve,
                reject,
            });
            this.dequeue(mode);
        });
    }

    dequeue(mode) {
        if (this.setup[mode].working_on_promise) {
            return false;
        }
        const item = this.setup[mode].queue.shift();
        if (!item) {
            return false;
        }
        try {
            this.setup[mode].working_on_promise = true;
            item.promise()
                .then((value) => {
                    this.setup[mode].working_on_promise = false;
                    item.resolve(value);
                    this.dequeue(mode);
                })
                .catch((err) => {
                    this.setup[mode].working_on_promise = false;
                    item.reject(err);
                    this.dequeue(mode);
                });
        } catch (err) {
            console.log(err);
            this.setup[mode].working_on_promise = false;
            item.reject(err);
            this.dequeue(mode);
        }
        return true;
    }
}

const PromisesQueue = Object.freeze(new Queue());

export default PromisesQueue;
