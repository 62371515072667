import React from 'react'
import PhonelinkErase from '@material-ui/icons/PhonelinkErase'
import reportError from 'error-reporter'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography/Typography'
import { mainTheme } from 'themes'
import PropTypes from 'prop-types'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        backgroundColor: 'lightgray',
        margin: 'auto'
    },
    icon: {
        verticalAlign: 'text-bottom',
        height: '50%',
        width: '30%'
    },
    textStyle: {
        width: '80%',
        marginRight: '10%',
        marginLeft: '10%',
        textAlign: 'center',
        wordBreak: 'break-word'
    }
})

class ErrorCatcher extends React.Component {
    constructor (props) {
        super(props)
        this.state = { hasError: false, message: '' }
    }

    componentDidCatch (error, errorInfo) {
        const lastErrorAt = localStorage.getItem('lastErrorAt')
        const fiveMinAgo = Date.now() - 300000
        window?.Rollbar?.critical(error + ' - ' + errorInfo?.componentStack + ' \n \n \n \n' + error?.stack)

        if (lastErrorAt < fiveMinAgo) {
            reportError(errorInfo.componentStack, `${window.location.href}: recovered/reloaded`, 0, 0, error)
            localStorage.setItem('lastErrorAt', String(Date.now()))
            window.location.reload(true)
        } else {
            reportError(errorInfo.componentStack, window.location.href, 0, 0, error)
        }
    }

    static getDerivedStateFromError (error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, message: error instanceof Error ? error.message : error }
    }

    render () {
        const linkStyle = { color: (this.props.theme) ? this.props.theme.palette.primary.main : mainTheme.palette.primary.main, fontWeight: 'bold' }
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallbackRender
                ? this.props.fallbackRender()
                : (
                    <div className={this.props.classes.container}>
                        <PhonelinkErase className={this.props.classes.icon}/>
                        <Typography className={this.props.classes.textStyle} align={'left'} >
                            Oops! Something went wrong. You can try going back, or contact our <a style={linkStyle} href="https://www.phone.com/support/">Support Center</a> if you need further assistance.
                            <p>
                                Error: {this.state.message}
                            </p>
                        </Typography>
                    </div>
                )
        }

        return this.props.children
    }
}

ErrorCatcher.propTypes = {
    /**
     * Used in combination with `src` or `srcSet` to
     * provide an alt attribute for the rendered `img` element.
     */
    theme: PropTypes.object,

    /**
     * Used to render icon or text elements inside the Avatar.
     * `src` and `alt` props will not be used and no `img` will
     * be rendered by default.
     *
     * This can be an element, or just a string.
     */
    children: PropTypes.node,

    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Overrides the UI shown when an error is caught.
     * This should be a render function such as a react functional component.
     */
    fallbackRender: PropTypes.func
}

export default withStyles(styles)(ErrorCatcher)
