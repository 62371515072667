/**
 *
 * @param {number} length
 * @returns
 */
const generateRandomString = length => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let randomString = ''
    for (let i = 0; i < length; i++) {
        randomString += characters[Math.floor(Math.random() * characters.length)]
    }
    return randomString
}

/**
 *
 * @param {number} max
 * @returns
 */
const randomNumber = (max = 1000000000) => {
    return Math.floor(Math.random() * max)
}

/**
 *
 */
export { generateRandomString, randomNumber }
