<template>
	<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M5.41699 10.5623C5.41699 10.1135 5.73529 9.74976 6.12793 9.74976H19.8727C20.2654 9.74976 20.5837 10.1135 20.5837 10.5623V11.1039C20.5837 11.5527 20.2654 11.9164 19.8727 11.9164H6.12793C5.73529 11.9164 5.41699 11.5527 5.41699 11.1039V10.5623Z" fill="currentColor" fill-opacity="0.75"/>
	<path d="M19.8727 14.0833H6.12793C5.73529 14.0833 5.41699 14.447 5.41699 14.8958V15.4374C5.41699 15.8861 5.73529 16.2499 6.12793 16.2499H19.8727C20.2654 16.2499 20.5837 15.8861 20.5837 15.4374V14.8958C20.5837 14.447 20.2654 14.0833 19.8727 14.0833Z" fill="currentColor" fill-opacity="0.75"/>
	</svg>
</template>

<script>
  export default {};
</script>
