// import { getValue } from 'remote-config-value'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styles from './styles'
import Typography from 'typography'
import RemoteConfigValue from 'remote-config-value'
import BottomNavigation from 'bottom-navigation'
import ConfirmModal from 'confirm-modal'
import { getValue } from 'remote-config-value'

class IntroVideoStep extends Component {
    constructor (props) {
        super(props)
        this.videoUrl = 'https://www.phone.com/wp-content/uploads/2021/06/Phone.com-DS-FN.mp4'
        this.state = {
            nextDisabled: true,
            showScriptDialog: false
        }
    }

    componentDidUpdate = (prevProps) => {
        const hasSaveRequest = this.props.saveId && (prevProps.saveId !== this.props.saveId)
        if (hasSaveRequest) {
            this.props.update({
                completed: true,
                gotoNext: true,
                saveData: {
                    checked: true
                }
            })
        }
    }

    scriptContent = (
        <div>
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_1'/>
            </Typography>
            <br/>
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_2'/>
            </Typography>
            <br/>
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_3'/>
            </Typography>
            <br/>
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_4'/>
            </Typography>
            <br/>
            <ul>
                <li>
                    <Typography variant='body3'>
                        <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_bullet_1'/>
                    </Typography>
                </li>
                <li>
                    <Typography variant='body3'>
                        <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_bullet_2'/>
                    </Typography>
                </li>
                <li>
                    <Typography variant='body3'>
                        <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_bullet_3'/>
                    </Typography>
                </li>
            </ul>
            <br/>
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_5_1'/> <b><RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_5_2_bold'/></b> <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_5_3'/>
            </Typography>
            <br/>
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_6'/>
            </Typography>
            <br/>
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_7'/>
            </Typography>
            <br/>
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_checklist_video_script_content_p_8'/>
            </Typography>
        </div>
    )

    bottomNavigationText = (classes) => {
        return (
            <div>
                <Typography variant='body3'>
                    <b><RemoteConfigValue valueId='transfer_number_intro_video_bottom_navigation_text_1_bold'/></b>
                </Typography>
                <Typography variant='body3'>
                    <RemoteConfigValue valueId='transfer_number_intro_video_bottom_navigation_text_2'/> <a data-test-id='transfer-process-tutorial-link' className={classes.phoneComLink} target='_blank' rel='noreferrer noopener' href={getValue('transfer_number_checklist_rejection_warning_link_href')}><RemoteConfigValue valueId='transfer_number_checklist_rejection_warning_link_text'/></a>
                </Typography>
            </div>
        )
    }

    openScriptDialog = () => {
        this.setState({ showScriptDialog: true })
    }

    render () {
        const { classes, smallView } = this.props
        const videoLink = getValue('transfer_number_checklist_video_link_href')
        return (
            <div className={classes.wrapper}>
                <div className='content'>
                    <video height='340' width='600' controls onEnded={() => this.setState({ nextDisabled: false })}>
                        <source src={videoLink} type="video/mp4"/>
                    </video>
                    <Typography variant='body2'>
                        <RemoteConfigValue valueId='transfer_number_checklist_video_watch_text_1'/> <a data-test-id='transfer-process-tutorial-link' className={classes.phoneComLink} onClick={this.openScriptDialog}><RemoteConfigValue valueId='transfer_number_checklist_video_watch_text_2'/></a> <RemoteConfigValue valueId='transfer_number_checklist_video_watch_text_3'/>
                    </Typography>
                </div>
                <ConfirmModal
                    title = {getValue('transfer_number_checklist_video_script_title')}
                    isShown = {Boolean(this.state.showScriptDialog)}
                    content = {this.scriptContent}
                    noButtonText = 'Close'
                    yesButtonText = 'Next'
                    onReject = {() => this.setState({ showScriptDialog: false })}
                    onConfirm = {this.props.onNextClick}
                    size = 'size800'
                />
                <BottomNavigation
                    backDisabled
                    hideBackButton
                    nextDisabled = {this.state.nextDisabled}
                    nextButtonText = {'Next'}
                    nextButtonDataTestId = {'bottom-navigation-submit-button'}
                    description = {this.bottomNavigationText(classes)}
                    onNextClick = {this.props.onNextClick}
                    smallView = {smallView}
                    className = 'bottom-navigation'
                />
            </div>
        )
    }
}

IntroVideoStep.propTypes = {
    classes: PropTypes.object,
    updateWizardStep: PropTypes.func,
    name: PropTypes.string,
    smallView: PropTypes.string,
    saveId: PropTypes.string,
    update: PropTypes.func,
    onNextClick: PropTypes.bool
}

export default withStyles(styles)(IntroVideoStep)
