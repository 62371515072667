<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3788 7.30309C14.5072 9.92232 13.0195 12.4529 10.9648 12.5531C8.9101 12.6533 6.72439 10.0814 6.59594 7.46219C6.4675 4.84296 7.96553 2.96506 10.2351 2.85436C12.5046 2.74366 14.2503 4.68387 14.3788 7.30309ZM7.92278 12.4277C7.84472 12.4638 7.74883 12.5279 7.63395 12.6047C7.29081 12.834 6.77834 13.1766 6.06625 13.2239C5.92549 13.2332 5.77893 13.238 5.62834 13.243H5.62832C4.76201 13.2714 3.76235 13.3042 2.96694 14.2289C2.11574 15.2184 1.65236 16.3614 1.52243 18.6919C1.46902 19.65 2.16043 20.4906 3.07449 20.7824C3.37084 20.877 3.68553 20.9807 4.01577 21.0896C7.60471 22.2725 13.0305 24.061 16.7219 21.3209C17.4208 20.8021 17.6814 19.8988 17.5581 19.0371C17.5532 19.0027 17.5483 18.9685 17.5433 18.9346C17.3021 19.1108 17.0439 19.2684 16.7667 19.4047C16.0942 19.7354 15.274 19.4739 14.9359 18.8042C14.7204 18.3774 14.5854 17.9497 14.4282 17.4515C14.3591 17.2326 14.2857 17 14.1993 16.7479C14.0167 16.215 13.9982 15.6022 14.3787 15.1877C14.6136 14.9318 14.8745 14.7375 15.1475 14.5341C15.3092 14.4137 15.4752 14.2901 15.6425 14.1487C15.3944 14.0124 15.1182 13.9096 14.8068 13.7937C14.4377 13.6563 14.019 13.5004 13.539 13.2487C13.4013 13.163 13.3702 13.085 13.341 13.0121C13.3113 12.9378 13.2836 12.8687 13.148 12.8019C13.2658 14.8474 12.8865 15.8022 11.6525 15.8813C9.98152 15.9883 8.35078 14.0816 7.92278 12.4277Z" fill="currentColor"/>
        <path d="M15.8534 14.9102C15.5334 15.1931 15.1534 15.5312 15.5122 16.1719C15.9617 16.9746 16.3081 17.5224 16.3081 17.5224C16.458 17.7899 16.699 18.3117 17.4159 17.9855C17.9383 17.7477 20.4562 16.784 21.9861 12.2632C23.261 8.49585 21.8129 5.40782 21.2923 4.69805C20.9962 4.29442 20.5887 4.1082 19.6782 4.63661C19.395 4.80097 19.3536 4.81027 18.8911 5.04434C18.7225 5.14223 18.3944 5.27802 18.2957 5.36738C17.9317 5.69697 18.0132 6.03436 18.3177 6.86468C18.7685 8.094 18.4537 8.53604 19.199 8.72601L20.6915 9.30936C20.762 9.33693 20.8192 9.38981 20.843 9.46168C20.9735 9.85546 21.2901 11.3041 19.9795 13.6808C19.9061 13.8139 19.7427 13.8679 19.6033 13.8076L18.2546 13.2242C18.0928 13.1272 17.6868 13.0128 17.3493 13.4816C17.0031 13.9622 16.1733 14.6274 15.8534 14.9102Z" fill="currentColor"/>
    </svg>
</template>

<script>
  export default {};
</script>
