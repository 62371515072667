import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ChatIcon from '@material-ui/icons/Chat'
import { withStyles } from '@material-ui/core'

import Popover from '@material-ui/core/Popover'
import api from 'users-app/src/util/api_v5'

const styles = theme => ({
    helpPopoverWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    helpPopoverMain: {
        width: 300,
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.2), 0px 20px 35px rgba(0, 0, 0, 0.15), 0px 10px 45px rgba(0, 0, 0, 0.1)',
        borderRadius: 10
    },
    tooltip: {
        backgroundColor: '#48B9D5',
        fontSize: 12
    },
    expressServiceCodeRow: {
        display: 'flex',
        alignItems: 'center',
        height: 30,
        paddingLeft: 15,
        paddingRight: 15,

        '& span': {
            fontSize: 13,
            marginLeft: 35
        }
    },
    userGuideRow: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderBottom: `1px solid ${theme.palette.secondary['-200']}`,
        height: 50,
        paddingLeft: 15,
        paddingRight: 15,
        '& span': {
            fontWeight: 'bold',
            fontSize: 14,
            marginLeft: 15,
            marginRight: 15
        },
        '&:hover': {
            background: theme.palette.primary['-300']
        }
    },
    contactSupportRow: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 50,
        paddingLeft: 15,
        paddingRight: 15,

        '& span': {
            fontWeight: 'bold',
            fontSize: 14,
            marginLeft: 10
        },
        '&:hover': {
            background: theme.palette.primary['-300']
        }
    }

})

function HelpPopup (props) {
    const { classes } = props
    const [expressCode, setExpressCode] = useState('Loading...')
    const [sfChat, setsetSFChat] = useState(true)

    const supportNumber = '+18582607860'

    useEffect(() => {
        if (!window.V5PHONECOM || !window.V5PHONECOM.role || window.V5PHONECOM.role !== 'account') {
            return setExpressCode(null)
        }

        api.getExpressServiceCode().then(setExpressCode)
    }, [])

    useEffect(() => {
        const script = document.createElement('script')
        document.body.appendChild(script)
        script.onload = function () {
            const settings = {
                communityURL: 'https://phone.my.salesforce-sites.com/einsteinBot',
                sfURL: 'https://phone.my.salesforce.com',
                orgId: '00D41000002iMoz',
                LACURL: 'https://c.la2-c1-ph2.salesforceliveagent.com/content',
                LAURL: 'https://d.la2-c1-ph2.salesforceliveagent.com/chat',
                depId: '5721K000000PgaS',
                butId: '5731K000000Pk8g',
                domain: '',
                botName: 'Phone_Support_Bot',
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I550000008OwyEAE_179154f586c',
                snapInURL: '',
                displayCTA: false
            }

            settings.snapInURL = settings.communityURL + '/resource/phoneEinsteinBot/js/SnapInChat.js?' + Date.now()

            console.log('snapIn.js URL ' + settings.snapInURL)
            const s = document.createElement('script')
            s.setAttribute('src', settings.snapInURL)
            s.onload = function () {
                window.initChat(settings)
            }
            document.body.appendChild(s)

            /** Show and open chat trigger */
            setsetSFChat(true)
        }
        script.src = 'https://service.force.com/embeddedservice/5.0/esw.min.js'
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const getLink = (url) => {
        const helpLinks = {
            default: 'http://www.phone.com/phone-support'
        }
        return helpLinks[url] || helpLinks.default
    }

    const showSFChat = (closeCB) => {
        const sfChatElement = document.getElementsByClassName('embeddedServiceHelpButton')[0]
        const sfChatOpenElement = document.querySelector('.embeddedServiceHelpButton .helpButton .helpButtonEnabled.uiButton')
        if (!sfChatElement || !sfChatOpenElement) return
        sfChatElement.style.display = 'block'
        sfChatOpenElement.click()
        closeCB()
    }

    const openDocs = () => {
    // .. prepping implementing dyn links
        const link = getLink('')
        window.open(link, 'support')
    }

    const content = (
        <div>

            {
                sfChat
                    ? <div className={classes.helpPopoverWrapper}>
                        <div id='PDC-SF-Chat-Trigger' className={classes.userGuideRow} onClick={() => showSFChat(props.onClose)}>
                            <ChatIcon />
                            <span>Chat with support</span>
                        </div>
                    </div>
                    : null
            }

            <div className={classes.helpPopoverWrapper}>
                <div className={classes.userGuideRow} onClick={() => openDocs()}>
                    <LibraryBooksIcon />
                    <span>View documentation</span>
                </div>
            </div>

            {expressCode
                ? <div className={classes.expressServiceCodeRow}><span>Your express code: {expressCode}</span></div>
                : null}
            <div className={classes.expressServiceCodeRow}><span>Our support line: {supportNumber}</span></div>
        </div>
    )
    return (
        <Popover
            open={props.display}
            onClose={props.onClose}
            anchorEl={props.anchorRef.current}
            classes={{ paper: classes.helpPopoverMain }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            {content}
        </Popover>
    )
}

HelpPopup.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    display: PropTypes.bool,
    anchorRef: PropTypes.object
}

export default withStyles(styles)(HelpPopup)
