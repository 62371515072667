<template>
    <DefaultModal
        v-bind="$attrs"
        :value="val"
        @input="on_input"
        :persistent="changes_made"
        @click:outside="on_click_outside"
        @click:x="on_click_outside"
        data-test-id="form-modal"
    >
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
        <!-- Pass on all scoped slots -->
        <!-- <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope" />
        </template> -->
        <DiscardChangesModal
            v-if="show_unsaved_changes_modal"
            :value="true"
            @closed="show_unsaved_changes_modal = false"
            @discard="discard_changes"
            :title="discard_title"
            :text="discard_text"
        />
        <!-- <DefaultModal v-model="show_unsaved_changes_modal" data-test-id="show-unsaved-changes-modal"
            max-width="450">
            {{l.t('app.there-are-unsaved-changes-would-you-like-to-proceed', 'There are unsaved changes would you like to proceed?')}}
            <template v-slot:buttons>
                <w-btn @click="$emit('input', false)" color="secondary">{{l.t('app.proceed', 'Proceed')}}</w-btn>
                <w-btn @click="show_unsaved_changes_modal = false" color="primary">{{l.t('app.continue-editing', 'Continue editing')}}</w-btn>
            </template>
        </DefaultModal> -->
    </DefaultModal>
</template>
<script>
import l from '../../../libs/lang';
import DefaultModal from './DefaultModal.vue';
import DiscardChangesModal from './DiscardChanges.vue'

export default {
    props: {
        value: {
            type: Boolean,
        },
        prevent_close_instantly: {
            type: Boolean,
        },
        discard_title: {
            type: [String, null],
            default: null,
        },
        discard_text: {
            type: [String, null],
            default: null,
        }
    },
    components: {
        DefaultModal,
        DiscardChangesModal
    },
    data() {
        return {
            l,
            show_unsaved_changes_modal: false,
            changes_made: false,
            val: this.$props.value,
            force_close: false,
        };
    },
    async created() {
        this.$data.changes_made = !!this.$props.prevent_close_instantly;
        this.$global_emitter.$on('form_submitted', () => this.$data.changes_made = false);
        this.$global_emitter.$on('form_input_changed', () => this.$data.changes_made = this.$props.value);
    },
    methods: {
        on_click_outside() {
            if (this.$data.changes_made) {
                this.$data.show_unsaved_changes_modal = true;
            } else {
                this.discard_changes();
            }
        },
        on_input(v) {
            if (!v && this.$data.changes_made && !this.show_unsaved_changes_modal) {
                this.on_click_outside();
            } else {
                this.$data.show_unsaved_changes_modal = false;
                this.$emit('input', v);
            }
        },
        discard_changes() {
            this.$emit('input', false);
            this.$emit('close');
            this.$data.force_close = true;
            this.$data.show_unsaved_changes_modal = false;
        },
        check_changes(v) {
            if (!v && this.$data.changes_made && !this.show_unsaved_changes_modal && !this.$data.force_close) {
                this.$data.show_unsaved_changes_modal = true;
                this.$emit('input', true);
                this.on_click_outside();
            } else {
                this.$data.val = v;
                this.$data.force_close = false;
                if (!v) {
                    this.$data.changes_made = false;
                }
            }
        }
    },
    watch: {
        value(v) {
            this.check_changes(v);
        }
    }
};
</script>
