<template>
    <div v-bind="$attrs" v-on="$listeners" class="error--text main-error">
        <v-icon color="error" size="22">$vuetifu.icons.exclamation_circled</v-icon>
        <span class="my-auto w-subtitle-2 main-error-text">
            <slot>{{ l.t('app.form-invalid-top-title', 'To continue, please address any issues found below.')}}</slot>
        </span>
    </div>
</template>
<script>
import l from '../../../libs/lang';

export default {
    data() {
        return {
            l,
        };
},
};
</script>

<style lang="scss" scoped>
.main-error {
    margin-top: 30px;
    margin-bottom: 30px;
    .main-error-text {
        margin-left: 5px;
    }
}
</style>
