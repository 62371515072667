export function numbers(state = [], action) {
	let numberIndex = -1
	switch (action.type) {
		case 'SET_NUMBERS':
			return [...action.numbers]
		case 'DELETE_NUMBER':
			numberIndex = state.findIndex(n => n.id === action.numberId)
			if (numberIndex === -1) return state
			state.splice(numberIndex, 1)
			return [...state]
		default:
			return state
	}
}

export function newNumbers(state = [], action) {
	let newNumberIndex = -1
	switch (action.type) {
		case 'SET_NEW_NUMBERS':
			return [...action.newNumbers]
		case 'DELETE_NEW_NUMBER':
			newNumberIndex = state.findIndex(n => n.id === action.newNumberId)
			if (newNumberIndex === -1) return state
			state.splice(newNumberIndex, 1)
			return [...state]
		default:
			return state
	}
}