/* eslint-disable quote-props */
import { getValue } from 'remote-config-value'
import { connect } from 'react-redux'
import { resetPhoneNumbers } from '../../actions/phone-numbers'
import { AccountInfoIcon, LoaStepIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import checklistIcon from './checklist.svg'
import IntroVideoStep from './IntroVideoStep.js'
import ChecklistStep from './ChecklistStep.js'
import NumberAssignmentStep from './NumberAssignmentStep'
import AccountInformationStep from './AccountInformationStep'
import LOAStep from './LOAStep.js'
import numberinfoIcon from './numberinfo.svg'
import NumberInfoStep from './NumberInfoStep.js'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import StepWizard from 'step-wizard'
import styles from './styles'
import Typography from 'typography'
import NumberAssignmentIcon from './icons/NumberAssignmentIcon'
import RemoteConfigValue from 'remote-config-value'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import PhoneComUser from 'phone-com-user'
import api from '../../util/api_v5.js'
import Prompt from 'pdc-prompt'

const mapStateToProps = state => ({
    phoneNumbers: state.phoneNumbers
})

const mapDispatchToProps = dispatch => ({
    resetPhoneNumbers: () => dispatch(resetPhoneNumbers())
})

class PlaceholderStep extends Component {
    componentDidUpdate = (prevProps) => {
        const hasSaveRequest = this.props.saveId && (prevProps.saveId !== this.props.saveId)
        if (hasSaveRequest) {
            this.props.update({
                completed: true,
                gotoNext: true,
                saveData: {
                }
            })
        }
    }

    render () {
        return (<div>Placeholder Step</div>)
    }
}
class TransferMyNumber extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isLoading: false,
            videoWatched: false,
            pdcPromptOpen: false,
            pdcPromptMessage: null
        }
        const { classes } = this.props
        const loaNavText = getValue('transfer_number_ea_bottom_nav_text')
        const checklistNavMessage = (
            <div>
                <Typography variant='body3'>
                    <b><RemoteConfigValue valueId='transfer_number_intro_video_bottom_navigation_text_1_bold'/></b>
                </Typography>
                <Typography variant='body3'>
                    <RemoteConfigValue valueId='transfer_number_intro_video_bottom_navigation_text_2'/> <a data-test-id='transfer-process-tutorial-link' className={classes.phoneComLink} target='_blank' rel='noreferrer noopener' href={getValue('transfer_number_checklist_rejection_warning_link_href')}><RemoteConfigValue valueId='transfer_number_checklist_rejection_warning_link_text'/></a>
                </Typography>
            </div>
        )
        const numberAssignmentNavMessage = (
            <Typography variant='body3'>
                <RemoteConfigValue valueId='transfer_number_number_assignment_bottom_navigation_text_1'/> <b><RemoteConfigValue valueId='transfer_number_number_assignment_bottom_navigation_text_2_bold'/></b>
            </Typography>
        )
        const numberInfoDesc = (
            <Typography variant='body3' remoteConfigID='transfer_number_number_info_footer'/>
        )
        this.steps = [
            {
                id: 'checklist',
                title: (<div data-test-id='tw-primary-title-checklist' className='title-wrapper'><img src={checklistIcon}/>Checklist</div>),
                secondaryTitle: <RemoteConfigValue valueId='transfer_number_checklist_preamble'/>,
                label: 'Checklist',
                secondaryLabel: '',
                component: ChecklistStep,
                bottomNavigationText: checklistNavMessage,
                'data-test-id': 'tw-step-1-checklist'
            },
            {
                id: 'numberInfo',
                title: (<div data-test-id='tw-primary-title-number-information' className='title-wrapper'><img style={{ paddingRight: '10px' }} src={numberinfoIcon}/>Number Information</div>),
                secondaryTitle: (<Typography variant='body2' remoteConfigID='transfer_number_number_info_secondary_title'/>),
                label: 'Number information',
                description: numberInfoDesc,
                component: NumberInfoStep,
                bottomNavigationText: numberInfoDesc,
                'data-test-id': 'tw-step-2-number-information'
            },
            {
                id: 'accountInfo',
                title: (<div data-test-id='tw-primary-title-account-information' className='title-wrapper'><AccountInfoIcon/><Typography variant='h4' remoteConfigID='tw_account_information_step_title'/></div>),
                secondaryTitle: (<Typography variant='body2' remoteConfigID='tw_account_information_step_subtitle'/>),
                label: 'Account information',
                component: AccountInformationStep,
                additionalProps: { form: this.state.form, name: 'accountInfo', ...props },
                bottomNavigationText: <><RemoteConfigValue valueId='tw_account_information_step_footer'/></>,
                'data-test-id': 'tw-step-3-account-information'
            },
            {
                id: 'numberAssignment',
                title: <div data-test-id='tw-primary-title-number-assignment' className='title-wrapper'><NumberAssignmentIcon/> <RemoteConfigValue valueId='transfer_number_number_assignment_title'/></div>,
                secondaryTitle: <RemoteConfigValue valueId='transfer_number_number_assignment_subtitle'/>,
                label: 'Number assignment',
                secondaryLabel: '',
                component: NumberAssignmentStep,
                bottomNavigationText: numberAssignmentNavMessage,
                'data-test-id': 'tw-step-4-number-assignment'
            },
            {
                id: 'LOA',
                label: 'Electronic authorization',
                title: <div data-test-id='tw-primary-title-letter-of-authorization' className='title-wrapper'><LoaStepIcon/> <RemoteConfigValue valueId='transfer_number_ea_title'/></div>,
                secondaryTitle: <RemoteConfigValue valueId='transfer_number_loa_subtitle'/>,
                name: 'EA',
                component: LOAStep,
                bottomNavigationText: loaNavText,
                disableNextButton: () => true,
                'data-test-id': 'tw-step-5-letter-of-authorization'
            }

        ]
    }

    handlePromptError = (message) => {
        this.setState({ pdcPromptOpen: true, pdcPromptMessage: message })
    }

    handlePdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null })

    onVideoSubmit = () => {
        this.setState({ videoWatched: true })
    }

    onSubmit = async (stepsSaveData) => {
        this.setState({ isLoading: true })
        let billUploadError = false
        const voipId = PhoneComUser.getAPIAccountId()
        // Upload COBs
        // Each COB must be uploaded individually
        if (stepsSaveData.numberInfo.latestBill) {
            const readFileAsync = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = async () => {
                        resolve(reader.result)
                    }
                    reader.onerror = reject
                })
            }
            const latestBill = stepsSaveData.numberInfo.latestBill
            const base64String = await readFileAsync(latestBill)
            for (const number of stepsSaveData.numberInfo?.phoneNumbers) {
                const res = await api.uploadFile(voipId, base64String, `COB/${number}`)
                console.log(res)
                if (res?.error) {
                    billUploadError = true
                    break
                }
            }
        }

        if (stepsSaveData.numberInfo.customerServiceRecord) {
            const readFileAsync = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = async () => {
                        resolve(reader.result)
                    }
                    reader.onerror = reject
                })
            }
            const customerServiceRecord = stepsSaveData.numberInfo.customerServiceRecord
            const base64String = await readFileAsync(customerServiceRecord)
            for (const number of stepsSaveData.numberInfo?.phoneNumbers) {
                const res = await api.uploadFile(voipId, base64String, `CSR/${number}`)
                console.log(res)
                if (res?.error) {
                    billUploadError = true
                    break
                }
            }
        }

        if (billUploadError) {
            this.handlePromptError('Something went wrong while uploading your latest bill. Please try again. If the error continues, contact us by clicking on Help on the left navigation bar.')
            this.setState({ isLoading: false })
            this.props.setBusy(false)
            return
        }

        // Create port transfer request
        console.log('Submitting form')
        const data = this.formatSaveDataPayload(stepsSaveData)
        const res = await api.createPortRequest(voipId, data)
        console.log(res)
        this.props.setBusy(false)

        if (res?.error) {
            this.handlePromptError('Something went wrong while submitting your transfer request. Please try again. If the error continues, contact us by clicking on Help on the left navigation bar.')
        } else {
            this.props.setTransferWizardComplete()
            this.props.setRedirectPath('transfer-requests/tw-complete')
        }
        this.props.resetPhoneNumbers()
        this.setState({ isLoading: false })
    }

    formatSaveDataPayload = (data) => {
        const formattedData = {
            'checklist': {
                'checked': Boolean(data?.checklist?.checked)
            },
            'number_info': {
                'provider': data.numberInfo?.provider,
                'provider_name': data.numberInfo?.providerName,
                'phone_numbers': data.numberInfo?.phoneNumbers,
                'completion_date': data.numberInfo?.completionDate
            },
            'account_info': {
                'is_business_number': Boolean(data.accountInfo?.isBusinessNumber),
                'company_name': data.accountInfo?.companyName,
                'first_name': data.accountInfo?.firstName,
                'last_name': data.accountInfo?.lastName,
                'address1': data.accountInfo?.address,
                'address2': data.accountInfo?.address2,
                'city': data.accountInfo?.city,
                'state': data.accountInfo?.state,
                'zipcode': data.accountInfo?.zip,
                'country': data.accountInfo?.country,
                'mobile': Boolean(data.accountInfo?.wirelessNumber),
                'account_number': data.accountInfo?.accountNumber,
                'account_pin': data.accountInfo?.accountPin
            },
            'number_assignment': {},
            'loa': {
                'checked': Boolean(data?.LOA?.iAccept)
            },
            'skip_auto_assign': false
        }
        // eslint-disable-next-line dot-notation
        formattedData['number_assignment']['assignment_list'] = data.numberAssignment?.assignmentList.map(item => {
            return {
                'number_to_transfer': item.numberToTransfer,
                'temporary_number': item.assignedValue
            }
        })
        return formattedData
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull
                    styles={{ loaderFull: { left: this.props.screenViewType.isMobileView || this.props.screenViewType.isTabletView ? '50%' : 'calc(50% + 120px)' } }}
                    text='Please wait...'
                    color={theme.palette.secondary.main} size='bigger'/>
            </div>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <>
                {this.state.isLoading ? this.renderLoader() : null}
                {this.state.videoWatched
                    ? (
                        <StepWizard
                            className = {`${classes.stepWizard} ${this.props.smallView ? 'mobile' : ''}`}
                            steps = {this.steps}
                            smallView = {this.props.smallView}
                            onSubmit = {this.onSubmit}
                            disallowCompleteGaps = {true}
                            extraData={{ setBusy: this.props.setBusy }}
                            enableBackButtonOnFirstStep = {false}
                        />)
                    : (
                        <IntroVideoStep
                            onNextClick={this.onVideoSubmit}
                        />
                    )
                }
                <Prompt isOpen={this.state.pdcPromptOpen} color='attention' position='bottom' onClose={this.handlePdcPromptClose} content={this.state.pdcPromptMessage} />
            </>
        )
    }
}

TransferMyNumber.propTypes = {
    classes: PropTypes.object,
    phoneNumbers: PropTypes.array,
    users: PropTypes.array,
    resetPhoneNumbers: PropTypes.func,
    screenViewType: PropTypes.object,
    smallView: PropTypes.string,
    setBusy: PropTypes.func,
    setRedirectPath: PropTypes.func,
    setTransferWizardComplete: PropTypes.func
}

PlaceholderStep.propTypes = {
    saveId: PropTypes.string,
    update: PropTypes.func,
    passData: PropTypes.object,
    setBusy: PropTypes.func
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TransferMyNumber))
