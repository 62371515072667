export function setPhones(phones) {
	return {
		type: 'SET_PHONES',
		phones
	}
}
export function updatePhone(phone) {
	return {
		type: 'UPDATE_PHONE',
		phone
	}
}
export function addPhone(phone) {
	return {
        type: 'ADD_PHONE',
        phone
	}
}
export function deletePhone(phoneId) {
	return {
		type: 'DELETE_PHONE',
		phoneId
	}
}
