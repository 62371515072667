const styles = theme => ({
	settingWrapper:	{
		...theme.personalSettingsApp.settingWrapper,
		flexDirection: 'column'
	},
	loadingDiv:		theme.loadingDiv,
	section: {
		marginBottom:	30,
		width:			400,
		'&:not(:first-child)': {
			marginTop: 30
		}
	},
	question: {
		fontSize:		16,
		fontWeight:		'bold',
		marginBottom:	10,
		color:			'gray',
		'& .em': {
			color: 'black'
		}
	},
	optionItem: {
		display:		'flex',
		alignItems:		'center',
		// justifyContent:	'space-between',
		'& .left-part': {
			display:	'flex',
			alignItems:	'center',
			'& svg': {
				marginRight: 10
			}
		},
		'& .right-part': {
			display:	'flex',
			alignItems:	'center'
		}
	},
	removeButton: {
		cursor:		'pointer',
		marginLeft:	10,
		color:		'#ff000077',
		'&:hover': {
			color:	'#ff0000'
		}
	},
	addButtonWrapper: {
		display:	'inline-block',
		marginTop:	10
	},
	addButton: {
		display: 'flex'
	},
	menuOptionSelect: {
		width: 100
	}
})

export default styles