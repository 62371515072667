<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7887_19433)">
<path d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04Z" fill="currentColor"/>
<path d="M10 9V12.5H7.65149C7.42237 12.5 7.31394 12.7826 7.48425 12.9358L11.8328 16.8495C11.9278 16.9351 12.0722 16.9351 12.1672 16.8495L16.5158 12.9358C16.6861 12.7825 16.5776 12.5 16.3485 12.5H14V9C14 8.72386 13.7761 8.5 13.5 8.5H10.5C10.2239 8.5 10 8.72386 10 9Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_7887_19433">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
  export default {};
</script>
