<template>
    <w-radio-group
        v-bind="$attrs"
        v-on="$listeners"
        v-model="selected"
        class="card-selector"
        hide-details="auto"
        data-test-id="card-selector"
    >
        <div v-for="(card, i) in cards" :class="{active_card: selected === card.id}">
            <Card @click="on_card_click(card)" :card="card" class="cursor-pointer"/>
            <v-radio
                color="secondary"
                class="cc-radio mt-3 mb-5"
                type="radio"
                :ref="`radio-${card.id}`"
                :value="card.id"
                :key="card.id"
                :data-test-id="`payment-methods-card-picker-${i}-radio`"
            >
                <template v-slot:label>
                    <span class="w-body-2">{{l.t('app.use-this-card', 'Use this card')}}</span>
                </template>
            </v-radio>
        </div>
    </w-radio-group>
</template>
<script>
import l from '../../libs/lang';
import Card from './CreditCard.vue';

export default {
    props: {
        cards: {
            type: Array,
            requried: true,
        },
        value: {
            type: Number,
            required: false,
        }
    },
    components: {
        Card,
    },
    data() {
        return {
            l,
            selected: null,
        };
    },
    created() {
        this.$data.selected = this.$props.value;
    },
    methods: {
        on_card_click(card) {
            if (card && card.id) {
                this.$data.selected = card.id;
                this.$refs[`radio-${card.id}`][0].$el.click();
            }
        },
    },
    watch: {
        selected(val) {
            this.$emit('input', val);
        },
    }
};
</script>

<style lang="scss">
.card-selector {
    .active_card .credit-card-wrapper {
        border: 2px solid var(--v-text-lighten1) !important;
    }
    .cc-radio:not(:last-child) {
    }
}
</style>
