import React, { useState, createContext, useEffect } from 'react'
import MuiThemeProvider from '@material-ui/styles/ThemeProvider'
import { Theme } from '@material-ui/core/styles'
import { theme as getThemed } from 'get-theme'
import { createMuiTheme } from '@material-ui/core/styles'
import { getValue } from 'firebase-utils'
const _ = require('lodash'); // eslint-disable-line

/**
 *
 */
export const ThemeContext = createContext({})

/**
 *
 */

interface ThemeOverride extends Theme {
    isPdcTheme: boolean
}
/**
 *
 */
export interface ThemeProviderProps {
    /**
     * theme being passed down for app being used, should not need to use
     */
    theme?: ThemeOverride
    /**
     * dom children in jsx. do not need to use. wrap your components
     */
    children: React.ReactNode
}

/**
 *
 */
export const ThemeProvider = (props: ThemeProviderProps): React.ReactElement => {
    const defaultTheme = props.theme?.isPdcTheme ? props.theme : getThemed
    const [theme, setTheme] = useState(defaultTheme)
    const [themeOverride, setThemeOverride] = useState('{}')

    const parsedDefaultTheme = JSON.parse(JSON.stringify(defaultTheme))
    const overrideTheme = (remoteTheme: any) => {
        const parsedRemoteTheme = JSON.parse(remoteTheme)
        const parsedUpdatedTheme = _.merge(parsedDefaultTheme, parsedRemoteTheme)
        const updatedTheme = createMuiTheme(parsedUpdatedTheme)
        if (updatedTheme !== theme && updatedTheme !== props.theme) setTheme(updatedTheme)
    }
    useEffect(() => {
        getValue('branded_theme_override', setThemeOverride)
    }, [])

    useEffect(() => {
        overrideTheme(themeOverride)
    }, [themeOverride])

    return (
        <ThemeContext.Provider value={theme}>
            <MuiThemeProvider theme={theme}>
                {props.children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    )
}

/**
 * get-theme wrapper
 */
export function getTheme () : any { return getThemed }
