<template>
    <div v-if="!['avo', 'avo_master'].includes($branding.data.code)" class="d-flex justify-end">
        <w-btn
            v-bind="$attrs"
            v-on="$listeners"
            @click="on_click"
            class="call-expert-btn"
            :color="!clicked ? 'rgba(170, 215, 226, 0.35)' : 'white'"
            :class="!clicked ? 'info--text text--darken-1' : 'warning--text text--darken-1 pl-0'"
            :ripple="false"
        >
            <template v-if="!clicked">
                <v-icon size="18" class="lr-expert-call-btn-icon">$vuetify.icons.on_call</v-icon>
                {{$lang.t('lr.have-an-expert-call-me-and-tell-me-more', 'Have an expert call me and tell me more')}}!
            </template>
            <template v-else>
                <v-icon size="22" color="warning" class="lr-expert-call-btn-icon">$vuetify.icons.phone_com</v-icon>
                {{$lang.t('lr.thank-you-we-ll-be-in-touch-with-you-shortly', 'Thank you! We’ll be in touch with you shortly')}}.
            </template>
        </w-btn>
    </div>
</template>
<script>
import {submitHubspotForm} from 'helpers';

export default {
    data() {
      return {
        clicked: false,
      };
    },
    methods: {
        async on_click() {
            if (this.$data.clicked) return null;
            try {
                const FORM_ID = '66f85af8-c2e5-4229-9e3d-37f15107ae26';
                await submitHubspotForm(this.$session.user.account, FORM_ID);
                this.$data.clicked = true;
            } catch (err) {
                console.log(err);
                this.$data.clicked = false;
            }
            return true;
        }
    }
};
</script>
<style>
.call-expert-btn {
    border-radius: 5px 3px 15px 15px !important;
    background: var(--info-background, rgba(170, 215, 226, 0.35));
    margin-right: 15px;
    text-align: right;
    margin-right: 0px;
}
.lr-expert-call-btn-icon {
    margin-right: 6px;
}
</style>
