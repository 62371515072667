/* eslint-disable jsdoc/require-description */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React, { createContext, Component } from 'react'
import { ContactConsumer, TeammateConsumer, ExtensionConsumer } from 'providers'
import { MyPhoneContact, Contacts, Teammates, Extensions, Contact } from 'models'
import ExtensionProvider from './ExtensionProvider'
import TeammateProvider from './TeammateProvider'
import ContactProvider from './ContactProvider'
import { formatPhoneNumber } from 'phone-numbers'

/**
 *
 */
export interface Cursor {
    total: number;
    queues: MyPhoneContacts[];
}
/**
 *
 */
export class MyPhoneContacts {
    items: MyPhoneContact[] = []
    hasMore: boolean
    total: number
    cursor: Cursor
    source: string
    sourceCursor: string
    /**
     * @param items
     * @param hasMore
     * @param total
     * @param cursor
     * @param source
     * @param sourceCursor
     */
    constructor (items: MyPhoneContact[],
        hasMore: boolean,
        total: number,
        cursor: Cursor,
        source: string,
        sourceCursor: string) {
        this.items = items
        this.hasMore = hasMore
        this.total = total
        this.cursor = cursor
        this.source = source
        this.sourceCursor = sourceCursor
    }

    /**
     * @param contacts
     */
    static fromContacts (contacts: Contacts): MyPhoneContacts {
        const items = []
        contacts.items.forEach((v) => {
            items.push(MyPhoneContact.fromContact(v))
        })
        const mpc = new MyPhoneContacts(items, contacts.hasMore, items.length, null, 'contacts', contacts.cursor)
        return mpc
    }

    /**
     * @param teammates
     */
    static fromTeammates (teammates: Teammates): MyPhoneContacts {
        const items = []
        teammates.items.forEach((v) => {
            items.push(MyPhoneContact.fromTeammate(v))
        })
        const mpc = new MyPhoneContacts(items, teammates.hasMore, items.length, null, 'teammates', teammates.cursor)
        return mpc
    }

    /**
     * @param extensions
     */
    static fromExtensions (extensions: Extensions): MyPhoneContacts {
        const items = []
        extensions.items.forEach((v) => {
            items.push(MyPhoneContact.fromExtension(v))
        })
        const mpc = new MyPhoneContacts(items, extensions.hasMore, items.length, null, 'extensions', extensions.cursor)
        return mpc
    }
}

/**
 *
 */
export class MyPhoneContactProviderWrapper extends Component<any, any> {
    /**
     *
     */
    render () {
        return (<>
            <ExtensionProvider>
                <ExtensionConsumer>
                    {(extensions: any) => (
                        <TeammateProvider>
                            <TeammateConsumer>
                                {(teammates: any) => (
                                    <ContactProvider>
                                        <ContactConsumer>
                                            {(contacts: any) => (
                                                <MyPhoneContactProviderComponent
                                                    extensions={extensions}
                                                    teammates={teammates}
                                                    contacts={contacts}
                                                >
                                                    {this.props.children}
                                                </MyPhoneContactProviderComponent>
                                            )}
                                        </ContactConsumer>
                                    </ContactProvider>
                                )}
                            </TeammateConsumer>
                        </TeammateProvider>
                    )}
                </ExtensionConsumer>
            </ExtensionProvider>
        </>)
    }
}

interface Props {
    contacts: any;
    teammates: any;
    extensions: any;
    children: React.ReactNode | undefined
}
interface State {
    extensionsLoaded: boolean;
    teammatesLoaded: boolean;
}

/**
 *
 */
export const MyPhoneContactContext = createContext<MyPhoneContactContextProps>({})
/**
 *
 */
export const MyPhoneContactConsumer = MyPhoneContactContext.Consumer

/**
 *
 */
export class MyPhoneContactProviderComponent extends Component<Props, State> {
    /**
     *
     */
    constructor (props: Props) {
        super(props)
        this.state = {
            extensionsLoaded: false,
            teammatesLoaded: false
        }
    }

    /**
     *
     */
    componentDidUpdate (prevProps: Props) {
        if (!this.state.teammatesLoaded) {
            if (this.props.teammates.loaded) {
                this.setState({ teammatesLoaded: true })
            }
        }
        if (!this.state.extensionsLoaded) {
            if (this.props.extensions.loaded) {
                this.setState({ extensionsLoaded: true })
            }
        }
    }

    /**
     *
     */
    encodeCursor (total, queues): Cursor {
        return { total, queues }
    }

    /**
     *
     */
    settleAllProviders = async (promiseArray: Promise<MyPhoneContacts>[]) => {
        return Promise.allSettled(promiseArray).then((values) => {
            const queues = []
            values.forEach((v, index) => {
                if (v.status === 'fulfilled') {
                    queues.push(v.value)
                } else {
                    //
                }
            })
            return queues
        })
    }

    // list & next
    /**
     *
     */
    list = async (filter = ''): Promise<MyPhoneContacts> => {
        // THIS CODE DOES NOT HANDLE THE COMPONENT BEING UNLOADED!!!!!!
        // THIS NEEDS TO BE AWARE OF ACTIVE REQUESTS AND REFUSE TO DO ANYTHING DURING THAT!!!!!!!
        // we need to track this so we can also cancel it
        const promiseArray = [this.listContacts(filter), this.listExtensions(filter), this.listTeammates(filter)]
        return this.settleAllProviders(promiseArray).then(queues => {
            const items = this.mergeLists(queues)
            // summed total
            let total = 0
            queues.forEach(q => { total += q.total })
            const hasMore = queues.some(q => q.hasMore)
            // Cursor struct holds queues and filter.
            const cursor: Cursor = this.encodeCursor(total, queues)
            // build returned contacts
            const ret = new MyPhoneContacts(
                items,
                hasMore,
                total,
                cursor,
                'merged',
                ''
            )
            return ret
        })
    }

    /**
     *
     */
    next = async (cursor: Cursor): Promise<MyPhoneContacts> => {
        const queues = cursor.queues
        const total = cursor.total
        const a = []
        queues.forEach(q => {
            if (q.items.length > 0) {
                a.push(q)
            } else if (q.items.length === 0 && q.hasMore) {
                switch (q.source) {
                        case 'contacts':
                            a.push(this.nextContacts(q.sourceCursor))
                            break
                        case 'teammates':
                        // a.push(this.nextTeammates(q.sourceCursor))
                            break
                        case 'extensions':
                        // a.push(this.nextExtensions(q.sourceCursor))
                            break
                        default:
                            break
                }
            }
        })
        const results = this.settleAllProviders(a).then(
            queues => {
                queues = queues.filter(q => q.items.length > 0)
                const items = this.mergeLists(queues)
                let hasMore = false
                queues.forEach(q => {
                    hasMore = hasMore || q.hasMore
                })
                // Cursor struct holds queues and filter.
                const cursor: Cursor = this.encodeCursor(total, queues)
                // build returned contacts
                const ret = new MyPhoneContacts(
                    items,
                    hasMore,
                    total,
                    cursor,
                    'merged',
                    ''
                )
                return ret
            }
        )
        return results
    }

    findExactMatch = async (number: string): Promise<MyPhoneContact> => {
        let match = null
        await this.list(number).then(res => {
            res.items.forEach(c => {
                if (c.hasNumber(number)) match = c
            })
            if (match === null && res.hasMore) {
                this.next(res.cursor).then(nres => {
                    nres.items.forEach(c => {
                        if (c.hasNumber(number)) match = c
                    })
                })
            }
        })
        return match
    }

    // providers' list & next fnc wrappers
    /**
     *
     */
    listContacts = async (filter: string): Promise<MyPhoneContacts> => {
        // filter will be used later to show inboxes
        const encodedFilter = ContactProvider.encodeFilter(filter, null)
        return this.props.contacts.list(encodedFilter).then(res => {
            return MyPhoneContacts.fromContacts(res)
        })
    }

    /**
     *
     */
    listTeammates = async (filter: string): Promise<MyPhoneContacts> => {
        return this.props.teammates.list(filter).then(res => {
            return MyPhoneContacts.fromTeammates(res)
        })
    }

    /**
     *
     */
    listExtensions = async (filter: string): Promise<MyPhoneContacts> => {
        return this.props.extensions.list(filter).then(res => {
            return MyPhoneContacts.fromExtensions(res)
        })
    }

    /**
     *
     */
    nextContacts = async (cursor: string): Promise<MyPhoneContacts> => {
        return this.props.contacts.next(cursor).then(res => {
            return MyPhoneContacts.fromContacts(res)
        })
    }

    /**
     *
     */
    nextTeammates = async (cursor: string): Promise<MyPhoneContacts> => {
        return this.props.teammates.next(cursor).then(res => {
            return MyPhoneContacts.fromTeammates(res)
        })
    }

    /**
     *
     */
    nextExtensions = async (cursor: string): Promise<MyPhoneContacts> => {
        return this.props.extensions.next(cursor).then(res => {
            return MyPhoneContacts.fromExtensions(res)
        })
    }

    /**
     *
     */
    mergeLists = (queues: MyPhoneContacts[]): MyPhoneContact[] => {
        const items: MyPhoneContact[] = []
        let exhausted = false
        while (!exhausted) {
            const compareArray = []
            queues.forEach((q, i) => {
                if (q.items.length > 0) {
                    compareArray.push({ item: q.items[0], index: i })
                }
            })
            let nextContact: MyPhoneContact = null
            let queueIndex = -1
            compareArray.forEach((c) => {
                if ((nextContact &&
                    c.item.displayName.toLocaleLowerCase() <= nextContact.displayName.toLocaleLowerCase()) ||
                    nextContact === null) {
                    nextContact = c.item
                    queueIndex = c.index
                }
            })
            // Found the next item, pop and update exhausted
            if (queueIndex >= 0) {
                const queue = queues[queueIndex]
                const item = queue.items.shift()
                item.__type = item.source
                items.push(item)
                if (queue.items.length === 0) {
                    // if has more update exhausted
                    if (queue.hasMore) {
                        exhausted = false
                    } else {
                        // else remove queue
                        queues = queues.splice(queueIndex, 1)
                    }
                }
            } else {
                exhausted = true
            }
        }
        return items
    };
    /// ////////////////////////////////////////

    /**
     *
     */
    getCacheId = (ct: string, c: Contact) => {
        return `${ct}@${c.id}`
    }

    /**
     *
     */
    getDisplayName = async (value: any): Promise<string> => {
        if (typeof (value) !== 'string') value = value.toString()
        const number = value.replace(/[^\d]/g, '')
        if (number.length > 0) {
            const contact = await this.findExactMatch(number)
            if (contact) return contact.displayName
            /*
             * This entire block can be simplified with the above two lines.
             * The search for contacts by phone number has been improved.
             * mapping display names to numbers is no longer required.
            value = formatPhoneNumber(value)
            if (number.length === 11 && number[0] === '1') number = number.substring(1)
            const num = parseInt(number)
            // console.log(num)
            // console.log(this.state.displayNames)
            if (this.state.extensionNames.has(num)) { return this.state.extensionNames.get(num) }
            if (this.state.displayNames.has(num)) {
                const cname = this.state.displayNames.get(num)
                return cname
            } else {
                await this.mapContacts(number)
                if (this.state.displayNames.has(num)) {
                    const cname = this.state.displayNames.get(num)
                    return cname
                } else {
                    value = formatPhoneNumber(value)
                    this.mapNumberToName(number, value)
                }
            } */
        }
        return formatPhoneNumber(value)
    }

    // Contact utils
    /**
     *
     */
    updateContact = async (contact: Contact): Promise<Contact> => {
        return this.props.contacts.update(contact)
    }

    // TODO update params to take Contact object
    /**
     *
     */
    deleteContact = async (contactId: number): Promise<void> => {
        const contacts = await this.props.contacts.list()
        if (!contacts.items.has(contactId)) return
        this.props.contacts.deleteContact(contactId).then(success => {
            if (success) {
                contacts.items.remove(contactId)
                contacts.total -= 1
            }
        })
    }

    /**
     *
     */
    isValidExtensionNumber = (number: string): boolean => {
        number = number.replace(/[^\d]/g, '')
        const num = parseInt(number)
        return this.props.extensions.hasExtension(num) || this.props.teammates.hasExtension(num)
    }

    /**
     *
     */
    render () {
        const { list, next, getDisplayName, deleteContact, updateContact, isValidExtensionNumber, findExactMatch } = this
        return (<>
            <MyPhoneContactContext.Provider value={{
                getDisplayName,
                deleteContact,
                updateContact,
                isValidExtensionNumber,
                list,
                next,
                findExactMatch
            } as MyPhoneContactContextProps}>
                {this.props.children}
            </MyPhoneContactContext.Provider>
        </>)
    }
}

/**
 *
 */
export interface MyPhoneContactContextProps {
    getDisplayName: (value: string | number) => Promise<string>;
    deleteContact: (contactId: number) => Promise<void>;
    updateContact: (contact: Contact) => Promise<Contact>;
    isValidExtensionNumber: (number: string) => boolean;
    findExactMatch: (number: string) => Promise<MyPhoneContact>
    list: (filter: string) => Promise<MyPhoneContacts>;
    next: (cursor: Cursor) => Promise<MyPhoneContacts>;
}

/**
 *
 */
export const MyPhoneContactProvider = MyPhoneContactProviderWrapper
