<template>
    <DefaultModal
        v-bind="$attrs"
        v-on="$listeners"
        :persistent="loading"
        :width="400"
        data-test-id="lr-delete-script-modal"
    >
        <template v-slot:title>
            <slot name="title" />
        </template>

        <w-alert v-if="alert" :message="alert.message" :level="alert.level" class="my-5"/>
        <w-loader v-if="loading" size="small" class="ma-auto" />

        <div v-else>
            <div v-if="step === 'show-confirmation'">
                <TypeConfirmation
                    v-model="typingConfirmation"
                    :phrase="phrase"
                    @keyup.enter="on_enter"
                    class="mb-5"
                />
                {{ $lang.t('lr.deactivation-modal-help-text', 'Your receptionist will stop answering. Be sure you have an alternative call handling rule in place.') }}
                <slot name="question" />
            </div>

            <div v-else-if="step === 'in-use'" class="w-body-2">
                {{$lang.t('app.used-as-routing-item', 'This script is being used as routing item in the following resources:')}}
                <div v-for="usg of usage" :key="usg.object.id">
                    <w-link v-if="usg.object_type === 'route'" target="_blank" :to="{name: 'routes.show', params: {id: usg.object.id}}">
                        {{usg.object.name || `#${usg.object.id}`}}
                    </w-link>
                    <w-link v-else-if="usg.object_type === 'extension'" target="_blank" :to="{name: 'extensions.show', params: {id: usg.object.id}}">
                        {{usg.object.name || `#${usg.object.id}`}}
                    </w-link>
                </div>
            </div>
        </div>
        <template v-if="!loading && (!alert || (alert && alert.level !== 'success'))" v-slot:buttons>
            <w-btn @click="$emit('input', false)" color="secondary" data-test-id="confirmation-modal-cancel-btn">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
            <w-btn v-if="step === 'show-confirmation'" @click="on_submit" :disabled="!typingConfirmation" color="error base text--darken-1" data-test-id="confirmation-modal-confirm-btn">{{btn_text}}</w-btn>
            <w-btn v-if="step === 'in-use'" @click="on_proceed" color="error base text--darken-1" data-test-id="confirmation-modal-confirm-btn">{{$lang.t('app.proceed', 'Proceed')}}</w-btn>
        </template>
    </DefaultModal>
</template>
<script>
import lang from 'lang';
import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
import TypeConfirmation from '../../../console/src/components/elements/TypeConfirmation.vue';

export default {
    props: {
        phrase: String,
        btn_text: String,
        success_message: {
            type: String,
            default: lang.t('app.successful-operation', 'successful operation')
        },
        callback: {
            type: Function,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        model: {
            type: Object,
            required: true,
            validator: (c) => c.constructor.name === 'LiveAnswer'
        },
    },
	components: {
        DefaultModal,
        TypeConfirmation,
    },
    data() {
        return {
            loading: false,
            usage: null,
            typingConfirmation: false,
            alert: null,
            step: null,
        }
    },
    async created() {
        this.$data.loading = true;
        try {
            const usage = await this.$props.model.getUsage(this.$props.item.id);
            this.$data.usage = usage.items;
            this.$data.step = this.$data.usage.length ? 'in-use' : 'show-confirmation'
        } catch (err) {
            this.$data.alert = this.$props.model.alert;
        }
        this.$data.loading = false;
    },
    methods: {
        async on_submit() {
            if (typeof this.$props.callback !== 'function') throw new Error('Invalid callback');
            this.$data.loading = true;
            if (this.$data.usage.length) await this.model.remove_script_from_routes(this.usage.map((x) => x.object.id), this.$props.item.id);
            await this.$props.callback(this.$props.item.id);
            if (!this.$props.model.alert || (this.$props.model.alert && this.$props.model.alert.level === 'success')) {
                this.$data.alert = {level: 'success', message: this.$props.success_message}
                this.$emit('processed', this.$props.item.id)
                this.$emit('input', false)
            } else {
                this.$data.alert = this.$props.model.alert;
                setTimeout(() => this.$data.alert = null, 5000);
            }
            this.$data.loading = false
        },
        async on_proceed() {
            this.$data.step = 'show-confirmation';
        },
        on_enter() {
            if (this.$data.typingConfirmation) this.on_submit(); 
        },
    }
};
</script>
