import React, { Component } from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CreditCardForm from './CreditCardForm'
import CreditCardCurrentPrimary from './CreditCardCurrentPrimary'
import Spinner from 'spinner'
import api from './api_v5'
import Button from 'button'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	mainWrapper: {
		maxHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
		'&:not(.small-view)': {
			padding: '10px 20px',
			maxWidth: 340,
			boxShadow: '0px 0px 6px gray'
		}
	},
	spinnerWrapper: {
		textAlign: 'center',
		padding: '20px 0px 20px 0px',
	},
	buttonWrapper: {
		textAlign: 'center'
	},
	errorMessage: {
		color:			'#df1d08',
		margin:			'30px 45px 0',
		fontSize:		14,
		fontWeight:		'bold',
		lineHeight:		1.29,
		letterSpacing:	-0.1,
		textAlign:		'center'
	}
})


class CreditCard extends Component {

	state = {
		errorMessage:	'',
		complete:		false,
		loading:		false,
		currentPrimaryCard: null
	}

	componentDidMount() {
		this._mounted = true
		this.getCurrentPrimaryCard()
	}

	componentWillUnmount() {
		this._mounted = false
	}

	getCurrentPrimaryCard = async () => {
		if (this.props.currentPrimaryCard) return
		this.setState({loading: true})
		let response = await api.getCurrentPrimaryCard() // TODO check response
		if (!this._mounted) return
		this.setState({loading: false, currentPrimaryCard: response || null})
	}

	onSuccess = () => this.setState({complete: true})

	reset = () => {
		this.setState({
			errorMessage: '',
			complete: false,
		})
		this.getCurrentPrimaryCard()
	}

	render() {
		const { classes } = this.props

		let innerContent
		if (this.state.loading) {
			innerContent = <div className={`${classes.spinnerWrapper}`}><Spinner/></div>
		} else if (!this.state.complete) {
			innerContent = <div>
				<CreditCardCurrentPrimary card={this.state.currentPrimaryCard || {}} loading={this.state.loading}/>
				<Elements>
					<CreditCardForm onSuccess={this.onSuccess} />
				</Elements>
			</div>
		} else {
			innerContent = <div>
				<p>Your card has been updated successfully</p>
				<div className={`${classes.buttonWrapper}`}>
					<Button
						onClick={this.reset}
						data-button='ok-button'
						variant='outlined' 
						figma={true} 
						color='primary'
					>
						OK
					</Button>
				</div>
			</div>
		}
		return (
			<>
				<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
					<div className={`${classes.mainWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
						<StripeProvider apiKey={window.APP_CONFIG.stripePublicKey}>
							{innerContent}
						</StripeProvider>
					</div>
				</div>
			</>
		)
	}
}

export default withStyles(styles)(CreditCard)