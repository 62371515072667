<template>
	<v-alert
		v-bind="$attrs"
		v-on="$listeners"
		class="help-text w-body-2 has-icon"
		:class="{'': !!$attrs.icon}"
	    data-test-id="help-text"
	>
		<template v-slot:prepend>
			<v-icon size="30" color="warning lighten-1" class="prepend-icon">$vuetify.icons.infotip_hot</v-icon>
		</template>
		<div class="text--text">
		<slot></slot>
		</div>
	</v-alert>
</template>

<script>
export default {};
</script>
<style lang="scss">
.help-text.v-alert {
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;
		&.has-icon {
			padding-left: 0;
		}
		.prepend-icon {
			margin-right: 15px !important;
		}
		.v-alert__wrapper {
			align-items: start !important;
		}
	}
</style>
