<template>
	<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask id="path-1-inside-1_1000_24789" fill="white">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99968 18.6666C15.0623 18.6666 19.1663 14.5625 19.1663 9.49992C19.1663 4.43731 15.0623 0.333252 9.99968 0.333252C4.93706 0.333252 0.833008 4.43731 0.833008 9.49992C0.833008 14.5625 4.93706 18.6666 9.99968 18.6666ZM10 16.25C13.7279 16.25 16.75 13.2279 16.75 9.5C16.75 5.77208 13.7279 2.75 10 2.75C6.27208 2.75 3.25 5.77208 3.25 9.5C3.25 13.2279 6.27208 16.25 10 16.25Z"/>
	</mask>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99968 18.6666C15.0623 18.6666 19.1663 14.5625 19.1663 9.49992C19.1663 4.43731 15.0623 0.333252 9.99968 0.333252C4.93706 0.333252 0.833008 4.43731 0.833008 9.49992C0.833008 14.5625 4.93706 18.6666 9.99968 18.6666ZM10 16.25C13.7279 16.25 16.75 13.2279 16.75 9.5C16.75 5.77208 13.7279 2.75 10 2.75C6.27208 2.75 3.25 5.77208 3.25 9.5C3.25 13.2279 6.27208 16.25 10 16.25Z" fill="currentColor"/>
	<path d="M18.1663 9.49992C18.1663 14.0102 14.51 17.6666 9.99968 17.6666V19.6666C15.6146 19.6666 20.1663 15.1148 20.1663 9.49992H18.1663ZM9.99968 1.33325C14.51 1.33325 18.1663 4.98959 18.1663 9.49992H20.1663C20.1663 3.88502 15.6146 -0.666748 9.99968 -0.666748V1.33325ZM1.83301 9.49992C1.83301 4.98959 5.48935 1.33325 9.99968 1.33325V-0.666748C4.38478 -0.666748 -0.166992 3.88502 -0.166992 9.49992H1.83301ZM9.99968 17.6666C5.48935 17.6666 1.83301 14.0102 1.83301 9.49992H-0.166992C-0.166992 15.1148 4.38478 19.6666 9.99968 19.6666V17.6666ZM15.75 9.5C15.75 12.6756 13.1756 15.25 10 15.25V17.25C14.2802 17.25 17.75 13.7802 17.75 9.5H15.75ZM10 3.75C13.1756 3.75 15.75 6.32436 15.75 9.5H17.75C17.75 5.21979 14.2802 1.75 10 1.75V3.75ZM4.25 9.5C4.25 6.32436 6.82436 3.75 10 3.75V1.75C5.71979 1.75 2.25 5.21979 2.25 9.5H4.25ZM10 15.25C6.82436 15.25 4.25 12.6756 4.25 9.5H2.25C2.25 13.7802 5.71979 17.25 10 17.25V15.25Z" fill="currentColor" mask="url(#path-1-inside-1_1000_24789)"/>
	<path d="M10 3.5L10.954 8.93185L14.003 12.0073L9.79833 10.261C9.37722 10.0861 9.12775 9.64847 9.1918 9.19701L10 3.5Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
	</svg>

</template>

<script>
  export default {};
</script>
