<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 6.3335C2 4.67664 3.34315 3.3335 5 3.3335H19C20.6569 3.3335 22 4.67664 22 6.3335V15.0002C22 16.657 20.6569 18.0002 19 18.0002H18.9465C17.6675 18.0002 16.4071 18.3068 15.2711 18.8944L9.9011 21.672C9.40258 21.9299 8.9229 21.2971 9.30586 20.8867L10.4298 19.6825C11.0264 19.0433 10.5732 18.0002 9.69878 18.0002H5C3.34315 18.0002 2 16.657 2 15.0002V6.3335Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6668 7.33366C18.6668 6.96547 18.3684 6.66699 18.0002 6.66699H6.00016C5.63197 6.66699 5.3335 6.96547 5.3335 7.33366C5.3335 7.70185 5.63197 8.00033 6.00016 8.00033H18.0002C18.3684 8.00033 18.6668 7.70185 18.6668 7.33366ZM5.3335 10.6672C5.3335 10.299 5.63197 10.0005 6.00016 10.0005H18.0002C18.3684 10.0005 18.6668 10.299 18.6668 10.6672C18.6668 11.0353 18.3684 11.3338 18.0002 11.3338H6.00016C5.63197 11.3338 5.3335 11.0353 5.3335 10.6672ZM5.3335 14.0002C5.3335 13.632 5.63197 13.3335 6.00016 13.3335H12.6668C13.035 13.3335 13.3335 13.632 13.3335 14.0002C13.3335 14.3684 13.035 14.6668 12.6668 14.6668H6.00016C5.63197 14.6668 5.3335 14.3684 5.3335 14.0002Z" fill="white"/>
    </svg>
</template>

<script>
  export default {};
</script>
