export function phones (state = [], action) {
    let phoneIndex = -1
    switch (action.type) {
      case 'SET_PHONES':
        return action.phones.slice()
      case 'UPDATE_PHONE':
        phoneIndex = state.findIndex(p => p.id === action.phone.id)
        if (phoneIndex === -1) return state
        return [
          ...state.slice(0, phoneIndex),
          {
            ...action.phone,
            e911_address:	action.phone.e911_address ? { ...action.phone.e911_address } : null,
          },
          ...state.slice(phoneIndex + 1)
        ]
      case 'ADD_PHONE':
        return [
          ...state,
          action.phone
        ]
      case 'DELETE_PHONE':
        phoneIndex = state.findIndex(p => p.id === action.phoneId)
        if (phoneIndex === -1) return state
        state.splice(phoneIndex, 1)
        return [...state]
      default:
        return state
    }
  }
  