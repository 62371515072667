<template>
    <w-loader v-if="order.loading"/> <!-- TODO this page has no vuetify and data-test-id="" -->
    <div v-else class="order show-page" data-test-id="orders-show-page">
      <BackButton v-if="router_exists()" />
      <template v-if="item">
        <div class="d-flex justify-center w-h5 mb-1">
        {{ $lang.t('app.order-ID', 'Order ID') }}-{{item.id}}
        </div>
        <div class="d-flex justify-center w-caption">
          {{$lang.t('orders.tracking-number', 'Tracking number')}}:&nbsp;
          <a
            v-if="item.tracking_number"
            :href="`https://www.fedex.com/fedextrack/?tracknumbers=${item.tracking_number}`"
            target="_blank"
            class="w-subtitle-3"
          >
            {{item.tracking_number}}
          </a>
            <span v-else class="text--text text--lighten-1">{{$lang.t('orders.available-after-shipping', 'Available after shipping')}}</span>
        </div>
      </template>
      <w-alert
        v-if="order.alert"
        :message="order.alert.message"
        :level="order.alert.level"
        :closable="order.enable_close_alert"
        @closed="order.alert = null"
        class="mb-6"
      />
      <div v-if="item" class="mt-9" :class="{'d-flex justify-center': $vuetify.breakpoint.mdAndUp}">
        <VerticalTable
          :items="[
            {text: $lang.t('orders.details-contact-information', 'Details & Contact information'), title: true},
            {text: $lang.t('app.voIP-id', 'VoIP ID'), value: item.voip_id},
            {text: $lang.t('app.status', 'Status'), value: order.status_translations[item.status] ? order.status_translations[item.status] : item.status},
            {text: $lang.t('orders.shipping-method', 'Shipping method'), value: order.formatShippingMethod(item.shipping_method)},
            {text: $lang.t('orders.shipping-address', 'Shipping address'), value: order.constructor.stringifyAddress(item.contact.address)},
            {text: $lang.t('app.name', 'Name'), value: item.contact.name},
            {text: $lang.t('app.phone', 'Phone'), value: format_phone_number(item.contact.phone)},
            {text: $lang.t('app.email-address', 'Email address'), value: item.contact.primary_email ? item.contact.primary_email : primary_email},
            {text: $lang.t('orders.signature-required', 'Signature required'), value: item.signature_required ? $lang.t('app.yes', 'Yes') : $lang.t('app.no', 'No')},
          ]"
          :style="{width: $vuetify.breakpoint.mdAndUp ? '350px' : '100%', marginRight: $vuetify.breakpoint.mdAndUp ? '37px' : 0}"
        />
        <VerticalTable
        :items="devices_items()"
        :style="{width: $vuetify.breakpoint.mdAndUp ? '350px' : '100%', marginTop: !$vuetify.breakpoint.mdAndUp ? '40px' : 0}"
      />
      </div>
    </div>
</template>

<script>
  import {formatPhoneNumber} from 'formatters';
  import {get_account_information} from 'phoenix-session-helpers';
  import {vueComponent} from 'helpers';
  import l from '../../../console/src/libs/lang';
  import Order from '../../../console/src/models/Order';
  import BackButton from '../../../console/src/components/elements/BackButton.vue';
  import VerticalTable from '../../../console/src/components/elements/VerticalTable.vue';

  export default {
    props: ['id', '_order'],
    components: { BackButton, VerticalTable },
    data() {
      return {
        item: null,
        order: new Order(this.$session, vueComponent(this)),
        primary_email: null,
      };
    },
    async created() {
      this.$data.order.loading = true;
      const {billing_contact} = await get_account_information(this.$session);
      this.$data.primary_email = billing_contact.primary_email;
      if (this.$props._order || (this.$route && this.$route.params._order)) {
        this.$data.item = this.$props._order || this.$route.params._order;
      } else {
        const items = await this.$session.get_list_all('/orders');
        const id = this.$props.id || (this.$route && this.$route.params.id);
        const item = items.items.find((x) => x.id === Number.parseInt(id));
        if (item) {
          this.$data.item = item;
        } else {
          this.$data.order.alert = {
            level: 'error',
            message: this.$lang.t('app.not-found', 'Not found'),
          };
        }
      }
      await this.$data.order.loadShippingMethods();
      this.$data.order.loading = false;
    },
    methods: {
      devices_items() {
        let items = [];
        if (this.$data.item.devices) {
          items.push({
            text: this.$lang.t('app.devices', 'Devices'),
            title: true
          })
          const {devices} = this.$data.item;
          for (let i = 0; i < devices.length; i++) {
            const device = devices[i];
            const device_items = [
              {text: this.$lang.t('app.product-id-uppercase', 'PRODUCT ID'), value: `${device.id}`},
              {text: this.$lang.t('app.device', 'Device'), value: `${device.model.manufacturer} ${device.model.name}`},
              {text: this.$lang.t('app.type', 'Type'), value: device.type},
              {text: this.$lang.t('app.code', 'Code'), value: device.code},
              {text: this.$lang.t('app.line-keys', 'Line keys'), value: `${device.model.lines}`},
              {text: this.$lang.t('app.E911-address', 'E911 address'), value: Order.stringifyAddress(device.address)},
            ];
            items = items.concat(device_items);
            if (i < devices.length - 1) {
              items.push({divider: true});
            }
          }
        }

        if (this.$data.item.accessories) {
          items.push({
            text: this.$lang.t('app.accessories', 'Accessories'),
            title: true
          })
          const {accessories} = this.$data.item;
          for (let i = 0; i < accessories.length; i++) {
            const device = accessories[i];
            const accessory_items = [
              {text: this.$lang.t('app.product-id-uppercase', 'PRODUCT ID'), value: `${device.id}`},
              {text: this.$lang.t('app.accessory', 'Accessory'), value: `${device.model.manufacturer} ${device.model.name}`},
              {text: this.$lang.t('app.code', 'Code'), value: device.code},
            ];
            items = items.concat(accessory_items);
            if (i < accessories.length - 1) {
              items.push({divider: true});
            }
          }
        }
        return items;
      },
      format_phone_number(number) {
        if (!number) return null;
        return formatPhoneNumber(number);
      },
      router_exists() {
        return !!this.$router;
      },
    },
    filters: {
      address: (data) => {
        if (!data) return '—';
        let lines = data.line_1;
        if (data.line_2) lines += ` ${data.line_2}`;
        lines = lines.trim();
        const parts = [
          lines,
          data.postal_code,
          data.city,
          data.province,
          data.country
        ].filter((x) => x);
        if (parts.length) return parts.join(', ');

        return '—';
      }
    }
  };
</script>
<style lang="scss">
  .order.show-page {
    table {
      tr {
        height: 25px;
        th {
          width: 15%;
          max-width: 15%;
          text-align: left !important;
        }
      }
    }
  }
</style>
