<template>
    <w-text-field
    v-bind="$attrs"
    v-on="$listeners"
    ref="input"
    prepend-inner-icon="$vuetify.icons.filter"
    :placeholder="placeholder ? placeholder : $lang.t('app.filter-by-name', 'Filter by name')"
    :disabled="disabled"
    @input="model.apply_dynamic_filters()"
    @focus="on_focus"
    @blur="on_blur"
    :autofocus="focus"
    :key="`dynamic-input-${focus}`"
  />
</template>
<script>
  export default {
    props: {
        model: {
            type: Object,
            required: true,
        },
        placeholder: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
      return {
        is_focused: false,
        force_focus: false,
        focus: false,
      };
    },
    methods: {
        on_focus() {
            this.$data.is_focused = true
        },
        on_blur() {
            if (!this.$data.force_focus) {
                this.$data.is_focused = false
                this.$data.focus = false
            }
        },
    },
    watch: {
        disabled(v) {
            this.$data.focus = false;
            if (v && this.$data.is_focused) {
                this.$data.force_focus = true
            } else if (this.$data.force_focus) {
                this.$data.focus = true;
                this.$data.force_focus = false;
                this.$data.is_focused = false;
            } else {
                this.$data.force_focus = false;
                this.$data.is_focused = false;
            }
        },
    }
  };
</script>
