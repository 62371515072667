/**
 * @param {object} theme
 */
const styles = (theme) => ({
    mainWrapper: {
        // overflowX:    'auto',
        width: '100%',
        padding: '50px min(65px, 5%) 65px',
        '&.small': {
            padding: '30px min(25px, 5%) 0px'
        },
        '&.tablet': {
            padding: '30px min(50px, 5%) 30px'
        }
    },
    table: {
        height: '100%'
    },
    header: {
        paddingTop: 50
    },
    tableHeadRow: {
        height: 50,
        backgroundColor: '#F5FAFE',
        fontSize: 16,
        fontWeight: 600
    },
    tableRow: {
        minHeight: 64
    },
    tableCell: {
        paddingLeft: 75
    },
    optionIcon: {
        cursor: 'pointer'
    },
    deleteMenuItem: {
        color: 'red',
        '&:hover': {
            color: 'red'
        }
    },
    pdcToggleWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    phoneNumberNameField: {
        cursor: 'text',
        width: '100%',
        '&.mobile': {
            width: 160
        }
    },
    phoneNumberNameTextField: {
        width: '100%'
    },
    phoneNumberNameTextFieldInput: {
        '&:not(.focused)': {
            '&:hover': {
                background: '#e8e8e8'
            },
            '& fieldset': {
                border: 'none'
            }
        }
    },
    bold: {
        fontWeight: 'bold'
    },
    loadingDiv: { ...theme.loadingDiv, position: 'fixed' },
    tableBody: {
        overflowX: 'auto',
        '& .pdc-loader-wrapper': {
            minHeight: 40,
            display: 'flex',
            alignItems: 'center',
            overflow: 'unset',
            margin: 'unset',
            transform: 'unset'
        }
    },
    tableShadow: {
        backgroundImage:
            '/* Shadows */ linear-gradient(to right, white, white), linear-gradient(to right, white, white), /* Shadow covers */ linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)), linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))',
        backgroundPosition: 'left center, right center, left center, right center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
        backgroundAttachment: 'local, local, scroll, scroll'
    },
    numberCell: {
        minWidth: 160,
        boxSizing: 'border-box'
    },
    typeCell: {
        minWidth: 145,
        boxSizing: 'border-box',
        '&.gray': {
            color: 'gray'
        }
    },
    nameCell: {
        minWidth: 160,
        boxSizing: 'border-box'
    },
    actionCell: {
        minWidth: 120,
        boxSizing: 'border-box'
    },
    inboxCell: {
        minWidth: 120,
        boxSizing: 'border-box'
    },
    recordCell: {
        minWidth: 60,
        boxSizing: 'border-box',
        maxWidth: 90
    },
    optionsCell: {
        minWidth: 60,
        boxSizing: 'border-box',
        maxWidth: 90
    },
    editCell: {
        minWidth: 60,
        boxSizing: 'border-box',
        maxWidth: 90,
        paddingBottom: 8.5,
        paddingTop: 8.5
    },
    confirmSpinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 50,
        '& svg': {
            color: theme.palette.primary[0]
        }
    },
    editIconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 46,
        width: 46,
        fontSize: 20,
        borderRadius: '50%',
        '&:not(.disabled)': {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.secondary['-300']
            }
        },
        '&.disabled': {
            cursor: 'not-allowed',
            '& .dot': {
                backgroundColor: theme.palette.secondary['-300']
            }
        }
    },

    customFileUpload: {
        height: 30,
        width: 30,
        color: 'blue',
        '&:hover': {
            color: 'red'
        },
        display: 'inline'
    },

    uploadedFile: {
        display: 'inline'
    },

    uploadText: {
        color: '#3FAE29',
        cursor: 'pointer'
    },

    spanText: {
        margin: 5
    }

})

export default styles
