import './src/asset-path.js'
import React from 'react'
import App from './src/components/App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

import withTheme from '@material-ui/core/styles/withTheme'

const store = createStore(rootReducer)

interface Props {
    extension
    extensions
    screenViewType
    standalone
    redirect: (path: string) => void
    setHasChange: (hasChange: boolean) => void
    routeProps
    unreadPorting
    setBadgeValue: (namespace, value) => void
    hasActivePorts: boolean
    setHasActivePorts
    subscribeForCHRNotifications: (f: (data) => void) => void
    isOffline: boolean
}

class PhoneNumbers extends React.Component<Props, any> {
    render = () => {
        return (
            <Provider store={store}>
                <App
                    extensions={this.props.extensions}
                    screenViewType={this.props.screenViewType}
                    standalone={this.props.standalone}
                    redirect={this.props.redirect}
                    setHasChange={this.props.setHasChange}
                    routeProps={this.props.routeProps}
                    unreadPorting={this.props.unreadPorting}
                    setBadgeValue={this.props.setBadgeValue}
                    hasActivePorts={this.props.hasActivePorts}
                    setHasActivePorts={this.props.setHasActivePorts}
                    subscribeForCHRNotifications={this.props.subscribeForCHRNotifications}
                    isOffline={this.props.isOffline}
                />
            </Provider>
        )
    }
}

export default withTheme(PhoneNumbers)
