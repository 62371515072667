<template>
    <DefaultModal max-width="477" v-bind="$attrs" v-on="$listeners" @close="on_close">
      <template v-slot:title>
        <div class="w-h5 page-title info--text text--darken-1">
            {{$lang.t('lr.order-details', 'Order details')}}
        </div>
      </template>
      <div>
          <div class="d-flex justify-space-between mt-4">
              <div class="w-body-2 font-weight-bold">
                {{$lang.t('lr.ai-connect', 'AI-Connect')}}
              </div>
              <div class="w-body-2 font-weight-bold">
                19.99
              </div>
          </div>
          <div v-if="promotionPeriodInMonths" class="d-flex justify-space-between mt-4">
              <div class="w-body-2 font-weight-bold">
                {{$lang.t('lr.month-free', `${promotionPeriodInMonths} month free`)}}
              </div>
              <div class="w-body-2 font-weight-bold">
                  —
              </div>
          </div>
          <div v-if="promotionPeriodInMonths" class="d-flex mt-3">
              <div class="w-caption text--text text--lighten-1">
                {{$lang.t('lr.discount-ends', `Discount ends ${promotionEndDateFormatted()}`)}}
              </div>
          </div>
          <v-divider class="light-divider my-5"></v-divider>
          <div class="d-flex justify-space-between mt-4">
              <div class="w-body-2 font-weight-bold">
                {{$lang.t('lr.amount-due', 'Amount due')}}
              </div>
              <div class="w-body-2 font-weight-bold">
                {{ promotionPeriodInMonths ? '0' : '19.99' }}
              </div>
          </div>
          <div class="d-flex mt-4">
            <w-checkbox class="mt-n4" v-model="may_submit"/>

            <span class="text--text">{{$lang.t('app.by-checking-this-box', `By checking this box, you understand the first ${this.promotionPeriodInMonths} month(s) of service are free. A fee of $19.99 per month will then be billed to the card you have on file each month you use the service. You may cancel anytime.`)}}</span>
        </div>
        <div class="d-flex justify-center mt-6">
            <w-btn @click="on_close" color="secondary" class="mr-5">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
            <w-btn @click="on_continue" color="primary" :disabled="!may_submit">{{$lang.t('app.submit', 'Submit')}}
                <v-icon color="white" class="my-auto" size="18">$vuetify.icons.chevron_forward</v-icon>
            </w-btn>
          </div>
      </div>
    </DefaultModal>
  </template>
  <script>
  import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
  import { getValue } from 'remote-config-value'
   
    export default {
      props: {
      },
      components: {
          DefaultModal,
      },
      data() {
        return {
            may_submit: false,
            promotionPeriodInMonths: null,
        };
      },
      created() {
        this.promotionPeriodInMonths = Number.parseInt(getValue('IAB_promotion_period_in_months'));
      },
      methods: {
        promotionEndDateFormatted() {
            const now = new Date();
            now.setMonth(now.getMonth() + this.promotionPeriodInMonths);
            return now.toLocaleString('en-US', {
                day: 'numeric',
                month: 'long'
            })
        },
        on_close() {
            this.$emit('close');
        },
        on_continue() {
            this.$emit('continue');
        },
      },
      computed: {
          
      }
    };
  </script>
  <style lang="scss">
  .call-expert-container {
      min-height: 350px;
  }
  </style>
  