import React, { Component } from 'react'
import { OnNothingSelected } from './configure_util'
import { DefaultArrowTooltip } from 'tooltips'
import gtmDataPush from 'gtm-events'
import { Stepper, Variant as StepperVariant, StepInfo } from 'stepper'
import AudioComponent2, { TTS, Recording, UploadAudio } from 'audio-component-2'
import Typography from 'typography'
import Section from './Section'
import MenuOptionsIcon from './icons/MenuOptionsIcon'
import NoOptionsDialedIcon from './icons/NoOptionsDialedIcon'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import CloseIcon from 'svg-icons/src/navigation/CloseIcon'
import AddCircleIcon from 'svg-icons/src/actions/AddCircleIcon'
import { getValue } from 'remote-config-value'
import UsersDropdown from './UsersDropdown'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'

const GtmAppName = 'configure-number'
const GtmMap = { OtherOption: 1, SameOption: 0 }
const RequiredFieldErrorText = 'Required field'

/***/
export interface Props {
    // Material ui's classes
    classes: any
    showValidationErrors: boolean
    menu: any
    // Call this with new instance of the menu so that a component update will happen
    updateMenu: (menu: any) => void
    companyExtension: any
    users: any[]
    id: string
    screenViewType: any
}

class PlayMenuSection extends Component<Props, any> {
    componentDidMount = (): void => {
        if (Object.keys(this.props.menu.menuActions).length === 0) {
            this.addMenuAction()
        }
    }

    changeDialpadOption = (value: string, oldOption: string): void => {
        if (value === oldOption) return
        const { menu } = this.props
        const menuActions = { ...menu.menuActions }
        const actions = { ...menu.actions }
        const dialpadOption = value
        menuActions[dialpadOption] = menuActions[oldOption]
        delete menuActions[oldOption]
        actions[dialpadOption] = actions[oldOption]
        delete actions[oldOption]
        this.props.updateMenu({ ...menu, menuActions, actions })
        // eslint-disable-next-line
        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `menu-option-key;${oldOption}->${value}` })
    }

    getAvailableOptions = (ignoreOption?: string): (string | number)[] => {
        const menuActions = this.props.menu.menuActions
        const allMenuOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '#']
        const availableOptions = allMenuOptions.filter(o => (ignoreOption && `${ignoreOption}` === `${o}`) || !(o in menuActions))
        return availableOptions
    }

    addMenuAction = (): void => {
        const availableOptions = this.getAvailableOptions()
        if (!availableOptions.length) return
        const { menu } = this.props
        const menuActions = { ...menu.menuActions }
        const actions = { ...menu.actions }
        menuActions[availableOptions[0]] = '|'
        actions[availableOptions[0]] = 'ring'
        this.props.updateMenu({ ...menu, menuActions, actions })
        // eslint-disable-next-line
        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'menu-action-add' })
    }

    removeMenuAction = (menuOption: string): void => {
        const { menu } = this.props
        const menuActions = { ...menu.menuActions }
        const actions = { ...menu.actions }
        delete menuActions[menuOption]
        delete actions[menuOption]
        this.props.updateMenu({ ...menu, menuActions, actions })
        // eslint-disable-next-line
        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'menu-action-remove' })
    }

    renderDialpadOptions = (selectedOption: string, onChange: (value: string) => void): JSX.Element => {
        const { classes, screenViewType } = this.props
        const availableOptions = this.getAvailableOptions(selectedOption)
        return (
            <Select
                label = {getValue('configure_number_menu_options_keypress_label')}
                value = {selectedOption.toString()}
                onChange = {(e: React.ChangeEvent<{ name?: string, value: unknown }>) => onChange(e.target.value.toString())}
                MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                formControlClasses = {{ root: `${classes.dropdownMenu} size-${screenViewType.isMobileView ? 'xs' : 'sm'} margin-right-2${screenViewType.isMobileView ? 0 : 5}` }}
                data-test-id = {`menu-option-keypress-select-${selectedOption}`}
            >
                {availableOptions.map((option, i) => (
                    <MenuItem
                        key = {i}
                        value = {option.toString()}
                        checked = {option === selectedOption}
                        data-test-id = {`menu-option-keypress-${option}`}
                    >{option.toString()}</MenuItem>
                ))}
            </Select>
        )
    }

    renderActionOptions = (menuOption: string, onChange: (value: string, menuOption: string) => void): JSX.Element => {
        const { classes, menu, screenViewType } = this.props
        const { actions } = menu
        const option = actions[menuOption]
        const validationError = this.props.showValidationErrors && option === '' ? RequiredFieldErrorText : null
        return (
            <Select
                value = {option}
                onChange = {(event: React.ChangeEvent<{ name?: string, value: unknown }>) => onChange(event.target.value as string, menuOption)}
                MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                error = {!!validationError}
                helperText = {validationError}
                formControlClasses = {{ root: `${classes.dropdownMenu} size-md ${screenViewType.isMobileView ? '' : 'margin-right-25'}` }}
            >
                <MenuItem value='ring' checked={option === 'ring'}>Rings</MenuItem>
                <MenuItem value='vm' checked={option === 'vm'}>Send voicemails to</MenuItem>
            </Select>
        )
    }

    changeTransfer = (value: string, menuOption: string): void => {
        const { menu } = this.props
        const menuActions = { ...menu.menuActions }
        const transferTo = value
        const prevTransferTo = menuActions[menuOption]
        menuActions[menuOption] = `${transferTo}|${prevTransferTo.split('|')[1]}`
        this.props.updateMenu({ ...menu, menuActions })
        // eslint-disable-next-line
        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'menu-option-transfer;user-click' })
    }

    changeOptionInbox = (value: string, menuOption: string): void => {
        const { menu } = this.props
        const menuActions = { ...menu.menuActions }
        const newInbox = value
        const prevTransferTo = menuActions[menuOption]
        menuActions[menuOption] = `${prevTransferTo.split('|')[0]}|${newInbox}`
        this.props.updateMenu({ ...menu, menuActions })
        // eslint-disable-next-line
        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'menu-option-inbox;user-click' })
    }

    changeActionOption = (value: string, menuOption: string): void => {
        const { menu } = this.props
        const actions = { ...menu.actions }
        const menuActions = { ...menu.menuActions }
        actions[menuOption] = value
        menuActions[menuOption] = `|${menuActions[menuOption].split('|')[1]}`
        this.props.updateMenu({ ...menu, menuActions, actions })
        // eslint-disable-next-line
        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'menu-option-action;user-click' })
    }

    renderMenuActions = (): JSX.Element => {
        const { classes, menu, screenViewType, companyExtension, users, showValidationErrors } = this.props
        const menuActions = menu.menuActions
        const hasAvailableOptions = this.getAvailableOptions().length
        const actionKeys = Object.keys(menuActions)
        const showValidationError = Boolean(this.props.showValidationErrors && actionKeys.length === 0)
        const isMobileView = screenViewType.isMobileView
        return (
            <div>
                {actionKeys.map((mo, index) => (
                    <div key={`${mo}-${this.props.id}`} className={`${classes.menuAction} ${isMobileView ? 'mobile' : ''}`}>
                        {this.renderDialpadOptions(mo, value => this.changeDialpadOption(value, mo))}
                        {this.renderActionOptions(mo, this.changeActionOption)}
                        {menu.actions[mo] === 'ring'
                            ? <UsersDropdown
                                users = {users}
                                label = {getValue('configure_number_menu_options_users_field_label')}
                                selectedValue = {menuActions[mo].split('|')[0].split(',').filter(e => e)}
                                isMultiple
                                onChange = {e => this.changeTransfer(e.target.value, mo)}
                                showValidationErrors = {showValidationErrors}
                                dataTestId = {`menu-option-forward-users-select-${mo}`}
                                dataTestIdMenuItemPrefix = {`select-user-${mo}-`}
                            />
                            : null
                        }
                        {menu.actions[mo] === 'ring' && !isMobileView ? <div className={`${classes.menuActionVerticalSeparator} ${isMobileView ? 'mobile' : ''}`}/> : null}
                        <UsersDropdown
                            users = {users}
                            label = {getValue('configure_number_menu_options_assign_field_label')}
                            selectedValue = {menuActions[mo].split('|')[1]}
                            onChange = {e => this.changeOptionInbox(e.target.value, mo)}
                            companyExtension = {companyExtension}
                            showValidationErrors = {showValidationErrors}
                            dataTestId = {`menu-option-assign-user-select-${mo}`}
                            dataTestIdMenuItemPrefix = {`assign-select-user-${mo}-`}
                        />
                        {index
                            ? <DefaultArrowTooltip title='Remove' placement='right'>
                                <div onClick={() => this.removeMenuAction(mo)} className={`${classes.removeMenuActionWrapper} ${isMobileView ? 'mobile' : ''}`}>
                                    <CloseIcon/>
                                    {isMobileView ? <Typography variant='body1'>remove</Typography> : null}
                                </div>
                            </DefaultArrowTooltip>
                            : null
                        }
                    </div>
                ))}
                {hasAvailableOptions
                    ? <div className={classes.addMenuWrapper} style={!actionKeys.length ? { marginTop: 0 } : null}>
                        <div
                            className = {`add-option-button ${showValidationError ? 'error' : ''}`}
                            onClick = {this.addMenuAction}
                            data-test-id = 'add-menu-option'
                        >
                            <AddCircleIcon/><Typography variant='body1' remoteConfigID='configure_number_menu_add_option_text'/>
                        </div>
                    </div>
                    : null
                }
            </div>
        )
    }

    renderOnNothingSelectedForwardSection = (): JSX.Element => {
        const { classes, menu, screenViewType, users, companyExtension, showValidationErrors } = this.props
        const { isMobileView } = screenViewType
        const mobileClass = isMobileView ? 'mobile' : ''
        return (
            <div className={`${classes.forwardTimeoutForwardWrapper} ${mobileClass}`} data-test-id='menu-timeout-forward-voicemail-section'>
                <UsersDropdown
                    users = {users}
                    selectedValue = {menu.onNothingSelected.ringUsers}
                    isMultiple
                    onChange = {event => {
                        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `${this.props.id}.callUsers;user-click` }) // eslint-disable-line
                        this.props.updateMenu({ ...menu, onNothingSelected: { ...menu.onNothingSelected, ringUsers: [...event.target.value] } })
                    }}
                    showValidationErrors = {showValidationErrors}
                    dataTestId = 'menu-timeout-ring-users-select'
                />
                <Typography classes={{ root: 'connector-text' }} variant='body2' remoteConfigID='configure_number_forward_second_timeout_voicemail_message'/>
                <UsersDropdown
                    users = {users}
                    selectedValue = {menu.onNothingSelected.voicemailUser}
                    onChange = {event => {
                        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'menu-forward-timeout-voicemail;user-click' }) // eslint-disable-line
                        this.props.updateMenu({ ...menu, onNothingSelected: { ...menu.onNothingSelected, voicemailUser: event.target.value } })
                    }}
                    companyExtension = {companyExtension}
                    showValidationErrors = {showValidationErrors}
                    dataTestId = 'menu-forward-voicemail-select'
                />
            </div>
        )
    }

    renderOnNothingSelectedVoicemailSection = (): JSX.Element => {
        const { classes, companyExtension, menu, users, showValidationErrors } = this.props
        return (
            <div className={classes.section}>
                <UsersDropdown
                    users = {users}
                    selectedValue = {menu.onNothingSelected.voicemailUser}
                    onChange = {event => {
                        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `${this.props.id}.callUsers;user-click` }) // eslint-disable-line
                        this.props.updateMenu({ ...menu, onNothingSelected: { ...menu.onNothingSelected, voicemailUser: event.target.value } })
                    }}
                    companyExtension = {companyExtension}
                    showValidationErrors = {showValidationErrors}
                    dataTestId = 'menu-timeout-voicemail-user-select'
                />
            </div>
        )
    }

    renderMainSection = (): JSX.Element => {
        const { classes, menu, screenViewType } = this.props
        const { isMobileView } = screenViewType
        const steps: StepInfo[] = [
            {
                id: 'menu-message',
                title: <div className={classes.menuMessageLabel}>
                    {getValue('configure_number_menu_welcome_message')}
                    <RemoteInfoTipMui arrow remoteConfigIDs={['configure_call_handling_menu_message']} placement={RemoteInfoTipMuiPlacement.TOP}/>
                </div>,
                active: true,
                content: (
                    <AudioComponent2
                        data-test-id = 'menu-welcome-message-section'
                        id = {`${this.props.id}-menu`}
                        greeting = {menu.greeting || new TTS()}
                        showValidationErrors = {this.props.showValidationErrors}
                        onChange = {greeting => this.props.updateMenu({ ...menu, greeting })}
                        textAreaInfoTipId = 'pro_tip_for_text_to_speach'
                        screenViewType = {screenViewType}
                        audioTypeNames = {{
                            [TTS.className]: 'Read a text',
                            [Recording.className]: 'Record a message',
                            [UploadAudio.className]: 'Upload a file'
                        }}
                    />
                ),
                'data-test-id': 'menu-message-step'
            },
            {
                id: 'menu-options',
                title: getValue('menu_options_question'),
                active: true,
                content: (
                    <div className={classes.section} data-test-id='menu-actions-section'>
                        {this.renderMenuActions()}
                    </div>
                ),
                'data-test-id': 'menu-options-step'
            }
        ]
        return (
            <Section
                screenViewType = {screenViewType}
                classes = {classes}
                icon = {<MenuOptionsIcon/>}
                remoteConfigID = 'configure_number_menu_header_text'
                data-test-id = 'use-a-menu-section'
            >
                <Stepper
                    data-test-id = 'menu-main-section'
                    variant = {StepperVariant.VERTICAL}
                    steps = {steps}
                    classes = {isMobileView ? { root: classes.mobileViewStepper } : {}}
                />
            </Section>
        )
    }

    renderTimeoutSection = (): JSX.Element => {
        const { classes, menu, screenViewType } = this.props
        const userNothingSelectedOptions = Object.values(OnNothingSelected)
        const selectedOption = menu.onNothingSelected.option
        const hasTimeoutError: boolean = this.props.showValidationErrors && !selectedOption
        const steps = [
            {
                id: 'on-timeout',
                title: getValue('configure_number_menu_timout_message'),
                active: true,
                content: (
                    <div data-test-id='menu-timeout-section'>
                        <Select
                            value = {selectedOption}
                            onChange = {(event) => {
                                const option = event.target.value
                                // eslint-disable-next-line
                                gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `menu-nothing-selected-option;${option}`, PDC_Value: selectedOption !== option ? GtmMap.OtherOption : GtmMap.SameOption })
                                this.props.updateMenu({ ...menu, onNothingSelected: { ...menu.onNothingSelected, option } })
                            }}
                            MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                            formControlClasses = {{ root: `${classes.dropdownMenu} size-lg` }}
                            error = {hasTimeoutError}
                            helperText = {hasTimeoutError ? 'Required field' : ''}
                            data-test-id = 'menu-timeout-select'
                        >
                            {userNothingSelectedOptions.map((option, i) => {
                                const optionText = option.split('-').join(' ')
                                return (
                                    <MenuItem
                                        key = {i}
                                        value = {option}
                                        checked = {option === selectedOption}
                                        data-test-id = {option}
                                    >{optionText[0].toUpperCase()}{optionText.substring(1)}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                )
            }
        ]
        if (selectedOption === OnNothingSelected.Forward) {
            steps.push({
                id: 'timeout-forward',
                title: getValue('configure_number_menu_timeout_forward_message'),
                active: true,
                content: this.renderOnNothingSelectedForwardSection()
            })
        } else if (selectedOption === OnNothingSelected.Voicemail) {
            steps.push({
                id: 'timeout-voicemail',
                title: getValue('configure_number_menu_timeout_voicemail_message'),
                active: true,
                content: this.renderOnNothingSelectedVoicemailSection()
            })
        }
        const { isMobileView } = screenViewType
        return (
            <Section
                screenViewType = {screenViewType}
                classes = {classes}
                icon = {<NoOptionsDialedIcon/>}
                remoteConfigID = 'configure_number_menu_timeout_header_text'
                data-test-id = 'menu-timeout-section'
            >
                <Stepper variant={StepperVariant.VERTICAL} steps={steps} classes={isMobileView ? { root: classes.mobileViewStepper } : {}}/>
            </Section>
        )
    }

    render = (): JSX.Element => {
        return (
            <>
                {this.renderMainSection()}
                {this.renderTimeoutSection()}
            </>
        )
    }
}

export default PlayMenuSection
