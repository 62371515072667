import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5664%3A7251
 *
 * @param {SvgIconProps} props - svg props
 */
export const CompanyIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d="M10.9899 0.916748C5.42394 0.916748 0.916687 5.43408 0.916687 11.0001C0.916687 16.5661 5.42394 21.0834 10.9899 21.0834C16.566 21.0834 21.0834 16.5661 21.0834 11.0001C21.0834 5.43408 16.566 0.916748 10.9899 0.916748ZM17.9777 6.96675H15.0031C14.6804 5.70633 14.2166 4.49633 13.6116 3.37708C15.4669 4.01233 17.0097 5.303 17.9777 6.96675ZM11 2.97375C11.8369 4.18375 12.4924 5.52483 12.9259 6.96675H9.0741C9.50769 5.52483 10.1631 4.18375 11 2.97375ZM3.19552 13.0167C3.03419 12.3714 2.93335 11.6958 2.93335 11.0001C2.93335 10.3043 3.03419 9.62875 3.19552 8.98342H6.60369C6.52302 9.64892 6.46252 10.3144 6.46252 11.0001C6.46252 11.6857 6.52302 12.3512 6.60369 13.0167H3.19552ZM4.02235 15.0334H6.99694C7.3196 16.2938 7.78344 17.5038 8.38844 18.6231C6.5331 17.9878 4.99035 16.7072 4.02235 15.0334ZM6.99694 6.96675H4.02235C4.99035 5.29292 6.5331 4.01233 8.38844 3.37708C7.78344 4.49633 7.3196 5.70633 6.99694 6.96675ZM11 19.0264C10.1631 17.8164 9.50769 16.4753 9.0741 15.0334H12.9259C12.4924 16.4753 11.8369 17.8164 11 19.0264ZM13.3595 13.0167H8.64052C8.54977 12.3512 8.47919 11.6857 8.47919 11.0001C8.47919 10.3144 8.54977 9.63883 8.64052 8.98342H13.3595C13.4503 9.63883 13.5209 10.3144 13.5209 11.0001C13.5209 11.6857 13.4503 12.3512 13.3595 13.0167ZM13.6116 18.6231C14.2166 17.5038 14.6804 16.2938 15.0031 15.0334H17.9777C17.0097 16.6972 15.4669 17.9878 13.6116 18.6231ZM15.3964 13.0167C15.477 12.3512 15.5375 11.6857 15.5375 11.0001C15.5375 10.3144 15.477 9.64892 15.3964 8.98342H18.8045C18.9659 9.62875 19.0667 10.3043 19.0667 11.0001C19.0667 11.6958 18.9659 12.3714 18.8045 13.0167H15.3964Z" fill={color}/>
        </SvgIcon>
    )
}

export default CompanyIcon
