import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const GeneralSettingsIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#364047'/>
            <path d='M19.9845 4.41663C11.3825 4.41663 4.41675 11.398 4.41675 20C4.41675 28.602 11.3825 35.5833 19.9845 35.5833C28.6021 35.5833 35.5834 28.602 35.5834 20C35.5834 11.398 28.6021 4.41663 19.9845 4.41663ZM30.7837 13.7666H26.1867C25.688 11.8187 24.9712 9.94871 24.0362 8.21896C26.9035 9.20071 29.2877 11.1954 30.7837 13.7666ZM20.0001 7.59563C21.2935 9.46563 22.3064 11.5382 22.9765 13.7666H17.0237C17.6937 11.5382 18.7067 9.46563 20.0001 7.59563ZM7.93858 23.1166C7.68925 22.1193 7.53341 21.0752 7.53341 20C7.53341 18.9247 7.68925 17.8806 7.93858 16.8833H13.2057C13.0811 17.9118 12.9876 18.9403 12.9876 20C12.9876 21.0596 13.0811 22.0881 13.2057 23.1166H7.93858ZM9.21641 26.2333H13.8135C14.3122 28.1812 15.029 30.0512 15.964 31.781C13.0967 30.7992 10.7124 28.8201 9.21641 26.2333ZM13.8135 13.7666H9.21641C10.7124 11.1798 13.0967 9.20071 15.964 8.21896C15.029 9.94871 14.3122 11.8187 13.8135 13.7666ZM20.0001 32.4043C18.7067 30.5343 17.6937 28.4617 17.0237 26.2333H22.9765C22.3064 28.4617 21.2935 30.5343 20.0001 32.4043ZM23.6466 23.1166H16.3536C16.2133 22.0881 16.1042 21.0596 16.1042 20C16.1042 18.9403 16.2133 17.8962 16.3536 16.8833H23.6466C23.7868 17.8962 23.8959 18.9403 23.8959 20C23.8959 21.0596 23.7868 22.0881 23.6466 23.1166ZM24.0362 31.781C24.9712 30.0512 25.688 28.1812 26.1867 26.2333H30.7837C29.2877 28.8045 26.9035 30.7992 24.0362 31.781ZM26.7944 23.1166C26.9191 22.0881 27.0126 21.0596 27.0126 20C27.0126 18.9403 26.9191 17.9118 26.7944 16.8833H32.0616C32.3109 17.8806 32.4667 18.9247 32.4667 20C32.4667 21.0752 32.3109 22.1193 32.0616 23.1166H26.7944Z' fill='white'/>
        </SvgIcon>
    )
}

export default GeneralSettingsIcon
