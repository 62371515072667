/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */

/***/
export class Extension {
    public extension_id: number
    public extension_name: string
    public extension: number
    public info: any

    /**
     * @param i
     */
    public static fromJson (i: any): Extension {
        const e = new Extension()
        e.extension_id = i.extension_id
        e.extension = i.extension
        e.extension_name = i.extension_name
        e.info = i
        return e
    }

    /**
     *
     */
    public toJson (): any {
        return {
            extension: this.extension,
            extension_id: this.extension_id,
            extension_name: this.extension_name
        }
    }
}
