import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=6026%3A8441
 *
 * @param {SvgIconProps} props - svg props
 */
export const HolidayIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M15.9834 16.4581C15.8313 17.2671 16.9608 18 16.9608 18L13.2543 17.9956C13.2543 17.9956 14.4714 17.0508 14.1302 16.438C12.9224 16.1897 11.8252 15.569 11 14.8601C14.1302 13 16.9608 7.9234 14.1809 2.02403C14.3976 2.00801 14.9093 2 15.1306 2C19.0215 2 22 4.67134 22 7.9234C22 10.819 19.8006 15.6811 15.9834 16.4581ZM8.37263 15.3242C8.22968 16.1876 9.30829 16.9915 9.30829 16.9915L5.78225 17C5.78225 17 6.9908 15.9835 6.67459 15.3327C2.55943 14.3843 1 9.4374 1 6.29047C1 2.83688 3.88927 0 7.54527 0C11.2013 0 14 2.83688 14 6.29047C14 9.36552 11.9593 14.5034 8.37263 15.3242ZM8.5 17C8.5 17 8.46124 18.2548 10.2361 19.5C11.8745 20.8913 10.2361 23 10.2361 23H9.49812C9.95936 22.3791 10.9738 20.7322 9.49812 19.6794C8.16236 18.7264 7.758 17 7.758 17H8.5ZM15.6852 18C15.6852 18 15.1217 19.8027 16.356 20.9412C17.805 22.2922 16.356 24 16.356 24H15.6852C16.1045 23.2903 17.0268 22.1973 15.6852 21.0588C14.5 20 15.0781 18 15.0781 18H15.6852Z' fill={color}/>
        </SvgIcon>
    )
}

export default HolidayIcon
