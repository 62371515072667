import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { setSmallView } from '../../actions/view'
import DropdownMenu from 'dropdown-menu'
import AvatarIcon from 'avatar-icon'
import PDCButton from 'button'
import Options from 'pdc-options'
import QuickAddNumberModal from 'quick-add-number-modal'
import EditExtensionField from 'edit-extension-field'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Tooltip from '@material-ui/core/Tooltip'
import CallForwardingSetupModal from './CallForwardingSetupModal'
import PropTypes from 'prop-types'
import RemoteConfigValue from 'remote-config-value'
import TableMui from 'pdc-table-mui'

const styles = theme => ({
    mainWrapper: {
        height: '100%'
    },
    tableWrapper: {
        height: '100%'
    },

    tableBody: {
        position: 'relative',
        overflowX: 'auto',
        '& .pdc-loader-wrapper': {
            width: 'fit-content',
            minHeight: 40,
            margin: 0,
            transform: 'unset',
            padding: 10
        }
    },
    tableHeadRow: {
        height: 50,
        fontSize: 16,
        fontWeight: 600,
        '& > div': {
            alignItems: 'center !important'
        }
    },
    tableRow: {
        minHeight: 85,
        '& .cell': {
            border: 'none',
            alignItems: 'center'
        }
    },
    leftColumn: {
        marginLeft: 39,
        minWidth: 200,
        alignItems: 'flex-start !important'
    },
    nameColumn: {
        fontSize: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& .nameInfoContainer': {
            display: 'inline-grid',
            '& .name': {
                fontSize: 16,
                fontWeight: 600,
                '&.pending': {
                    color: '#85929A'
                },
                '&.new': {
                    color: '#85929A',
                    marginBottom: 5
                }
            },
            '& .email': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: 12,
                '&.pending': {
                    color: '#E85646'
                }
            },
            '& .inviteButtonWrapper > button': {
                borderRadius: 4,
                padding: '5px 10px 5px 30px',
                fontSize: 16,
                '& .icon-left': {
                    left: 10,
                    width: 15,
                    height: 15
                }
            }
        },
        '& .inviteUserNameContainer': {
            color: '#3FAE29',
            fontSize: 20,
            cursor: 'pointer'
        }
    },
    numberColumn: {},
    planColumn: {
        fontSize: 16
    },
    extColumn: {
        fontSize: 16
    },
    meetingsColumn: {
        color: '#3FAE29',
        '&.link': {
            cursor: 'pointer'
        }
    },
    actionsColumn: {
        display: 'flex'
    },
    notConfiguredColumn: {
        fontSize: 16,
        color: '#85929A'
    },
    dropdownHeader: {
        fontSize: 16,
        color: '#3FAE29',
        borderBottom: '1px solid lightgray'
    },
    infoIcon: {
        color: '#A6AFB5',
        cursor: 'help',
        height: 13,
        width: 13,
        marginLeft: 4,
        marginBottom: 2
    },
    tooltip: {
        backgroundColor: '#48B9D5',
        fontSize: 12
    },
    menuItem: {
        padding: 0,
        pointerEvents: 'unset !important'
    },
    tooltipInnerDiv: {
        padding: '6px 12px',
        height: '100%',
        width: '100%'
    },
    xIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        width: 50,
        cursor: 'pointer',
        borderRadius: '50%',
        '& svg': {
            color: '#85929A'
        },
        '&:hover': {
            background: '#F4F5F6'
        }
    },
    nameCell: { minWidth: 240 },
    planCell: { minWidth: 140 },
    directNumberCell: { minWidth: 170 },
    meetingCell: { minWidth: 100 },
    extensionCell: { minWidth: 100 },
    optionsCell: { minWidth: 100 },
    extensionColumn: { maxWidth: 100 }
})

const mapStateToProps = state => ({
    smallView: state.smallView,
    users: state.users,
    numbers: state.numbers,
    newNumbers: state.newNumbers
})

const mapDispatchToProps = dispatch => ({
    setSmallView: boolVal => dispatch(setSmallView(boolVal))
})

class ManageUsersLarge extends Component {
    constructor (props) {
        super(props)
        this.state = {
            perPage: 5,
            page: 0,
            showAddNumberModal: false,
            addNumberUser: null,
            callForwardingSetupModalUser: null
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ page: 0, perPage: parseInt(event.target.value, 10) })
    }

    directNumberDropdownButton = props => {
        return (
            <div {...props}>
                <PDCButton data-test-id='list-unassigned-numbers' variant='graphic' icon='add' color='primary' shape='circle'></PDCButton>
            </div>
        )
    }

    optionsDropdownButton = props => {
        return (
            <div {...props}>
                <Options data-button='options' variant='vertical'/>
            </div>
        )
    }

    getDropdownItems = user => {
        const items = [
            { text: 'Edit User Settings', onClick: () => this.props.onOpenUserSettings(user, '') },
            { type: 'separator' },
            { text: 'Setup Call Forwarding', onClick: () => this.props.onOpenUserSettings(user, 'incoming-calls') }
        ]

        if (user.direct_number !== null) items.push({ text: 'Unassign Number', onClick: () => this.props.unassignPhoneNumber(user) })

        items.push({ text: 'Show SIP credentials', onClick: () => this.props.showSIPCredentials(user) })
        items.push({ type: 'separator' })

        if (user.status === 'invite_pending') { items.push({ text: 'Resend Invite', onClick: () => this.props.resendInvite(user) }) } else if (user.status === 'new') { items.push({ text: 'Invite User', onClick: () => this.props.openInviteUserModal(user.id) }) } else { items.push({ text: 'Reset Password', onClick: () => this.props.resetPassword(user) }) }

        if (!user.is_admin) {
            items.push({ text: 'Delete User', onClick: () => this.props.deleteOneUser(user), className: 'danger strong' })
        }
        if (!user.is_admin && this.props.isCSR) {
            items.push({ text: 'Set as the admin', onClick: () => this.props.reassignAdminUser(user), className: 'strong' })
        }

        return items
    }

    getDirectNumberDropdownItems = user => {
        const header = <span data-test-id='direct-number-unassigned-number-list-title'>Unassigned numbers</span>
        //
        // let host = window.location.hostname
        // let baseUrl = host === 'localhost' ? `http://${host}:3000` : `https://${host}`
        // let footer = <span onClick={()=>window.open(`${baseUrl}/phone-numbers/add-numbers`, '_blank')}>Get New number<ArrowRightAltIcon/></span>
        const footer = <span data-test-id='get-new-number-button'>Get New number<ArrowRightAltIcon/></span>

        const items = [
            { text: header, className: 'strong' },
            { type: 'separator' },
            { text: footer, onClick: () => this.setState({ showAddNumberModal: true, addNumberUser: user }), className: 'active strong' }
        ]
        const numberItems = []
        this.props.numbers.forEach((number) => {
            numberItems.push({ text: this.props.prettyFormatNumberOnLoad(number.phone_number), onClick: () => this.props.assignNumber(user, number) })
            numberItems.push({ type: 'separator' })
        })

        items.splice(2, 0, ...numberItems)
        return items
    }

    getDevicesDropdownItems = user => {
        const { classes } = this.props
        const header = <span>Buy Phone<ArrowRightAltIcon/></span>
        return [
            { text: header, onClick: () => console.log(user.name), className: classes.dropdownHeader },
            { text: 'Yealink M5A - #1382', onClick: () => console.log(user.name) }
        ]
    }

    parseMeetingIdFromMeetingUrl = meeting_url => {
        const url = new URL(meeting_url)
        const parts = url.pathname.split('/')
        return parts.pop()
    }

    closeAddNumberModal = () => { this.setState({ showAddNumberModal: false, addNumberUser: null }) }

    renderNameColumn = user => {
        const { classes } = this.props
        if (user.status === 'new') {
            return (
                user.first_name
                    ? <div className={classes.nameColumn}>
                        <AvatarIcon
                            imageUrl = {user.avatar_url}
                            noBorder = {true}
                            opacity = {0.7}
                            text = {user.is_admin ? 'admin' : false}
                            style = {{ marginRight: 20 }}
                        />
                        <div className='nameInfoContainer'>
                            <div className={'name new'}>
                                {`${user.first_name} ${user.last_name}`}
                            </div>
                            <div className='inviteButtonWrapper'>
                                <PDCButton data-button='invite-user' variant='outlined' icon='add' shape='circle' onClick={() => this.props.openInviteUserModal(user.id)}>Invite user</PDCButton>
                            </div>
                        </div>
                    </div>
                    : <div className={classes.nameColumn}>
                        <PDCButton data-button='invite-user' variant='outlined' icon='add' shape='circle' onClick={() => this.props.openInviteUserModal(user.id)}>Invite user</PDCButton>
                    </div>

            )
        }

        return (
            <div className={classes.nameColumn}>
                <AvatarIcon
                    imageUrl = {user.avatar_url}
                    noBorder = {true}
                    opacity = {user.status === 'invite_pending' ? 0.7 : 1}
                    text = {user.is_admin ? 'admin' : false}
                    style = {{ marginRight: 20 }}
                />
                <div className='nameInfoContainer'>
                    <div className={`name ${user.status === 'invite_pending' ? 'pending' : ''}`}>
                        {`${user.first_name} ${user.last_name}`}
                    </div>
                    {user.status === 'invite_pending'
                        ? <div className={'email pending'}>
                            {'Has not yet joined'}
                        </div>
                        : <Tooltip classes={{ tooltip: classes.tooltip }} title={user.email} placement='top'>
                            <div className={'email'}>
                                {user.email}
                            </div>
                        </Tooltip>
                    }
                </div>
            </div>
        )
    }

    renderPlanColumn = user => {
        const { classes } = this.props
        if (user.status === 'new') return <div className={classes.notConfiguredColumn}>{user.plan ? user.plan.data.plan_description : ''}</div>

        const shouldDisablePlan = this.props.forceProPlan

        const basicUserTooltip = {
            title: <><p><RemoteConfigValue valueId={'configure_users_manage_users_basic_plan_1'}/></p>
                <p><RemoteConfigValue valueId={'configure_users_manage_users_basic_plan_2'}/></p>
                <RemoteConfigValue valueId={'configure_users_manage_users_basic_plan_3'}/></>,
            placement: 'right'
        }
        const planMenuItems = [
            { value: 1, content: 'Basic', disabled: !!user.device, tooltip: user.device ? basicUserTooltip : null },
            { value: 2, content: 'Plus' },
            { value: 3, content: 'Pro' }
        ]

        if (shouldDisablePlan) {
            return (
                <div
                    data-test-id='user-plan-name'
                    className={`${classes.planColumn}`}>
                    {planMenuItems.find(plan => plan.value === (user.user_plan_id ? user.user_plan_id : 1)).content}
                </div>
            )
        }
        return (
            <Select
                labelId="plan-select-label"
                id="plan-select"
                value={user.user_plan_id ? user.user_plan_id : 1}
                label={''}
                onChange={e => this.props.changeUserPlan(user, e.target.value)}
                classes= {{ root: classes.selectRoot }}
                data-test-id = 'users-plan-select'
                disabled = {shouldDisablePlan}
                style = {{ width: 100 }}
            >
                {planMenuItems.map((item, i) => (
                    <MenuItem data-test-id={`user-plan-item-${item.content}`} classes= {{ root: classes.menuItem }} key={i} value={item.value} disabled={item.disabled}>
                        <Tooltip data-test-id='user-plan-infotip' classes={{ tooltip: classes.tooltip }} enterTouchDelay={0} interactive disableFocusListener key={i} title={item.tooltip?.title || ''} placement={item.tooltip?.placement}>
                            <div className={classes.tooltipInnerDiv} onClick={(e) => e.preventDefault()}>
                                {item.content}
                            </div>
                        </Tooltip>
                    </MenuItem>
                ))}
            </Select>
        )
    }

    renderDirectNumberColumn = user => {
        const { classes } = this.props
        return (
            <div
                data-test-id='user-direct-number'
                className={`${classes.numberColumn}`}>
                {user.direct_number !== null
                    ? this.props.prettyFormatNumberOnLoad(user.direct_number.e164_number)
                    : <DropdownMenu
                        button = {this.directNumberDropdownButton}
                        dropdownId = {`${user.id}_direct_number`}
                        menuItems = {this.getDirectNumberDropdownItems(user)}
                        dropdownStyle = 'configure'
                    />
                }
            </div>
        )
    }

    renderMeetingColumn = user => {
        const { classes } = this.props
        if (user.status === 'new') return <div className={classes.notConfiguredColumn}></div>
        return (
            <div
                onClick={() => { if (user.meeting_plan !== null) { window.open(user.meeting_plan.add_on_configuration.meeting_url, '_blank') } }}
                className={`${classes.meetingsColumn} ${user.meeting_plan !== null ? 'link' : ''}`}>
                {user.meeting_plan !== null
                    ? this.parseMeetingIdFromMeetingUrl(user.meeting_plan.add_on_configuration.meeting_url)
                    : user.status === 'invite_pending'
                        ? <PDCButton variant='graphic' disabled={true} data-button='add-meeting' icon='add' color='primary' shape='circle'></PDCButton>
                        : <PDCButton variant='graphic' onClick={() => this.props.addMeeting(user)} data-button='add-meeting' icon='add' color='primary' shape='circle'></PDCButton>
                }
            </div>
        )
    }

    renderExtensionColumn = user => {
        const { classes } = this.props
        const userExtension = (user.extension ? user.extension.data.extension_number : '').toString()
        return (
            <div className={classes.extensionColumn}>
                <EditExtensionField
                    value = {userExtension}
                    onChange = {async (extension) => { return await this.props.updateUserExtension(user, extension) }}
                />
            </div>
        )
    }

    renderOptionsColumn = user => {
        const { classes } = this.props
        return (
            <div className={classes.actionsColumn}>
                <DropdownMenu
                    button = {this.optionsDropdownButton}
                    dropdownId = {`${user.id}_de`}
                    menuItems = {this.getDropdownItems(user)}
                    dropdownStyle = 'configure'
                />
            </div>
        )
    }

    renderConfiguredUserRow = (user) => {
        const columns = [
            { key: 0, content: this.renderNameColumn(user), primary: true, stringValue: `${user.first_name} ${user.last_name}`, testId: 'name' },
            { key: 1, content: this.renderPlanColumn(user), testId: 'plan' },
            { key: 2, content: this.renderDirectNumberColumn(user), testId: 'direct-number' },
            { key: 3, content: this.renderMeetingColumn(user), testId: 'meeting' },
            { key: 4, content: this.renderExtensionColumn(user), testId: 'extension' },
            { key: 4, content: this.renderOptionsColumn(user), testId: 'options' }
        ]

        return { key: user.id, columns }
    }

    renderCallForwardingSetupModal = () => {
        const callForwardingSetupModalUser = this.state.callForwardingSetupModalUser
        if (!callForwardingSetupModalUser) return null
        return (
            <CallForwardingSetupModal
                user = {callForwardingSetupModalUser}
                onClose = {() => this.setState({ callForwardingSetupModalUser: null })}
            />
        )
    }

    renderPlanHeadCol = () => {
        const { classes } = this.props
        const planTooltip = 'You can provide your users with just what they need, and save on your entire bill by customizing their user plan.'
        return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={planTooltip} placement='top'>
                <span>Plan</span>
            </Tooltip>
        )
    }

    renderMeetingHeadCol = () => {
        const { classes } = this.props
        return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title='Meeting Bridge' placement='top'>
                <span>Meeting</span>
            </Tooltip>
        )
    }

    renderExtHeadCol = () => {
        const { classes } = this.props
        const extensionTooltip = 'Not sure what an extension is? Not a problem, it is mostly for informational purposes.'
        return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={extensionTooltip} placement='top'>
                <span>Extension</span>
            </Tooltip>
        )
    }

    generateTableHeadColumns = () => {
        const headColumns = [
            { content: 'Name', testId: 'name-col' },
            { content: this.renderPlanHeadCol(), testId: 'plan-col' },
            { content: 'Direct Number', testId: 'direct-number-col' },
            { content: this.renderMeetingHeadCol(), testId: 'meeting-col' },
            { content: this.renderExtHeadCol(), testId: 'extension-col' },
            { content: '', testId: 'options-col' } // Options column header is empty, but must exist for styling purposes
        ]
        return headColumns
    }

    generateTableRows = () => {
        const rows = this.props.users.map(user => {
            return this.renderConfiguredUserRow(user)
        })
        return rows
    }

    render () {
        const { classes } = this.props
        return (
            <div className={classes.mainWrapper}>
                <TableMui
                    headColumns = {this.generateTableHeadColumns()}
                    rows = {this.generateTableRows()}
                    infiniteScroller = {true}
                    loadMore = {this.props.loadMore}
                    hasMore = {this.props.hasMore}
                    reverseScroll = {false}
                    listView = {false}
                    dataAttr = {'users-table'}
                />
                <QuickAddNumberModal
                    showModal={this.state.showAddNumberModal}
                    onCloseModal={this.closeAddNumberModal}
                    findAvailableNumbers={this.props.findAvailableNumbers}
                    searchPending={this.props.pendingFindAvailableNumbers}
                    addAvailableNumber={this.props.addAvailableNumber}
                    addNumberPending={this.props.pendingAddAvailableNumber}
                    addNumberUser={this.state.addNumberUser}
                    newNumbers={this.props.newNumbers}
                />
                {this.renderCallForwardingSetupModal()}
            </div>
        )
    }
}

ManageUsersLarge.propTypes = {
    classes: PropTypes.object,
    hasMore: PropTypes.bool,
    changeValueInUser: PropTypes.fucn,
    loadMore: PropTypes.bool,
    numbers: PropTypes.array,
    smallView: PropTypes.bool,
    users: PropTypes.array,
    pendingAddAvailableNumber: PropTypes.bool,
    addAvailableNumber: PropTypes.func,
    findAvailableNumbers: PropTypes.func,
    prettyFormatNumberOnLoad: PropTypes.func,
    openInviteUserModal: PropTypes.func,
    updateUserExtension: PropTypes.func,
    addMeeting: PropTypes.func,
    deleteOneUser: PropTypes.func,
    assignNumber: PropTypes.func,
    onOpenUserSettings: PropTypes.func,
    unassignPhoneNumber: PropTypes.func,
    resendInvite: PropTypes.func,
    resetPassword: PropTypes.func,
    changeUserPlan: PropTypes.func,
    pendingFindAvailableNumbers: PropTypes.bool,
    showSIPCredentials: PropTypes.func,
    reassignAdminUser: PropTypes.func,
    isCSR: PropTypes.bool,
    newNumbers: PropTypes.array,
    forceProPlan: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ManageUsersLarge))
