import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import IdentityPopup from './IdentityPopup'
// import { theme } from 'get-theme'
import Typography from 'typography'
import { FeatureEventsContext, ThemeContext } from 'providers'

const useStyles = makeStyles(styles)

interface Props {
  extension: {
      extension_id: number // eslint-disable-line
      phone_number: any // eslint-disable-line
      extension: number
      extension_name: string // eslint-disable-line
      is_virtual: 1 | 0 // eslint-disable-line
      is_default: 1 | 0 // eslint-disable-line
      caller_id: string // eslint-disable-line
      userId: number
  }
  companyName: string | null
  csr: boolean
  redirect: (path: string) => void
  toggleShowNavigation: (navigationShow: boolean) => void
  callContext
  navigationShow: boolean
  userInfo: {
    name: string
    number: string
    company: string
    accountId: number
    csr: boolean
  }
}

/**
 *
 */
const IdentityArea = (props: Props): JSX.Element => {
    const [showPopup, setShowPopup] = React.useState(false)
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    const theme = useContext(ThemeContext)

    const userInfo = props.userInfo || null
    const { name, company, number } = userInfo
    return (
        <div className={classes.identityAreaWrapper}>
            <div
                className = 'identity'
                onClick = {() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-identity-area-click' })
                    setShowPopup(true)
                }}
                data-test-id = 'identity-element'
            >
                <div className={classes.brandLogo}>
                    <img src={theme?.icons?.identityAreaLogo} alt='brand logo' style={{ width: '100%', height: '100%' }}/>
                </div>
                <div style={{ overflow: 'hidden', paddingRight: '5px' }}>
                    <Typography variant='identityText' className={classes.identityText} color='secondary' data-test-id='identity-name'>{name}</Typography>
                    <Typography variant='identityText' color='secondary'>{company}</Typography>
                    <Typography variant='identityText' color='secondary'>{number}</Typography>
                </div>
            </div>
            {showPopup
                ? <IdentityPopup
                    open
                    onClose = {() => setShowPopup(false)}
                    userInfo = {userInfo}
                />
                : null
            }
        </div>
    )
}

export default IdentityArea
