<template>
  <div ref="create_lr" class="live-answer">
    <w-loader v-if="la.loading || la.appointments_loading" :overlay="la.appointments_loading"/>
    <template v-if="!la.loading || la.appointments_loading">
      <BackButton v-if="!is_part_of_a_modal" absolute/>
      <!-- <div class="w-h4 info--text text--darken-1">
        {{ $lang.t('lr.lets-preview-your-new-script', 'Let\'s preview your new script') }}
      </div>
      <div class="w-body-2 mb-7">
        {{$lang.t('lr.configure-your-script-details-and-how-youre-welcoming-and-routing-customers', 'Configure your script details and how you’re welcoming and routing customers.')}}
      </div> -->
      <w-alert
        v-if="la.alert"
        :message="la.alert.message"
        :level="la.alert.level"
        :closable="la.enable_close_alert"
        @closed="la.alert = null"
        class="mb-6"
      />
      <LiveAnswer
        :_la="la"
        @submitted="createLa"
        :title="$lang.t('lr.create-new-script', 'Create new script')"
        :preselected_vendor_id="preselected_vendor_id"
        :preselected_code="code"
        @click:cancel="onClickCancel ? onClickCancel() : $emit('click:cancel')"
        class="create-page"
      />
    </template>
  </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import {get_account_information, is_nxt} from 'phoenix-session-helpers';
  import LA from '../../../console/src/models/LiveAnswer';
  import LiveAnswer from '../elements/LiveAnswer.vue';
  import BackButton from '../../../console/src/components/elements/BackButton.vue';

  export default {
    props: {
      vendor_id: {
        type: Number,
        required: false,
      },
      code: {
        type: Number,
        required: false,
      },
      postSaveCb: {
        type: Function,
      },
      onClickCancel: {
        type: Function,
      },
    },
    components: {
      LiveAnswer,
      BackButton,
    },
    data() {
      return {
        la: new LA(this.$session, vueComponent(this)),
        preselected_vendor_id: null,
        is_part_of_a_modal: false,
      };
    },
    async created() {
      if (this.$route && this.$route.params && this.$route.params.vendor_id) {
        this.$data.preselected_vendor_id = this.$route.params.vendor_id;
      } else if (this.$props.vendor_id) {
        this.$data.preselected_vendor_id = this.$props.vendor_id;
      }
      await this.$data.la.prepoluate_business_profile();
      this.$data.la.loading = true;
      try {
        this.$session.user.account = await get_account_information(this.$session);
        const user_is_nxt = await is_nxt(this.$session);
        await this.$data.la.prepopulate_contacts(user_is_nxt);
        await this.$data.la.prepare_generic_names();
        this.$data.la.loading = false;
      } catch (e) {
        console.log('error while prepopulating a contact.', e);
        this.$data.la.item.contacts = [JSON.parse(JSON.stringify(this.$data.la.empty_contact))];
        this.$data.la.loading = false;
      }
    },
    mounted() {
      this.$data.is_part_of_a_modal = !this.$route || (this.$refs.create_lr && this.$refs.create_lr.closest('.v-dialog'));
    },
    methods: {
      async createLa(data) {
        const newScript = await this.$data.la.create(data);
        if (this.$data.la.alert && this.$data.la.alert.level === 'success' && this.$props.postSaveCb) {
          this.$props.postSaveCb(newScript);
        }
      }
    }
  };
</script>

<style scoped>
  .wrapper-body{
    min-height: 400px;
  }
</style>