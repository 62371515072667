import Vue from 'vue'
import Vuetify from 'vuetify'
import Theme from './vuetify-setup/theme'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/font-change.sass'
import CustomIcons from './vuetify-setup/custom-icons'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        values: CustomIcons
    },
    theme: { ...Theme, options: { customProperties: true } }
}

export default new Vuetify(opts)
