import React from 'react'
import { Checkbox as MuiCheckbox, FormControlLabel, CheckboxProps as MuiCheckboxProps } from '@material-ui/core'
import { CheckboxOutlineBlankIcon, CheckboxIcon, IndeterminateCheckboxIcon } from 'svg-icons'
import Typography from 'typography'
import { ThemeProvider } from 'theme-provider'

/***/
export enum Variant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

interface CheckboxProps extends MuiCheckboxProps {
    /**
     * Variant of the checkbox
     */
    variant: Variant.PRIMARY | Variant.SECONDARY;
    /**
     * A label which will be placed next to the checkbox
     */
    label?: string;

    // data-test-id attribute value for the label used for automation tests
    labelDataTestId?: string;
}

/**
 * Checkbox component
 *
 * @param {CheckboxProps} props - props
 */
export const Checkbox = (props: CheckboxProps): JSX.Element => {
    const { variant, label, labelDataTestId } = props
    const checkboxComponent = (
        <MuiCheckbox
            disableRipple
            icon = {<CheckboxOutlineBlankIcon/>}
            checkedIcon = {<CheckboxIcon/>}
            indeterminateIcon = {<IndeterminateCheckboxIcon/>}
            color = {variant}
            {...props}
        />
    )
    return (
        <ThemeProvider>
            {label
                ? <FormControlLabel
                    control = {checkboxComponent}
                    label = {<Typography variant='body1'>{label}</Typography>}
                    data-test-id={labelDataTestId || 'mui-checkbox'}
                />
                : checkboxComponent
            }
        </ThemeProvider>
    )
}

const defaultMenuItemProps = {
    label: null
}

Checkbox.defaultProps = defaultMenuItemProps

export default Checkbox
