import React, { Component } from 'react'
import { XIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const styles = theme => ({
    prompt: {
        position: 'fixed',
        color: 'white',
        padding: '13px 50px 14px 18px',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '19px',
        boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
        borderRadius: 8,
        zIndex: 99999,
        '&.closed': {
            bottom: '-500%'
        },
        '& .close-prompt': {
            position: 'absolute',
            right: 7,
            top: 7,
            width: 20,
            height: 20,
            cursor: 'pointer'
        },
        '&.primary': {
            background: theme.palette.primary['0'],
            '& .close-prompt': {
                color: theme.palette.primary['-200']
            }
        },
        '&.secondary': {
            background: theme.palette.secondary['0'],
            '& .close-prompt': {
                color: theme.palette.secondary['-200']
            }
        },
        '&.tertiary': {
            background: theme.palette.tertiary['0'],
            '& .close-prompt': {
                color: theme.palette.tertiary['-200']
            }
        },
        '&.attention': {
            background: theme.palette.attention['0'],
            '& .close-prompt': {
                color: theme.palette.attention['-100']
            }
        },
        '&.important': {
            background: theme.palette.important['0'],
            '& .close-prompt': {
                color: theme.palette.important['-100']
            }
        },
        '&.top': {
            left: '50%',
            transform: 'translateX(-50%)',
            top: 100,
            transition: 'top .3s',
            '&.closed': {
                top: '-100%',
                bottom: 'unset'
            }
        },
        '&.bottom': {
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: 75,
            transition: 'bottom .3s',
            '&.closed': {
                bottom: '-100%'
            }
        },
        '&.left': {
            top: '50%',
            transform: 'translateY(-50%)',
            left: 100,
            transition: 'left .3s',
            '&.closed': {
                left: '-100%',
                bottom: 'unset'
            }
        },
        '&.right': {
            top: '50%',
            transform: 'translateY(-50%)',
            right: 100,
            transition: 'right .3s',
            '&.closed': {
                right: '-100%',
                bottom: 'unset'
            }
        },
        '&.top-left': {
            top: 100,
            left: 100,
            transition: 'left .3s, top .3s',
            '&.closed': {
                left: '-100%',
                top: '-100%',
                bottom: 'unset'
            }
        },
        '&.top-right': {
            top: 100,
            right: 100,
            transition: 'right .3s, top .3s',
            '&.closed': {
                right: '-100%',
                top: '-100%',
                bottom: 'unset'
            }
        },
        '&.bottom-left': {
            bottom: 100,
            left: 100,
            transition: 'left .3s, bottom .3s',
            '&.closed': {
                left: '-100%',
                bottom: '-100%'
            }
        },
        '&.bottom-right': {
            bottom: 100,
            right: 100,
            transition: 'right .3s, bottom .3s',
            '&.closed': {
                right: '-100%',
                bottom: '-100%'
            }
        }
    }
})

class Prompt extends Component {
    componentDidUpdate (prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            // Nothing
        }
    }

    onClose = () => {
        if (!this.props.isOpen) return
        this.props.onClose()
    }

    render () {
        const { classes, color, position, content, className, isOpen } = this.props
        const promptProps = this.props.promptProps || {}
        const openClass = isOpen ? '' : 'closed'
        let classNames = `${this.props.classNames} ${classes.prompt} ${color || ''} ${position || ''} ${openClass}`
        if (className && className.wrapper) classNames += ` ${className.wrapper}`
        return (
            <ClickAwayListener onClickAway={this.onClose}>
                <div {...promptProps} className={classNames} data-test-id={this.props.testId}>{content}<XIcon onClick={this.props.onClose} className='close-prompt'/></div>
            </ClickAwayListener>
        )
    }
}

Prompt.propTypes = {
    /**
     * Set to true/false when you want the prompt to appear/disappear.
     * true or false
     */
    isOpen: PropTypes.bool,
    /**
     * Function which will be called on click on the X button.
     */
    onClose: PropTypes.func,
    /**
     * The color of the prompt
     * 'primary', 'secondary', 'tertiary', 'attention' or 'important'
     */
    color: PropTypes.string.isRequired,
    /**
     * The content of the prompt
     */
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    /**
     * Position of the prompt
     * 'top-left', 'top', 'top-right', 'left', 'right', 'bottom-left', 'bottom' or 'bottom-right'
     */
    position: PropTypes.string.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    classNames: PropTypes.string,
    promptProps: PropTypes.object,
    testId: PropTypes.string
}

Prompt.defaultProps = {
    isOpen: false
}

export default withStyles(styles)(Prompt)
