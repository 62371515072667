export const convertWidthToEm = px => {
  // The px is representation of the desizer px in case font-size is 1px
  return `${parseFloat(px) / 1}em`
}

const styles = theme => ({
  container: {
    position: 'relative'
  },
  avatarWrapper: {
    // border:			'0.5px solid #8FD981',
    // borderRadius:	'50%',
    // padding:		2,
    // background:		'transparent'
  },
  imageWrapper: {
    border:	`${convertWidthToEm(5)} solid #75D063`,
    borderRadius:	'50%',
    '&.noBorder': {
      border:	`${convertWidthToEm(5)} solid white`
    }
  },
  image: {
    borderRadius:	'50%',
    height:	convertWidthToEm(55),
    width:	convertWidthToEm(55)
  },
  textWrapper: {
    position:	'absolute',
    display:	'flex',
    bottom:	convertWidthToEm(-3),
    right:	convertWidthToEm(-3),
    width:	convertWidthToEm(38),
    height:	convertWidthToEm(18),
    borderRadius:	convertWidthToEm(4),
    '& .text': {
      borderRadius:	convertWidthToEm(1 / 3),
      padding:	`${convertWidthToEm(0.5)} ${convertWidthToEm(1 / 3)}`,
      color:	'white',
      fontSize:	convertWidthToEm(12),
      font:	'NTR, Montserrat, Helvetica, arial, sans-serif !important',
      backgroundColor:	'#3FAE29',
      textAlign:	'center',
      letterSpacing:	convertWidthToEm(1 / 60),
      lineHeight:	convertWidthToEm(2 / 3)
    }
  },
  editWrapper: {
    position:	'absolute',
    display:	'flex',
    justifyContent:	'center',
    alignItems:	'center',
    bottom:	convertWidthToEm(-3),
    right:	convertWidthToEm(-3),
    width:	convertWidthToEm(50),
    height:	convertWidthToEm(50),
    borderRadius:	'50%',
    boxShadow:	`${convertWidthToEm(2)} ${convertWidthToEm(3)} ${convertWidthToEm(4)} rgba(0, 0, 0, 0.25)`,
    background:	'black',
    color:	'white',
    cursor:	'pointer'
  },
  cameraIcon: {
    width:	26,
    height:	26
  }
})

export default styles
