<template>
	<div>
		<FormModal
			v-bind="$attrs"
			v-on="$listeners"
			@input="on_modal_input"
			@close="$emit('close')"
			:value="value"
			:prevent_close_instantly="true"
			max-width="550px"
		>
			<div v-if="$slots['title']" class="w-subtitle-1 info--text text--darken-1">
				<slot name="title"/>
			</div>
			<ValidationError v-if="error" class="mt-3">{{Object.values(error).join(('\n'))}}</ValidationError>
			<w-loader v-if="validating || loading" size="small" :message="validating ? l.t('app.validating-address', 'Validating address') : ''"/>
			<AddressForm
				v-else
				v-model="user_input"
				:key="`odal-${value}`"
				:available_countries="['US', 'CA']"
			/>
			<template v-if="!validating" v-slot:buttons>
				<w-btn
					@click="$emit('input', false)"
					color="secondary"
				>
					{{ l.t('app.close', 'Close') }}
				</w-btn>
				<w-btn
					color="primary"
					:disabled="disable_submit()"
					@click="assign_address(true)"
				>
					{{ confirm_btn_text || l.t('app.save', 'Save') }}
				</w-btn>
			</template>
		</FormModal>
		<AddressValidatorModal
			v-if="show_select_address_modal"
			:value="true"
			:validated_address="address_input"
			:user_input="user_input"
			@address_input="on_address_select"
		/>
	</div>
</template>
<script>
    import Cachier from 'cachier';
	import {emptyAddress} from 'helpers';
	import EventBus from 'event-bus/EventBus';
	import {get_account_information, validate_address} from 'phoenix-session-helpers';
    import l from '../../../libs/lang';
    import FormModal from './FormModal.vue';
    import DefaultModal from './DefaultModal.vue';
    import AddressForm from '../form/AddressForm.vue';
	import AddressValidatorModal from './AddressValidatorModal';
	import ValidationError from '../form/FieldValidationError.vue';

    export default {
      props: {
        address_value: { // :_address
            type: [Object],
        },
        validation: {
			type: Boolean,
			default: false,
		},
		prefill_with_cache: {
			type: Boolean,
			default: true,
		},
		confirm_btn_text: {
			type: String,
		},
		value: {
			type: Boolean,
		}
      },
      components: {
		FormModal,
        AddressForm,
        DefaultModal,
		ValidationError,
		AddressValidatorModal,
      },
      data() {
		const empty_address = emptyAddress();
        return {
            l,
			error: null,
            validating: false,
            loading: false,
            empty_address,
			user_input: JSON.parse(JSON.stringify(empty_address)),
			address_input: {},
			last_address_cache_key: 'last-address-used',
			show_select_address_modal: false,
			cachier: new Cachier(this.$session.user.id),
        };
      },
      async created() {
		this.$data.loading = true;
		if (this.$props.address_value) {
			this.$data.user_input = this.$props.address_value;
		} else {
			this.$data.user_input = await this.generate_default_address_object();
		}
		if (Object.keys(this.$data.address_input).length) this.assign_address(false, true);
		this.$global_emitter.$on('form_input_changed', () => this.$data.error = null);
		this.$data.loading = false;
	},
      methods: {
		on_address_select(data) {
			this.$data.address_input = JSON.parse(JSON.stringify(data));
			this.$data.user_input = JSON.parse(JSON.stringify(data));
			this.cachier.setItem(this.$data.last_address_cache_key, this.$data.address_input);
			setTimeout(() => {
				this.$data.show_select_address_modal = false;
				EventBus.$emit('form_submitted');
				this.$emit('input', false);
				this.$emit('confirmed', this.$data.address_input);
			}, 1);
		},
        async assign_address(form_confirmation = false, prevent_address_comparation = false) {
			this.$data.show_select_address_modal = false;
			this.$data.error = null;
			let input;
			if (Object.values(this.$data.user_input).every((x) => !x)) {
				input = null;
			} else {
				input = JSON.parse(JSON.stringify(this.$data.user_input));
			}
			if (this.$props.validation && input.country === 'US'&& !prevent_address_comparation) {
				this.$data.validating = true;
				const is_address_valid = await validate_address(this.$session, input);
				if (is_address_valid.success) {
					this.cachier.setItem(this.$data.last_address_cache_key, input);
					this.$data.address_input = input;
					if (!prevent_address_comparation && is_address_valid.validated_address) {
						this.$data.address_input = is_address_valid.validated_address;
						this.$data.show_select_address_modal = 
							this.$data.address_input
							&& this.$data.user_input
							&& JSON.stringify(this.$data.user_input).toLowerCase() !== JSON.stringify(this.$data.address_input).toLowerCase();
					}
				} else {
					this.$data.error = is_address_valid.message;
				}
				this.$data.validating = false;
			} else {
				this.$data.address_input = input;
			}
			if (form_confirmation && !this.$data.error && !this.$data.show_select_address_modal) {
				this.$emit('confirmed', this.$data.address_input);
				EventBus.$emit('form_submitted');
				this.$emit('input', this.$data.show_select_address_modal);
			}
		},
        disable_submit() {
			for (const prop of ['line_1', 'city', 'country', 'postal_code', 'province']) {
				if (!this.$data.user_input[prop]) return true;
			}
			return JSON.stringify(this.$data.user_input).toLowerCase() === JSON.stringify(this.$props.address_value).toLowerCase();
		},
		async generate_default_address_object() {
			if (this.$props.prefill_with_cache) {
				const last_address_used = this.$data.cachier.getItem(this.$data.last_address_cache_key);
				if (last_address_used) {
					return last_address_used;
				} 
				const user = await get_account_information(this.$session);
				return user.contact.address;
			}
			return emptyAddress();
		},
        async on_modal_input(v) {
			this.$emit('input', v);
        },
      },
	  watch: {
		address_input(v) {
			this.$emit('address_input', v);
			if (['line_1', 'city', 'postal_code', 'province'].find((x) => v[x])) {
				this.cachier.setItem(this.$data.last_address_cache_key, v);
			}
		},
		error(val) {
			this.$emit('error', val);
		},
	  }
    };
</script>

<style lang="scss">
.address-update-alert {
	display: flex !important;
	justify-content: start;
	padding: 0 10px;
	margin-bottom: 30px;
	.v-icon {
		margin-right: 6px;
	}
}
.address-radio-selector {
	min-width: 100%;
	.v-radio{
		align-items: start;
		border-radius: 10px;
		border: 1px solid var(--v-secondary-base);
		padding: 15px 15px 15px 20px;
		width: 46%;
		min-height: 110px !important;
	}
	.v-item--active {
		border-radius: 10px;
		border: 2px solid var(--v-text-primary, #364047);
	}
	.v-input--radio-group__input {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
		width: 100%;
		.v-radio:nd-of-child(2) {
			text-align: right;
		}
	}
}
</style>
