// React
import React from "react";

// Stylesheet
import "./reserved-numbers.css";

// User Selected Numbers
class ReservedNumbers extends React.Component {
  render() {
    let hidden = false //(this.props.reservedNumbers.length > 4 && this.props.mode == 'vanity');
    return (
      <div className="numbers-list row" style={{visibility: hidden ? 'hidden' : 'visible'}}>
        {this.props.reservedNumbers.map((number, index) => {
          let price;
          if (number.price == null || number.price === 0) {
            price = <span className="price price-free">FREE</span>;
          } else {
            price = <span className="price">${number.price}</span>;
          }
          return (
            <div key={number.phone_number} className="search-result">
              <li>
                <span
                  className="reserved-number"
                  dangerouslySetInnerHTML={{ __html: number.pretty_formatted }}
                />
                {price}
                <span
                  onClick={() => {
                    this.props.removeNumber(number);
                  }}
                  className="remove-number"
                >
                  ✕
                </span>
              </li>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ReservedNumbers;
