import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
    checkedBorderTheme: {
        border: `2px solid ${theme.palette.primary.main}`
    },
    uncheckedBorderTheme: {
        border: '2px solid #000'
    },
    checkboxDiv: {
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '4px',
        background: 'white',
        marginRight: '5px'
    },
    checkMark: {
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        width: '16px',
        height: '10px',
        transform: 'rotate(315deg)',
        marginTop: '3px',
        transition: '0.2s opacity, 0.2s border',
        '&.not-shown': {
            opacity: 0
        }
    }
})
/**
 * @deprecated - Use newer version [Checkbox](/#checkbox)
 */
class PDCCheckbox extends Component {
    render () {
        const { classes } = this.props
        return (
            <div className={`${this.props.checked ? classes.checkedBorderTheme : classes.uncheckedBorderTheme} ${classes.checkboxDiv}`}>
                <div className={`${classes.checkMark} ${this.props.checked ? '' : 'not-shown'}`}></div>
            </div>
        )
    }
}

PDCCheckbox.propTypes = {
    classes: PropTypes.object,
    checked: PropTypes.bool
}

export default withStyles(styles)(PDCCheckbox)
