import { combineReducers } from 'redux'
import { smallView, currentView } from './view'
import { currentExtension } from './pdcuser'
import { users, tempUsers } from './users'
import { numbers, newNumbers } from './numbers'


export default combineReducers({
	smallView,
	currentView,
	currentExtension,
	users,
	tempUsers,
	numbers,
	newNumbers
})