import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const NumberAssignmentIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#364047'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M24.5326 16.9458L26.929 14.111L24.547 12.1499C24.3803 12.0126 24.4543 11.7428 24.6678 11.7098L31.4471 10.6634C31.5912 10.6411 31.7237 10.7465 31.7345 10.8919L32.2563 17.9072C32.2725 18.1251 32.021 18.2575 31.8505 18.1207L29.3765 16.1348L26.739 19.4313C26.1696 20.1429 25.3078 20.5571 24.3965 20.5571H23.0981C22.8158 20.5571 22.5466 20.4378 22.3571 20.2286L20.4032 18.0725C20.2576 17.9118 20.3716 17.6546 20.5884 17.6546H23.0052C23.5938 17.6546 24.1526 17.3953 24.5326 16.9458ZM7.75 18.1347V20.0357C7.75 20.3118 7.97386 20.5357 8.25 20.5357H15.3232C15.6052 20.5357 15.8741 20.6547 16.0636 20.8635L21.11 26.422L19.2148 28.2224C19.0598 28.3697 19.1492 28.631 19.3619 28.6524L26.1483 29.3369C26.306 29.3528 26.4385 29.2198 26.422 29.0622L25.7434 22.5754C25.7218 22.3692 25.4729 22.2774 25.3226 22.4202L23.4003 24.2463L18.2727 18.3254C17.8928 17.8868 17.3412 17.6347 16.7609 17.6347H8.25C7.97386 17.6347 7.75 17.8586 7.75 18.1347Z' fill='white'/>
        </SvgIcon>
    )
}

export default NumberAssignmentIcon
