import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/JQacodb8V4YdmDt3Awk2zl/Call-handling?node-id=2529%3A10295
 *
 * @param {SvgIconProps} props - svg props
 */
export const NoAnswerIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#2A9BB7'/>
            <path d='M20 21.8059C18.5333 21.8059 17.1125 22.0316 15.7833 22.456V25.2551C15.7833 25.6072 15.5725 25.9233 15.27 26.0677C14.3717 26.5102 13.5558 27.079 12.8317 27.7381C12.6667 27.9007 12.4375 27.991 12.19 27.991C11.9333 27.991 11.7042 27.8916 11.5392 27.7291L9.26583 25.4898C9.10083 25.3363 9 25.1106 9 24.8578C9 24.605 9.10083 24.3792 9.26583 24.2167C12.0617 21.6072 15.8383 20 20 20C24.1617 20 27.9383 21.6072 30.7342 24.2167C30.8992 24.3792 31 24.605 31 24.8578C31 25.1106 30.8992 25.3363 30.7342 25.4989L28.4608 27.7381C28.2958 27.9007 28.0667 28 27.81 28C27.5625 28 27.3333 27.9007 27.1683 27.7472C26.4442 27.079 25.6192 26.5192 24.7208 26.0767C24.4183 25.9323 24.2075 25.6253 24.2075 25.2641V22.465C22.8875 22.0316 21.4667 21.8059 20 21.8059Z' fill='white'/>
            <path d='M20.9091 14.5455L20.5987 15.2282L21.0034 15.4122L21.3591 15.1455L20.9091 14.5455ZM27.1061 10.5606C27.1646 10.1506 26.8797 9.77066 26.4697 9.71208L19.7875 8.75749C19.3775 8.69891 18.9976 8.98383 18.939 9.39389C18.8804 9.80394 19.1653 10.1838 19.5754 10.2424L25.5151 11.0909L24.6665 17.0306C24.608 17.4407 24.8929 17.8206 25.3029 17.8792C25.713 17.9377 26.0929 17.6528 26.1515 17.2428L27.1061 10.5606ZM10.5987 10.6828L20.5987 15.2282L21.2194 13.8627L11.2194 9.31723L10.5987 10.6828ZM21.3591 15.1455L26.8136 11.0545L25.9136 9.85455L20.4591 13.9455L21.3591 15.1455Z' fill='white'/>
        </SvgIcon>
    )
}

export default NoAnswerIcon
