import PhoneComUser from 'phone-com-user'
import axios from 'axios'

/**
 * checks if the var is an object or not
 *
 * @param {any}a - anything
 */
const isObj = function (a) {
    if ((!!a) && (a.constructor === Object)) {
        return true
    }
    return false
}
/**
 * helps build prefix for url params, will wrap prefix in [prefix]item if given
 *
 * @param {string} param - param/ item
 * @param {string} prefix - prefix to put in front of item
 */
const _toParamArrayHelper = function (param, prefix) {
    return '' + (prefix !== '' ? '[' : '') + param + (prefix !== '' ? ']' : '')
}
/**
 * will take a json object and convert them to url params
 *
 * @param {object| string} params - current params or Json object to convert to params
 * @param {boolean}skipObjects - whether to skip the param field objects
 * @param {string}prefix - the prefix to the param objects
 */
const toParam = function (params, skipObjects, prefix) {
    if (skipObjects === undefined) {
        skipObjects = false
    }
    if (prefix === undefined) {
        prefix = ''
    }
    let result = ''
    if (typeof (params) !== 'object') {
        return prefix + '=' + encodeURIComponent(params) + '&'
    }
    for (var param in params) {
        const c = '' + prefix + _toParamArrayHelper(param, prefix)
        if (isObj(params[param]) && !skipObjects) {
            result += toParam(params[param], false, '' + c)
        } else if (Array.isArray(params[param]) && !skipObjects) {
            result += c + '=['
            params[param].forEach(function (item, ind) {
                result += toParam(`"${item}"`, false).replace('=', '').replace('&', '')
                if (ind < params[param].length - 1) {
                    result += ','
                }
            })
            result += ']&'
            console.log(result)
        } else {
            result += c + '=' + encodeURIComponent(params[param]) + '&'
        }
    }
    return result
}
/**
 * This is a wrapper around axios for all our api calls
 * Handles getting user token and catching 403 errors
 * use this instead of axios in the same way
 *
 * @public
 */
class Ajax {
    /**
     * Ajax constructor
     */
    constructor () {
        const ajax = axios.create()
        ajax.interceptors.response.use(this.onSuccess, this.onError)
        this.ajax = ajax
    }

    /**
     * gets the headers given the scheme, mostly Authorization: `CP ${pdc_token}` or `Bearer ${pdc_token}`
     *
     * @param {string} scheme - the scheme being used, cp or bearer mostly, Bearer is the likely choice if unknown
     */
    getHeaders = (scheme) => {
        const pdc_token = PhoneComUser.getToken()
        return {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${scheme} ${pdc_token}`
            }
        }
    }

    /**
     * The on error override happens when an error is detected during a request
     *
     * @param {object} error - the error
     * @public
     */
    onError = (error) => {
        return error

        // return Promise.reject(error) // reject if network error

        // switch (error?.code) {
        //         case 403:
        //             // Append an error modal prompting user to relogin
        //             // ReactDOM.render(<ErrorOverlay message={"Authentication Error"} redirect={PhoneComUser.getRedirectUrl()}/>, document.getElementById('error-root'))
        //             return error
        //         default:
        //             return error
        // }
    }

    /**
     * called when a call is successful
     *
     * @param {object} response - the response of the request
     * @public
     */
    onSuccess = response => {
        if (typeof response.data === 'object' && response.data !== null && 'error' in response.data) { return this.onError(response.data.error) } else return response
    }

    /**
     * a get request using ajax
     *
     * @param {string} path - url to call as get including url params
     * @param {string} scheme - scheme used (CP or Bearer)
     * @public
     */
    get (path, scheme = null) {
        scheme = scheme || PhoneComUser.getScheme()
        return this.ajax.get(path, this.getHeaders(scheme))
    }

    /**
     * Post request converted to a Get request. This is done in a best effort way. This method was mostly made to support changes to convert over to use service worker caching.
     * this will take a payload as a json and try to convert it to url params
     *
     * @param {string} path - only the url and path - do not include any url params
     * @param {object} payload - payload as json
     * @param {string} scheme - scheme (CP or Bearer)
     * @param {object} config - additonal ajax configuations
     * @public
     */
    postAsGet (path, payload, scheme = null, config = {}) {
        scheme = scheme || PhoneComUser.getScheme()
        payload.account_id = PhoneComUser.getAPIAccountId()
        payload.extension_id = payload.extension_id ? payload.extension_id : PhoneComUser.getExtensionId()

        path += `?${toParam(payload)}`
        return this.ajax.get(path, Object.assign(config, this.getHeaders(scheme)))
    }

    /**
     * An ajax post request.
     * This will automatically append the current extension and account id (voip_id) to the payload before sending.
     *
     * @param {string} path - url and path without url params
     * @param {object} payload - payload to send
     * @param {string} scheme - scheme to use (CP or Bearer normally)
     * @param {object} config - additional ajax configs
     * @public
     */
    post (path, payload, scheme = null, config = {}) {
        scheme = scheme || PhoneComUser.getScheme()
        payload.account_id = PhoneComUser.getAPIAccountId()
        payload.extension_id = payload.extension_id ? payload.extension_id : PhoneComUser.getExtensionId()
        return this.ajax.post(path, payload, Object.assign(config, this.getHeaders(scheme)))
    }

    postClean (path, payload, scheme = null, config = {}) {
        scheme = scheme || PhoneComUser.getScheme()
        return this.ajax.post(path, payload, Object.assign(config, this.getHeaders(scheme)))
    }

    /**
     * An ajax post request.
     * This will automatically append the current account id (voip_id) to the payload before sending.
     *
     * @param {string} path - url and path without url params
     * @param {object} payload - payload to send
     * @param {string} scheme - scheme to use (CP or Bearer normally)
     * @param {object} config - additional ajax configs
     * @public
     */
    postAccount (path, payload, scheme = null, config = {}) {
        scheme = scheme || PhoneComUser.getScheme()
        payload.account_id = PhoneComUser.getAPIAccountId()
        return this.ajax.post(path, payload, Object.assign(config, this.getHeaders(scheme)))
    }

    /**
     * An ajax patch request.
     * This will automatically append the current extension and account id (voip_id) to the payload before sending.
     *
     * @param {string} path - url and path without url params
     * @param {object} payload - payload to send
     * @param {string} scheme - scheme to use (CP or Bearer normally)
     * @param {object} config - additional ajax configs
     * @public
     */
    patch (path, payload, scheme = null, config = {}) {
        scheme = scheme || PhoneComUser.getScheme()
        payload.account_id = PhoneComUser.getAPIAccountId()
        payload.extension_id = payload.extension_id ? payload.extension_id : PhoneComUser.getExtensionId()
        return this.ajax.patch(path, payload, Object.assign(config, this.getHeaders(scheme)))
    }

    patchClean (path, payload, scheme = null, config = {}) {
        scheme = scheme || PhoneComUser.getScheme()
        return this.ajax.patch(path, payload, Object.assign(config, this.getHeaders(scheme)))
    }

    /**
     * An ajax put request.
     * This will automatically append the current extension and account id (voip_id) to the payload before sending.
     *
     * @param {string} path - url and path without url params
     * @param {object} payload - payload to send
     * @param {string} scheme - scheme to use (CP or Bearer normally)
     * @param {object} config - additional ajax configs
     * @public
     */
    put (path, payload, scheme = null, config = {}) {
        scheme = scheme || PhoneComUser.getScheme()
        payload.account_id = PhoneComUser.getAPIAccountId()
        payload.extension_id = payload.extension_id ? payload.extension_id : PhoneComUser.getExtensionId()
        return this.ajax.put(path, payload, Object.assign(config, this.getHeaders(scheme)))
    }

    /**
     * An delete patch request.
     * This will automatically append the current extension and account id (voip_id) to the payload before sending.
     *
     * @param {string} path - url and path without url params
     * @param {object} payload - payload to send
     * @param {string} scheme - scheme to use (CP or Bearer normally)
     * @param {object} config - additional ajax configs
     * @public
     */
    delete (path, payload, scheme = null, config = {}) {
        scheme = scheme || PhoneComUser.getScheme()
        payload.account_id = PhoneComUser.getAPIAccountId()
        payload.extension_id = payload.extension_id ? payload.extension_id : PhoneComUser.getExtensionId()
        // axios.delete does support a request body, but it must be included in
        // optional config. Here we use config.data to set the request body
        return this.ajax.delete(path, Object.assign(config, this.getHeaders(scheme)))
    }
}

export default new Ajax()
