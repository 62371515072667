// React
import React from "react";

// Stylesheet
import "./loader.css";

// Loader
class Loader extends React.Component {
  render() {
    // Handle when to hide and show the loader
    let loading = this.props.loading;
    let loader;
    if (loading === true) {
      loader = "loader show";
    } else {
      loader = "loader hidden";
    }
    return (
      <div className={loader}>
        <div
          className="phonering-alo-phone phonering-alo-green phonering-alo-show"
          id="phonering-alo-phoneIcon"
        >
          <div className="phonering-alo-ph-circle" />
          <div className="phonering-alo-ph-circle-fill" />
          <span className="pps-btn-img">
            <div className="phonering-alo-ph-img-circle" />
          </span>
        </div>
      </div>
    );
  }
}

export default Loader;
