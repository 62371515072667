import React, { Component } from 'react'

import Draggable from 'react-draggable'

// import { AudioPlayerControlSliderIcon } from 'pdc-svg-icons'
import { NotificationDotIcon as AudioPlayerControlSliderIcon } from 'svg-icons'
import { DefaultArrowTooltip } from 'tooltips'
import { DownloadIcon } from 'svg-icons'
import { withStyles } from '@material-ui/styles'
import ActionButton from './ActionButton'

import styles from './styles'
import Typography from 'typography'

function formatSecondsToMinutes (seconds) {
    let minutes = Math.floor(seconds / 60)
    if (isNaN(minutes)) minutes = 0
    seconds = (seconds - (minutes * 60)).toFixed(0)
    if (isNaN(seconds)) seconds = 0
    if (seconds < 10) seconds = '0' + seconds
    return (`${minutes}:${seconds}`)
}

interface Props {
    classes
    url: string
    preload?: '' | 'none' | 'metadata' | 'auto'
    duration?: number
    onError?: () => void
    onPlay?: (isPlaying: boolean) => void
    onReadyToPlay?: () => void
    disabled?: boolean
    playFromSecond?: number
    downloadable?: boolean
    name?: string
    loadUrl?: () => Promise<string>
    loading?: boolean
    label?: string
    playButtonOnly?: boolean
    size?: 'small' | 'big'
    stopPlaying?: boolean
    volume: number
}

interface State {
    progress: number
    tempProgress: number
    selected: boolean
    play: boolean
    duration: number | string
    volume: { value: number }
    error: boolean
    playerControlPosition: { x: number, y: number }
    dragState: 'active' | 'inactive'
    loading: boolean
    dragTime: number | string
    onUrlPlay: boolean
}

/**
 *
 */
export class AudioPlayer extends Component<Props, State> {
    seekRef: React.RefObject<HTMLDivElement>
    progressRef: React.RefObject<HTMLElement>
    audio: HTMLAudioElement
    mounted: boolean
    /**
     *
     */
    constructor (props) {
        super(props)
        this.seekRef = React.createRef()
        this.progressRef = React.createRef()
        this.state = this.getDefaultState()
        this.audio = this.createAudio()
    }

    /**
     *
     */
    getDefaultState = (): State => {
        return {
            progress: 0,
            selected: false,
            play: false,
            duration: this.props.duration || 0,
            volume: { value: this.props.volume || 50 },
            error: false,
            playerControlPosition: null,
            dragState: 'inactive',
            loading: false,
            dragTime: null,
            tempProgress: 0,
            onUrlPlay: false
        }
    }

    /**
     *
     */
    createAudio = () => {
        const audio = new Audio(this.props.url)
        audio.preload = this.props.preload || 'auto'
        audio.volume = this.state.volume.value / 100
        /**
         *
         */
        audio.onerror = () => {
            if (!this.mounted) return
            if (!this.props.url && this.props.loadUrl) return
            this.props.onError?.()
            this.setState({ error: true })
        }
        /**
         *
         */
        audio.onpause = () => {
            this.props.onPlay?.(false)
        }
        /**
         *
         */
        audio.onplay = () => {
            this.props.onPlay?.(true)
        }
        return audio
    }

    // Pause player when disable flag is triggered
    /**
     *
     */
    componentDidUpdate (prevProps) {
        if (!prevProps.duration && this.props.duration) this.props.onReadyToPlay?.()
        if (prevProps.url !== this.props.url) {
            this.audio.pause()
        }
        if (this.props.disabled && this.state.play) {
            this.setState({ play: false })
            this.audio.pause()
        }
        if (prevProps.url !== this.props.url) {
            this.audio = new Audio(this.props.url)
            this.setState({ play: false, duration: 0, progress: 0, playerControlPosition: null }, this.resetEventListeners)
            if (this.state.onUrlPlay) {
                this.setState({ onUrlPlay: false }, this.toggleAudio)
            }
        }
        if (this.props.playFromSecond && prevProps.playFromSecond !== this.props.playFromSecond) {
            let playFromSecond = this.props.playFromSecond
            const duration = this.getDuration()
            if (playFromSecond > duration) playFromSecond = duration
            const progress = 100 * playFromSecond / duration
            this.audio.currentTime = playFromSecond
            this.setState({ progress })
            if (!this.state.play) this.toggleAudio()
        }
        if (!prevProps.stopPlaying && this.props.stopPlaying) {
            this.setState({ play: false })
            this.audio.pause()
            this.audio.currentTime = 0
            this.setState({ progress: 0 })
        }
    }

    /**
     *
     */
    resetEventListeners = () => {
        this.audio.removeEventListener('loadedmetadata', this.initializeAudioState)
        this.audio.removeEventListener('timeupdate', this.updateCurrentTime)
        this.audio.addEventListener('loadedmetadata', this.initializeAudioState)
        this.audio.addEventListener('timeupdate', this.updateCurrentTime)
    }

    /**
     *
     */
    componentWillUnmount = () => {
        this.audio.removeEventListener('loadedmetadata', this.initializeAudioState)
        this.audio.removeEventListener('timeupdate', this.updateCurrentTime)
        this.audio.pause()
        this.mounted = false
    }

    /**
     *
     */
    controlSeek = event => {
        if (Array.from(event.target.classList).includes('player-control')) return
        const seekbarRect = this.seekRef.current.getBoundingClientRect()
        const left = event.pageX - seekbarRect.left
        const percentage = left / seekbarRect.width
        const seekTime = this.getDuration() * percentage
        this.audio.currentTime = seekTime
        this.setState({ progress: percentage * 100, playerControlPosition: { x: left, y: 0 } })
    }

    /**
     *
     */
    toggleAudio = (replay = false) => {
        if (this.state.play === true) {
            this.setState({ play: false })
            this.audio.pause()
            if (replay) {
                this.audio.currentTime = 0
                this.setState({ progress: 0 }, this.toggleAudio)
            }
        } else {
            this.setState({ loading: true })
            if (!this.props.url && this.props.loadUrl) {
                this.setState({ ...this.getDefaultState(), loading: true, onUrlPlay: true }, this.createAudio)
                /* const url = */this.props.loadUrl()
                return
            }
            this.audio.play().then(() => {
                this.setState({ play: true, loading: false })
            }).catch(e => {
                this.props.onError?.()
                this.setState({ error: true, loading: false })
                console.log('unable to play audio', e)
                window.alert('unable to play audio')
            })
        }
    }

    /**
     *
     */
    initializeAudioState = (/* event */) => {
        this.setState({
            error: false,
            duration: this.getDuration().toFixed(0)
        })
        this.props.onReadyToPlay?.()
    }

    /**
     *
     */
    updateCurrentTime = (/* event */) => {
        this.setState({ progress: this.audio.currentTime / this.getDuration() * 100 })
        if (this.state.dragState === 'inactive') {
            const playerControlPosition = { x: this.progressRef.current?.offsetWidth || 0, y: 0 }
            this.setState({ playerControlPosition })
        }
        if (this.state.progress >= 100) {
            this.setState({ play: false })
        }
    }

    /**
     *
     */
    adjustVolume = event => {
        this.setState({ volume: { value: event.target.value } })
        this.audio.volume = this.state.volume.value / 100
    }

    /**
     *
     */
    getDuration = () => (this.props.duration || this.props.duration === 0) ? this.props.duration : this.audio.duration

    /**
     *
     */
    componentDidMount = () => {
        this.mounted = true
        this.resetEventListeners()
    }

    /**
     *
     */
    onDragStart = () => this.setState({ dragState: 'active' })

    /**
     *
     */
    onDragStop = (e, info) => {
        this.setState({ dragState: 'inactive' })
        const parentWidth = this.seekRef.current.offsetWidth
        const positionX = info.x
        const progressWidth = 100 * positionX / parentWidth
        this.setState({ progress: progressWidth, dragTime: null })

        const seekTime = this.getDuration() * progressWidth / 100
        this.audio.currentTime = seekTime
    }

    /**
     *
     */
    controlDragged = (e, info) => {
        this.setState({ playerControlPosition: null })

        const parentWidth = this.seekRef.current.offsetWidth
        const positionX = info.x
        const progressWidth = 100 * positionX / parentWidth

        if (!this.state.play) {
            this.setState({ progress: progressWidth })
        }

        const dragTime = formatSecondsToMinutes(this.getDuration() * progressWidth / 100)
        this.setState({ dragTime, tempProgress: progressWidth })
    }

    /**
     *
     */
    render = () => {
        const { classes, label, playButtonOnly, size } = this.props
        const { loading, duration } = this.state
        const styles = { seek: { width: `${this.state.progress}%` } }
        const currentTime = formatSecondsToMinutes(this.audio.currentTime <= this.getDuration() ? this.audio.currentTime : this.getDuration())
        const totalTime = formatSecondsToMinutes(duration)

        return (
            <div className={`${classes.audioControlContainer} ${playButtonOnly ? 'fit-content' : ''}`}>
                <ActionButton
                    classes = {classes}
                    loading = {loading || !!this.props.loading}
                    isPlaying = {playButtonOnly ? false : this.state.play}
                    onClick = {!loading ? this.toggleAudio.bind(this, !!playButtonOnly) : null}
                    size = {size || 'big'}
                />
                {!playButtonOnly
                    ? <div className={classes.progressAndTimingWrapper}>
                        {label ? <div title={label} className={classes.labelWrapper}><Typography variant='subtitle2'>{label}</Typography></div> : null}
                        <div
                            className = {`${classes.audioControlSeekbar} ${this.state.error ? classes.seekBarErrorBorder : classes.seekBarBorder}`}
                            onClick = {duration ? this.controlSeek : null}
                            ref = {this.seekRef}
                        >
                            <span className='progress-bar' style={styles.seek} ref={this.progressRef}>
                                <Draggable
                                    disabled = {!duration}
                                    axis = 'x'
                                    grid = {[1, 0]}
                                    bounds = {{ left: 0, right: this.seekRef.current ? this.seekRef.current.offsetWidth : 0 }}
                                    positionOffset = {{ x: -17.5, y: 0 }}
                                    onStart = {this.onDragStart}
                                    onStop = {this.onDragStop}
                                    onDrag = {this.controlDragged}
                                    position = {this.state.playerControlPosition}
                                >
                                    <DefaultArrowTooltip
                                        title = {this.state.dragTime || currentTime}
                                        placement = 'top'
                                    >
                                        <div className={`player-control ${this.state.dragState === 'active' ? 'force-show' : ''}`}><AudioPlayerControlSliderIcon/></div>
                                    </DefaultArrowTooltip>
                                </Draggable>
                            </span>
                            {this.state.dragState === 'active' && this.state.play
                                ? <span style={{ width: `${this.state.tempProgress}%` }} className='temp-progress-bar'></span>
                                : null
                            }
                        </div>
                        <div className={classes.timeInfo}>
                            <span>{currentTime}</span>
                            <span>{totalTime}</span>
                        </div>
                    </div>
                    : null
                }

                {this.props.downloadable
                    ? <a
                        href = {this.props.url}
                        download = {this.props.name || 'newname'}
                        target = '_blank'
                        className = {classes.downloadStyle}
                        rel = 'noreferrer'
                    >
                        <DownloadIcon/>
                    </a>
                    : ''
                }
            </div>
        )
    }
}

/**
 *
 */
export const AudioPlayerWithClasses = withStyles(styles)(AudioPlayer)

export default AudioPlayerWithClasses
