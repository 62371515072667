<template>
    <w-loader v-if="order.loading"/>
    <div v-else class="buy-device" data-test-id="buy-device-list">
      <w-alert
        v-if="order.alert"
        :message="order.alert.message"
        :level="order.alert.level"
        class="mb-6"
      />
      <w-stepper v-if="stepper.items.length" v-model="stepper.active" alt-labels>
        <v-stepper-header :style="{'maxWidth': '746px'}" class="mx-auto">
          <template v-for="(step, index) of stepper.items">
            <v-stepper-step
              :complete="max_step >= index + 1 && previous_steps_completed(index, step) && step.completed() && stepper.active !== index + 1"
              complete-icon="$vuetify.icons.check"
              :step="index + 1"
              @click="step_on_click(index, step)"
              class="cursor-pointer"
              :key="`stepper-${index}`"
            >
              {{ step.title }}
            </v-stepper-step>

            <v-divider v-if="index < stepper.items.length - 1"/>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <ChooseYourPhonesStep
              v-if="stepper.active === 1"
              :devices="order.devices"
              :accessories="order.accessories"
              :types="[{value: null, text: $lang.t('orders.all-devices', 'All devices')}].concat(order.devices_types)"
              :_cart="cart"
              :available_users="virtual_extensions.length"
              :cart_total_price="cartTotalPrice"
              @submitted="devices_choosen"
              @added="device_added"
              @removed="device_removed"
              @added_accessory="accessory_added"
              @removed_accessory="accessory_removed"
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <SetupYourPhones
              v-if="stepper.active === 2"
              :cart="cart"
              :order="order"
              :extensions="virtual_extensions"
              @submitted="set_up_submitted($event, true)"
              @updated="set_up_submitted($event, false)"
              @back="back_to_choose_device"
              @validation_failed="max_step = 2"
              :key="`2-${stepper.active}`"
            />
          </v-stepper-content>

          <v-stepper-content step="3">
            <ShippingDetails
              v-if="stepper.active === 3"
              :cart="cart"
              :order="order"
              @submitted="shipping_details_submitted($event, true)"
              @updated="shipping_details_submitted($event, false)"
              @back="on_back_from_step_3"
              :shipping_address_contact="shipping_address"
              :shipping="shipping"
            />
          </v-stepper-content>

          <v-stepper-content step="4">
            <PaymentMethods
                v-model="payment_method"
                @continue="stepper.active = 5"
                @back="stepper.active = 3"
              />
          </v-stepper-content>
          <v-stepper-content step="5">
            <FinalTable
              v-if="stepper.active === 5"
              :cart="order.transform_cart_array_to_object(cart)"
              :shipping="shipping"
              :shipping_address="shipping_address"
              :payment_method="payment_method"
            />
            <StepperButtons
              :disable_continue="!cartLength()"
              @continue="submit_order"
              @back="stepper.active = 4"
              :continue_text="$lang.t('devices.place-order', 'Place order')"
            />
            <DefaultModal
              v-if="confirm_question"
              :value="true"
              @close="confirm_question = null"
              max-width="500px"
              :with_x="false"
              :message="confirm_question"
              data-test-id="buy-device-confirm-confirmation-modal"
            >
              <div class="w-body-1">{{ confirm_question }}</div>
              <template v-slot:buttons>
                <w-btn @click="confirm_question = null" color="secondary" class="mr-5">{{ $lang.t('app.cancel', 'Cancel') }}</w-btn>
                <w-btn @click="confirmed" color="primary">{{$lang.t('app.confirm', 'Confirm')}}</w-btn>
              </template>
            </DefaultModal>
          </v-stepper-content>
        </v-stepper-items>
      </w-stepper>
      <DefaultModal :value="show_backordered_devices_modal" :with_x="false" persistent max-width="350" @close="show_backordered_devices_modal = false">
        <div class="mb-5">{{$lang.t('devices.continued-disruptions-to-global-supply-chains-is-causing-many-phones-to-be-backordered', 'Continued disruptions to global supply chains is causing many phones to be backordered.')}}</div>
        <div class="mb-5">{{$lang.t('devices.we-at-Phone.com-will-do-our-best-to-indicate-all-backordered-products-along-with-any-known-ETA', 'We at Phone.com will do our best to indicate all backordered products along with any known ETA.')}}</div>
        <div>{{$lang.t('devices.if-you-wish-to-order-any-backordered-items-you-may-do-so,-and-we-will-ship-it-as-soon-as-its-in-stock', 'If you wish to order any backordered items you may do so, and we will ship it as soon as it\'s in stock.')}}</div>        
        
        <template v-slot:buttons>
          <v-spacer></v-spacer>
          <w-btn @click="show_backordered_devices_modal = false" color="primary" class="pull-right">{{$lang.t('app.i-understand', 'I Understand')}}</w-btn>
        </template>
      </DefaultModal>
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import { formatPrice } from 'formatters';
  import {nxt_details, is_nxt } from 'phoenix-session-helpers';
  import Order from '../../../console/src/models/Order';
  import FinalTable from '../elements/final-table.vue';
  import PaymentMethods from '../elements/payment-methods.vue';
  import ShippingDetails from '../elements/shipping-details.vue';
  import SetupYourPhones from '../elements/setup-your-phones.vue';
  import ChooseYourPhonesStep from '../elements/choose-your-phones.vue';
  import StepperButtons from '../../../console/src/components/elements/StepperButtons.vue';
  import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
  import SectionTitle from '../../../console/src/components/elements/SectionTitle.vue';
  import {getFeatureEnabled} from 'feature-flag';

  export default {
    props: {
      id: Number,
      _device: Object,
      postSaveCb: Function,
    },
    components: {
      FinalTable,
      DefaultModal,
      SectionTitle,
      StepperButtons,
      PaymentMethods,
      ShippingDetails,
      SetupYourPhones,
      ChooseYourPhonesStep,
    },
    data() {
      const default_shipping = {
        'value': 'FEDEXGROUND',
        'name': this.$lang.t('shipping.FEDEXGROUND-title', 'Standard Shipping'),
        'code': 30100,
        'code_name': 'SHIPPING',
        'description': this.$lang.t('shipping.FEDEXGROUND-description', 'Up to 5 business days based on distance to destination'),
        'total': 0,
        'price': 0,
        'taxes': 0,
      }
      return {
        item: null,
        virtual_extensions: [],
        order: new Order(this.$session, vueComponent(this)),
        cart: {devices: [], accessories: []},
        cartCacheKey: 'global_cart',
        stepper: {
          active: 1,
          items: [],
        },
        shipping: {...default_shipping},
        default_shipping: {...default_shipping},
        shipping_address: null,
        payment_method: null,
        confirm_question: null,
        tab: 'list',
        max_step: 1,
        show_backordered_devices_modal: true,
      };
    },
    async created() {
      let cart_cache = this.$data.order.cachier.getItem(this.$data.cartCacheKey);
      if (cart_cache && Array.isArray(cart_cache)) { // structure changed to object, so we avoid bugs
        this.$data.order.cachier.removeItem(this.$data.cartCacheKey);
        cart_cache = null;
      }
      if (cart_cache) {
        this.$data.cart = cart_cache;
      }
      this.$data.stepper.items = [
        {
          title: this.$lang.t('devices.choose-your-phones', 'Choose your phones'),
          completed: () => this.cartLength() > 0,
        },
        {
          title: this.$lang.t('devices.setup-your-phones', 'Set up your phones'),
          completed: () => {
            return this.cartLength() > 0
            && (
              !this.$data.cart.devices.length
              || (
                this.$data.cart.devices.length
                && this.$data.cart.devices.every((x) => x._custom_setup.address)
              )
            )
          }
        },
        {
          title: this.$lang.t('devices.shipping-details', 'Shipping details'),
          completed: () => this.$data.shipping && this.shipping_address,
        },
        {
          title: this.$lang.t('billing.payment-methods', 'Payment methods'),
          completed: () => !!this.$data.payment_method,
        },
        {
          title: this.$lang.t('devices.confirm-your-order', 'Confirm your order'),
          completed: () => false,
        },
      ];
      this.$data.order.loading = true;
      const promises_arr = [
        this.load_extensions(),
        this.$data.order.loadDevicesPriceList(),
      ];

      if (await getFeatureEnabled('allow_order_accessories')) {
        promises_arr.push(this.$data.order.loadAccessoriesPriceList())
      }
      const promises = await Promise.all(promises_arr);
      this.$data.virtual_extensions = promises[0];
      this.$data.order.devices_types = this.$data.order.generateDeviceTypes();
      this.$data.order.loading = false;
    },
    methods: {
      async load_extensions() {
        try {
          let uri = '/extensions?filters[eligible_for_device]=1';
          if (await is_nxt(this.$session)) {
            uri = `${uri}&filters[nxt_extensions]=1`;
          }
          let extensions = await this.$session.get_list_all(uri);
          extensions = extensions.items.filter((x) => {
            if (
              x
              && x.device_membership
              && x.device_membership.device
              && !['iphone', 'generic', 'softphone'].includes(x.device_membership.device.type)
            ) {
              return false;
            }

            return true;

          });

          if (await is_nxt(this.$session)) {
            const nxt_user_details = await nxt_details(this.$session);
            if (nxt_user_details.company_inbox_extension) {
              extensions = extensions.filter((x) => x.id !== nxt_user_details.company_inbox_extension);
            }
          }

          return extensions;
       } catch (err) {
        this.$data.order.validation_error(err);
       }

       return null;
      },
      previous_steps_completed(index) {
        for (let i = 0; i < index; i++) {
          if (!this.stepper.items[i].completed()) return false;
        }
        return true;
      },
      cartLength() {
        return this.$data.cart.devices.length + this.$data.cart.accessories.length
      },
      step_on_click(index) {
        if (
          this.$data.max_step >= index + 1
          && (this.cartLength() || (!this.cartLength() && index === 0))
          && this.previous_steps_completed(index)
        ) {
          this.$data.stepper.active = index + 1;
        }
      },
      device_added(device) {
        this.$data.cart.devices.push({
          ...device,
          _custom_setup: {
            lines: [],
            address: null,
          },
        });
        this.max_step = 1;
        this.cacheCart();
      },
      device_removed(device) {
        let index = this.cart.devices.findIndex((x) => x.id === device.id && x._custom_setup && (!x._custom_setup.address || !x._custom_setup.lines.length));
        if (index === -1) {
          index = this.cart.devices.findIndex((x) => x.id === device.id);
        }
        if (index > -1) this.$data.cart.devices.splice(index, 1);
        this.cacheCart();
      },
      accessory_added(accr) {
        this.$data.cart.accessories.push(accr);
        this.cacheCart();
      },
      accessory_removed(accr) {
        let index = this.cart.accessories.findIndex((x) => x.id === accr.id);
        if (index > -1) this.$data.cart.accessories.splice(index, 1);
        this.cacheCart();
      },
      devices_choosen() {
        this.cacheCart();
        const max_step = this.$data.cart.devices.length ? 2 : 3;
        this.$data.stepper.active = max_step
        this.max_step = max_step;
        this.checkShippingMethod();
      },
      checkShippingMethod() {
        if (this.$data.cart.accessories.length && !this.$data.cart.devices.length) {
          this.$data.shipping = {...this.$data.default_shipping};
        }
      },
      on_back_from_step_3() {
        this.$data.stepper.active = this.$data.cart.devices.length ? 2 : 1;
      },
      back_to_choose_device(cart) {
        this.$data.cart = cart;
        this.$data.stepper.active = 1;
      },
      async set_up_submitted(cart, update_stepper = false) {
        this.$data.cart = cart;
        if (update_stepper) this.$data.stepper.active = 3;
        this.max_step = 3;
        this.cacheCart();
        this.checkShippingMethod();
      },
      shipping_details_submitted(data, update_stepper) {
        this.$data.shipping_address = data.shipping_address;
        this.$data.shipping = data.shipping;
        if (update_stepper) this.$data.stepper.active++;
        this.max_step = 4;
      },
      async submit_order() {
        const price = this.cartTotalPrice(true);
        const devices_count = this.cartLength();
        if (!devices_count || !this.$data.shipping_address || !this.$data.shipping) {
          const alert = {
            'level': 'error',
            'message': this.$lang.t('orders.validation-failed', 'Validation failed'),
          };
          alert.message += ':';
          if (!devices_count) alert.message += `\n ${this.$lang.t('orders.no-devices-selected', 'Items not selected.')}`;
          if (!this.$data.shipping_address) alert.message += `\n ${this.$lang.t('orders.no-contact-selected', 'Address not selected.')}`;
          if (!this.$data.shipping) alert.message += `\n ${this.$lang.t('orders.no-shipping', 'Shipping not selected.')}`;
          this.$data.order.alert = alert;
          this.$data.order.hide_alert(5);
          return null;
        }
        const devices_translation = devices_count === 1 ? this.$lang.t('app.item', 'item') : this.$lang.t('app.items', 'items');
        const question = this.$lang.t(
          'orders.confirm-order-question',
          'Are you sure you want to buy {} {} for ${}?',
          [devices_count, devices_translation, formatPrice(price)]
        );
        this.$data.confirm_question = question;
        return true;
      },
      sort_cart(cart) {
        const sortingCb = (a, b) => a.manufacturer.localeCompare(b.manufacturer) || a.price.total - b.price.total;
        cart.devices.sort(sortingCb);
        cart.accessories.sort(sortingCb);
        return cart;
      },
      cartTotalPrice(with_shipping = false) {
        let price = 0;
        for (const type of ['devices', 'accessories']) {
          for (const item of this.$data.cart[type]) {
            price += item.price.total;
            if (with_shipping && this.$data.shipping) {
              price += this.$data.shipping.total || 0;
            }
          }
        }

        return price;
      },
      async confirmed() {
        this.$data.confirm_question = null;
        const devices = this.$data.cart.devices.reduce((a, c) => {
          a.push({ 'code': c.code })
          return a;
        }, [])
        const accessories = this.$data.cart.accessories.reduce((a, c) => {
          a.push({ 'code': c.code })
          return a;
        }, [])
        try {
          this.$data.shipping_address = await this.$session.create_item('/contacts', this.$data.shipping_address);
          const data = {
            'contact_id': this.$data.shipping_address.id,
            'shipping_method': this.$data.shipping.value,
            'payment_method_id': this.$data.payment_method.id,
            devices,
            accessories,
          };
          const order = await this.$data.order.create(data);
          this.$data.order.cachier.removeItem(this.$data.cartCacheKey);
          const sync_result = await this.$data.order.sync_devices_with_setup(this.$data.cart.devices, order);
          this.$data.order.alert = this.$data.order.generate_create_order_alert(this.$data.cart, sync_result);
          this.order.gtm_data_push('PDC_Purchase_Completed', [...this.$data.cart.devices, ...this.$data.cart.accessories], this.cartTotalPrice())
          this.$emit('created', order);
          if (this.$data.order.alert && this.$data.order.alert.level === 'success' && this.$props.postSaveCb) {
            this.$props.postSaveCb(order);
          }
        } catch (err) {
          this.$data.order.validation_error(err);
        }
        this.$data.stepper.items = [];
        setTimeout(() => this.$data.order.component.routerPush('devices.my-orders'), this.$data.order.timeUntilRedirects);
      },
      cacheCart() {
        this.$data.order.cachier.setItem(this.$data.cartCacheKey, this.$data.cart);
      }
    },
    filters: {
      upperFirstCase(val) {
       return val.charAt(0).toUpperCase() + val.slice(1).split('_').join(' ').split('-')
       .join(' ');
     },
   },
  };
</script>
<style lang="scss">
  .step-title {
    color: var(--v-text-base);
  }
  .buy-device {
    .w-200 .v-input__slot, .w-200 .v-text-field{
      width: 200px !important;
    }
  }
</style>
