<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 0.999756C2.2583 0.999943 3.79002 1.38694 4.04809 2.99984C4.40486 5.22966 4.85943 9.72534 5.17715 12.5553C5.33713 13.9801 6.53181 14.9312 7.96246 14.8358L19.0461 14.0969C19.9126 14.0392 20.6428 13.4289 20.8534 12.5864L22.6723 5.31063C22.7118 5.15285 22.5924 5 22.4298 5H5.50008"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" />
        <path
            d="M4.70166 18.3803L3.82377 17.9014L3.82377 17.9014L4.70166 18.3803ZM19.5 19.75C20.0523 19.75 20.5 19.3023 20.5 18.75C20.5 18.1977 20.0523 17.75 19.5 17.75V19.75ZM5.1221 15.5211L3.82377 17.9014L5.57956 18.8591L6.8779 16.4789L5.1221 15.5211ZM4.92114 19.75H19.5V17.75H4.92114V19.75ZM3.82377 17.9014C3.36942 18.7344 3.97231 19.75 4.92114 19.75V17.75C5.49043 17.75 5.85217 18.3594 5.57956 18.8591L3.82377 17.9014Z"
            fill="currentColor" />
        <circle cx="7.99951" cy="22" r="1.375" stroke="currentColor" stroke-width="1.25" />
        <circle cx="17.4995" cy="22" r="1.375" stroke="currentColor" stroke-width="1.25" />
        <path d="M7.49951 21.75V19.5H9.49951L8.35665 21.75H7.49951Z" fill="currentColor" />
        <path d="M16.9995 21.75V19.5H18.9995L17.8567 21.75H16.9995Z" fill="currentColor" />
        <path d="M10.4474 5.72548L11.194 14.5071" stroke="currentColor" stroke-width="0.5" />
        <path d="M7.75 5.5L8.74974 14.5" stroke="currentColor" stroke-width="0.5" />
        <path d="M13.25 5.74585L13.4954 13.9982" stroke="currentColor" stroke-width="0.5" />
        <path d="M18.7496 5.74973L17.9998 13.5" stroke="currentColor" stroke-width="0.5" />
        <path d="M16 5.75415L15.7501 13.4997" stroke="currentColor" stroke-width="0.5" />
        <path d="M5.50098 8.38965L21.4985 8.11041" stroke="currentColor" stroke-width="0.5" />
        <path d="M5.50391 11.2793L21.4942 10.7209" stroke="currentColor" stroke-width="0.5" />
    </svg>
</template>
<script>
  export default {
    props: [],
    data() {
      return {
      };
    },
  };
</script>

<style scoped>

</style>
