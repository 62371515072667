import React from 'react'
import AlertMui, { AlertProps as AlertPropsMui } from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { ThemeProvider } from 'theme-provider'
import Typography from 'typography'
import { CloseIcon, SoftAlertIcon, AlertOutlinedIcon, AlertFilledIcon } from 'svg-icons'
import { theme } from 'get-theme'

/***/
export enum Color { INFO = 'info', ERROR = 'error', WARNING = 'warning' }

/***/
export interface AlertProps extends Omit<AlertPropsMui, 'color' | 'title'> {
    /**
     * Can be 'info' | 'error' | 'warning'
     */
    color?: Color
    /**
     * Soft variant of the alert. It has lighter colors.
     */
    soft?: boolean
    /**
     * A title
     */
    title?: React.ReactNode | string
    /**
     * The main content of the alert
     */
    content: React.ReactNode | string
    /**
     * If `true` it will show an icon in front. If an `icon` is sent then it will show that one otherwise it will show a default icon but only in case the color is 'error' or 'warning'.
     */
    showIcon?: boolean
    /**
     * If provided then on X click the id will be saved in localStorage and the alert will not appear again as long as it is in localStorage
     */
    hideId?: string
}

const getIcon = (color: Color, soft: boolean): JSX.Element | false => {
    if (color === Color.INFO) return false
    if (soft) return <SoftAlertIcon/>
    if (color === Color.ERROR) return <AlertFilledIcon secondaryColor={theme.palette.error.main}/>
    return <AlertOutlinedIcon/>
}

const onCloseClick = (hideId, onClose, forceUpdate, ...args) => {
    if (hideId) localStorage.setItem(hideId, 'true')
    onClose?.(...args)
    if (hideId) forceUpdate({})
}

/**
 * Alert component
 *
 * @param {AlertProps} props - props
 */
export const Alert = (props: AlertProps): JSX.Element => {
    const [, forceUpdate] = React.useState()
    const { color, title, content, onClose, showIcon, icon, soft, hideId } = props
    if (hideId && localStorage.getItem(hideId) === 'true') return null
    const otherProps = { ...props }
    const removePropNames = ['color', 'soft', 'action', 'icon', 'variant', 'hideId']
    removePropNames.forEach((propName: string): boolean => delete otherProps[propName])
    const titleElement = title ? <AlertTitle><Typography variant='subtitle2'>{title}</Typography></AlertTitle> : null
    const actionElement = onClose || hideId ? <CloseIcon onClick={onCloseClick.bind(this, hideId, onClose, forceUpdate)}/> : null
    const iconElement = showIcon ? (icon || getIcon(color, soft)) : false
    const contentElement = <Typography variant='body2'>{content}</Typography>
    const colorProp = color === Color.ERROR ? 'error' : color === Color.WARNING ? 'warning' : 'info'
    return (
        <ThemeProvider>
            <AlertMui
                action = {actionElement}
                icon = {iconElement}
                color = {colorProp}
                variant = {soft ? 'standard' : 'filled'}
                {...otherProps}
            >
                {titleElement}
                {contentElement}
            </AlertMui>
        </ThemeProvider>
    )
}

Alert.defaultProps = {
    color: Color.INFO,
    soft: false,
    title: null,
    showIcon: false
}

export default Alert
