<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M5.82106 10.661C6.94391 13.0724 8.81873 15.0742 11.1457 16.3709L13.0519 14.5934C13.2858 14.3752 13.6233 14.3116 13.9129 14.4223C14.8397 14.7648 15.8469 14.9676 16.8851 15.0039C17.3455 15.0199 17.7091 15.4098 17.6931 15.8703L17.591 18.7922C17.5749 19.2527 17.185 19.6163 16.7246 19.6002C8.86306 19.3257 2.7143 12.7319 2.98883 4.87042C3.00491 4.40995 3.39482 4.04636 3.85529 4.06244L6.78556 4.16477C7.24603 4.18085 7.60963 4.57075 7.59355 5.03122C7.557 6.07775 7.68936 7.08826 7.96639 8.03677C8.04825 8.33301 7.96987 8.65719 7.72726 8.88343L5.82106 10.661Z" fill="currentColor"/>
	<path d="M15.9965 7.02792L14.5253 7.99377C13.728 8.51725 12.7975 8.80174 11.8438 8.81367L11.3392 8.81998C11.129 8.8226 11.0157 9.06767 11.1498 9.2295L12.3641 10.6944C12.5435 10.9108 12.8054 11.0421 13.0862 11.0563C13.6783 11.0862 14.2637 10.9188 14.7503 10.5801L17.2262 8.85726L18.3808 10.522C18.5053 10.7016 18.7841 10.6462 18.8305 10.4326L19.8116 5.91412C19.8426 5.7716 19.7454 5.63296 19.6009 5.61334L15.1345 5.00712C14.9205 4.97807 14.7728 5.21577 14.8937 5.39476L15.9965 7.02792Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {};
</script>
