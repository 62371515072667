<template>
  <w-btn color="secondary" @click="on_click" ref="cancel_changes_btn" data-test-id="cancel-changes">{{l.t('app.cancel', 'Cancel')}}</w-btn>
</template>
<script>
import l from '../../libs/lang';

export default {
    data() {
        return { l };
    },
    methods: {
        on_click() {
            if (!this.$router || this.$refs.cancel_changes_btn.$el.closest('.v-dialog')) {
                this.$emit('click:cancel');
            } else {
                this.$router.go(-1);
            }
        },
    }
};
</script>
