import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5537%3A4529
 *
 * @param {SvgIconProps} props - svg props
 */
export const IndeterminateCheckboxIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM7.25 11C7.11193 11 7 11.1119 7 11.25V12.75C7 12.8881 7.11193 13 7.25 13H16.75C16.8881 13 17 12.8881 17 12.75V11.25C17 11.1119 16.8881 11 16.75 11H7.25Z' fill={color}/>
        </SvgIcon>
    )
}

export default IndeterminateCheckboxIcon
