/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React, { Component, createContext } from 'react'
import { Contacts, Contact } from 'models'
// import * as Models from 'models'
import { getPhoneCom } from 'phonecom'

const ContactContext = createContext({})

/**
 *
 */
export const ContactConsumer = ContactContext.Consumer

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}
interface ContactProviderState {
    contacts: Contacts;
    loaded: boolean;
    isLoading: boolean;
    inboxes: Record<string, unknown>[];
}

class ContactProvider extends Component<Props, ContactProviderState> {
    /**
     * @param props
     */
    constructor (props: Props) {
        super(props)
        this.state = {
            contacts: new Contacts(),
            loaded: false,
            isLoading: false,
            inboxes: []
        }
    }

    /**
     *
     */
    componentDidMount () {
        this.load()
    }

    // list & next
    list = async (filter = '@null'): Promise<Contacts> => {
        await this.load()
        const f = ContactProvider.decodeFilter(filter)
        const keyword = f.keyword
        const inbox = f.inbox
        // local contacts
        const localContacts = this.state.contacts
        let c = new Contacts(localContacts)
        c.hasMore = localContacts.hasMore
        console.log('includes: ', filter, c, c.filter(keyword, inbox))
        if (keyword.length || inbox) {
            c = c.filter(keyword, inbox)
        }
        // console.log('load contacts', keyword, c.items)
        return c
    }

    next = async (cursor: string): Promise<Contacts> => {
        return Contacts.load(cursor).then(
            res => {
                res.items = this.filterLocalItems(res.items)
                if (cursor === this.state.contacts.cursor) {
                    this.updateLocal(res)
                }
                // console.log('contact load', res)
                return res
            }
        )
    }

    /**
     * @param contacts
     * @param items
     */
    filterLocalItems (items: Map<number, Contact>) {
        const filtered = new Map<number, Contact>()
        items.forEach((v, k) => {
            if (!this.state.contacts.items.has(k)) {
                filtered.set(k, v)
            }
        })
        return filtered
    }

    /**
     * @param keyword
     * @param inbox
     */
    static encodeFilter (keyword: string, inbox: number): string {
        return keyword + '@' + inbox
    }

    /**
     * @param filter
     */
    static decodeFilter (filter: string): {keyword: string; inbox: number | null} {
        const items = filter.split('@')
        return {
            keyword: items[0],
            inbox: items[1] === 'null' ? null : parseInt(items[1])
        }
    }

    load = async (): Promise<void> => {
        let res: Contacts = new Contacts()
        if (!this.state.loaded && !this.state.isLoading) {
            this.setState({ isLoading: true })
            const phonecom = await getPhoneCom()
            res = await Contacts.load(this.state.contacts.cursor)
            await this.updateLocal(res)
            await this.setState({ loaded: true, isLoading: false, inboxes: phonecom.extensions })
        }
    }

    updateLocal = async (contacts: Contacts) => {
        const local = this.state.contacts
        const updated = local.update(contacts)
        await this.setState({ contacts: updated })
    }

    // Contact utils
    update = async (contact: Contact): Promise<Contact> => {
        const contacts = this.state.contacts
        return Contact.update(contact).then(res => {
            if (res) {
                contacts.add(contact)
                this.setState({ contacts })
            } else {
                // handle remote insert error
            }
            return res
        })
    }

    // TODO update params to take Contact object
    deleteContact = async (contactId: number): Promise<boolean> => {
        if (this.state.contacts.has(contactId)) {
            return await Contact.delete(contactId).then(async success => {
                if (success) {
                    const contacts = this.state.contacts
                    contacts.remove(contactId)
                    await this.setState({ contacts })
                }
                return success
            })
        }
    }

    /**
     *
     */
    render () {
        const { list, next, update, deleteContact } = this
        const { loaded, inboxes } = this.state
        return (<ContactContext.Provider value={{
            list,
            next,
            update,
            deleteContact,

            loaded,
            inboxes
        }}>
            {this.props.children}
        </ContactContext.Provider>)
    }
}

export default ContactProvider
