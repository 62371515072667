// React
import React from "react";
import {isMobile} from 'react-device-detect';

// Stylesheet
import "./keyword-search.css";

class KeywordSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { keyword: [] };
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset !== prevProps.reset) {
      this.setState({ keyword: [] });
    }
  }

  // Handle keyword input change
  checkInput(e) {
    if (/[^0-9a-zA-Z]/.test(e.target.value)) {
      e.preventDefault();
      return;
    }
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.props.keywordSearch(this.state.keyword);
      }
    );
  }

  handleKeyDown = (e) => {
    if (!isMobile) return
    if (e.keyCode === 13){
      e.preventDefault()
      e.target.blur()
    }
  }

  // Keyword Text Search Box
  render() {
    return (
      <div className="keyword-search">
      <div htmlFor="keyword">
        Contains
      </div>
        <div className="form-group">
          <input
            data-test-id='keyword-search'
            type="text"
            id="keyword"
            maxLength="10"
            pattern="^[a-zA-Z0-9]+$"
            value={this.state.keyword}
            className="form-control"
            placeholder="keyword"
            onKeyDown={e => this.handleKeyDown(e)}
            onChange={e => this.checkInput(e)}
          />
        </div>
      </div>
    );
  }
}
export default KeywordSearch;
