/**
 * @param {object} theme
 */
const styles = theme => ({
    audioComponent: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15
    },
    textarea: {
        borderRadius: 4,
        minWidth: 300,
        maxWidth: 500,
        minHeight: 100,
        maxHeight: 250,
        width: 300,
        height: 100,
        resize: 'horizontal',
        '&.mobile': {
            width: 300
        }
    },
    ttsSection: {
        '& .textarea-wrapper': {
            display: 'flex',
            alignItems: 'start'
        }
    },
    audioPlayerContainer: {
        width: 300,
        maxWidth: '100%',
        padding: '20px 10px'
    },
    dropdownItemsWrapper: {
        maxHeight: 300
    },
    dropdownMenu: {
        minWidth: 300,
        '& [class^="MuiInputBase-root"], & [class*=" MuiInputBase-root"]': {
            width: 300
        }
    }
})

export default styles
