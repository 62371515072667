import { combineReducers } from 'redux'
import { smallView, currentView } from './view'
import { currentExtension } from './pdcuser'
import { phones } from './phones'
import { users } from './users'

export default combineReducers({
	smallView,
	currentView,
	currentExtension,
	phones,
	users
})