<template>
	<svg v-if="circle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect width="24" height="24" rx="12" fill="currentColor"/>
	<path d="M16.25 17H7.75C7.47386 17 7.25 16.7761 7.25 16.5V16.25C7.25 15.9739 7.47386 15.75 7.75 15.75H16.25C16.5261 15.75 16.75 15.9739 16.75 16.25V16.5C16.75 16.7761 16.5261 17 16.25 17Z" fill="white"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9106 9.94509C15.1919 10.2028 15.2041 10.6423 14.9375 10.9152L12.4669 13.4446C12.1704 13.7482 11.6813 13.7455 11.3881 13.4387L8.94253 10.8794C8.69685 10.6223 8.70242 10.2157 8.95505 9.96544V9.96544C9.21346 9.70944 9.63135 9.71428 9.88375 9.97621L11.4487 11.6002V6.97619C11.4487 6.7132 11.6619 6.5 11.9249 6.5V6.5C12.1879 6.5 12.4011 6.7132 12.4011 6.97619V11.6002L13.9677 9.97441C14.222 9.71049 14.6404 9.69749 14.9106 9.94509V9.94509Z" fill="white"/>
	</svg>
	<svg v-else width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M10.25 11H1.75C1.47386 11 1.25 10.7761 1.25 10.5V10.25C1.25 9.97386 1.47386 9.75 1.75 9.75H10.25C10.5261 9.75 10.75 9.97386 10.75 10.25V10.5C10.75 10.7761 10.5261 11 10.25 11Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M8.9106 3.94509C9.1919 4.20285 9.20407 4.64226 8.93748 4.9152L6.4669 7.44463C6.17039 7.74821 5.68129 7.74553 5.38812 7.43872L2.94253 4.87938C2.69685 4.62227 2.70242 4.21573 2.95505 3.96544V3.96544C3.21346 3.70944 3.63135 3.71428 3.88375 3.97621L5.44871 5.60024V0.976191C5.44871 0.713198 5.66191 0.5 5.9249 0.5V0.5C6.18789 0.5 6.40109 0.713198 6.40109 0.976191V5.60024L7.96774 3.97441C8.22204 3.71049 8.64039 3.69749 8.9106 3.94509V3.94509Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {
    props: ['circle'],
  };
</script>
