/**
 * @param {object} state
 * @param {object} action
 */
export function users (state = [], action) {
    let userIndex = -1
    switch (action.type) {
            case 'SET_USERS':
                return action.users.slice()
            case 'UPDATE_USER':
                userIndex = state.findIndex(u => u.id === action.user.id)
                if (userIndex === -1) return state
                return [
                    ...state.slice(0, userIndex),
                    {
                        ...action.user,
                        direct_number: action.user.direct_number ? { ...action.user.direct_number } : null,
                        extension: action.user.extension ? { ...action.user.extension } : null,
                        user_plan: action.user.user_plan ? { ...action.user.user_plan } : null,
                        meeting_plan: action.user.meeting_plan ? { ...action.user.meeting_plan } : null,
                        device: action.user.device ? { ...action.user.device } : null
                    },
                    ...state.slice(userIndex + 1)
                ]
            case 'ADD_USER':
                return [
                    ...state,
                    action.user
                ]
            case 'DELETE_USER':
                userIndex = state.findIndex(u => u.id === action.userId)
                if (userIndex === -1) return state
                state.splice(userIndex, 1)
                return [...state]
            default:
                return state
    }
}

const tempUsersInit = {
    id: 1,
    user_plan_id: 1,
    first_name: '',
    last_name: '',
    email: '',
    is_admin: false,
    relationships: {},
    status: 'new'
}
/**
 * @param {object} state
 * @param {object} action
 */
export function tempUsers (state = [{ ...tempUsersInit }], action) {
    let userIndex = -1
    switch (action.type) {
            case 'SET_TEMP_USERS':
                return [...action.tempUsers]
            case 'UPDATE_TEMP_USER':
                userIndex = state.findIndex(u => u.id === action.tempUser.id)
                if (userIndex === -1) return state
                return [
                    ...state.slice(0, userIndex),
                    { ...action.tempUser },
                    ...state.slice(userIndex + 1)
                ]
            case 'ADD_TEMP_USER':
                return [
                    ...state,
                    {
                        ...tempUsersInit,
                        id: state[state.length - 1].id + 1
                    }
                ]
            case 'DELETE_TEMP_USER':
                userIndex = state.findIndex(u => u.id === action.tempUserId)
                if (userIndex === -1) return state
                state.splice(userIndex, 1)
                return [...state]
            default:
                return state
    }
}
