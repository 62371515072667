//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import l from 'lang';
export default {
  props: ['size', 'message', 'no_margins', 'overlay'],
  data: function data() {
    return {
      l: l
    };
  },
  mounted: function mounted() {
    if (this.$props.size === 'large' || !this.$props.size) {
      var parent_modal = this.get_parent_modal();
      if (parent_modal) {
        var modal_width = parent_modal.offsetWidth;
        var modal_height = parent_modal.offsetHeight;
        this.$refs.loader.style.marginTop = "".concat((modal_height - this.$refs.loader.offsetHeight - 57) / 2, "px");
        this.$refs.loader.style.marginRight = "".concat((modal_width - this.$refs.loader.offsetWidth - 226) / 2, "px");
      } else if (!this.$props.overlay) {
        var screen_width = document.getElementById('app').offsetWidth;
        var screen_height = window.innerHeight;
        // $headerHeight: 57px;
        // $sidebar-width: 226px;
        this.$refs.loader.classList.add('absolute-loader');
        this.$refs.loader.style.top = "".concat((screen_height - this.$refs.loader.offsetHeight - 57) / 2, "px");
        this.$refs.loader.style.right = "".concat((screen_width - this.$refs.loader.offsetWidth - 226) / 2, "px");
      }
    }
  },
  methods: {
    get_parent_modal: function get_parent_modal() {
      var a = this.$refs.loader;
      while (a) {
        a = a.parentNode;
        if (a && a.parentNode && a.parentNode.classList && a.parentNode.classList.contains('v-dialog--active')) {
          return a.parentNode;
        }
      }
      return null;
    }
  }
};