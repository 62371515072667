/**
 * @param {object} theme
 */
const styles = theme => ({
    loadingDiv: Object.assign({}, theme.loadingDiv, { position: 'fixed' }),
    section: {
        marginBottom: 30,
        width: 600,
        maxWidth: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5
    },
    sectionTitle: {
        marginBottom: 10,
        color: theme.palette.info.dark
    },
    notificationItem: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        height: 28,
        '&.indent': {
            paddingLeft: 20
        }
    },
    notificationText: {
        display: 'flex',
        flexDirection: 'column',
        '& .main': {
            fontSize: 18
        },
        '& .secondary': {
            fontSize: 14,
            color: 'gray'
        },
        '&.small .main': {
            fontSize: 16
        }
    },
    actionButton: {
        cursor: 'pointer',
        width: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    saveButton: {
        width: 100,
        marginTop: 20
    },
    autoReplyAlert: {
        maxWidth: 780,
        marginBottom: 20
    },
    autoReplySection: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5,
        '& .auto-reply-textarea': {
            width: '100%',
            maxWidth: 500,
            minHeight: 110,
            maxHeight: 150,
            overflowY: 'auto !important',
            marginBottom: 15
        },
        '& > label': {
            width: 'fit-content',
            height: 28
        }
    }
})

export default styles
