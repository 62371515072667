/* eslint-disable class-methods-use-this */
import Resource from './Resource'
import l from '../libs/lang'
import orders_setup from '../libs/resources-setups/live-receptionist'
import { stringifyAddress } from 'formatters'

class Order extends Resource {
    /**
     *
     * @param {object} session PhoenixApiJsClient object
     * @param {object} component helpers vueComponent
     */
    constructor (session, component) {
        super(session, component, '/orders')
        this.filters = {
        }
        this.setup = orders_setup
        this.item = null
        this.devices_types_translations = {
            desk: l.t('orders.desk-phones', 'Desk phones'),
            cordless: l.t('orders.cordless-phones', 'Cordless phones'),
            ata: l.t('orders.atas', 'Analog Telephone Adapters (ATAs)'),
            conference: l.t('orders.conference-phones', 'Conference phones')
        }
        this.devices = []
        this.accessories = []
        this.devices_types = []
        this.shipping_methods = null
        this.status_translations = {
            new: l.t('app.new', 'New')
        }
    }

    /**
     *
     */
    async loadShippingMethods () {
        try {
            const methods = await this.session.get_list_all('/shipping-price-list')
            this.shipping_methods = {}
            methods.items.map((x) => {
                this.shipping_methods[x.value] = x
                return x
            })
        } catch (err) {
            this.shipping_methods = null
        }
    }

    /**
     *
     * @param {object} data
     * @returns
     */
    async create (data) {
        this.loading = true
        try {
            this.item = await this.session.create_item(this.uri, data)
            // this.successfulCreation('devices.my-orders');
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
        return this.item
    }

    /**
     *
     * @param {Array} dvcs
     * @param {object} order
     * @returns
     */
    async sync_devices_with_setup (dvcs, order) {
        if (!Array.isArray(dvcs)) throw new Error('Devices have to be an array of objects')
        const results = {
            success: 0,
            error: 0
        }
        if (dvcs.length && order.devices) {
            const devices = JSON.parse(JSON.stringify(dvcs))
            this.loading = true
            const ordered_devices = order.devices
            for (const device of ordered_devices) {
                const setup_index = devices.findIndex((x) => x.code === device.code && (x._custom_setup.lines.length || x._custom_setup.address))
                if (setup_index > -1) {
                    const setup = devices[setup_index]._custom_setup
                    devices.splice(setup_index, 1)
                    try {
                        await this.session.patch_item(`/devices/${device.id}`, setup)
                        results.success++
                    } catch (err) {
                        results.error++
                    }
                } else {
                    results.error++
                }
            }
            this.loading = false
        }

        return results
    }

    /**
     *
     * @param {object} cart
     * @param {object} sync_results
     * @returns
     */
    generate_create_order_alert (cart, sync_results) {
        const { success, error } = sync_results
        const { devices } = cart
        let message = l.t('orders.order-created', 'Your order is created.')
        if (devices.length) {
            if (success === devices.length) {
                if (devices.length === 1) {
                    message = `${message} ${l.t('orders.device-is-set-up', 'Device is set up.')}`
                } else {
                    message = `${message} ${l.t('orders.all-devices-set-up', 'All devices are set up.')}`
                }
                return {
                    level: 'success',
                    message
                }
            }

            if (error === devices.length) {
                if (devices.length === 1) {
                    message = `${message} ${l.t(
                        'orders.device-not-configured',
                        'We encountered problems configuring your device.'
                    )}`
                } else {
                    message = `${message} ${l.t(
                        'orders.devices-not-configured',
                        'We encountered problems configuring your devices.'
                    )}`
                }
                return {
                    level: 'warning',
                    message
                }
            }

            return {
                level: 'warning',
                message: `${message} ${l.t(
                    'orders.final-results',
                    'Devices configured successfully: {}. Devices with an error while configuring: {}',
                    [success, error]
                )}`
            }
        }
        return {
            level: 'success',
            message
        }
    }

    /**
     *
     * @param {object} device
     * @param {boolean} success
     * @param {object} setup
     * @param {object} err
     * @returns
     */
    generate_result_object (device, success, setup, err) {
        const result = {
            id: device.id,
            success
        }
        if (setup) {
            Object.assign(result, setup)
        }
        if (err) {
            result.error_message = Resource.find_validation_message(err) || l.t('order.error-while-updating-device-setup', 'Error while updating device setup')
        }
        return result
    }

    /**
     *
     * @param {string} val
     * @returns
     */
    formatShippingMethod (val) {
        if (!val) return '—'
        if (!this.shipping_methods) return val

        if (this.shipping_methods[val]) {
            return this.shipping_methods[val].name || val
        }

        return val
    }

    /**
     *
     * @param {object} data
     * @returns
     */
    async cancel_order (data) {
        this.loading = true
        try {
            const order = await this.session.patch_item(`/orders/${data.id}`, { status: 'deleted' })
            this.alert = {
                message: l.t('orders.successfully-canceled-order', 'Successfully canceled order'),
                level: 'success'
            }
            this.hide_alert(5)
            this.loading = false
            return order
        } catch (err) {
            this.validation_error(err)
            this.hide_alert(5)
            this.loading = false
            return false
        }
    }

    /**
     * @param {number} order
     * @param {number} contact_id
     */
    async change_address (order, contact_id) {
        this.loading = true
        try {
            await this.session.patch_item(`/orders/${order.id}`, { contact_id })
            this.alert = {
                message: l.t('orders.order-successfully-updated', 'Order successfully updated'),
                level: 'success'
            }
            this.hide_alert(5)
            this.loading = false
            return true
        } catch (err) {
            this.validation_error(err)
            this.hide_alert(5)
            this.loading = false
            return false
        }
    }

    /**
     *
     * @param {object} params
     * @returns
     */
    async loadItems (params) {
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            const items = await this.session.get_list(this.uri, params.limit, offset)
            // we need json in order to avoid looking for credentials every time we have a task realted to orders
            // const items = {
            //     "filters": {},
            //     "sort": {
            //         "id": "desc"
            //     },
            //     "total": 2,
            //     "limit": 25,
            //     "offset": null,
            //     "items": [
            //         {
            //         "id": 37910,
            //         "voip_id": 2850052,
            //         "status": "new",
            //         "status_date": 1706183309,
            //         "shipping_method": "FEDEXGROUND",
            //         "signature_required": true,
            //         "tracking_number": "",
            //         "contact": {
            //             "id": 7550740,
            //             "name": "testing orders",
            //             "company": "",
            //             "address": {
            //             "line_1": "1815 N HUTCHINSON RD",
            //             "line_2": "APT 78",
            //             "city": "SPOKANE VLY",
            //             "province": "WA",
            //             "postal_code": "99212",
            //             "country": "US"
            //             },
            //             "phone": "+18888888888",
            //             "fax": "",
            //             "primary_email": "",
            //             "alternate_email": ""
            //         },
            //         "devices": [
            //             {
            //             "id": 686279,
            //             "name": null,
            //             "type": "other",
            //             "code": 17387,
            //             "mac_address": "",
            //             "serial_number": "",
            //             "sip_authentication": {
            //                 "host": "sip.phone.com",
            //                 "port": 5060,
            //                 "username": "686279",
            //                 "password": "sDuSZ6qvv5UMZs"
            //             },
            //             "lines": [
            //                 {
            //                 "line": 1,
            //                 "extension": {
            //                     "id": 2671035,
            //                     "name": "user 06",
            //                     "extension": 107,
            //                     "voip_id": 2850052
            //                 }
            //                 }
            //             ],
            //             "model": {
            //                 "id": 59,
            //                 "code": 17387,
            //                 "manufacturer": "Yealink",
            //                 "name": "SIP-T31P",
            //                 "description": "As a very friendly entry-level IP phone, the Yealink SIP-T31P has an extra-large 132x64-pixel graphical LCD with backlight that brings clear visual experience for users. The T31P offers support for two VoIP accounts and includes local 5-way conferencing.",
            //                 "type": "desk",
            //                 "lines": 2,
            //                 "price": null,
            //                 "features": {
            //                 "user_guide_link": "https://support.yealink.com/forward2download?path=ZIjHOJbWuW/DFrGTLnGypjZRKhDplusSymbolXJQ4tgS5EtyrVVLb13hXw2I0D9GjMcFcVEk5a5/plusSymbol7C0MSGNwU3Bk5xl5cklGJodPhKnP0KuYA7di7plusSymbolNXh2Gx2oRhd8dIi/tXwt4oplusSymboljICDpZBtvYtTCrfcETHK0ihdqedaAQb7C39KVEYr8c=",
            //                 "supported_until": null,
            //                 "line_key_count": 2,
            //                 "display_size": 2.3,
            //                 "display_backlit": true,
            //                 "display_color": false,
            //                 "display_touchscreen": false,
            //                 "sip_concurrent_calls": 2,
            //                 "supports_video_calls": "No",
            //                 "connections_ethernet": "2 x 10/100 (passthrough)",
            //                 "connections_poe": true,
            //                 "connections_wifi": "No",
            //                 "connections_bluetooth": "No",
            //                 "connections_dect": "No",
            //                 "connections_usb": "No",
            //                 "connections_headset": "RJ9",
            //                 "supports_expansion_modules": "No",
            //                 "supports_dect_handsets": "No",
            //                 "conf_mic_range": null,
            //                 "conf_participants": null,
            //                 "conf_daisy_chain": null,
            //                 "conf_exp_mics": null,
            //                 "hearing_aid_compatible": true
            //                 },
            //                 "support_category": "supported",
            //                 "is_backordered": false
            //             },
            //             "address": {
            //                 "line_1": "1815 N HUTCHINSON RD",
            //                 "line_2": "APT 78",
            //                 "city": "SPOKANE VLY",
            //                 "province": "WA",
            //                 "postal_code": "99212",
            //                 "country": "US"
            //             }
            //             }
            //         ],
            //         "accessories": [
            //             {
            //             "id": 2109,
            //             "code": 18010,
            //             "model": {
            //                 "id": 21,
            //                 "code": 18010,
            //                 "manufacturer": "Yealink",
            //                 "name": "DD10K DECT USB Dongle",
            //                 "description": "Turn your Yealink T41S/T42S desk phone into a cordless accessory connecting to a W60B DECT base station. Or, turn your T53/T53W/T54W/T57W/T58A/VP59 into a DECT base station supporting up to four W52H/W53H/W56H handsets.",
            //                 "price": null,
            //                 "is_backordered": false,
            //                 "image": "https://m-cdn.phone.com/accessory/21"
            //             }
            //             }
            //         ]
            //         },
            //         {
            //         "id": 37022,
            //         "voip_id": 2850052,
            //         "status": "shipped",
            //         "status_date": 1686174914,
            //         "shipping_method": "FEDEXGROUND",
            //         "signature_required": true,
            //         "tracking_number": "063331617555768",
            //         "contact": {
            //             "id": 6969598,
            //             "name": "Ryan Butler",
            //             "company": "",
            //             "address": {
            //             "line_1": "1815 N HUTCHINSON RD APT 78",
            //             "line_2": "",
            //             "city": "Spokane VLY",
            //             "province": "WA",
            //             "postal_code": "99212",
            //             "country": "US"
            //             },
            //             "phone": "(619)794-8234",
            //             "fax": "",
            //             "primary_email": "",
            //             "alternate_email": ""
            //         },
            //         "devices": null,
            //         "accessories": null
            //         }
            //     ]
            // }
            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            this.items = items.items
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     */
    async loadDevicesPriceList () {
        try {
            const items = await this.session.get_list_all('/devices-price-list')
            items.items = items.items.map((x) => {
                x.device_type_translation = this.devices_types_translations[x.type] || x.type
                return x
            })
            this.devices = items.items
        } catch (err) {
            this.validation_error(err)
        }
        return this.devices
    }

    /**
     *
     */
    async loadAccessoriesPriceList () {
        try {
            const items = await this.session.get_list_all('/devices-accessories-price-list')
            items.items = items.items.map((x) => {
                x.device_type_translation = l.t('app.accessories', 'Accessories')
                return x
            })
            this.accessories = items.items
        } catch (err) {
            this.validation_error(err)
        }

        return this.accessories
    }

    /**
     *
     */
    generateDeviceTypes () {
        const types = []
        this.devices.map((x) => {
            if (!types.find((type) => type.value === x.type)) {
                types.push({
                    value: x.type,
                    text: this.devices_types_translations[x.type] || x.type
                })
            }
            return x
        })
        const accsValue = 'accessories'

        if (
            this.accessories &&
            this.accessories.length &&
            !types.find((type) => type.value === accsValue)
        ) {
            types.push({
                value: accsValue,
                text: l.t('app.accessories', 'Accessories')
            })
        }
        return types
    }

    /**
     *
     * @param {object} cart_with_devices_and_acsrs
     * @returns
     */
    transform_cart_array_to_object (cart_with_devices_and_acsrs) {
        const cart = {}
        for (const type of ['devices', 'accessories']) {
            for (const item of cart_with_devices_and_acsrs[type]) {
                if (!cart[item.id]) {
                    cart[item.id] = {
                        quantity: 1,
                        device: item
                    }
                } else {
                    cart[item.id].quantity++
                }
            }
        }

        return cart
    }

    /**
     *
     * @param {object} data
     * @returns
     */
    static stringifyAddress (data) {
        return stringifyAddress(data)
    }
}

export default Order
