import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/9zP1Njd5AtB4jGpuSrQiug/General-UI-updates---pdc%2C-configure-%26-mini?node-id=8%3A16500
 *
 * @param {SvgIconProps} props - svg props
 */
export const MyPdcIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.41669 11.3571C1.60418 16.7451 6.0208 21.0589 11.4508 21.0833H11.5432C17.0924 21.0584 21.5834 16.5534 21.5834 11.0003C21.5834 5.43103 17.0675 0.916626 11.497 0.916626C6.04588 0.916626 1.60471 5.23943 1.41669 10.6434V11.3571ZM11.2706 7.88653C12.4642 7.88653 13.4316 8.83173 13.4316 9.99766C13.4316 11.1635 12.4642 12.1085 11.2706 12.1085C10.0773 12.1085 9.10985 11.1635 9.10985 9.99766C9.10985 8.83173 10.0773 7.88653 11.2706 7.88653ZM12.1968 14.5385C13.1728 14.3562 16.1469 13.5049 16.1469 9.90252C16.1469 7.4634 14.2563 5.4562 11.8287 5.20169C11.6572 5.18355 11.483 5.17402 11.3065 5.17402C8.63317 5.17402 6.38834 7.29189 6.46584 9.90252C6.56772 13.3313 9.87384 15.8264 13.2519 17.4232C13.4094 17.4978 13.5836 17.3596 13.4701 17.1788C13.3917 17.0539 13.2662 16.8809 13.1196 16.6791C12.7387 16.1543 12.2158 15.4341 12.0103 14.8527C11.9594 14.7089 12.0438 14.5673 12.1968 14.5385Z" fill={color}/>
        </SvgIcon>

    )
}

export default MyPdcIcon
