import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5537%3A4534
 *
 * @param {SvgIconProps} props - svg props
 */
export const RadioButtonUncheckedIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M12 22.0005C17.5228 22.0005 22 17.5233 22 12.0005C22 6.47764 17.5228 2.00049 12 2.00049C6.47715 2.00049 2 6.47764 2 12.0005C2 17.5233 6.47715 22.0005 12 22.0005ZM12 19.5005C16.1421 19.5005 19.5 16.1426 19.5 12.0005C19.5 7.85835 16.1421 4.50049 12 4.50049C7.85786 4.50049 4.5 7.85835 4.5 12.0005C4.5 16.1426 7.85786 19.5005 12 19.5005Z' fill={color}/>
        </SvgIcon>
    )
}

export default RadioButtonUncheckedIcon
