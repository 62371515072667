import React, { Component, createContext } from 'react'
import { Extensions } from 'models'
import { getPhoneCom } from 'phonecom'
const ExtensionContext = createContext({})
/**
 *
 */
export const ExtensionConsumer = ExtensionContext.Consumer

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props{

}
interface State{
    extensions: Extensions;
    loaded: boolean;
    isLoading: boolean;
}

/**
 *
 */
export default class ExtensionProvider extends Component<Props, State> {
    /**
     * @param props
     */
    constructor (props: Props) {
        super(props)
        this.state = {
            extensions: new Extensions(),
            loaded: false,
            isLoading: false
        }
    }

    /**
     *
     */
    componentDidMount () {
        this.load()
    }

    /**
     * @param filter
     */
    list = async (filter = ''): Promise<Extensions> => {
        await this.load()
        let e = this.state.extensions
        if (filter) {
            e = e.filter(filter)
            e.cursor = filter
        }
        return e
    }

    /**
     * @param cursor
     */
    next = async (cursor: string): Promise<Extensions> => {
        return this.list(cursor)
    }

    /**
     *
     */
    load = async () => {
        if (!this.state.loaded && !this.state.isLoading) {
            await this.setState({ isLoading: true })
            const phonecom = await getPhoneCom()
            const extensions = new Extensions(phonecom.extensions)
            await this.setState({ loaded: true, extensions, isLoading: false })
            console.log('providers load:', this.state.extensions)
        }
    }

    hasExtension = (ext: number) : boolean => {
        return this.state.extensions.contains(ext)
    }

    /**
     *
     */
    render () {
        const { list, next, hasExtension } = this
        const { loaded } = this.state
        return (<ExtensionContext.Provider value={{
            list,
            next,
            hasExtension,

            loaded
        }}>
            {this.props.children}
        </ExtensionContext.Provider>)
    }
}
