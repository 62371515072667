import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import { SwipeableDrawer, ListItem, List, ListItemIcon, ListItemText, Chip } from '@material-ui/core'

const useStyles = makeStyles(styles)

function MobileDrawer (props) {
    const classes = useStyles()

    return (
        <div>
            <SwipeableDrawer
                onOpen={props.handleDrawerToggle}
                container={props.container}
                variant='temporary'
                anchor={props?.theme?.direction === 'rtl' ? 'right' : 'left'}
                open={props.mobileDrawerOpen}
                onClose={props.handleDrawerToggle}
                disableBackdropTransition={false}
                classes = {{ paper: `${classes.drawerPaper}` }}
                // Better open performance on mobile.
                ModalProps={{ keepMounted: true }}
            >
                <DrawerContent
                    renderNavHeader={props.renderNavHeader}
                    renderNavFooter={props.renderNavFooter}
                    links={props.links}
                    tabs={props.tabs}
                    handleMenuChange={props.handleMenuChange}
                    smallView
                />
            </SwipeableDrawer>
        </div>
    )
}

function DesktopDrawer (props) {
    const classes = useStyles()
    return (
        <div>
            <div className={classes.drawer} />
            <SwipeableDrawer
                classes = {{ paper: `${classes.drawerPaper}` }}
                variant = 'permanent'
                onOpen = {() => { /**/ }}
                onClose = {() => { /**/ }}
                open
            >
                <DrawerContent
                    renderNavHeader = {props.renderNavHeader}
                    renderNavFooter = {props.renderNavFooter}
                    links = {props.links}
                    tabs = {props.tabs}
                    handleMenuChange = {props.handleMenuChange}
                />
            </SwipeableDrawer>
        </div>
    )
}

function DrawerContent (props) {
    const classes = useStyles()
    return (
        <div className={`${classes.drawableContent} ${props.smallView ? 'small-view' : ''}`}>
            {props.renderNavHeader()}
            <DrawerTabs
                handleMenuChange = {props.handleMenuChange}
                links = {props.links}
                tabs = {props.tabs}
            />
            {props.renderNavFooter?.()}
        </div>
    )
}

function DrawerTabs (props) {
    const classes = useStyles()
    if (!props.tabs) return null
    return (
        <div className={classes.drawerWrapper}>
            <List className={classes.drawerList}>
                {props.tabs.map((route, index) => { // eslint-disable-line no-unused-vars
                    if (!route.enabled) return null
                    const to = route.link.indexOf('http') === -1 ? route.link : '#'
                    const activeClass = route.active ? 'active' : ''
                    const separateClass = route.text === 'Control Panel' ? 'separate' : ''
                    const Icon = route.icon
                    const unreadInfoText = route.unread > 99 ? '99+' : route.unread
                    return (
                        <div
                            data-test-id={route.testId}
                            key={route.text}
                            onClick={route.onClick ? route.onClick : () => props.handleMenuChange(route, to)}
                            className={classes.appLinkStyle}
                            ref={route.ref ? route.ref : null}
                        >
                            <ListItem button className={`${classes.drawerItem} ${activeClass} ${separateClass}`}>
                                <ListItemIcon>
                                    <Icon
                                        active={route.active ? 1 : 0}
                                        read={!route.unread ? 1 : 0}
                                        className={`${classes.drawIcon} ${route.active ? 'active' : ''} `}
                                    />
                                </ListItemIcon>

                                <ListItemText classes={{ root: classes.navItemRoot, primary: `${classes.navItemText} ${activeClass}` }} primary={route.text} />
                                {route.unread
                                    ? <div className={classes.unreadInfo} data-test-id="unread-badge-div"><span title={route.unread}>{unreadInfoText}</span></div>
                                    : route.badge
                                        ? <Chip label={route.badge} color="secondary" size="small" />
                                        : null}
                                {!route.enabled && (
                                    <span className={classes.disabledLabel}>
                                        Coming Soon!
                                    </span>
                                )}
                            </ListItem>
                        </div>
                    )
                })}
            </List>
            <Links links={props.links} />
        </div>
    )
}

function Links (props) {
    const { links } = props
    const classes = useStyles()

    const openNewTab = (address) => {
        window.open(address, '_blank')
    }

    if (!links || !links.length) return null
    return (
        <div className={classes.linksSection}>
            <List className={classes.drawerList}>
                {links.map((link) => {
                    const Icon = link.icon
                    return (
                        <div
                            data-test-id={link.testId}
                            key={link.text}
                            onClick={link.onClick ? link.onClick : () => openNewTab(link.address)}
                            className={classes.appLinkStyle}
                            ref={link.ref ? link.ref : null}
                        >
                            <ListItem button className={`${classes.drawerItem} ${classes.drawerLinkItem}`}>
                                <ListItemIcon className={classes.iconWrapper}>
                                    <Icon
                                        className={`${classes.drawIcon}`}
                                    />
                                </ListItemIcon>
                                <ListItemText classes={{ root: classes.navItemRoot, primary: `${classes.navItemText}` }} primary={link.text} />
                            </ListItem>
                        </div>
                    )
                })}
            </List>
        </div>
    )
}

DesktopDrawer.propTypes = {
    container: PropTypes.object,
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    renderNavHeader: PropTypes.func,
    renderNavFooter: PropTypes.func,
    tabs: PropTypes.array
}

Drawer.propTypes = {
    container: PropTypes.object,
    handleDrawerToggle: PropTypes.func,
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    mobileDrawerOpen: PropTypes.bool,
    renderNavHeader: PropTypes.func,
    renderNavFooter: PropTypes.func,
    screenViewType: PropTypes.bool,
    tabs: PropTypes.array
}

DrawerContent.propTypes = {
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    renderNavHeader: PropTypes.func,
    renderNavFooter: PropTypes.func,
    tabs: PropTypes.array,
    smallView: PropTypes.bool
}

DrawerTabs.propTypes = {
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    tabs: PropTypes.array
}

Links.propTypes = {
    links: PropTypes.array
}

MobileDrawer.propTypes = {
    container: PropTypes.object,
    handleDrawerToggle: PropTypes.func,
    handleMenuChange: PropTypes.func,
    links: PropTypes.array,
    mobileDrawerOpen: PropTypes.bool,
    renderNavHeader: PropTypes.func,
    renderNavFooter: PropTypes.func,
    tabs: PropTypes.array,
    theme: PropTypes.object
}

/**
 * @param {object} props
 */
export default function Drawer (props) {
    const { isMobileView, isTabletView } = props.screenViewType
    const isSmallView = isMobileView || isTabletView

    return isSmallView
        ? <MobileDrawer
            renderNavHeader={props.renderNavHeader}
            renderNavFooter={props.renderNavFooter}
            links={props.links}
            tabs={props.tabs}
            mobileDrawerOpen={props.mobileDrawerOpen}
            container={props.container}
            handleDrawerToggle={props.handleDrawerToggle}
            handleMenuChange={props.handleMenuChange}
        />
        : <DesktopDrawer
            renderNavHeader={props.renderNavHeader}
            renderNavFooter={props.renderNavFooter}
            handleMenuChange={props.handleMenuChange}
            tabs={props.tabs}
            links={props.links}
            container={props.container}
            handleDrawerToggle={props.handleDrawerToggle}
        />
}
