import React, { Component } from 'react'
import { UpdateData } from 'step-wizard/src/StepWizard'
import BusinessHours from 'business-hours'
import { OpenHours, generateSaveData as generateBusinessHoursSaveData } from 'business-hours/src/helper'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import { PassData, StepName } from './CompanySetup'
import Api from 'api'

/***/
export interface BusinessHoursData {
    hasChange: boolean
    openHoursScheduleType: '24' | 'custom'
    openHours: OpenHours
    openHoursSchedule
    lunchSchedule
    holidaysSchedule
    customHolidaysSchedule
}

interface Props {
    classes
    saveId: number | string
    update: (data: UpdateData) => void
    smallView: boolean
    passData: PassData
}

interface State {
    businessHoursData: BusinessHoursData
    loading: boolean
    saving: boolean
}

class BusinessHoursStep extends Component<Props, State> {
    constructor (props) {
        super(props)
        const passData = this.props.passData[StepName.BusinessHours]
        const businessHoursData = passData ? JSON.parse(JSON.stringify(passData)) : { hasChange: false, openHoursScheduleType: null, openHours: null }
        this.state = { businessHoursData, loading: true, saving: false }
    }

    componentDidUpdate = (prevProps: Props): void => {
        const hasSaveRequest = Boolean(this.props.saveId && prevProps.saveId !== this.props.saveId)
        if (hasSaveRequest) this.save()
    }

    save = async () => {
        const data = this.state.businessHoursData
        if (data.hasChange) {
            this.setState({ saving: true })
            const businessHoursState = {
                ...data,
                lunchPause: { status: 'off' },
                holidays: { status: 'off' },
                customHolidays: { status: 'off' }
            }
            const saveData = generateBusinessHoursSaveData(businessHoursState)
            const openHoursScheduleSaveData = saveData.openHoursSchedule
            const lunchScheduleSaveData = saveData.lunchSchedule
            const holidaysScheduleSaveData = saveData.holidaysSchedule
            const customHolidaysScheduleSaveData = saveData.customHolidaysSchedule
            const response = await Api.updateSchedules(openHoursScheduleSaveData, lunchScheduleSaveData, holidaysScheduleSaveData, customHolidaysScheduleSaveData)
            // Update state after save
            const businessHoursData = { ...this.state.businessHoursData }
            const openHoursSchedule = {
                ...businessHoursData.openHoursSchedule,
                status: 'on',
                id: response.open_hours_schedule.id,
                items: response.open_hours_schedule.items
            }
            const lunchSchedule = { id: response.lunch_schedule.id, ...businessHoursState.lunchSchedule }
            const holidaysSchedule = { id: response.holidays_schedule.id, ...businessHoursState.holidaysSchedule }
            const customHolidaysSchedule = { id: response.custom_holidays_schedule.id, ...businessHoursState.customHolidaysSchedule }
            businessHoursData.openHoursSchedule = openHoursSchedule
            businessHoursData.lunchSchedule = lunchSchedule
            businessHoursData.holidaysSchedule = holidaysSchedule
            businessHoursData.customHolidaysSchedule = customHolidaysSchedule
            this.setState({ businessHoursData })
        }

        this.props.update({ completed: true, gotoNext: true, saveData: this.getSaveData() })
        this.setState({ saving: false })
    }

    getSaveData = () => this.state.businessHoursData

    setLoading = (loading: boolean): void => this.setState({ loading })

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull data-test-id='csw-spinner-icon' text='Please wait...' color={theme.palette.secondary[0]} size='bigger'/>
            </div>
        )
    }

    render = (): JSX.Element => {
        const { classes, smallView } = this.props
        const { loading, saving } = this.state
        const setBusy = (hasChange: boolean): void => {
            const businessHoursData = { ...this.state.businessHoursData }
            businessHoursData.hasChange = hasChange
            this.setState({ businessHoursData }, () => {
                this.props.update({ completed: true, saveData: this.getSaveData() })
            })
        }
        const onUpdate = (data: BusinessHoursData): void => {
            const businessHoursData = { ...this.state.businessHoursData }
            Object.assign(businessHoursData, data)
            this.setState({ businessHoursData }, () => {
                this.props.update({ completed: true, saveData: this.getSaveData() })
            })
        }
        const passData = this.props.passData[StepName.BusinessHours]
        const editingData = { ...passData }
        delete editingData.hasChange
        if (!editingData.openHours) delete editingData.openHours
        if (!editingData.openHoursScheduleType) delete editingData.openHoursScheduleType
        return (
            <div className={`${classes.businessHours} ${smallView ? 'mobile' : ''}`}>
                {loading || saving ? this.renderLoader() : null}
                <BusinessHours
                    smallView = {smallView}
                    setBusy = {setBusy}
                    onUpdate = {onUpdate}
                    editingData = {editingData}
                    origin = 'company-setup'
                    setLoading = {this.setLoading}
                />
            </div>
        )
    }
}

export default withStyles(styles)(BusinessHoursStep)
