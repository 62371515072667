/* eslint-disable @typescript-eslint/indent */
import { formatPhoneNumber } from 'phone-numbers'

/**
 * @param {object} state
 * @param {object} action
 */
export function currentTab (state = null, action) {
    switch (action.type) {
            case 'SWITCH_TAB':
                return action.tab
            default:
                return state
    }
}

function checkIfValidRulesGroup (smsForwarding, numberRulesGroup, phoneNumber) {
    const numberRules = numberRulesGroup.rules
    const actions = numberRules.actions
    const ruleTypes = numberRules.rule_types
    // Unsupported rule types
    if (ruleTypes.filter(t => !['ring-users', 'play-menu', 'voicemail', 'play-recording', 'live_answer'].includes(t)).length) return false
    if (!smsForwarding) return false

    // Voicemail
    if (ruleTypes.includes('voicemail')) {
        // It can be either only voicemail or ring-users + voicemail or play-recording + ring-users + voicemail
        if (ruleTypes.length > 3) return false
        if (ruleTypes.filter(t => !['ring-users', 'voicemail', 'play-recording', 'live_answer'].includes(t)).length) return false
    }

    // Ring users & Ring external
    if (ruleTypes.includes('ring-users')) {
        if (!ruleTypes.includes('voicemail')) return false
        if (ruleTypes.length === 3 &&
            (
                (numberRules.actions[0].type !== 'play-recording' && numberRules.actions[0].type !== 'live_answer') ||
                ruleTypes.some(t => !['ring-users', 'voicemail', 'play-recording', 'live_answer'].includes(t))
            )
        ) return false
        if (![2, 3].includes(ruleTypes.length)) return false
        const ringUsersActions = actions.filter(a => a.type === 'ring-users')
        if (ringUsersActions.length > 2) return false
        if (!ringUsersActions[0].data.extensions.length) {
            if (ringUsersActions[0].data.phone_numbers.length !== 1) return false // Ring external can forward to 1 number only
        }
        if (ringUsersActions.length === 2) {
            if (ringUsersActions[1].data.phone_numbers.length) return false
            if (!ringUsersActions[1].data.extensions.length) return false
        }
    }
    // Menu
    if (ruleTypes.includes('play-menu')) {
        // It can have (but doesn't have to) play-recording
        if (ruleTypes.length === 2 && !ruleTypes.includes('play-recording')) return false
        if (ruleTypes.length > 2) return false
        const playMenuAction = actions.find(a => a.type === 'play-menu')
        const playMenuData = playMenuAction.data
        // Check if there is at least 1 option that has something else than ring-users or voicemail
        const hasNotSupportedOption = Object.keys(playMenuData.options).some(o => {
            if (!'1234567890#'.includes(o)) return false
            const optionRuleGroups = playMenuData.options[o].rule_groups
            if (optionRuleGroups.length > 1) return true
            if (optionRuleGroups[0].type !== 'all') return true
            if (optionRuleGroups[0].rules.rule_types.find(type => !['ring-users', 'voicemail'].includes(type))) return true
            if (optionRuleGroups[0].rules.rule_types.length > 2) return true
            if (optionRuleGroups[0].rules.actions.length > 2) return true
            return false
        })
        if (hasNotSupportedOption) return false
        // The 'on_nothing_selected' option can be one of these: 'ring-users', 'voicemail', 'disconnect'
        const onNothingSelected = playMenuData.on_nothing_selected
        const onNothingSelectedRuleGroups = onNothingSelected.rule_groups
        if (!onNothingSelectedRuleGroups || onNothingSelectedRuleGroups?.length > 1) return false
        if (onNothingSelectedRuleGroups[0].type !== 'all') return false
        const timeoutRuleTypes = onNothingSelectedRuleGroups[0].rules.rule_types
        const timeoutRuleActions = onNothingSelectedRuleGroups[0].rules.actions
        if (timeoutRuleTypes.length === 1) {
            if (!timeoutRuleTypes.some(e => ['ring-users', 'voicemail', 'disconnect'].includes(e))) return false
            if (timeoutRuleActions.length !== 1) return false
        } else if (timeoutRuleTypes.length === 2) {
            if (!timeoutRuleTypes.some(e => e === 'voicemail') || !timeoutRuleTypes.some(e => e === 'ring-users')) return false
            if (timeoutRuleActions[0].type !== 'ring-users' || timeoutRuleActions[1].type !== 'voicemail') return false
        } else return false
    }
    return true
}

function checkIfValidOneGroupRules (phoneNumber) {
    const smsForwarding = phoneNumber.sms_forwarding
    const numberRulesGroups = phoneNumber.call_rules.rule_groups
    const numberRulesGroup = numberRulesGroups[0]
    if (numberRulesGroup.type !== 'all') return false
    // if (actions.length === 0) return true
    const numberRules = numberRulesGroup.rules
    const actions = numberRules.actions
    const ruleTypes = numberRules.rule_types
    // Unsupported rule types
    const isDirectLine = ruleTypes.length === 2 && actions.length === 2 && ruleTypes.includes('ring-users') && ruleTypes.includes('voicemail')
    if (!isDirectLine && ruleTypes.filter(t => !['ring-users', 'play-menu', 'voicemail', 'fax', 'play-recording', 'live_answer'].includes(t)).length) return false
    // Fax
    if (ruleTypes.includes('fax')) {
        if (ruleTypes.length > 1) return false
        // Check if the fax extension is different than the sms forwarding extension
        const faxAction = actions.find(a => a.type === 'fax')
        if (smsForwarding?.extension.id !== faxAction.data.extension_id) return false
    } else {
        // // Ring users
        // if (ruleTypes.includes('ring-users')) {
        //     const rules = phoneNumber.call_rules.rule_groups[0].rules
        //     const ringUsersAction = rules.actions.find(a => a.type === 'ring-users')
        //     if (ringUsersAction.data.extensions.length > 1) return false
        // }
        if (!checkIfValidRulesGroup(smsForwarding, numberRulesGroup, phoneNumber.phone_number)) return false
    }

    return true
}

function checkIfValidMultiGroupRules (phoneNumber) {
    const companyExtensionId = window.V5PHONECOM?.companyExtension?.id
    const smsForwarding = phoneNumber.sms_forwarding
    const isAssignedToCompany = smsForwarding?.extension.id === companyExtensionId

    const numberRulesGroups = phoneNumber.call_rules.rule_groups
    if (
        !isAssignedToCompany && (
            (numberRulesGroups[0].type !== 'schedule' && numberRulesGroups[0].schedule.name === 'custom-holidays') ||
            (numberRulesGroups[1].type !== 'schedule' && numberRulesGroups[0].schedule.name === 'holidays') ||
            (numberRulesGroups[2].type !== 'schedule' && numberRulesGroups[0].schedule.name === 'lunch-break') ||
            (numberRulesGroups[3].type !== 'schedule' && numberRulesGroups[0].schedule.name === 'open-hours') ||
            numberRulesGroups[4].type !== 'all'
        )
    ) return false

    let isValid = true
    numberRulesGroups.forEach((numberRulesGroup, i) => {
        if (!isValid) return
        isValid = checkIfValidRulesGroup(smsForwarding, numberRulesGroup, phoneNumber.phone_number)
    })
    return isValid
}

function checkForUnsupportedRules (phoneNumber) {
    const numberRulesGroups = phoneNumber.call_rules.rule_groups
    // Currently we support the default ('all') rule group and 'schedule' rule groups

    // It will have 5 rules in case company business hours are used
    // or 1 rule in case they aren't used
    if (![1, 5].includes(numberRulesGroups.length)) return true
    if (numberRulesGroups.length === 1) return !checkIfValidOneGroupRules(phoneNumber)
    if (numberRulesGroups.length === 5) return !checkIfValidMultiGroupRules(phoneNumber)
    return false
}

function formatNumber (phoneNumber) {
    // Set the label if not set
    if (!phoneNumber?.name) phoneNumber.name = formatPhoneNumber(phoneNumber.phone_number)
    // Set the type
    const rulesGroups = phoneNumber.call_rules.rule_groups
    if (!rulesGroups || !rulesGroups.length) return
    if (rulesGroups.some((ruleGroup) => {
        return ruleGroup.type === 'schedule' && !ruleGroup.schedule
    })) {
        phoneNumber.call_rules.rule_groups = []
        return (phoneNumber.type = 'Advanced rules')
    }
    // if (phoneNumber.phone_number === '+12038846621') debugger
    if (checkForUnsupportedRules(phoneNumber)) return (phoneNumber.type = 'Advanced rules')

    const companyExtensionId = window.V5PHONECOM?.companyExtension?.id
    if (
        rulesGroups.length === 1 &&
        rulesGroups[0].rules.rule_types.length === 2 &&
        rulesGroups[0].rules.rule_types.includes('ring-users') &&
        rulesGroups[0].rules.rule_types.includes('voicemail') &&
        rulesGroups[0].rules.actions[0].data.extensions?.length === 1 &&
        rulesGroups[0].rules.actions[0].data.extensions[0].extension_id === companyExtensionId &&
        rulesGroups[0].rules.actions[1].data.extension_id === companyExtensionId
    ) phoneNumber.type = 'Main company number'
    else if (rulesGroups.length > 1) phoneNumber.type = 'Multiple rules set'
    else {
        const rules = rulesGroups[0].rules
        const ruleTypes = [...rules.rule_types]
        const playRecordingIndex = ruleTypes.findIndex(e => e === 'play-recording')
        if (playRecordingIndex !== -1) ruleTypes.splice(playRecordingIndex, 1)
        const hasVirtualAnswer = [...rules.actions].map(a => a?.data?.code).includes(19087)
        if (ruleTypes.length === 1) {
            if (ruleTypes[0] === 'voicemail') phoneNumber.type = 'Voicemail'
            else if (ruleTypes[0] === 'fax') phoneNumber.type = 'Fax'
            else if (ruleTypes[0] === 'play-menu') phoneNumber.type = 'Menu'
            else if (hasVirtualAnswer) phoneNumber.type = 'AI-Connect'
            else if (ruleTypes[0] === 'live_answer') phoneNumber.type = 'Receptionist Service'
        } else if (ruleTypes.length === 2) {
            if (ruleTypes.includes('ring-users') && ruleTypes.includes('voicemail')) {
                const ringUsersAction = rules.actions.find(a => a.type === 'ring-users')
                if (!ringUsersAction.data.extensions.length && ringUsersAction.data.phone_numbers.length) phoneNumber.type = 'External'
                else phoneNumber.type = ringUsersAction.data.extensions.length > 1 ? 'Users' : 'Users' // 'Direct Number'
            } else if (hasVirtualAnswer) phoneNumber.type = 'AI-Connect'
            else if (ruleTypes.includes('voicemail') && ruleTypes.includes('live_answer')) phoneNumber.type = 'Receptionist Service'
        } else if (ruleTypes.length === 3) {
            if (hasVirtualAnswer) phoneNumber.type = 'AI-Connect'
            else if (ruleTypes.includes('ring-users') && ruleTypes.includes('voicemail') && ruleTypes.includes('live_answer')) phoneNumber.type = 'Receptionist Service'
        }
    }
}

const defaultPhoneNumbers = {
    notLoaded: true,
    items: [],
    total: 1
}

/**
 * @param {object} state
 * @param {object} action
 */
export function phoneNumbers (state = defaultPhoneNumbers, action) {
    let phoneNumbers
    let index
    switch (action.type) {
            case 'ADD_PHONE_NUMBERS':
                phoneNumbers = action.phoneNumbers
                phoneNumbers.items.forEach(phoneNumber => {
                    formatNumber(phoneNumber)
                    if (phoneNumbers.notInOrder) phoneNumber.notInOrder = true
                    let index = state.items.findIndex(p => p.id === phoneNumber.id)
                    if (index !== -1) {
                        delete phoneNumber.notInOrder
                        state.items[index] = phoneNumber
                        return
                    }
                    index = state.items.findIndex(p => p.id > phoneNumber.id)
                    if (index === -1) index = state.items.length
                    state.items.splice(index, 0, phoneNumber)
                })
                // state.items = state.items.concat(phoneNumbers.items)
                if (phoneNumbers.limit !== 1) {
                    state.hasMore = phoneNumbers.items.length === phoneNumbers.limit
                }
                delete state.notLoaded
                return { ...state }
            case 'UPDATE_PHONE_NUMBER':
                index = state.items.findIndex(n => n.id === action.phoneNumber.id)
                formatNumber(action.phoneNumber)
                state.items[index] = action.phoneNumber
                return { ...state }
            case 'DELETE_PHONE_NUMBER':
                index = state.items.findIndex(n => n.id === action.phoneNumber.id)
                if (index === -1) {
                    return state
                }
                state.items.splice(index, 1)
                return { ...state }
            case 'RESET_PHONE_NUMBERS':
                return {
                    notLoaded: true,
                    items: [],
                    total: 1
                }
            default:
                return state
    }
}
