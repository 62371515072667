<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M19.8321 12.0605C20.7738 12.0769 21.5307 10.9465 21.552 9.72534C21.5734 8.50417 20.8351 7.54897 19.7949 7.53081C18.7548 7.51266 18.0131 8.33943 17.9917 9.5606C17.9704 10.7818 18.8904 12.044 19.8321 12.0605ZM20.1823 13.3636C20.6841 13.2382 20.9047 12.8656 20.9667 12.3746C21.1747 12.5783 21.3569 12.7472 21.5459 12.8182C21.7218 12.8842 21.8769 12.9205 22.016 12.9531C22.7087 13.1153 23.0007 13.1836 23.455 16.3636C23.4635 17.3346 20.7277 17.4545 17.455 16.9091C16.1574 16.6928 16.0602 16.1335 15.8036 14.6557C15.7367 14.2699 15.6588 13.8216 15.5459 13.3004C15.9584 12.5231 16.5903 12.4532 17.2978 12.3749L17.2978 12.3749C17.5258 12.3497 17.7616 12.3236 18.0004 12.2727C18.1662 12.2374 18.3624 12.1092 18.5518 11.9554C18.9274 12.8678 19.6139 13.5057 20.1823 13.3636Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M13.4258 11.6017C15.3762 11.5066 16.7884 9.1026 16.6663 6.61432C16.5442 4.12605 14.887 2.28277 12.7326 2.38782C10.5783 2.49287 9.15635 4.27681 9.27841 6.76508C9.40046 9.25335 11.4754 11.6968 13.4258 11.6017ZM10.2637 11.6506C10.3728 11.5777 10.4638 11.5168 10.5379 11.4825C10.9443 13.0538 12.4924 14.8652 14.0786 14.7636C15.25 14.6885 15.6099 13.7815 15.498 11.8383C15.6268 11.9018 15.653 11.9674 15.6812 12.0379C15.7089 12.1072 15.7385 12.1813 15.8692 12.2627C16.3249 12.5019 16.7223 12.65 17.0727 12.7805C18.4196 13.2824 19.0723 13.5256 19.6749 17.6937C19.7993 18.5547 19.5395 19.4609 18.8356 19.9722C15.3329 22.5165 10.2186 20.8292 6.82959 19.7111C6.54551 19.6174 6.27356 19.5277 6.0157 19.4448C5.10241 19.1512 4.40995 18.3095 4.46734 17.3519C4.59676 15.1925 5.03563 14.1218 5.83364 13.1934C6.58864 12.315 7.53758 12.2839 8.35992 12.2569C8.50288 12.2522 8.64201 12.2477 8.77563 12.2388C9.45158 12.1939 9.93804 11.8685 10.2637 11.6506Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M8.03842 7.12935C8.11771 8.7457 7.2004 10.3073 5.93345 10.3691C4.66649 10.4308 3.31864 8.84363 3.23936 7.22728C3.16007 5.61094 4.08373 4.45212 5.48317 4.38388C6.88262 4.31564 7.95913 5.51301 8.03842 7.12935ZM4.08875 10.1769C4.03905 10.1999 3.97798 10.2408 3.90483 10.2897C3.68635 10.4358 3.36003 10.6541 2.9066 10.6842C2.81697 10.6902 2.72365 10.6932 2.62776 10.6964H2.62774C2.07612 10.7145 1.43957 10.7354 0.933109 11.3246C0.364211 11.9864 0.0674107 12.7561 0.0026978 14.3956C-0.0162195 14.8749 0.32746 15.2915 0.785776 15.4329C1.03523 15.51 1.30863 15.6002 1.6012 15.6967C2.1641 15.8824 2.79797 16.0915 3.46882 16.2752C3.654 15.8768 3.80475 15.5372 3.93419 15.2455C4.48645 14.0013 4.65085 13.6309 5.4477 13.3016C6.68982 12.7883 7.97304 13.045 8.78406 13.3016L8.30441 16.5332C8.78231 16.3907 9.23829 16.1753 9.65946 15.8685C10.1283 15.527 10.3015 14.9231 10.2186 14.3491C9.81421 11.5476 9.37643 11.3845 8.47227 11.0476L8.47226 11.0476C8.23722 10.9601 7.97066 10.8607 7.66498 10.7003C7.57732 10.6457 7.55746 10.596 7.53887 10.5495C7.51995 10.5022 7.50235 10.4582 7.41597 10.4156C7.49104 11.7191 7.24959 12.3275 6.46382 12.3779C5.3998 12.446 4.36134 11.2309 4.08875 10.1769Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {};
</script>
