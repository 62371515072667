/**
 * @param {Theme} theme
 */
const styles = theme => ({
    modalMain: {
        display: 'flex',
        flexDirection: 'column',
        width: 500,
        height: 450,
        '&.small': {
            width: '100%',
            height: 385
        }
    },
    modalMainLoading: {
        width: 500,
        height: 385,
        '&.small': {
            width: '100%',
            height: 400
        }
    },
    mainInnerLoading: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        zIndex: 2,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    filtersRow: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 30,
        '&.small': {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    resultsRow: {
        display: 'flex',
        overflowX: 'auto'
    },
    resultsWrapper: {
        flexWrap: 'wrap',
        maxHeight: 300,
        '&.small': {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    numberItem: {
        position: 'relative',
        width: 144,
        height: 62,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
        display: 'inline-flex',
        backgroundColor: 'fff',
        borderRadius: 5,
        color: '#333',
        textDecoration: 'none',
        boxShadow: '0 0 30px #f3f3f3',
        zIndex: 0,
        border: '1px solid #eee',
        cursor: 'pointer',
        '& .number': {
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14
        },
        '& .price': {
            position: 'absolute',
            top: 2,
            right: 5,
            color: '#fa9927',
            fontSize: 11
        },
        '&:hover': {
            boxShadow: '0 0 30px #ddd',
            '& .number': {
                color: theme.palette.primary[0]
            },
            '& .price': {
                fontWeight: 'bold'
            }
        },
        '&.small': {
            width: '100%'
        }
    },
    numberItemSelected: {
        position: 'relative',
        width: 144,
        height: 62,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
        display: 'inline-flex',
        backgroundColor: 'fff',
        borderRadius: 5,
        boxShadow: '0 0 30px #ddd',
        zIndex: 0,
        border: '1px solid #eee',
        '& .number': {
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary[0],
            fontSize: 14

        },
        '& .price': {
            position: 'absolute',
            top: 2,
            right: 5,
            color: '#fa9927',
            fontSize: 11,
            fontWeight: 'bold'

        },
        '&.small': {
            width: '100%'
        }
    },
    confirmRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fa9927',
        fontWeight: 'bold',
        marginTop: 15,
        '&.small': {
            paddingTop: 10,
            textAlign: 'center'
        }
    },
    noResultsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#fa9927',
        marginTop: 50,
        '&.small': {
            fontSize: 12,
            display: 'block',
            flexDirection: 'column',
            textAlign: 'center',
            marginTop: 0
        }
    },
    textField: {
        width: 200
    },
    buttonWrapper: {
        marginLeft: 15,
        '&.small': {
            display: 'flex',
            marginLeft: 0,
            marginTop: 15,
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    spinnerRow: {
        marginTop: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.small': {
            marginTop: 0,
            flexDirection: 'column',
            textAlign: 'center'
        },
        '& span': {
            marginLeft: 5,
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '21px',
            color: theme.palette.primary[0]
        },
        '& svg': {
            color: theme.palette.primary[0]
        }
    }
})

export default styles
