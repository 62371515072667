/**
 * @param {object} theme
 */
const styles = theme => ({
    audioPlayerContainer: {
        width: '100%',
        padding: '20px 10px',
        borderTop: '1px solid lightgray'
    },
    ttsContent: {
        border: '1px solid lightgray',
        borderTop: 'none',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        '& .tts-textarea': {
            padding: 10,
            border: 'none',
            fontSize: 20,
            width: '100%',
            minHeight: 125,
            maxHeight: 300,
            outline: 'none',
            '&.error': {
                border: `2px solid ${theme.palette.error.main}`
            }
        }
    },
    separator: {
        border: 'none',
        borderTop: '1px dashed lightgray',
        margin: '0 10px'
    },
    languageOptions: {
        display: 'flex',
        padding: 10,
        '& > div': {
            flex: 1,
            fontSize: 18,
            fontWeight: 500,
            '&:first-child': {
                borderRight: '1px dashed lightgray'
            },
            '&:last-child': {
                paddingLeft: 10
            }
        }
    },
    autocomplete: {
        display: 'flex',
        alignItems: 'center',
        '&.lang-autocomplete': {
            paddingRight: 10
        },
        '&.voice-autocomplete': {
            paddingLeft: 10
        }
    },
    autocompleteInputParent: {
        '&:before, &:after': {
            border: 'none !important'
        },
        '& .MuiIconButton-root': {
            padding: 0
        }
    },
    autocompleteInput: {
        padding: 0
    },
    infoSection: {
        borderTop: '1px solid lightgray',
        padding: '8px 10px',
        color: 'gray',
        display: 'flex',
        alignItems: 'center'
    },
    infoIcon: {
        marginRight: 5
    }
})

export default styles
