<template>
  <div v-bind="$attrs" v-on="$listeners" class="w-subtitle-1 section-title mt-3 mb-2" :class="{'info--text text--darken-1': !no_color}" data-test-id="section-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    'no_color': {
      type: Boolean,
      required: false,
      default: false,
    },
    'light': {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {};
  },
};
</script>
