// React
import React from 'react'
import PDCtooltip from 'pdc-tooltip'
import { withStyles } from '@material-ui/core'

// Stylesheet
import './search-result.css'

const styles = theme => ({
    tooltip: {
        backgroundColor: '#48B9D5',
        fontSize: 12,
        fontFamily: 'Montserrat',
        borderRadius: 6,
        margin: 0,
        maxWidth: 326,
        width: 326,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    tooltipPopper: {
        top: '-3px !important'
    }
})

// Single Search Result
class SearchResult extends React.Component {
  priceTooltipText = 'Premium numbers bring trust to your business. The fee shown is a one time setup fee.';

  renderE164onHover = number => {
      if (/[a-zA-Z]/.test(number.format_mask)) {
          const e164_number = this.props.E164toPDC(number.phone_number)
          const element = <div className='e164number'>{e164_number}</div>
          return element
      }
      return null
  }

  render () {
      const shouldBlur = this.props.loading || this.props.reservePop
      const blurClass = shouldBlur ? 'search-results-list-blur' : ''
      return (
          <div className={'search-results-list row ' + blurClass}>
              {this.props.resultData.map((number, index) => {
                  let price
                  const isFree = number.price == null || number.price === 0
                  // Format price based on free vs. non-free
                  if (isFree) {
                      price = <span className="number-price number-price-free">FREE</span>
                  } else {
                      price = (
                          <span className="number-price">
                ${Math.round(parseFloat(number.price)).toLocaleString()}
                          </span>
                      )
                  }
                  const keyword = this.props.alpha
                  let display_number
                  if (keyword !== null) {
                      display_number = this.props.prettyFormat(number)
                  } else {
                      display_number = this.props.prettyFormat(number)
                  }
                  return (
                      <PDCtooltip key={number.phone_number} disabled={isFree || shouldBlur} text={this.priceTooltipText}>
                          <div
                              key={number.phone_number}
                              vendor_id={number.vendor_id}
                              data-search-result={`${index}`}
                              className={`search-result justify-content-center ${isFree ? 'search-result-free' : ''} ${/[a-zA-Z]/.test(number.format_mask) ? 'vanity' : ''}`}
                          >
                              <div
                                  vendor_id={number.vendor_id}
                                  onClick={() => {
                                      this.props.moveNumber(number)
                                  }}
                                  className={`number-link ${isFree ? 'number-link-free' : ''} ${/[a-zA-Z]/.test(number.format_mask) ? 'vanity' : ''}`}
                              >
                                  <div dangerouslySetInnerHTML={{ __html: display_number }} />
                                  {this.renderE164onHover(number)}
                                  {price}
                              </div>
                          </div>
                      </PDCtooltip>
                  )
              })}
              {[...Array(10)].map(n => {
                  return (
                      <div
                          key={`num${n}`}
                          className={'search-result justify-content-center'}
                      >
                      </div>
                  )
              })}
          </div>
      )
  }
}

export default withStyles(styles)(SearchResult)
