<template>
	<span @click='copyText' class="copy-to-clipboard" data-test-id="copy-to-clipboard">
		<transition name="slide-fade-right-left" mode="out-in">
			<w-tooltip v-if="!message" top no-carret>
				<template v-slot:activator>
					<v-icon
						color="primary"
						dark
						:large="size === 'big'"
						:small="size === 'small'"
						class="copy-to-clipboard-button"
						data-test-id="copy-to-clipboard-btn"
					>
						mdi-content-copy
					</v-icon>
				</template>
				{{ hover_text }}
			</w-tooltip>
			<span
				v-else
				:class="{'success--text': message.level === 'success', 'error--text': message.level === 'error'}"
				data-test-id="copy-to-clipboard-copied"
			>
				{{ message.message }}
			</span>
		</transition>
	</span>
</template>

<script>
import l from '../../libs/lang';
import copy_to_clipboard from '../../libs/copy-to-clipboard';

export default {
	props: {
		copy: {
			required: true,
			type: String,
		},
		size: {
			default: 'small',
			type: String,
			validator: (val) => ['small', 'medium', 'big'].includes(val),
		},
		hover_text: {
			default: 'Copy to clipboard',
			type: String,
		}
	},
	data() {
		return {
			l,
			message: null,
			message_timeout: null,
		};
	},
	methods: {
		copyText() {
			try {
				copy_to_clipboard(this.$props.copy);
				this.$data.message = {
					level: 'success',
					message: l.t('app.copied', 'Copied'),
				};
			} catch (err) {
				this.$data.message = {
					level: 'error',
					message: l.t(
						'app.generic-error',
						'Something went wrong'
					),
				};
			}
		},
	},
	watch: {
		message: {
			// eslint-disable-next-line object-shorthand
			handler: function (val) {
				if (this.$data.message_timeout) clearTimeout(this.$data.message_timeout);
				if (val) {
					this.$data.message_timeout = setTimeout(
						() => (this.$data.message = null),
						2000
					);
				}
			},
		},
	},
};
</script>
<style scoped>
	.copy-to-clipboard {
		position:  absolute;
	}
	.copy-to-clipboard-button {
		cursor: pointer !important;
	}
</style>
