import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

const api_base = process.env.REACT_APP_USER_API_URL

class API {
    static getMusicOnHoldLink = voip_recording_id => {
        return getPhoneCom().then(phonecom => {
            const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/greetings/get-music-on-hold-link`
            return ajax.post(requestUrl, { voip_recording_id }).then(response => response.data)
        })
    }

    static createFileGreeting = (name, file) => {
        return getPhoneCom().then(phonecom => {
            const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
            return ajax.post(requestUrl, { name, file }).then(response => response.data)
        })
    }

    static createTTSGreeting = (tts_text, tts_voice, name) => {
        return getPhoneCom().then(phonecom => {
            const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
            return ajax.post(requestUrl, { origin: 'tts', tts_text, tts_voice, name }).then(response => response.data)
        })
    }

    static getVoicemailConfig = () => {
        return getPhoneCom().then(phonecom => {
            const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/get-ext-vm-config`
            return ajax.post(requestUrl, {}).then(response => response.data)
        })
    }

    static configureVoicemail = voip_recording_id => {
        return getPhoneCom().then(phonecom => {
            const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/configure-voicemail`
            return ajax.post(requestUrl, { voip_recording_id }).then(response => response.data)
        })
    }

    static getNotificationSettings = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/notifications/get-notification-settings`
        const response = (await ajax.post(requestUrl, {})).data
        return response
    }

    static setNotificationSettings = async data => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/notifications/set-notification-settings`
        const response = (await ajax.post(requestUrl, data)).data
        return response
    }

    static getExtension = async extensionId => {
        const voipId = PhoneComUser.getAPIAccountId()
        extensionId = extensionId || parseInt(PhoneComUser.getExtensionId())
        const baseUrl = 'https://api.phone.com/v4'
        const requestUrl = `${baseUrl}/accounts/${voipId}/extensions/${extensionId}`
        const response = await ajax.get(requestUrl, 'Bearer')
        return response.data
    }

    static addExtensionForward = async (phoneNumber, screening) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-device-calling`
        const data = {
            device_type: 'web',
            call_mode: 'voip',
            caller_id_mode: 'calling_number',
            phone_number: { phone_number: phoneNumber, screening }
        }
        const response = await ajax.post(requestUrl, data)
        return response
    }

    static removeNumberForwarding = async phoneNumber => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/remove-number-forwarding`
        const data = { phone_number: phoneNumber }
        const response = await ajax.post(requestUrl, data)
        return response
    }

    static getUser = async userId => {
        const account_id = PhoneComUser.getAPIAccountId()
        const requestUrl = `${api_base}/voip/${account_id}/users?page_size=1&offset=0&order_by=status.asc&include=extension,plan,devices,numbers&user_ids=${userId}`
        const response = await ajax.get(requestUrl)
        if (response.message === 'Network Error') return null
        return response?.data?.data[0]
    }

    static updateUser = async user => {
        const account_id = PhoneComUser.getAPIAccountId()
        const requestUrl = `${api_base}/voip/${account_id}/users`
        const response = await ajax.put(requestUrl, [user])
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling update users endpoint', err)
            return { error: true }
        }
    }

    static uploadAvatar = async (user, base64image) => {
        const account_id = PhoneComUser.getAPIAccountId()
        const requestUrl = `${api_base}/voip/${account_id}/users/${user.id}/avatar`
        const response = await ajax.put(requestUrl, { image: base64image })
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            console.log('Got non-2xx response from server:', response.response.data)
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling upload-avatar endpoint', err)
            return { error: true }
        }
    }

    static updateContact = async (user_id, type, value) => {
        const account_id = PhoneComUser.getAPIAccountId()
        const payload = {}
        const contact = {
            contact_type: type,
            contact: value
        }
        payload.contact = contact

        const requestUrl = `${api_base}/voip/${account_id}/user/${user_id}/update_contact`
        const response = await ajax.post(requestUrl, { contact })
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            console.log('Got non-2xx response from server:', response.response.data)
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling update contact endpoint', err)
            return { error: true }
        }
    }

    static confirmContact = async (user_id, type, value, code) => {
        const account_id = PhoneComUser.getAPIAccountId()
        const payload = {}
        const contact = {
            contact_type: type,
            contact: value,
            verification_code: code
        }
        payload.contact = contact

        const requestUrl = `${api_base}/voip/${account_id}/user/${user_id}/confirm_contact`
        const response = await ajax.post(requestUrl, { contact })
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            console.log('Got non-2xx response from server:', response.response.data)
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling confirm contact endpoint', err)
            return { error: true }
        }
    }

    static updateAsignedNumberNames = async (current_user_name, new_user_name, user_extension_id) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/update-number-names`
        const data = { current_user_name, new_user_name, user_extension_id }
        const response = await ajax.post(requestUrl, data)
        return response
    }

    static updateMeetingsProfile = async (new_user_name, new_user_email) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/video/update-user-info`
        const data = { name: new_user_name, email: new_user_email }
        const response = await ajax.post(requestUrl, data)
        return response
    }

    static loadPhoneNumbers = async (cursor = null, filters = {}, limit = 20, sort = 'asc') => {
        const data = { filters, sort, limit }
        if (cursor) data.cursor = cursor
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/list-phone-numbers-2-2`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static updateExtensionCallerId = async (extensionId, callerId) => {
        const baseUrl = 'https://api.phone.com/v4'
        const voipId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${baseUrl}/accounts/${voipId}/extensions/${extensionId}`
        const response = await ajax.patchClean(requestUrl, { caller_id: callerId })
        if (response.toString().substr(0, 5) === 'Error') return { error: true }
        return response.data
    }

    static getAutoResponderEvents = async data => {
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const accountId = PhoneComUser.getAPIAccountId()
        const extensionId = PhoneComUser.getExtensionId()
        const requestUrl = `${v4Root}/accounts/${accountId}/extensions/${extensionId}/integrations/events/overview`
        const response = await ajax.get(requestUrl)
        if (response.message === 'Network Error') return []
        return response.data
    }

    static addAutoResponderCallback = async (message, rateLimit) => {
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const accountId = PhoneComUser.getAPIAccountId()
        const extensionId = PhoneComUser.getExtensionId()
        const requestUrl = `${v4Root}/accounts/${accountId}/extensions/${extensionId}/integrations/events/callbacks`
        const payload = {
            mode: 'APPLICATION',
            config: {
                application: 'sms-autoresponder',
                parameters: {
                    message,
                    'rate-limit': rateLimit
                }
            }
        }
        const response = await ajax.postClean(requestUrl, payload)
        if (response.message === 'Network Error') return {}
        return response.data
    }

    static addAutoResponderListener = async callbackId => {
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const accountId = PhoneComUser.getAPIAccountId()
        const extensionId = PhoneComUser.getExtensionId()
        const requestUrl = `${v4Root}/accounts/${accountId}/extensions/${extensionId}/integrations/events/listeners`
        const payload = { callback_id: callbackId } // eslint-disable-line
        const response = await ajax.postClean(requestUrl, payload)
        if (response.message === 'Network Error') return {}
        return response.data
    }

    static addAutoResponderSubscription = async (listenerId, subscriptionType) => {
        if (!['calls', 'messages'].includes(subscriptionType)) return
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const accountId = PhoneComUser.getAPIAccountId()
        const extensionId = PhoneComUser.getExtensionId()
        const requestUrl = `${v4Root}/accounts/${accountId}/extensions/${extensionId}/integrations/events/listeners/${listenerId}/subscriptions`
        const payload = {
            ':tags': subscriptionType === 'messages'
                ? ['message', '*ms-message', 'inbound']
                : ['call', 'completed-call', 'inbound']
        }
        const response = await ajax.postClean(requestUrl, payload)
        if (response.message === 'Network Error') return {}
        return response.data
    }

    static deleteAutoRespondedListener = async (listenerId) => {
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const accountId = PhoneComUser.getAPIAccountId()
        const extensionId = PhoneComUser.getExtensionId()
        const requestUrl = `${v4Root}/accounts/${accountId}/extensions/${extensionId}/integrations/events/listeners/${listenerId}`
        const response = await ajax.delete(requestUrl, {})
        if (response.message === 'Network Error') return {}
        return response.data
    }
}

export default API
