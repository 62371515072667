import React from 'react'
import Section from './Section'
import UsersDropdown from './UsersDropdown'
import { OnNoAnswer } from './configure_util'
import NoAnswerIcon from './icons/NoAnswerIcon'
import { Stepper, Variant as StepperVariant, StepInfo } from 'stepper'
import gtmDataPush from 'gtm-events'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import Typography from 'typography'

/**
 *
 */
const NoAnswerSection = (props: any): JSX.Element => {
    const { classes, screenViewType, users, showValidationErrors, companyExtension } = props
    const { isMobileView } = screenViewType

    const GtmAppName = 'configure-number'
    const GtmMap = { OtherOption: 1, SameOption: 0 }

    const selectedScheduleOption = props.selectedScheduleOption
    const onCallOptionGroups = { ...props.onCallOptionGroups }
    const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }

    const userNothingSelectedOptions = Object.values(OnNoAnswer)
    const noAnswerSelectedOption = onCallOptionGroup.forward[1].enabled ? OnNoAnswer.Forward : OnNoAnswer.Voicemail
    const hasTimeoutError: boolean = props.showValidationErrors && !noAnswerSelectedOption

    const steps: StepInfo[] = [
        {
            id: 'forward-no-anwer-option',
            title: 'What should we do if no one picks up?',
            active: true,
            content: (
                <div data-test-id='ring-users-first-timeout-section'>
                    <Select
                        value = {noAnswerSelectedOption}
                        onChange = {(event) => {
                            const option = event.target.value
                            // eslint-disable-next-line
                            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `forward-first-timeout-option;${option}`, PDC_Value: noAnswerSelectedOption !== option ? GtmMap.OtherOption : GtmMap.SameOption })
                            onCallOptionGroup.forward[1].enabled = option === OnNoAnswer.Forward
                            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                            props.updateOnCallOptionGroups(onCallOptionGroups)
                            console.log('onCallOptionGroups 1', onCallOptionGroups)
                            console.log('event.target.value 1', event.target.value)
                        }}
                        MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                        formControlClasses = {{ root: `${classes.dropdownMenu} size-lg` }}
                        error = {hasTimeoutError}
                        helperText = {hasTimeoutError ? 'Required field' : ''}
                        data-test-id = 'menu-timeout-select'
                    >
                        {userNothingSelectedOptions.map((option, i) => {
                            const optionText = option.split('-').join(' ')
                            return (
                                <MenuItem
                                    key = {i}
                                    value = {option}
                                    checked = {option === noAnswerSelectedOption}
                                    data-test-id = {option}
                                >{optionText[0].toUpperCase()}{optionText.substring(1)}</MenuItem>
                            )
                        })}
                    </Select>
                </div>
            )
        }
    ]

    if (noAnswerSelectedOption === OnNoAnswer.Forward) {
        const mobileClass = isMobileView ? 'mobile' : ''
        steps.push({
            id: 'forward-no-answer-forward',
            title: 'Select users to ring',
            active: true,
            content: (
                <div className={`${classes.forwardTimeoutForwardWrapper} ${mobileClass}`} data-test-id='ring-users-first-timeout-forward-section'>
                    <UsersDropdown
                        users = {users}
                        selectedValue = {onCallOptionGroup.forward[1].users}
                        isMultiple
                        onChange = {event => {
                            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'forward-timeout-forward;user-click' }) // eslint-disable-line
                            onCallOptionGroup.forward[1].users = event.target.value
                            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                            props.updateOnCallOptionGroups(onCallOptionGroups)
                        }}
                        showValidationErrors = {showValidationErrors}
                    />
                    <Typography classes={{ root: 'connector-text' }} variant='body2' remoteConfigID='configure_number_forward_second_timeout_voicemail_message'/>
                    <UsersDropdown
                        users = {users}
                        selectedValue = {onCallOptionGroup.assignVoicemailUser}
                        onChange = {event => {
                            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'forward-second-timeout-voicemail;user-click' }) // eslint-disable-line
                            onCallOptionGroup.assignVoicemailUser = event.target.value
                            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                            props.updateOnCallOptionGroups(onCallOptionGroups)
                            console.log('onCallOptionGroups 3', onCallOptionGroups)
                            console.log('event.target.value 3', event.target.value)
                        }}
                        companyExtension = {companyExtension}
                        showValidationErrors = {showValidationErrors}
                        dataTestId = 'forward-timeout-forward-voicemail'
                    />
                </div>
            )
        })
    } else if (noAnswerSelectedOption === OnNoAnswer.Voicemail) {
        steps.push({
            id: 'forward-no-answer-voicemail',
            title: 'Assign voicemail to:',
            active: true,
            content: (
                <div data-test-id='ring-users-second-timeout-voicemail-section'>
                    <UsersDropdown
                        users = {users}
                        selectedValue = {onCallOptionGroup.assignVoicemailUser}
                        onChange = {event => {
                            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'forward-timeout-voicemail;user-click' }) // eslint-disable-line
                            onCallOptionGroup.assignVoicemailUser = event.target.value
                            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                            props.updateOnCallOptionGroups(onCallOptionGroups)
                        }}
                        companyExtension = {companyExtension}
                        showValidationErrors = {showValidationErrors}
                        dataTestId = 'forward-timeout-forward-voicemail'
                    />
                </div>
            )
        })
    }

    return (
        <Section screenViewType={screenViewType} classes={classes} icon={<NoAnswerIcon/>} remoteConfigID='configure_number_forward_no_answer_header_text'>
            <Stepper variant={StepperVariant.VERTICAL} steps={steps} classes={isMobileView ? { root: classes.mobileViewStepper } : {}}/>
        </Section>
    )
}

export default NoAnswerSection
