export default [
    {
        alpha: 'ISO 3166-2:AF',
        numeric: 4,
        alpha3: 'AFG',
        alpha2: 'AF',
        name: 'Afghanistan'
    },
    {
        alpha: 'ISO 3166-2:AX',
        numeric: 248,
        alpha3: 'ALA',
        alpha2: 'AX',
        name: 'Åland Islands'
    },
    {
        alpha: 'ISO 3166-2:AL',
        numeric: 8,
        alpha3: 'ALB',
        alpha2: 'AL',
        name: 'Albania'
    },
    {
        alpha: 'ISO 3166-2:DZ',
        numeric: 12,
        alpha3: 'DZA',
        alpha2: 'DZ',
        name: 'Algeria'
    },
    {
        alpha: 'ISO 3166-2:AS',
        numeric: 16,
        alpha3: 'ASM',
        alpha2: 'AS',
        name: 'American Samoa'
    },
    {
        alpha: 'ISO 3166-2:AD',
        numeric: 20,
        alpha3: 'AND',
        alpha2: 'AD',
        name: 'Andorra'
    },
    {
        alpha: 'ISO 3166-2:AO',
        numeric: 24,
        alpha3: 'AGO',
        alpha2: 'AO',
        name: 'Angola'
    },
    {
        alpha: 'ISO 3166-2:AI',
        numeric: 660,
        alpha3: 'AIA',
        alpha2: 'AI',
        name: 'Anguilla'
    },
    {
        alpha: 'ISO 3166-2:AQ',
        numeric: 10,
        alpha3: 'ATA',
        alpha2: 'AQ',
        name: 'Antarctica'
    },
    {
        alpha: 'ISO 3166-2:AG',
        numeric: 28,
        alpha3: 'ATG',
        alpha2: 'AG',
        name: 'Antigua and Barbuda'
    },
    {
        alpha: 'ISO 3166-2:AR',
        numeric: 32,
        alpha3: 'ARG',
        alpha2: 'AR',
        name: 'Argentina'
    },
    {
        alpha: 'ISO 3166-2:AM',
        numeric: 51,
        alpha3: 'ARM',
        alpha2: 'AM',
        name: 'Armenia'
    },
    {
        alpha: 'ISO 3166-2:AW',
        numeric: 533,
        alpha3: 'ABW',
        alpha2: 'AW',
        name: 'Aruba'
    },
    {
        alpha: 'ISO 3166-2:AU',
        numeric: 36,
        alpha3: 'AUS',
        alpha2: 'AU',
        name: 'Australia'
    },
    {
        alpha: 'ISO 3166-2:AT',
        numeric: 40,
        alpha3: 'AUT',
        alpha2: 'AT',
        name: 'Austria'
    },
    {
        alpha: 'ISO 3166-2:AZ',
        numeric: 31,
        alpha3: 'AZE',
        alpha2: 'AZ',
        name: 'Azerbaijan'
    },
    {
        alpha: 'ISO 3166-2:BS',
        numeric: 44,
        alpha3: 'BHS',
        alpha2: 'BS',
        name: 'Bahamas'
    },
    {
        alpha: 'ISO 3166-2:BH',
        numeric: 48,
        alpha3: 'BHR',
        alpha2: 'BH',
        name: 'Bahrain'
    },
    {
        alpha: 'ISO 3166-2:BD',
        numeric: 50,
        alpha3: 'BGD',
        alpha2: 'BD',
        name: 'Bangladesh'
    },
    {
        alpha: 'ISO 3166-2:BB',
        numeric: 52,
        alpha3: 'BRB',
        alpha2: 'BB',
        name: 'Barbados'
    },
    {
        alpha: 'ISO 3166-2:BY',
        numeric: 112,
        alpha3: 'BLR',
        alpha2: 'BY',
        name: 'Belarus'
    },
    {
        alpha: 'ISO 3166-2:BE',
        numeric: 56,
        alpha3: 'BEL',
        alpha2: 'BE',
        name: 'Belgium'
    },
    {
        alpha: 'ISO 3166-2:BZ',
        numeric: 84,
        alpha3: 'BLZ',
        alpha2: 'BZ',
        name: 'Belize'
    },
    {
        alpha: 'ISO 3166-2:BJ',
        numeric: 204,
        alpha3: 'BEN',
        alpha2: 'BJ',
        name: 'Benin'
    },
    {
        alpha: 'ISO 3166-2:BM',
        numeric: 60,
        alpha3: 'BMU',
        alpha2: 'BM',
        name: 'Bermuda'
    },
    {
        alpha: 'ISO 3166-2:BT',
        numeric: 64,
        alpha3: 'BTN',
        alpha2: 'BT',
        name: 'Bhutan'
    },
    {
        alpha: 'ISO 3166-2:BO',
        numeric: 68,
        alpha3: 'BOL',
        alpha2: 'BO',
        name: 'Bolivia, Plurinational State of'
    },
    {
        alpha: 'ISO 3166-2:BQ',
        numeric: 535,
        alpha3: 'BES',
        alpha2: 'BQ',
        name: 'Bonaire, Sint Eustatius and Saba'
    },
    {
        alpha: 'ISO 3166-2:BA',
        numeric: 70,
        alpha3: 'BIH',
        alpha2: 'BA',
        name: 'Bosnia and Herzegovina'
    },
    {
        alpha: 'ISO 3166-2:BW',
        numeric: 72,
        alpha3: 'BWA',
        alpha2: 'BW',
        name: 'Botswana'
    },
    {
        alpha: 'ISO 3166-2:BV',
        numeric: 74,
        alpha3: 'BVT',
        alpha2: 'BV',
        name: 'Bouvet Island'
    },
    {
        alpha: 'ISO 3166-2:BR',
        numeric: 76,
        alpha3: 'BRA',
        alpha2: 'BR',
        name: 'Brazil'
    },
    {
        alpha: 'ISO 3166-2:IO',
        numeric: 86,
        alpha3: 'IOT',
        alpha2: 'IO',
        name: 'British Indian Ocean Territory'
    },
    {
        alpha: 'ISO 3166-2:BN',
        numeric: 96,
        alpha3: 'BRN',
        alpha2: 'BN',
        name: 'Brunei Darussalam'
    },
    {
        alpha: 'ISO 3166-2:BG',
        numeric: 100,
        alpha3: 'BGR',
        alpha2: 'BG',
        name: 'Bulgaria'
    },
    {
        alpha: 'ISO 3166-2:BF',
        numeric: 854,
        alpha3: 'BFA',
        alpha2: 'BF',
        name: 'Burkina Faso'
    },
    {
        alpha: 'ISO 3166-2:BI',
        numeric: 108,
        alpha3: 'BDI',
        alpha2: 'BI',
        name: 'Burundi'
    },
    {
        alpha: 'ISO 3166-2:KH',
        numeric: 116,
        alpha3: 'KHM',
        alpha2: 'KH',
        name: 'Cambodia'
    },
    {
        alpha: 'ISO 3166-2:CM',
        numeric: 120,
        alpha3: 'CMR',
        alpha2: 'CM',
        name: 'Cameroon'
    },
    {
        alpha: 'ISO 3166-2:CA',
        numeric: 124,
        alpha3: 'CAN',
        alpha2: 'CA',
        name: 'Canada'
    },
    {
        alpha: 'ISO 3166-2:CV',
        numeric: 132,
        alpha3: 'CPV',
        alpha2: 'CV',
        name: 'Cape Verde'
    },
    {
        alpha: 'ISO 3166-2:KY',
        numeric: 136,
        alpha3: 'CYM',
        alpha2: 'KY',
        name: 'Cayman Islands'
    },
    {
        alpha: 'ISO 3166-2:CF',
        numeric: 140,
        alpha3: 'CAF',
        alpha2: 'CF',
        name: 'Central African Republic'
    },
    {
        alpha: 'ISO 3166-2:TD',
        numeric: 148,
        alpha3: 'TCD',
        alpha2: 'TD',
        name: 'Chad'
    },
    {
        alpha: 'ISO 3166-2:CL',
        numeric: 152,
        alpha3: 'CHL',
        alpha2: 'CL',
        name: 'Chile'
    },
    {
        alpha: 'ISO 3166-2:CN',
        numeric: 156,
        alpha3: 'CHN',
        alpha2: 'CN',
        name: 'China'
    },
    {
        alpha: 'ISO 3166-2:CX',
        numeric: 162,
        alpha3: 'CXR',
        alpha2: 'CX',
        name: 'Christmas Island'
    },
    {
        alpha: 'ISO 3166-2:CC',
        numeric: 166,
        alpha3: 'CCK',
        alpha2: 'CC',
        name: 'Cocos (Keeling) Islands'
    },
    {
        alpha: 'ISO 3166-2:CO',
        numeric: 170,
        alpha3: 'COL',
        alpha2: 'CO',
        name: 'Colombia'
    },
    {
        alpha: 'ISO 3166-2:KM',
        numeric: 174,
        alpha3: 'COM',
        alpha2: 'KM',
        name: 'Comoros'
    },
    {
        alpha: 'ISO 3166-2:CG',
        numeric: 178,
        alpha3: 'COG',
        alpha2: 'CG',
        name: 'Congo'
    },
    {
        alpha: 'ISO 3166-2:CD',
        numeric: 180,
        alpha3: 'COD',
        alpha2: 'CD',
        name: 'Congo, the Democratic Republic of the'
    },
    {
        alpha: 'ISO 3166-2:CK',
        numeric: 184,
        alpha3: 'COK',
        alpha2: 'CK',
        name: 'Cook Islands'
    },
    {
        alpha: 'ISO 3166-2:CR',
        numeric: 188,
        alpha3: 'CRI',
        alpha2: 'CR',
        name: 'Costa Rica'
    },
    {
        alpha: 'ISO 3166-2:CI',
        numeric: 384,
        alpha3: 'CIV',
        alpha2: 'CI',
        name: "Côte d'Ivoire"
    },
    {
        alpha: 'ISO 3166-2:HR',
        numeric: 191,
        alpha3: 'HRV',
        alpha2: 'HR',
        name: 'Croatia'
    },
    {
        alpha: 'ISO 3166-2:CU',
        numeric: 192,
        alpha3: 'CUB',
        alpha2: 'CU',
        name: 'Cuba'
    },
    {
        alpha: 'ISO 3166-2:CW',
        numeric: 531,
        alpha3: 'CUW',
        alpha2: 'CW',
        name: 'Curaçao'
    },
    {
        alpha: 'ISO 3166-2:CY',
        numeric: 196,
        alpha3: 'CYP',
        alpha2: 'CY',
        name: 'Cyprus'
    },
    {
        alpha: 'ISO 3166-2:CZ',
        numeric: 203,
        alpha3: 'CZE',
        alpha2: 'CZ',
        name: 'Czech Republic'
    },
    {
        alpha: 'ISO 3166-2:DK',
        numeric: 208,
        alpha3: 'DNK',
        alpha2: 'DK',
        name: 'Denmark'
    },
    {
        alpha: 'ISO 3166-2:DJ',
        numeric: 262,
        alpha3: 'DJI',
        alpha2: 'DJ',
        name: 'Djibouti'
    },
    {
        alpha: 'ISO 3166-2:DM',
        numeric: 212,
        alpha3: 'DMA',
        alpha2: 'DM',
        name: 'Dominica'
    },
    {
        alpha: 'ISO 3166-2:DO',
        numeric: 214,
        alpha3: 'DOM',
        alpha2: 'DO',
        name: 'Dominican Republic'
    },
    {
        alpha: 'ISO 3166-2:EC',
        numeric: 218,
        alpha3: 'ECU',
        alpha2: 'EC',
        name: 'Ecuador'
    },
    {
        alpha: 'ISO 3166-2:EG',
        numeric: 818,
        alpha3: 'EGY',
        alpha2: 'EG',
        name: 'Egypt'
    },
    {
        alpha: 'ISO 3166-2:SV',
        numeric: 222,
        alpha3: 'SLV',
        alpha2: 'SV',
        name: 'El Salvador'
    },
    {
        alpha: 'ISO 3166-2:GQ',
        numeric: 226,
        alpha3: 'GNQ',
        alpha2: 'GQ',
        name: 'Equatorial Guinea'
    },
    {
        alpha: 'ISO 3166-2:ER',
        numeric: 232,
        alpha3: 'ERI',
        alpha2: 'ER',
        name: 'Eritrea'
    },
    {
        alpha: 'ISO 3166-2:EE',
        numeric: 233,
        alpha3: 'EST',
        alpha2: 'EE',
        name: 'Estonia'
    },
    {
        alpha: 'ISO 3166-2:ET',
        numeric: 231,
        alpha3: 'ETH',
        alpha2: 'ET',
        name: 'Ethiopia'
    },
    {
        alpha: 'ISO 3166-2:FK',
        numeric: 238,
        alpha3: 'FLK',
        alpha2: 'FK',
        name: 'Falkland Islands (Malvinas)'
    },
    {
        alpha: 'ISO 3166-2:FO',
        numeric: 234,
        alpha3: 'FRO',
        alpha2: 'FO',
        name: 'Faroe Islands'
    },
    {
        alpha: 'ISO 3166-2:FJ',
        numeric: 242,
        alpha3: 'FJI',
        alpha2: 'FJ',
        name: 'Fiji'
    },
    {
        alpha: 'ISO 3166-2:FI',
        numeric: 246,
        alpha3: 'FIN',
        alpha2: 'FI',
        name: 'Finland'
    },
    {
        alpha: 'ISO 3166-2:FR',
        numeric: 250,
        alpha3: 'FRA',
        alpha2: 'FR',
        name: 'France'
    },
    {
        alpha: 'ISO 3166-2:GF',
        numeric: 254,
        alpha3: 'GUF',
        alpha2: 'GF',
        name: 'French Guiana'
    },
    {
        alpha: 'ISO 3166-2:PF',
        numeric: 258,
        alpha3: 'PYF',
        alpha2: 'PF',
        name: 'French Polynesia'
    },
    {
        alpha: 'ISO 3166-2:TF',
        numeric: 260,
        alpha3: 'ATF',
        alpha2: 'TF',
        name: 'French Southern Territories'
    },
    {
        alpha: 'ISO 3166-2:GA',
        numeric: 266,
        alpha3: 'GAB',
        alpha2: 'GA',
        name: 'Gabon'
    },
    {
        alpha: 'ISO 3166-2:GM',
        numeric: 270,
        alpha3: 'GMB',
        alpha2: 'GM',
        name: 'Gambia'
    },
    {
        alpha: 'ISO 3166-2:GE',
        numeric: 268,
        alpha3: 'GEO',
        alpha2: 'GE',
        name: 'Georgia'
    },
    {
        alpha: 'ISO 3166-2:DE',
        numeric: 276,
        alpha3: 'DEU',
        alpha2: 'DE',
        name: 'Germany'
    },
    {
        alpha: 'ISO 3166-2:GH',
        numeric: 288,
        alpha3: 'GHA',
        alpha2: 'GH',
        name: 'Ghana'
    },
    {
        alpha: 'ISO 3166-2:GI',
        numeric: 292,
        alpha3: 'GIB',
        alpha2: 'GI',
        name: 'Gibraltar'
    },
    {
        alpha: 'ISO 3166-2:GR',
        numeric: 300,
        alpha3: 'GRC',
        alpha2: 'GR',
        name: 'Greece'
    },
    {
        alpha: 'ISO 3166-2:GL',
        numeric: 304,
        alpha3: 'GRL',
        alpha2: 'GL',
        name: 'Greenland'
    },
    {
        alpha: 'ISO 3166-2:GD',
        numeric: 308,
        alpha3: 'GRD',
        alpha2: 'GD',
        name: 'Grenada'
    },
    {
        alpha: 'ISO 3166-2:GP',
        numeric: 312,
        alpha3: 'GLP',
        alpha2: 'GP',
        name: 'Guadeloupe'
    },
    {
        alpha: 'ISO 3166-2:GU',
        numeric: 316,
        alpha3: 'GUM',
        alpha2: 'GU',
        name: 'Guam'
    },
    {
        alpha: 'ISO 3166-2:GT',
        numeric: 320,
        alpha3: 'GTM',
        alpha2: 'GT',
        name: 'Guatemala'
    },
    {
        alpha: 'ISO 3166-2:GG',
        numeric: 831,
        alpha3: 'GGY',
        alpha2: 'GG',
        name: 'Guernsey'
    },
    {
        alpha: 'ISO 3166-2:GN',
        numeric: 324,
        alpha3: 'GIN',
        alpha2: 'GN',
        name: 'Guinea'
    },
    {
        alpha: 'ISO 3166-2:GW',
        numeric: 624,
        alpha3: 'GNB',
        alpha2: 'GW',
        name: 'Guinea-Bissau'
    },
    {
        alpha: 'ISO 3166-2:GY',
        numeric: 328,
        alpha3: 'GUY',
        alpha2: 'GY',
        name: 'Guyana'
    },
    {
        alpha: 'ISO 3166-2:HT',
        numeric: 332,
        alpha3: 'HTI',
        alpha2: 'HT',
        name: 'Haiti'
    },
    {
        alpha: 'ISO 3166-2:HM',
        numeric: 334,
        alpha3: 'HMD',
        alpha2: 'HM',
        name: 'Heard Island and McDonald Islands'
    },
    {
        alpha: 'ISO 3166-2:VA',
        numeric: 336,
        alpha3: 'VAT',
        alpha2: 'VA',
        name: 'Holy See (Vatican City State)'
    },
    {
        alpha: 'ISO 3166-2:HN',
        numeric: 340,
        alpha3: 'HND',
        alpha2: 'HN',
        name: 'Honduras'
    },
    {
        alpha: 'ISO 3166-2:HK',
        numeric: 344,
        alpha3: 'HKG',
        alpha2: 'HK',
        name: 'Hong Kong'
    },
    {
        alpha: 'ISO 3166-2:HU',
        numeric: 348,
        alpha3: 'HUN',
        alpha2: 'HU',
        name: 'Hungary'
    },
    {
        alpha: 'ISO 3166-2:IS',
        numeric: 352,
        alpha3: 'ISL',
        alpha2: 'IS',
        name: 'Iceland'
    },
    {
        alpha: 'ISO 3166-2:IN',
        numeric: 356,
        alpha3: 'IND',
        alpha2: 'IN',
        name: 'India'
    },
    {
        alpha: 'ISO 3166-2:ID',
        numeric: 360,
        alpha3: 'IDN',
        alpha2: 'ID',
        name: 'Indonesia'
    },
    {
        alpha: 'ISO 3166-2:IR',
        numeric: 364,
        alpha3: 'IRN',
        alpha2: 'IR',
        name: 'Iran, Islamic Republic of'
    },
    {
        alpha: 'ISO 3166-2:IQ',
        numeric: 368,
        alpha3: 'IRQ',
        alpha2: 'IQ',
        name: 'Iraq'
    },
    {
        alpha: 'ISO 3166-2:IE',
        numeric: 372,
        alpha3: 'IRL',
        alpha2: 'IE',
        name: 'Ireland'
    },
    {
        alpha: 'ISO 3166-2:IM',
        numeric: 833,
        alpha3: 'IMN',
        alpha2: 'IM',
        name: 'Isle of Man'
    },
    {
        alpha: 'ISO 3166-2:IL',
        numeric: 376,
        alpha3: 'ISR',
        alpha2: 'IL',
        name: 'Israel'
    },
    {
        alpha: 'ISO 3166-2:IT',
        numeric: 380,
        alpha3: 'ITA',
        alpha2: 'IT',
        name: 'Italy'
    },
    {
        alpha: 'ISO 3166-2:JM',
        numeric: 388,
        alpha3: 'JAM',
        alpha2: 'JM',
        name: 'Jamaica'
    },
    {
        alpha: 'ISO 3166-2:JP',
        numeric: 392,
        alpha3: 'JPN',
        alpha2: 'JP',
        name: 'Japan'
    },
    {
        alpha: 'ISO 3166-2:JE',
        numeric: 832,
        alpha3: 'JEY',
        alpha2: 'JE',
        name: 'Jersey'
    },
    {
        alpha: 'ISO 3166-2:JO',
        numeric: 400,
        alpha3: 'JOR',
        alpha2: 'JO',
        name: 'Jordan'
    },
    {
        alpha: 'ISO 3166-2:KZ',
        numeric: 398,
        alpha3: 'KAZ',
        alpha2: 'KZ',
        name: 'Kazakhstan'
    },
    {
        alpha: 'ISO 3166-2:KE',
        numeric: 404,
        alpha3: 'KEN',
        alpha2: 'KE',
        name: 'Kenya'
    },
    {
        alpha: 'ISO 3166-2:KI',
        numeric: 296,
        alpha3: 'KIR',
        alpha2: 'KI',
        name: 'Kiribati'
    },
    {
        alpha: 'ISO 3166-2:KP',
        numeric: 408,
        alpha3: 'PRK',
        alpha2: 'KP',
        name: "Korea, Democratic People's Republic of"
    },
    {
        alpha: 'ISO 3166-2:KR',
        numeric: 410,
        alpha3: 'KOR',
        alpha2: 'KR',
        name: 'Korea, Republic of'
    },
    {
        alpha: 'ISO 3166-2:KW',
        numeric: 414,
        alpha3: 'KWT',
        alpha2: 'KW',
        name: 'Kuwait'
    },
    {
        alpha: 'ISO 3166-2:KG',
        numeric: 417,
        alpha3: 'KGZ',
        alpha2: 'KG',
        name: 'Kyrgyzstan'
    },
    {
        alpha: 'ISO 3166-2:LA',
        numeric: 418,
        alpha3: 'LAO',
        alpha2: 'LA',
        name: "Lao People's Democratic Republic"
    },
    {
        alpha: 'ISO 3166-2:LV',
        numeric: 428,
        alpha3: 'LVA',
        alpha2: 'LV',
        name: 'Latvia'
    },
    {
        alpha: 'ISO 3166-2:LB',
        numeric: 422,
        alpha3: 'LBN',
        alpha2: 'LB',
        name: 'Lebanon'
    },
    {
        alpha: 'ISO 3166-2:LS',
        numeric: 426,
        alpha3: 'LSO',
        alpha2: 'LS',
        name: 'Lesotho'
    },
    {
        alpha: 'ISO 3166-2:LR',
        numeric: 430,
        alpha3: 'LBR',
        alpha2: 'LR',
        name: 'Liberia'
    },
    {
        alpha: 'ISO 3166-2:LY',
        numeric: 434,
        alpha3: 'LBY',
        alpha2: 'LY',
        name: 'Libya'
    },
    {
        alpha: 'ISO 3166-2:LI',
        numeric: 438,
        alpha3: 'LIE',
        alpha2: 'LI',
        name: 'Liechtenstein'
    },
    {
        alpha: 'ISO 3166-2:LT',
        numeric: 440,
        alpha3: 'LTU',
        alpha2: 'LT',
        name: 'Lithuania'
    },
    {
        alpha: 'ISO 3166-2:LU',
        numeric: 442,
        alpha3: 'LUX',
        alpha2: 'LU',
        name: 'Luxembourg'
    },
    {
        alpha: 'ISO 3166-2:MO',
        numeric: 446,
        alpha3: 'MAC',
        alpha2: 'MO',
        name: 'Macao'
    },
    {
        alpha: 'ISO 3166-2:MK',
        numeric: 807,
        alpha3: 'MKD',
        alpha2: 'MK',
        name: 'Macedonia, the former Yugoslav Republic of'
    },
    {
        alpha: 'ISO 3166-2:MG',
        numeric: 450,
        alpha3: 'MDG',
        alpha2: 'MG',
        name: 'Madagascar'
    },
    {
        alpha: 'ISO 3166-2:MW',
        numeric: 454,
        alpha3: 'MWI',
        alpha2: 'MW',
        name: 'Malawi'
    },
    {
        alpha: 'ISO 3166-2:MY',
        numeric: 458,
        alpha3: 'MYS',
        alpha2: 'MY',
        name: 'Malaysia'
    },
    {
        alpha: 'ISO 3166-2:MV',
        numeric: 462,
        alpha3: 'MDV',
        alpha2: 'MV',
        name: 'Maldives'
    },
    {
        alpha: 'ISO 3166-2:ML',
        numeric: 466,
        alpha3: 'MLI',
        alpha2: 'ML',
        name: 'Mali'
    },
    {
        alpha: 'ISO 3166-2:MT',
        numeric: 470,
        alpha3: 'MLT',
        alpha2: 'MT',
        name: 'Malta'
    },
    {
        alpha: 'ISO 3166-2:MH',
        numeric: 584,
        alpha3: 'MHL',
        alpha2: 'MH',
        name: 'Marshall Islands'
    },
    {
        alpha: 'ISO 3166-2:MQ',
        numeric: 474,
        alpha3: 'MTQ',
        alpha2: 'MQ',
        name: 'Martinique'
    },
    {
        alpha: 'ISO 3166-2:MR',
        numeric: 478,
        alpha3: 'MRT',
        alpha2: 'MR',
        name: 'Mauritania'
    },
    {
        alpha: 'ISO 3166-2:MU',
        numeric: 480,
        alpha3: 'MUS',
        alpha2: 'MU',
        name: 'Mauritius'
    },
    {
        alpha: 'ISO 3166-2:YT',
        numeric: 175,
        alpha3: 'MYT',
        alpha2: 'YT',
        name: 'Mayotte'
    },
    {
        alpha: 'ISO 3166-2:MX',
        numeric: 484,
        alpha3: 'MEX',
        alpha2: 'MX',
        name: 'Mexico'
    },
    {
        alpha: 'ISO 3166-2:FM',
        numeric: 583,
        alpha3: 'FSM',
        alpha2: 'FM',
        name: 'Micronesia, Federated States of'
    },
    {
        alpha: 'ISO 3166-2:MD',
        numeric: 498,
        alpha3: 'MDA',
        alpha2: 'MD',
        name: 'Moldova, Republic of'
    },
    {
        alpha: 'ISO 3166-2:MC',
        numeric: 492,
        alpha3: 'MCO',
        alpha2: 'MC',
        name: 'Monaco'
    },
    {
        alpha: 'ISO 3166-2:MN',
        numeric: 496,
        alpha3: 'MNG',
        alpha2: 'MN',
        name: 'Mongolia'
    },
    {
        alpha: 'ISO 3166-2:ME',
        numeric: 499,
        alpha3: 'MNE',
        alpha2: 'ME',
        name: 'Montenegro'
    },
    {
        alpha: 'ISO 3166-2:MS',
        numeric: 500,
        alpha3: 'MSR',
        alpha2: 'MS',
        name: 'Montserrat'
    },
    {
        alpha: 'ISO 3166-2:MA',
        numeric: 504,
        alpha3: 'MAR',
        alpha2: 'MA',
        name: 'Morocco'
    },
    {
        alpha: 'ISO 3166-2:MZ',
        numeric: 508,
        alpha3: 'MOZ',
        alpha2: 'MZ',
        name: 'Mozambique'
    },
    {
        alpha: 'ISO 3166-2:MM',
        numeric: 104,
        alpha3: 'MMR',
        alpha2: 'MM',
        name: 'Myanmar'
    },
    {
        alpha: 'ISO 3166-2:NA',
        numeric: 516,
        alpha3: 'NAM',
        alpha2: 'NA',
        name: 'Namibia'
    },
    {
        alpha: 'ISO 3166-2:NR',
        numeric: 520,
        alpha3: 'NRU',
        alpha2: 'NR',
        name: 'Nauru'
    },
    {
        alpha: 'ISO 3166-2:NP',
        numeric: 524,
        alpha3: 'NPL',
        alpha2: 'NP',
        name: 'Nepal'
    },
    {
        alpha: 'ISO 3166-2:NL',
        numeric: 528,
        alpha3: 'NLD',
        alpha2: 'NL',
        name: 'Netherlands'
    },
    {
        alpha: 'ISO 3166-2:NC',
        numeric: 540,
        alpha3: 'NCL',
        alpha2: 'NC',
        name: 'New Caledonia'
    },
    {
        alpha: 'ISO 3166-2:NZ',
        numeric: 554,
        alpha3: 'NZL',
        alpha2: 'NZ',
        name: 'New Zealand'
    },
    {
        alpha: 'ISO 3166-2:NI',
        numeric: 558,
        alpha3: 'NIC',
        alpha2: 'NI',
        name: 'Nicaragua'
    },
    {
        alpha: 'ISO 3166-2:NE',
        numeric: 562,
        alpha3: 'NER',
        alpha2: 'NE',
        name: 'Niger'
    },
    {
        alpha: 'ISO 3166-2:NG',
        numeric: 566,
        alpha3: 'NGA',
        alpha2: 'NG',
        name: 'Nigeria'
    },
    {
        alpha: 'ISO 3166-2:NU',
        numeric: 570,
        alpha3: 'NIU',
        alpha2: 'NU',
        name: 'Niue'
    },
    {
        alpha: 'ISO 3166-2:NF',
        numeric: 574,
        alpha3: 'NFK',
        alpha2: 'NF',
        name: 'Norfolk Island'
    },
    {
        alpha: 'ISO 3166-2:MP',
        numeric: 580,
        alpha3: 'MNP',
        alpha2: 'MP',
        name: 'Northern Mariana Islands'
    },
    {
        alpha: 'ISO 3166-2:NO',
        numeric: 578,
        alpha3: 'NOR',
        alpha2: 'NO',
        name: 'Norway'
    },
    {
        alpha: 'ISO 3166-2:OM',
        numeric: 512,
        alpha3: 'OMN',
        alpha2: 'OM',
        name: 'Oman'
    },
    {
        alpha: 'ISO 3166-2:PK',
        numeric: 586,
        alpha3: 'PAK',
        alpha2: 'PK',
        name: 'Pakistan'
    },
    {
        alpha: 'ISO 3166-2:PW',
        numeric: 585,
        alpha3: 'PLW',
        alpha2: 'PW',
        name: 'Palau'
    },
    {
        alpha: 'ISO 3166-2:PS',
        numeric: 275,
        alpha3: 'PSE',
        alpha2: 'PS',
        name: 'Palestinian Territory, Occupied'
    },
    {
        alpha: 'ISO 3166-2:PA',
        numeric: 591,
        alpha3: 'PAN',
        alpha2: 'PA',
        name: 'Panama'
    },
    {
        alpha: 'ISO 3166-2:PG',
        numeric: 598,
        alpha3: 'PNG',
        alpha2: 'PG',
        name: 'Papua New Guinea'
    },
    {
        alpha: 'ISO 3166-2:PY',
        numeric: 600,
        alpha3: 'PRY',
        alpha2: 'PY',
        name: 'Paraguay'
    },
    {
        alpha: 'ISO 3166-2:PE',
        numeric: 604,
        alpha3: 'PER',
        alpha2: 'PE',
        name: 'Peru'
    },
    {
        alpha: 'ISO 3166-2:PH',
        numeric: 608,
        alpha3: 'PHL',
        alpha2: 'PH',
        name: 'Philippines'
    },
    {
        alpha: 'ISO 3166-2:PN',
        numeric: 612,
        alpha3: 'PCN',
        alpha2: 'PN',
        name: 'Pitcairn'
    },
    {
        alpha: 'ISO 3166-2:PL',
        numeric: 616,
        alpha3: 'POL',
        alpha2: 'PL',
        name: 'Poland'
    },
    {
        alpha: 'ISO 3166-2:PT',
        numeric: 620,
        alpha3: 'PRT',
        alpha2: 'PT',
        name: 'Portugal'
    },
    {
        alpha: 'ISO 3166-2:PR',
        numeric: 630,
        alpha3: 'PRI',
        alpha2: 'PR',
        name: 'Puerto Rico'
    },
    {
        alpha: 'ISO 3166-2:QA',
        numeric: 634,
        alpha3: 'QAT',
        alpha2: 'QA',
        name: 'Qatar'
    },
    {
        alpha: 'ISO 3166-2:RE',
        numeric: 638,
        alpha3: 'REU',
        alpha2: 'RE',
        name: 'Réunion'
    },
    {
        alpha: 'ISO 3166-2:RO',
        numeric: 642,
        alpha3: 'ROU',
        alpha2: 'RO',
        name: 'Romania'
    },
    {
        alpha: 'ISO 3166-2:RU',
        numeric: 643,
        alpha3: 'RUS',
        alpha2: 'RU',
        name: 'Russian Federation'
    },
    {
        alpha: 'ISO 3166-2:RW',
        numeric: 646,
        alpha3: 'RWA',
        alpha2: 'RW',
        name: 'Rwanda'
    },
    {
        alpha: 'ISO 3166-2:BL',
        numeric: 652,
        alpha3: 'BLM',
        alpha2: 'BL',
        name: 'Saint Barthélemy'
    },
    {
        alpha: 'ISO 3166-2:SH',
        numeric: 654,
        alpha3: 'SHN',
        alpha2: 'SH',
        name: 'Saint Helena, Ascension and Tristan da Cunha'
    },
    {
        alpha: 'ISO 3166-2:KN',
        numeric: 659,
        alpha3: 'KNA',
        alpha2: 'KN',
        name: 'Saint Kitts and Nevis'
    },
    {
        alpha: 'ISO 3166-2:LC',
        numeric: 662,
        alpha3: 'LCA',
        alpha2: 'LC',
        name: 'Saint Lucia'
    },
    {
        alpha: 'ISO 3166-2:MF',
        numeric: 663,
        alpha3: 'MAF',
        alpha2: 'MF',
        name: 'Saint Martin (French part)'
    },
    {
        alpha: 'ISO 3166-2:PM',
        numeric: 666,
        alpha3: 'SPM',
        alpha2: 'PM',
        name: 'Saint Pierre and Miquelon'
    },
    {
        alpha: 'ISO 3166-2:VC',
        numeric: 670,
        alpha3: 'VCT',
        alpha2: 'VC',
        name: 'Saint Vincent and the Grenadines'
    },
    {
        alpha: 'ISO 3166-2:WS',
        numeric: 882,
        alpha3: 'WSM',
        alpha2: 'WS',
        name: 'Samoa'
    },
    {
        alpha: 'ISO 3166-2:SM',
        numeric: 674,
        alpha3: 'SMR',
        alpha2: 'SM',
        name: 'San Marino'
    },
    {
        alpha: 'ISO 3166-2:ST',
        numeric: 678,
        alpha3: 'STP',
        alpha2: 'ST',
        name: 'Sao Tome and Principe'
    },
    {
        alpha: 'ISO 3166-2:SA',
        numeric: 682,
        alpha3: 'SAU',
        alpha2: 'SA',
        name: 'Saudi Arabia'
    },
    {
        alpha: 'ISO 3166-2:SN',
        numeric: 686,
        alpha3: 'SEN',
        alpha2: 'SN',
        name: 'Senegal'
    },
    {
        alpha: 'ISO 3166-2:RS',
        numeric: 688,
        alpha3: 'SRB',
        alpha2: 'RS',
        name: 'Serbia'
    },
    {
        alpha: 'ISO 3166-2:SC',
        numeric: 690,
        alpha3: 'SYC',
        alpha2: 'SC',
        name: 'Seychelles'
    },
    {
        alpha: 'ISO 3166-2:SL',
        numeric: 694,
        alpha3: 'SLE',
        alpha2: 'SL',
        name: 'Sierra Leone'
    },
    {
        alpha: 'ISO 3166-2:SG',
        numeric: 702,
        alpha3: 'SGP',
        alpha2: 'SG',
        name: 'Singapore'
    },
    {
        alpha: 'ISO 3166-2:SX',
        numeric: 534,
        alpha3: 'SXM',
        alpha2: 'SX',
        name: 'Sint Maarten (Dutch part)'
    },
    {
        alpha: 'ISO 3166-2:SK',
        numeric: 703,
        alpha3: 'SVK',
        alpha2: 'SK',
        name: 'Slovakia'
    },
    {
        alpha: 'ISO 3166-2:SI',
        numeric: 705,
        alpha3: 'SVN',
        alpha2: 'SI',
        name: 'Slovenia'
    },
    {
        alpha: 'ISO 3166-2:SB',
        numeric: 90,
        alpha3: 'SLB',
        alpha2: 'SB',
        name: 'Solomon Islands'
    },
    {
        alpha: 'ISO 3166-2:SO',
        numeric: 706,
        alpha3: 'SOM',
        alpha2: 'SO',
        name: 'Somalia'
    },
    {
        alpha: 'ISO 3166-2:ZA',
        numeric: 710,
        alpha3: 'ZAF',
        alpha2: 'ZA',
        name: 'South Africa'
    },
    {
        alpha: 'ISO 3166-2:GS',
        numeric: 239,
        alpha3: 'SGS',
        alpha2: 'GS',
        name: 'South Georgia and the South Sandwich Islands'
    },
    {
        alpha: 'ISO 3166-2:SS',
        numeric: 728,
        alpha3: 'SSD',
        alpha2: 'SS',
        name: 'South Sudan'
    },
    {
        alpha: 'ISO 3166-2:ES',
        numeric: 724,
        alpha3: 'ESP',
        alpha2: 'ES',
        name: 'Spain'
    },
    {
        alpha: 'ISO 3166-2:LK',
        numeric: 144,
        alpha3: 'LKA',
        alpha2: 'LK',
        name: 'Sri Lanka'
    },
    {
        alpha: 'ISO 3166-2:SD',
        numeric: 729,
        alpha3: 'SDN',
        alpha2: 'SD',
        name: 'Sudan'
    },
    {
        alpha: 'ISO 3166-2:SR',
        numeric: 740,
        alpha3: 'SUR',
        alpha2: 'SR',
        name: 'Suriname'
    },
    {
        alpha: 'ISO 3166-2:SJ',
        numeric: 744,
        alpha3: 'SJM',
        alpha2: 'SJ',
        name: 'Svalbard and Jan Mayen'
    },
    {
        alpha: 'ISO 3166-2:SZ',
        numeric: 748,
        alpha3: 'SWZ',
        alpha2: 'SZ',
        name: 'Swaziland'
    },
    {
        alpha: 'ISO 3166-2:SE',
        numeric: 752,
        alpha3: 'SWE',
        alpha2: 'SE',
        name: 'Sweden'
    },
    {
        alpha: 'ISO 3166-2:CH',
        numeric: 756,
        alpha3: 'CHE',
        alpha2: 'CH',
        name: 'Switzerland'
    },
    {
        alpha: 'ISO 3166-2:SY',
        numeric: 760,
        alpha3: 'SYR',
        alpha2: 'SY',
        name: 'Syrian Arab Republic'
    },
    {
        alpha: 'ISO 3166-2:TW',
        numeric: 158,
        alpha3: 'TWN',
        alpha2: 'TW',
        name: 'Taiwan, Province of China'
    },
    {
        alpha: 'ISO 3166-2:TJ',
        numeric: 762,
        alpha3: 'TJK',
        alpha2: 'TJ',
        name: 'Tajikistan'
    },
    {
        alpha: 'ISO 3166-2:TZ',
        numeric: 834,
        alpha3: 'TZA',
        alpha2: 'TZ',
        name: 'Tanzania, United Republic of'
    },
    {
        alpha: 'ISO 3166-2:TH',
        numeric: 764,
        alpha3: 'THA',
        alpha2: 'TH',
        name: 'Thailand'
    },
    {
        alpha: 'ISO 3166-2:TL',
        numeric: 626,
        alpha3: 'TLS',
        alpha2: 'TL',
        name: 'Timor-Leste'
    },
    {
        alpha: 'ISO 3166-2:TG',
        numeric: 768,
        alpha3: 'TGO',
        alpha2: 'TG',
        name: 'Togo'
    },
    {
        alpha: 'ISO 3166-2:TK',
        numeric: 772,
        alpha3: 'TKL',
        alpha2: 'TK',
        name: 'Tokelau'
    },
    {
        alpha: 'ISO 3166-2:TO',
        numeric: 776,
        alpha3: 'TON',
        alpha2: 'TO',
        name: 'Tonga'
    },
    {
        alpha: 'ISO 3166-2:TT',
        numeric: 780,
        alpha3: 'TTO',
        alpha2: 'TT',
        name: 'Trinidad and Tobago'
    },
    {
        alpha: 'ISO 3166-2:TN',
        numeric: 788,
        alpha3: 'TUN',
        alpha2: 'TN',
        name: 'Tunisia'
    },
    {
        alpha: 'ISO 3166-2:TR',
        numeric: 792,
        alpha3: 'TUR',
        alpha2: 'TR',
        name: 'Turkey'
    },
    {
        alpha: 'ISO 3166-2:TM',
        numeric: 795,
        alpha3: 'TKM',
        alpha2: 'TM',
        name: 'Turkmenistan'
    },
    {
        alpha: 'ISO 3166-2:TC',
        numeric: 796,
        alpha3: 'TCA',
        alpha2: 'TC',
        name: 'Turks and Caicos Islands'
    },
    {
        alpha: 'ISO 3166-2:TV',
        numeric: 798,
        alpha3: 'TUV',
        alpha2: 'TV',
        name: 'Tuvalu'
    },
    {
        alpha: 'ISO 3166-2:UG',
        numeric: 800,
        alpha3: 'UGA',
        alpha2: 'UG',
        name: 'Uganda'
    },
    {
        alpha: 'ISO 3166-2:UA',
        numeric: 804,
        alpha3: 'UKR',
        alpha2: 'UA',
        name: 'Ukraine'
    },
    {
        alpha: 'ISO 3166-2:AE',
        numeric: 784,
        alpha3: 'ARE',
        alpha2: 'AE',
        name: 'United Arab Emirates'
    },
    {
        alpha: 'ISO 3166-2:GB',
        numeric: 826,
        alpha3: 'GBR',
        alpha2: 'GB',
        name: 'United Kingdom'
    },
    {
        alpha: 'ISO 3166-2:US',
        numeric: 840,
        alpha3: 'USA',
        alpha2: 'US',
        name: 'United States'
    },
    {
        alpha: 'ISO 3166-2:UM',
        numeric: 581,
        alpha3: 'UMI',
        alpha2: 'UM',
        name: 'United States Minor Outlying Islands'
    },
    {
        alpha: 'ISO 3166-2:UY',
        numeric: 858,
        alpha3: 'URY',
        alpha2: 'UY',
        name: 'Uruguay'
    },
    {
        alpha: 'ISO 3166-2:UZ',
        numeric: 860,
        alpha3: 'UZB',
        alpha2: 'UZ',
        name: 'Uzbekistan'
    },
    {
        alpha: 'ISO 3166-2:VU',
        numeric: 548,
        alpha3: 'VUT',
        alpha2: 'VU',
        name: 'Vanuatu'
    },
    {
        alpha: 'ISO 3166-2:VE',
        numeric: 862,
        alpha3: 'VEN',
        alpha2: 'VE',
        name: 'Venezuela, Bolivarian Republic of'
    },
    {
        alpha: 'ISO 3166-2:VN',
        numeric: 704,
        alpha3: 'VNM',
        alpha2: 'VN',
        name: 'Viet Nam'
    },
    {
        alpha: 'ISO 3166-2:VG',
        numeric: 92,
        alpha3: 'VGB',
        alpha2: 'VG',
        name: 'Virgin Islands, British'
    },
    {
        alpha: 'ISO 3166-2:VI',
        numeric: 850,
        alpha3: 'VIR',
        alpha2: 'VI',
        name: 'Virgin Islands, U.S.'
    },
    {
        alpha: 'ISO 3166-2:WF',
        numeric: 876,
        alpha3: 'WLF',
        alpha2: 'WF',
        name: 'Wallis and Futuna'
    },
    {
        alpha: 'ISO 3166-2:EH',
        numeric: 732,
        alpha3: 'ESH',
        alpha2: 'EH',
        name: 'Western Sahara'
    },
    {
        alpha: 'ISO 3166-2:YE',
        numeric: 887,
        alpha3: 'YEM',
        alpha2: 'YE',
        name: 'Yemen'
    },
    {
        alpha: 'ISO 3166-2:ZM',
        numeric: 894,
        alpha3: 'ZMB',
        alpha2: 'ZM',
        name: 'Zambia'
    },
    {
        alpha: 'ISO 3166-2:ZW',
        numeric: 716,
        alpha3: 'ZWE',
        alpha2: 'ZW',
        name: 'Zimbabwe'
    }
]
