import React from 'react';
import './browserNotSupported.css'
function BrowserNotSupported() {

  
  return (
    <div className='browser-error-container'>
      <div className='line-wrapper'>
        <div className='line1'>Internet Explorer not supported.</div>
        <div className='line2'>This page cannot be displayed in Internet Explorer due to browser limitations.</div>
        <div className='line3'>Kindly use a modern browser such as Edge, Chrome, FireFox or Safari.</div>
      </div>


    </div>
  );
}

export default BrowserNotSupported;