<template>
    <w-text-field
        v-model="val"
        @keydown="auto_correct"
        :rules="rules"
        :placeholder="placeholder"
        :label="with_label ? placeholder : undefined"
        :disabled="disabled"
        hide-details='auto'
        data-test-id="zip-code-input"/>
    </template>
    <script>
  import {specialKeys} from 'helpers';
  
  export default {
      props: {
          validation: {
              type: Boolean,
              default: false,
            },
            value: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
            },
            with_label: {
                type: Boolean,
                default: false
            }
        },
        created() {
            this.$data.val = this.$props.value;
        },
        data() {
            return {
                val: '',
                placeholder: this.$lang.t('app.zip-code', 'Zip code'),
            };
        },
        methods: {
            validate(v) {
                if (v) {
                const postal_regex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
                if (!postal_regex.test(v)) {
                    return this.$lang.t('app.invalid-zip-code', 'Invalid zip code');
                }
            }

            return true;
        },
        auto_correct(e) {
            const always_allowed = specialKeys;
            if (
                !e.ctrlKey
                && !e.altKey
                && !e.shiftKey
                && !e.metaKey
                && !always_allowed.includes(e.key)
            ) {
                e.preventDefault();
                const selectedText = document.activeElement.value.substring(
                    document.activeElement.selectionStart,
                    document.activeElement.selectionEnd
                );
                const value = selectedText !== '' ? e.key : (this.$data.val || '') + e.key;
                if (!value.length) return this.$emit('input', '');

                const index = value.length - 1;
                if (index >= 9) {
                    return null;
                }
                const last_char = value[index];
                const number_regex = /\d+/i;
                const second_part_regex = /(?:[-\s]*([0-9]{4}))/i;
                if (index <= 4 && !number_regex.test(last_char)) {
                    return null;
                }
                if (index > 4 && second_part_regex.test(last_char)) {
                    return null;
                }
                this.$data.val = value;
                this.$emit('input', this.$data.val);
            }

            return true;
        },
    },
    watch: {
        val(v) {
            this.$emit('input', v);
        }
    },
    computed: {
        rules() {
            let rules = [this.validate]
            if (Array.isArray(this.$attrs.rules)) {
                rules = rules.concat(this.$attrs.rules);
            }
            return rules;
        }
    }
};
</script>
