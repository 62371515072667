import React, { Component } from 'react'
import ConfirmModal from 'confirm-modal'
import TextField from 'pdc-text-field'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import api from '../../util/api_v5'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'

const styles = theme => ({
    companyCallerIdStyle: { color: theme.palette.primary['0'] },
    modalTitle: {
        display: 'flex',
        columnGap: 5
    }
})

class EditCallerIdModal extends Component {
    state = {
        callerIdText: this.props.currentCallerId || this.props.defaultCompanyCallerId,
        saveError: null
    }

    onBlur = () => {
        // if its empty autofill back to company
        if (!this.state.callerIdText) {
            this.setState({ callerIdText: this.props.defaultCompanyCallerId })
        }
    }

    onSave = async () => {
        console.log('hit api')
        if (!this.state.callerIdText) return this.setState({ callerIdText: this.props.defaultCompanyCallerId }, this.updateCallerId)
        this.updateCallerId()
    }

    updateCallerId = async () => {
        const newCallerId = this.state.callerIdText || this.props.defaultCompanyCallerId || ''
        const res = await api.updateCallerName(this.props.voipDidId, newCallerId)
        if (!res.message || res.message !== 'success') return this.setState({ saveError: res.error })
        this.props.onSuccess(this.props.voipDidId, newCallerId)
        this.props.onClose()
    }

    renderContent = () => {
        const { classes } = this.props
        const onChange = callerIdText => {
            callerIdText = callerIdText.replace(/[^a-zA-Z0-9\s,]/g, '')
            if (callerIdText.length > 15) callerIdText = callerIdText.substring(0, 15)
            this.setState({ callerIdText })
        }
        return (
            <div>
                <p>
                    By default all phone numbers have a caller ID name corresponding to your company&apos;s caller ID name: <a className={classes.companyCallerIdStyle} href={'/company/general-settings'}>{this.props.defaultCompanyCallerId}</a>.
                    <br/>
                    <br/>
                    If you wish to show a different caller ID name for this number, simply enter it below and save.
                </p>
                <TextField
                    label={'Custom Caller ID Name'}
                    content={this.state.callerIdText}
                    onInputChange={onChange}
                    onBlur={this.onBlur}
                    error={this.state.saveError}
                    // className={{ wrapper: classes.textField }}
                />
            </div>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <ConfirmModal
                title={<div className={classes.modalTitle}>Custom Caller ID Name <RemoteInfoTipMui arrow remoteConfigIDs={['configure_caller_id_disclamer']} placement={RemoteInfoTipMuiPlacement.TOP}/></div>}
                isShown={this.props.isShown}
                content={this.renderContent()}
                noButtonText='Cancel'
                yesButtonText='Save'
                onReject={this.props.onClose}
                onConfirm={this.onSave}
                size='size440'
            />

        )
    }
}

EditCallerIdModal.propTypes = {
    // Material UI classes
    classes: PropTypes.any,
    voipDidId: PropTypes.number,
    defaultCompanyCallerId: PropTypes.string,
    currentCallerId: PropTypes.string,
    isShown: PropTypes.bool,
    // Called on close
    onClose: PropTypes.func,
    // Called after save
    onSuccess: PropTypes.func
}

export default withStyles(styles)(EditCallerIdModal)
