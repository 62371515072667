<template>
	<div v-if="contact" class="live-answer-contact">
		<Collapsable :force_open="!contact.id">
			<template v-slot:title>
				<w-btn icon text class="text white--text mr-1" width="24" height="24" :ripple="false">{{ index }}</w-btn>
				<span class="w-body-2 my-auto text--text">{{ contact_title(contact) }}</span>
			</template>
			<div class="d-md-flex">
				<w-text-field
					v-model="contact.first_name"
					@change="contact.first_name = clean_text_value(contact.first_name)"
					@keydown="on_name_keydown"
					@paste="contact.first_name = clean_pasted_data($event)"
					:disabled="disabled"
					:rules="[
						$validation.required(),
						$validation.minlength(2)
					]"
					:placeholder="$lang.t('app.first-name', 'First name')"
					required
					hide-details="auto"
					:class="$vuetify.breakpoint.mdAndUp ? 'lr-contact-first-field' : 'mb-3'"
					data-test-id="lr-contact-first-name-input"
				/>
				<w-text-field
					:disabled="disabled"
					v-model="contact.last_name"
					@change="contact.last_name = clean_text_value(contact.last_name)"
					@keydown="on_name_keydown"
					@paste="contact.last_name = clean_pasted_data($event)"
					:placeholder="$lang.t('app.last-name', 'Last name')"
					:rules="[
						$validation.required(),
						$validation.minlength(2),
					]"
					required
					class="ml-md-5"
					:class="$vuetify.breakpoint.mdAndUp ? 'lr-contact-second-field' : 'mb-3'"
					hide-details="auto"
					data-test-id="lr-contact-last-name-input"
				/>
				<w-text-field
					v-model="contact.title"
					:disabled="disabled"
					@keydown="on_name_keydown"
					@change="contact.title = clean_text_value(contact.title)"
					@paste="contact.title = clean_pasted_data($event)"
					:placeholder="$lang.t('app.title', 'Title')"
					class="ml-md-5"
					:class="$vuetify.breakpoint.mdAndUp ? 'lr-contact-third-field' : 'mb-3'"
					hide-details="auto"
					data-test-id="lr-contact-prefix-input"
				/>
			</div>
			<TransitionGroup name="list">
				<Destination
					v-for="(d, i) of contact.destinations"
					:key="d._unique_key"
					:item="d"
					:deletable="contact.destinations.length > 1"
					:disabled="disabled"
					:allow_email="d.type === 'email' || !contact.destinations.filter((x) => d._unique_key !== x._unique_key && (x.type === 'email' || x.type === 'voicemail')).length"
					:allow_voicemail="d.type === 'voicemail' || !contact.destinations.filter((x) => d._unique_key !== x._unique_key && (x.type === 'voicemail' || x.type === 'email')).length"
					@remove="removeDestination(i)"
					@changed="destinationChanged($event, i)"
					:data-test-id="`lr-contact-${i}-destination`"
				/>
			</TransitionGroup>
			<div class="d-flex justify-space-between mt-6">
				<w-btn
					v-if="!disabled"
					@click="$emit('addDestination')"
					color="primary"
					class="font-weight-bold pl-0 add-new-destination-btn"
					text
					:disabled="!contact.destinations.every((x) => x.type && x.destination)"
					data-test-id="lr-contact-add-destination-btn"
				>
					<v-icon class="mr-1">mdi-plus-circle</v-icon> {{ $lang.t('app.add-another-destination', 'Add another destination') }}
				</w-btn>
				<span v-if="!disabled && deletable" @click="on_remove" class="my-auto cursor-pointer w-btn-medium error--text w-letter-spacing-medium">
					{{ $lang.t('app.delete-contact', 'Delete contact') }}
				</span>
			</div>
		</Collapsable>
		<DefaultModal v-if="show_modal" :value="true" max-width="500" @close="show_modal=false" :key="show_modal">
			<template v-slot:title>{{show_modal}}</template>
			{{$lang.t('lr.contacts-sorry-we-dont-accept-digits', 'Sorry, we do not accept digits and numbers as part of a name. Please enter the numbers as words like “William the Third”.')}}
			<template v-slot:buttons>
				<w-btn @click="show_modal = null" color="secondary">{{$lang.t('app.close', 'Close')}}</w-btn>
			</template>
		</DefaultModal>
	</div>
</template>

<script>
  import {preventDoubleSpace, preventNumber} from 'helpers';
  import { randomNumber } from 'random-generator';
  import Destination from './Destination.vue';
  import Collapsable from '../../../console/src/components/elements/Collapsable.vue';
  import ExpandButton from '../../../console/src/components/elements/ExpandButton.vue';
  import SectionTitle from '../../../console/src/components/elements/SectionTitle.vue';
  import AddToListButton from '../../../console/src/components/elements/AddToListButton.vue';
  import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
  import EventBus from 'event-bus/EventBus';

  export default {
    props: ['_contact', 'index', 'disabled', 'deletable'],
    components: {
		Collapsable,
		Destination,
		SectionTitle,
		ExpandButton,
		DefaultModal,
		AddToListButton,
    },
    data() {
			return {
				contact: null,
				open: !!this.$props._contact.id,
				show_modal: null,
			};
    },
    created() {
      this.$data.contact = JSON.parse(JSON.stringify(this.$props._contact));
      if (this.$data.contact.destinations && this.$data.contact.destinations.length) {
          this.$data.contact.destinations = this.$data.contact.destinations.map((d) => {
              if (!d._unique_key) d._unique_key = randomNumber();
              return d;
          });
      }
    },
	methods: {
		on_remove() {
			this.$emit('remove');
			this.$global_emitter.$emit('form_input_changed');
		},
		contact_title(contact) {
			if (contact.title || contact.first_name || contact.last_name) {
				return [contact.title, contact.first_name, contact.last_name].filter((x) => x).join(' ');
			}

			return this.$lang.t('app.contact', 'Contact');
		},
		removeDestination(index) {
			if (index > -1) {
				this.$data.contact.destinations.splice(index, 1);
			}
			this.$emit('changed', this.$data.contact);
		},
		destinationChanged(data, index) {
			if (index > -1) {
				this.$data.contact.destinations[index] = data;
				this.$emit('changed', this.$data.contact);
				if (this.$data.contact.destinations.length > 1) {
					this.reorder_destinations();
				}
			}
		},
		reorder_destinations() {
			const ext_phones = this.$data.contact.destinations.filter((x) => ['extension', 'phone_number'].includes(x.type));
			const voicemails = this.$data.contact.destinations.filter((x) => x.type === 'voicemail');
			const emails = this.$data.contact.destinations.filter((x) => x.type === 'email');
			const no_type = this.$data.contact.destinations.filter((x) => !x.type);

			return this.$data.contact.destinations = [...ext_phones, ...voicemails, ...emails, ...no_type];
		},
		on_name_keydown(e) {
			preventDoubleSpace(e);
			if (preventNumber(e)) {
				this.$data.show_modal = `${e.target.value}${e.key}`;
			}
		},
		clean_pasted_data(e) {
			e.preventDefault();
			EventBus.$emit('form_input_changed');
			if (e && e.clipboardData && e.clipboardData.getData('text')) {
				return this.clean_text_value(e.clipboardData.getData('text'));
			}

			return '';
		},
		clean_text_value(text) {
			return text.trim().replace(/[0-9]/g, '').replace(/  +/g, ' ');
		},
    },
    watch: {
		contact: {
			deep: true,
			handler(val) {
				this.$emit('changed', val);
			}
		}
    }
  };
</script>
