import React, { Component } from 'react'

import Draggable from 'react-draggable'

import { AudioPlayerControlIcon } from 'pdc-svg-icons'
import dloadIcon from './images/icon_in_dload.png'
import { AudioPlayerControlSliderIcon } from 'pdc-svg-icons'
import { DefaultArrowTooltip } from 'tooltips'

import { withStyles } from '@material-ui/styles'

import './VoicemailAudioController.css'
import Spinner from 'spinner'
import styles from './styles'
import PropTypes from 'prop-types'

function formatSecondsToMinutes (seconds) {
    const minutes = Math.floor(seconds / 60)
    seconds = (seconds - (minutes * 60)).toFixed(0)
    if (seconds < 10) seconds = '0' + seconds
    return (`${minutes}:${seconds}`)
}

class AudioPlayer extends Component {
    constructor (props) {
        super(props)

        this.seekRef = React.createRef()
        this.progressRef = React.createRef()
        this.audio = new Audio(props.url)
        this.audio.preload = props.preload || 'auto'
        this.state = {
            progress: 0,
            selected: false,
            play: false,
            duration: props.duration || 0,
            volume: { value: 50 },
            error: false,
            playerControlPosition: null,
            dragState: 'inactive',
            controlButtonHovered: false
        }

        this.audio.onerror = e => {
            if (!this._mounted) return
            if (this.props.onError) this.props.onError()
            this.setState({ error: true })
        }

        this.controlSeek = event => {
            if (Array.from(event.target.classList).includes('player-control')) return
            const seekbarRect = this.seekRef.current.getBoundingClientRect()
            const left = event.pageX - seekbarRect.left
            const percentage = left / seekbarRect.width
            const seekTime = this.getDuration() * percentage
            this.audio.currentTime = seekTime
            this.setState({ progress: percentage * 100, playerControlPosition: { x: left, y: 0 } })
        }

        this.toggleAudio = () => {
            if (this.state.play === true) {
                this.setState({ play: false })
                this.audio.pause()
            } else {
                this.setState({ loading: true })
                this.audio.play().then(() => {
                    this.setState({ play: true, loading: false }, () => {
                        if (this.props.onPlay) this.props.onPlay()
                    })
                }).catch(e => {
                    if (this.props.onError) this.props.onError()
                    this.setState({ error: true, loading: false })
                    window.alert('unable to play audio')
                })
            }
        }

        this.initializeAudioState = event => {
            this.setState({
                error: false,
                duration: this.getDuration().toFixed(0)
            })
            if (this.props.onReadyToPlay) {
                this.props.onReadyToPlay()
            }
        }

        this.updateCurrentTime = event => {
            this.setState({ progress: this.audio.currentTime / this.getDuration() * 100 })
            if (this.state.dragState === 'inactive') {
                const playerControlPosition = { x: this.progressRef.current.offsetWidth, y: 0 }
                this.setState({ playerControlPosition })
            }
            if (this.state.progress >= 100) {
                this.setState({ play: false })
            }
        }

        this.adjustVolume = event => {
            this.setState({ volume: { value: event.target.value } })
            this.audio.volume = this.state.volume.value / 100
        }
    }

    getDuration = () => (this.props.duration || this.props.duration === 0) ? this.props.duration : this.audio.duration

    componentDidMount = () => {
        this._mounted = true
        this.resetEventListeners()
    }

    resetEventListeners = () => {
        this.audio.removeEventListener('loadedmetadata', this.initializeAudioState)
        this.audio.removeEventListener('timeupdate', this.updateCurrentTime)
        this.audio.addEventListener('loadedmetadata', this.initializeAudioState)
        this.audio.addEventListener('timeupdate', this.updateCurrentTime)
    }

    componentWillUnmount = () => {
        this.audio.removeEventListener('loadedmetadata', this.initializeAudioState)
        this.audio.removeEventListener('timeupdate', this.updateCurrentTime)
        this.audio.pause()
        this._mounted = false
    }

    // Pause player when disable flag is triggered
    componentDidUpdate (prevProps) {
        if (!prevProps.duration && this.props.duration && this.props.onReadyToPlay) this.props.onReadyToPlay()
        if (prevProps.url !== this.props.url) {
            this.audio.pause()
        }
        if (this.props.disabled && this.state.play) {
            this.setState({ play: false })
            this.audio.pause()
        }
        if ((!prevProps.url && this.props.url) || prevProps.url !== this.props.url) {
            this.audio = new Audio(this.props.url)
            this.resetEventListeners()
        }
        if (this.props.playFromSecond && prevProps.playFromSecond !== this.props.playFromSecond) {
            let playFromSecond = this.props.playFromSecond
            const duration = this.getDuration()
            if (playFromSecond > duration) playFromSecond = duration
            const progress = 100 * playFromSecond / duration
            this.audio.currentTime = playFromSecond
            this.setState({ progress })
            if (!this.state.play) this.toggleAudio()
        }
    }

    onDragStart = () => this.setState({ dragState: 'active' })

    onDragStop = (e, info) => {
        this.setState({ dragState: 'inactive' })
        const parentWidth = this.seekRef.current.offsetWidth
        const positionX = info.x
        const progressWidth = 100 * positionX / parentWidth
        this.setState({ progress: progressWidth, dragTime: null })

        const seekTime = this.getDuration() * progressWidth / 100
        this.audio.currentTime = seekTime
    }

    controlDragged = (e, info) => {
        this.setState({ playerControlPosition: null })

        const parentWidth = this.seekRef.current.offsetWidth
        const positionX = info.x
        const progressWidth = 100 * positionX / parentWidth

        if (!this.state.play) {
            this.setState({ progress: progressWidth })
        }

        const dragTime = formatSecondsToMinutes(this.getDuration() * progressWidth / 100)
        this.setState({ dragTime, tempProgress: progressWidth })
    }

    setControlButtonHovered = controlButtonHovered => this.setState({ controlButtonHovered })

    render = () => {
        const { classes } = this.props
        const styles = {
            seek: {
                width: `${this.state.progress}%`
            }
        }
        const currentTime = formatSecondsToMinutes(this.audio.currentTime <= this.getDuration() ? this.audio.currentTime : this.getDuration())
        const totalTime = formatSecondsToMinutes(this.state.duration)

        return (
            <div className={classes.audioControlContainer}>
                {(this.state.loading)
                    ? <Spinner/>
                    : <div
                        className = {classes.controlButtonWrapper}
                        onMouseEnter = {() => this.setControlButtonHovered(true)}
                        onMouseLeave = {() => this.setControlButtonHovered(false)}
                    >
                        <AudioPlayerControlIcon
                            data-test-id= 'audio-player-play-btn'
                            className = {classes.controlButtonImg}
                            type = {!this.state.play ? 'play' : this.state.controlButtonHovered ? 'pauseHover' : 'pause'}
                            onClick = {this.toggleAudio}
                        />
                    </div>
                }
                <div className={classes.progressAndTimingWrapper}>
                    <div
                        className = {`${classes.audioControlSeekbar} ${this.state.error ? classes.seekBarErrorBorder : classes.seekBarBorder}`}
                        onClick = {this.controlSeek}
                        ref = {this.seekRef}
                    >
                        <span className='progress-bar' style={styles.seek} ref={this.progressRef}>
                            <Draggable
                                axis = 'x'
                                grid = {[1, 0]}
                                bounds = {{ left: 0, right: this.seekRef.current ? this.seekRef.current.offsetWidth : 0 }}
                                positionOffset = {{ x: -17.5, y: 0 }}
                                onStart = {this.onDragStart}
                                onStop = {this.onDragStop}
                                onDrag = {this.controlDragged}
                                position = {this.state.playerControlPosition}
                            >
                                <DefaultArrowTooltip
                                    title = {this.state.dragTime || currentTime}
                                    placement = 'top'
                                >
                                    <div className={`player-control ${this.state.dragState === 'active' ? 'force-show' : ''}`}><AudioPlayerControlSliderIcon/></div>
                                </DefaultArrowTooltip>
                            </Draggable>
                        </span>
                        {this.state.dragState === 'active' && this.state.play
                            ? <span style={{ width: `${this.state.tempProgress}%` }} className='temp-progress-bar'></span>
                            : null
                        }
                    </div>
                    <div className={classes.timeInfo}>
                        <span>{currentTime}</span>
                        <span>{totalTime}</span>
                    </div>
                </div>

                {this.props.downloadable
                    ? <a href={this.props.url} download='newname' target='_blank' className={classes.downloadStyle} rel='noreferrer'>
                        <img src={dloadIcon} alt='Download' className={classes.voicemailDownload} onClick={this.download}/>
                    </a>
                    : ''
                }
            </div>
        )
    }
}

AudioPlayer.propTypes = {
    /**
     * Material ui classes
     */
    classes: PropTypes.object,
    url: PropTypes.string,
    preload: PropTypes.string,
    duration: PropTypes.number,
    onError: PropTypes.func,
    onPlay: PropTypes.func,
    onReadyToPlay: PropTypes.func,
    disabled: PropTypes.bool,
    playFromSecond: PropTypes.number,
    downloadable: PropTypes.bool
}

export default withStyles(styles)(AudioPlayer)
