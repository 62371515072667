import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import PropTypes from 'prop-types'
import LoaderFull from 'loader-full'
import TextField from 'pdc-text-field'
import Button from 'button'
import { theme } from 'get-theme'
import RemoteConfigValue from 'remote-config-value'
import api from '../../util/api_v5'
import Prompt from 'pdc-prompt'
import { getValue } from 'remote-config-value'

class Notifications extends Component {
    state = {
        loading: true,
        storredNotifications: null,
        administrativeContact: null,
        billingContact: null,
        showValidationErrors: false,
        showSaveErrorPrompt: false
    }

    componentDidMount = async () => {
        // We need to keep the whole contact and billing_contact objects because we need their parameters in the upsert request
        const accountInfo = await api.getAccountInfo()
        const storredAdministrativeContact = accountInfo.contact
        const storredBillingContact = accountInfo.billing_contact
        if (storredAdministrativeContact.alternate_email === storredAdministrativeContact.primary_email) storredAdministrativeContact.alternate_email = ''
        if (storredBillingContact.alternate_email === storredBillingContact.primary_email) storredBillingContact.alternate_email = ''
        this.setState({
            loading: false,
            storredAdministrativeContact,
            storredBillingContact,
            administrativeContact: Object.assign({}, storredAdministrativeContact),
            billingContact: Object.assign({}, storredBillingContact)
        })
    }

    validate = () => {
        const { billingContact, administrativeContact } = this.state
        const billingAlternateEmail = billingContact.alternate_email
        const billingPrimaryEmail = billingContact.primary_email
        const administrativeAlternateEmail = administrativeContact.alternate_email
        const administrativePrimaryEmail = administrativeContact.primary_email
        if ((billingAlternateEmail && !this.isValidEmail(billingAlternateEmail)) || (administrativeAlternateEmail && !this.isValidEmail(administrativeAlternateEmail))) return false
        if (billingAlternateEmail === billingPrimaryEmail || administrativeAlternateEmail === administrativePrimaryEmail) return false
        return true
    }

    onSaveClick = () => {
        const isValid = this.validate()
        if (!isValid) return this.setState({ showValidationErrors: true })
        this.setState({ showValidationErrors: false })
        this.save()
    }

    save = async () => {
        const contact = { ...this.state.administrativeContact }
        const billing_contact = { ...this.state.billingContact }

        // Make sure the required address fields and not empty
        const requiredAddressFields = ['line_1', 'city', 'province', 'postal_code', 'country']
        requiredAddressFields.forEach(requiredField => {
            if (!contact.address[requiredField]) contact.address[requiredField] = ' '
            if (!billing_contact.address[requiredField]) billing_contact.address[requiredField] = ' '
        })

        const requestData = { contact, billing_contact }
        this.setState({ loading: true })
        const response = await api.updateAccountInfo(requestData)
        if (response.error) return setTimeout(() => this.setState({ loading: false, showSaveErrorPrompt: true }), 5)
        this.setState({
            loading: false,
            storredAdministrativeContact: { ...contact },
            storredBillingContact: { ...billing_contact }
        })
    }

    getChanges = () => {
        const changes = []
        const storredAdministrativeContact = this.state.storredAdministrativeContact
        const editedAdministrativeContact = this.state.administrativeContact
        if (storredAdministrativeContact.alternate_email !== editedAdministrativeContact.alternate_email) changes.push('administrativeEmail')

        const storredBillingContact = this.state.storredBillingContact
        const editedBillingContact = this.state.billingContact
        // This check here is because we want to show blank field for billing email in case it is the same with the administrative one.
        if (storredBillingContact.alternate_email !== storredAdministrativeContact.alternate_email || editedBillingContact.alternate_email) {
            if (storredBillingContact.alternate_email !== editedBillingContact.alternate_email) changes.push('billingEmail')
        }
        return changes
    }

    renderFooter = () => {
        const { classes } = this.props
        const changes = this.getChanges()
        return (
            <div className={classes.footer}>
                <Button
                    onClick = {this.onSaveClick}
                    color = 'primary'
                    disabled = {!changes.length}
                    data-test-id = 'account-notifications-save-button'
                >SAVE</Button>
            </div>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} size='bigger'/>
            </div>
        )
    }

    isValidEmail = email => {
        // eslint-disable-next-line
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        return regex.test(email)
    }

    renderEmailSection = (name) => {
        const { classes } = this.props
        const contact = this.state[`${name}Contact`]
        const alternateEmail = contact.alternate_email
        const primaryEmail = contact.primary_email
        let error = ''
        if (this.state.showValidationErrors && alternateEmail) {
            if (!this.isValidEmail(alternateEmail)) error = getValue('configure_account_notifications_invalid_email_message')
            else if (alternateEmail === primaryEmail) error = getValue('configure_account_notifications_already_used_email_message')
        }
        const onChange = alternateEmail => {
            const contact = { ...this.state[`${name}Contact`] }
            contact.alternate_email = alternateEmail
            this.setState({ [`${name}Contact`]: contact })
        }
        return (
            <div className={classes.section} data-test-id={`${name}-email-wrapper`}>
                <div className={classes.header}><RemoteConfigValue valueId={`account_notifications_${name}_email_title`}/></div>
                <div className={classes.sectionContent}>
                    <div><RemoteConfigValue valueId={`account_notifications_${name}_email_description`}/></div>
                    <TextField
                        content = {alternateEmail}
                        onInputChange = {onChange}
                        className = {{ wrapper: classes.textField }}
                        error = {error}
                    />
                </div>
            </div>
        )
    }

    renderSaveErrorPrompt = () => {
        return (
            <Prompt
                isOpen = {this.state.showSaveErrorPrompt}
                color = 'important'
                content = {getValue('account_notifications_save_error_message')}
                position = 'bottom'
                onClose = {() => this.setState({ showSaveErrorPrompt: false })}
            />
        )
    }

    render = () => {
        const { classes, smallView } = this.props
        const isLoading = this.state.loading
        const smallViewClass = smallView ? 'small-view' : ''
        return (
            <div className={classes.notifications}>
                {isLoading ? this.renderLoader() : null}
                {!isLoading
                    ? <div className={`${classes.settingWrapper} ${smallViewClass}`}>
                        {this.renderEmailSection('administrative')}
                        {this.renderEmailSection('billing')}
                    </div>
                    : null
                }
                {!isLoading ? this.renderFooter() : null}
                {this.renderSaveErrorPrompt()}
            </div>
        )
    }
}

Notifications.propTypes = {
    // Material Ui's withStyles' classes
    classes: PropTypes.object,
    setBusy: PropTypes.func,
    smallView: PropTypes.bool
}

export default withStyles(styles)(Notifications)
