import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import ConfirmModal from 'confirm-modal'
import TextField from 'pdc-text-field'
import { XIcon } from 'pdc-svg-icons'
import Spinner from 'spinner'


const styles = theme => ({
	modalContainer: {
		minHeight: 270
	},
	modalHeader: {
		fontSize: 24,
		marginBottom: 10,
		'& span': {
			fontWeight: 'bold'
		}
	},
	modalContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	modalRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop:		15,
		'&.first': {
			'& > div:first-child': {
				marginRight: 30
			}
		},
		'&.switch': {
			flexDirection: 'column',
			'& .label': {
				fontSize: 14,
				fontWeight: 600
			}
		}
	},
	inlineInput: {
		'& .MuiFilledInput-root': {
			backgroundColor: 'white',
			'&:hover': {
				backgroundColor: '#F4F5F6',
			},
			'&.Mui-focused': {
				backgroundColor: '#E9F8E4',
			},
			
		},
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			top: 55,
		}
	},
	inlineInputFullWidth: {
		width: '100%',
		'& .MuiFilledInput-root': {
			width: '100%',
			backgroundColor: 'white',
			'&:hover': {
				backgroundColor: '#F4F5F6',
			},
			'&.Mui-focused': {
				backgroundColor: '#E9F8E4',
			},
			
		},
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			top: 55,
		}
	},
	infoIcon: {
		color:	'#A6AFB5',
		cursor:	'help',
		height: 13,
		width:	13,
		marginLeft: 4,
		marginBottom: 2
	},
	xIconContainer: {
		position: 		'absolute',
		top:			'20px',
		right:			'20px',
		cursor:			'pointer',
		background: 	'white',
		height:			'40px',
		width:			'40px',
		borderRadius:	'50%',
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'center',
		'& svg': {
			color: theme.palette.secondary[0],
		},
		'&:hover': {
			background: theme.palette.secondary['-400']
		}
	},
	tooltip: {
		backgroundColor: '#48B9D5',
		fontSize:	12
	},
	spinnerRow: {
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'center',
		height:			100,
		'& svg': {
			color: theme.palette.primary[0]
		}	
	}
})

const mapStateToProps = state => ({
	smallView:	state.smallView,
})

const mapDispatchToProps = dispatch => ({

})

class InviteUserModal extends Component {	
	
	constructor(props) {
		super(props)
		this.state = {
			tmpUser: {...this.props.user},
			pending: false
		}
	}

	updateUser = tmpUser => this.setState({tmpUser})
	
	updateFieldInUser = (field, value) => {
		let tmpUser	= this.state.tmpUser
		tmpUser[field]	= value
		tmpUser[field+'_error'] = false
		this.updateUser(tmpUser)
	}

	validateUser = () => {
		let isValid = true
		let user = this.state.tmpUser
		isValid = !this.validateFirstName(user) ? false : isValid
		isValid = !this.validateLastName(user) ? false : isValid
		isValid = !this.validateEmailForUser(user) ? false : isValid
	
		return isValid
	}

	validateFirstName = user => {
		if (user.first_name.length === 0){
			let error = 'Please enter a valid First Name.'
			this.updateFieldInUser('first_name_error', error)
			return false
		}
		return true
	}

	validateLastName = user => {
		if (user.last_name.length === 0){
			let error = 'Please enter a valid Last Name.'
			this.updateFieldInUser('last_name_error', error)
			return false
		}
		return true
	}

	validateEmailForUser = user => {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (!re.test(String(user.email).toLowerCase())) {
			let error = 'Please enter a valid email address.'
			this.updateFieldInUser('email_error', error)
			return false
		}
		return true	
	}

	saveUser = async () => {
		if (!this.validateUser()) {
			return
		}
		this.setState({pending: true})
		// cleanUser
		let user = this.state.tmpUser
		delete user.firstNameError
		delete user.lastNameError
		delete user.emailError
		user.first_name = user.first_name.trim()
		user.last_name = user.last_name.trim()
		user.email = user.email.trim()
		user.status = 'invite_pending'
		let response = await this.props.onInviteUser(user)
		if (response === 'email_taken') {
			this.updateFieldInUser('email_error', 'Email is already in use.')
		}
		this.setState({pending: false})
	}
	
	renderInput = (onChange, label, value, error, errorMessage) => {
		const { classes } = this.props

		return(
			<TextField
				classes={{root: label === 'Email' ? classes.inlineInputFullWidth : classes.inlineInput}}
				onChange={onChange} 
				label={label}
				variant='filled'
				value={value} 
				id={label}
				error={error}
				helperText={errorMessage}
			/>
		)
	}

	renderPendingRow = () => {
		const { classes } = this.props
		return (
			<div className={`${classes.spinnerRow} ${this.props.smallView ? 'small' : ''}`}>
				<Spinner/>
			</div>
		)
	}

	renderModalContent = () => {
		const { classes } = this.props
		let user = this.state.tmpUser
		return  (<div className={classes.modalContainer}>
				<div onClick={() => this.props.onCloseModal()} className={classes.xIconContainer}><XIcon/></div>
				<div className={classes.modalHeader}>
					Invite a member of your team to use this<br/><span>{user.plan.data.plan_description} Plan</span>
				</div>
				<div className={classes.modalContent}>
					<div className={`${classes.modalRow} first`}>
						<TextField
							fullWidth		= {true}
							label			= 'First name'
							error			= {user.first_name_error ? 'Please enter a valid name' : ''}
							editable		= {true}
							onInputChange	= {value => this.updateFieldInUser('first_name', value)}
							content			= {user.first_name}
						/>
						<TextField
							fullWidth		= {true}
							label			= 'Last Name'
							error			= {user.last_name_error ? 'Please enter a valid name' : ''}
							editable		= {true}
							onInputChange	= {value => this.updateFieldInUser('last_name', value)}
							content			= {user.last_name}
						/>
							
					</div>
					<div className={classes.modalRow}>
						<TextField
							fullWidth		= {true}
							label			= 'Email'
							error			= {user.email_error ? user.email_error : ''}
							editable		= {true}
							onInputChange	= {value => this.updateFieldInUser('email', value)}
							content			= {user.email}
						/>
					</div>
				</div>
			</div>
		)
	}

	render(){

		let modalContent = this.state.pending ? this.renderPendingRow() : this.renderModalContent()
		return (
			<ConfirmModal
				title					= ''
				isShown					= {this.props.showModal}
				content					= {modalContent}
				noButtonText			= 'Cancel'
				yesButtonText			= 'Invite'
				confirmButtonDisabled	= {this.state.pending}
				rejectButtonDisabled	= {this.state.pending}
				onReject				= {() => this.props.onCloseModal()}
				onConfirm				= {() => this.saveUser()}
				size					= 'size3'
			/>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InviteUserModal))