import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { setTempUsers } from '../../actions/users'
import { TextField } from 'text-field-mui'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import PDCButton from 'button'
import { BulbIcon } from 'pdc-svg-icons'
import Spinner from 'spinner'
import PropTypes from 'prop-types'

const styles = theme => ({
    addUsersContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 30
    },
    addUsersFormRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 30,
        '&.first > div:first-child': {
            marginRight: 30
        },
        '&.button': {
            justifyContent: 'center'
        },
        '&.spinner': {
            justifyContent: 'center',
            '& svg': {
                color: theme.palette.primary[0]
            }
        },
        '&.separator': {
            borderBottom: '1px solid #DEE1E3',
            marginRight: '-15px',
            marginLeft: '-15px'
        },
        '& .admin-option': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .admin-label': {
            fontSize: 14,
            fontWeight: 600
        }
    },
    tipContainer: {
        zIndex: '-1',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        color: '#A1A1A1',
        textAlign: 'center',
        '& .tip': {
            color: theme.palette.important[0],
            fontFamily: 'NTR !important',
            fontWeight: 'bold',
            fontSize: 16
        },
        '& .orangeTip': {
            color: 'black',
            fontWeight: 'bold',
            marginBottom: 5
        },
        '& svg': {
            color: theme.palette.important[0],
            width: 36,
            height: 36

        }
    },
    pdcMenu: {
        width: 110
    },
    planWrapper: {
        width: 120
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView,
    tempUsers: state.tempUsers
})

const mapDispatchToProps = dispatch => ({
    setTempUsers: tempUsers => dispatch(setTempUsers(tempUsers))

})

class AddUsersSmall extends Component {
    componentDidMount = () => {
        if (this.props.tempUsers.length > 1) {
            this.props.setTempUsers([{
                id: 1,
                user_plan_id: 1,
                first_name: '',
                last_name: '',
                email: '',
                is_admin: false,
                relationships: {},
                status: 'new'
            }])
        }
    }

    render () {
        const { classes } = this.props

        const shouldDisablePlan = this.props.forceProPlan

        const user = this.props.tempUsers[0]
        const planMenuItems = [
            { value: 1, content: 'Basic' },
            { value: 2, content: 'Plus' },
            { value: 3, content: 'Pro' }
        ]

        const buttonSpinnerRow = this.props.pendingAddUsers
            ? <div className={`${classes.addUsersFormRow} spinner`}><Spinner color='currentColor'/></div>
            : <div className={`${classes.addUsersFormRow} button`}>
                <PDCButton color='primary' data-button='invite-users' variant='filled' onClick={() => this.props.onAddUsersClick()}>Add new user</PDCButton>
            </div>

        return (
            <div className={classes.addUsersContainer}>
                <div className={`${classes.addUsersFormRow} first`}>
                    <TextField
                        label = {'First name'}
                        value = {user.first_name}
                        onChange = {e => this.props.changeValueInUser(user, 'first_name', e.target.value)}
                        onXClick = {() => this.props.changeValueInUser(user, 'first_name', '')}
                        error = {user.first_name_error}
                        helperText = {user.first_name_error}
                        data-test-id = {'first_name'}
                        noLabelOnActiveOrResolved
                    />
                    <TextField
                        label = {'Last name'}
                        value = {user.last_name}
                        onChange = {e => this.props.changeValueInUser(user, 'last_name', e.target.value)}
                        onXClick = {() => this.props.changeValueInUser(user, 'last_name', '')}
                        error = {user.last_name_error}
                        helperText = {user.last_name_error}
                        data-test-id = {'last_name'}
                        noLabelOnActiveOrResolved
                    />
                </div>
                <div className={classes.addUsersFormRow}>
                    <TextField
                        fullWidth
                        label = {'Email'}
                        value = {user.email}
                        onChange = {e => this.props.changeValueInUser(user, 'email', e.target.value)}
                        onXClick = {() => this.props.changeValueInUser(user, 'email', '')}
                        error = {user.email_error}
                        helperText = {user.email_error}
                        data-test-id = {'email'}
                        noLabelOnActiveOrResolved
                    />
                </div>
                <div className={classes.addUsersFormRow}>
                    <div className={classes.planWrapper}>
                        {this.props.forceProPlan
                            ? (<div className='forcedPlanText' style={{ fontSize: '16px' }}>Plan: {planMenuItems.find(plan => plan.value === 3).content}</div>)
                            : (<Select
                                labelId="plan-select-label"
                                id="plan-select"
                                fullWidth
                                value={this.props.forceProPlan ? 3 : (user.user_plan_id ? user.user_plan_id : 1)}
                                label={''}
                                onChange={e => this.props.changeValueInUser(user, 'user_plan_id', e.target.value)}
                                disabled={shouldDisablePlan}
                            >
                                {planMenuItems.map((item, i) => (
                                    <MenuItem data-menu-item={item.content} key={i} value={item.value}>
                                        {item.content}
                                    </MenuItem>
                                ))}
                            </Select>)}
                    </div>
                </div>
                <div className={`${classes.addUsersFormRow} separator`}>
                </div>
                {buttonSpinnerRow}
                <div className={classes.tipContainer}>
                    <BulbIcon/>
                    <span className='tip'>Tip! </span>
                    <span className='orangeTip'>Not sure about your user&apos;s first and last name spelling?</span>
                    <span>No worries, they will be able to change it when they accept the invite.</span>
                </div>
            </div>
        )
    }
}

AddUsersSmall.propTypes = {
    classes: PropTypes.object,
    changeValueInUser: PropTypes.fucn,
    smallView: PropTypes.bool,
    users: PropTypes.array,
    tempUsers: PropTypes.array,
    pendingAddUsers: PropTypes.bool,
    onAddUsersClick: PropTypes.func,
    addUserRow: PropTypes.func,
    inviteUsers: PropTypes.func,
    setTempUsers: PropTypes.func,
    forceProPlan: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddUsersSmall))
