import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import Typography from 'typography'
// import Select from 'select-mui'
// import MenuItem from 'menu-item-mui'
import TimezoneField from 'timezone-field'
import { TextField } from 'text-field-mui'
import AudioComponent2, { Greeting, createGreeting, TTS, Recording, UploadAudio, StoredFile } from 'audio-component-2'
import Api from 'api'
import { UpdateData } from 'step-wizard/src/StepWizard'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import { getValue } from 'remote-config-value'
import { PassData, StepName } from './CompanySetup'

/***/
export interface GeneralSettingsData {
    timezone: string
    callerId: string
    voicemail
    holdMusic
    hasCompanySettingsChange: boolean
}

interface Props {
    saveId: number | string
    update: (data: UpdateData) => void
    passData: PassData
    classes
    extension: any
    smallView: boolean
}

interface State {
    storedVoicemail: Greeting
    storedHoldMusic: Greeting
    storedTimezone: string
    storedCallerId: string

    timezone: string
    callerId: string
    showValidationErrors: boolean
    voicemail: Greeting
    voicemailLoading: boolean
    holdMusic: Greeting
    holdMusicOptions: StoredFile[]
    holdMusicLoading: boolean
    companySettingsLoading: boolean
    saving: boolean
}

class GeneralSettingsStep extends Component<Props, State> {
    constructor (props: Props) {
        super(props)
        const passData = this.props.passData[StepName.GeneralSettings]
        const timezone = passData?.timezone || ''
        const callerId = passData?.callerId || ''
        const voicemail = passData?.voicemail || null
        const holdMusic = passData?.holdMusic || null
        this.state = {
            storedVoicemail: null,
            storedHoldMusic: null,
            storedTimezone: '',
            storedCallerId: '',
            timezone,
            callerId,
            voicemail,
            showValidationErrors: false,
            holdMusic,
            holdMusicOptions: [],
            voicemailLoading: true,
            holdMusicLoading: true,
            companySettingsLoading: false,
            saving: false
        }
    }

    componentDidMount = () => {
        this.loadCurrentSettings()
        this.loadVoicemail()
    }

    loadCurrentSettings = async () => {
        const passData = this.props.passData[StepName.GeneralSettings]
        this.setState({ companySettingsLoading: true })
        const companySettings = await Api.getCompanySettings()
        const holdMusicOptions = (companySettings.hold_music_options || []).map(file => new StoredFile(file.id, file.name, '', !!file.selected))
        holdMusicOptions.unshift(new StoredFile('0', '(Ringing Tone)', null))
        const storedTimezone = companySettings.timezone
        const storedCallerId = companySettings.caller_id
        const timezone = passData?.timezone || storedTimezone
        const callerId = passData?.callerId || storedCallerId

        if (passData?.holdMusic) holdMusicOptions.forEach((hm: StoredFile) => hm.toggleSelect(hm.isEqualTo(passData.holdMusic)))

        // NOTE: On load the saved hold music will be in `holdMusicOptions` event though it can be saved as a recording or as an uploaded file
        const selectedHoldMusic: StoredFile = holdMusicOptions.find(o => o.selected) || holdMusicOptions.find((hm: StoredFile) => hm.getId() === '0')
        selectedHoldMusic.toggleSelect(true)
        this.setState({
            companySettingsLoading: false,
            holdMusicOptions,
            timezone,
            callerId,
            holdMusic: selectedHoldMusic,
            // storedHoldMusicOptions: [...holdMusicOptions].map(holdMusicOption => holdMusicOption.copy()),
            storedTimezone,
            storedCallerId,
            storedHoldMusic: selectedHoldMusic?.copy()
        }, this.loadHoldMusicLink)
    }

    loadHoldMusicLink = async () => {
        const stateUpdate = { holdMusicLoading: false }
        const holdMusic = this.state.holdMusic?.copy() as StoredFile
        if (!holdMusic || !holdMusic.hasAudio()) return this.setState(stateUpdate)
        const urlResponse = await Api.getMusicOnHoldLink(holdMusic.getId())
        const url: string = urlResponse.download_link
        holdMusic.setUrl(url)
        if (
            this.state.holdMusic instanceof StoredFile &&
            holdMusic.getId() !== this.state.holdMusic.getId()
        ) Object.assign(stateUpdate, { holdMusic })
        const holdMusicOptions = [...this.state.holdMusicOptions]
        holdMusicOptions.forEach(hm => hm.getId() === holdMusic.getId() ? hm.setUrl(url) : null)
        this.setState({ holdMusicOptions, ...stateUpdate })
    }

    loadVoicemail = async () => {
        // const passData = this.props.passData[StepName.GeneralSettings]
        const companyExtension = this.props.passData.extraData.companyExtension
        const voicemailConfig = companyExtension?.id ? await Api.getVoicemailConfig(companyExtension.id) : null
        if (voicemailConfig.voip_recording) {
            const urlResponse = await Api.getMusicOnHoldLink(voicemailConfig.voip_recording.voip_recording_id)
            voicemailConfig.url = urlResponse.download_link
        }
        const storedVoicemail: Greeting = createGreeting(voicemailConfig)
        const voicemail = /* passData?.voicemail || */storedVoicemail.copy()
        this.setState({ voicemail, storedVoicemail, voicemailLoading: false })
    }

    componentDidUpdate = (prevProps: Props, prevState: State): void => {
        const hasSaveRequest = Boolean(this.props.saveId && (prevProps.saveId !== this.props.saveId))
        if (hasSaveRequest) this.save()
        const { holdMusic } = this.state
        if (
            holdMusic instanceof StoredFile &&
            !holdMusic.getUrl() &&
            prevState.holdMusic?.getId() !== holdMusic.getId()
        ) this.loadHoldMusicLink()
    }

    save = async () => {
        const isValid: boolean = this.isValid()
        if (!isValid) {
            this.props.update({ completed: false })
            return this.setState({ showValidationErrors: true }, this.scrollToErrorElement)
        }

        this.setState({ saving: true })

        const updateData: UpdateData = { completed: isValid, gotoNext: true }
        updateData.saveData = this.getSaveData()

        const { timezone, callerId, voicemail, holdMusic } = this.state
        const { storedTimezone, storedCallerId, storedVoicemail, storedHoldMusic } = this.state
        // Create the voicemail recording and set it as voicemail
        const extensionId = this.props.passData.extraData.companyExtension.id
        let createVoicemailRequest, saveVoicemailRequest
        if (!voicemail.isEqualTo(storedVoicemail)) {
            const audioData: any = { type: 'voicemail', id: storedVoicemail.getId() }
            if (voicemail instanceof TTS) {
                // Create the voicemail. Data: voicemail.tts: language, text, voice
                audioData.recordingType = 'tts'
                audioData.text = voicemail.getText()
                audioData.voice = voicemail.getVoice()
            } else if (voicemail instanceof Recording) {
                // Create the voicemail. Data: voicemail.recording: recordedAudio: downloadLink, filename
                audioData.recordingType = 'new-file'
                audioData.filename = voicemail.recordedAudio?.filename
                audioData.downloadLink = voicemail.recordedAudio?.downloadLink
            } else if (voicemail instanceof UploadAudio) {
                // Create the voicemail. Data: voicemail.uploadedAudio: base64Data, downloadLink, filename
                audioData.recordingType = 'new-file'
                audioData.filename = voicemail.getName()
                audioData.downloadLink = voicemail.getUrl()
            }
            createVoicemailRequest = this.saveAudio(audioData)
            createVoicemailRequest.then((voicemailRecordingId: number): void => {
                const updateVoicemailOnSave = () => {
                    if (voicemail instanceof TTS) {
                        voicemail.setText(audioData.text)
                        voicemail.setUrl('')
                        voicemail.setId(voicemailRecordingId)
                        voicemail.onSaved()
                    }
                }
                if (audioData.id) updateVoicemailOnSave()
                else {
                    saveVoicemailRequest = Api.configureVoicemail(voicemailRecordingId, extensionId)
                    saveVoicemailRequest.then(updateVoicemailOnSave)
                }
            })
        }

        if (storedTimezone !== timezone || storedCallerId !== callerId || !holdMusic.isEqualTo(storedHoldMusic)) {
            // Create the hold music recording if not created
            let holdMusicRecordingId = holdMusic.getId()
            let saveHoldMusicRequest
            if (!holdMusicRecordingId) {
                const audioData: any = { type: 'hold-music' }
                if (holdMusic instanceof StoredFile) holdMusicRecordingId = holdMusic.getId()
                else if (holdMusic instanceof UploadAudio) {
                    // Create the holdMusic. Data: holdMusic.uploadedAudio: base64Data, downloadLink, filename
                    audioData.recordingType = 'new-file'
                    audioData.filename = holdMusic.getName()
                    audioData.downloadLink = holdMusic.getUrl()
                } else if (holdMusic instanceof Recording) {
                    // Create the holdMusic. Data: holdMusic.recordedAudio: downloadLink, filename
                    audioData.recordingType = 'new-file'
                    audioData.filename = holdMusic.recordedAudio?.filename
                    audioData.downloadLink = holdMusic.recordedAudio?.downloadLink
                }
                if (holdMusic instanceof StoredFile) {
                    audioData.recordingType = 'stored-file'
                    audioData.id = holdMusic.getId()
                    saveHoldMusicRequest = new Promise(r => r(holdMusic.getId()))
                } else {
                    saveHoldMusicRequest = this.saveAudio(audioData)
                }
            }
            if (!holdMusicRecordingId) {
                holdMusicRecordingId = await saveHoldMusicRequest
                updateData.saveData.holdMusic.id = holdMusicRecordingId
            }

            // Update the company / account settings
            // eslint-disable-next-line
            const companySettings = { timezone, caller_id: callerId, hold_music_id: holdMusicRecordingId, extension_id: extensionId }
            const companyRequest = Api.setCompanySettings(companySettings)
            await Promise.all([createVoicemailRequest, saveHoldMusicRequest, companyRequest])
            await saveVoicemailRequest
        }

        this.props.update(updateData)
        this.setState({ saving: false })
    }

    scrollToErrorElement = () => {
        const errorElement = document.getElementsByClassName('error')[0]
        const muiErrorElement = document.getElementsByClassName('Mui-error')[0]
        if (errorElement) errorElement.scrollIntoView()
        else if (muiErrorElement) muiErrorElement?.scrollIntoView()
    }

    saveAudio = async (audioData) => {
        const extensionId = this.props.passData.extraData.companyExtension.id
        let id = audioData.id
        if (audioData.recordingType === 'tts') {
            const { text, voice } = audioData
            let response
            if (audioData.type === 'voicemail') response = await Api.createTTSGreeting({ text, voice, name: 'TTS', extensionId, id })
            else if (audioData.type === 'hold-music') response = await Api.createTTSMusicOnHold({ text, voice, name: 'TTS', extensionId })
            id = response.voip_recording_id
        } else if (audioData.recordingType === 'new-file') {
            const { filename, downloadLink } = audioData
            const base64Data = downloadLink.split(';')[1].split(',')[1]
            let response
            if (audioData.type === 'voicemail') response = await Api.createFileGreeting({ name: filename, file: base64Data, extensionId, id })
            else if (audioData.type === 'hold-music') response = await Api.createFileMusicOnHold({ name: filename, file: base64Data, extensionId })
            id = response.voip_recording_id
        } else if (audioData.recordingType === 'stored-file') { /* set to audioData.id */ }
        return id
    }

    isValid = (): boolean => {
        const { timezone, callerId, voicemail, holdMusic } = this.state
        return Boolean(timezone && callerId && voicemail?.isValid() && holdMusic?.isValid())
    }

    getSaveData = (): GeneralSettingsData => {
        const { timezone, callerId, voicemail, holdMusic } = this.state
        const { storedTimezone, storedCallerId, storedVoicemail, storedHoldMusic } = this.state
        const hasCompanySettingsChange = Boolean(
            storedTimezone !== timezone ||
            storedCallerId !== callerId ||
            !storedVoicemail.isEqualTo(voicemail) ||
            !storedHoldMusic.isEqualTo(holdMusic)
        )
        return { timezone, callerId, voicemail, holdMusic, hasCompanySettingsChange }
    }

    getChanges = () => {
        const changes = []
        const { timezone, callerId, voicemail, holdMusic } = this.state
        const { storedTimezone, storedCallerId, storedVoicemail, storedHoldMusic } = this.state
        if (storedTimezone !== timezone) changes.push('timezone')
        if (storedCallerId !== callerId) changes.push('callerId')
        if (!holdMusic.isEqualTo(storedHoldMusic)) changes.push('holdMusic')
        if (!voicemail.isEqualTo(storedVoicemail)) changes.push('voicemail')
        return changes
    }

    Section = (props): JSX.Element => {
        const { classes } = this.props
        return (
            <div className={classes.generalSettingsSection}>
                <Typography variant='h6' classes={{ root: 'title' }}>{props.title}</Typography>
                <Typography variant='body2' classes={{ root: 'description' }}>{props.description}</Typography>
                <div className='content'>{props.children}</div>
            </div>
        )
    }

    renderTimezoneSection = (): JSX.Element => {
        const Section = this.Section
        const hasError = this.state.showValidationErrors && !this.state.timezone
        return (
            <Section
                title = {getValue('company_setup_timezone_title')}
                description = {getValue('company_setup_timezone_name_message')}
            >
                <TimezoneField
                    savedTimezone = {this.state.storedTimezone}
                    timezoneValue = {this.state.timezone}
                    onChange = {timezone => this.setState({ timezone })}
                    origin = 'company-setup'
                    error = {hasError}
                    helperText = {hasError ? 'Required field' : null}
                />
            </Section>
        )
    }

    renderCallerIdSection = (): JSX.Element => {
        const { classes } = this.props
        const Section = this.Section
        const callerId = this.state.callerId || ''
        const error = this.state.showValidationErrors && !callerId ? 'Required field' : ''
        const onChange = event => {
            let callerId = event.target.value.replace(/[^a-zA-Z0-9\s,]/g, '')
            if (callerId.length > 15) callerId = callerId.substring(0, 15)
            this.setState({ callerId })
        }
        return (
            <Section
                title = {<>{getValue('company_setup_caller_id_title')} <RemoteInfoTipMui arrow remoteConfigIDs={['configure_caller_id_disclamer']} placement={RemoteInfoTipMuiPlacement.TOP}/></>}
                description = {getValue('company_setup_caller_id_name_message')}
            >
                <TextField
                    value = {callerId}
                    onChange = {onChange}
                    onXClick = {() => this.setState({ callerId: '' })}
                    classNames = {{ root: classes.textField }}
                    error = {!!error}
                    helperText = {error}
                />
            </Section>
        )
    }

    renderVoicemailSection = (): JSX.Element | null => {
        const isLoading: boolean = this.state.voicemailLoading || this.state.holdMusicLoading || this.state.companySettingsLoading
        if (isLoading) return null
        const onVoicemailChange = (voicemail: Greeting): void => {
            // const isDifferent = !voicemail.isEqualTo(this.state.storedVoicemail)
            // if (isDifferent) {
            //     voicemail.setId(null)
            //     if (voicemail instanceof TTS && voicemail.getUrl()) voicemail.setUrl(null)
            // } else if (voicemail instanceof TTS) {
            //     voicemail.setUrl((this.state.storedVoicemail as TTS).getUrl())
            // }
            this.setState({ voicemail })
        }
        const companyName = this.props.passData.extraData.companyName || ''
        const defaultVoicemailText = `You have reached the voicemail of, ${companyName}. Please leave a message with your name, number, and the reason for your call. Someone from our team will get back to you as soon as possible.`
        const Section = this.Section
        return (
            <Section
                title = {getValue('company_setup_voicemail_title')}
                description = {getValue('company_setup_voicemail_name_message')}
            >
                <AudioComponent2
                    data-test-id = 'company-setup-voicemail-component'
                    id = 'company-setup-voicemail-component'
                    greeting = {this.state.voicemail || new TTS(undefined, defaultVoicemailText)}
                    showValidationErrors = {this.state.showValidationErrors}
                    onChange = {onVoicemailChange}
                    screenViewType = {{ isMobileView: this.props.smallView }}
                    audioTypeNames = {{
                        [TTS.className]: getValue('company_setup_voicemail_tts_text'),
                        [Recording.className]: getValue('company_setup_voicemail_record_text'),
                        [UploadAudio.className]: getValue('company_setup_voicemail_upload_text')
                    }}
                />
            </Section>
        )
    }

    renderHoldMusicSection = (): JSX.Element | null => {
        const isLoading: boolean = this.state.voicemailLoading || this.state.holdMusicLoading || this.state.companySettingsLoading
        if (isLoading) return null
        const holdMusic = this.state.holdMusic
        const onHoldMusicChange = holdMusic => {
            const newStateData: any = {}
            if (holdMusic instanceof StoredFile) {
                const holdMusicOptions = [...this.state.holdMusicOptions]
                holdMusicOptions.forEach(hm => hm.toggleSelect(hm.getId() === holdMusic.getId()))
                newStateData.holdMusicOptions = holdMusicOptions
                this.setState({ holdMusic, holdMusicOptions })
            } else holdMusic.setId(null)
            this.setState({ holdMusic, ...newStateData })
        }
        const Section = this.Section
        return (
            <Section
                title = {getValue('company_setup_hold_music_title')}
                description = {getValue('company_setup_hold_music_name_message')}
            >
                <AudioComponent2
                    data-test-id = 'company-setup-hold-music-component'
                    id = 'company-setup-hold-music-component'
                    greeting = {holdMusic}
                    showValidationErrors = {this.state.showValidationErrors}
                    onChange = {onHoldMusicChange}
                    screenViewType = {{ isMobileView: this.props.smallView }}
                    audioTypeNames = {{
                        [Recording.className]: getValue('company_setup_hold_music_record_text'),
                        [UploadAudio.className]: getValue('company_setup_hold_music_upload_text'),
                        [StoredFile.className]: getValue('company_setup_hold_music_choose_file')
                    }}
                    hideTTS
                    selectionList = {this.state.holdMusicOptions}
                    // textAreaInfoTipId = ''
                />
            </Section>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull data-test-id='csw-spinner-icon' text='Please wait...' color={theme.palette.secondary.main} size='bigger'/>
            </div>
        )
    }

    render = (): JSX.Element => {
        const { classes, smallView } = this.props
        const { saving, voicemailLoading, holdMusicLoading, companySettingsLoading } = this.state
        const isLoading: boolean = voicemailLoading || holdMusicLoading || companySettingsLoading
        return (
            <div className={`${classes.generalSettings} ${smallView ? 'mobile' : ''}`}>
                {isLoading || saving ? this.renderLoader() : null}
                {!companySettingsLoading
                    ? <>
                        {this.renderTimezoneSection()}
                        {this.renderCallerIdSection()}
                    </>
                    : null
                }
                {this.renderVoicemailSection()}
                {this.renderHoldMusicSection()}
            </div>
        )
    }
}

export default withStyles(styles)(GeneralSettingsStep)
