// React
import React from "react";
import {isMobile} from 'react-device-detect';


// Stylesheet
import "./position-search.css";

class PositionSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [
        { id: 1, value: "" },
        { id: 2, value: "" },
        { id: 3, value: "" },
        { id: 4, value: "-" },
        { id: 5, value: "" },
        { id: 6, value: "" },
        { id: 7, value: "" },
        { id: 8, value: "-" },
        { id: 9, value: "" },
        { id: 10, value: "" },
        { id: 11, value: "" },
        { id: 12, value: "" }
      ]
    };
    this.initialState = JSON.parse(JSON.stringify(this.state));  // Deep copy base state
    this.numInputs = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset !== prevProps.reset) {
      this.setState(
        JSON.parse(JSON.stringify(this.initialState))
      )
    }
  }

  // Move focus to previous number input field
  moveToPrevField(index) {
    let inputkey = index - 1;
    if (index === 0) {
      this.numInputs[0].focus();
    } else if (index === 4) {
      this.numInputs[2].focus();
    } else if (index === 8) {
      this.numInputs[6].focus();
    } else {
      this.numInputs[inputkey].focus();
    }
  }

  // Move focus to next number input field
  moveToNextField(index) {
    let inputkey = index + 1;
    if (index === 2) {
      this.numInputs[4].focus();
    } else if (index === 6) {
      this.numInputs[8].focus();
    } else if (index === 11) {
      this.numInputs[11].focus();
    } else {
      this.numInputs[inputkey].focus();
    }
  }

  // Process all number inputs before sending to filter
  processInputs() {
    let is_like = "";
    let numbersArray = [];
    this.state.inputs.forEach(function(input) {
      let newValue = "";
      if (input.value === "") {
        newValue = ".";
      } else if (input.value === "-") {
        newValue = "";
      } else {
        newValue = input.value;
      }
      numbersArray.push(newValue);
    });
    is_like = "+1" + numbersArray.join("");
    this.props.searchPosition(is_like);
  }

  // Detect keypress event on mobile devices
  onKeyDownMobile(event) {
    if (event.keyCode === 13){
      event.preventDefault()
      event.target.blur()
      return
    }
    let key = event.key;
    let index = parseInt(event.target.name, 0);
    if (key.toLowerCase() == 'backspace' || key.toLowerCase() == 'back') {
      let newItems = [...this.state.inputs];
      if (newItems[index].value == ''){
        this.moveToPrevField(index);
      }
    }

  }

  onPositionSearchInput(event) {
    let key = event.key;
    let keycode = event.keyCode;
    let index = parseInt(event.target.name, 0);
    if (isMobile) {
      let eventValue = event.target.value
      let newItems = [...this.state.inputs];
      if (eventValue == '') {
        newItems[index].value = "";
        this.setState({ inputs: newItems });
        this.moveToPrevField(index);
      }else {
        newItems[index].value = eventValue.toUpperCase();
        this.setState({ inputs: newItems });
        this.moveToNextField(index);
      }
      event.preventDefault();
      this.processInputs();
    } else if (keycode === 8) {
      // backspace
      let newItems = [...this.state.inputs];
      newItems[index].value = "";
      this.setState({ inputs: newItems });
      this.numInputs[index].value = "";
      this.moveToPrevField(index);
      event.preventDefault();
      this.processInputs();
    } else if (keycode === 37 || keycode === 38) {
      // left/up arrow
      this.moveToPrevField(index);
      event.preventDefault();
    } else if (keycode === 46) {
      // delete
      let newItems = [...this.state.inputs];
      newItems[index].value = "";
      this.setState({ inputs: newItems });
      this.numInputs[index].value = "";
      this.moveToNextField(index);
      event.preventDefault();
      this.processInputs();
    } else if (keycode === 39 || keycode === 40) {
      // right/down arrow
      this.moveToNextField(index);
    } else if (
      (keycode > 47 && keycode < 58) ||
      (keycode > 64 && keycode < 91) ||
      (keycode > 95 && keycode < 106)
    ) {
      let newItems = [...this.state.inputs];
      newItems[index].value = key.toUpperCase();
      this.setState({ inputs: newItems });
      this.numInputs[index].value = key.toUpperCase();
      this.moveToNextField(index);
      event.preventDefault();
      this.processInputs();
    } else if (keycode === 9 || keycode === 16) {
      // tab
      this.moveToNextField(index);
      event.preventDefault();
    } else if (key === "Shift" || keycode === 65) {
      // shift
      event.preventDefault();
    } else {
      // everything else;
      event.preventDefault();
    }
  }

  // Position Text Search Boxes
  render() {
    let search_boxes = this.state.inputs.map((number, id) => {
      if (number.value !== "-") {
        
        let searchInput = <input
                      key={id}
                      data-test-id={`position-search-${id}`}
                      type="text"
                      name={id}
                      maxLength="1"
                      className="form-control num"
                      onKeyDown={e => this.onPositionSearchInput(e)}
                      ref={e => {
                        this.numInputs[id] = e;
                      }}
                      onChange={()=>{console.log('')}}
                      tabIndex={id}
                      value={number.value}
                    />
        if (isMobile) {
          searchInput = <input
                          key={id}
                          data-test-id={`position-search-${id}`}
                          type="text"
                          name={id}
                          maxLength="1"
                          className="form-control num"
                          onChange={e => this.onPositionSearchInput(e)}
                          onKeyDown={e => this.onKeyDownMobile(e)}
                          ref={e => {
                            this.numInputs[id] = e;
                          }}
                          tabIndex={id}
                          value={number.value}
                        />
        }

        return searchInput
      } else {
        return <span className="position-search-dash" key={id}>-</span>;
      }
    });

    return (
      <div className="position-search">
        <div className="position-search-label">and matches</div>
        <div className="position-search-boxes form-group">{search_boxes}</div>
      </div>
    );
  }
}
export default PositionSearch;
