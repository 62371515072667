import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'

const apiBase = process.env.REACT_APP_USER_API_URL
const deviceBase = process.env.REACT_APP_DEVICE_SERVICE_BASE

class API {
    static loadPhones = async (limit, offset) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${deviceBase}/voip/${accountId}/devices?limit=${limit}&offset=${offset}`
        const response = await ajax.get(requestUrl)
        if (response?.errors) {
            return response
        } else {
            try {
                return response.data
            } catch (err) {
                console.error('Error calling devices endpoint', err)
                return { errors: [err] }
            }
        }
    }

    static loadUsers = async (pageSize, offset) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${apiBase}/voip/${accountId}/users?page_size=${pageSize}&offset=${offset}&order_by=extension.asc&include=extension,plan,add_ons,devices,numbers`
        const response = await ajax.get(requestUrl)
        if (response?.errors) {
            return response
        } else {
            try {
                return response.data
            } catch (err) {
                console.error('Error calling users endpoint', err)
                return { errors: [err] }
            }
        }
    }

    static updateDeviceName = async (deviceId, device_name) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${deviceBase}/voip/${accountId}/devices/${deviceId}/name`
        const response = await ajax.put(requestUrl, { device_name })
        if (response?.errors) {
            return response
        } else {
            try {
                return response.data
            } catch (err) {
                console.error('Error calling device name endpoint', err)
                return { errors: [err] }
            }
        }
    }

    static submitE911 = async (deviceId, address) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${deviceBase}/voip/${accountId}/devices/${deviceId}/e911_submit`

        const response = await ajax.post(requestUrl, address)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling e911_submit endpoint', err)
            return { error: true }
        }
    }

    static getIncompleteE911Count = async () => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${deviceBase}/voip/${accountId}/incomplete_e911_count`
        const response = await ajax.get(requestUrl)
        if (response?.errors) {
            return response
        } else {
            try {
                return response.data
            } catch (err) {
                console.error('Error calling incomplete_e911_count endpoint', err)
                return { errors: [err] }
            }
        }
    }

    static assignDevice = async (deviceId, user_id) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${deviceBase}/voip/${accountId}/devices/${deviceId}/assign/${user_id}`
        const response = await ajax.post(requestUrl, {})
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling assign device endpoint', err)
            return { error: true }
        }
    }

    static unassignDevice = async (deviceId, user_id) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const requestUrl = `${deviceBase}/voip/${accountId}/devices/${deviceId}/unassign`
        const response = await ajax.post(requestUrl, {})
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload
            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling unassign device endpoint', err)
            return { error: true }
        }
    }
}
export default API
