const stateOptions = [
    {
        content: 'State',
        value: ''
    },
    {
        content: 'Alabama',
        value: 'AL'
    },
    {
        content: 'Alaska',
        value: 'AK'
    },
    {
        content: 'American Samoa',
        value: 'AS'
    },
    {
        content: 'Arizona',
        value: 'AZ'
    },
    {
        content: 'Arkansas',
        value: 'AR'
    },
    {
        content: 'California',
        value: 'CA'
    },
    {
        content: 'Colorado',
        value: 'CO'
    },
    {
        content: 'Connecticut',
        value: 'CT'
    },
    {
        content: 'Delaware',
        value: 'DE'
    },
    {
        content: 'District Of Columbia',
        value: 'DC'
    },
    {
        content: 'Federated States Of Micronesia',
        value: 'FM'
    },
    {
        content: 'Florida',
        value: 'FL'
    },
    {
        content: 'Georgia',
        value: 'GA'
    },
    {
        content: 'Guam',
        value: 'GU'
    },
    {
        content: 'Hawaii',
        value: 'HI'
    },
    {
        content: 'Idaho',
        value: 'ID'
    },
    {
        content: 'Illinois',
        value: 'IL'
    },
    {
        content: 'Indiana',
        value: 'IN'
    },
    {
        content: 'Iowa',
        value: 'IA'
    },
    {
        content: 'Kansas',
        value: 'KS'
    },
    {
        content: 'Kentucky',
        value: 'KY'
    },
    {
        content: 'Louisiana',
        value: 'LA'
    },
    {
        content: 'Maine',
        value: 'ME'
    },
    {
        content: 'Marshall Islands',
        value: 'MH'
    },
    {
        content: 'Maryland',
        value: 'MD'
    },
    {
        content: 'Massachusetts',
        value: 'MA'
    },
    {
        content: 'Michigan',
        value: 'MI'
    },
    {
        content: 'Minnesota',
        value: 'MN'
    },
    {
        content: 'Mississippi',
        value: 'MS'
    },
    {
        content: 'Missouri',
        value: 'MO'
    },
    {
        content: 'Montana',
        value: 'MT'
    },
    {
        content: 'Nebraska',
        value: 'NE'
    },
    {
        content: 'Nevada',
        value: 'NV'
    },
    {
        content: 'New Hampshire',
        value: 'NH'
    },
    {
        content: 'New Jersey',
        value: 'NJ'
    },
    {
        content: 'New Mexico',
        value: 'NM'
    },
    {
        content: 'New York',
        value: 'NY'
    },
    {
        content: 'North Carolina',
        value: 'NC'
    },
    {
        content: 'North Dakota',
        value: 'ND'
    },
    {
        content: 'Northern Mariana Islands',
        value: 'MP'
    },
    {
        content: 'Ohio',
        value: 'OH'
    },
    {
        content: 'Oklahoma',
        value: 'OK'
    },
    {
        content: 'Oregon',
        value: 'OR'
    },
    {
        content: 'Palau',
        value: 'PW'
    },
    {
        content: 'Pennsylvania',
        value: 'PA'
    },
    {
        content: 'Puerto Rico',
        value: 'PR'
    },
    {
        content: 'Rhode Island',
        value: 'RI'
    },
    {
        content: 'South Carolina',
        value: 'SC'
    },
    {
        content: 'South Dakota',
        value: 'SD'
    },
    {
        content: 'Tennessee',
        value: 'TN'
    },
    {
        content: 'Texas',
        value: 'TX'
    },
    {
        content: 'Utah',
        value: 'UT'
    },
    {
        content: 'Vermont',
        value: 'VT'
    },
    {
        content: 'Virgin Islands',
        value: 'VI'
    },
    {
        content: 'Virginia',
        value: 'VA'
    },
    {
        content: 'Washington',
        value: 'WA'
    },
    {
        content: 'West Virginia',
        value: 'WV'
    },
    {
        content: 'Wisconsin',
        value: 'WI'
    },
    {
        content: 'Wyoming',
        value: 'WY'
    }
]

const provinceOptions = [
    {
        content: 'Province',
        value: ''
    },
    {
        content: 'Alberta',
        value: 'AB'
    },
    {
        content: 'British Columbia',
        value: 'BC'
    },
    {
        content: 'Manitoba',
        value: 'MB'
    },
    {
        content: 'New Brunswick',
        value: 'NB'
    },
    {
        content: 'Newfoundland and Labrador',
        value: 'NL'
    },
    {
        content: 'Northwest Territories',
        value: 'NT'
    },
    {
        content: 'Nova Scotia',
        value: 'NS'
    },
    {
        content: 'Nunavut',
        value: 'NU'
    },
    {
        content: 'Ontario',
        value: 'ON'
    },
    {
        content: 'Prince Edward Island',
        value: 'PE'
    },
    {
        content: 'Quebec',
        value: 'QC'
    },
    {
        content: 'Saskatchewan',
        value: 'SK'
    },
    {
        content: 'Yukon Territory',
        value: 'YT'
    }
]

const countryOptions = [
    {
        content: 'Country',
        value: ''
    },
    {
        content: 'United States',
        value: 'US'
    },
    {
        content: 'Canada',
        value: 'CA'
    }
]

/**
 *
 */
export { stateOptions, provinceOptions, countryOptions }
