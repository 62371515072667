import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5661%3A6826
 *
 * @param {SvgIconProps} props - svg props
 */
export const RefreshIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M17.65 6.35C16.2 4.9 14.21 4 12 4C7.58001 4 4.01001 7.58 4.01001 12C4.01001 16.42 7.58001 20 12 20C15.6171 20 18.6512 17.602 19.6431 14.3108C19.6903 14.1543 19.5707 14 19.4073 14H17.8271C17.721 14 17.6269 14.0672 17.5887 14.1662C16.7235 16.409 14.5475 18 12 18C8.69001 18 6.00001 15.31 6.00001 12C6.00001 8.69 8.69001 6 12 6C13.66 6 15.14 6.69 16.22 7.78L13.4268 10.5732C13.2693 10.7307 13.3808 11 13.6036 11H19.75C19.8881 11 20 10.8881 20 10.75V4.60355C20 4.38083 19.7307 4.26929 19.5732 4.42678L17.65 6.35Z' fill={color}/>
        </SvgIcon>
    )
}

export default RefreshIcon
