// React
import React from "react";

// Components
import KeywordSearch from "./keyword-search/keyword-search";
import RegionSearch from "./region-search/region-search";
import PositionSearch from "./position-search/position-search";
import ResetFilters from "../search-filters/reset-filters/reset-filters"

// Stylesheet
import "./search-bar.css";

// Search Box
class SearchBar extends React.Component {
  render() {
    let regionClass = "regionSearch hidden";
    let otherClass = "searchbarWrap show";
    let tollFree = "text-center hidden";
    if (this.props.is_free === "true") {
      regionClass = "regionSearch show";
      otherClass = "searchbarWrap hidden";
    }
    if (this.props.is_tollfree === "true") {
      regionClass = "regionSearch hidden";
      otherClass = "searchbarWrap hidden";
      tollFree = "text-center show";
    }
    return (
        <div className="search-bar row">
          <div className={otherClass}>
          <div className="row">
            <div className="search-bar col-sm-12 col-md-4">
            <KeywordSearch
              keywordSearch={this.props.keywordSearch.bind(this)}
              reset={this.props.reset}
            />
            </div>
            <div className="adv-filters col-sm-12 col-md-7">
              <PositionSearch
                searchPosition={this.props.searchPosition.bind(this)}
                reset={this.props.reset}
              />
            </div>

            <div className="col-sm-1 hidden-sm-xs" style={{'marginTop': '35px', 'textAllign': 'center'}}>
              <ResetFilters
                handleReset={JSON.stringify(this.props.initialFilterBy) !== JSON.stringify(this.props.filterBy) ? this.props.handleReset : ()=>{return null}}
                disabled={JSON.stringify(this.props.initialFilterBy) === JSON.stringify(this.props.filterBy)}
              />
            </div>
            </div>
            </div>
          <div className={tollFree}>
            <h4>Select a toll free number below:</h4>
          </div>
          <div className={regionClass}>
            <RegionSearch
              AreaCodeSearch={this.props.AreaCodeSearch.bind(this)}
              CitySearch={this.props.CitySearch.bind(this)}
              cities={this.props.cities}
              area_codes={this.props.area_codes}
              CityNumberSearch={this.props.CityNumberSearch.bind(this)}
              is_free={this.props.is_free}
              is_tollfree={this.props.is_tollfree}
              is_local={this.props.is_local}
            />
          </div>
        </div>
    );
  }
}

export default SearchBar;
