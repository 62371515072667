<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_5966_1049)">
	<path d="M19.5625 11.2196L11.6193 19.1629C10.0928 20.6893 7.48733 20.5566 5.79701 18.8663C4.1067 17.176 3.974 14.5705 5.50048 13.0441L14.1344 4.41013C15.0876 3.45694 16.7178 3.53997 17.7733 4.59547C18.8288 5.65096 18.9118 7.28119 17.9587 8.23437L10.0154 16.1776C9.63554 16.5575 8.98054 16.5241 8.55987 16.1035C8.13921 15.6828 8.10585 15.0278 8.48574 14.6479L15.7382 7.39539L14.591 6.24812L7.33847 13.5006C6.38528 14.4538 6.46831 16.084 7.5238 17.1395C8.57929 18.195 10.2095 18.2781 11.1627 17.3249L19.1059 9.38165C20.6324 7.85517 20.4997 5.24971 18.8094 3.55939C17.1191 1.86908 14.5136 1.73638 12.9871 3.26286L4.35321 11.8968C2.25343 13.9966 2.4358 17.5773 4.76094 19.9024C7.08608 22.2275 10.6668 22.4099 12.7665 20.3101L20.7098 12.3669L19.5625 11.2196Z" fill="currentColor"/>
	</g>
	<defs>
	<clipPath id="clip0_5966_1049">
	<rect width="24" height="24" fill="white"/>
	</clipPath>
	</defs>
	</svg>
</template>

<script>
  export default {};
</script>
