import React from 'react'
import DialogMui, { DialogProps } from '@material-ui/core/Dialog'
import { CloseIcon } from 'svg-icons'
import { theme } from 'get-theme'

interface Props extends DialogProps {
    showCloseButton?: boolean
}

const CloseXButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
    return (
        <div
            onClick = {onClick}
            style = {{
                cursor: 'pointer',
                position: 'absolute',
                top: 20,
                right: 30
            }}
            data-testid = 'close-modal-x-button'
        ><CloseIcon color={theme.palette.secondary.main}/></div>
    )
}

/***/
const Dialog = (props: Props): JSX.Element => {
    const { showCloseButton } = props
    const muiProps = { ...props }
    const extraProps = ['showCloseButton']
    extraProps.forEach(prop => delete muiProps[prop])
    return (
        <DialogMui {...muiProps}>
            {showCloseButton && <CloseXButton onClick={() => props.onClose?.({}, 'backdropClick')}/>}
            {props.children}
        </DialogMui>
    )
}

export default Dialog
