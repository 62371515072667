import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.1.0?node-id=5629%3A4434
 *
 * @param {SvgIconProps} props - svg props
 */
export const LogoIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M1 12.3896C1.20454 18.2674 6.02267 22.9734 11.9463 23H12.0471C18.1008 22.9728 23 18.0583 23 12.0003C23 5.9248 18.0736 1 11.9967 1C6.05003 1 1.20512 5.71578 1 11.611V12.3896ZM11.7497 8.60353C13.0519 8.60353 14.1072 9.63466 14.1072 10.9066C14.1072 12.1784 13.0519 13.2093 11.7497 13.2093C10.448 13.2093 9.39255 12.1784 9.39255 10.9066C9.39255 9.63466 10.448 8.60353 11.7497 8.60353ZM12.7601 15.8602C13.8249 15.6613 17.0693 14.7326 17.0693 10.8028C17.0693 8.14193 15.0069 5.95226 12.3586 5.67461C12.1715 5.65483 11.9814 5.64443 11.7889 5.64443C8.87253 5.64443 6.42362 7.95483 6.50817 10.8028C6.61931 14.5433 10.226 17.2652 13.9111 19.0072C14.0829 19.0886 14.273 18.9378 14.1492 18.7406C14.0636 18.6042 13.9267 18.4156 13.7668 18.1954C13.3512 17.623 12.7809 16.8373 12.5566 16.203C12.5012 16.0462 12.5932 15.8916 12.7601 15.8602Z' fill={color}/>
        </SvgIcon>
    )
}

export default LogoIcon
