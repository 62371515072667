import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c(VContainer,{attrs:{"data-test-id":"alert","fluid":""}},[_c(VRow,{staticClass:"pa-0 alert",attrs:{"justify":"center"}},[_c('div',{staticClass:"d-flex alert-container",class:{
                'info--text text--darken-1 info lighten-5': _vm.level === 'success',
                'error--text text--darken-1 error lighten-5': _vm.level === 'error',
                'warning--text text--darken-1 warning-focus': _vm.level === 'warning',
            }},[_c('w-btn',{staticClass:"alert-icon",class:_vm.icon_classes,attrs:{"icon":"","width":"24","height":"24"}},[_c(VIcon,[_vm._v("\n                    "+_vm._s(_vm.icon)+"\n                ")])],1),_vm._v(" "),_c('div',{staticClass:"alert-message my-auto"},[_vm._v("\n                "+_vm._s(_vm.message)+"\n            ")]),_vm._v(" "),(_vm.closable && _vm.level !== 'success')?_c('span',{staticClass:"close-alert cursor-pointer ml-8",on:{"click":function($event){_vm.show = false}}},[_c(VIcon,{attrs:{"color":(_vm.icon_color + " lighten-1")}},[_vm._v("mdi mdi-close")])],1):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }