import React, { Component } from 'react'
import { Tabs, Tab } from 'tabs'
import { OnCallOption, ScheduleOption } from './configure_util'
import gtmDataPush from 'gtm-events'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import { Switch } from 'switch'
import { getValue } from 'remote-config-value'
import { ValidationErrors } from './validate'
import { RuleProps } from './current_rules'
import { LunchAltIcon, StorefrontIcon, AfterHoursIcon, HolidayIcon } from 'svg-icons'
import AudioComponent2, { TTS, Recording, UploadAudio } from 'audio-component-2'
import WelcomeMessageIcon from './icons/WelcomeMessageIcon'
import Section from './Section'
import PlayMenuSection from './PlayMenuSection'
import RingUsersSection from './RingUsersSection'
import ForwardExternalSection from './ForwardExternalSection'
import NoAnswerSection from './NoAnswerSection'
// import LiveReceptionistSection from './LiveReceptionistSection'
import Typography from 'typography'
import NoAnswerIcon from './icons/NoAnswerIcon'
import VoicemailIcon from './icons/VoicemailIcon'
import UsersDropdown from './UsersDropdown'

const GtmAppName = 'configure-number'
const GtmMap = { OtherOption: 1, SameOption: 0 }

interface Props {
    // Material ui classes
    classes,
    // Are there mupltiple schedules? Should it show the schhedule tabs?
    useSchedules: boolean
    // The company business hours schedules
    schedules: any[]
    // Checks if the company is open 24 hours (by the schedules)
    isOpen24Schedule: (schedules: any[]) => boolean
    onCallOptionGroups: { [key in ScheduleOption]: RuleProps }
    // The selected schedule option (open-hours holidays ...)
    selectedScheduleOption: ScheduleOption
    // Change the selected schedule option i.e. the selected schedule tab
    switchSelectedScheduleOption: (tabName: ScheduleOption) => void
    // Should show validation errors
    showValidationErrors: boolean
    // The validation errors
    validationErrors: ValidationErrors
    screenViewType: any
    companyExtension: any
    users: any[]
    phoneNumber: string
    // liveAnswerConfiguration: any
    // lrModalOpen: boolean
    // collapseLRModal: () => void
    // existingLRNotes: string
    // existingLRPlanId: any
    // receptionistOption: any
    // scriptId: any
    origin: any
    contactedExpert: boolean
    // Render functions
    setContactedExpert: (a: boolean) => void
    renderOnCallSection: () => JSX.Element
    updateOnCallOptionGroups: (a: any) => void
    // updateLiveAnswerConfiguration: (a: any) => void
    // updateReceptionistOption: (a: any) => void
}

const tabIcons = {
    [ScheduleOption.OpenHours.toString()]: StorefrontIcon,
    [ScheduleOption.ClosedHours.toString()]: AfterHoursIcon,
    [ScheduleOption.Holidays.toString()]: HolidayIcon,
    [ScheduleOption.LunchBreak.toString()]: LunchAltIcon
}

class SchedulesRules extends Component<Props, any> {
    renderWelcomeMessageSection = () => {
        const { classes, useSchedules, schedules, screenViewType, selectedScheduleOption } = this.props
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = {
            ...onCallOptionGroups[selectedScheduleOption],
            greeting: onCallOptionGroups[selectedScheduleOption].greeting
        }
        const hasGreeting = onCallOptionGroup.hasGreeting
        const greeting = onCallOptionGroup.greeting
        let disableWelcomeClass = ''
        const disableWelcomeSection = onCallOptionGroup.onCallOption === OnCallOption.LiveAnswer || onCallOptionGroup.onCallOption === OnCallOption.VirtualAnswer || onCallOptionGroup.onCallOption === OnCallOption.LiveAnswerPlus
        if (disableWelcomeSection) {
            disableWelcomeClass = classes.disableWelcomeMessage
        }
        const updateWelcomeMessage = greeting => {
            onCallOptionGroups[selectedScheduleOption] = { ...onCallOptionGroup, greeting }
            this.props.updateOnCallOptionGroups(onCallOptionGroups)
        }
        const toggleGreeting = hasGreeting => {
            onCallOptionGroups[selectedScheduleOption] = { ...onCallOptionGroup, hasGreeting }
            this.props.updateOnCallOptionGroups(onCallOptionGroups)
        }
        const welcomeMessageToggleLabelRemoteConfigId = useSchedules && !this.props.isOpen24Schedule(schedules)
            ? {
                [ScheduleOption.OpenHours]: 'configure_number_open_hours_welcome_message_toggle_label', // ' during business hours',
                [ScheduleOption.ClosedHours]: 'configure_number_after_hours_welcome_message_toggle_label', // ' ouside of business hours',
                [ScheduleOption.Holidays]: 'configure_number_holidays_welcome_message_toggle_label', // ' during holidays',
                [ScheduleOption.LunchBreak]: 'configure_number_lunch_break_welcome_message_toggle_label' // ' during lunch break'
            }[schedules?.length ? selectedScheduleOption : null] || ''
            : 'configure_number_welcome_message_toggle_label'
        return (
            <Section screenViewType={screenViewType} classes={classes} icon={<WelcomeMessageIcon/>} remoteConfigID='configure_number_welcome_message_header_text'>
                <div className={`${classes.welcomeMessageToggleWrapper} ${disableWelcomeClass}`} style={{ marginBottom: hasGreeting ? 14 : 0 }}>
                    <Switch
                        checked = {hasGreeting && !disableWelcomeSection}
                        onChange = {() => toggleGreeting(!hasGreeting)}
                        name = {`${selectedScheduleOption}-welcome-message`}
                        value = {`${selectedScheduleOption}-welcome-message`}
                        label = {getValue(welcomeMessageToggleLabelRemoteConfigId)}
                    />
                    {
                        (onCallOptionGroup.onCallOption === OnCallOption.LiveAnswer || onCallOptionGroup.onCallOption === OnCallOption.LiveAnswerPlus) && (
                            <Typography variant='body2' className={classes.liveAnswerWelcomeMessageText} remoteConfigID='configure_number_welcome_message_live_answer_text'/>
                        )
                    }
                    {
                        onCallOptionGroup.onCallOption === OnCallOption.VirtualAnswer && (
                            <Typography variant='body2' className={classes.liveAnswerWelcomeMessageText} remoteConfigID='configure_number_welcome_message_virtual_answer_text'/>
                        )
                    }
                </div>
                {hasGreeting && !disableWelcomeSection
                    ? <AudioComponent2
                        data-test-id = 'call-welcome-message-section'
                        id = 'call-welcome-message'
                        greeting = {greeting}
                        showValidationErrors = {this.props.showValidationErrors}
                        onChange = {updateWelcomeMessage}
                        textAreaInfoTipId = 'pro_tip_for_text_to_speach'
                        screenViewType = {screenViewType}
                        audioTypeNames = {{
                            [TTS.className]: getValue('configure_number_welcome_greeting_tts_text'),
                            [Recording.className]: getValue('configure_number_welcome_greeting_record_text'),
                            [UploadAudio.className]: getValue('configure_number_welcome_greeting_upload_text')
                        }}
                    />
                    : null
                }
            </Section>
        )
    }

    renderPlayAMenuSection = (): JSX.Element => {
        const selectedScheduleOption = this.props.selectedScheduleOption
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }

        const updateState = (stateName, value) => {
            onCallOptionGroup[stateName] = value
            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
            this.props.updateOnCallOptionGroups(onCallOptionGroups)
        }

        return (
            <PlayMenuSection
                classes={this.props.classes}
                users = {this.props.users}
                id = {selectedScheduleOption}
                showValidationErrors = {this.props.showValidationErrors && this.props.validationErrors[selectedScheduleOption]}
                menu = {onCallOptionGroup.menu}
                updateMenu = {menu => updateState('menu', menu)}
                companyExtension = {this.props.companyExtension}
                screenViewType = {this.props.screenViewType}
            />
        )
    }

    formatUserString = u => `${u.extension.id}-${u.first_name.replace(/\s/g, '')}-${u.last_name.replace(/\s/g, '')}`

    renderRingUsersSection = (): JSX.Element => {
        return (
            <RingUsersSection
                classes={this.props.classes}
                users = {this.props.users}
                formatUserString = {this.formatUserString}
                selectedScheduleOption = {this.props.selectedScheduleOption}
                onCallOptionGroups = {this.props.onCallOptionGroups}
                showValidationErrors = {this.props.showValidationErrors}
                updateOnCallOptionGroups = {this.props.updateOnCallOptionGroups}
                screenViewType = {this.props.screenViewType}
                companyExtension = {this.props.companyExtension}
            />
        )
    }

    renderAssignVoicemailSection = (): JSX.Element => {
        const { classes, screenViewType, users, showValidationErrors, companyExtension } = this.props
        const selectedScheduleOption = this.props.selectedScheduleOption
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        const isForward = onCallOptionGroup.onCallOption === OnCallOption.Forward
        const remoteConfigID = isForward ? 'configure_number_forward_no_answer_header_text' : 'configure_number_voicemail_header_text'
        const dataTestId = isForward ? 'no-answer-section' : 'voicemail-section'
        return (
            <Section
                screenViewType = {screenViewType}
                classes = {classes}
                icon = {isForward ? <NoAnswerIcon/> : <VoicemailIcon/>}
                remoteConfigID = {remoteConfigID}
                data-test-id = {dataTestId}
            >
                <Typography classes={{ root: 'question' }} remoteConfigID='configure_number_no_answer_message' variant='subtitle1'/>
                <span className='question'>
                    <UsersDropdown
                        users = {users}
                        selectedValue = {onCallOptionGroup.assignVoicemailUser}
                        onChange = {event => {
                            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: 'assignVoicemailUser;user-click' }) // eslint-disable-line
                            onCallOptionGroup.assignVoicemailUser = event.target.value
                            onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                            this.props.updateOnCallOptionGroups(onCallOptionGroups)
                        }}
                        companyExtension = {companyExtension}
                        showValidationErrors = {showValidationErrors}
                        dataTestId = {`${dataTestId}-user-select`}
                    />
                </span>
            </Section>
        )
    }

    renderForwardExternalSection = (): JSX.Element => {
        return (
            <ForwardExternalSection
                key={this.props.selectedScheduleOption}
                classes={this.props.classes}
                users = {this.props.users}
                formatUserString = {this.formatUserString}
                selectedScheduleOption = {this.props.selectedScheduleOption}
                onCallOptionGroups = {this.props.onCallOptionGroups}
                showValidationErrors = {this.props.showValidationErrors}
                updateOnCallOptionGroups = {this.props.updateOnCallOptionGroups}
                screenViewType = {this.props.screenViewType}
                companyExtension = {this.props.companyExtension}
            />
        )
    }
    // if you delete this, delete <LiveReceptionistSection /> component
    // renderLiveReceptionstSection = (receptionistType: string): JSX.Element => {
    //     return (
    //         <LiveReceptionistSection
    //             key={this.props.selectedScheduleOption}
    //             classes={this.props.classes}
    //             users = {this.props.users}
    //             phoneNumber = {this.props.phoneNumber}
    //             formatUserString = {this.formatUserString}
    //             selectedScheduleOption = {this.props.selectedScheduleOption}
    //             onCallOptionGroups = {this.props.onCallOptionGroups}
    //             showValidationErrors = {this.props.showValidationErrors}
    //             updateOnCallOptionGroups = {this.props.updateOnCallOptionGroups}
    //             updateLiveAnswerConfiguration = {this.props.updateLiveAnswerConfiguration}
    //             liveAnswerConfiguration = {this.props.liveAnswerConfiguration}
    //             lrModalOpen = {this.props.lrModalOpen}
    //             collapseLRModal = {this.props.collapseLRModal}
    //             existingLRNotes = {this.props.existingLRNotes}
    //             existingLRPlanId = {this.props.existingLRPlanId}
    //             scriptId = {this.props.scriptId}
    //             origin = {this.props.origin}
    //             screenViewType = {this.props.screenViewType}
    //             companyExtension = {this.props.companyExtension}
    //             receptionistOption = {this.props.receptionistOption}
    //             updateReceptionistOption = {this.props.updateReceptionistOption}
    //             contactedExpert = {this.props.contactedExpert}
    //             setContactedExpert = {contactedExpert => this.props.setContactedExpert(contactedExpert)}
    //             receptionistType = {receptionistType}
    //         />
    //     )
    // }

    renderNoAnswerSection = (props): JSX.Element => {
        return (
            <NoAnswerSection {...props} />
        )
    }

    render = (): JSX.Element => {
        const {
            classes,
            useSchedules,
            schedules,
            isOpen24Schedule,
            selectedScheduleOption,
            renderOnCallSection,
            switchSelectedScheduleOption,
            showValidationErrors,
            validationErrors,
            screenViewType,
            updateOnCallOptionGroups
        } = this.props
        const scheduleOptions = Object.values(ScheduleOption)
        const onCallOptionGroups = { ...this.props.onCallOptionGroups }
        const onCallOptionGroup = { ...onCallOptionGroups[selectedScheduleOption] }
        const notSameAs = !onCallOptionGroup.sameAsClosedHours
        const activeSchedules = schedules.filter(s => s.status === 'on')
        const hasCustomSchedules = activeSchedules.length > 1 || (activeSchedules.length === 1 && !isOpen24Schedule(activeSchedules))
        const showSameAsAfterHours = [ScheduleOption.LunchBreak, ScheduleOption.Holidays].includes(selectedScheduleOption) && schedules?.length
        const { isMobileView } = screenViewType
        const mobileClass = isMobileView ? 'mobile' : ''
        return (
            <>
                {hasCustomSchedules && useSchedules
                    ? <Tabs
                        classes = {{ root: `${classes.scheduleTabs} ${showSameAsAfterHours ? 'less-margin' : ''} ${mobileClass}` }}
                        value = {selectedScheduleOption}
                        onChange = {(event, value) => {
                            // eslint-disable-next-line
                            gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `schedule-tab-click;${selectedScheduleOption}`, PDC_Value: value !== selectedScheduleOption ? GtmMap.OtherOption : GtmMap.SameOption })
                            switchSelectedScheduleOption(value)
                        }}
                        theme = 'secondary'
                    >
                        {scheduleOptions.map(o => {
                            if (!activeSchedules.find(s => s.name === o) && (o !== ScheduleOption.ClosedHours)) return null
                            const error = Boolean(showValidationErrors && validationErrors[o])
                            const Icon = tabIcons[o]
                            const remoteConfigTabId = `configure_number_${o.split('-').join('_')}_tab_text`
                            const label = (
                                <div className={classes.tabLabel}>
                                    <Icon/>
                                    <span className='tab-name'>{getValue(remoteConfigTabId)}</span>
                                </div>
                            )
                            return (
                                <Tab
                                    theme = 'secondary'
                                    key = {o}
                                    data-test-id = {o}
                                    value = {o}
                                    label = {label}
                                    isMobileView = {isMobileView}
                                    hasNotification = {error}
                                />
                            )
                        })}
                    </Tabs>
                    : null
                }
                {showSameAsAfterHours
                    ? <>
                        <div className={classes.sameAsAfterHours}>
                            <Switch
                                checked = {onCallOptionGroup.sameAsClosedHours}
                                onChange = {() => {
                                    onCallOptionGroup.sameAsClosedHours = !onCallOptionGroup.sameAsClosedHours
                                    onCallOptionGroups[selectedScheduleOption] = onCallOptionGroup
                                    updateOnCallOptionGroups(onCallOptionGroups)
                                }}
                                name = 'same-as-closed-hours'
                                value = 'same-as-closed-hours'
                                label = {getValue('configure_number_same_as_after_hours_text')}
                            />
                        </div>
                        {!onCallOptionGroup.sameAsClosedHours ? <hr className={classes.sameAsBorder}/> : null}
                    </>
                    : null
                }
                {notSameAs ? renderOnCallSection() : null}
                {notSameAs ? this.renderWelcomeMessageSection() : null}
                {notSameAs && onCallOptionGroup.onCallOption === OnCallOption.Menu ? this.renderPlayAMenuSection() : null}
                {notSameAs && onCallOptionGroup.onCallOption === OnCallOption.Forward ? this.renderRingUsersSection() : null}
                {notSameAs && onCallOptionGroup.onCallOption === OnCallOption.Voicemail ? this.renderAssignVoicemailSection() : null}
                {notSameAs && onCallOptionGroup.onCallOption === OnCallOption.ForwardExternal ? this.renderForwardExternalSection() : null}
                {notSameAs && onCallOptionGroup.onCallOption !== '' && onCallOptionGroup.onCallOption !== OnCallOption.VirtualAnswer ? this.renderNoAnswerSection(this.props) : null}
                {/* {notSameAs && onCallOptionGroup.onCallOption === OnCallOption.LiveAnswer ? this.renderLiveReceptionstSection('live') : null} */}
                {/* {notSameAs && onCallOptionGroup.onCallOption === OnCallOption.VirtualAnswer ? this.renderLiveReceptionstSection('virtual') : null} */}
            </>
        )
    }
}

export default withStyles(styles)(SchedulesRules)
