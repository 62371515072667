// React
import React from "react";

import { PriceBreakdownTooltip } from 'tooltips'

// Stylesheet
import "./modal.css";

// Actions/Buttons
class Modal extends React.Component {

	confirm = () =>{
		this.setState({
			disablePurchase:true,
			purchaseButtonClass:'btn disabled'
		})
		this.props.toggleSummaryModal()
		this.props.purchasePhoneNumbers(this.props.reservedNumbers)
	}

	render() {
		this.state = {
			disablePurchase:false,
			purchaseButtonClass:'btn'
		}
		const chargesByDid = this.props.pricingSummary.charges_by_did
		let didPricingRows = []
		let taxDetails = {}
		let feeDetails = {}
		let taxesAndFeesTotal = 0
		let totalToday = 0
		let didPricingTooltipRows = []
		Object.keys(chargesByDid).forEach(did => {
			let fees = chargesByDid[did].details.fees
			let taxes = chargesByDid[did].details.taxes
			let numberPrice = chargesByDid[did].details.price
			let formatedNumberPrice = '$' + (numberPrice/100).toFixed(2)
			let formatedDid = this.props.prettyFormat(did)
			let didRow =	<div key={did} className='modal-row'>
								<div className='left-column'>
									{formatedDid} - Purchase Fee
								</div>
								<div className='right-column'>
									{formatedNumberPrice}
								</div>
							</div>
			// Fee breakdown
			let feeTypes = Object.keys(fees.fee_breakdown)
			feeTypes.forEach(feeType => {
				if (feeDetails[feeType]) {
					feeDetails[feeType] += fees.fee_breakdown[feeType]
				} else {
					feeDetails[feeType] = fees.fee_breakdown[feeType]
				}
			})
			// Tax breakdown
			let taxTypes = Object.keys(taxes.tax_breakdown)
			taxTypes.forEach(taxType => {
				if (taxDetails[taxType]) {
					taxDetails[taxType] += taxes.tax_breakdown[taxType]
				} else {
					taxDetails[taxType] = taxes.tax_breakdown[taxType]
				}

			})


			taxesAndFeesTotal += fees.fee_total + taxes.tax_total
			totalToday += chargesByDid[did].total 
			didPricingRows.push(didRow)
		});
		Object.keys(feeDetails).forEach(feeType => {
			let feeDetailRow = <div key={feeType} className='modal-row'>
								<div className='left-column tooltip'>
									{feeType}
								</div>
								<div className='right-column'>
									${(feeDetails[feeType]/100).toFixed(2)}
								</div>
							</div>
			
			didPricingTooltipRows.push(feeDetailRow)
		})

		Object.keys(taxDetails).forEach(taxType => {
			let taxDetailRow = <div key={taxType} className='modal-row'>
								<div className='left-column tooltip'>
									{taxType}
								</div>
								<div className='right-column'>
									${(taxDetails[taxType]/100).toFixed(2)}
								</div>
							</div>
			
			didPricingTooltipRows.push(taxDetailRow)
		})

		let taxesAndFeesTooltip = ''
		let tooltipClass = ''
		if ((taxesAndFeesTotal/100).toFixed(2) != '0.00') {
			tooltipClass = '.info'
			taxesAndFeesTooltip = <PriceBreakdownTooltip title={
					<React.Fragment>
						{didPricingTooltipRows}
					</React.Fragment>
				} placement='bottom'><span className='tooltip-icon'>&#9432;</span>
			</PriceBreakdownTooltip>
		}

		let taxesAndFeesRow = <div key='taxesAndFeesRow' className='modal-row taxes-and-fees {tooltipClass}'>
			<div className='left-column'>
				Taxes and fees
			</div>
			<div className='right-column'>
				${(taxesAndFeesTotal/100).toFixed(2)} {taxesAndFeesTooltip}
			</div>
		</div>
		let dailyTodalRow = <div key='totalTodayRow' className='modal-row total-today'>
			<div className='left-column'>
			</div>
			<div className='right-column'>
				<span>Today: </span>${(totalToday/100).toFixed(2)}
			</div>
		</div>
		didPricingRows.push(taxesAndFeesRow)
		didPricingRows.push(dailyTodalRow)


		return (
			<div id="myModal" className="summary-modal" onClick={(e) => e.stopPropagation()}>
				<div className="summary-modal-content">
					<div className="summary-modal-header">
						<span>Confirm Changes</span>
					</div>
					<div className="summary-modal-body">
						<div className='modal-row'>
							<div className='row-header'>Charge Today</div>
						</div>
						{didPricingRows}
					</div>
					<div className="summary-modal-footer">
						<div className='modal-disclaimer'>
							Your primary card on file will be charged today for the total shown above.
							If you exceeded your plan number allocation, 
							you will be also charged a prorated amount in our overnight billing, 
							and full amount at your next billing period.
							
						</div>
						<div className="modal-buttons">
							<button
								className='btn cancel'
								id="cancel_button"
								onClick={() => {
									this.props.toggleSummaryModal();
								}}
							>
								Cancel
							</button>
							<button
								className={this.state.purchaseButtonClass}
								id="purchase_button"
								disabled={this.state.disablePurchase}
								onClick={() => {
									this.confirm();
								}}
							>
								Confirm
							</button>
						</div>

					</div>
				</div>
			</div>
		);
	}
}

export default Modal;
