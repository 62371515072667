import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5701%3A11731
 *
 * @param {SvgIconProps} props - svg props
 */
export const UploadIcon2 = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M0 14.6608C2.40924e-06 17.1737 1.93697 18 3.92255 18H8.6543V17.1648C7.44455 17.17 6.25089 17.1734 5.09044 17.1734C3.30113 17.2601 1.06812 17.0001 0.981381 14.6607C0.894644 12.3213 2.98391 12.0614 3.74723 12.0614C3.43002 11.5397 3.12268 10.1095 4.37177 9.20212C5.62086 8.29472 6.95916 8.94218 7.49449 9.20212C7.85137 7.55587 8.94454 4.93905 12.643 5.04678C16.3415 5.15452 17.7882 7.15122 17.4872 9.63534C19.6285 9.3754 22.9296 10.0686 22.9296 13.4861C22.9296 16.6535 20.1638 17.1214 19.0932 17.1214C17.7887 17.1214 16.4423 17.1258 15.0782 17.132V18H19.8962C21.2378 18 23.832 16.8765 23.993 13.6082C24.179 9.83203 20.6152 8.42699 18.4686 8.68233C18.6833 6.69071 16.595 4.00352 12.5801 4.00352C8.65439 3.89255 7.31608 6.42958 6.86998 7.90253C4.28259 7.20937 2.05207 9.20219 2.5874 11.3683C1.51675 11.455 -2.40883e-06 12.1479 0 14.6608Z' fill={color}/>
            <path d='M11.4626 11.7373C11.4626 11.4405 11.7032 11.2 12 11.2C12.2967 11.2 12.5373 11.4405 12.5373 11.7373V22.0626C12.5373 22.3594 12.2967 22.5999 12 22.5999C11.7032 22.5999 11.4626 22.3594 11.4626 22.0626V11.7373Z' fill='url(#paint0_linear_5701_11731)'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M8.46091 14.5909C8.14196 14.2913 8.13032 13.7885 8.43506 13.4744L11.2828 10.5397C11.6757 10.1349 12.3254 10.1349 12.7182 10.5398L15.5651 13.4744C15.8698 13.7885 15.8581 14.2913 15.5392 14.5909C15.2271 14.884 14.7374 14.8723 14.4397 14.5645L12.1328 12.1797C12.0602 12.1053 11.9398 12.1053 11.8672 12.1797L9.56029 14.5646C9.26261 14.8723 8.77298 14.8841 8.46091 14.5909Z' fill={color}/>
            <defs>
                <linearGradient id='paint0_linear_5701_11731' x1='12' y1='11.2' x2='12' y2='22.7781' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.213051' stopColor={color}/>
                    <stop offset='1' stopColor={color} stopOpacity='0'/>
                </linearGradient>
            </defs>
        </SvgIcon>
    )
}

export default UploadIcon2
