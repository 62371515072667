/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext } from 'react'
import Session from 'phoenix-api-js-client'
import { get_phoenix_session } from 'phoenix-session-helpers'

const session = get_phoenix_session()

interface PhoenixSessionContextProps {
  session: Session
}

/**
 *
 */
export const PhoenixSessionContext = createContext<PhoenixSessionContextProps>({
    session
})

/**
 *
 */
export const PhoenixSessionProvider: React.FC = ({ children }) => {
    return (
        <PhoenixSessionContext.Provider value={{ session }}>
            {children}
        </PhoenixSessionContext.Provider>
    )
}
