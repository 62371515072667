<template>
	<div id="create-payment-method">
		<w-loader v-if="p_method.loading" />
		<div class="relative" v-else>
			<BackButton v-if="!prevent_back"/>
			<InvalidFormTitle
				v-if="(p_method.alert && p_method.alert.level === 'error') || Object.values(elements).find((x) => x.error)"
				class="mt-n4 text-center"
			/>
			<!-- <InvalidFormTitle class="mt-n4 text-center" /> -->
			<w-alert
				v-if="p_method.alert"
				:level="p_method.alert.level"
				:message="p_method.alert.message"
				@closed="p_method.alert = null"
				class="mb-11"
				ref="alert"
			/>
			<w-form
				v-if="!p_method.alert || (p_method.alert && p_method.alert.status_code !== 201)"
				v-model="is_form_valid"
				@submit.prevent="submitted"
				ref="form"
				class="create-page create-payment-method-form"
				data-discard="true"
				data-test-id="payment-methods-create-page"
			>
				<div class="w-h6 info--text text--darken-1 main-title">
					{{ l.t('p-methods.add-new-payment-method', 'Add new payment method') }}
				</div>
				<div class="w-body-1 main-subtitle">
					{{ l.t('p-methods.credit-debit-card', 'Credit or Debit Card') }}
				</div>
				<w-text-field
					:disabled="custom_fields_disabled"
					v-model="nickname"
					:placeholder="l.t('p-methods.card-nickname', 'Card nickname')"
					class="stripe-field fs-exclude mb-4 w-100"
					data-test-id="nickname-input"
				></w-text-field>
				<div class="name-fields d-flex mb-4">
					<w-text-field
						:disabled="custom_fields_disabled"
						v-model="additional_options.first_name"
						:placeholder="l.t('app.first-name', 'First name')"
						class="stripe-field fs-exclude first-name"
						data-test-id="first-name-input"
					/>
					<w-text-field
						:disabled="custom_fields_disabled"
						v-model="additional_options.last_name"
						:placeholder="l.t('app.last-name', 'Last name')"
						class="stripe-field fs-exclude last-name"
						data-test-id="last-name-input"
					/>
				</div>
				<AddressForm
					v-model="additional_options.address"
					:disabled="custom_fields_disabled"
					:available_countries="['US', 'CA']"
					class="mb-5"
					data-test-id="address-input"
				/>
				<div id="stripe-card-number" class="stripe-field fs-exclude"></div>
				<FieldValidationError v-if="elements.number.error" class="mt-2">
					{{ elements.number.error }}
				</FieldValidationError>
				<div class="d-flex mt-5">
					<div class="w-100 mr-2">
						<div id="stripe-card-exp" class="stripe-field fs-exclude"></div>
						<FieldValidationError v-if="elements.exp.error" class="mt-2">
							{{ elements.exp.error }}
						</FieldValidationError>
					</div>
					<div class="w-100 mr-2">
						<div id="stripe-card-cvc" class="w-100 ml-2 stripe-field fs-exclude"></div>
						<FieldValidationError v-if="elements.cvc.error" class="mt-2">
							{{ elements.cvc.error }}
						</FieldValidationError>
					</div>
				</div>
				<!-- <div id="stripe-address" class="stripe-field fs-exclude mt-5"></div>
				<FieldValidationError v-if="elements.address.error" class="mt-2">
					{{ elements.address.error }}
				</FieldValidationError> -->
				<div class="error" role="alert">
					<span class="message"></span>
				</div>
				<w-checkbox
					v-model="primary"
					:disabled="p_method.processing_card"
					@click="primary_on_click"
					:key="primary_render_key"
					data-test-id="cc-set-as-primary"
					class="mt-4"
				>
					<template v-slot:label>
						<span class="text--text">{{l.t('app.set-as-primary-method', 'Set as primary payment method')}}</span>
					</template>
				</w-checkbox>
				<DefaultModal v-model="show_confirmation" max-width="350" @close="show_confirmation = false" data-test-id="confirm-primary-method-modal">
					{{l.t('p-methods.you-are-changing-primary', 'You’re changing your primary payment method. Would you like to continue?')}}
					<template v-slot:buttons>
					<w-btn @click="show_confirmation = false" color="secondary" data-test-id="cancel-btn">{{l.t('app.cancel', 'Cancel')}}</w-btn>
						<w-btn @click="confirmed" color="primary" data-test-id="confirm-btn">{{l.t('app.yes', 'Yes')}}</w-btn>
					</template>
				</DefaultModal>
				<w-btn
					color="primary"
					type="submit"
					class="mt-7"
					:disabled="!may_submit || p_method.processing_card || !is_form_valid"
					data-test-id="submit-btn"
				>
					{{ l.t('p-methods.add-payment-method', 'Add payment method') }}
				</w-btn>
			</w-form>
		</div>
	</div>
</template>

<script>
import {vueComponent, specialKeys} from 'helpers';
import l from '../../../libs/lang';
import BackButton from '../../elements/BackButton.vue';
import PaymentMethod from '../../../models/PaymentMethod';
import AddressForm from '../../elements/form/AddressForm.vue';
import DefaultModal from '../../elements/modal/DefaultModal.vue';
import InvalidFormTitle from '../../elements/form/InvalidFormTitle.vue';
import FieldValidationError from '../../elements/form/FieldValidationError.vue';

const additional_options = {
	first_name: '',
	last_name: '',
	address: {
		line_1: '',
		line_2: '',
		city: '',
		province: '',
		postal_code: '',
		country: 'US',
	},
};

export default {
	props: {
		prevent_back: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		BackButton,
		AddressForm,
		DefaultModal,
		InvalidFormTitle,
		FieldValidationError,
	},
    data() {
      return {
		l,
		primary_render_key: Date.now(),
		custom_fields_disabled: false,
		nickname: null,
		additional_options: JSON.parse(JSON.stringify(additional_options)),
		is_form_valid: false,
		elements: {
			number: {
				el: null,
				stripe_el: 'cardNumber',
				id: '#stripe-card-number',
				error: null,
				is_complete: false,
				conf: {
					placeholder: l.t('app.card-number', 'Card number'),
				}
			},
			exp: {
				el: null,
				stripe_el: 'cardExpiry',
				id: '#stripe-card-exp',
				error: null,
				is_complete: false,
			},
			cvc: {
				el: null,
				stripe_el: 'cardCvc',
				id: '#stripe-card-cvc',
				error: null,
				is_complete: false,
			},
			// address: {
			// 	el: null,
			// 	stripe_el: 'address',
			// 	id: '#stripe-address',
			// 	error: null,
			// 	is_complete: false,
			// 	conf: {
			// 		mode: 'billing',
			// 		defaultValues: {
			// 			name: '',
			// 			address: {
			// 				line1: '',
			// 				line2: '',
			// 				city: '',
			// 				state: '',
			// 				postal_code: '',
			// 				country: 'US',
			// 			},
			// 		},
			// 	},
			// },
		},
		may_submit: false,
		primary: false,
		always_allowed: specialKeys,
		p_method: new PaymentMethod(this.$session, vueComponent(this)),
		num_regex: /^-?\d+$/,
		show_confirmation: false,
      };
	},
	async mounted() {
		await this.$data.p_method.load_stripe();
		if (this.$data.p_method.alert) return false;
		const appearance = {
			theme: 'none',
		};
		const element_styles = {
			base: {
				fontWeight: 500,
				fontFamily: 'Montserrat, sans-serif',
				fontSize: '12px',
				letterSpacing: '0px',
				fontSmoothing: 'antialiased',
				':focus': {
					color: this.$vuetify.theme.themes.light.text.base,
				},
				'::placeholder': {
					color: this.$vuetify.theme.themes.light.secondary.base,
				},
				':focus::placeholder': {
					color: this.$vuetify.theme.themes.light.secondary.base,
				},
				lineHeight: '44px',
			},
			invalid: {
				'::placeholder': {
					color: this.$vuetify.theme.themes.light.secondary.base,
				},
			},
		};

		const element_classes = {
			base: 'stripe-field-base',
			focus: 'stripe-field-focus',
			invalid: 'stripe-field-invalid',
		};
		const elements = this.$data.p_method.stripe.elements({
			appearance,
			locale: l.detect(),
			fonts: [{
				family: 'Montserrat',
				cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:500',
				weight: 500
			}]
		});
		for (const key of Object.keys(this.$data.elements)) {
			let conf = {
				style: element_styles,
				classes: element_classes,
			};

			if (this.$data.elements[key].conf && typeof this.$data.elements[key].conf === 'object') {
				conf = {
					...conf,
					...this.$data.elements[key].conf,
				};
			}
			this.$data.elements[key].el = elements.create(this.$data.elements[key].stripe_el, conf);
		}
		for (const key of Object.keys(this.$data.elements)) {
			this.$data.elements[key].el.mount(this.$data.elements[key].id);
		}
		for (const key of Object.keys(this.$data.elements)) {
			this.$data.elements[key].el.on('change', async (e) => {
				this.handle_stripe_error(e, key);
			});
		}

		return true;
	},
	methods: {
		confirmed() {
			this.$data.show_confirmation = false;
			setTimeout(() => {
				this.$data.primary = true;
				this.$data.primary_render_key = Date.now();
			}, 1);
		},
		primary_on_click() {
			if (this.$data.primary) {
				this.$data.show_confirmation = true;
			}
		},
		handle_stripe_error(err, field) {
			const { error } = err;
			this.$data.p_method.alert = null;
			this.$data.elements[field].error = error ? error.message : '';
			this.$data.elements[field].is_complete = err.complete;
			const may_submit = Object.values(this.$data.elements).every((x) => !x.error && x.is_complete);
			this.$data.may_submit = may_submit;
		},
		update_fields(conf) {
			this.custom_fields_disabled = conf.disabled;
			for (const key of Object.keys(this.$data.elements)) {
				this.$data.elements[key].el.update(conf);
			}
		},
		clear_fields() {
			for (const key of Object.keys(this.$data.elements)) {
				this.$data.elements[key].el.clear();
			}
			this.$data.nickname = null;
			this.$data.additional_options = JSON.parse(JSON.stringify(additional_options));
		},
		generate_additionl_options() {
			const options = {
				name: `${this.$data.additional_options.first_name} ${this.$data.additional_options.last_name}`,
			};
			for (const [a_key, value] of Object.entries(this.$data.additional_options.address)) {
				let key;
				if (a_key === 'province') {
					key = 'state';
				} else if (a_key === 'postal_code') {
					key = 'zip';
				} else {
					key = a_key.replace('_', '');
				}
				options[`address_${key}`] = value || '';
			}
			return options;
		},
		async submitted() {
			this.update_fields({disabled: true});
			const options = this.generate_additionl_options();
			await this.$data.p_method.create(this.$data.elements.number.el, options, this.$data.primary, this.$data.nickname);
			this.update_fields({disabled: false});
			if (this.$data.p_method.alert.level === 'success') this.clear_fields();
			if (this.$refs.alert) {
				const modal = this.$refs.alert.$el.closest('.default-modal-content');
				if (modal) {
					modal.scrollIntoView({behavior: 'smooth'})
				}
			}
		},
    },
	watch: {
		show_confirmation(val) {
			if (!val) {
				this.$data.primary = false;
				this.$data.primary_render_key = Date.now();
			}
		},
	}
};
</script>
