/* eslint-disable jsdoc/require-param-type */
/* eslint-disable jsdoc/require-description */
import themes from './themes'

/**
 * branding is determined based on user or url
 * if user is present, the priority is the following:
 * 1. voip_id
 * 2. branding_id
 *
 * if not present, we'll try to match url
 * if there is no match, default branding will be chosen
 *
 * available options
    code: String => has to be unique,
    theme: String => default theme based on code, if not available, default theme will be chosen,
    icon: String => if not provide, it will be chosed based on code,
    voip_ids: Array => array of voip_ids for specific branding,
    branding_id: Number => branding_id for a user,
    excluded_routes: Array => applicable only if routes prop is missing, array of routes that will be filtered from default routes
    urls: Array => array of urls that match specific brancing,
    routes: Array => list of available routes, if present, excluded routes wont be applied
    mobile: Object => link to apps (props ios and android)
 */

class Branding {
    /**
     * @param session
     */
    constructor (session) {
        this.session = session

        const brandings = [
            {
                code: 'avo',
                branding_id: 773,
                voip_ids: [],
                excluded_routes: [
                    'advanced-tools',
                    'api-requests.create',
                    'api-requests.index',
                    'api-requests.show',
                    'applications.index',
                    'applications.create',
                    'applications.show',
                    'billing.index',
                    'billing.statement',
                    'billing.payment-methods',
                    'billing.payment-methods-create',
                    'conferencing',
                    'devices.buy',
                    'video.index',
                    'video.users.index',
                    'video.users.create',
                    'video.users.schedules.index',
                    'video.users.schedules.create',
                    'video.users.schedules.show'
                ]
            },
            {
                code: 'avo_master',
                theme: 'avo',
                icon: 'avo',
                voip_ids: [28262],
                excluded_routes: [
                    'advanced-tools',
                    'applications.index',
                    'applications.create',
                    'applications.show',
                    'api-requests.create',
                    'api-requests.index',
                    'api-requests.show',
                    'conferencing',
                    'devices.buy',
                    'video.index',
                    'video.users.index',
                    'video.users.create',
                    'video.users.schedules.index',
                    'video.users.schedules.create',
                    'video.users.schedules.show'
                ]
            },
            {
                code: 'lapro',
                urls: [
                    'reccp.pdc-partners.xyz',
                    'preprod.reccp.pdc-partners.xyz'
                ],
                routes: [
                    'homepage',
                    'call-logs',
                    'reports.index',
                    'reports.show',
                    'contacts.index',
                    'contacts.show',
                    'contacts.create',
                    'contacts.import',
                    'contacts.duplications',
                    'groups.index',
                    'groups.contacts',
                    'blockedCalls.index',
                    'blockedCalls.create',
                    'blockedCalls.recent'
                ]

            },
            {
                code: 'idseal',
                branding_id: 797,
                powered_by: true,
                icon: 'idseal'
            },
            {
                code: 'tailor',
                branding_id: 798,
                powered_by: true,
                icon: 'tailor'
            },
            {
                code: 'ringboost',
                urls: ['rb.pdc-partners.xyz']

            },
            {
                code: 'pfm',
                urls: ['pfm.pdc-partners.xyz']

            }
        ]
        const default_brand = {
            code: 'default',
            icon: 'default',
            theme: themes.default,
            mobile: {
                ios: 'https://apps.apple.com/us/app/phone-com/id727705029',
                android:
            'https://play.google.com/store/apps/details?id=com.phone.app&hl=en'
            },
            routes: [
                'homepage',
                'phone-numbers.index',
                'phone-numbers-page',
                'phone-numbers.show',
                'phone-numbers.delete-csv',
                'phone-numbers.call-flow',
                'phone-numbers.make-call',
                'phone-numbers.create',
                'phone-numbers.wizard',
                'phone-numbers.visualizer',
                'phone-numbers.visualizer-default',
                'extensions.create',
                'extensions.index',
                'extensions.show',
                'extensions.call-flow',
                'extensions.type',
                'extensions.visualizer',
                'extensions.visualizer-default',
                'billing.index',
                'billing.payment-methods',
                'billing.payment-methods-create',
                'billing.statement',
                'devices.create',
                'devices.index',
                'devices.show',
                'devices.buy',
                'devices.my-orders',
                'devices.my-orders.confirm',
                'devices.my-orders.show',
                'contacts.index',
                'contacts.show',
                'contacts.create',
                'contacts.import',
                'contacts.duplications',
                'groups.index',
                'groups.contacts',
                'conversations',
                'messages.index',
                'messages.create',
                'voicemail',
                'fax.index',
                'fax.create',
                'mobile-apps',
                'conferencing',
                'queues.index',
                'queues.show',
                'queues.create',
                'menus.index',
                'menus.show',
                'menus.create',
                'schedules.index',
                'schedules.show',
                'schedules.create',
                'media.index',
                'media.hold-music',
                'media.create',
                'media.show',
                'media.music-on-hold-show',
                'media.loader',
                'video.index',
                'video.users.index',
                'video.users.create',
                'video.users.schedules.index',
                'video.users.schedules.create',
                'video.users.schedules.show',
                'routes.index',
                'routes.create',
                'routes.show',
                'blockedCalls.index',
                'blockedCalls.create',
                'blockedCalls.recent',
                'applications.index',
                'applications.create',
                'applications.show',
                'subaccounts.index',
                'subaccounts.create',
                'account.settings',
                'account.contacts',
                'trunks.index',
                'trunks.create',
                'trunks.show',
                'call-logs',
                'reports.index',
                'reports.show',
                'live-answer.index',
                'live-answer.create',
                'live-answer.show',
                'advanced-tools',
                'api-requests.index',
                'api-requests.show',
                'api-requests.create',
                'not-found'
            ]
        }
        for (let i = 0; i < brandings.length; i++) {
            const theme_code = brandings[i].theme || brandings[i].code
            brandings[i].theme = themes[theme_code] || themes.default
            if (brandings[i].excluded_routes && brandings[i].excluded_routes.length) {
                let routes = brandings[i].routes || default_brand.routes
                routes = routes.filter((x) => !brandings[i].excluded_routes.includes(x))
                brandings[i].routes = routes
            }
            brandings[i].icon = brandings[i].icon || brandings[i].code
            brandings[i] = {
                ...default_brand,
                ...brandings[i]
            }
        }
        brandings.push(default_brand)
        this.brandings = brandings
        this._data = null
    }

    /**
     *
     */
    get data () {
        if (this._data) return this._data
        if (this.session && this.session.user) {
            const voip_id_match = this.brandings.find((x) => x.voip_ids && x.voip_ids.length && x.voip_ids.includes(this.session.user.id))
            if (voip_id_match) {
                this._data = voip_id_match
            } else if (this.session.user.account && this.session.user.account.branding_id) {
                const branding_id_match = this.brandings.find((x) => x.branding_id && x.branding_id === this.session.user.account.branding_id)
                if (branding_id_match) {
                    this._data = branding_id_match
                }
            }
        }
        if (!this._data) {
            const { hostname } = document.location
            const url_match = this.brandings.find((x) => x.urls && x.urls.includes(hostname))
            if (url_match) {
                this._data = url_match
            }
        }
        if (!this._data) {
            this._data = this.brandings.find((x) => x.code === 'default')
        }
        return this._data
    }
}

export default Branding
