import React from 'react'


let NetworkInterceptor = (func) => {
    let oldXHROpen = window.XMLHttpRequest.prototype.open;
    window.XMLHttpRequest.prototype.open = function (method, url, async, user, password) {
        this.addEventListener('load', ()=>{func(this)});

        return oldXHROpen.apply(this, arguments);
    }
};



export {NetworkInterceptor}