// React
import React from "react";

// Stylesheet
import "./region-search.css";

class RegionSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_free: this.props.is_free,
      is_tollfree: this.props.is_tollfree,
      is_local: this.props.is_local,
      areacode: [],
      city: [],
      adropdown: "hidden",
      dropdown: "hidden"
    };
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target) || this.node2.contains(e.target)) {
      return;
    } else {
      this.setState({
        adropdown: "hidden",
        dropdown: "hidden"
      });
    }
  };

  // Handle area code input change
  checkInput(e) {
    if (/[^0-9]/.test(e.target.value)) {
      e.preventDefault();
      return;
    } else if (e.target.value.length <= 0) {
      this.setState({
        city: [],
        areacode: [],
        adropdown: "hidden",
        dropdown: "hidden"
      });
      this.props.AreaCodeSearch(e.target.value);
      //return;
    } else if (e.target.value.startsWith(0)) {
      this.setState({
        areacode: [],
        city: [],
        adropdown: "hidden"
      });
    } else if (e.target.value.startsWith(8)) {
      this.setState({
        areacode: [],
        city: [],
        adropdown: "hidden"
      });
      this.props.AreaCodeSearch(e.target.value);
    } else {
      this.setState({
        areacode: e.target.value,
        city: [],
        adropdown: "show"
      });
      this.props.AreaCodeSearch(e.target.value);
    }
  }

  checkInput2(e) {
    this.setState({
      city: e.target.value
    });
    if (e.target.value.length >= 3) {
      this.props.CitySearch(e.target.value);
      this.setState({
        dropdown: "show"
      });
    } else {
      this.setState({
        dropdown: "hidden"
      });
    }
  }

  selectCity(city) {
    this.setState({
      areacode: [],
      city: city.city + ", " + city.state + " (" + city.area_code + ")",
      dropdown: "hidden"
    });
    this.props.CityNumberSearch(city.area_code);
  }

  selectAreaCode(code) {
    this.setState({
      city: [],
      areacode: code,
      adropdown: "hidden"
    });
    this.props.CityNumberSearch(code);
  }

  // Region Search
  render() {
    return (
      <div className="region-search">
        <div className="form-group">
          <label>Enter an area code or city:</label>
          <div className="region-filters">
            <form autoComplete="new-city">
              <input
                type="text"
                id="areacode"
                maxLength="5"
                placeholder="Area Code"
                value={this.state.areacode}
                className="form-control"
                onChange={e => this.checkInput(e)}
                autoComplete="off"
              />
              <input
                type="text"
                id="city"
                placeholder="City"
                value={this.state.city}
                className="form-control"
                onChange={e => this.checkInput2(e)}
                autoComplete="off"
              />
            </form>
            <div
              id="cities"
              className={this.state.dropdown}
              ref={node => (this.node = node)}
            >
              {this.props.cities.slice(0, 15).map((city, index) => {
                return (
                  <div
                    className="city"
                    key={index}
                    onClick={() => {
                      this.selectCity(city);
                    }}
                  >
                    {city.city}, {city.state}
                    <span className="zip">({city.area_code})</span>
                  </div>
                );
              })}
            </div>
            <div
              id="area_codes"
              className={this.state.adropdown}
              ref={node2 => (this.node2 = node2)}
            >
              {this.props.area_codes.slice(0, 15).map((area_code, index) => {
                return (
                  <div
                    className="city"
                    key={index}
                    onClick={() => {
                      this.selectAreaCode(area_code);
                    }}
                  >
                    {area_code}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RegionSearch;
