import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/JQacodb8V4YdmDt3Awk2zl/Call-handling?node-id=2529%3A10211
 *
 * @param {SvgIconProps} props - svg props
 */
export const MenuOptionsIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#2A9BB7'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M25.0417 15.875C25.0417 13.85 23.4 12.2083 21.375 12.2083C19.35 12.2083 17.7083 13.85 17.7083 15.875C17.7083 17.9001 19.35 19.5417 21.375 19.5417C23.4 19.5417 25.0417 17.9001 25.0417 15.875ZM23.4375 15.875C23.4375 14.7359 22.5141 13.8125 21.375 13.8125C20.2359 13.8125 19.3125 14.7359 19.3125 15.875C19.3125 17.0141 20.2359 17.9375 21.375 17.9375C22.5141 17.9375 23.4375 17.0141 23.4375 15.875ZM10.375 14.5C9.61561 14.5 9 15.1156 9 15.875C9 16.6344 9.61561 17.25 10.375 17.25H15.875C16.6344 17.25 17.25 16.6344 17.25 15.875C17.25 15.1156 16.6344 14.5 15.875 14.5H10.375ZM25.5 15.875C25.5 15.1156 26.1156 14.5 26.875 14.5H29.625C30.3844 14.5 31 15.1156 31 15.875C31 16.6344 30.3844 17.25 29.625 17.25H26.875C26.1156 17.25 25.5 16.6344 25.5 15.875ZM20.4583 24.125C20.4583 22.1 18.8167 20.4583 16.7917 20.4583C14.7666 20.4583 13.125 22.1 13.125 24.125C13.125 26.1501 14.7666 27.7917 16.7917 27.7917C18.8167 27.7917 20.4583 26.1501 20.4583 24.125ZM18.8542 24.125C18.8542 22.9859 17.9308 22.0625 16.7917 22.0625C15.6526 22.0625 14.7292 22.9859 14.7292 24.125C14.7292 25.2641 15.6526 26.1875 16.7917 26.1875C17.9308 26.1875 18.8542 25.2641 18.8542 24.125ZM10.375 22.75C9.61561 22.75 9 23.3656 9 24.125C9 24.8844 9.61561 25.5 10.375 25.5H11.2917C12.0511 25.5 12.6667 24.8844 12.6667 24.125C12.6667 23.3656 12.0511 22.75 11.2917 22.75H10.375ZM20.9167 24.125C20.9167 23.3656 21.5323 22.75 22.2917 22.75H29.625C30.3844 22.75 31 23.3656 31 24.125C31 24.8844 30.3844 25.5 29.625 25.5H22.2917C21.5323 25.5 20.9167 24.8844 20.9167 24.125Z' fill='white'/>
        </SvgIcon>
    )
}

export default MenuOptionsIcon
