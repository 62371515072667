import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const UserSetupIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#364047'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M28.4842 20.0655C29.5044 20.0833 30.3244 18.8587 30.3475 17.5358C30.3706 16.2129 29.5708 15.1781 28.444 15.1584C27.3171 15.1387 26.5136 16.0344 26.4905 17.3573C26.4674 18.6803 27.464 20.0477 28.4842 20.0655ZM28.8636 21.4773C29.4072 21.3414 29.6462 20.9378 29.7134 20.4058C29.9387 20.6265 30.1361 20.8095 30.3408 20.8864C30.5314 20.9579 30.6995 20.9973 30.8501 21.0325L30.8501 21.0325C31.6006 21.2082 31.9169 21.2823 32.409 24.7273C32.4182 25.7792 29.4545 25.9091 25.909 25.3182C24.5032 25.0839 24.398 24.478 24.12 22.877C24.0475 22.4591 23.9631 21.9734 23.8408 21.4088C24.2877 20.5667 24.9722 20.491 25.7387 20.4062C25.9857 20.3789 26.2411 20.3506 26.4999 20.2955C26.6795 20.2572 26.8921 20.1184 27.0972 19.9517C27.5041 20.9401 28.2478 21.6312 28.8636 21.4773Z' fill='white'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M21.5445 19.5685C23.6574 19.4655 25.1872 16.8612 25.055 14.1656C24.9228 11.4699 23.1274 9.47304 20.7935 9.58684C18.4596 9.70065 16.9192 11.6332 17.0514 14.3289C17.1837 17.0245 19.4315 19.6715 21.5445 19.5685ZM18.1189 19.6216C18.237 19.5426 18.3356 19.4766 18.4159 19.4395C18.8561 21.1416 20.5332 23.104 22.2516 22.994C23.5206 22.9126 23.9106 21.93 23.7893 19.8248C23.9288 19.8937 23.9573 19.9647 23.9878 20.0411C24.0178 20.1162 24.0499 20.1964 24.1915 20.2847C24.6851 20.5438 25.1156 20.7042 25.4952 20.8456C26.9668 21.3939 27.6734 21.6572 28.3308 26.2835C28.4532 27.1449 28.1928 28.0456 27.4974 28.5684C23.7009 31.4228 18.0998 29.5749 14.3986 28.3538C14.0422 28.2362 13.7035 28.1244 13.3856 28.0233C12.4715 27.7322 11.7806 26.8926 11.8317 25.9346C11.9615 23.5033 12.4384 22.3181 13.3196 21.2929C14.1375 20.3413 15.1655 20.3076 16.0564 20.2784C16.2113 20.2733 16.362 20.2683 16.5068 20.2587C17.239 20.2101 17.766 19.8576 18.1189 19.6216Z' fill='white'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M15.7081 14.7236C15.794 16.4747 14.8002 18.1664 13.4277 18.2333C12.0552 18.3002 10.595 16.5807 10.5091 14.8297C10.4232 13.0787 11.4238 11.8233 12.9399 11.7493C14.456 11.6754 15.6222 12.9726 15.7081 14.7236ZM11.4293 18.0252C11.3754 18.0501 11.3093 18.0943 11.23 18.1473C10.9933 18.3057 10.6398 18.5421 10.1486 18.5747C10.0515 18.5812 9.9504 18.5845 9.84651 18.5879C9.24891 18.6075 8.55931 18.6301 8.01065 19.2685C7.38638 19.9947 7.06455 20.8408 7.00011 22.6648C6.98318 23.1441 7.32637 23.5592 7.78521 23.699C8.07293 23.7867 8.39151 23.8918 8.73439 24.0049L8.73441 24.0049C9.34423 24.2061 10.0309 24.4326 10.7577 24.6316C10.9583 24.2 11.1216 23.8321 11.2618 23.5162C11.8601 22.1682 12.0382 21.7669 12.9015 21.4102C14.2471 20.8542 15.6372 21.1322 16.5159 21.4102L15.9962 24.9112C16.5373 24.7497 17.0525 24.5021 17.5257 24.1454C17.9889 23.7963 18.1625 23.1962 18.081 22.6219C17.6398 19.513 17.1658 19.3364 16.1781 18.9684C15.9235 18.8736 15.6347 18.766 15.3035 18.5921C15.2086 18.5329 15.187 18.4791 15.1669 18.4288C15.1464 18.3775 15.1273 18.3298 15.0338 18.2837C15.1151 19.6958 14.8535 20.355 14.0023 20.4095C12.8496 20.4834 11.7246 19.167 11.4293 18.0252Z' fill='white'/>
        </SvgIcon>
    )
}

export default UserSetupIcon
