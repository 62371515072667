// React
import React from "react";
import FilterButton from "../filter-button/filter-button";
import HoverBoxWrapper from "../hover-box-wrapper/hover-box-wrapper"
import NumberTypeSelector from "./number-type-selector/number-type-selector"

// Number Type Filter
class NumberTypeFilter extends React.Component {

  constructor(props) {
    super(props);
    this.initialState = {
      selectorOpen: false
    };
    this.state = JSON.parse(JSON.stringify(this.initialState));
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  // Hide NpaSelector if click away from component
  handleClick = e => {
    if (this.node.contains(e.target)) {
      // Do nothing - click was within component
      return;
    }
    else {
      this.setState({selectorOpen: false});
    }
  };

  toggleSelector = () => {
    this.setState({
       selectorOpen: !this.state.selectorOpen
    })
  };

  render() {

    const child = <NumberTypeSelector
      tollfree={this.props.tollfree}
      local={this.props.local}
      handleCheckbox={this.props.handleCheckbox}
    />;

    const selectedFilters = []
    if (this.props.local) {
      selectedFilters.push('Local');
    }
    if (this.props.tollfree) {
      selectedFilters.push('Toll Free');
    }

    return (
      <div ref={node => this.node = node}>
        <FilterButton
          data-test-id={'number-type-filter'}
          selectedFilters={selectedFilters}
          handleClick={this.toggleSelector}
          defaultText={"Local or Toll Free"}
          open={this.state.selectorOpen}
          handleIconClick={selectedFilters.length == 0 ? this.toggleSelector : this.props.handleResetNumberTypeFilter}
        />
        <HoverBoxWrapper
          children={child}
          open={this.state.selectorOpen}
        />
      </div>
    );
  }
}

export default NumberTypeFilter;
