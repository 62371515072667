import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8540
 *
 * @param {SvgIconProps} props - svg props
 */
export const AddNumberIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M6.32437 11.3725C7.62411 14.1638 9.79426 16.481 12.4878 17.982L14.6942 15.9244C14.965 15.6719 15.3557 15.5982 15.6909 15.7263C16.7637 16.1228 17.9296 16.3576 19.1313 16.3995C19.6643 16.4181 20.0852 16.8695 20.0665 17.4025L19.9484 20.7846C19.9298 21.3177 19.4785 21.7385 18.9455 21.7199C9.84558 21.4021 2.72822 13.7697 3.04599 4.66979C3.06461 4.13678 3.51593 3.71591 4.04894 3.73453L7.44081 3.85297C7.97382 3.87159 8.39469 4.32291 8.37608 4.85592C8.33377 6.0673 8.48699 7.237 8.80765 8.33492C8.90241 8.67783 8.81168 9.05307 8.53086 9.31495L6.32437 11.3725Z' fill={color}/>
            <path d='M18.2472 5.24982H20.8154C21.0915 5.24982 21.3154 5.47368 21.3154 5.74982V6.79528C21.3154 7.07142 21.0915 7.29528 20.8154 7.29528H18.2472V9.86346C18.2472 10.1396 18.0233 10.3635 17.7472 10.3635H16.7017C16.4256 10.3635 16.2017 10.1396 16.2017 9.86346V7.29528H13.6335C13.3574 7.29528 13.1335 7.07142 13.1335 6.79528V5.74982C13.1335 5.47368 13.3574 5.24982 13.6335 5.24982H16.2017V2.68164C16.2017 2.4055 16.4256 2.18164 16.7017 2.18164H17.7472C18.0233 2.18164 18.2472 2.4055 18.2472 2.68164V5.24982Z' fill={color}/>
        </SvgIcon>
    )
}

export default AddNumberIcon
