<template>
  <svg v-if="circle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.06737 6.80547V8.22214H9.54737V7.42125C9.54737 7.29092 9.67643 7.18514 9.83537 7.18514H14.1628C14.3223 7.18514 14.4514 7.29092 14.4514 7.42125V8.22214H14.934V6.80547C14.934 6.54481 14.6759 6.33325 14.357 6.33325H9.64443C9.3255 6.33325 9.06737 6.54481 9.06737 6.80547ZM17.3334 8.45844C17.3334 8.3281 17.2139 8.22233 17.0667 8.22233H6.93341C6.78621 8.22233 6.66675 8.3281 6.66675 8.45844V8.93066C6.66675 9.06099 6.78621 9.16677 6.93341 9.16677H7.73341L8.77235 17.2205C8.78781 17.4708 9.02195 17.6668 9.30461 17.6668H14.6955C14.9787 17.6668 15.2118 17.4708 15.2278 17.2205L16.2667 9.16677H17.0667C17.2139 9.16677 17.3334 9.06099 17.3334 8.93066V8.45844Z" fill="white"/>
  </svg>

  <svg v-else width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96736 3.85756V5.80548H7.62736V4.70425C7.62736 4.52504 7.80482 4.3796 8.02336 4.3796H13.9736C14.1929 4.3796 14.3704 4.52504 14.3704 4.70425V5.80548H15.034V3.85756C15.034 3.49914 14.6791 3.20825 14.2406 3.20825H7.76082C7.32229 3.20825 6.96736 3.49914 6.96736 3.85756ZM18.3332 6.13038C18.3332 5.95117 18.1689 5.80573 17.9665 5.80573H4.03317C3.83077 5.80573 3.6665 5.95117 3.6665 6.13038V6.77969C3.6665 6.95889 3.83077 7.10434 4.03317 7.10434H5.13317L6.5617 18.1782C6.58297 18.5224 6.9049 18.7918 7.29357 18.7918H14.7061C15.0955 18.7918 15.416 18.5224 15.438 18.1782L16.8665 7.10434H17.9665C18.1689 7.10434 18.3332 6.95889 18.3332 6.77969V6.13038Z" fill="currentColor"/>
  </svg>

</template>

<script>
  export default {
    props: ['circle'],
  };
</script>
