import React, { Component } from 'react'
import { formatPhoneNumber/* , formatAsYouType */ } from 'phone-numbers'
import AvatarIcon from 'avatar-icon'
import { TextField } from 'text-field-mui'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Tooltip from '@material-ui/core/Tooltip'
import TimezoneField from 'timezone-field'
import Menu from 'pdc-menu'
import RemoteInfoTip from '../remote-info-tip/RemoteInfoTip'
import RemoteConfigValue from 'remote-config-value'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
    row: {
        display: 'flex',
        columnGap: 30,
        marginBottom: 36,
        '&.main': {
            alignItems: 'center',
            marginBottom: 56
        },
        '& .menu-wrapper': {
            width: 285
        },
        '& .menu-items-wrapper': {
            maxHeight: 300,
            overflowY: 'auto'
        },
        '& > div': {
            flex: 1 // this is to make all fields in the row the same width
        }
    },
    infoColumn: {
        marginRight: 'auto',
        marginLeft: 30,
        maxWidth: 250,
        '& .name': {
            fontSize: 24,
            lineHeight: '32px',
            letterSpacing: '-0.2px',
            marginBottom: 10
        },
        '& .email': {
            fontSize: 14,
            lineHeight: '21px',
            color: theme.palette.primary[0],
            fontWeight: 600,
            marginBottom: 7,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '& .direct-number': {
            fontSize: 14,
            lineHeight: '21px',
            color: theme.palette.primary[0],
            fontWeight: 600,
            marginBottom: 7,
            '& span': {
                color: 'black',
                fontWeight: 'normal'
            }
        },
        '& .extension': {
            fontSize: 14,
            lineHeight: '21px',
            color: theme.palette.primary[0],
            fontWeight: 600,
            '& span': {
                color: 'black',
                fontWeight: 'normal'
            }
        }
    },
    pdcMenu: {
        width: '100%'
    },
    pdcMenuItems: {
        maxHeight: 190,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    avatarError: {
        position: 'absolute',
        fontSize: '11px',
        color: 'red',
        top: 210,
        left: 60
    },
    cropContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        width: 240,
        height: 240,
        top: 15,
        left: 240,
        zIndex: 1
    },
    tooltip: {
        backgroundColor: '#48B9D5',
        fontSize: 12
    },
    notInvitedLabel: {
        fontSize: 10,
        fontWeight: 600,
        lineHeight: '12px',
        background: '#2A9BB7',
        padding: '6px 12px 6px 12px',
        color: 'white',
        borderRadius: '13px',
        marginBottom: 10
    }
})

class EditProfile extends Component {
    state = {
        avatarError: false,
        src: null,
        crop: {
            x: 25,
            y: 25,
            width: 50,
            height: 50,
            unit: '%',
            aspect: 1
        }
    }

    renderImageSection = () => {
        const { classes, tmpUser } = this.props
        const directNumber = tmpUser.direct_number ? formatPhoneNumber(tmpUser.direct_number.e164_number) : false
        return (
            <div className={`${classes.row} main`}>
                <div>
                    <AvatarIcon
                        imageUrl = {tmpUser.avatar_url}
                        selectImage = {this.selectImage}
                        edittable = {true}
                        text = {false}
                        height = {125}
                        width = {125}
                        noBorder = {true}
                        opacity = {tmpUser.status === 'invite_pending' ? 0.7 : 1}

                    />
                    {this.state.avatarError
                        ? <span className={classes.avatarError}>{this.state.avatarError}</span>
                        : null
                    }

                    {this.cropImage()
                        ? <div className={classes.cropContainer}>
                            {this.cropImage()}
                        </div>
                        : null
                    }
                </div>
                <div className={classes.infoColumn}>
                    <div className='name'>{tmpUser.first_name} {tmpUser.last_name}</div>
                    {tmpUser.status === 'new'
                        ? <div className={classes.notInvitedLabel}>User has not been invited yet</div>
                        : <Tooltip classes={{ tooltip: classes.tooltip }} title={tmpUser.email} placement='top'>
                            <div className='email'>{tmpUser.email}</div>
                        </Tooltip>
                    }
                    {directNumber
                        ? <div className='direct-number'><span>Direct Number: </span>{directNumber}</div>
                        : null
                    }
                    <div className='extension'><span>Extension: </span>{tmpUser.extension ? tmpUser.extension.data.extension_number : '/'}</div>
                </div>
            </div>
        )
    }

    updateFieldInUser = (field, value) => {
        const { tmpUser } = this.props
        value = typeof value !== 'boolean' && !Number.isInteger(value) ? value.trimStart() : value
        if (['first_name', 'last_name', 'email', 'personal_phone_number', 'voicemail_password'].includes(field)) {
            delete tmpUser[`${field}_error`] // reset validation
        }
        if (field === 'voicemail_password' && value.length > 12) return
        // if (field == 'personal_phone_number') {
        //     let number = value.match(/\d/g) || []
        //     number = number.join('')
        //     if (number === '1') return number
        //     if (isNaN(number) || number.length > 10) return
        //     value = formatAsYouType(number)
        // }
        tmpUser[field] = value
        this.props.updateUser(tmpUser)
    }

    onImageLoaded = image => {
        this.imageRef = image
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop)
    };

    async makeClientCrop (crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            )
            this.updateFieldInUser('avatar_url', croppedImageUrl)
            this.setState({ croppedImageUrl })
        }
    }

    getCroppedImg (image, crop, fileName) {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return canvas.toDataURL('image/jpeg')
    }

    cropImage = () => {
        if (!this.state.src) return null
        if (this.props.tmpUser.avatar_url === this.props.user.avatar_url) return
        return (
            <ReactCrop
                src={this.state.src}
                crop={this.state.crop}
                keepSelection={true}
                // locked={true}
                circularCrop={true}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={newCrop => { this.setState({ crop: newCrop }) }}
            />
        )
    }

    selectImage = event => {
        this.setState({ avatarError: false })
        const file = event.target.files[0]
        if (!this.validateFile(file)) {
            return
        }
        const reader = new FileReader()
        reader.onload = (event) => {
            this.updateFieldInUser('avatar_url', event.target.result)
            this.setState({ src: event.target.result })
        }
        reader.readAsDataURL(file)
    }

    validateFile = file => {
        const isValidType = file.type.split('/')[0] === 'image'
        if (!isValidType) {
            this.setState({ avatarError: 'Only images allowed' })
            return false
        }

        const maxFileSize = 10000000
        const isValidSize = !file.size || file.size < maxFileSize
        if (!isValidSize) {
            this.setState({ avatarError: 'Max allowed size is 10mb.' })
            return false
        }

        return true
    }

    renderCallerIdField = () => {
        const { phoneNumbers, tmpUser } = this.props
        const phoneNumbersItems = phoneNumbers.map(pn => ({ content: `${pn.name ? `${pn.name} - ` : ''}${formatPhoneNumber(pn.phone_number)}`, value: pn.phone_number }))
        phoneNumbersItems.unshift({ content: 'private', value: 'private' })
        const callerId = tmpUser.callerId || 'private'
        const onChange = selection => {
            tmpUser.callerId = selection.value
            this.props.updateUser(tmpUser)
        }
        const label = <span><RemoteConfigValue valueId='user_profile_caller_id_field_label'/> <RemoteInfoTip id='user-settings.profile.preferred-number'/></span>
        return (
            <Menu
                variant = 'single'
                label = {label}
                items = {phoneNumbersItems}
                default = {callerId}
                onChange = {onChange}
                className = {{ wrapper: 'menu-wrapper', itemsWrapper: 'menu-items-wrapper' }}
                controlable
            />
        )
    }

    renderEditSection = () => {
        const { classes } = this.props
        const user = this.props.tmpUser
        return (
            <>
                <div className={classes.row}>
                    <TextField
                        label = {'First name'}
                        value = {user.first_name}
                        onChange = {(e) => this.updateFieldInUser('first_name', e.target.value)}
                        onXClick = {() => this.updateFieldInUser('first_name', '')}
                        error = {user.first_name_error}
                        helperText = {user.first_name_error}
                        data-test-id = 'first_name'
                    />
                    <TextField
                        label = {'Last Name'}
                        value = {user.last_name}
                        onChange = {(e) => this.updateFieldInUser('last_name', e.target.value)}
                        onXClick = {() => this.updateFieldInUser('last_name', '')}
                        error = {user.last_name_error}
                        helperText = {user.last_name_error}
                        data-test-id = 'last_name'
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        label = {this.props.isModal ? 'Email' : 'Your Email'}
                        value = {user.email}
                        onChange = {(e) => this.updateFieldInUser('email', e.target.value)}
                        onXClick = {() => this.updateFieldInUser('email', '')}
                        error = {user.email_error}
                        helperText = {user.email_error}
                        data-test-id = 'email'
                        disabled = {this.props.disableEmail}
                    />
                    <TextField
                        label = {'Mobile Number'}
                        value = {user.personal_phone_number}
                        onChange = {(e) => this.updateFieldInUser('personal_phone_number', e.target.value)}
                        onXClick = {() => this.updateFieldInUser('personal_phone_number', '')}
                        error = {user.personal_phone_number_error}
                        helperText = {user.personal_phone_number_error}
                        data-test-id = 'personal_phone_number'
                    />
                </div>
                <div className={classes.row}>
                    <TimezoneField
                        savedTimezone = {this.props.user.timezone}
                        timezoneValue = {this.props.tmpUser.timezone}
                        className = {{ wrapper: classes.pdcMenu, itemsWrapper: classes.pdcMenuItems }}
                        onChange = {timezone => this.updateFieldInUser('timezone', timezone)}
                        fieldWidth = {260}
                        label = {'Timezone'}
                    />
                    <TextField
                        label = {'Voicemail Pin'}
                        value = {user.voicemail_password}
                        onChange = {(e) => this.updateFieldInUser('voicemail_password', e.target.value)}
                        onXClick = {() => this.updateFieldInUser('voicemail_password', '')}
                        error = {user.voicemail_password_error}
                        helperText = {user.voicemail_password_error}
                        data-test-id = 'voicemail_password'
                    />
                </div>
                {process.env.REACT_APP_ENABLE_CALLER_ID_IN_USER_PROFILE
                    ? <div className={classes.row}>
                        {this.renderCallerIdField()}
                    </div>
                    : null
                }
            </>
        )
    }

    render () {
        return (
            <>
                {this.renderImageSection()}
                {this.renderEditSection()}
            </>
        )
    }
}

EditProfile.propTypes = {
    classes: PropTypes.object,
    tmpUser: PropTypes.object,
    user: PropTypes.object,
    phoneNumbers: PropTypes.array,
    updateUser: PropTypes.func,
    isModal: PropTypes.bool,
    disableEmail: PropTypes.bool
}

export default withStyles(styles)(EditProfile)
