import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8539
 *
 * @param {SvgIconProps} props - svg props
 */
export const PhoneNumberIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M6.32437 11.3725C7.62411 14.1638 9.79426 16.481 12.4878 17.982L14.6942 15.9244C14.965 15.6719 15.3557 15.5982 15.6909 15.7263C16.7637 16.1228 17.9296 16.3576 19.1313 16.3995C19.6643 16.4181 20.0852 16.8695 20.0665 17.4025L19.9484 20.7846C19.9298 21.3177 19.4785 21.7385 18.9455 21.7199C9.84558 21.4021 2.72822 13.7697 3.04599 4.66979C3.06461 4.13678 3.51593 3.71591 4.04894 3.73453L7.44081 3.85297C7.97382 3.87159 8.39469 4.32291 8.37608 4.85592C8.33377 6.0673 8.48698 7.237 8.80765 8.33492C8.90241 8.67783 8.81168 9.05307 8.53086 9.31495L6.32437 11.3725Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M15.27 3C14.9939 3 14.77 3.22386 14.77 3.5V4.77295H13.6335C13.3574 4.77295 13.1335 4.99681 13.1335 5.27295V5.90931C13.1335 6.18546 13.3574 6.40931 13.6335 6.40931H14.77V7.9624H13.6335C13.3574 7.9624 13.1335 8.18626 13.1335 8.4624V9.09877C13.1335 9.37491 13.3574 9.59877 13.6335 9.59877H14.77V10.6818C14.77 10.958 14.9939 11.1818 15.27 11.1818H15.9064C16.1825 11.1818 16.4064 10.958 16.4064 10.6818V9.59877H18.0426V10.6818C18.0426 10.958 18.2665 11.1818 18.5426 11.1818H19.179C19.4551 11.1818 19.679 10.958 19.679 10.6818V9.59877H20.8154C21.0915 9.59877 21.3154 9.37491 21.3154 9.09877V8.4624C21.3154 8.18626 21.0915 7.9624 20.8154 7.9624H19.679V6.40931H20.8154C21.0915 6.40931 21.3154 6.18546 21.3154 5.90931V5.27295C21.3154 4.99681 21.0915 4.77295 20.8154 4.77295H19.679V3.5C19.679 3.22386 19.4551 3 19.179 3H18.5426C18.2665 3 18.0426 3.22386 18.0426 3.5V4.77295H16.4064V3.5C16.4064 3.22386 16.1825 3 15.9064 3H15.27ZM18.0426 6.40931H16.4064V7.9624H18.0426V6.40931Z' fill={color}/>
        </SvgIcon>
    )
}

export default PhoneNumberIcon
