/**
 * @param {object} theme
 */
const styles = theme => ({
    configureCallingWrapper: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1100,
        '& .content-wrapper': {
            padding: '50px 65px 65px',
            flex: 1,
            overflowY: 'auto'
        },
        '& .full-separator': {
            borderBottom: `1px solid ${theme.palette.secondary[-200]}`,
            margin: '0 -65px 50px'
        },
        '& .big-title': {
            marginBottom: 40,
            textTransform: 'uppercase'
        },
        '&.tablet': {
            '& .content-wrapper': {
                padding: '20px 50px 65px'
            },
            '& .full-separator': {
                margin: '0 -50px 50px'
            }
        },
        '&.mobile': {
            '& .content-wrapper': {
                padding: '20px 25px 65px'
            },
            '& .full-separator': {
                margin: '0 -25px 50px'
            },
            '& .question': {
                display: 'block'
            }
        },
        '& .question': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10,
            '&.error': {
                color: '#EA0303'
            },
            '& > p': {
                whiteSpace: 'nowrap',
                marginRight: 10
            }
        },
        '&.company-setup': {
            position: 'relative'
        }
    },
    voiceLineToggleSection: {
        marginBottom: 25
    },
    headerBar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.primary[-400],
        minHeight: 60,
        padding: '0 65px',
        '& .go-back': {
            color: theme.palette.primary[0],
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& svg': {
                marginRight: 4,
                fontSize: 14
            },
            '& span': {
                fontFamily: 'Montserrat-semi-bold',
                fontWeight: 600,
                fontSize: 16,
                lineHeight: '24px'
            }
        }
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 15,
        marginBottom: 15,
        '&.number-type-section': {
            marginBottom: 20,
            padding: 0,
            '&.fax': {
                marginBottom: 40
            }
        },
        '& .remove-section-spacings': {
            '& > div': {
                margin: 0,
                padding: 0,
                '& > span': {
                    margin: 0
                }
            }
        },
        '& .section-header': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 2,
            '& > svg': {
                fontSize: 40,
                marginRight: 14
            },
            '& > p': {
                color: theme.palette.info.dark
            },
            '&.mobile': {
                marginBottom: 10,
                '& > svg': {
                    marginRight: 8
                }
            },
            '& > span[data-user-pilot-id]': {
                marginLeft: 3
            }
        },
        '& .section-content': {
            marginLeft: 54,
            '&.mobile': {
                marginLeft: 0
            }
        }
    },
    radio: {
        marginRight: 10,
        padding: 0,
        '&.checked .radio': {
            borderColor: theme.palette.primary['0'],
            '& .check-circle': {
                background: theme.palette.primary['0']
            }
        }
    },
    menuAction: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 14,
        padding: '25px 0 26px',
        borderBottom: `1px solid ${theme.palette.secondary.border}`,
        '&:first-child': {
            borderTop: `1px solid ${theme.palette.secondary.border}`
        },
        '&.mobile': {
            height: 'fit-content',
            padding: '30px 0',
            flexWrap: 'wrap',
            rowGap: 20
        }
    },
    addMenuWrapper: {
        marginTop: 30,
        '& .add-option-button': {
            display: 'inline-flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&.error': {
                color: theme.palette.error.main
            },
            '& svg': {
                marginRight: 5,
                fontSize: 30
            },
            '&:hover': {
                color: theme.palette.primary.light
            }
        }
    },
    dropdownMenu: {
        '&.size-xs': {
            minWidth: 80,
            maxWidth: 80
        },
        '&.size-sm': {
            minWidth: 90,
            maxWidth: 90
        },
        '&.size-md': {
            minWidth: 200,
            maxWidth: 200
        },
        '&.size-mlg': {
            minWidth: 269,
            maxWidth: 269
        },
        '&.size-lg': {
            minWidth: 300,
            maxWidth: 300
        },
        '&.size-lg-dynamic': {
            minWidth: 300
        },
        '&.margin-right-25': {
            marginRight: 25
        },
        '&.margin-right-20': {
            marginRight: 20
        }
    },
    dropdownItemsWrapper: {
        maxHeight: 300
    },
    readATextWrapper: {
        marginTop: 10,
        '& .textarea-wrapper': {
            display: 'flex'
        }
    },
    textarea: {
        fontSize: 12.8,
        padding: 10,
        border: '1px solid gray',
        borderRadius: 4,
        minWidth: 300,
        maxWidth: 500,
        minHeight: 100,
        maxHeight: 250,
        width: 400,
        height: 100,
        resize: 'horizontal',
        '&.error': {
            borderColor: '#EA0303',
            borderWidth: 2
        },
        '&.mobile': {
            width: 300
        }
    },
    menuOptionSelect: {
        margin: '0 10px'
    },
    removeMenuActionWrapper: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 6,
        cursor: 'pointer',
        marginLeft: 10,
        color: theme.palette.secondary.main,
        '& > svg': {
            fontSize: 24
        },
        '&:hover': {
            color: theme.palette.secondary.light
        },
        '&.mobile': {
            '& > svg': {
                fontSize: 20
            },
            marginLeft: 0,
            color: theme.palette.text.secondary
        }
    },
    loadingDiv: { ...theme.loadingDiv, position: 'fixed' },
    audioPlayerContainer: {
        width: 400,
        padding: '20px 10px'
    },
    menuOptionWrapper: {
        maxWidth: 300,
        flex: 1,
        marginLeft: 20,
        minWidth: 150
    },
    scheduleTabs: {
        marginBottom: 40,
        '&.less-margin': {
            marginBottom: 20
        },
        '&.mobile': {
            marginLeft: -25,
            marginRight: -25
        }
    },
    actionButton: {
        color: theme.palette.primary[0],
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        svg: {
            marginRight: 4,
            fontSize: 14
        },
        '& span': {
            'white-space': 'nowrap'
        }
    },
    footer: {
        height: 83,
        background: theme.palette.secondary[-500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: `2px solid ${theme.palette.secondary[-300]}`,
        '& button': {
            width: 150
        }
    },
    sameAsAfterHours: {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            marginRight: 20
        }
    },
    welcomeMessageToggleWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            marginLeft: 5
        }
    },
    disableWelcomeMessage: {
        pointerEvents: 'none',
        opacity: 0.5,
        display: 'block'
    },
    liveAnswerWelcomeMessageText: {
        marginTop: 10
    },
    selectUsersContent: {
        width: 300,
        '& .select-form-control-root': {
            '& [class^="MuiInputBase-root"], & [class*=" MuiInputBase-root"]': {
                width: 300
            }
        },
        '& .selected-user-item': {
            '&:hover': {
                background: theme.palette.action.primaryFocus,
                cursor: 'pointer'
            },
            '&.dragging': {
                background: 'white',
                opacity: 0.5,
                boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)',
                cursor: 'grabbing'
            },
            '&.drop-underline': {
                borderBottom: `1px solid ${theme.palette.primary.light}`
            },
            '&.drop-overline': {
                borderTop: `1px solid ${theme.palette.primary.light}`
            }
        }
    },
    selectedUsersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 15,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            height: 58,
            paddingRight: 12,
            paddingLeft: 20,
            width: 300,
            position: 'relative',
            '& > svg': {
                position: 'absolute',
                right: 20,
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: theme.palette.secondary.main
            }
        }
    },
    whileRinging: {
        display: 'flex',
        alignItems: 'center',
        '& .while-playing': {
            marginLeft: 15,
            marginRight: 15
        }
    },
    menuActionVerticalSeparator: {
        minWidth: 1,
        height: 55,
        background: theme.palette.secondary.border,
        marginLeft: 20,
        marginRight: 20,
        '&:not(.mobile) + div': {
            minWidth: 269,
            maxWidth: 269
        }
    },
    mobileViewStepper: {
        marginLeft: -40,
        '& > div': {
            width: '100% !important'
        }
    },
    nowrap: {
        whiteSpace: 'nowrap'
    },
    sameAsBorder: {
        borderTop: `1px solid ${theme.palette.secondary.border}`,
        marginTop: 20,
        marginBottom: 30
    },
    companyBusinessHoursAlert: {
        maxWidth: 780,
        marginTop: -20,
        marginBottom: 50
    },
    liveReceptionistInfoAlert: {
        maxWidth: 780,
        marginBottom: 30,
        marginLeft: 55,
        backgroundColor: theme.palette.paper.gray
    },
    liveReceptionistCollapseButton: {
        cursor: 'pointer',
        '& .edit-button': {
            borderRadius: '50%',
            width: 30,
            height: 30,
            minWidth: 30,
            minHeight: 30,
            display: 'grid',
            placeItems: 'center',
            backgroundColor: 'rgb(133 146 154 / 15%)',
            '& > svg': {
                fontSize: 20,
                color: '#6E7A82'
            },
            '&:hover': {
                backgroundColor: 'rgb(206 239 199 / 50%)',
                '& > svg': {
                    color: '#29921B'
                }

            }
        }
    },
    liveReceptionistCollapse: {
        maxHeight: '0px',
        overflow: 'hidden',
        transition: '1s all 0s',
        '&.expanded': {
            maxHeight: '1000px'
        }
    },
    receptionistConfigurationSection: {
        display: 'flex',
        alignItems: 'flex-start',
        marginLeft: 55
    },
    receptionistConfigurationSectionText: {
        marginLeft: 12,
        width: 740,
        '& p': {
            fontFamily: 'Montserrat-medium !important'
        }
    },
    receptionistSeparator: {
        borderBottom: `1px solid ${theme.palette.secondary[-200]}`,
        margin: '15px 0px 15px'
    },
    receptionistOptions: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 15
    },
    receptionistOptionText: {
        marginLeft: 6
    },
    receptionOptionTitle: {
        display: 'flex',
        gap: 3
    },
    scriptEditSection: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginLeft: 55,
        marginBottom: 15,
        '&.mobile': {
            marginLeft: 0
        }
    },
    scriptEditText: {
        marginLeft: 38,
        color: theme.palette.text.secondary
    },
    scriptEditTitle: {
        fontWeight: '600 !important'
    },
    expertContact: {
        display: 'flex',
        alignItems: 'center',
        gap: 5
    },
    smallInfoLogo: {
        width: 30
    },
    expertContactText: {
        color: theme.palette.warning.main
    },
    expertContactTextModal: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontWeight: '600 !important',
        fontSize: 15,
        bottom: 0,
        left: 0,
        right: 0,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    chargesSection: {
        display: 'flex'
    },
    chargesText: {
        display: 'flex'
    },
    thankYouDialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '70px'
    },
    thankYouLogo: {
        width: 60,
        marginBottom: 15
    },
    editNumberColumn: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& .edit-button': {
            borderRadius: '50%',
            width: 30,
            height: 30,
            minWidth: 30,
            minHeight: 30,
            display: 'grid',
            placeItems: 'center',
            backgroundColor: theme.palette.action.primaryFocus,
            marginRight: 8,
            '& > svg': {
                fontSize: 20,
                color: theme.palette.primary.dark
            }
            // '&:hover': {
            //     backgroundColor: 'rgb(206 239 199 / 50%)',
            //     '& > svg': {
            //         color: '#29921B'
            //     }

            // }
        }
    },
    menuItem: {
        '&.Mui-disabled': {
            pointerEvents: 'auto !important',
            cursor: 'not-allowed'
        }
    },
    menuItemContentWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        padding: 12
    },
    menuMessageLabel: {
        display: 'flex'
    },
    tabLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .tab-name': {
            textTransform: 'lowercase',
            '&:first-letter': {
                textTransform: 'uppercase'
            }
        }
    },
    forwardTimeoutForwardWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 15,
        '& .connector-text': {
            width: 110
        },
        '&.mobile': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& .connector-text': {
                width: 'unset'
            },
            '& > div.size-mlg': {
                minWidth: 300
            }
        }
    },
    saveProgressContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2000,
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
        '& .save-progress-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            alignItems: 'center',
            justifyContent: 'center',
            height: 'fit-content',
            marginTop: '45vh',
            position: 'relative',
            transform: 'translateY(-100px)',
            '& > p': {
                textAlign: 'center'
            },
            '& .progress-bar-root': {
                maxWidth: 300,
                width: '100%',
                '& .progress-bar-fill': {
                    transition: 'transform 0.8s linear'
                }
            },
            '& .save-info-wrapper': {
                position: 'absolute',
                top: 'calc(100% + 20px)',
                display: 'flex',
                flexDirection: 'column',
                rowGap: 6,
                width: 300,
                '& svg': {
                    fontSize: 20
                },
                '& p': {
                    color: theme.palette.primary.main
                },
                '&.error': {
                    '& p': {
                        color: theme.palette.error.main
                    }
                }
            }
        }
    },
    forwardNumberContent: {
        display: 'flex',
        columnGap: 15,
        alignItems: 'center',
        '& .forward-external-text-field': {
            width: 300
        },
        '&.mobile .forward-external-text-field': {
            width: 270
        }
    },
    editButton: {
        padding: 10,
        borderRadius: '50%',
        backgroundColor: theme.palette.action.primaryFocus,
        cursor: 'pointer',
        '& > svg': {
            color: theme.palette.primary.main
        }
    },
    callOptionSelect: {
        display: 'flex',
        columnGap: 15,
        alignItems: 'center',
        '&.mobile': {
            display: 'block'
        }
    },
    forwardOptionsModalContent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 2, // total of 25 with confirm modal's margins
        '& .caller-id-section': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 15,
            marginBottom: 30,
            '& .caller-id-select': {
                width: 470
            }
        },
        '& .voice-tag-section': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 10,
            marginBottom: 20
        },
        '& .screening-section': {
            marginBottom: 5 // total 40 with the confirm modal's margin
        },
        '& .title-wrapper': {
            display: 'flex',
            alignItems: 'center',
            columnGap: 2
        },
        '&.mobile': {
            '& .caller-id-section': {
                '& .caller-id-select': {
                    width: '100%'
                }
            }
        }
    },
    IABConfirmChargesPopup: {
        maxWidth: '477px',
        padding: '50px'
    },
    IABConfirmChargesPopupTitle: {
        color: theme.palette.info.dark,
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '19px',
        marginBottom: '25px'
    },
    IABConfirmChargesPopupContent: {
        '& .info-table': {
            marginBottom: '50px'
        },
        '& .info-table-row': {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '11px'
        },
        '& .free-period': {
            marginBottom: '6px'
        }

    },
    IABConfirmChargesPopupActions: {
    }
})

export default styles
