<template>
	<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M8.9962 16.4761C10.7315 20.2029 13.629 23.2966 17.2252 25.3006L20.1711 22.5535C20.5327 22.2163 21.0543 22.1179 21.5017 22.289C22.9342 22.8184 24.4907 23.1318 26.0951 23.1878C26.8068 23.2127 27.3687 23.8153 27.3438 24.5269L27.1862 29.0426C27.1613 29.7542 26.5587 30.3161 25.8471 30.2913C13.6975 29.867 4.19485 19.6767 4.61912 7.52707C4.64397 6.81543 5.24656 6.25352 5.95819 6.27837L10.4868 6.43651C11.1984 6.46136 11.7604 7.06394 11.7355 7.77558C11.679 9.39294 11.8836 10.9546 12.3117 12.4205C12.4382 12.8783 12.3171 13.3793 11.9422 13.729L8.9962 16.4761Z" fill="currentColor"/>
	<path d="M24.7219 10.8613L21.7822 12.7913C20.9849 13.3147 20.0544 13.5992 19.1007 13.6112L17.2387 13.6344C17.0286 13.6371 16.9153 13.8821 17.0494 14.044L19.1082 16.5277C19.3855 16.8621 19.7903 17.065 20.2242 17.087C21.1393 17.1333 22.0439 16.8744 22.796 16.3511L26.6223 13.6885L28.5854 16.5191C28.7099 16.6987 28.9888 16.6432 29.0351 16.4297L30.6493 8.99578C30.6802 8.85327 30.5831 8.71462 30.4386 8.69501L23.0879 7.69731C22.8739 7.66826 22.7262 7.90596 22.8471 8.08494L24.7219 10.8613Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {};
</script>
