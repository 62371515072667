import React, { Component } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, withStyles } from '@material-ui/core'
import Button from 'button'
import PropTypes from 'prop-types'
import RemoteConfigValue from 'remote-config-value'

const styles = theme => ({
    paper: {
        maxWidth: 450
    },
    content: {
        padding: '10px 0'
    },
    infoText: {
        margin: '15px 0'
    },
    line: {
        display: 'flex',
        margin: '10px 0',
        '& .key': {
            minWidth: 80,
            marginRight: 20
        }
    }
})

class SipCredentialsModal extends Component {
    selectText = event => {
        const node = event.target
        if (document.body.createTextRange) {
            const range = document.body.createTextRange()
            range.moveToElementText(node)
            range.select()
        } else if (window.getSelection) {
            const selection = window.getSelection()
            const range = document.createRange()
            range.selectNodeContents(node)
            selection.removeAllRanges()
            selection.addRange(range)
        } else {
            console.warn('Could not select text in node: Unsupported browser.')
        }
    }

    render = () => {
        const { classes, onClose, name, sipProxy, username, password } = this.props
        return (
            <Dialog classes={{ paper: classes.paper }} onClose={onClose} open>
                <DialogTitle>{name} SIP credentials</DialogTitle>
                <DialogContent classes={{ root: classes.content }}>
                    <div className={classes.infoText}><RemoteConfigValue valueId='users_sip_credentials_modal_info_text'/></div>
                    <div className={classes.line}>
                        <div className='key'>SIP Proxy:</div>
                        <div className='value' onClick={this.selectText}>{sipProxy}</div>
                    </div>
                    <div className={classes.line}>
                        <div className='key'>Username:</div>
                        <div className='value' onClick={this.selectText}>{username}</div>
                    </div>
                    <div className={classes.line}>
                        <div className='key'>Password:</div>
                        <div className='value' onClick={this.selectText}>{password}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={onClose}><RemoteConfigValue valueId='users_sip_credentials_modal_button_text'/></Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SipCredentialsModal.propTypes = {
    // Material ui classes
    classes: PropTypes.any,
    // Function called on close
    onClose: PropTypes.func,
    name: PropTypes.string,
    sipProxy: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string
}

export default withStyles(styles)(SipCredentialsModal)
