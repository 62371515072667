// import './css-style-sheets/index.css' // commented out because it was overriding the docs gen
import './css-style-sheets/pdc.css'

import './css-style-sheets/components/App.css'
import './css-style-sheets/components/AppBar.css'
import './css-style-sheets/components/Dialer.css'
import './css-style-sheets/components/DialerButton.css'
import './css-style-sheets/components/DropdownComponent.css'
import './css-style-sheets/components/InfiniteScroller.css'
import './css-style-sheets/components/LoaderBar.css'
import './css-style-sheets/components/MediaModal.css'
import './css-style-sheets/components/MessengerModal.css'
import './css-style-sheets/components/PDCLoader.css'

import './css-style-sheets/content/ContactItem.css'
import './css-style-sheets/content/ConversationContent.css'
import './css-style-sheets/content/ConversationHeader.css'
import './css-style-sheets/content/ConversationMessages.css'
import './css-style-sheets/content/Header.css'
import './css-style-sheets/content/SendMessage.css'

const messagesTheme = {

}

/**
 *
 */
export { messagesTheme }
