import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/JQacodb8V4YdmDt3Awk2zl/Call-handling?node-id=2529%3A10204
 *
 * @param {SvgIconProps} props - svg props
 */
export const CallHandlingIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='40' height='40' rx='20' fill='#2A9BB7'/>
            <path fillRule='evenodd' clipRule='evenodd' d='M27.1129 24.0006C27.1129 24.9963 27.872 25.8043 28.8064 25.8043C29.7413 25.8043 30.5 24.9963 30.5 24.0006V21.2048C30.5 20.3318 29.9208 19.6063 29.1519 19.4371L29.0876 19.1269C28.7187 14.3975 24.4066 10 19.9424 10C15.4613 10 11.2071 14.4372 10.8819 19.1954L10.8955 19.4263C10.1029 19.5778 9.5 20.3174 9.5 21.2048V24.0006C9.5 24.9963 10.259 25.8043 11.1935 25.8043C12.1284 25.8043 12.8871 24.9963 12.8871 24.0006V21.2048L12.8668 19.7799C12.8668 15.9523 16.3487 12.0707 19.9424 12.0707C23.5361 12.0707 27.1231 15.9523 27.1231 19.7799C27.1231 20.155 27.1129 21.2048 27.1129 21.2048V24.0006ZM14.6771 20.0325C14.6771 19.6321 14.3578 19.311 13.9675 19.311C13.5771 19.311 13.2578 19.6321 13.2578 20.0325V25.083C13.2578 25.4798 13.5771 25.8045 13.9675 25.8045C14.3578 25.8045 14.6771 25.4798 14.6771 25.083V20.0325ZM26.0321 19.311C26.4224 19.311 26.7418 19.6321 26.7418 20.0325V25.083C26.7418 25.4798 26.4224 25.8045 26.0321 25.8045C25.6418 25.8045 25.3224 25.4798 25.3224 25.083V20.0325C25.3224 19.6321 25.6418 19.311 26.0321 19.311ZM20 28.7876C20 28.1181 20.5427 27.5754 21.2121 27.5754H23.4593C23.9872 27.5754 24.4362 27.9129 24.6025 28.3838H30.0168C30.2399 28.3838 30.4208 28.5647 30.4208 28.7878C30.4208 29.011 30.2399 29.1919 30.0168 29.1919H24.6024C24.4359 29.6625 23.987 29.9997 23.4593 29.9997H21.2121C20.5427 29.9997 20 29.457 20 28.7876Z' fill='white'/>
        </SvgIcon>
    )
}

export default CallHandlingIcon
