<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 18.5C19 18.7761 18.7761 19 18.5 19H5.5C5.22386 19 5 18.7761 5 18.5V5.5C5 5.22386 5.22386 5 5.5 5H10.5C10.7761 5 11 4.77614 11 4.5V3.5C11 3.22386 10.7761 3 10.5 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V13.5C21 13.2239 20.7761 13 20.5 13H19.5C19.2239 13 19 13.2239 19 13.5V18.5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5001 3.5V4.5C14.5001 4.77614 14.724 5 15.0001 5H18.2502L12.0327 11.2175C11.8374 11.4128 11.8374 11.7294 12.0327 11.9246C12.2279 12.1199 12.5445 12.1199 12.7398 11.9246L19.0001 5.6643V9C19.0001 9.27614 19.224 9.5 19.5001 9.5H20.5001C20.7762 9.5 21.0001 9.27614 21.0001 9V3.5C21.0001 3.22386 20.7762 3 20.5001 3H15.0001C14.724 3 14.5001 3.22386 14.5001 3.5Z" fill="currentColor"/>
    </svg>
</template>

<script>
  export default {};
</script>
