import React from 'react'
import './pricing-page.css'
import MessageLoader from '../../../components/search-results/loader/message-loader'
// import { makeStyles } from '@material-ui/core';
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import PropTypes from 'prop-types'

// const useStyles = makeStyles(theme => ({
// pricingLink: {
//     marginTop: '5px',
//     'a': {
//         color: '#2A9BB7'
//     }
// }
//   }));
/**
 * @param {any} props - is the checkbox checked
 */
export default function PricingPage (props) {
    // let classes = useStyles();

    const removeNumber = (did) => {
        const removeNumber = props.reservedNumbers.find(number => number.phone_number === did)
        props.removeNumberAndReprice(removeNumber)
    }
    const chargesByDid = props.pricingSummary.charges_by_did
    const didPricingRows = []
    let taxesAndFeesTotal = 0
    let totalToday = 0
    let totalMonthlyCharge = 0
    let nonIncludedDidsCount = 0
    Object.keys(chargesByDid).forEach(did => {
        const fees = chargesByDid[did].details.fees
        const taxes = chargesByDid[did].details.taxes
        const numberPrice = chargesByDid[did].details.price
        const monthlyCharge = chargesByDid[did].details.monthly_recurring_cost
        const formatedNumberPrice = '$' + (numberPrice / 100).toFixed(2)
        const formatedDid = props.prettyFormat(did)
        const didRow = <div key={did} className='pricing-row' data-test-id='pricing-row'>
            <div className='pricing-left-column'>

                <span style={{ fontWeight: 'bolder' }}>
                    {formatedDid}
                </span>
                <span> - One time setup fee</span>
            </div>
            <div className='pricing-right-column'>
                <span>{formatedNumberPrice}</span>
                <a
                    onClick={() => removeNumber(did)}
                    className="pricing-remove-number"
                >
                                ✕
                </a>
            </div>
        </div>

        taxesAndFeesTotal += fees.fee_total + taxes.tax_total
        totalToday += chargesByDid[did].total
        totalMonthlyCharge += monthlyCharge
        if (monthlyCharge > 0) { nonIncludedDidsCount++ }
        didPricingRows.push(didRow)
    })

    const taxesAndFeesRow = <div key='taxesAndFeesRow' className='pricing-row taxes-and-fees'>
        <div className='pricing-left-column'>
            Taxes
        </div>
        <div className='pricing-right-column'>
            ${(taxesAndFeesTotal / 100).toFixed(2)}
        </div>
    </div>
    didPricingRows.push(taxesAndFeesRow)

    const monthlyCostRow = (
        <div className='pricing-row total-today'>
            <div className='pricing-left-column'>
                <span>{nonIncludedDidsCount} x Additional Numbers - monthly fee</span>
            </div>
            <div className='pricing-right-column'>
                <RemoteInfoTipMui arrow remoteConfigIDs={['configure_numbers_add_review_order']} placement={RemoteInfoTipMuiPlacement.TOP}/>
                ${(totalMonthlyCharge / 100).toFixed(2)}
            </div>
        </div>
    )

    if (props.removingNumber) {
        return (
            <div className='pricing-remove-loader'>
                <MessageLoader message={'Updating your cart...'}/>
            </div>
        )
    }

    return (
        <div className='pricing-page-wrapper' onClick={(e) => e.stopPropagation()}>
            <div>
                <div className="pricing-page-header">
                    <div className='pricing-page-title'>Review Order</div>
                    <div className='pricing-page-charge'>Charge Today</div>
                </div>
                <hr className="pricing-page-spacer"></hr>
                <div className="pricing-page-body">
                    {didPricingRows}
                </div>
                <hr className="pricing-page-spacer"></hr>
                <div className="pricing-page-footer">
                    <div key='totalTodayRow' className='pricing-row total-today'>
                        <div className='pricing-total-label pricing-left-column'>
                            <span>Total</span>
                        </div>
                        <div className='pricing-total pricing-right-column'>
                            ${(totalToday / 100).toFixed(2)}
                        </div>
                    </div>
                    {totalMonthlyCharge > 0 && monthlyCostRow}
                </div>
            </div>
        </div>
    )
}

PricingPage.propTypes = {
    classes: PropTypes.any,
    reservedNumbers: PropTypes.array,
    pricingSummary: PropTypes.object,
    removeNumberAndReprice: PropTypes.func,
    removingNumber: PropTypes.bool,
    prettyFormat: PropTypes.func
}
