import React, { Component } from 'react'
import gtmDataPush from 'gtm-events'
import AudioUploader from './AudioUploader'
import AudioRecorder from './AudioRecorder'
import AudioPlayer from './AudioPlayer'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
import LoadingBar from 'loading-bar'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import { withStyles } from '@material-ui/core'
import { Textarea } from 'textarea-mui'
import styles from './styles'
import { Greeting, TTS, Recording, UploadAudio, StoredFile } from './Greeting'
import { CheckIcon } from 'svg-icons'
import Api from 'api'

const GtmAppName = 'configure-number'
const GtmMap = { OtherOption: 1, SameOption: 0 }

/***/
export interface Props {
    // Material ui's classes
    classes
    'data-test-id': string
    id: string
    showValidationErrors: boolean
    greeting: Greeting
    // Call this with new instance of the menu so that a component update will happen
    onChange: (a: Greeting) => void
    textAreaInfoTipId?: string
    screenViewType: any
    audioTypeNames?: Partial<{ [key: string]: string }>
    hideTTS?: boolean
    selectionList?: StoredFile[]
}

/***/
export interface State {
    tts: TTS
    recording: Recording
    uploadAudio: UploadAudio
    storedFile: StoredFile
}

interface AudioPlayerWrapperProps {
    classes
    id
    url: string
    onReadyToPlay: () => void
    readyToPlayAudio: boolean
    loading?: boolean
    loadUrl?: () => Promise<string>
}

const AudioPlayerWrapper = (props: AudioPlayerWrapperProps): JSX.Element => {
    const { classes, url, readyToPlayAudio, onReadyToPlay, loadUrl, id, loading } = props
    const showPlayer = readyToPlayAudio || (loadUrl && !url)
    return (
        <div className = {classes.audioPlayerContainer}>
            <div style={{ display: showPlayer ? 'block' : 'none' }}>
                <AudioPlayer
                    key = {`tts-audio-${id}`}
                    url = {url}
                    loading = {!!loading}
                    onPlay = {() => { /**/ }}
                    onReadyToPlay = {onReadyToPlay}
                    loadUrl = {loadUrl}
                />
            </div>
            {!showPlayer ? <LoadingBar/> : null}
        </div>
    )
}

class AudioComponent extends Component<Props, State> {
    /***/
    constructor (props: Props) {
        super(props)
        const greeting: Greeting = props.greeting
        const tts = greeting instanceof TTS ? greeting : new TTS()
        const recording = greeting instanceof Recording ? greeting : new Recording()
        const uploadAudio = greeting instanceof UploadAudio ? greeting : new UploadAudio()
        const storedFile = greeting instanceof StoredFile ? greeting : (this.props.selectionList?.[0] || null)
        this.state = { tts, recording, uploadAudio, storedFile }
    }

    renderTTS = (): JSX.Element => {
        const { classes, showValidationErrors, textAreaInfoTipId, screenViewType } = this.props
        const greeting: TTS = this.props.greeting as TTS
        const value = greeting.getText()

        const onTextAreaChange = e => {
            const value = e.target.value
            const tts = greeting.copy()
            tts.setText(value)
            this.setState({ tts })
            this.props.onChange(tts)
        }
        const onRestore = greeting.hasChange() && greeting.getStoredId()
            ? () => {
                const tts = greeting.copy()
                tts.reset()
                this.props.onChange(tts)
            }
            : null
        const hasError = showValidationErrors && value === ''
        const mobileViewClass = screenViewType.isMobileView ? 'mobile' : ''
        const textareaClasses = `${classes.textarea} ${mobileViewClass}`
        return (
            <div className={classes.ttsSection}>
                <div className='textarea-wrapper'>
                    <div className='textarea-formcontrol'>
                        <Textarea
                            error = {hasError}
                            helperText = {hasError ? 'Required field' : ''}
                            className = {textareaClasses}
                            value = {value}
                            onChange = {onTextAreaChange}
                            onRestore = {onRestore}
                        />
                    </div>
                    {textAreaInfoTipId ? <RemoteInfoTipMui arrow remoteConfigIDs={[textAreaInfoTipId]} placement={RemoteInfoTipMuiPlacement.TOP}/> : null}
                </div>
                {greeting.getText()
                    ? <AudioPlayerWrapper
                        id = 'tts'
                        loadUrl = {greeting.hasChange()
                            ? async () => {
                                const newGreeting = greeting.copy()
                                newGreeting.setLoadingUrl(true)
                                this.props.onChange(newGreeting)
                                const urlResponse = await Api.ttsToAudio(value)
                                const url = urlResponse.url
                                newGreeting.setUrl(url)
                                this.props.onChange(newGreeting)
                                return url
                            }
                            : null
                        }
                        classes = {classes}
                        loading = {greeting.isLoadingUrl()}
                        url = {greeting.getUrl()}
                        readyToPlayAudio = {greeting.isReadyToPlay()}
                        onReadyToPlay = {() => {
                            if (!(this.props.greeting instanceof TTS)) return null
                            const newGreeting = greeting.copy()
                            newGreeting.setReadyToPlay()
                            this.props.onChange(newGreeting)
                        }}
                    />
                    : null
                }
            </div>
        )
    }

    renderUploadAudioFile = (): JSX.Element => {
        const { showValidationErrors, id } = this.props
        const greeting: UploadAudio = this.props.greeting as UploadAudio
        const hasError = showValidationErrors && !greeting.isValid()
        const onChange = uploadAudio => {
            this.setState({ uploadAudio })
            this.props.onChange(uploadAudio)
        }
        return (
            <AudioUploader
                id = {`${id}-GreetingUploadAudio`}
                onChange = {onChange}
                loading = {greeting.isLoadingUrl()}
                uploadedAudio = {greeting}
                hasError = {hasError}
                maxSize = {4194304} // 4MB
            />
        )
    }

    renderRecordAMessage = (): JSX.Element => {
        const { showValidationErrors } = this.props
        const greeting: Recording = this.props.greeting as Recording
        const hasError = showValidationErrors && !greeting.isValid()
        const onChange = recording => {
            this.setState({ recording })
            this.props.onChange(recording)
        }
        return (
            <AudioRecorder
                onChange = {onChange}
                recording = {greeting}
                hasError = {hasError}
            />
        )
    }

    renderStoredFile = (): JSX.Element => {
        const { classes, id, selectionList } = this.props
        const storedFile: StoredFile = this.props.greeting as StoredFile
        return (
            <>
                <Select
                    label = 'Select option'
                    value = {storedFile.getId()}
                    onChange = {(event) => {
                        const voipRecordingId: number = event.target.value as number
                        const newStoredFile: StoredFile = selectionList.find(e => e.getId() === voipRecordingId)

                        const differentSelection = newStoredFile.isEqualTo(this.state.storedFile)
                        // eslint-disable-next-line
                        gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `${id};stored-file-select-${voipRecordingId}`, PDC_Value: differentSelection ? GtmMap.OtherOption : GtmMap.SameOption })

                        this.setState({ storedFile: newStoredFile })
                        this.props.onChange(newStoredFile)
                    }}
                    MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                    formControlClasses = {{ root: classes.dropdownMenu }}
                    data-test-id = {`${id}-choose-file-select`}
                >
                    {selectionList.map(file => {
                        const checked = file.getId() === storedFile.getId()
                        return (
                            <MenuItem
                                key = {file.getId()}
                                data-test-id = {`${file.getId()}-custom-file`}
                                value = {file.getId()}
                                checked = {checked}
                                icon = {checked ? CheckIcon : null}
                            >{file.getName()}</MenuItem>
                        )
                    })}
                </Select>
                {storedFile.hasAudio()
                    ? <AudioPlayerWrapper
                        id = 'storedFile'
                        classes = {classes}
                        url = {storedFile.getUrl()}
                        readyToPlayAudio = {storedFile.isReadyToPlay()}
                        onReadyToPlay = {() => {
                            if (!(this.props.greeting instanceof StoredFile)) return null
                            const newGreeting = storedFile.copy()
                            newGreeting.setReadyToPlay()
                            this.props.onChange(newGreeting)
                        }}
                    />
                    : null
                }
            </>
        )
    }

    renderTypeOptions = (): JSX.Element => {
        const { classes, id, hideTTS, selectionList } = this.props
        const greeting: Greeting = this.props.greeting
        const audioTypeNames = this.props.audioTypeNames || {
            [TTS.className]: 'Text to speech',
            [Recording.className]: 'Record a greeting',
            [UploadAudio.className]: 'Upload an audio file',
            [StoredFile.className]: 'Choose from a list'
        }
        const isDisabled = greeting instanceof Recording && !!greeting.isRecording
        const types = [
            { 'data-test-id': 'record-a-message', type: Recording.className },
            { 'data-test-id': 'upload-a-file', type: UploadAudio.className }
        ]
        if (!hideTTS) types.unshift({ 'data-test-id': 'read-a-text', type: TTS.className })
        if (selectionList) types.push({ 'data-test-id': 'stored-file-list', type: StoredFile.className })
        return (
            <Select
                label = {null}
                value = {greeting.className}
                onChange = {(event) => {
                    const greetingType: string = event.target.value as string
                    // eslint-disable-next-line
                    gtmDataPush({ PDC_Action: GtmAppName, PDC_Label: `${id};${greetingType}`, PDC_Value: greeting.className !== greetingType ? GtmMap.OtherOption : GtmMap.SameOption })
                    const greetingsMap = {
                        [TTS.className]: this.state.tts,
                        [Recording.className]: this.state.recording,
                        [UploadAudio.className]: this.state.uploadAudio,
                        [StoredFile.className]: this.state.storedFile
                    }
                    const newGreeting = greetingsMap[greetingType]
                    this.props.onChange(newGreeting)
                }}
                MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                formControlClasses = {{ root: classes.dropdownMenu }}
                disabled = {isDisabled}
                data-test-id = 'menu-option-select'
            >
                {types.map(type => (
                    <MenuItem
                        key = {type.type.toString()}
                        data-test-id = {type['data-test-id']}
                        value = {type.type}
                        checked = {type.type === greeting.className}
                    >{audioTypeNames[type.type]}</MenuItem>
                ))}
            </Select>
        )
    }

    render = (): JSX.Element => {
        const { classes, greeting } = this.props
        const dataTestIdProp = this.props['data-test-id'] ? { 'data-test-id': this.props['data-test-id'] } : {}
        return (
            <div className={classes.audioComponent} {...dataTestIdProp}>
                {this.renderTypeOptions()}
                {greeting instanceof TTS ? this.renderTTS() : null}
                {greeting instanceof Recording ? this.renderRecordAMessage() : null}
                {greeting instanceof UploadAudio ? this.renderUploadAudioFile() : null}
                {greeting instanceof StoredFile ? this.renderStoredFile() : null}
            </div>
        )
    }
}

/***/
export { createGreeting } from './util'
export default withStyles(styles)(AudioComponent)
