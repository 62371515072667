/***/
const styles = (theme) => ({
    splashScreen: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
        padding: '0 65px',
        '& .content': {
            width: 1066,
            maxWidth: '100%',
            height: 364,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: 25,
            '& .left': {
                // flex: 2,
                borderRadius: 25,
                position: 'relative',
                overflow: 'hidden',
                '& .all-set-text': {
                    position: 'absolute',
                    top: '10%',
                    right: 27,
                    width: '52%'
                },
                '& .pdc-logo': {
                    position: 'absolute',
                    bottom: 40,
                    right: 40,
                    color: theme.palette.secondary.light,
                    fontSize: 50
                },
                '& > .thank-you-text': {
                    position: 'absolute',
                    bottom: 110,
                    right: 30,
                    color: 'white',
                    textAlign: 'center'
                },
                '& > .shadow-div': {
                    position: 'absolute',
                    left: 389,
                    bottom: 33,
                    width: 42,
                    height: 150,
                    background: '#4a4a4a55',
                    boxShadow: '0px 0px 17px 35px #4a4a4a55'
                }
            },
            '& .right': {
                // flex: 1,
                background: 'linear-gradient(180deg, #39A626 0%, #3FAE29 23.96%, #79C669 70.31%, #A5DA9B 100%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                rowGap: 15,
                width: 300,
                '& > p': {
                    textAlign: 'center',
                    color: 'white',
                    '&:nth-of-type(2)': {
                        maxWidth: 250
                    }
                },
                '& > button': {
                    marginTop: 20,
                    '& span': {
                        fontWeight: 'bold !important'
                    }
                }
            },
            '& .left, & .right': {
                borderRadius: 25,
                height: '100%'
            }
        },
        '&.mobile': {
            padding: 0,
            display: 'block',
            '& .up': {
                position: 'relative',
                '& .main-image': {
                    width: '100%'
                },
                '& .all-set-text': {
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '55vw'
                }
            },
            '& .down': {
                position: 'relative',
                padding: '30px 20px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                rowGap: 30,
                '& > .thank-you-wrapper': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    rowGap: 10,
                    '& .pdc-logo': {
                        fontSize: 60,
                        color: theme.palette.primary.main
                    },
                    '& > p': {
                        color: theme.palette.text.primary,
                        textAlign: 'center'
                    }
                },
                '& > p': {
                    maxWidth: 282,
                    textAlign: 'center'
                },
                '& > button': {},
                '& > .separator': {
                    width: 110,
                    borderTop: `1px solid ${theme.palette.text.tertiary}`,
                    marginTop: 0,
                    marginBottom: 0
                }
            },
            '& .phonecom-bold': {
                fontWeight: 700
            }
        },
        '&.tablet': {
            padding: '0 50px',
            '& .content': {
                '& .left': {
                    '& > .thank-you-text': {
                        width: 183,
                        right: 4
                    },
                    '& .shadow-div': {
                        left: 353
                    }
                },
                '& .right': {
                    width: 250,
                    '& > p': {
                        '&:nth-of-type(2)': {
                            maxWidth: 134
                        }
                    }
                }
            },
            '&.tablet-portrait': {
                '& .content': {
                    '& .left': {
                        '& .shadow-div': {
                            left: 268
                        }
                    }
                }
            }
        }
    },
    loadingDiv: Object.assign({}, theme.loadingDiv, { zIndex: 5000 }),
    stepWizard: {
        height: '100%',
        '& > .stepper-wrapper': {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 400,
            maxWidth: '100%'
        },
        '& > .content': {
            paddingLeft: 25,
            paddingRight: 25,
            '& .title-wrapper': {
                display: 'flex',
                alignItems: 'center',
                columnGap: 10,
                rowGap: 5,
                '& > svg': {
                    fontSize: 40
                }
            }
        },
        '&.mobile': {
            '& > .content': {
                paddingLeft: 25,
                paddingRight: 25
            },
            '& .title-wrapper': {
                flexDirection: 'column'
            }
        }
    },
    wizardLogo: {
        position: 'absolute',
        top: 30,
        left: 30,
        fontSize: 50,
        color: theme.palette.primary.main
    },
    generalSettingsSection: {
        display: 'flex',
        flexDirection: 'column',
        '& .title': {
            color: theme.palette.info.dark,
            marginBottom: 5
        },
        '& .description': {
            color: theme.palette.text.primary,
            marginBottom: 15
        },
        '& .content': {}
    },
    generalSettings: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 30,
        width: 675,
        margin: 'auto',
        paddingTop: 42,
        '&.mobile': {
            width: '100%'
        }
    },
    businessHours: {
        width: 675,
        margin: 'auto',
        paddingTop: 30,
        '&.mobile': {
            width: '100%'
        }
    },
    callHandling: {
        width: 1138,
        margin: 'auto',
        '&.mobile': {
            width: '100%'
        },
        '& .phone-number-title': {
            color: theme.palette.info.main,
            width: 'fit-content',
            margin: '5px auto 30px'
        },
        '& > div.company-setup': {
            '&.saving': {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'white'
            },
            '& > div.content-wrapper': {
                paddingTop: 0
            }
        }
    },
    infoWrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '& .main-message': {
            margin: '30px 0 20px',
            '&.second': {
                marginBottom: 10
            }
        },
        '& .saving-progress': {
            width: 250
        }
    },
    userSetup: {
        maxWidth: 794,
        marginLeft: 'auto',
        marginRight: 'auto',
        '&.mobile': {
            width: '100%'
        },
        '& .firstnameWrapper': {
            maxWidth: 125
        },
        '& .lastnameWrapper': {
            maxWidth: 125
        },
        '& .emailWrapper': {
            maxWidth: 250
        },
        '& .column-div': {
            paddingTop: 10
        }
    },
    userStepTableTd: {
        verticalAlign: 'top'
    },
    extensionColumn: {
        maxWidth: 100
    },
    tooltip: {
        backgroundColor: '#48B9D5',
        fontSize: 12
    },
    textField: {
        width: 300
    },
    saveProgressContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2000,
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
        '& .save-progress-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            alignItems: 'center',
            justifyContent: 'center',
            height: 'fit-content',
            marginTop: '45vh',
            position: 'relative',
            transform: 'translateY(-100px)',
            '& > p': {
                textAlign: 'center'
            },
            '& .progress-bar-root': {
                maxWidth: 300,
                width: '100%',
                '& .progress-bar-fill': {
                    transition: 'transform 0.8s linear'
                }
            },
            '& .save-info-wrapper': {
                position: 'absolute',
                top: 'calc(100% + 20px)',
                display: 'flex',
                flexDirection: 'column',
                rowGap: 6,
                width: 300,
                '& svg': {
                    fontSize: 20
                },
                '& p': {
                    color: theme.palette.primary.main
                },
                '&.error': {
                    '& p': {
                        color: theme.palette.error.main
                    }
                }
            }
        }
    }
})

export default styles
