<template>
	<v-row v-if="!tight" class="form-field-container" data-test-id="form-input">
		<v-col
			:md="label_cols"
			class="form-label"
			:cols="!label && !$slots.label ? 0 : 12"
			data-test-id="form-input-label"
		>
			<slot name="label">
				<FormInputLabel class="mt-2">{{ label }}</FormInputLabel>
			</slot>
			<p v-if="explanation" :class="explanationClasses" data-test-id="form-explanation">{{ explanation }}</p>
		</v-col>
		<v-col
			:md="input_cols"
			cols="12"
			class="form-input"
			data-test-id="form-input-input-container"
		>
			<slot></slot>
		</v-col>
	</v-row>
	<v-row v-else>
		<v-col md="12" class="d-flex">
			<slot name="label">
				<FormInputLabel class="mr-2">{{ label }}</FormInputLabel>
			</slot>
			<p v-if="explanation" :class="explanationClasses" data-test-id="form-explanation">{{ explanation }}</p>
			<div class="w-100">
				<slot></slot>
			</div>
		</v-col>
	</v-row>
</template>

<script>
	import FormInputLabel from './FormInputLabel.vue';

  export default {
    props: {
			label: {
				required: false,
				type: String,
			},
			explanation: {
				required: false,
				type: String,
			},
			tight: {
				type: Boolean,
				default: false,
			},
			label_cols: {
				type: [String, Number],
				default: 3,
			}
    },
    components: { FormInputLabel },
    data() {
      return {
				explanationClasses: 'form-input-explanation mb-0 pa-0 pl-2 pr-7 pt-2 mt-n3 text--text text--lighten-1',
      };
    },
    computed: {
			input_cols() {
				return 12 - this.$props.label_cols;
			},
    }
  };
</script>

<style lang="scss">
@import 'vue-apps-assets/styles/scss/vars.scss';
.form-label {
	min-height: $input-height !important;
	padding-left: 0px;
}
.form-input-explanation {
	 text-align: left;
	 font-size: 11px;
}
</style>
