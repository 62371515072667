import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import LiveReceptionistImage from './live-receptionist.png'

const styles = theme => ({
	settingWrapper:	{...theme.personalSettingsApp.settingWrapper},
	image: {
		width:		'80%',
    	boxShadow:	'5px 5px 10px -3px black'
	}
})

class LiveReceptionist extends Component {
	render() {
		const { classes } = this.props
		return (
			<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
				<div>
					<a href='https://www.phone.com/features/live-receptionist/' target='_blank'>
						<img className={classes.image} src={LiveReceptionistImage} alt='Live receptionist'/>
					</a>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(LiveReceptionist)