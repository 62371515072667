// import TextField from '@material-ui/core/TextField'
import { TextField } from 'text-field-mui'
import React, { useState, useEffect } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'

// used for labeling different instances
let dataTestId = 0

interface Props {
    value: any
    onChange: any
}

/**
 *
 */
export default function EditExtensionField (props: Props) {
    const [editValue, setEditValue] = useState(null)
    const [errorLabel, setErrorLabel] = useState('')
    const [isBusy, setIsBusy] = useState(false)
    const inputRef = React.useRef()

    useEffect(() => {
        // if the value passed in ins invalid, we need to show that
        if (typeof props.value !== 'number' && !/^\d+$/.test(props.value)) {
            setErrorLabel('Invalid value')
        }
    }, [props.value])

    /**
     *
     */
    const cancel = () => {
        canceled = true
        setEditValue(null)
        setErrorLabel('')
        inputRef.current?.blur()
    }

    /**
     *
     */
    const commit = async (why) => {
        if (editValue === '' || editValue === props.value) return cancel()
        if (isBusy || editValue === null) return
        setErrorLabel('')
        if (editValue && props.value !== editValue) {
            setIsBusy(true)
            try {
                await props.onChange(editValue)
                if (!inputRef.current) return
                // it is expected the container will update props now
                // to redraw the correct value
                setEditValue(null)
            } catch (err) {
                setErrorLabel(err)
            }
        }
        setIsBusy(false)
    }

    // this is used to track if the cancel button is clicked
    // so we can ignore the blur commit - this does not require a hook
    // because the render won't occur
    let canceled = false

    return (
        <TextField
            fullWidth
            noLabelOnActiveOrResolved
            data-testid={dataTestId++}
            label='Extension'
            inputRef={inputRef}
            value={editValue !== null ? editValue : props.value}
            onChange={(evt) => setEditValue(evt.target.value.replace(/\D/g, ''))}
            onKeyDown={(evt) => evt.keyCode === 13 ? commit('keyPress') : evt.keyCode === 27 ? cancel() : null}
            disabled={isBusy}
            error={errorLabel !== ''}
            InputProps={{
                endAdornment:
                    isBusy
                        ? <InputAdornment classes = {{ root: 'adorned-end-x' }} position = 'end'>
                            <CircularProgress size='1em'/>
                        </InputAdornment>
                        : null
            }}
            onBlur={() => !canceled && commit('onBlur')}
        />
    )
}
