<template>
    <w-text-field
        v-bind="$attrs"
        v-on="$listeners"
        :placeholder="placeholder"
        :label="with_label ? placeholder : undefined"
    />
</template>
<script>
  export default {
    props: {
      with_label: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        placeholder: this.$lang.t('app.city', 'City')
      }
    }
  };
</script>
