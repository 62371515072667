<template>
	<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_11141_15609)">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M5.36858 9.59116C5.07028 9.27155 5.29692 8.75 5.73411 8.75L16.2662 8.75C16.7034 8.75 16.93 9.27155 16.6317 9.59116L11.3657 15.2334C11.168 15.4452 10.8323 15.4452 10.6346 15.2334L5.36858 9.59116Z" :fill="black ? '#364047' : 'currentColor'"/>
	</g>
	<defs>
	<clipPath id="clip0_11141_15609">
	<rect width="22" height="22" fill="white" transform="translate(0 0.5)"/>
	</clipPath>
	</defs>
	</svg>

</template>

<script>
  export default {
		props: {
			'black': {
				type: Boolean,
				default: false,
			}
		}
  };
</script>
