import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5537%3A4484
 *
 * @param {SvgIconProps} props - svg props
 */
export const ArrowBackIosIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16.5888 3.58967C16.8817 3.88256 16.8817 4.35744 16.5888 4.65033L9.23914 12L16.5888 19.3497C16.8817 19.6426 16.8817 20.1174 16.5888 20.4103L15.5295 21.4697C15.2366 21.7626 14.7617 21.7626 14.4688 21.4697L5.52947 12.5303C5.23658 12.2374 5.23658 11.7626 5.52947 11.4697L14.4688 2.53033C14.7617 2.23744 15.2366 2.23744 15.5295 2.53033L16.5888 3.58967Z' fill={color}/>
        </SvgIcon>
    )
}

export default ArrowBackIosIcon
