import React, { Component } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import AudioPlayer from '../AudioPlayer'
import LoadingBar from 'loading-bar'
import { DefaultArrowTooltip } from 'tooltips'
import CloseIcon from '@material-ui/icons/Close'
import { Recording } from '../Greeting'
import { FormHelperText } from '@material-ui/core'
import { ThemeProvider } from 'theme-provider'
import Typography from 'typography'
import { SoftAlertIcon } from 'svg-icons'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import ActionButton from './ActionButton'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

interface Props {
    classes
    recording: Recording
    onChange: (recording: Recording) => void
    hasError: boolean
}

interface State {
    recording: Recording
    isBlocked: boolean
    readyToPlay: boolean
    isRecording: boolean
}

class AudioRecorder extends Component<Props, State> {
    recordingInterval: number
    mounted: boolean
    constructor (props) {
        super(props)
        this.state = {
            recording: props.recording || { isRecording: false },
            isBlocked: null,
            readyToPlay: false,
            isRecording: false
        }
        this.mounted = false
    }

    componentDidMount = () => {
        this.mounted = true
    }

    componentWillUnmount = () => {
        this.mounted = false
    }

    componentDidUpdate = (prevProps: Props) => {
        if (!prevProps.recording.isEqualTo(this.props.recording)) {
            this.setState({ recording: this.props.recording || new Recording() })
        }
    }

    getUserMedia = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true })
            this.setState({ isBlocked: false })
        } catch (error) {
            console.log(error)
            this.setState({ isBlocked: true })
        }
    }

    getRecordingDuration = () => {
        const start = this.state.recording.start / 1000
        const end = (this.state.recording.end || Date.now()) / 1000
        return (end - start).toFixed(2)
    }

    startRecording = async () => {
        if (this.state.isBlocked) return console.log('Permission Denied')
        try { await Mp3Recorder.start() } catch (e) {
            const isPermissionDeniedError = e.message === 'Permission denied'
            const isFirefoxPermissionDeniedError = e.message === 'The request is not allowed by the user agent or the platform in the current context.'
            if (isPermissionDeniedError || isFirefoxPermissionDeniedError) {
                alert('The microphone is disabled in the browser. Enable it in order to use this feature.')
            }
            return console.error(e)
        }
        if (!this.mounted) return
        const recording = new Recording(null, null, Date.now(), 0, true)
        this.setState({ recording, readyToPlay: false })
        this.props.onChange(recording)
        this.recordingInterval = window.setInterval(() => {
            const recording = this.state.recording
            recording.currentDuration = this.getRecordingDuration()
            this.setState({ recording })
            this.props.onChange(recording)
        }, 25)
    }

    stopRecording = async () => {
        let blob = null
        try {
            const [, blob2] = await Mp3Recorder.stop().getMp3()
            blob = blob2
            this.setState({ isRecording: false })
            clearInterval(this.recordingInterval)
            delete this.recordingInterval
            const recording = this.state.recording
            recording.isRecording = false
            recording.end = Date.now()
            this.setState({ recording })
            this.props.onChange(recording)

            this.onRecordingComplete(blob)
        } catch (e) { return console.error(e) }
    }

    onRecordingComplete = blob => {
        const reader = new FileReader()
        const filename = `recording ${Date.now()}`
        reader.onload = async () => {
            const result = reader.result
            const recordedAudio = { downloadLink: result, filename }
            const recording = this.state.recording
            recording.recordedAudio = recordedAudio
            this.setState({ recording })
            this.props.onChange(recording)
        }
        reader.readAsDataURL(blob)
    }

    removeRecordedAudio = () => {
        const recording = new Recording()
        this.setState({ recording })
        this.props.onChange(recording)
    }

    toggleRecord = () => {
        if (this.state.isBlocked === null) this.getUserMedia()

        if (this.state.recording && this.state.recording.isRecording) this.stopRecording()
        else this.startRecording()
    }

    renderRecordSection = () => {
        const { classes, hasError } = this.props
        const recordingDuration = this.state.recording.currentDuration
        const isRecording = this.state.recording && this.state.recording.isRecording
        const disabled = this.state.isBlocked
        return (
            <div className={classes.recordSection}>
                <DefaultArrowTooltip
                    title = {disabled ? 'Enable audio recording in your browser' : ''}
                    placement = 'right'
                >
                    <div>
                        <ActionButton classes={classes} isRecording={isRecording} onClick={this.toggleRecord}/>
                        {hasError
                            ? <ThemeProvider>
                                <FormHelperText error>
                                    <SoftAlertIcon/>
                                    <Typography variant='helperText'>Required field</Typography>
                                </FormHelperText>
                            </ThemeProvider>
                            : null
                        }
                    </div>
                </DefaultArrowTooltip>
                <span className={classes.recordInfo}>
                    {this.state.recording.isRecording ? <span>Recording {recordingDuration}s</span> : null}
                </span>
            </div>
        )
    }

    renderRecordingSection = () => {
        const { classes } = this.props
        return (
            <div className={classes.audioSection}>
                <div className={classes.audioPlayerWrapper} style={{ display: this.state.readyToPlay ? 'block' : 'none' }}>
                    <AudioPlayer
                        url = {this.state.recording.recordedAudio.downloadLink}
                        onError = {this.removeRecordedAudio}
                        onReadyToPlay = {() => this.setState({ readyToPlay: true })}
                    />
                </div>
                {!this.state.readyToPlay
                    ? <LoadingBar className={classes.loadingBar}/>
                    : <DefaultArrowTooltip
                        title = 'Remove'
                        placement = 'right'
                        leaveDelay = {1}
                        enterTouchDelay = {0}
                    >
                        <div className={classes.removeButton} onClick={this.removeRecordedAudio}><CloseIcon/></div>
                    </DefaultArrowTooltip>
                }
            </div>
        )
    }

    render = () => {
        return (!this.state.recording.recordedAudio
            ? this.renderRecordSection()
            : this.renderRecordingSection()
        )
    }
}

export default withStyles(styles)(AudioRecorder)
