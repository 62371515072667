import { PhoneNumber } from './PhoneNumber'

function checkIfValidRulesGroup (smsForwarding, numberRulesGroup) {
    const numberRules = numberRulesGroup.rules
    const actions = numberRules.actions
    const ruleTypes = numberRules.rule_types
    // Unsupported rule types
    if (ruleTypes.filter(t => !['ring-users', 'play-menu', 'voicemail', 'play-recording'].includes(t)).length) return false

    if (!smsForwarding) return false

    // Voicemail
    if (ruleTypes.includes('voicemail')) {
        // It can be either only voicemail or ring-users + voicemail or play-recording + ring-users + voicemail
        if (ruleTypes.length > 3) return false
        if (ruleTypes.filter(t => !['ring-users', 'voicemail', 'play-recording'].includes(t)).length) return false
    }

    // Ring users
    if (ruleTypes.includes('ring-users')) {
        if (ruleTypes.length === 3 && (!ruleTypes.includes('voicemail') || !ruleTypes.includes('play-recording') || numberRules.actions[0].type !== 'play-recording')) return false
        if (ruleTypes.length === 2 && !ruleTypes.includes('voicemail')) return false
        if (![2, 3].includes(ruleTypes.length)) return false
        const ringUsersActions = actions.filter(a => a.type === 'ring-users')
        if (ringUsersActions.length > 1) return false
    }

    // Menu
    if (ruleTypes.includes('play-menu')) {
        // It can have (but doesn't have to) play-recording
        if (ruleTypes.length === 2 && !ruleTypes.includes('play-recording')) return false
        if (ruleTypes.length > 2) return false
        const playMenuAction = actions.find(a => a.type === 'play-menu')
        const playMenuData = playMenuAction.data
        // Check if there is at least 1 option that has something else than ring-users or voicemail
        const notSupportedOption = Object.keys(playMenuData.options).find(o => {
            const optionRuleGroups = playMenuData.options[o].rule_groups
            if (optionRuleGroups.length > 1) return false
            if (optionRuleGroups[0].type !== 'all') return false
            if (optionRuleGroups[0].rules.rule_types.find(type => !['ring-users', 'voicemail'].includes(type))) return false
            if (optionRuleGroups[0].rules.rule_types.length > 2) return false
            return false
        })
        if (notSupportedOption) return false
        // The 'on_nothing_selected' option can be one of these: 'ring-users', 'voicemail', 'disconnect'
        const onNothingSelected = playMenuData.on_nothing_selected
        const onNothingSelectedRuleGroups = onNothingSelected.rule_groups
        if (!onNothingSelectedRuleGroups || onNothingSelectedRuleGroups?.length > 1) return false
        if (onNothingSelectedRuleGroups[0].type !== 'all') return false
        if (onNothingSelectedRuleGroups[0].rules.rule_types.length !== 1) return false
        const onNothingSelectedType = onNothingSelectedRuleGroups[0].rules.rule_types[0]
        if (!['ring-users', 'voicemail', 'disconnect'].includes(onNothingSelectedType)) return false
        if (onNothingSelectedType === 'ring-users' && onNothingSelectedRuleGroups[0].rules.actions.length !== 1) return false
    }
    return true
}

function checkIfValidOneGroupRules (phoneNumber) {
    const smsForwarding = phoneNumber.sms_forwarding
    const numberRulesGroups = phoneNumber.call_rules.rule_groups
    const numberRulesGroup = numberRulesGroups[0]
    if (numberRulesGroup.type !== 'all') return false
    // if (actions.length === 0) return true

    const numberRules = numberRulesGroup.rules
    const actions = numberRules.actions
    const ruleTypes = numberRules.rule_types
    // Unsupported rule types
    const isDirectLine = ruleTypes.length === 2 && ruleTypes.includes('ring-users') && ruleTypes.includes('voicemail')
    if (!isDirectLine && ruleTypes.filter(t => !['ring-users', 'play-menu', 'voicemail', 'fax', 'play-recording'].includes(t)).length) return false

    // Fax
    if (ruleTypes.includes('fax')) {
        if (ruleTypes.length > 1) return false
        // Check if the fax extension is different than the sms forwarding extension
        const faxAction = actions.find(a => a.type === 'fax')
        if (smsForwarding?.extension.id !== faxAction.data.extension_id) return false
    } else {
        // // Ring users
        // if (ruleTypes.includes('ring-users')) {
        //     const rules = phoneNumber.call_rules.rule_groups[0].rules
        //     const ringUsersAction = rules.actions.find(a => a.type === 'ring-users')
        //     if (ringUsersAction.data.extensions.length > 1) return false
        // }

        if (!checkIfValidRulesGroup(smsForwarding, numberRulesGroup)) return false
    }

    return true
}

function checkIfValidMultiGroupRules (phoneNumber) {
    const companyExtensionId = window.V5PHONECOM?.companyExtension?.id
    const smsForwarding = phoneNumber.sms_forwarding
    const isAssignedToCompany = smsForwarding?.extension.id === companyExtensionId

    const numberRulesGroups = phoneNumber.call_rules.rule_groups
    if (
        !isAssignedToCompany && (
            (numberRulesGroups[0].type !== 'schedule' && numberRulesGroups[0].schedule.name === 'custom-holidays') ||
            (numberRulesGroups[1].type !== 'schedule' && numberRulesGroups[0].schedule.name === 'holidays') ||
            (numberRulesGroups[2].type !== 'schedule' && numberRulesGroups[0].schedule.name === 'lunch-break') ||
            (numberRulesGroups[3].type !== 'schedule' && numberRulesGroups[0].schedule.name === 'open-hours') ||
            numberRulesGroups[4].type !== 'all'
        )
    ) return false

    let isValid = true
    numberRulesGroups.forEach((numberRulesGroup, i) => {
        if (!isValid) return
        isValid = checkIfValidRulesGroup(smsForwarding, numberRulesGroup)
    })
    return isValid
}

function checkForUnsupportedRules (phoneNumber) {
    const numberRulesGroups = phoneNumber.call_rules.rule_groups
    // Currently we support the default ('all') rule group and 'schedule' rule groups

    // It will have 5 rules in case company business hours are used
    // or 1 rule in case they aren't used
    if (![1, 5].includes(numberRulesGroups.length)) return true
    if (numberRulesGroups.length === 1) return !checkIfValidOneGroupRules(phoneNumber)
    if (numberRulesGroups.length === 5) return !checkIfValidMultiGroupRules(phoneNumber)
    return false
}

/**
 * @param {PhoneNumber} phoneNumber
 */
export function getPhoneNumberType (phoneNumber: PhoneNumber): string {
    // Set the type
    const rulesGroups = phoneNumber.call_rules?.rule_groups
    if (!rulesGroups || !rulesGroups.length) return null
    if (rulesGroups.some((ruleGroup: any): boolean => {
        return ruleGroup.type === 'schedule' && !ruleGroup.schedule
    })) {
        phoneNumber.call_rules.rule_groups = []
        return 'Advanced rules'
    }
    // if (phoneNumber.phone_number === '+12035387303') debugger
    if (checkForUnsupportedRules(phoneNumber)) return 'Advanced rules'

    const companyExtensionId = window.V5PHONECOM?.companyExtension?.id

    if (
        rulesGroups.length === 1 &&
        rulesGroups[0].rules.rule_types.length === 2 &&
        rulesGroups[0].rules.rule_types.includes('ring-users') &&
        rulesGroups[0].rules.rule_types.includes('voicemail') &&
        rulesGroups[0].rules.actions[0].data.extensions?.length === 1 &&
        rulesGroups[0].rules.actions[0].data.extensions[0].extension_id === companyExtensionId &&
        rulesGroups[0].rules.actions[1].data.extension_id === companyExtensionId
    ) return 'Main company number'

    if (rulesGroups.length > 1) return 'Multiple rules set'

    const rules = rulesGroups[0].rules
    const ruleTypes = [...rules.rule_types]
    const playRecordingIndex = ruleTypes.findIndex(e => e === 'play-recording')
    if (playRecordingIndex !== -1) ruleTypes.splice(playRecordingIndex, 1)
    if (ruleTypes.length === 1) {
        if (ruleTypes[0] === 'voicemail') return 'Voicemail'
        if (ruleTypes[0] === 'fax') return 'Fax'
        if (ruleTypes[0] === 'play-menu') return 'Menu'
    } else if (ruleTypes.length === 2) {
        if (ruleTypes.includes('ring-users') && ruleTypes.includes('voicemail')) {
            const ringUsersAction = rules.actions.find(a => a.type === 'ring-users')
            return ringUsersAction.data.extensions.length > 1 ? 'Users' : 'Users' // 'Direct Number'
        }
    }
    return null
}
