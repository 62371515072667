import React from 'react'
import { LinearProgressProps } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import { ThemeProvider } from 'theme-provider'

interface Props extends Omit<LinearProgressProps, 'color'> {
    color: 'primary' | 'secondary' | 'error'
}

const getClasses = (props: Props) => {
    const color = props.color
    if (!color) return props.classes
    const classes = { ...props.classes || {} }
    if (!classes.root) classes.root = ''
    classes.root += ` color-${color}`
    return classes
}

/***/
export const ProgressBar = (props: Props): JSX.Element => (
    <ThemeProvider>
        <LinearProgress
            {...props}
            variant = 'determinate'
            classes = {getClasses(props)}
            color = 'primary'
        />
    </ThemeProvider>
)

export default ProgressBar
