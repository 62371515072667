
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { RedirectIcon } from 'pdc-svg-icons'


const styles = theme => ({
	header:			theme.settingsApp.header,
	settingWrapper:	theme.settingsApp.settingWrapper,
	cancelAccountWrapper: {
		maxHeight:		'100%',
		display:		'flex',
		flexDirection:	'column'
	},
	textLine: {
		fontSize: 15,
		fontWeight: 500,
		'& span': {
			color: theme.palette.primary[0],
			cursor: 'pointer'
		},
		'& svg': { 
			color: theme.palette.primary[0]
		},
		'&:last-child': {
			marginTop: 15
		}
	}
})


const mapStateToProps = state => ({
	smallView:	state.smallView,
})

const mapDispatchToProps = dispatch => ({
})


class CancelAccount extends Component {

	render() {
		const { classes } = this.props
		return (
            <div className={classes.cancelAccountWrapper}>
				<div className={classes.textLine}>To cancel your account contact our team at (877) 472-9245</div>
				<div className={classes.textLine}>Or submit a <span onClick={() => window.open('https://www.phone.com/support-request/', '_blank')}>service request <RedirectIcon/></span></div>
            </div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CancelAccount))