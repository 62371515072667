import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.1.0?node-id=7747%3A18448
 *
 * @param {SvgIconProps} props - svg props
 */
export const DownloadIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M21 22H3C2.72386 22 2.5 21.7761 2.5 21.5V20C2.5 19.7239 2.72386 19.5 3 19.5H21C21.2761 19.5 21.5 19.7239 21.5 20V21.5C21.5 21.7761 21.2761 22 21 22Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M18.2787 8.30943C18.591 8.5956 18.6046 9.08343 18.3086 9.38645L12.3913 15.4446C12.0948 15.7482 11.6057 15.7455 11.3126 15.4387L5.50887 9.36507C5.22635 9.06942 5.23276 8.60194 5.52326 8.31413L6.30948 7.53521C6.60663 7.24083 7.08716 7.2464 7.37739 7.5476L10.8974 11.2005V1.5C10.8974 1.22386 11.1213 1 11.3974 1H12.3022C12.5783 1 12.8022 1.22386 12.8022 1.5V11.2005L16.3422 7.52668C16.6246 7.23369 17.089 7.21925 17.389 7.49413L18.2787 8.30943Z' fill={color}/>
        </SvgIcon>
    )
}

export default DownloadIcon
