<template>
	<svg v-if="backward" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M16.5888 3.58967C16.8817 3.88256 16.8817 4.35744 16.5888 4.65033L9.23914 12L16.5888 19.3497C16.8817 19.6426 16.8817 20.1174 16.5888 20.4103L15.5295 21.4697C15.2366 21.7626 14.7617 21.7626 14.4688 21.4697L5.52947 12.5303C5.23658 12.2374 5.23658 11.7626 5.52947 11.4697L14.4688 2.53033C14.7617 2.23744 15.2366 2.23744 15.5295 2.53033L16.5888 3.58967Z" fill="currentColor"/>
	</svg>
	<svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M7.41119 3.58967C7.1183 3.88256 7.1183 4.35744 7.41119 4.65033L14.7609 12L7.41119 19.3497C7.1183 19.6426 7.1183 20.1174 7.41119 20.4103L8.47053 21.4697C8.76342 21.7626 9.2383 21.7626 9.53119 21.4697L18.4705 12.5303C18.7634 12.2374 18.7634 11.7626 18.4705 11.4697L9.53119 2.53033C9.2383 2.23744 8.76342 2.23744 8.47053 2.53033L7.41119 3.58967Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {
	props: ['backward'],
  };
</script>
