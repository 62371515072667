<template>
	<DescriptiveSelector v-bind="$attrs" v-on="$listeners" :items="items" :loading="loading"/>
</template>
<script>
import Cachier from 'cachier';
import l from '../../libs/lang';
import DescriptiveSelector from './form/descriptive-selector/SelectorInput.vue';

export default {
	props: {
		allowed_values: {
			type: [Array, null],
			validator: (v) => {
				if (v) return v.every((x) => ['FEDEXGROUND', 'STANDARDOVERNIGHT', '3DAY', '2DAY', 'PRIORITYOVERNIGHT', 'OVERNIGHTEARLYAM'].includes(x))
				return true;
			}
		}
	},
	components: {
		DescriptiveSelector,
	},
	data() {
		return {
			l,
			shipping_methods: [],
			cache_key: 'shipping-methods',
			cachier: new Cachier(this.$session.user.id),
			loading: false,
			translations: {
				'FEDEXGROUND': { // needs to be listed separately, because translation package has to pick it
					name: l.t('shipping.FEDEXGROUND-title', 'Standard Shipping'),
					description: l.t('shipping.FEDEXGROUND-description', 'Up to 5 business days based on distance to destination'),
				},
				'STANDARDOVERNIGHT': {
					name: l.t('shipping.STANDARDOVERNIGHT-title', 'Overnight Shipping'),
					description: l.t('shipping.STANDARDOVERNIGHT-description', 'Next business day by 3PM; by 8PM to residences'),
				},
				'3DAY': {
					name: l.t('shipping.3DAY-title', '3-Day Shipping'),
					description: l.t('shipping.3DAY-description', '3rd business day by 4:30PM; by 8PM to residences'),
				},
				'2DAY': {
					name: l.t('shipping.2DAY-title', '2-Day Shipping'),
					description: l.t('shipping.2DAY-description', '2nd business day by 4:30PM; by 8PM to residences'),
				},
				'PRIORITYOVERNIGHT': {
					name: l.t('shipping.PRIORITYOVERNIGHT-title', 'Priority Overnight Shipping'),
					description: l.t('shipping.PRIORITYOVERNIGHT-description', 'Next business day by 10:30AM'),
				},
				'OVERNIGHTEARLYAM': {
					name: l.t('shipping.OVERNIGHTEARLYAM-title', 'Early Morning Overnight Shipping'),
					description: l.t('shipping.OVERNIGHTEARLYAM-description', 'Next business day by 8 or 8:30AM'),
				},
			},
		};
	},
	async created() {
		this.$data.loading = true;
		const cached_methods = this.$data.cachier.getItem(this.$data.cache_key);
		if (Array.isArray(cached_methods)) {
			this.$data.shipping_methods = cached_methods;
		} else {
			const response = await this.$session.get_list_all('/shipping-price-list');
			this.$data.shipping_methods = response.items;
			this.$data.cachier.setItem(this.$data.cache_key, this.$data.shipping_methods);
		}
		this.$emit('shipping_methods_items', this.$data.shipping_methods);
		this.$data.loading = false;

		this.$forceUpdate();
	},
	methods: {
	  price(val) {
			if (val === 0) return l.t('app.free', 'Free');
		    return `$${(val / 100).toFixed(2)} ${l.t('app.per-device', 'Per device')}`;
	  }
	},
	computed: {
		items() {
			const items = [];
			let methods = this.$data.shipping_methods;
			if (this.$props.allowed_values) methods = methods.filter((x) => this.$props.allowed_values.includes(x.value))
			for (const method of methods) {
				items.push({
					text: this.translations[method.value] ? this.translations[method.value].name : method.name,
					value: method.value,
					label: this.price(method.price),
					description: this.translations[method.value] ? this.translations[method.value].description : method.description,
				});
			}

			return items;
		},
	}
};
</script>
