<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
        fill="currentColor" stroke="none">
            <path d="M330 1799 c-113 -93 -217 -183 -232 -200 l-28 -29 0 -385 0 -384 22
            -25 c13 -14 219 -190 458 -391 360 -303 440 -365 462 -363 18 2 163 119 464
            373 241 204 446 378 456 388 17 16 18 46 18 400 0 210 -4 388 -8 395 -5 6
            -109 97 -233 202 l-224 189 -475 0 -475 1 -205 -171z m620 -235 c0 -228 -3
            -284 -12 -277 -7 5 -82 65 -165 132 l-153 122 0 154 0 155 165 0 165 0 0 -286z
            m450 132 l0 -154 -122 -98 c-68 -54 -142 -113 -165 -132 l-43 -34 0 286 0 286
            165 0 165 0 0 -154z m-900 -41 l0 -114 -146 -123 c-80 -68 -150 -124 -155
            -126 -5 -2 -9 46 -9 115 l0 118 148 122 c81 68 150 123 155 123 4 0 7 -52 7
            -115z m1181 -11 l149 -126 0 -115 c0 -68 -4 -113 -10 -111 -5 2 -74 57 -154
            123 l-145 120 0 118 c-1 64 2 117 5 117 3 0 73 -57 155 -126z m-906 -384 c123
            -98 221 -170 234 -170 12 0 107 69 223 161 112 89 208 166 215 171 9 7 67 -36
            198 -147 l185 -156 0 -133 0 -133 -267 -225 -266 -226 -60 44 c-139 103 -333
            103 -467 2 -36 -27 -58 -38 -66 -32 -7 5 -125 104 -263 219 l-250 210 2 140 2
            139 180 153 c99 84 182 153 184 153 2 0 99 -76 216 -170z m320 -872 c35 -13
            95 -55 95 -67 0 -5 -41 -43 -90 -85 l-91 -77 -99 83 c-108 91 -109 93 -38 129
            75 38 149 44 223 17z"/>
        </g>
    </svg>
</template>

<script>
  export default {};
</script>
