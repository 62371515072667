import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5522%3A1487
 *
 * @param {SvgIconProps} props - svg props
 */
export const CloseIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M18.6464 6.76355C18.8417 6.56829 18.8417 6.25171 18.6464 6.05645L17.9436 5.35355C17.7483 5.15829 17.4317 5.15829 17.2364 5.35355L12 10.59L6.76355 5.35355C6.56829 5.15829 6.25171 5.15829 6.05645 5.35355L5.35355 6.05645C5.15829 6.25171 5.15829 6.56829 5.35355 6.76355L10.59 12L5.35355 17.2364C5.15829 17.4317 5.15829 17.7483 5.35355 17.9436L6.05645 18.6464C6.25171 18.8417 6.56829 18.8417 6.76355 18.6464L12 13.41L17.2364 18.6464C17.4317 18.8417 17.7483 18.8417 17.9436 18.6464L18.6464 17.9436C18.8417 17.7483 18.8417 17.4317 18.6464 17.2364L13.41 12L18.6464 6.76355Z' fill={color}/>
        </SvgIcon>
    )
}

export default CloseIcon
