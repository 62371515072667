<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5628_762)">
<path d="M8.79409 4.57752C8.4636 4.33716 8 4.57324 8 4.98189V19.0181C8 19.4268 8.4636 19.6628 8.79409 19.4225L18.444 12.4044C18.7185 12.2047 18.7185 11.7953 18.444 11.5956L8.79409 4.57752Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_5628_762">
<rect width="24" height="24" fill="currentColor"/>
</clipPath>
</defs>
</svg>

</template>

<script>
  export default {
  };
</script>
