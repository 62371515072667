import React from 'react'
import { TimeItems, TimeItem } from './timeItem'
import { ScheduleItems, ScheduleItem } from './scheduleItem'
import RemoteConfigValue from 'remote-config-value'
const daysMap: {day: string, short: string}[] = [
    {
        day: 'Monday',
        short: 'M'
    },
    {
        day: 'Tuesday',
        short: 'T'
    },
    {
        day: 'Wednesday',
        short: 'W'
    },
    {
        day: 'Thursday',
        short: 'Th'
    },
    {
        day: 'Friday',
        short: 'F'
    },
    {
        day: 'Saturday',
        short: 'S'
    },
    {
        day: 'Sunday',
        short: 'Su'
    }
]

/**
 * @param {number} hours
 * @param {number} minutes
 * @param {string} m
 */
const formatTime = (hours, minutes, m) => {
    const timeItem = new TimeItem({ hours, minutes, m })
    return timeItem.toString12()
}

const formatHourItem = (hourString: string): { content: string, value: string, disabled?: boolean } => {
    const content = hourString
    const value = hourString.replace(/\s/g, '')
    return { content, value }
}

/**
 * @param {string} item1
 * @param {string} item2
 */
const compareTimeItems = (item1, item2) => {
    const timeItem1 = new TimeItem(item1)
    const timeItem2 = new TimeItem(item2)
    return TimeItems.compare(timeItem1, timeItem2)
}

/**
 * @param {object} range - { from, to, includeFrom, includeTo }
 */
const getHours = (range) => {
    const hours = []
    const amPm = ['am', 'pm']
    const minutesArray = ['00', '30']
    const hoursArray = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

    const allTimesArray = []
    for (let m = 0; m < amPm.length; m++) {
        for (let i = 0; i < hoursArray.length; i++) {
            for (let j = 0; j < minutesArray.length; j++) {
                const timeItemString12 = formatTime(hoursArray[i], minutesArray[j], amPm[m])
                allTimesArray.push(timeItemString12)
            }
        }
    }

    if (range.overflow) {
        if (range.from) {
            const fromIndex = allTimesArray.findIndex(e => e === range.from)
            allTimesArray.push(...allTimesArray.splice(0, fromIndex))
        } else if (range.to) { /**/ }
        hours.push(...allTimesArray.map(e => {
            const formatted = formatHourItem(e)
            if (range.calculateDistance) {
                const timeDistance = TimeItems.getTimeDistance(new TimeItem(range.from), new TimeItem(e))
                formatted.content += ` (${timeDistance} hours)`
            }
            return formatted
        }))
    } else {
        allTimesArray.forEach(time => {
            const hourItem = formatHourItem(time)
            if (range.from) {
                const comparation = compareTimeItems(range.from, hourItem.value)
                if ((!range.includeFrom && comparation > -1) || (range.includeFrom && comparation === 1)) hourItem.disabled = true
            }
            if (range.to) {
                const comparation = compareTimeItems(range.to, hourItem.value)
                if ((!range.includeTo && comparation < 1) || (range.includeTo && comparation === -1)) hourItem.disabled = true
            }
            if (range.calculateDistance) {
                const timeDistance = TimeItems.getTimeDistance(new TimeItem(range.from), new TimeItem(time))
                if (!hourItem.disabled) hourItem.content += ` (${timeDistance} hours)`
            }
            hours.push(hourItem)
        })
        const lastHourItem = formatHourItem(formatTime(11, 59, 'pm'))
        if (range.to) {
            const comparation = compareTimeItems(range.to, lastHourItem.value)
            if ((!range.includeTo && comparation < 1) || (range.includeTo && comparation === -1)) lastHourItem.disabled = true
        }
        if (range.calculateDistance) {
            const timeDistance = TimeItems.getTimeDistance(new TimeItem(range.from), new TimeItem('11:59 pm'))
            lastHourItem.content += ` (${timeDistance} hours)`
        }
        hours.push(lastHourItem)
    }

    return hours
}

const holidayOptions = [
    // { content: 'Open', value: 'open' },
    { content: 'Closed', value: 'closed' },
    { content: 'Custom Hours', value: 'custom' }
]

const customHolidayOptions = [
    // { content: 'Open', value: 'open', 'data-test-id': 'open' },
    { content: 'Closed', value: 'closed', 'data-test-id': 'closed' },
    { content: 'Custom Hours', value: 'custom', 'data-test-id': 'custom' }
]

const expirationOptions = [
    { content: <RemoteConfigValue valueId='custom_holidays_repeat_yearly_label'/>, value: 'off', 'data-test-id': 'every-year' },
    { content: <RemoteConfigValue valueId='custom_holidays_use_once_label'/>, value: 'on', 'data-test-id': 'only-once' }
]

/**
 * @param {object} item1
 * @param {object} item2
 * @returns
 */
const getFormattedScheduleItemsIntersectionTimes = (item1, item2) => {
    const fromTimeItem1 = new TimeItem(item1.from)
    const toTimeItem1 = new TimeItem(item1.to)
    const fromTimeItem2 = new TimeItem(item2.from)
    const toTimeItem2 = new TimeItem(item2.to)
    const scheduleItem1 = new ScheduleItem({ from: fromTimeItem1, to: toTimeItem1 })
    const scheduleItem2 = new ScheduleItem({ from: fromTimeItem2, to: toTimeItem2 })
    const { from, to } = ScheduleItems.getIntersectionTimes(scheduleItem1, scheduleItem2)
    return { from, to }
}

/**
 * @param {Array} scheduleItems
 * @param {boolean} formatted - i.e. has this format { from, to, status, text } if true
 */
const getScheduleItemsUnionTimes = (scheduleItems, formatted = false) => {
    // If there is a gap it will consider it as part of the union
    // Example: {from: 10:00 am, to: 11:00 am} and {from: 3:00 pm, to: 4:00 pm} will give {from: 10:00 am, to: 4:00 pm}
    const response: { from?: string, to?: string } = {}
    scheduleItems.forEach(scheduleItem => {
        const startHour = scheduleItem.start_hour
        const startMinute = scheduleItem.start_minute
        const endHour = scheduleItem.end_hour
        const endMinute = scheduleItem.end_minute
        const from = formatted ? scheduleItem.from : TimeItem.generate12HoursString(startHour, startMinute)
        const to = formatted ? scheduleItem.to : TimeItem.generate12HoursString(endHour, endMinute)
        if (compareTimeItems(from, to) === 1) {
            response.from = '12:00 am'
            response.to = '11:59 pm'
        }
        if (!response.from) response.from = from
        else if (compareTimeItems(response.from, from) === 1) response.from = from
        if (!response.to) response.to = to
        else if (compareTimeItems(response.to, to) === -1) response.to = to
    })
    if (!response.from) return null
    return response
}

/***/
export {
    getHours,
    getFormattedScheduleItemsIntersectionTimes,
    getScheduleItemsUnionTimes,
    daysMap,
    holidayOptions,
    customHolidayOptions,
    expirationOptions
}
