export function setSmallView(smallView) {
	return {
		type: 'SET_SMALL_VIEW',
		smallView
	}
}

export function switchView(view) {
	return {
		type: 'SWITCH_VIEW',
		view
	}
}