import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'
import { formatPhoneNumber } from 'phone-numbers'
/**
 *
 */
export class Contact {
   public addresses: Map<string, Address> = new Map<string, Address>()
   public cursor: string
   public emails: string[] = []
   public group: Group
   public id: number
   public jobTitle = ''
   public name: Name
   public notes = ''
   public numbers: Map<string, PhoneNumber> = new Map()
   public organization = ''
   public voipPhoneId: number
   /**
    * @param {string} number
    */
        public has = (number: string) => {
            number = formatPhoneNumber(number)
            return this.numbers.has(number)
        }

    /**
     * @param {string} number
     */
    public includes = (searchString: string) => {
        let found = false
        const fnumber = searchString.replace(/[^0-9]/g, '')
        if (fnumber.length > 0) {
            this.numbers.forEach((value, key) => {
                const fnum = key.replace(/[^0-9]/g, '')
                if (fnum.includes(fnumber)) found = true
            })
        }
        return found || this.name?.includes(searchString)
    }

    /**
     * @param {any} json
     */
    public static fromJson (json: any): Contact {
        const contact = new Contact()
        // validate(json)
        // primitive fields
        contact.id = json.id || json.contact_id
        contact.cursor = json.cursor
        contact.jobTitle = json.job_title
        contact.organization = json.organization
        contact.notes = json.notes
        // nested fields
        contact.name = new Name(json.name)
        contact.group = new Group(json.group)
        contact.addresses = Address.parseAddresses(json.addresses)
        contact.numbers = PhoneNumber.parseNumbers(json.numbers)
        contact.emails = json.emails
        contact.voipPhoneId = json.voip_phone_id
        return contact
    }

    /**
     * @param {Contact} contact
     */
    public static update = async (contact: Contact): Promise<Contact> => {
        // if contact has id then contact exists on remote, update remote
        if (contact.id) {
            return Contact.updateContact(contact).then(res => {
                // validate
                if (res) {
                    if (res.data.group_id) {
                        contact.group.id = res.data.group_id
                    }
                    return contact
                } else {
                    // creation failed
                    return contact
                }
            })
        }
        // else remote call to create contact
        return Contact.createContact(contact).then(res => {
            // validate
            if (res.data) {
                if (res.data.contact_id) {
                    contact.id = res.data.contact_id
                }
                if (res.data.group_id) {
                    contact.group.id = res.data.group_id
                }
                return contact
            } else {
                // creation failed
                return contact
            }
        })
    }

    /**
     * @param {number} id
     */
    public static delete = async (id: number): Promise<boolean> => {
        return Contact.deleteContact(id)
            .then((res) => {
                if ((res.data && res.data.message === 'success')) {
                    return true
                } else {
                    console.error('DELETE CONTACT FAILED')
                }
                return false
            })
    }

    /**
     *
     */
    public toJson = () => {
        const json: any = {}
        json.contact_id = this.id
        json.cursor = this.cursor
        json.job_title = this.jobTitle
        json.organization = this.organization
        json.notes = this.notes
        json.name = this.name
        json.group = this.group
        json.addresses = {
            home: this.addresses.get('home'),
            business: this.addresses.get('business')
        }
        json.emails = this.emails
        // convert numbers map back to array
        const numbers = []
        this.numbers.forEach((value, key, map) => {
            numbers.push(value)
        })
        json.numbers = numbers
        return json
    }

    /**
     * @param {Contact} contact
     */
    public static createContact = async (contact: Contact): Promise<any> => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/create-contact`
        return ajax.post(requestUrl, contact.toJson())
    }

    /**
     * @param {Contact} contact
     */
    public static updateContact = async (contact: Contact): Promise<any> => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/update-contact`
        return ajax.post(requestUrl, contact.toJson())
    }

    /**
     * @param {number} contactId
     */
    public static deleteContact = async (contactId: number): Promise<any> => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/delete-contact`
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return ajax.post(requestUrl, { contact_id: contactId })
    }
}
class PhoneNumber {
    public number = ''
    public type = 'home'
    // types: Array<string>    = ['home', 'business', 'mobile', 'fax', 'pager']
    public constructor (number) {
        this.number = formatPhoneNumber(number.number)
        this.type = number.type
    }

    public static parseNumbers (json): Map<string, PhoneNumber> {
        const numbers = new Map<string, PhoneNumber>()
        let number: PhoneNumber
        json.forEach(element => {
            number = new PhoneNumber(element)
            if (number.number.length > 0) numbers.set(element.number, number)
        })
        return numbers
    }

    public equals = (other: PhoneNumber) => {
        const replacedThis = this.number.replace(/\D/g, '')
        const replacedOther = other.number.replace(/\D/g, '')
        return replacedThis === replacedOther
    }
}
class Address {
    // types: Array<string>    = ['home', 'business']
    public address = ''
    public city = ''
    public state = ''
    public country = ''
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public zip_code = ''
    public static parseAddresses (json: any): Map<string, Address> {
        const addresses = new Map<string, Address>()
        let address: Address
        Object.keys(json).forEach(type => {
            address = new Address(json[type])
            addresses.set(type, address)
        })
        return addresses
    }

    public constructor (address) {
        this.address = address.address
        this.city = address.city
        this.state = address.state
        this.country = address.country
        this.zip_code = address.zip_code
    }
}
class Group {
    public name = ''
    public id = 0
    public constructor (group) {
        this.name = group.name
        this.id = group.id
    }
}
class Name {
    public display = ''
    public first = ''
    public middle = ''
    public last = ''
    public nick = ''
    public constructor (name) {
        this.display = name.display
        this.first = name.first
        this.middle = name.middle
        this.last = name.last
        this.nick = name.nick
    }

    public includes = (search: string) => {
        search = search.toLowerCase()
        return this.display.toLowerCase().includes(search) ||
            this.first.toLowerCase().includes(search) ||
            this.middle.toLowerCase().includes(search) ||
            this.last.toLowerCase().includes(search) ||
            this.nick.toLowerCase().includes(search)
    }
}
