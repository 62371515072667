import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import AvatarIcon from 'avatar-icon'
import SelectionControl from 'selection-control'
import Options from 'pdc-options'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import PDCButton from 'button'
import { XIcon } from 'pdc-svg-icons'
import { avatarSingleSvg } from 'pdc-icons'
import CallForwardingSetupModal from './CallForwardingSetupModal'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import DropdownMenu from 'dropdown-menu'
import EditExtensionField from 'edit-extension-field'
import QuickAddNumberModal from 'quick-add-number-modal'
import PropTypes from 'prop-types'

const styles = theme => ({
    mainWrapper: {
        height: '100%',
        '& > div': {
            // padding: 20
        },
        '& .infinite-scroller': {
            paddingLeft: 10
        }
    },
    userRow: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #DEE1E3'

    },
    rowMain: {
        display: 'flex',
        alignItems: 'center',
        height: 90,
        '&.active': {
            cursor: 'pointer'
        }
    },
    rowExpandableContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        transition: 'max-height .2s, padding .2s',
        overflow: 'hidden'
    },
    expRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 25,
        '& .expRowLabel': {
            fontSize: 14,
            fontWeight: 600,
            width: '30%'
        },
        '& .expRowRadio': {
            fontSize: 14,
            marginRight: 'auto',
            '& .selectionControlWrapper': {
                display: 'flex',
                alignItems: 'center'
            }
        },
        '& .expRowData': {
            fontSize: 14,
            marginRight: 'auto'
        }
    },
    avatarColumn: {
        '&.empty': {
            '& img': {
                background: 'gray'
            }
        }
    },
    infoColumn: {
        display: 'inline-grid',
        marginLeft: 20,
        '& .name': {
            fontWeight: 600,
            fontSize: 16,
            maxWidth: 160,
            wordBreak: 'break-all'
        },
        '& .name_pending': {
            fontWeight: 600,
            fontSize: 16,
            maxWidth: 160,
            color: '#85929A'
        },
        '& .email': {
            fontSize: 14,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&.draw': {
                textOverflow: 'inherit',
                whiteSpace: 'unset',
                wordBreak: 'break-all',
                color: 'black'
            }
        },
        '& .email_pending': {
            fontSize: 14,
            color: '#FF6C5C',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&.draw': {
                textOverflow: 'inherit',
                whiteSpace: 'unset',
                wordBreak: 'break-all'
            }
        },
        '& .inviteButtonWrapper > button': {
            borderRadius: 4,
            padding: '5px 10px 5px 30px',
            fontSize: 16,
            '& .icon-left': {
                left: 10,
                width: 15,
                height: 15
            }
        }
    },
    optionsColumn: {
        marginLeft: 'auto'
    },

    drawerStyle: {
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20
    },
    optionsDrawer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    optionsDrawerHeader: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        height: 130,
        borderBottom: '1px solid #DEE1E3',
        position: 'relative',
        paddingRight: 15
    },
    optionsDrawerItem: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: 56,
        borderBottom: '1px solid #DEE1E3',
        '&:hover': {
            background: theme.palette.primary['-300'],
            cursor: 'pointer'
        }
    },
    optionsDrawerFooter: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        minHeight: 130
    },
    xIcon: {
        position: 'absolute',
        right: 15,
        top: 15,
        color: theme.palette.secondary['0'],
        cursor: 'pointer'
    },
    meetingsColumn: {
        '&.link': {
            cursor: 'pointer',
            color: theme.palette.primary['0']

        }
    }
})

const mapStateToProps = state => ({
    users: state.users,
    numbers: state.numbers,
    newNumbers: state.newNumbers
})

const mapDispatchToProps = dispatch => ({
})

class ManageUsersSmall extends Component {
    constructor (props) {
        super(props)
        this.state = {
            openDrawer: false,
            drawerUserId: null,
            expandedUser: null,
            callForwardingSetupModalUser: null,
            showAddNumberModal: false,
            addNumberUser: null
        }
    }

    toggleExpandedUserArea = user => {
        const oldUser = this.state.expandedUser
        if (oldUser && oldUser.id !== user.id) {
            this.props.changeValueInUser(oldUser, 'expanded', !oldUser.expanded)
        }
        this.setState({ expandedUser: user.expanded === true ? null : user })
        this.props.changeValueInUser(user, 'expanded', !user.expanded)
    }

    deleteUser = user => {
        this.setState({ openDrawer: false, drawerUserId: null })
        this.props.deleteOneUser(user)
    }

    parseMeetingIdFromMeetingUrl = meeting_url => {
        const url = new URL(meeting_url)
        const parts = url.pathname.split('/')
        return parts.pop()
    }

    directNumberDropdownButton = props => {
        return (
            <div {...props}>
                <PDCButton data-button='list-unassigned-numbers' variant='graphic' icon='add' color='primary'></PDCButton>
            </div>
        )
    }

    getDirectNumberDropdownItems = user => {
        const header = <span>Unassigned numbers</span>
        // let host = window.location.hostname
        // let baseUrl = host === 'localhost' ? `http://${host}:3000` : `https://${host}`
        // let footer = <span onClick={()=>window.open(`${baseUrl}/phone-numbers/add-numbers`, '_blank')}>Get New number<ArrowRightAltIcon/></span>
        const footer = <span>Get New number<ArrowRightAltIcon/></span>

        const items = [
            { text: header, className: 'strong' },
            { type: 'separator' },
            { text: footer, onClick: () => this.setState({ showAddNumberModal: true, addNumberUser: user }), className: 'active strong' }
        ]
        const numberItems = []
        this.props.numbers.forEach((number) => {
            numberItems.push({ text: this.props.prettyFormatNumberOnLoad(number.phone_number), onClick: () => this.props.assignNumber(user, number) })
            numberItems.push({ type: 'separator' })
        })

        items.splice(2, 0, ...numberItems)
        return items
    }

    closeAddNumberModal = () => { this.setState({ showAddNumberModal: false, addNumberUser: null }) }

    renderOptionsDrawerItem = (item) => {
        const { classes } = this.props

        return (
            <div data-drawer-item={item.text} key={item.text} className={classes.optionsDrawerItem} onClick={item.onClick}>
                {item.text}
            </div>
        )
    }

    renderOptionsDrawer = () => {
        const { classes } = this.props

        const user = this.props.users.find(u => u.id === this.state.drawerUserId)
        if (!user) return <div>Something went wrong...</div>

        const drawerItems = [
            { text: 'Edit User Settings', onClick: () => this.props.onOpenUserSettings(user, '') },
            { text: 'Setup Call Forwarding', onClick: () => this.props.onOpenUserSettings(user, 'incoming-calls') }
        ]

        if (user.direct_number !== null) drawerItems.push({ text: 'Unassign Number', onClick: () => this.props.unassignPhoneNumber(user) })

        drawerItems.push({ text: 'Show SIP credentials', onClick: () => this.props.showSIPCredentials(user) })
        if (user.status === 'invite_pending') { drawerItems.push({ text: 'Resend Invite', onClick: () => this.props.resendInvite(user) }) } else if (user.status === 'new') { drawerItems.push({ text: 'Invite User', onClick: () => this.props.openInviteUserModal(user.id) }) } else { drawerItems.push({ text: 'Reset Password', onClick: () => this.props.resetPassword(user) }) }

        if (!user.is_admin && this.props.isCSR) {
            drawerItems.push({ text: 'Set as the admin', onClick: () => this.props.reassignAdminUser(user), className: 'strong' })
        }

        return (
            <div data-drawer={user.id} className={classes.optionsDrawer}>
                <div className={classes.optionsDrawerHeader}>
                    <div className={`${classes.avatarColumn} ${user.status === 'new' ? 'empty' : ''}`}>
                        <AvatarIcon
                            imageUrl = {user.status === 'new' ? avatarSingleSvg : user.avatar_url}
                            noBorder = {true}
                            opacity = {user.status === 'invite_pending' ? 0.7 : 1}
                            text = {user.is_admin ? 'admin' : false}
                            style = {{}}
                        />
                    </div>
                    {user.status !== 'new'
                        ? <div className={classes.infoColumn}>
                            <div className={`${user.status === 'invite_pending' ? 'name_pending' : 'name'} draw`}>{user.first_name} {user.last_name}</div>
                            <div className={'email draw'}>{user.email}</div>

                        </div>
                        : <div className={classes.infoColumn}>
                            <div className={'email draw'}>Ext: {(user.extension ? user.extension.data.extension_number : '').toString()}</div>
                        </div>
                    }

                    <XIcon onClick={() => { this.setState({ openDrawer: false, drawerUserId: null }) }} className={classes.xIcon}/>
                </div>
                {drawerItems.map(this.renderOptionsDrawerItem)}
                {!user.is_admin
                    ? <div className={classes.optionsDrawerFooter}>
                        <PDCButton
                            variant = 'outlined'
                            color = 'attention'
                            onClick = {() => this.deleteUser(user)}
                            disabled = {false}
                            className = {classes.actionButton}
                        >
                            Delete user
                        </PDCButton>
                    </div>
                    : null}
            </div>
        )
    }

    renderExpandedArea = user => {
        const { classes } = this.props

        const user_extension = (user.extension ? user.extension.data.extension_number : '').toString()
        const planLabels = { 1: 'Basic', 2: 'Plus', 3: 'Pro' }

        const padding = user.expanded ? 20 : 0
        return (
            <div
                data-expandable-area={user.id}
                className={classes.rowExpandableContainer}
                style={{ maxHeight: user.expanded ? 278 : 0, paddingTop: padding, paddingBottom: padding }}
            >
                <div className={classes.expRow}>
                    <div className='expRowLabel'>
                        Plan Type
                    </div>
                    {this.props.forceProPlan
                        ? (<div className='expRowData'>{planLabels[user.user_plan_id]}</div>)
                        : (<>
                            <div className='expRowRadio'>
                                <div className='selectionControlWrapper'>
                                    <SelectionControl disabled={!!user?.device || this.props.forceProPlan} data-radio={'basic'} name={`user_plan_${user.id}`} value={'1'} onClick={() => this.props.changeUserPlan(user, 1)} checked={user.user_plan_id === 1} variant='radio' />
                                    <span>Basic</span>
                                </div>
                            </div>
                            <div className='expRowRadio'>
                                <div className='selectionControlWrapper'>
                                    <SelectionControl disabled={this.props.forceProPlan} data-radio={'plus'} name={`user_plan_${user.id}`} value={'2'} onClick={() => this.props.changeUserPlan(user, 2)} checked={user.user_plan_id === 2} variant='radio' />
                                    <span>Plus</span>
                                </div>
                            </div>
                            <div className='expRowRadio'>
                                <div className='selectionControlWrapper'>
                                    <SelectionControl data-radio={'pro'} name={`user_plan_${user.id}`} value={'3'} onClick={() => this.props.changeUserPlan(user, 3)} checked={user.user_plan_id === 3} variant='radio' />
                                    <span>Pro</span>
                                </div>
                            </div>
                        </>)
                    }
                </div>
                <div className={classes.expRow}>
                    <div className='expRowLabel'>
                        Direct Number
                    </div>
                    <div className='expRowData'>
                        {user.direct_number !== null
                            ? this.props.prettyFormatNumberOnLoad(user.direct_number.e164_number)
                            : <DropdownMenu
                                button = {this.directNumberDropdownButton}
                                dropdownId = {`${user.id}_direct_number`}
                                menuItems = {this.getDirectNumberDropdownItems(user)}
                                dropdownStyle = 'configure'
                            />
                        }
                    </div>
                </div>
                <div className={classes.expRow}>
                    <div className='expRowLabel'>
                        Meeting
                    </div>
                    <div className='expRowData'>
                        <div
                            onClick={() => { if (user.meeting_plan !== null) { window.open(user.meeting_plan.add_on_configuration.meeting_url, '_blank') } }}
                            className={`${classes.meetingsColumn} ${user.meeting_plan !== null ? 'link' : ''}`}>
                            {user.meeting_plan !== null
                                ? this.parseMeetingIdFromMeetingUrl(user.meeting_plan.add_on_configuration.meeting_url)
                                : user.status === 'invite_pending'
                                    ? <PDCButton disabled={true} data-button='add-meeting'variant='graphic' icon='add' color='primary'></PDCButton>
                                    : <PDCButton onClick={() => this.props.addMeeting(user)} data-button='add-meeting' variant='graphic' icon='add' color='primary'></PDCButton>
                            }
                        </div>
                    </div>
                </div>
                <div className={classes.expRow}>
                    <div className='expRowLabel'>
                        Extension
                    </div>
                    <div className='expRowData'>
                        <EditExtensionField
                            value = {user_extension}
                            onChange = {async (extension) => { return await this.props.updateUserExtension(user, extension) }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderNotConfiguredUserRow = user => {
        const { classes } = this.props
        return (
            <div key={user.id} className={classes.userRow}>
                {user.first_name
                    ? <div className={classes.rowMain}>
                        <div className={classes.avatarColumn} >
                            <AvatarIcon
                                imageUrl = {user.avatar_url}
                                noBorder = {true}
                                opacity = {0.7}
                                text = {user.is_admin ? 'admin' : false}
                                style = {{}}
                            />
                        </div>
                        <div className={classes.infoColumn}>
                            <div className={'name_pending'}>{user.first_name} {user.last_name}</div>
                            <div className={'inviteButtonWrapper'}>
                                <PDCButton figma={true} data-button='invite-user' variant='outlined' icon='add' onClick={() => this.props.openInviteUserModal(user.id)}>Invite user</PDCButton>
                            </div>
                        </div>
                        <div className={classes.optionsColumn}>
                            <Options onClick={(e) => { e.stopPropagation(); this.setState({ openDrawer: true, drawerUserId: user.id }) }} variant='vertical'/>
                        </div>
                    </div>
                    : <div className={classes.rowMain}>
                        <div className={classes.avatarColumn}>
                            <PDCButton figma={true} data-button='invite-user' variant='outlined' icon='add' onClick={() => this.props.openInviteUserModal(user.id)}>Invite user</PDCButton>
                        </div>
                        <div className={classes.infoColumn}>

                        </div>
                        <div className={classes.optionsColumn}>
                            <Options onClick={(e) => { e.stopPropagation(); this.setState({ openDrawer: true, drawerUserId: user.id }) }} variant='vertical'/>
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderUserRow = user => {
        const { classes } = this.props
        return (
            <div key={user.id} className={`${classes.userRow}`}>
                <div className={`${classes.rowMain} active`} onClick={() => this.toggleExpandedUserArea(user)}>
                    <div className={classes.avatarColumn} >
                        <AvatarIcon
                            imageUrl = {user.avatar_url}
                            noBorder = {true}
                            opacity = {user.status === 'invite_pending' ? 0.7 : 1}
                            text = {user.is_admin ? 'admin' : false}
                            style = {{}}
                        />
                    </div>
                    <div className={classes.infoColumn}>
                        <div className={`${user.status === 'invite_pending' ? 'name_pending' : 'name'}`}>{user.first_name} {user.last_name}</div>
                        <div className={`${user.status === 'invite_pending' ? 'email_pending' : 'email'}`}>{user.email}</div>
                    </div>
                    <div className={classes.optionsColumn}><Options onClick={() => this.setState({ openDrawer: true, drawerUserId: user.id })} variant='vertical'/></div>
                </div>
                {this.renderExpandedArea(user)}
            </div>
        )
    }

    renderCallForwardingSetupModal = () => {
        const callForwardingSetupModalUser = this.state.callForwardingSetupModalUser
        if (!callForwardingSetupModalUser) return null
        return (
            <CallForwardingSetupModal
                user = {callForwardingSetupModalUser}
                onClose = {() => this.setState({ callForwardingSetupModalUser: null })}
            />
        )
    }

    render () {
        const { classes } = this.props

        // let hasMore = this.props.users.length ? this.props.users.total - this.props.users.length > 0 : false
        return (
            <div className={classes.mainWrapper}>
                <InfiniteScroller
                    reverseScroll = {false}
                    loadMore = {this.props.loadMore}
                    hasMore = {this.props.hasMore}
                    loader = {<Spinner/>}
                >
                    {this.props.users.map((user) => (
                        user.status === 'new' ? this.renderNotConfiguredUserRow(user) : this.renderUserRow(user)
                    ))}
                </InfiniteScroller>

                <SwipeableDrawer
                    classes={{ paperAnchorBottom: classes.drawerStyle }}
                    anchor='bottom'
                    open={this.state.openDrawer}
                    onClose={() => this.setState({ openDrawer: false, drawerUserId: null })}
                    onOpen={() => this.setState({ openDrawer: true })}
                >
                    {this.renderOptionsDrawer()}
                </SwipeableDrawer>
                {this.renderCallForwardingSetupModal()}
                <QuickAddNumberModal
                    showModal={this.state.showAddNumberModal}
                    onCloseModal={this.closeAddNumberModal}
                    findAvailableNumbers={this.props.findAvailableNumbers}
                    searchPending={this.props.pendingFindAvailableNumbers}
                    addAvailableNumber={this.props.addAvailableNumber}
                    addNumberPending={this.props.pendingAddAvailableNumber}
                    addNumberUser={this.state.addNumberUser}
                    newNumbers={this.props.newNumbers}
                    smallView={true}
                />
            </div>
        )
    }
}

ManageUsersSmall.propTypes = {
    classes: PropTypes.object,
    hasMore: PropTypes.bool,
    changeValueInUser: PropTypes.func,
    loadMore: PropTypes.func,
    numbers: PropTypes.array,
    assignDevice: PropTypes.func,
    assignLoading: PropTypes.bool,
    smallView: PropTypes.bool,
    users: PropTypes.array,
    pendingAddAvailableNumber: PropTypes.bool,
    addAvailableNumber: PropTypes.func,
    findAvailableNumbers: PropTypes.func,
    prettyFormatNumberOnLoad: PropTypes.func,
    openInviteUserModal: PropTypes.func,
    updateUserExtension: PropTypes.func,
    addMeeting: PropTypes.func,
    deleteOneUser: PropTypes.func,
    assignNumber: PropTypes.func,
    onOpenUserSettings: PropTypes.func,
    unassignPhoneNumber: PropTypes.func,
    resendInvite: PropTypes.func,
    resetPassword: PropTypes.func,
    changeUserPlan: PropTypes.func,
    pendingFindAvailableNumbers: PropTypes.bool,
    newNumbers: PropTypes.array,
    isCSR: PropTypes.bool,
    reassignAdminUser: PropTypes.func,
    showSIPCredentials: PropTypes.func,
    forceProPlan: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ManageUsersSmall))
