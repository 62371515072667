<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M2.83337 3.20825C2.28109 3.20825 1.83337 3.65597 1.83337 4.20825V15.0416C1.83337 15.5939 2.28109 16.0416 2.83338 16.0416H19.1667C19.719 16.0416 20.1667 15.5939 20.1667 15.0416V4.20825C20.1667 3.65597 19.719 3.20825 19.1667 3.20825H2.83337ZM3.70837 5.04159C3.43223 5.04159 3.20837 5.26544 3.20837 5.54159V13.7083C3.20837 13.9844 3.43223 14.2083 3.70837 14.2083H18.2917C18.5678 14.2083 18.7917 13.9844 18.7917 13.7083V5.54159C18.7917 5.26544 18.5678 5.04159 18.2917 5.04159H3.70837Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M16.4999 16.0415C16.4999 16.0415 12.3749 16.0415 12.3749 16.9582C12.3749 17.4644 12.8333 17.8748 12.8333 17.8748H15.3541C15.4807 17.8748 15.5833 17.9774 15.5833 18.104V18.7915C15.5833 18.9181 15.4807 19.0207 15.3541 19.0207H6.64578C6.51921 19.0207 6.41661 18.9181 6.41661 18.7915V18.104C6.41661 17.9774 6.51921 17.8748 6.64578 17.8748H9.16661C9.16661 17.8748 9.62494 17.4644 9.62494 16.9582C9.62494 16.0415 13.7499 16.0415 13.7499 16.0415C-13.7305 16.0415 31.2687 16.0415 16.4999 16.0415Z" fill="currentColor"/>
	</svg>
</template>

<script>
  export default {};
</script>
