<template>
  <component v-if="!has_router" :is="$attrs.tag || 'span'" v-bind="$attrs" v-on="$listeners"><slot/></component>
  <router-link v-else v-bind="$attrs" v-on="$listeners"><slot/></router-link>
</template>
<script>
  export default {
    props: [],
    data() {
      return {
        has_router: false,
      };
    },
    created() {
        this.$data.has_router = !!this.$router;
    }
  };
</script>
