<template>
  <div>
    <template v-if="codes && codes.length > 1">
        <div :class="`${classes.field_titles} mt-0`" data-test-id="lr-plan-title">
            {{ vendor_name() === 'ONVEGO' ? $lang.t('lr.select-a-script-type', 'Select a script type') : $lang.t('lr.select-a-plan', 'Select a plan')}}
            <InfoLink
                max-width="300"
                key="live-answer.plan-help-text"
                data-test-id="lr-plan-info-link"
            >
                <template v-slot:icon>
                    <v-icon size="19" class="pb-1" color="#6E7A82">
                    $vuetify.icons.infotip_stylized
                    </v-icon>
                </template>
                {{ $lang.t(
                    'live-answer.the-fee-for-script-type-you-choose',
                    'The fee for the script type you choose will be applied based on the billing cycle of your Phone.com service. Taxes and fees may be applicable. You can cancel any time by deactivating your script.'
                ) }}
            </InfoLink>
        </div>
        <div :class="classes.field_explanation">
            <template v-if="vendor_name() !== 'ONVEGO'">
                {{$lang.t('lr.how-many-minutes-per-month', 'How many minutes per month are you expecting calls received by your Live receptionist?')}}
            </template>
            <template v-else>
                {{$lang.t('lr.choose-a-standard-answer-bot-script-for-call-screening-and-routing', 'Choose a Standard AI-Connect Script for call screening and routing, or upgrade to an Enhanced AI-Connect Script if you’d like to add Google Calendar integrated appointment setting.')}}
            </template>
        </div>
        <w-select
            v-bind="$attrs"
            v-on="$listeners"
            v-model="code"
            :items="codes"
            :id="vendor_name() === 'ONVEGO' && has_onvego_apointments ? 'onvego-codes-selector' : ''"
            :placeholder="vendor_name() === 'ONVEGO' && has_onvego_apointments ? $lang.t('lr.select-your-script-type', 'Select your script type') : $lang.t('lr.select-your-plan', 'Select your plan')"
            hide-details="auto"
            data-test-id="lr-plan-select"
        />
    </template>
    <w-alert
        v-else-if="!codes && (Array.isArray(codes) && !codes.length)"
        level="error"
        :message="$lang.t('lr.no-codes-for-vendor', 'Currently there is no plans for this vendor, please select another one.')"
        class="my-3"
    />
  </div>
</template>
<script>
    import LiveAnswerVendor from '../../../console/src/models/LiveAnswerVendor';
    import InfoLink from '../../../console/src/components/elements/InfoLink.vue';

    export default {
      props: {
        value: {},
        vendor: {
            type: Object,
            required: true,
        },
        la: {
            type: Object,
            required: true,
        },
        avo: {
            type: Object,
            required: true,
            validator: (v) => v.prefered_plan,
        },
        onvego: {
            type: Object,
            required: true,
            validator: (v) => v.prefered_plan,
        },
        has_onvego_apointments: {
            type: Boolean,
            default: false,
        },
        classes: {
            type: Object,
            default: () => ({
                main_titles: 'w-body-1 info--text text--darken-1 field-title',
                field_titles: 'w-body-1 info--text text--darken-1 field-title',
                field_explanation: 'w-body-2 field-explanation',
                ordinary_field_title: 'w-body-2 field-title-ordinary',
            }),
        },
      },
      components: {
        InfoLink,
      },
      data() {
        return {
            codes: null,
            code: this.$props.value,
        };
      },
      created() {
        this.generateCodes();
      },
      methods: {
        translate_code(c) {
            if (LiveAnswerVendor.CODES_DETAILS[c.code]) {
                return LiveAnswerVendor.CODES_DETAILS[c.code].long;
            }
            return this.$lang.t('live-receptionist.no-decription-available', 'No description available. Code: {}', [c.code]);
        },
        vendor_name() {
            if (this.$props.vendor) {
                return LiveAnswerVendor.vendor_group(this.$props.vendor);
            }

            return null;
        },
        generateCodes() {
            this.$data.codes = [];
            if (this.$props.vendor.codes && this.$props.vendor.codes.length) {
                this.$props.vendor.codes.map((c) => this.$data.codes.push(
                    {
                        value: c.code,
                        text: this.translate_code(c)
                    }
                ));
                if (this.$props.vendor.id === this.$props.avo_id && ['avo', 'avo_master'].includes(this.$branding.data.code)) {
                    this.$data.codes = [{
                        text: this.translate_code(this.$props.la.avo_plan),
                        value: this.$props.la.avo_plan.code,
                    }];
                    this.$data.code = this.$props.la.avo_plan.code;
                }
                if (this.vendor_name() === 'ONVEGO' && !this.$props.has_onvego_apointments) {
                    if (this.codes.find((x) => x.code === this.$props.onvego.prefered_plan)) {
                        this.$data.code = this.$props.onvego.prefered_plan;
                    }
                    this.$data.code = this.$props.vendor.codes[0].code;
                }
                if (this.$props.vendor.codes.length === 1) {
                    this.$data.code = this.$props.vendor.codes[0].code;
                }
            }
            if (!this.$data.codes.map((x) => x.value).includes(this.$data.code)) {
                this.$data.code = null;
            }
        },
      },
      watch: {
        code(v) {
            this.$emit('input', v);
        }
      }
    };
</script>

<style scoped>
    #onvego-codes-selector.v-input {
        max-width: 645px !important;
    }
</style>