import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=6022%3A49
 *
 * @param {object} props - svg props
 */
export const StorefrontIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M22.8911 8.54445L21.7358 3.68889C21.4937 2.68889 20.6354 2 19.6341 2H17.2023H14.9906H13.098H10.8973H9.00467H6.78196H4.35018C3.35986 2 2.49058 2.7 2.2595 3.68889L1.10413 8.54445C0.840048 9.67778 1.08213 10.8333 1.78635 11.7444C1.87438 11.8667 1.99542 11.9556 2.09445 12.0667V19.7778C2.09445 21 3.08477 22 4.29516 22H19.7001C20.9105 22 21.9008 21 21.9008 19.7778V12.0667C21.9999 11.9667 22.1209 11.8667 22.2089 11.7556C22.9132 10.8444 23.1662 9.67778 22.8911 8.54445ZM6.51787 4.22222L5.87967 9.62222C5.79164 10.3444 5.21946 10.8889 4.54824 10.8889C4.00907 10.8889 3.66796 10.5667 3.52491 10.3667C3.23882 10 3.13979 9.52222 3.24982 9.06667L4.35018 4.22222H6.51787ZM19.6011 4.21111L20.7565 9.06667C20.8665 9.53333 20.7675 10 20.4814 10.3667C20.3273 10.5667 19.9972 10.8889 19.447 10.8889C18.7758 10.8889 18.1926 10.3444 18.1156 9.62222L17.4774 4.22222L19.6011 4.21111ZM15.8599 9.24445C15.9149 9.67778 15.7829 10.1111 15.4968 10.4333C15.2437 10.7222 14.8916 10.8889 14.4404 10.8889C13.7032 10.8889 13.098 10.2333 13.098 9.43333V4.22222H15.2547L15.8599 9.24445ZM10.8973 9.43333C10.8973 10.2333 10.2921 10.8889 9.47783 10.8889C9.10371 10.8889 8.7626 10.7222 8.49851 10.4333C8.22342 10.1111 8.09138 9.67778 8.1354 9.24445L8.74059 4.22222H10.8973V9.43333ZM18.5998 19.7778H5.39551C4.79032 19.7778 4.29516 19.2778 4.29516 18.6667V13.0778C4.38319 13.0889 4.46021 13.1111 4.54824 13.1111C5.50555 13.1111 6.37483 12.7111 7.01303 12.0556C7.67325 12.7222 8.55353 13.1111 9.55485 13.1111C10.5122 13.1111 11.3704 12.7111 12.0086 12.0778C12.6579 12.7111 13.5381 13.1111 14.5285 13.1111C15.4528 13.1111 16.333 12.7222 16.9932 12.0556C17.6315 12.7111 18.5007 13.1111 19.458 13.1111C19.5461 13.1111 19.6231 13.0889 19.7111 13.0778V18.6667C19.7001 19.2778 19.205 19.7778 18.5998 19.7778Z' fill={color}/>
        </SvgIcon>
    )
}

export default StorefrontIcon
