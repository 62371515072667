import React, { Component } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import LoaderFull from 'loader-full'
import { Select } from 'select-mui'
import { Checkbox, Variant as CheckboxVariant } from 'checkbox-mui'
import { Radio, Variant as RadioVariant } from 'radio-mui'
import { Switch } from 'switch'
import Button from 'button'
import { getHours, daysMap, holidayOptions, customHolidayOptions, expirationOptions/* , getScheduleItemsUnionTimes, getFormattedScheduleItemsIntersectionTimes */ } from './util'
import { getOpenHoursScheduleType, getDefaultSchedule, getSavedSchedules, getScheduleItemInfo, ScheduleItemInfo, checkHasChange, generateSaveData, BusinessHoursType } from './helper'
import { theme } from 'get-theme'
import Api from 'api'
import TextField from 'pdc-text-field'
import { PlusCircleOutlinedIcon, PlusCircleIcon, XIcon } from 'pdc-svg-icons' // CalendarIcon
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
// import TextField from '@material-ui/core/TextField'
import { v1 as uuidv1 } from 'uuid'
import { TimeItem } from './timeItem'
import RemoteConfigValue from 'remote-config-value'
import Typography from 'typography'
import { MenuItem } from 'menu-item-mui'
import { RemoteInfoTipMui, Placement as RemoteInfoTipMuiPlacement } from 'remote-info-tip-mui'
const OpenHoursScheduleName = 'open-hours'
const HolidaysScheduleName = 'holidays'
const CustomHolidaysScheduleName = 'custom-holidays'
const LunchScheduleName = 'lunch-break'

const baseMessage = 'Choose this option if you want incoming calls to be handled'
const officeHours = [
    { id: BusinessHoursType.Open24, title: 'Open 24 hours', description: `${baseMessage} the same way all the time`, 'data-test-id': 'open-24' },
    { id: BusinessHoursType.OpenCustom, title: 'Custom business hours', description: `${baseMessage} differently when your office is closed`, 'data-test-id': 'open-custom' }
]

interface Props {
    classes
    setBusy: (hasChange: boolean) => void
    /**
     * Is it mobile view
     */
    smallView: boolean
    /**
     * In case it is company-setup then only some parts will be shown
     */
    origin: 'business-hours-tab' | 'company-setup'
    /**
     * Prefill data
     */
    editingData?
    onUpdate?: (states: any) => void
    setLoading?: (isLoading: boolean) => void
}

interface ScheduleState {
    status: 'on' | 'off'
    items: ScheduleItemInfo[]
}

interface State {
    openHoursScheduleType: BusinessHoursType
    customOpenHours: ScheduleState
    openHours: ScheduleState
    lunchPause: ScheduleItemInfo
    holidays: ScheduleState
    customHolidays: ScheduleState
    loading: boolean
    invalidSaveAttempt: boolean
    initialSetup: boolean
    openHoursSchedule
    lunchSchedule
    holidaysSchedule
    customHolidaysSchedule
    allHolidays
}

class BusinessHours extends Component<Props, State> {
    hasChanges = false
    constructor (props) {
        super(props)
        this.state = {
            openHoursScheduleType: BusinessHoursType.Open24,
            customOpenHours: { status: 'on', items: daysMap.map(day => (getScheduleItemInfo(day.day, ['S', 'Su'].includes(day.short) ? 'off' : 'on', '9:00 am', '5:00 pm'))) },
            openHours: { status: 'on', items: daysMap.map(day => (getScheduleItemInfo(day.day, 'off', '9:00 am', '5:00 pm'))) },
            lunchPause: getScheduleItemInfo('Are you closed for lunch?', 'off', '10:00 am', '2:00 pm'),
            holidays: { status: 'off', items: [] },
            customHolidays: { status: 'off', items: [] },
            loading: true,
            invalidSaveAttempt: false,
            initialSetup: true,
            openHoursSchedule: null,
            lunchSchedule: null,
            holidaysSchedule: null,
            customHolidaysSchedule: null,
            allHolidays: null
        }
    }

    componentDidMount = async () => {
        const [schedules, allHolidays] = await Promise.all([Api.loadSchedules(), Api.loadHolidays()])
        let openHoursSchedule, holidaysSchedule, lunchSchedule, customHolidaysSchedule

        schedules.items.forEach(schedule => {
            if (schedule.name === OpenHoursScheduleName) openHoursSchedule = schedule
            else if (schedule.name === HolidaysScheduleName) holidaysSchedule = schedule
            else if (schedule.name === LunchScheduleName) lunchSchedule = schedule
            else if (schedule.name === CustomHolidaysScheduleName) customHolidaysSchedule = schedule
        })
        const openHoursScheduleType = getOpenHoursScheduleType(openHoursSchedule)
        if (!openHoursSchedule) openHoursSchedule = getDefaultSchedule(OpenHoursScheduleName)
        if (!lunchSchedule) lunchSchedule = getDefaultSchedule(LunchScheduleName)
        if (!holidaysSchedule) holidaysSchedule = getDefaultSchedule(HolidaysScheduleName, { allHolidays })
        if (!customHolidaysSchedule) customHolidaysSchedule = getDefaultSchedule(CustomHolidaysScheduleName)
        this.setState({ allHolidays, openHoursSchedule, holidaysSchedule, customHolidaysSchedule, lunchSchedule, openHoursScheduleType })

        const { openHours, lunchPause, holidays, customHolidays } = getSavedSchedules(this.state)
        this.setState({ openHours, lunchPause, holidays, customHolidays, loading: false })
        if (this.props.editingData) this.setState({ ...this.props.editingData, initialSetup: false })

        if (this.props.origin === 'company-setup') this.updateParent()
    }

    componentDidUpdate = (prevProps, prevState) => {
        const hasChanges = checkHasChange(this.state)
        if (this.hasChanges === undefined || this.hasChanges !== hasChanges) {
            this.props.setBusy(hasChanges)
            this.hasChanges = hasChanges
        }
        let shouldUpdate = false
        if (prevState.openHoursScheduleType !== this.state.openHoursScheduleType) shouldUpdate = true
        if (prevState.openHours.status !== this.state.openHours.status) shouldUpdate = true
        if (prevState.openHours.items.some(item => this.state.openHours.items.some(item2 => (item.text === item2.text && (item.from !== item2.from || item.to !== item2.to || item.status !== item2.status))))) shouldUpdate = true
        if (!this.state.initialSetup && shouldUpdate) this.updateParent(hasChanges)
        if (prevState.loading !== this.state.loading) {
            this.props.setLoading?.(this.state.loading)
        }
    }

    goToErrorElement = () => {
        const errorElement = document.getElementsByClassName('error')[0]
        const muiErrorElement = document.getElementsByClassName('Mui-error')[0]
        if (errorElement) errorElement.scrollIntoView()
        else if (muiErrorElement) muiErrorElement?.scrollIntoView()
    }

    updateParent = (hasChanges = false): void => {
        const states = JSON.parse(JSON.stringify(this.state))
        states.hasChange = hasChanges
        this.props.onUpdate?.(states)
    }

    renderLoader = () => {
        const { classes, origin } = this.props
        if (origin === 'company-setup') return null
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} size='bigger'/>
            </div>
        )
    }

    onUSHolidaysClick = (openHoursScheduleType) => {
        if (this.state.openHoursScheduleType === openHoursScheduleType) return
        if (openHoursScheduleType === BusinessHoursType.Open24) {
            const openHours24: ScheduleState = {
                status: 'on',
                items: daysMap.map((day): ScheduleItemInfo => ({
                    from: (new TimeItem({ hours: 0, minutes: 0 })).toString12(),
                    to: (new TimeItem({ hours: 0, minutes: 0 })).toString12(),
                    status: 'on',
                    text: day.day
                }))
            }
            this.setState({ customOpenHours: { ...this.state.openHours }, openHours: openHours24 })
        } else {
            this.setState({ openHours: { ...this.state.customOpenHours } })
        }
        this.setState({ openHoursScheduleType })
    }

    renderBusinesseHoursOptions = () => {
        const { classes, origin } = this.props
        const renderRadio = openHoursScheduleType => (
            <Radio
                variant={RadioVariant.PRIMARY}
                checked={this.state.openHoursScheduleType === openHoursScheduleType}
                onChange={() => this.onUSHolidaysClick(openHoursScheduleType)}
                name = 'office-hours'
                value = {`office-hours-${openHoursScheduleType}`}
                classes={{ root: classes.radio }}
            />
        )
        return (
            <div className='office-hours-options-wrapper'>
                {officeHours.map((officeHourOption, i) => (
                    <div key={i} className='office-hours-option' data-test-id={officeHourOption['data-test-id']}>
                        {renderRadio(officeHourOption.id)}
                        <div className='office-hours-option-text'>
                            <div className='main-text'>
                                {origin === 'company-setup'
                                    ? <Typography variant='h6'>{officeHourOption.title}</Typography>
                                    : officeHourOption.title
                                }
                            </div>
                            <div className='description'>
                                {origin === 'company-setup'
                                    ? <Typography variant='body2'>{officeHourOption.description}</Typography>
                                    : officeHourOption.description
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    renderHoursFields = (item, onFromChange, onToChange, overflow) => {
        const { classes } = this.props
        const fromHours = getHours({ from: null, to: item.to, includeFrom: true, includeTo: false, overflow, calculateDistance: false })
        const toHours = getHours({ from: item.from, to: null, includeFrom: false, includeTo: true, overflow, calculateDistance: true })
        if (!overflow) {
            fromHours.pop() // Remove the last one
            toHours.shift() // Remove the first one
        }
        return (
            <div data-test-id='time-menus-wrapper' className='column-content'>
                <Select
                    value = {item.from.replace(/\s/g, '')}
                    onChange = {e => onFromChange(e.target.value as string)}
                    formControlClasses = {{ root: 'select-wrapper' }}
                    MenuProps = {{ classes: { list: classes.selectItemsWrapper } }}
                >
                    {fromHours.map(fromHourItem => <MenuItem key={fromHourItem.value} value={`${fromHourItem.value}`}>{fromHourItem.content}</MenuItem>)}
                </Select>
                <span className='to-span'>to</span>
                <Select
                    value = {item.to.replace(/\s/g, '')}
                    onChange = {e => onToChange(e.target.value as string)}
                    formControlClasses = {{ root: 'select-wrapper xlg' }}
                    MenuProps = {{ classes: { list: classes.selectItemsWrapper } }}
                >
                    {toHours.map(fromHourItem => <MenuItem key={fromHourItem.value} value={`${fromHourItem.value}`}>{fromHourItem.content}</MenuItem>)}
                </Select>
            </div>
        )
    }

    renderDisabledHoursFields = () => (
        <div data-test-id='time-menus-wrapper' className='column-content'>
            <Select
                key = 'disabled1'
                disabled
                label = 'None'
                formControlClasses = {{ root: 'select-wrapper' }}
                MenuProps = {{ classes: { list: this.props.classes.selectItemsWrapper } }}
            />
            <span className='to-span'>to</span>
            <Select
                key = 'disabled2'
                disabled
                label = 'None'
                formControlClasses = {{ root: 'select-wrapper xlg' }}
                MenuProps = {{ classes: { list: this.props.classes.selectItemsWrapper } }}
            />
        </div>
    )

    renderCustomOfficeHoursSubsection = () => {
        const { classes, origin, smallView } = this.props
        const openHoursScheduleType = this.state.openHoursScheduleType
        if (openHoursScheduleType !== BusinessHoursType.OpenCustom) return null
        const lunchPause = { ...this.state.lunchPause }
        const toggleDayOpenHours = day => {
            const openHours = { ...this.state.openHours }
            const openHoursItems = [...openHours.items]
            const index = openHoursItems.findIndex(dayInfo => dayInfo.text === day)
            const dayInfo = { ...openHoursItems[index] }
            dayInfo.status = dayInfo.status === 'on' ? 'off' : 'on'
            openHoursItems[index] = dayInfo
            openHours.items = openHoursItems
            this.setState({ openHours })
        }
        const openHours = { ...this.state.openHours }
        const openHoursItems = [...openHours.items]
        const renderCheckbox = (item, onChange) => (
            <Checkbox
                variant={CheckboxVariant.PRIMARY}
                checked={item.status === 'on'}
                onChange={onChange}
                name='custom-hours-day'
                value={item.text}
                classes={{ root: classes.checkbox }}
            />
        )
        const hasSelectedDay = /* openHoursScheduleType === BusinessHoursType.Open24 || */openHoursItems.find(dayInfo => dayInfo.status === 'on')
        return (
            <div className='custom-office-hours-subsection'>
                {origin !== 'company-setup' ? <div className='section-heading'><RemoteConfigValue valueId='bh_business_hours_heading_label'/></div> : null}
                <div className={`${classes.table} ${origin === 'company-setup' ? 'company-setup' : ''} ${smallView ? 'mobile' : ''}`}>
                    {openHoursScheduleType === BusinessHoursType.OpenCustom
                        ? openHoursItems.map((dayInfo, i) => (
                            <div key={dayInfo.text} className='table-row'>
                                {renderCheckbox(dayInfo, () => toggleDayOpenHours(dayInfo.text))}
                                <div className='column-name'>{dayInfo.text}</div>
                                {smallView ? <div className='new-line'/> : null}
                                {dayInfo.status === 'on'
                                    ? this.renderHoursFields(
                                        dayInfo,
                                        from => {
                                            openHoursItems[i] = { ...openHoursItems[i] }
                                            openHoursItems[i].from = from
                                            openHours.items = openHoursItems
                                            this.setState({ openHours })
                                        },
                                        to => {
                                            openHoursItems[i] = { ...openHoursItems[i] }
                                            openHoursItems[i].to = to
                                            openHours.items = openHoursItems
                                            this.setState({ openHours })
                                        },
                                        true
                                    )
                                    : origin === 'company-setup'
                                        ? this.renderDisabledHoursFields()
                                        : null
                                }
                            </div>
                        ))
                        : null
                    }
                    {origin !== 'company-setup' && hasSelectedDay
                        ? <div className='table-row'>
                            {renderCheckbox(
                                lunchPause,
                                () => {
                                    lunchPause.status = lunchPause.status === 'on' ? 'off' : 'on'
                                    this.setState({ lunchPause })
                                }
                            )}
                            <div className='column-name lunch'>
                                <span>{lunchPause.text}</span>
                                <RemoteInfoTipMui arrow remoteConfigIDs={['configure_company_business_hours_closed_for_lunch']} placement={RemoteInfoTipMuiPlacement.TOP}/>
                            </div>
                            {lunchPause.status === 'on'
                                ? this.renderHoursFields(
                                    lunchPause,
                                    from => {
                                        lunchPause.from = from
                                        this.setState({ lunchPause })
                                    },
                                    to => {
                                        lunchPause.to = to
                                        this.setState({ lunchPause })
                                    },
                                    true
                                )
                                : null
                            }
                        </div>
                        : null
                    }
                </div>
            </div>
        )
    }

    renderBusinessHoursSection = () => {
        const { origin } = this.props
        return (
            <div className='section business-hours-section'>
                {origin !== 'company-setup' ? <div className='title' style={{ marginBottom: 16 }}><span>When is your company open?</span> <RemoteInfoTipMui arrow remoteConfigIDs={['configure_company_business_hours_when_open']} placement={RemoteInfoTipMuiPlacement.TOP}/></div> : null}
                {this.renderBusinesseHoursOptions()}
                {this.renderCustomOfficeHoursSubsection()}
            </div>
        )
    }

    renderDefaultHolidays = () => {
        const { classes, smallView } = this.props
        const holidays = { ...this.state.holidays }
        const holidayItems = [...holidays.items]
        const onChange = () => {
            holidays.status = holidays.status === 'on' ? 'off' : 'on'
            this.setState({ holidays })
        }
        const toggleHolidayOpenHours = holiday => {
            const holidays = { ...this.state.holidays }
            const holidayItems = [...holidays.items]
            const index = holidayItems.findIndex(holidayInfo => holidayInfo.text === holiday)
            const holidayInfo = { ...holidayItems[index] }
            holidayInfo.itemStatus = holidayInfo.itemStatus === 'on' ? 'off' : 'on'
            holidayItems[index] = holidayInfo
            holidays.items = holidayItems
            this.setState({ holidays })
        }
        const renderCheckbox = (item, onChange) => (
            <Checkbox
                variant={CheckboxVariant.PRIMARY}
                checked={item.itemStatus === 'on'}
                onChange={onChange}
                name='custom-hours-day'
                value={item.text}
                classes={{ root: classes.checkbox }}
            />
        )
        return (
            <>
                <div className="title" style={{ marginBottom: 46 }}>
                    <span>Do you observe US holidays?</span>
                    <RemoteInfoTipMui arrow remoteConfigIDs={['configure_company_business_hours_national_holidays']} placement={RemoteInfoTipMuiPlacement.TOP}/>
                    <Switch
                        checked={holidays.status === 'on'}
                        onChange={onChange}
                        name="observe-holidays"
                        value={holidays.status}
                    />
                </div>
                {holidays.status === 'on'
                    ? (
                        <div className={`${classes.table} ${smallView ? 'mobile' : ''}`}>
                            {holidayItems.map((holidayItem, i) => (
                                <div key={i} className="table-row" data-test-id={holidayItem.text}>
                                    {renderCheckbox(holidayItem, () => toggleHolidayOpenHours(holidayItem.text))}
                                    <div className="column-name">{holidayItem.text}</div>
                                    {holidayItem.itemStatus === 'on'
                                        ? (
                                            <Select
                                                value = {holidayItem.status}
                                                onChange = {e => {
                                                    holidayItems[i] = { ...holidayItems[i] }
                                                    holidayItems[i].status = e.target.value as string
                                                    holidays.items = holidayItems
                                                    this.setState({ holidays })
                                                }}
                                                formControlClasses = {{ root: 'select-wrapper lg single-menu' }}
                                                MenuProps = {{ classes: { list: classes.selectItemsWrapper } }}
                                            >
                                                {holidayOptions.map(holidayOption => <MenuItem key={holidayOption.value} value={`${holidayOption.value}`}>{holidayOption.content}</MenuItem>)}
                                            </Select>
                                        )
                                        : null}
                                    {holidayItem.itemStatus === 'on' && holidayItem.status === 'custom'
                                        ? this.renderHoursFields(
                                            holidayItem,
                                            (from) => {
                                                holidayItems[i] = { ...holidayItems[i] }
                                                holidayItems[i].from = from
                                                holidays.items = holidayItems
                                                this.setState({ holidays })
                                            },
                                            (to) => {
                                                holidayItems[i] = { ...holidayItems[i] }
                                                holidayItems[i].to = to
                                                holidays.items = holidayItems
                                                this.setState({ holidays })
                                            },
                                            false
                                        )
                                        : null}
                                </div>
                            ))}
                        </div>
                    )
                    : null}
            </>
        )
    }

    formatDateStringMDYYYY = date => `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

    checkForDuplicateCustomHolidayNamesAndDates = () => {
        const customHolidays = { ...this.state.customHolidays }
        const customHolidayItems = []
        customHolidays.items.forEach(item => {
            item = { ...item }
            delete item.hasDuplicateName
            delete item.hasDuplicateDate
            customHolidayItems.push(item)
        })
        for (let i = 0; i < customHolidayItems.length; i++) {
            const item1 = customHolidayItems[i]
            if (!item1.hasDuplicateName) {
                for (let j = i + 1; j < customHolidayItems.length; j++) {
                    const item2 = customHolidayItems[j]
                    if (item1.name && item2.name && item1.name.toLowerCase() === item2.name.toLowerCase()) item1.hasDuplicateName = item2.hasDuplicateName = true
                }
            }
            if (!item1.hasDuplicateDate) {
                for (let j = i + 1; j < customHolidayItems.length; j++) {
                    const item2 = customHolidayItems[j]
                    if (item1.date && item2.date && (new Date(item1.date)).getTime() === (new Date(item2.date).getTime())) item1.hasDuplicateDate = item2.hasDuplicateDate = true
                }
            }
        }
        customHolidays.items = customHolidayItems
        this.setState({ customHolidays })
    }

    renderCustomHolidays = () => {
        const { classes, smallView } = this.props
        const customHolidays = { ...this.state.customHolidays }
        const invalidSaveAttempt = this.state.invalidSaveAttempt
        const customHolidayItems = [...customHolidays.items]
        const addNewCustomHolidayItem = () => {
            customHolidayItems.push({
                id: uuidv1().toString(),
                date: this.formatDateStringMDYYYY(new Date()),
                useOnce: true,
                status: 'closed',
                from: '12:00 am',
                to: '11:59 pm'
            })
            customHolidays.items = customHolidayItems
            this.setState({ customHolidays })
        }
        const onChange = () => {
            customHolidays.status = customHolidays.status === 'on' ? 'off' : 'on'
            this.setState({ customHolidays })
            if (customHolidayItems.length === 0) addNewCustomHolidayItem()
        }
        const onAddNewCustomHolidayClick = () => addNewCustomHolidayItem()
        const removeCustomHoliday = id => {
            const index = customHolidayItems.findIndex(customHolidayItem => customHolidayItem.id === id)
            // if (typeof id === 'number') customHolidayItems[index].status = 'delete'
            // else customHolidayItems.splice(index, 1)
            customHolidayItems.splice(index, 1)
            customHolidays.items = customHolidayItems
            this.setState({ customHolidays }, this.checkForDuplicateCustomHolidayNamesAndDates)
        }
        const onRemoveCustomHolidayButtonKeyDown = e => {
            if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
                e.preventDefault()
            }
        }
        const onRemoveCustomHolidayButtonKeyUp = (e, customHolidayItemId) => {
            if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
                e.preventDefault()
                removeCustomHoliday(customHolidayItemId)
            }
        }
        const onAddCustomHolidayButtonKeyUp = e => {
            if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
                e.preventDefault()
            }
        }
        const onAddCustomHolidayButtonKeyDown = e => {
            if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
                e.preventDefault()
                addNewCustomHolidayItem()
            }
        }
        return (
            <>
                <div className='title' style={{ marginBottom: 30 }}>
                    <span>Are there other days you are closed or hold special business hours?</span>
                    <RemoteInfoTipMui arrow remoteConfigIDs={['configure_company_business_hours_other_custom_days']} placement={RemoteInfoTipMuiPlacement.TOP}/>
                    <Switch
                        checked={customHolidays.status === 'on'}
                        onChange={onChange}
                        name='observe-customHolidays'
                        value={customHolidays.status}
                    />
                </div>
                {customHolidays.status === 'on'
                    ? <div className={`${classes.table} ${smallView ? 'mobile' : ''}`}>
                        {customHolidayItems.map((customHolidayItem, i) => (
                            customHolidayItem.status === 'delete'
                                ? null
                                : <div key={i} className='table-row lg' data-test-id={`custom-holiday-row-${i}`}>
                                    <div data-test-id={`custom-holiday-name-${i}`} className='column-name editable'>
                                        <TextField
                                            placeholder = 'Enter a name'
                                            content = {customHolidayItem.name || ''}
                                            showBackground = {true}
                                            onInputChange = {holidayName => {
                                                customHolidayItems[i] = { ...customHolidayItems[i] }
                                                customHolidayItems[i].name = holidayName.trimStart()
                                                customHolidays.items = customHolidayItems
                                                this.setState({ customHolidays })
                                            }}
                                            className = {{ wrapper: 'custom-holiday-text-field' }}
                                            required={true}
                                            onBlur = {() => {
                                                customHolidayItems[i] = { ...customHolidayItems[i] }
                                                customHolidayItems[i].name = customHolidayItems[i].name?.trim() || ''
                                                customHolidays.items = customHolidayItems
                                                this.setState({ customHolidays }, this.checkForDuplicateCustomHolidayNamesAndDates)
                                            }}
                                            error={
                                                (invalidSaveAttempt && !customHolidayItem.name?.length)
                                                    ? 'Name is required'
                                                    : customHolidayItem.hasDuplicateName
                                                        ? 'Duplicate name'
                                                        : ''
                                            }
                                        />
                                    </div>
                                    <div data-test-id={`custom-holiday-date-${i}`} className='date-column'>
                                        {/* <div className='date-picker'>
                                            <CalendarIcon/>
                                        </div> */}
                                        <div className='picker-wrapper'>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    variant = 'inline'
                                                    value = {new Date(customHolidayItem.date)}
                                                    format = 'MM/dd/yyyy'
                                                    onChange = {value => {
                                                        customHolidayItems[i] = { ...customHolidayItems[i] }
                                                        customHolidayItems[i].date = this.formatDateStringMDYYYY(value)
                                                        customHolidays.items = customHolidayItems
                                                        this.setState({ customHolidays }, this.checkForDuplicateCustomHolidayNamesAndDates)
                                                    }}
                                                    label={customHolidayItem.hasDuplicateDate ? 'Duplicate date' : ''}
                                                    error={customHolidayItem.hasDuplicateDate}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        {/* <span className='date-string'>{customHolidayItem.date}</span> */}
                                    </div>
                                    <Select
                                        value = {customHolidayItem.useOnce ? 'on' : 'off'}
                                        onChange = {e => {
                                            customHolidayItems[i] = { ...customHolidayItems[i] }
                                            customHolidayItems[i].useOnce = e.target.value === 'on'
                                            customHolidays.items = customHolidayItems
                                            this.setState({ customHolidays })
                                        }}
                                        formControlClasses = {{ root: 'select-wrapper md single-menu' }}
                                        MenuProps = {{ classes: { list: classes.selectItemsWrapper } }}
                                        data-test-id = {`custom-holiday-recurrency-${i}`}
                                    >
                                        {expirationOptions.map(expirationOption => (
                                            <MenuItem
                                                key = {expirationOption.value}
                                                value = {`${expirationOption.value}`}
                                                data-test-id = {expirationOption.value === 'on' ? 'use-once' : 'every-year'}
                                            >{expirationOption.content}</MenuItem>
                                        ))}
                                    </Select>
                                    <Select
                                        value = {customHolidayItem.status}
                                        onChange = {e => {
                                            customHolidayItems[i] = { ...customHolidayItems[i] }
                                            customHolidayItems[i].status = e.target.value as string
                                            customHolidays.items = customHolidayItems
                                            this.setState({ customHolidays })
                                        }}
                                        formControlClasses = {{ root: 'select-wrapper lg single-menu' }}
                                        MenuProps = {{ classes: { list: classes.selectItemsWrapper } }}
                                        data-test-id = {`custom-holiday-status-${i}`}
                                    >
                                        {customHolidayOptions.map(custHolOption => (
                                            <MenuItem
                                                key = {custHolOption.value}
                                                value = {`${custHolOption.value}`}
                                                data-test-id = {custHolOption.value}
                                            >{custHolOption.content}</MenuItem>
                                        ))}
                                    </Select>
                                    {customHolidayItem.status === 'custom'
                                        ? this.renderHoursFields(
                                            customHolidayItem,
                                            from => {
                                                customHolidayItems[i] = { ...customHolidayItems[i] }
                                                customHolidayItems[i].from = from
                                                customHolidays.items = customHolidayItems
                                                this.setState({ customHolidays })
                                            },
                                            to => {
                                                customHolidayItems[i] = { ...customHolidayItems[i] }
                                                customHolidayItems[i].to = to
                                                customHolidays.items = customHolidayItems
                                                this.setState({ customHolidays })
                                            },
                                            false
                                        )
                                        : null
                                    }
                                    <div
                                        className = 'remove-custom-holiday-button'
                                        onClick = {() => removeCustomHoliday(customHolidayItem.id)}
                                        tabIndex = {0}
                                        onKeyUp = {e => onRemoveCustomHolidayButtonKeyUp(e, customHolidayItem.id)}
                                        onKeyDown = {onRemoveCustomHolidayButtonKeyDown}
                                        data-test-id = {`custom-holiday-remove-button-${i}`}
                                    ><XIcon/></div>
                                </div>
                        ))}
                        {/* TODO: this button can be clicked if you click anywhere in its row, but the button looks like its only on the left side. */}
                        {/* eslint-disable-next-line react/no-children-prop */}
                        <div className='add-custom-holiday-wrapper'>
                            <div
                                onClick = {onAddNewCustomHolidayClick}
                                tabIndex = {0}
                                onKeyUp = {onAddCustomHolidayButtonKeyUp}
                                onKeyDown = {onAddCustomHolidayButtonKeyDown}
                            >
                                <div className='add-custom-holiday-button'>
                                    <PlusCircleOutlinedIcon className='basic-icon'/>
                                    <PlusCircleIcon className='hover-icon'/>
                                </div>
                                <span>Add a day</span>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </>
        )
    }

    renderHolidaysSection = () => {
        if (this.props.origin === 'company-setup') return null
        if (this.state.openHoursScheduleType === BusinessHoursType.Open24) return null
        return (
            <>
                <div className='section holidays-section'>
                    <div className='section-heading'><RemoteConfigValue valueId='bh_holidays_heading_label'/></div>
                    {this.renderDefaultHolidays()}
                </div>
                <div className='section custom-holidays-section'>
                    <div className='section-heading'><RemoteConfigValue valueId='bh_custom_holidays_heading_label'/></div>
                    {this.renderCustomHolidays()}
                </div>
            </>
        )
    }

    onSaveClick = async () => {
        // TODO: we should throw away our custom components so we can use FormControls and material ui's validation for this entire form.

        if (this.state.customHolidays.items.some(item => item.hasDuplicateName)) return this.goToErrorElement()
        if (this.state.customHolidays.items.some(item => item.hasDuplicateDate)) {
            if (!this.state.invalidSaveAttempt) this.setState({ invalidSaveAttempt: true }, this.goToErrorElement)
            return
        }

        // make sure every custom holiday has a name
        const foundInvalidHolidayName = this.state.customHolidays.items.find(i => !i.name)
        if (this.state.customHolidays.status === 'on' && foundInvalidHolidayName) return this.setState({ invalidSaveAttempt: true }, this.goToErrorElement)

        const saveData = generateSaveData(this.state)
        // return console.log('#### SAVE DATA:', saveData)
        const openHoursScheduleSaveData = saveData.openHoursSchedule
        const lunchScheduleSaveData = saveData.lunchSchedule
        const holidaysScheduleSaveData = saveData.holidaysSchedule
        const customHolidaysScheduleSaveData = saveData.customHolidaysSchedule
        this.setState({ loading: true })
        const response = await Api.updateSchedules(openHoursScheduleSaveData, lunchScheduleSaveData, holidaysScheduleSaveData, customHolidaysScheduleSaveData)
        this.updateSchedulesOnSave(saveData, response)
        this.setState({ loading: false })
    }

    updateSchedulesOnSave = (saveData, response) => {
        // Open hours
        const openHoursSchedule = {
            ...this.state.openHoursSchedule,
            status: 'on',
            id: response.open_hours_schedule.id,
            items: response.open_hours_schedule.items
        }
        // Lunch break
        const lunchSchedule = {
            ...this.state.lunchSchedule,
            status: saveData.lunchSchedule.status,
            id: response.lunch_schedule.id,
            items: response.lunch_schedule.items
        }
        // Holidays
        const holidaysSchedule = {
            ...this.state.holidaysSchedule,
            status: saveData.holidaysSchedule.status,
            id: response.holidays_schedule.id
        }
        response.holidays_schedule.items.forEach(holidayInfo => {
            const holiday = this.state.allHolidays.find(h => h.id === holidayInfo.holiday_id)
            holidayInfo.holiday = {
                id: holiday.id,
                name: holiday.name
            }
            delete holidayInfo.holiday_id
        })
        holidaysSchedule.items = response.holidays_schedule.items
        // Custom holidays
        const customHolidaysSchedule = {
            ...this.state.customHolidaysSchedule,
            status: saveData.customHolidaysSchedule.status,
            id: response.custom_holidays_schedule.id
        }
        customHolidaysSchedule.items = response.custom_holidays_schedule.items

        const openHoursScheduleType = getOpenHoursScheduleType(openHoursSchedule)
        this.setState({ openHoursSchedule, holidaysSchedule, customHolidaysSchedule, lunchSchedule, openHoursScheduleType })

        const { openHours, lunchPause, holidays, customHolidays } = getSavedSchedules(this.state)
        const customOpenHours = { ...this.state.customOpenHours }
        if (openHoursScheduleType === BusinessHoursType.Open24) {
            customOpenHours.items = daysMap.map(day => (getScheduleItemInfo(day.day, ['S', 'Su'].includes(day.short) ? 'off' : 'on', '9:00 am', '5:00 pm')))
        }
        this.setState({ openHours, lunchPause, holidays, customHolidays, loading: false, customOpenHours })
    }

    renderFooter = () => {
        const { classes, origin } = this.props
        if (origin === 'company-setup') return null
        const hasChanges = checkHasChange(this.state)
        return (
            <div className={classes.footer}>
                <Button
                    onClick = {this.onSaveClick}
                    color = 'primary'
                    disabled = {!hasChanges}
                >Save</Button>
            </div>
        )
    }

    render = () => {
        const { classes, origin, smallView } = this.props
        const originClass = origin === 'company-setup' ? 'company-setup' : ''
        const mobileClass = smallView ? 'mobile' : ''
        const settingWrapperClassNames = `${classes.settingWrapper} ${originClass} ${mobileClass}`
        const classNames = `${classes.businessHours} ${mobileClass}`
        return (
            <div className={classNames}>
                {this.state.loading
                    ? this.renderLoader()
                    : <>
                        <div className={settingWrapperClassNames}>
                            {this.renderBusinessHoursSection()}
                            {this.renderHolidaysSection()}
                        </div>
                        {this.renderFooter()}
                    </>
                }
            </div>
        )
    }
}

export default withStyles(styles)(BusinessHours)
