import React from "react";
import FilterButton from "../filter-button/filter-button"
import HoverBoxWrapper from "../hover-box-wrapper/hover-box-wrapper"
import CitySelector from "./city-selector/city-selector"


class CityFilter extends React.Component {

  constructor(props) {
    super(props);
    this.initialState = {
      selectedCities: [],
      selectedNpas: [],
      citiesSelectorOpen: false,
      prevAvailableCities: []
    };
    this.state = JSON.parse(JSON.stringify(this.initialState));
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  // Hide NpaSelector if click away from component
  handleClick = e => {
    if (this.node.contains(e.target)) {
      // Do nothing - click was within component
      return;
    }
    else {
      this.setState({citiesSelectorOpen: false});
    }
  };

  toggleCitySelector = () => {

    this.setState({
       citiesSelectorOpen: !this.state.citiesSelectorOpen
    })
  };

  handleCheckbox = (city, npas, checked) => {
    if (!checked) {
      this.setState({
        selectedCities: [city],
        selectedNpas: npas
        // selectedCities: this.state.selectedCities.concat(city)
      })
    }
    else {
      this.setState({
        selectedCities: [],
        selectedNpas: []
        // selectedCities: this.state.selectedCities.filter(item => item !== city)
      })
    }
  };

  handleSelectChange = (city, npas) => {

    if (city == ""){
      this.setState({
        selectedCities: [],
        selectedNpas: []
      })
    }
    else{
      this.setState({
        selectedCities: [city],
        selectedNpas: [npas]
      })
    }

    this.toggleCitySelector()
  }


  componentDidUpdate(prevProps, prevState) {


    if (this.props.reset !== prevProps.reset || this.props.resetCity !== prevProps.resetCity) {
      this.setState(
        JSON.parse(JSON.stringify(this.initialState))
      )
    }
    if (this.state.selectedCities !== prevState.selectedCities) {
      this.props.handleCitiesFilterUpdate(this.state.selectedCities, this.state.selectedNpas);
    }
  }

  render() {
    const child = <CitySelector
      selectedCities={this.state.selectedCities}
      availableCities={this.props.availableCities}
      handleCheckbox={this.handleCheckbox}
      handleSelectChange={this.handleSelectChange}
      CitySearch={this.props.CitySearch}
      loading={this.props.loading}
      filteredCities={this.props.filteredCities}

    />;
    const hoverbox = <HoverBoxWrapper
      children={child}
      open={this.state.citiesSelectorOpen}
    />

    return (
      <div
        ref={node => this.node = node}
        // style={{'position': 'relative', 'marginLeft': '120px'}}
      >
        <FilterButton
          data-test-id={'city-filter'}
          selectedFilters={this.state.selectedCities}
          handleClick= {this.toggleCitySelector} 
          handleIconClick={this.state.selectedCities.length == 0 ? this.toggleCitySelector : this.props.handleResetCity}
          defaultText={"City"}
          open={this.state.citiesSelectorOpen}
          hoverbox={hoverbox}
        />

      </div>
    );
  }

}

export default CityFilter;
