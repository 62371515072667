import React from "react";

import "./npa-selector.css"
import Fuse from 'fuse.js';
import Loader from '../../util/loader/loader'
import Spinner from 'spinner';
import SelectionControl from 'selection-control'



class NpaSelector extends React.Component {

  state = {
    searchValue: "",
    fuseList: [],
    fuseOptions: {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        "area_code",
      ]
    }
  }
  
  componentDidMount = () => {

    if (this.props.selectedNpas.length > 0) {
      this.setState({searchValue: ''})
    }
    let fuseList = []

    this.props.allNpas.forEach((value, index) => {
      fuseList.push({'area_code': value})
    });

    this.setState({fuseList:fuseList})

  }

  onNpaSearch = (e) => {
    let searchTherm = e.currentTarget.value
    if (this.state.fuseList.length === 0 ) return this.setState({searchValue:searchTherm})
    if (searchTherm.length > 2) {
      if (!this.props.allNpas.includes(searchTherm)){
        this.props.invalidAreaCodeMessage(true)
      }else{
        this.props.invalidAreaCodeMessage(false)
      }
    }
    else{
      this.props.invalidAreaCodeMessage(false)
    }
    this.setState({searchValue:searchTherm})
  }

  keyPress = (e) => {
    if(e.keyCode == 13){
      let searchTherm = e.currentTarget.value
      if (this.props.allNpas.includes(searchTherm) || searchTherm == ""){
        this.props.handleSelectChange(searchTherm)
      }
    }
  }
  
  render() {

    var fuse = new Fuse(this.state.fuseList, this.state.fuseOptions); // "list" is the item array
    let searchResults = []
    if (this.state.searchValue != '') {
      searchResults = fuse.search(this.state.searchValue);
    }
    let hasSelectedNpas = this.props.selectedNpas.length > 0;
    let hasSearchResults = searchResults.length > 0;
    const npas = [];
    const selectedNpasList = [];

    this.props.selectedNpas.forEach((value, index) => {
      selectedNpasList.push(
        <div
          key={`selected-npa-${value}`}
          className={"form-check item-name form-check-inline npa-background"}
          onClick={() => this.props.handleCheckbox(value, true)}
        >
          <div
            data-menu-item	= {value}
            value			= {value}
            key				= {index}
          >
            <SelectionControl
            data-menu-item	= {value}
            classes = {{checkboxWrapper: 'selection-checkbox-button'}}
            variant			= 'checkbox'
            checked			= {true}
            name			= {`mic-${index}}`}
            value			= {`mic-${value}`}
            disableHover	= {true}
            onClick={() => {}}
          />
          </div>
          {value}
        </div>
        )
    })
    searchResults.forEach((value, index) => {

      value = value.area_code
      let selected = ''
      let checked = false;
      if (this.props.selectedNpas.includes(value))
        return;
      npas.push(
        <li key={`npa-${value}`}>
          <div
            key={value}
            className={"form-check item-name form-check-inline npa-background"}
            onClick={() => this.props.handleCheckbox(value, checked)}
          >
            <div
              data-menu-item	= {value}
              value = {value}
              key = {index}
            >
              <SelectionControl
              data-menu-item = {value}
              classes = {{checkboxWrapper: 'selection-checkbox-button'}}
              variant = 'checkbox'
              checked = {checked}
              disabled = {this.props.selectedNpas.length >= 3 || this.props.allNpas.length === 0}
              name = {`mic-${index}}`}
              value = {`mic-${value}`}
              disableHover = {true}
              onClick={() => {}}
            />
            </div>
            {value}
          </div>
        </li>
      );
    });

    
    return (
      <div className="npa-selector">
        <span className="input-wrapper">
          <input data-test-id='npa-input' onChange={this.onNpaSearch} onKeyDown={this.keyPress} className="search-npa" type="text" value={this.state.searchValue} id="npa-search" name="npa-search" placeholder="Enter area code" autoComplete="off" autoFocus/>
        </span>

        {hasSelectedNpas && 
          <ul className="list-unstyled npa-list selected-npa-list">
            {selectedNpasList}
          </ul>
        }
        {hasSelectedNpas && hasSearchResults && <div className='npa-divider'></div>}
        {this.props.loading ?
          <Spinner/> :
          <ul className="list-unstyled npa-list">
            {npas}
          </ul>
        }
      </div>
      
    );
  }

}

export default NpaSelector;
