export function setUsers(users) {
	return {
		type: 'SET_USERS',
		users
	}
}
export function updateUser(user) {
	return {
		type: 'UPDATE_USER',
		user
	}
}
export function addUser(user) {
	return {
        type: 'ADD_USER',
        user
	}
}
export function deleteUser(userId) {
	return {
		type: 'DELETE_USER',
		userId
	}
}

export function setTempUsers(tempUsers) {
	return {
		type: 'SET_TEMP_USERS',
		tempUsers
	}
}
export function updateTempUser(tempUser) {
	return {
		type: 'UPDATE_TEMP_USER',
		tempUser
	}
}
export function addTempUser() {
	return {
		type: 'ADD_TEMP_USER'
	}
}
export function deleteTempUser(tempUserId) {
	return {
		type: 'DELETE_TEMP_USER',
		tempUserId
	}
}