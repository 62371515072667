import React, { Component } from 'react'
import ConfirmModal from 'confirm-modal'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	contentWrapper: {},
	section: {
		margin: '10px 0',
		'& span': {
			fontSize: 16
		}
	}
})

class EnableCallRecordingModal extends Component {

	renderContent = () => {
		const { classes } = this.props
		return (
			<div className={classes.contentWrapper}>
				<div className={classes.section}>
					<span>Our call-recording service allows recording for both incoming and outgoing calls for the selected phone number.</span>
				</div>
				<div className={classes.section}>
					<span>It is a premium feature that costs $7.99 per month per number.</span>
				</div>
			</div>
		)
	}

	render() {
		return (
			<ConfirmModal
				title			= 'Enable call recording?'
				isShown			= {this.props.show}
				content			= {this.renderContent()}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Add Call Recording'
				onReject		= {this.props.onClose}
				onConfirm		= {this.props.onConfirm}
				size			= 'size440'
			/>
		)
	}
}

export default withStyles(styles)(EnableCallRecordingModal)