<template>
    <w-btn
        v-bind="$attrs"
        v-on="$listeners"
        height="32"
        width="32"
        changes-form
        icon
    >
        <v-icon size="18" color="error">$vuetify.icon.trash</v-icon>
    </w-btn>
</template>
<script>
export default {};
</script>
