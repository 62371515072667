import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=5522%3A1494
 *
 * @param {SvgIconProps} props - svg props
 */
export const ArrowDropdownIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5.78508 9.09116C5.48678 8.77155 5.71342 8.25 6.15061 8.25L17.8494 8.25C18.2866 8.25 18.5132 8.77155 18.2149 9.09116L12.3655 15.3584C12.1679 15.5702 11.8321 15.5702 11.6345 15.3584L5.78508 9.09116Z' fill={color}/>
        </SvgIcon>
    )
}

export default ArrowDropdownIcon
