import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/9zP1Njd5AtB4jGpuSrQiug/General-UI-updates---pdc%2C-configure-%26-mini?node-id=8%3A16500
 *
 * @param {SvgIconProps} props - svg props
 */
export const AdvancedSettingsIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.972 13.0118H18.8883C18.7151 13.6441 18.4613 14.2443 18.1434 14.7961L18.9205 15.5734C19.549 16.2017 19.549 17.2248 18.9205 17.8531L18.3485 18.421C17.72 19.0533 16.7008 19.0533 16.0724 18.421L15.2868 17.6396C14.7349 17.9497 14.1427 18.2035 13.5143 18.3727V19.4722C13.5143 20.3583 12.7932 21.0833 11.9029 21.0833H11.0972C10.2069 21.0833 9.48577 20.3583 9.48577 19.4722V18.3727C8.86135 18.1995 8.2736 17.9497 7.72129 17.6356L6.93573 18.421C6.30729 19.0533 5.28807 19.0533 4.65963 18.421L4.08758 17.8531C3.45914 17.2248 3.45914 16.2017 4.08758 15.5734L4.86508 14.7961C4.54723 14.2402 4.29304 13.6441 4.11981 13.0118H3.02809C2.13779 13.0118 1.41669 12.2908 1.41669 11.4007V10.5952C1.41669 9.70506 2.13779 8.9841 3.02809 8.9841H4.11578C4.28498 8.35578 4.53877 7.76371 4.853 7.20789L4.08758 6.44262C3.45914 5.8143 3.45914 4.7953 4.08758 4.16295L4.65963 3.59504C5.28807 2.96672 6.30729 2.96672 6.93573 3.59504L7.70115 4.35628C8.25708 4.03809 8.8533 3.78434 9.48577 3.61115V2.5277C9.48577 1.63758 10.2069 0.916626 11.0972 0.916626H11.9029C12.7932 0.916626 13.5143 1.63758 13.5143 2.5277V3.61115C14.1508 3.78032 14.751 4.03809 15.307 4.35628L16.0724 3.59504C16.7008 2.96672 17.72 2.96672 18.3485 3.59504L18.9205 4.16295C19.549 4.7953 19.549 5.8143 18.9205 6.44262L18.1551 7.20789C18.4693 7.75968 18.7231 8.35578 18.8963 8.9841H19.972C20.8627 8.9841 21.5834 9.70506 21.5834 10.5952V11.4007C21.5834 12.2908 20.8627 13.0118 19.972 13.0118ZM11.5021 6.96663C9.27294 6.96663 7.46795 8.77436 7.46795 11C7.46795 13.2287 9.27294 15.0364 11.5021 15.0364C13.7312 15.0364 15.5362 13.2287 15.5362 11C15.5362 8.77436 13.7312 6.96663 11.5021 6.96663ZM11.5021 13.0166C12.6161 13.0166 13.5192 12.1137 13.5192 10.9999C13.5192 9.88617 12.6161 8.98328 11.5021 8.98328C10.3881 8.98328 9.48502 9.88617 9.48502 10.9999C9.48502 12.1137 10.3881 13.0166 11.5021 13.0166Z" fill={color}/>
        </SvgIcon>
    )
}

export default AdvancedSettingsIcon
