import { useCallback, useState } from 'react'

type ArrayHook<T> = [T[], {
    push: (...args: T[]) => void
    unshift: (...args: T[]) => void
    pop: () => T
    shift: () => T
    clear: () => void
    updateByKey: (key: string | number, value: any, updateData: Partial<T>) => void
    updateByIndex: (index: number, updateData: Partial<T>) => void
    set: (index: number, element: T) => void
    upsertByKey: (key: string | number, element: T) => void
}]

/***/
export function useArray<T> (initial = []): ArrayHook<T> {
    const [array, setArray] = useState(initial as T[])

    const clear = useCallback(() => setArray([]), [])

    const push = useCallback((...elements: T[]) => {
        setArray(currentArray => ([...currentArray, ...elements]))
    }, [array])

    const unshift = useCallback((...elements: T[]) => {
        setArray(currentArray => ([...elements, ...currentArray]))
    }, [array])

    const pop = useCallback(() => {
        const lastElement = array.at ? array.at(-1) : array[array.length - 1]
        setArray(currentArray => currentArray.slice(0, currentArray.length - 1))
        return lastElement
    }, [array])

    const shift = useCallback(() => {
        const firstElement = array[0]
        setArray(currentArray => currentArray.slice(1, currentArray.length))
        return firstElement
    }, [array])

    const updateByIndex = useCallback((index: number, updateData: Partial<T>): void => {
        setArray(currentArray => {
            if (!currentArray[index]) return currentArray
            return [
                ...currentArray.slice(0, index),
                { ...currentArray[index], ...updateData },
                ...currentArray.slice(index + 1, currentArray.length)
            ]
        })
    }, [array])

    const updateByKey = useCallback((key: string | number, value: any, updateData: Partial<T>): void => {
        setArray(currentArray => {
            const index = currentArray.findIndex(e => e[key] === value)
            if (index === -1) return currentArray
            return [
                ...currentArray.slice(0, index),
                { ...currentArray[index], ...updateData },
                ...currentArray.slice(index + 1, currentArray.length)
            ]
        })
    }, [array])

    const set = useCallback((index: number, element: T): void => {
        setArray(currentArray => {
            if (index < 0) return currentArray
            return [
                ...currentArray.slice(0, index),
                ...new Array(index - currentArray.length),
                element,
                ...currentArray.slice(index + 1, currentArray.length)
            ]
        })
    }, [array])

    const upsertByKey = useCallback((key: string | number, element: T): void => {
        setArray(currentArray => {
            const index = currentArray.findIndex(e => e[key] === element[key])
            if (index === -1) return [...currentArray, element]
            return [
                ...currentArray.slice(0, index),
                element,
                ...currentArray.slice(index + 1, currentArray.length)
            ]
        })
    }, [array])

    return [array, { push, unshift, pop, shift, clear, updateByKey, updateByIndex, set, upsertByKey }]
}

export default useArray
