// NOTE: The code about the hold music needs to be refactored. Now it is made only to work.

import React, { Component } from 'react'
import AudioTts from 'audio-tts'
import AudioRecorder from 'audio-recorder'
import AudioUploader from 'audio-uploader'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import SelectionControl from 'selection-control'
import Api from 'api'
import styles from './styles'

import TextFieldsIcon from '@material-ui/icons/TextFields'
import PublishIcon from '@material-ui/icons/Publish'
import MicIcon from '@material-ui/icons/Mic'
import { withStyles } from '@material-ui/core'

const voices = ['Joanna', 'Salli', 'Joey', 'Matthew']
const languages = ['English']

enum Tab {
    TTS = 'tts',
    CUSTOM = 'custom',
    RECORD = 'record'
}

interface Props {
    /**
     * Material ui classes
     */
    classes
    customAudioFiles: any[]
    onLoadingStateChanged: (isLoading: boolean) => void
    updateHasChange: (hasChange: boolean) => void
    save: string
    ttsVoice: string
    ttsLanguage: string
    autoFocus: boolean
    hideTtsOptions: boolean
    hideRecordOptions: boolean // not used at the moment
    audio: any
    updateAudio: (audio: any) => void
    type: 'voicemail' | 'hold-music'
    extension: any
    onSaveHoldMusic: (holdMusicAudio: any) => void
    setHoldMusicSaveType:(type: string) => void
    error: boolean
    smallView: boolean
    onChange: (data: any) => void
    editingAudio: any
    onHoldMusicChange: (holdMusic: any) => void
}

type CustomFileOption = 'upload' | 'choose'

interface State {
    loading: boolean
    customFileOption: CustomFileOption
    customAudioFiles: any[]
    selectedTab: Tab
    tts: { text: string, voice: string, language: string }
    uploadedAudio: any
    existingAudio: any
    existingAudiosMap: {[id: number]: any}
    readyToPlay: boolean
    recording: any
    removeBtnHover: boolean
}

class AudioManager extends Component<Props, State> {
    constructor (props) {
        super(props)
        let customFileOption: CustomFileOption = 'upload'
        let customAudioFiles = this.props.customAudioFiles
        if (customAudioFiles && customAudioFiles.find(f => f.selected)) {
            customFileOption = 'choose'
            customAudioFiles = JSON.parse(JSON.stringify(customAudioFiles))
        }
        this.state = { loading: true, customFileOption, customAudioFiles, ...this.getDefaultStates() }
    }

    componentDidMount = async () => {
        this.init()
        this.props.onLoadingStateChanged?.(this.state.loading)
    }

    componentDidUpdate (prevProps, prevState) {
        const hasChange = this.checkHasChange()
        if (this.props.updateHasChange) this.props.updateHasChange(hasChange)
        if (prevState.loading !== this.state.loading && this.props.onLoadingStateChanged) {
            this.props.onLoadingStateChanged(this.state.loading)
        }
        if (!prevProps.save && this.props.save) this.onSave()
    }

    getDefaultStates = () => {
        let selectedTab = this.props.type === 'voicemail' ? Tab.TTS : Tab.CUSTOM
        if (this.props.editingAudio?.type === 'record') selectedTab = Tab.RECORD
        else if (this.props.editingAudio?.type === 'upload') selectedTab = Tab.CUSTOM
        else if (this.props.editingAudio?.type === 'choose') selectedTab = Tab.CUSTOM
        else if (this.props.editingAudio?.type === 'file') selectedTab = Tab.CUSTOM
        return {
            selectedTab,
            tts: { text: '', voice: this.props.ttsVoice || voices[0], language: this.props.ttsLanguage || languages[0] },
            uploadedAudio: null,
            existingAudio: null,
            existingAudiosMap: {},
            readyToPlay: false,
            loading: true,
            recording: { isRecording: false },
            removeBtnHover: false
        }
    }

    init = async () => {
        this.setState({ ...this.getDefaultStates() })
        if (this.props.autoFocus) {
            const textArea = document.getElementById('tts-textarea')
            if (textArea) textArea.focus()
        }
        const audioConfig = await this.getAudioConfig()
        this.initAudio(audioConfig, false)
        if (this.props.editingAudio) this.setEditingAudio()
    }

    setEditingAudio = () => {
        const { editingAudio } = this.props
        if (editingAudio.type === 'upload') this.setState({ uploadedAudio: editingAudio.uploadedAudio, selectedTab: Tab.CUSTOM, customFileOption: 'upload' })
        else if (editingAudio.type === 'file') this.setState({ uploadedAudio: { ...editingAudio }, selectedTab: Tab.CUSTOM, customFileOption: 'upload' })
        else if (editingAudio.type === 'choose') {
            this.onHoldMusicChange(editingAudio.holdMusic.value)
            this.setState({ selectedTab: Tab.CUSTOM, customFileOption: 'choose' })
        } else if (editingAudio.type === 'record') this.setState({ recording: editingAudio.recording, selectedTab: Tab.RECORD })
        else if (editingAudio.type === 'tts') {
            const tts = editingAudio.tts || {
                text: editingAudio.voipRecording?.ttsText || '',
                voice: editingAudio.voipRecording?.ttsVoice || '',
                language: languages[0]
            }
            this.setState({ tts, selectedTab: Tab.TTS })
        }
    }

    initAudio = async (audioConfig, force) => {
        const voipRecording = audioConfig.voipRecording
        const voipRecordingId = voipRecording ? voipRecording.voipRecordingId : null
        let selectedTab = Tab.CUSTOM
        let audioLink = audioConfig.downloadLink
        const recordingLinkResponse = await this.getRecordingLink(voipRecordingId)
        if (!audioLink) audioLink = recordingLinkResponse ? recordingLinkResponse.download_link : null
        const filename = voipRecording ? voipRecording.name : ''
        let updatedAudio: any = {
            downloadLink: audioLink,
            voipRecordingId,
            type: 'file',
            filename,
            voipRecording
        }
        if (!voipRecording) {
            updatedAudio = {}
        } else if (!this.props.hideTtsOptions && voipRecording.ttsText) {
            selectedTab = Tab.TTS
            updatedAudio.type = Tab.TTS
            if (!this.props.editingAudio || force) this.setState({ tts: { text: voipRecording.ttsText, voice: voipRecording.ttsVoice, language: this.state.tts.language } })
        } else if (!this.props.hideRecordOptions && this.isRecordedAudio(audioConfig)) {
            selectedTab = Tab.RECORD
            const recording = this.state.recording
            recording.recordedAudio = JSON.parse(JSON.stringify(updatedAudio))
            if (!this.props.editingAudio || force) this.setState({ recording })
        } else {
            selectedTab = Tab.CUSTOM
            if (this.state.customFileOption === 'choose') {
                const existingAudio = JSON.parse(JSON.stringify(updatedAudio))
                if (existingAudio.voipRecordingId === (await this.getAudioConfig()).voipRecording.voipRecordingId) {
                    // This means the user didn't change the selection while the recording link was loading
                    if (!this.props.editingAudio || force) this.setState({ existingAudio })
                }
                const existingAudiosMap = this.state.existingAudiosMap
                existingAudiosMap[updatedAudio.voipRecording.voipRecordingId] = existingAudio
            } else if (this.state.customFileOption === 'upload') {
                if (!this.props.editingAudio || force) this.setState({ uploadedAudio: JSON.parse(JSON.stringify(updatedAudio)) })
            }
        }
        this.props.updateAudio(updatedAudio)
        const stateUpdateData: any = { loading: false }
        if (!this.props.editingAudio) stateUpdateData.selectedTab = selectedTab
        this.setState(stateUpdateData)
    }

    getRecordingLink = async voipRecordingId => {
        if (isNaN(parseInt(voipRecordingId))) return {}
        return await Api.getMusicOnHoldLink(voipRecordingId)
    }

    formatVoicemailResponse = (voicemailConfig: {[key: string]: string | {[key: string]: string}}): void => {
        const convertToCamelCaseKeys = (object) => {
            Object.keys(object).forEach((key: string): void => {
                const split = key.split('_').map((s: string, i: number): string => {
                    if (i === 0) return s
                    return `${s[0].toUpperCase()}${s.substring(1)}`
                })
                const camelCaseKey = split.join('')
                object[camelCaseKey] = object[key]
                delete object[key]
            })
        }
        convertToCamelCaseKeys(voicemailConfig)
        if (voicemailConfig.voipRecording) convertToCamelCaseKeys(voicemailConfig.voipRecording)
    }

    getAudioConfig = async () => {
        if (this.props.type === 'voicemail') {
            if (this.props.audio) return this.props.audio
            this.setState({ loading: true })
            const companyExtension = await Api.getCompanyExtension()
            const voicemailConfig = companyExtension?.id ? await Api.getVoicemailConfig(companyExtension.id) : null
            this.formatVoicemailResponse(voicemailConfig)
            this.setState({ loading: false })
            return voicemailConfig
        }
        if (this.props.type === 'hold-music') {
            const selectedFile = this.state.customAudioFiles.find(f => f.selected)
            const existingAudio = this.state.existingAudiosMap[selectedFile.value]
            if (existingAudio) return existingAudio
            return {
                voipRecording: {
                    voipRecordingId: selectedFile.value,
                    name: selectedFile.content
                }
            }
        }
    }

    isRecordedAudio = audioConfig => {
        const voipRecording = audioConfig.voipRecording
        const name = voipRecording ? (voipRecording.name || voipRecording.filename) : audioConfig?.recorderAudio.filename
        if (!name) return false
        const nameSplit = name.split(' ')
        if (name.length !== 23 || nameSplit.length !== 2) return false
        if (nameSplit[0] !== 'recording') return false
        const timestamp = nameSplit[1]
        for (let c = 0; c < timestamp.length; c++) {
            if (isNaN(parseInt(timestamp[c]))) return false
        }
        return true
    }

    checkHasChange = () => {
        let hasChange = false
        const audio = this.props.audio
        const { selectedTab, tts, uploadedAudio, recording, customFileOption } = this.state
        if (selectedTab === Tab.TTS) {
            hasChange = Boolean(tts.language && tts.text && tts.voice && (
                !audio ||
                   audio.type !== Tab.TTS ||
                   tts.text !== audio.voipRecording.ttsText ||
                   tts.voice !== audio.voipRecording.ttsVoice
            ))
        }
        if (selectedTab === Tab.CUSTOM) {
            if (customFileOption === 'upload') {
                hasChange = Boolean(uploadedAudio && (
                    !audio ||
                        audio.type !== 'file' ||
                        audio.voipRecordingId !== uploadedAudio.voipRecordingId ||
                        audio.downloadLink !== uploadedAudio.downloadLink
                ))
            } else if (customFileOption === 'choose') {
                const selectedFile = this.state.customAudioFiles.find(f => f.selected)
                hasChange = selectedFile.content !== this.props.audio.voipRecording?.name
            }
        }
        if (selectedTab === Tab.RECORD) {
            hasChange = Boolean(recording.recordedAudio && recording.start && recording.end)
        }
        return hasChange
    }

    onSave = async () => {
        if (!this.checkHasChange()) return
        this.setState({ loading: true })
        let voipRecordingId = null
        let newAudio = null
        if (this.state.selectedTab === Tab.TTS) {
            const { tts, name } = this.state
            let response
            if (this.props.type === 'voicemail') {
                response = await Api.createTTSGreeting({ text: tts.text, voice: tts.voice, name })
            } else if (this.props.type === 'hold-music') {
                response = await Api.createTTSMusicOnHold({ text: tts.text, voice: tts.voice, name })
            }
            voipRecordingId = response.voip_recording_id
            newAudio = {
                voipRecording: {
                    voipRecordingId,
                    name: 'TTS',
                    ttsText: tts.text
                }
            }
        } else if (this.state.selectedTab === Tab.CUSTOM) {
            if (this.state.customFileOption === 'choose') return
            const uploadedAudio = this.state.uploadedAudio
            let response
            if (this.props.type === 'voicemail') {
                response = await Api.createFileGreeting({ name: uploadedAudio.filename, file: uploadedAudio.base64Data })
            } else if (this.props.type === 'hold-music') {
                response = await Api.createFileMusicOnHold({ name: uploadedAudio.filename, file: uploadedAudio.base64Data })
            }
            delete uploadedAudio.base64Data
            uploadedAudio.voipRecordingId = response.voip_recording_id
            this.setState({ uploadedAudio })
            voipRecordingId = uploadedAudio.voipRecordingId
            newAudio = {
                downloadLink: uploadedAudio.downloadLink,
                type: 'file',
                voipRecording: { voipRecordingId, filename: uploadedAudio.filename },
                voipRecordingId
            }
        } else {
            const recordedAudio = this.state.recording.recordedAudio
            const downloadLink = recordedAudio.downloadLink
            const filename = recordedAudio.filename
            const base64Data = downloadLink.split(';')[1].split(',')[1]
            let response
            if (this.props.type === 'voicemail') {
                response = await Api.createFileGreeting({ name: filename, file: base64Data })
            } else if (this.props.type === 'hold-music') {
                response = await Api.createFileMusicOnHold({ name: filename, file: base64Data })
            }
            voipRecordingId = response.voip_recording_id
            this.removeRecordedAudio()
            newAudio = {
                downloadLink,
                type: 'file',
                voipRecording: { voipRecordingId, filename },
                voipRecordingId
            }
        }
        this.props.updateAudio(newAudio)
        await this.saveAudio(newAudio)
        this.init()
    }

    saveAudio = async newAudio => {
        const extensionId = this.props.extension ? this.props.extension.extension_id : null
        const voipRecordingId = newAudio.voipRecording.voipRecordingId
        if (this.props.type === 'voicemail') return await Api.configureVoicemail(voipRecordingId, extensionId)
        if (this.props.type === 'hold-music') {
            this.setState({ loading: true })
            const newAudioFile = {
                value: voipRecordingId,
                content: newAudio.voipRecording.name || newAudio.voipRecording.filename
            }
            await this.props.onSaveHoldMusic(newAudioFile)
            this.setCustomFileOption('choose')
            const customAudioFiles = JSON.parse(JSON.stringify(this.props.customAudioFiles))
            this.setState({ customAudioFiles, uploadedAudio: null })
        }
    }

    renderTtsContent = () => {
        const updateTts = tts => {
            this.props.onChange?.({ type: 'tts', tts })
            this.setState({ tts })
        }
        return (
            <AudioTts
                text = {this.state.tts.text}
                audio = {this.props.audio}
                hideTtsOptions = {Boolean(this.props.hideTtsOptions)}
                updateTts = {updateTts}
                showValidationErrors = {this.props.error}
            />
        )
    }

    removeRecordedAudio = () => this.setState({ recording: { isRecording: false } })

    renderRecordAMessage = () => {
        const onRecorded = recording => {
            this.props.onChange?.({ type: 'record', recording })
            this.setState({ recording })
        }
        return (
            <AudioRecorder
                onRecorded = {onRecorded}
                recording = {this.state.recording}
                // audio = {this.props.audio}
                onDelete = {this.removeRecordedAudio}
                hasError = {!this.state.recording?.recordedAudio && this.props.error}
            />
        )
    }

    renderAudioPlayer = () => {
        const { classes } = this.props
        const audio = this.state.existingAudio
        if (audio && !audio.downloadLink) return null
        return (
            <div className={classes.audioPlayerContainer}>
                {audio
                    ? <div style={{ display: this.state.readyToPlay ? 'block' : 'none' }}>
                        <AudioPlayer
                            key = {audio.voipRecordingId}
                            url = {audio.downloadLink}
                            onPlay = {() => { /**/ }}
                            onReadyToPlay = {() => this.setState({ readyToPlay: true })}
                        />
                    </div>
                    : null}
                {!this.state.readyToPlay ? <LoadingBar/> : null}
            </div>
        )
    }

    setCustomFileOption = customFileOption => {
        this.setState({ customFileOption })
        this.props.setHoldMusicSaveType(customFileOption)
    }

    onHoldMusicChange = async (value: number | string) => {
        const customAudioFiles = [...this.state.customAudioFiles]
        const holdMusic = customAudioFiles.find(f => `${f.value}` === `${value}`)
        customAudioFiles.forEach(f => (f.selected = f.value === holdMusic.value))
        this.setState({ customAudioFiles })
        const audioConfig = await this.getAudioConfig()
        if (!audioConfig.downloadLink) this.setState({ readyToPlay: false, existingAudio: null })
        this.initAudio(audioConfig, true)
        this.props.onChange?.({ type: 'choose', holdMusic })
        if (this.state.customFileOption === 'choose') this.props.onHoldMusicChange?.(holdMusic)
    }

    renderCustomAudioFile = () => {
        const { classes } = this.props
        const customAudioFiles = this.state.customAudioFiles
        let selected = customAudioFiles ? customAudioFiles.find(f => f.selected) : null
        if (selected) selected = `${selected.value}`
        // const error = this.props.error ? 'Cannot be empty' : ''
        const onUploaded = uploadedAudio => {
            this.setState({ uploadedAudio })
            this.props.onChange?.({ type: 'upload', uploadedAudio })
        }
        return (
            <div className={classes.customFileWrapper}>
                {customAudioFiles
                    ? <div className={classes.customOptionsWrapper}>
                        <div className={classes.title} onClick={() => this.setCustomFileOption('choose')}>
                            <SelectionControl
                                variant = 'radio'
                                checked = {this.state.customFileOption === 'choose'}
                                name = 'audio-custom-file'
                                value = 'choose'
                            />
                            <span>Choose from the list</span>
                        </div>
                        <div className={classes.title} onClick={() => this.setCustomFileOption('upload')}>
                            <SelectionControl
                                variant = 'radio'
                                checked = {this.state.customFileOption === 'upload'}
                                name = 'audio-custom-file'
                                value = 'upload'
                            />
                            <span>Upload your own</span>
                        </div>
                    </div>
                    : null
                }
                {customAudioFiles
                    ? <div style={this.state.customFileOption === 'choose' ? {} : { display: 'none' }}>
                        {this.renderAudioPlayer()}
                        <Select
                            value = {selected}
                            onChange = {e => this.onHoldMusicChange(e.target.value as string)}
                            error = {this.props.error}
                            helperText = {this.props.error && !selected ? 'Required field' : null}
                        >
                            {customAudioFiles.map(audioFileInfo => {
                                return <MenuItem key={audioFileInfo.value} value={`${audioFileInfo.value}`}>{audioFileInfo.content}</MenuItem>
                            })}
                        </Select>
                    </div>
                    : null
                }
                <div style={this.state.customFileOption === 'upload' ? {} : { display: 'none' }}>
                    <AudioUploader
                        id = {`${this.props.type}-audio-uploader`}
                        onUploaded = {onUploaded}
                        uploadedAudio = {this.state.uploadedAudio}
                        onDelete = {() => this.setState({ uploadedAudio: null })}
                        maxSize = {1024 * 1024 * 4} // 4MB
                        hasError = {!this.state.uploadedAudio && this.props.error}
                    />
                </div>
            </div>
        )
    }

    renderTabs = () => {
        const { classes } = this.props
        const tabs = [
            { id: Tab.CUSTOM, name: 'Custom File', icon: PublishIcon },
            { id: Tab.RECORD, name: 'Record audio', icon: MicIcon }
        ]
        if (!this.props.hideTtsOptions) {
            const ttsTab = { id: Tab.TTS, name: 'Text To Speech', icon: TextFieldsIcon }
            tabs.splice(0, 0, ttsTab)
        }
        const onTabClick = selectedTab => {
            if (selectedTab !== Tab.CUSTOM && this.props.setHoldMusicSaveType) this.props.setHoldMusicSaveType(selectedTab)
            this.setState({ selectedTab })

            if (selectedTab === Tab.TTS) this.props.onChange?.({ type: 'tts', tts: this.state.tts })
            else if (selectedTab === Tab.RECORD) this.props.onChange?.({ type: 'record', recording: this.state.recording })
            else if (selectedTab === Tab.CUSTOM) {
                const { customFileOption } = this.state
                if (customFileOption === 'upload') this.props.onChange?.({ type: 'upload', uploadedAudio: this.state.uploadedAudio })
                else if (customFileOption === 'choose') {
                    const customAudioFiles = [...this.state.customAudioFiles]
                    const holdMusic = customAudioFiles.find(f => !!f.selected)
                    this.props.onChange?.({ type: 'choose', holdMusic })
                }
            }
        }
        return (
            <div className={`${classes.header} ${this.state.selectedTab !== Tab.TTS ? 'round-corners' : ''}`}>
                {tabs.map(tab => (
                    <div
                        key = {tab.id}
                        className = {`tab ${this.state.selectedTab === tab.id ? 'selected' : ''}`}
                        onClick = {() => onTabClick(tab.id)}
                    >
                        {this.props.smallView && this.state.selectedTab !== tab.id ? <tab.icon/> : tab.name}
                    </div>
                ))}
            </div>
        )
    }

    render = () => {
        const { classes } = this.props
        return (
            <div className={`${classes.container} ${this.props.smallView ? 'small-view' : ''}`}>
                {this.renderTabs()}
                {this.state.selectedTab === Tab.TTS ? this.renderTtsContent() : null}
                {this.state.selectedTab === Tab.CUSTOM ? this.renderCustomAudioFile() : null}
                {this.state.selectedTab === Tab.RECORD ? this.renderRecordAMessage() : null}
            </div>
        )
    }
}

export default withStyles(styles)(AudioManager)
