<template>
    <div class="selection">
        <div class="text-and-description">
            <div class="w-body-2">
                {{text}}
            </div>
            <div v-if="description" class="w-caption description" >{{description}}</div>
        </div>
        <div v-if="label" class="label w-label">{{label}}</div>
    </div>
</template>
<script>
  export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        }
    },
    data() {
      return {
      };
    },
  };
</script>

<style lang="scss">
.selection {
    height: 57px !important;
    max-height: 57px !important;
    width: 100%;
    //margin-top: 50px;
    display: flex;
    justify-content: space-between;
    .text-and-description {
        margin: auto 0;
    }
    .description {
        width: 100%;
        color: var(--v-text-lighten1);
    }
    .label {
        width: 110px;
        padding: 1px 6px 0px;
        min-height: 20px;
        background: rgba(140, 152, 160, 0.15);
        border-radius: 2px;
        margin: auto 0 auto 6px;
        color: var(--v-text-lighten1);
        line-height: 20px;
        text-transform: uppercase;
    }
}
</style>
