<template>
    <svg
        @click="on_click"
        :class="{open, closed: !open}"
        class="expand-button cursor-pointer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="24" height="24" rx="12" :fill="btn_color"/>
        <g clip-path="url(#clip0_4587_66319)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.11842 10.8412C7.82012 10.5215 8.04676 10 8.48395 10L15.5161 10C15.9533 10 16.1799 10.5215 15.8816 10.8412L12.3655 14.6084C12.1679 14.8202 11.8322 14.8202 11.6345 14.6084L8.11842 10.8412Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_4587_66319">
        <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
        </clipPath>
        </defs>
    </svg>
</template>
<script>
  export default {
    props: {
        value: {
            type: Boolean,
        },
        button_color: {
            type: String,
        },
        clickable: {
            type: Boolean,
            default: true,
        }
    },
    data() {
      return {
        btn_color: this.$props.button_color || this.$vuetify.theme.defaults.light.secondary.base,
        open: !!this.$props.value,
      };
    },
    methods: {
        on_click() {
            if (this.$props.clickable) {
                this.$data.open = !this.$data.open;
            }
        },
    },
    watch: {
        open(v) {
            this.$emit('input', v);
        }
    }
  };
</script>

<style scoped lang="scss">
    .expand-button {
        fill: var(--v-secondary-base);
        &.open {
            transform: rotate( -180deg );
            transition: transform 150ms ease;
        }
        &.closed {
            transition: transform 150ms ease;
        }
    }
</style>
