import React, { Component } from 'react'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import Prompt from 'pdc-prompt'
import { DefaultArrowTooltip } from 'tooltips'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import PropTypes from 'prop-types'

class AudioUploader extends Component {
    constructor (props) {
        super(props)
        this.state = {
            uploadedAudio: props.uploadedAudio,
            readyToPlay: false,
            maxSizeError: false,
            typeError: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.id !== this.props.id) {
            const inputFileElement = document.getElementById(`${this.props.id}-upload-file`)
            this.setState({ uploadedAudio: null, maxSizeError: false, typeError: false })
            document.getElementById(`${this.props.id}-upload-file`).value = ''
            this.props.onDelete?.()
            inputFileElement.value = ''
        } else if (prevProps.uploadedAudio !== this.props.uploadedAudio) {
            this.setState({ uploadedAudio: this.props.uploadedAudio })
        }
    }

    onAudioUploaded = e => {
        e.persist()
        if (!e.target.value) return
        this.setState({ uploadedAudio: null })
        this.props.onDelete?.()
        const filename = e.target.value.split('\\').pop()
        const inputElement = document.getElementById(`${this.props.id}-upload-file`)
        const reader = new FileReader()
        const maxSize = this.props.maxSize
        if (maxSize && maxSize < inputElement.files[0].size) {
            return this.setState({ maxSizeError: true })
        }
        reader.onload = async () => {
            const result = reader.result
            const base64Data = result.split(';')[1].split(',')[1]
            const uploadedAudio = { downloadLink: result, filename, base64Data }
            const fileType = result.split(';')[0].split(':')[1].split('/')[0]
            if (fileType !== 'audio') return this.setState({ typeError: true })
            this.setState({ uploadedAudio })
            this.props.onUploaded(uploadedAudio)
        }
        reader.readAsDataURL(inputElement.files[0])
        this.setState({ readyToPlay: false })
    }

    renderAudioPlayer = () => {
        const { classes } = this.props
        if (this.props.hidePlayer) return null
        return <div className={classes.audioPlayerContainer}>
            <div style={{ display: this.state.readyToPlay ? 'block' : 'none' }}>
                <AudioPlayer
                    key = {this.state.uploadedAudio.voip_recording_id || this.state.uploadedAudio.voipRecordingId}
                    url = {this.state.uploadedAudio.download_link || this.state.uploadedAudio.downloadLink}
                    onPlay = {() => { /**/ }}
                    onReadyToPlay = {() => this.setState({ readyToPlay: true })}
                />
            </div>
            {!this.state.readyToPlay ? <LoadingBar/> : null}
        </div>
    }

    removeUploadedAudio = () => {
        this.setState({ uploadedAudio: null, maxSizeError: false, typeError: false })
        document.getElementById(`${this.props.id}-upload-file`).value = ''
        this.props.onDelete?.()
    }

    renderInfoSection = () => {
        const { classes } = this.props
        return (
            <div className={classes.infoSection}>
                <InfoOutlinedIcon classes={{ root: classes.infoIcon }}/>
                <div className='info-paragraphs-wrapper'>
                    <span>
                        Click on the Upload a file button and choose a file from your computer. File uploaded must be in mp3 format and under 4Mb.
                    </span>
                    <span>
                        Be sure you have permission from the rightful owner before uploading copyrighted audio.
                        See our <a href='https://www.phone.com/customer-terms/' target='_blank' rel="noopener noreferrer">Terms of Service</a> for more info.
                    </span>
                </div>
            </div>
        )
    }

    renderErrorPrompt = () => {
        const { classes/*, maxSize */ } = this.props
        // let maxSizeFormatted = ''
        // if (maxSize < 1024) maxSizeFormatted = `${maxSize}B`
        // else if (maxSize < (1024 * 1024)) maxSizeFormatted = `${maxSize / 1024}KB`
        // else if (maxSize < (1024 * 1024 * 1024)) maxSizeFormatted = `${maxSize / (1024 * 1024)}MB`
        // else maxSizeFormatted = `${maxSize / (1024 * 1024 * 1024)}GB`

        let error = false
        let errorMessage = ''
        if (this.state.maxSizeError) {
            error = true
            errorMessage = (
                <div className={classes.promptErrorMessage}>
                    <span>Uh oh...</span>
                    <span className='gap'>The file you’re attempting to upload exceeds the maximum size of 4MB</span>
                    <span>Please try uploading a different file</span>
                </div>
            )
        } else if (this.state.typeError) {
            error = true
            errorMessage = (
                <div className={classes.promptErrorMessage}>
                    <span>Uh oh...</span>
                    <span className='gap'>You’re attempting to upload a file that isn’t the correct format.</span>
                    <span>Please try uploading a file in mp3 format</span>
                </div>
            )
        }

        return (
            <Prompt
                isOpen = {error}
                onClose = {this.removeUploadedAudio}
                color = 'attention'
                content = {errorMessage}
                position = 'bottom'
            />
        )
    }

    render = () => {
        const { classes } = this.props
        let filename = ''
        const uploadedAudio = this.state.uploadedAudio
        if (uploadedAudio) {
            filename = uploadedAudio.filename
            if (!filename && uploadedAudio.voip_recording) filename = uploadedAudio.voip_recording.filename
            if (!filename && uploadedAudio.voipRecording) filename = uploadedAudio.voipRecording.filename
        }
        const errorClass = this.props.hasError ? 'error' : ''
        return (
            <div className={`${classes.audioUploaderWrapper} ${errorClass}`}>
                <div className={classes.fileSection}>
                    <div className={classes.selectButtonWrapper}>
                        <DefaultArrowTooltip
                            title = {this.state.uploadedAudio ? 'The current one will be replaced' : ''}
                            placement = 'right'
                        >
                            <label className={`${classes.uploadFileButton} ${errorClass}`} htmlFor={`${this.props.id}-upload-file`}>UPLOAD A FILE</label>
                        </DefaultArrowTooltip>
                        <input
                            type = 'file'
                            id = {`${this.props.id}-upload-file`}
                            style = {{ display: 'none' }}
                            accept = '.mp3,audio/*'
                            onChange = {this.onAudioUploaded}
                        />
                        <div className={classes.uploadedFileInfo}>
                            <span className='filename'>{filename}</span>
                            {this.props.onDelete && this.state.uploadedAudio
                                ? <DefaultArrowTooltip
                                    title = 'Remove'
                                    placement = 'right'
                                >
                                    <div className={classes.removeButton} onClick={this.removeUploadedAudio}><CloseIcon/></div>
                                </DefaultArrowTooltip>
                                : null
                            }
                        </div>
                    </div>
                    {this.state.uploadedAudio ? this.renderAudioPlayer() : null}
                </div>
                {this.renderInfoSection()}
                {this.renderErrorPrompt()}
            </div>
        )
    }
}

AudioUploader.propTypes = {
    /**
     * Material ui classes
     */
    classes: PropTypes.object,
    uploadedAudio: PropTypes.object,
    id: PropTypes.string,
    hasError: PropTypes.bool,
    onDelete: PropTypes.func,
    maxSize: PropTypes.number,
    onUploaded: PropTypes.func,
    hidePlayer: PropTypes.bool
}

export default withStyles(styles)(AudioUploader)
