import React, { Component, createContext } from 'react'
import { PhoneNumbers } from 'models'
import { getPhoneCom } from 'phonecom'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}
interface PhoneNumberProviderState {
    phoneNumbers: PhoneNumbers
    loaded: boolean
    isLoading: boolean
}
const PhoneNumberContext = createContext({})
/**
 *
 */
export const PhoneNumberConsumer = PhoneNumberContext.Consumer

class PhoneNumberProvider extends Component<Props, PhoneNumberProviderState> {
    /**
     * @param props
     */
    constructor (props) {
        super(props)
        this.state = {
            phoneNumbers: new PhoneNumbers(),
            loaded: false,
            isLoading: false
        }
    }

    /**
     *
     */
    componentDidMount () {
        this.load()
    }

    list = async (filterDid = ''): Promise<PhoneNumbers> => {
        await this.load()
        let res = this.state.phoneNumbers
        if (filterDid) res = res.filter(filterDid)
        return res
    }

    next = async (cursor: string): Promise<PhoneNumbers> => {
        return PhoneNumbers.load(cursor).then(
            res => {
                this.update(res)
                return res
            }
        )
    }

    load = async (): Promise<void> => {
        let res: PhoneNumbers = new PhoneNumbers()
        if (!this.state.loaded && !this.state.isLoading) {
            this.setState({ isLoading: true })
            await getPhoneCom()
            res = await PhoneNumbers.load(this.state.phoneNumbers.cursor)
            this.update(res)
            await this.setState({ phoneNumbers: res, loaded: true, isLoading: false })
            console.log('providers load phoneNumbers:', this.state.phoneNumbers)
        }
    }

    update = async (phoneNumbers: PhoneNumbers): Promise<any> => {
        const updated = this.state.phoneNumbers.update(phoneNumbers)
        this.setState({ phoneNumbers: updated })
    }

    /**
     *
     */
    render () {
        const { list, next } = this
        const { loaded, phoneNumbers } = this.state
        // let { displayNames } = this.state
        return (<PhoneNumberContext.Provider value={{
            list,
            next,
            phoneNumbers,
            loaded
        }}>
            {this.props.children}
        </PhoneNumberContext.Provider>)
    }
}

export default PhoneNumberProvider
